import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CRadioGroupProps, StyledRadioGroup } from "./c-radio-group";

// interface CRadioRequireFaceDetectionProps extends CRadioGroupProps {

// }

const CRadioRequireFaceDetection: FC<CRadioGroupProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <StyledRadioGroup
      {...props}
      height="45px"
      buttonStyle="solid"
      optionType="button"
      options={[
        { label: t("face-detection-required"), value: true },
        { label: t("face-detection-not-required"), value: false },
      ]}
    />
  );
};

export default CRadioRequireFaceDetection;
