import { Col, Divider, Form, Row, Typography } from "antd";
import Container from "components/display/container";
import CDatePicker from "components/input/c-date-picker";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import CUpload from "components/input/c-upload";
import PageContent from "components/layout/page-content";
import AddVehicleToUserField from "components/user-management/add-vehicle-field";
import { usePageRoute } from "provider/page-route";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetEmployee,
  usePatchEmployee,
  usePostEmployee,
} from "services/employee";
import dayjs from "dayjs";

const UserEmployeeCreateAndEditPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const post_user = usePostEmployee();
  const patch_user = usePatchEmployee();
  const { data, isLoading } = useGetEmployee(id);

  useEffect(() => {
    const vehicleInfos = data?.vehicleInfos?.map((e) => {
      const {
        vehicleBrand,
        vehicleImage,
        vehicleModel,
        licensePlateNumber,
        provincePlate,
      } = e;

      return {
        vehicleBrand,
        vehicleImage,
        vehicleModel,
        licensePlateNumber,
        provincePlate,
      };
    });
    form.setFieldsValue({
      ...data,
      startDate: data?.startDate ? dayjs(data?.startDate) : undefined,
      profile: data?.profile?.url,
      position: data?.position?.id,
      vehicles: vehicleInfos,
      department: data?.department?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const pageKey = getCurrentKey();

  return (
    <PageContent
      loading={isLoading && !!id}
      breadcrumb={{
        form,
        mutate: {
          mutation: id ? patch_user : post_user,
          invalidateQueries: ["get-employees", "get-employee"],
          onSaveAndContinue: !id,
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title level={5} className="!text-primary-dark">
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form layout="vertical" className="!p-5" form={form}>
          <Row gutter={24}>
            <Col span={6} className="!flex justify-center">
              <div>
                <Typography.Text className="!text-primary">
                  {t("basic-information")}
                </Typography.Text>
                <Form.Item className="!mt-3" name="profile">
                  <CUpload.Capture />
                </Form.Item>
              </div>
            </Col>
            <Col span={18}>
              <Row gutter={[24, 0]}>
                <Form.Item name="id" hidden>
                  <CInput />
                </Form.Item>
                <Col span={24}>
                  <Form.Item
                    label={t("identification-card-number")}
                    name="identificationNumber"
                  >
                    <CInput placeholder={t("identification-card-number")} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label={t("name-title")} name="prefix">
                    <CSelect
                      placeholder={t("name-title")}
                      valueOptions={{
                        values: [
                          { label: t("mister"), value: "MISTER" },
                          { label: t("mrs"), value: "MRS" },
                          { label: t("miss"), value: "MISS" },
                        ],
                        valueKey: "value",
                        titleKey: "label",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t("first-name")}
                    rules={[{ required: true }]}
                    name="firstName"
                  >
                    <CInput placeholder={t("first-name")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("last-name")}
                    rules={[{ required: true }]}
                    name="lastName"
                  >
                    <CInput placeholder={t("last-name")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("email")}
                    rules={[{ type: "email" }]}
                    name="email"
                  >
                    <CInput placeholder={t("email")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("phone-number")} name="phoneNumber">
                    <CInput placeholder={t("phone-number")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("job-position")}
                    rules={[{ required: true }]}
                    name="position"
                  >
                    <CSelect.JobPosition />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("department")} name="department">
                    <CSelect.Department
                      queryParams={{ departmentType: "EMPLOYEE" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("employee-id")}
                    rules={[{ required: true }]}
                    name="identityCode"
                  >
                    <CInput placeholder={t("employee-id")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("start-date")}
                    rules={[{ required: false }]}
                    name="startDate"
                  >
                    <CDatePicker disabled={false} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("swipe-card-code")} name="cardCode">
                    <CInput placeholder={t("swipe-card-code")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t("address")} name="address">
                    <CInput.TextArea rows={6} placeholder={t("address")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item noStyle name="vehicles">
                    <AddVehicleToUserField />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageContent>
  );
};

export default UserEmployeeCreateAndEditPage;
