import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Col, Divider, Form, Grid, Row, Typography } from "antd";
import CDrawer from "components/display/c-drawer";
import CInput from "components/input/c-input";
import CRadio from "components/input/c-radio";
import CSelect from "components/input/c-select";
import { fireNotification } from "components/popup/notification";
import dayjs from "dayjs";
import { useAppLanguage } from "provider/app-language";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { useVehicleInspection } from "provider/vehicle-inspection";
import { FormInstance } from "rc-field-form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetVehicleCondition,
  usePatchVehicleCondition,
} from "services/vehicle-condition";
import { IVehicleCondition } from "services/vehicle-condition/interface";
import styled from "styled-components";
import FormInputRadio from "./form-input-radio";

interface InspectionDescriptionDrawerProps {
  visible: boolean;
  onClose: () => void;
}

interface IProps {
  form: FormInstance;
  disabled: boolean;
}

interface ICheckInspection {
  keyName: "first" | "second" | "third" | "fourth";
  values: any;
}

const arr = [
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
];

const checkInspection = ({ keyName, values }: ICheckInspection) => {
  const findWithValues = arr
    .map((e) => {
      return values[`${keyName}TimeCheckQuestion${e}`];
    })
    .includes(false);
  return findWithValues;
};

const InspectionDescriptionDrawer: React.FC<
  InspectionDescriptionDrawerProps
> = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { pageKey } = usePageRoute();
  const { setAppLoading } = useAppLoading();
  const qClient = useQueryClient();

  const { selectedId, setSelectedId } = useVehicleInspection();
  const { xl } = Grid.useBreakpoint();

  const { data, isFetching } = useGetVehicleCondition(selectedId);
  const path_inspection = usePatchVehicleCondition();

  useEffect(() => {
    initailValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initailValue = () => {
    if (!data) return;
    form.resetFields();
    const { question, tenthQuestion, eleventhQuestion } = data;
    let checkbox = [];
    if (tenthQuestion) {
      checkbox.push("tenthQuestion");
    }
    if (eleventhQuestion) {
      checkbox.push("eleventhQuestion");
    }
    form.setFieldsValue({ ...data, checkbox, ...question });
  };

  const onFinish = (values: any) => {
    const dataValues = initailValuesOnFinish(values);
    setAppLoading(true);
    path_inspection.mutate(
      { ...dataValues },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: pageKey });
          qClient.invalidateQueries(["get-vehicle-conditions"]);
          qClient.invalidateQueries(["get-vehicle-condition"]);
          onClose();
          setSelectedId?.(undefined);
          form.resetFields();
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: pageKey,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const initailValuesOnFinish = (values: any) => {
    const {
      checkbox,
      status,
      id,
      previousLoading,
      qualityControl,
      description,
      gateName,
    } = values;

    let tenthQuestion = checkbox.some((e: string) => e === "tenthQuestion");
    let eleventhQuestion = checkbox.some(
      (e: string) => e === "eleventhQuestion"
    );

    let checkInput = false;
    let statusCheck = status;
    let passedTheInspection: string | undefined;
    let firstInspection: string | undefined;
    let secondInspection: string | undefined;
    let thirdInspection: string | undefined;
    let fourthInspection: string | undefined;

    if (status === "WAITING_FOR_INSPECT") {
      checkInput = checkInspection({ keyName: "first", values });
      if (checkInput) {
        statusCheck = "FAILED_THE_1ST_INSPECTION";
        firstInspection = dayjs().format();
      } else {
        statusCheck = "PASSED_THE_INSPECTION";
        passedTheInspection = dayjs().format();
      }
    }

    if (status === "FAILED_THE_1ST_INSPECTION") {
      checkInput = checkInspection({ keyName: "second", values });
      if (checkInput) {
        statusCheck = "FAILED_THE_2ND_INSPECTION";
        secondInspection = dayjs().format();
      } else {
        statusCheck = "PASSED_THE_INSPECTION";
        passedTheInspection = dayjs().format();
      }
    }

    if (status === "FAILED_THE_2ND_INSPECTION") {
      checkInput = checkInspection({ keyName: "third", values });
      if (checkInput) {
        statusCheck = "FAILED_THE_3RD_INSPECTION";
        thirdInspection = dayjs().format();
      } else {
        statusCheck = "PASSED_THE_INSPECTION";
        passedTheInspection = dayjs().format();
      }
    }

    if (status === "FAILED_THE_3RD_INSPECTION") {
      checkInput = checkInspection({ keyName: "fourth", values });
      if (checkInput) {
        statusCheck = "VIP";
        fourthInspection = dayjs().format();
      } else {
        statusCheck = "PASSED_THE_INSPECTION";
        passedTheInspection = dayjs().format();
      }
    }

    if (status === "VIP") {
      if (qualityControl) {
        statusCheck = "PASSED_THE_INSPECTION";
        passedTheInspection = dayjs().format();
      } else {
        statusCheck = "FAILED_THE_INSPECTION";
        fourthInspection = dayjs().format();
      }
    }

    const question: any = {};
    Object.assign(question, values);
    delete question.checkbox;
    delete question.id;
    delete question.previousLoading;
    delete question.status;

    const data = {
      id,
      previousLoading,
      tenthQuestion,
      eleventhQuestion,
      status: statusCheck,
      qualityControl,
      description,
      passedTheInspection,
      firstInspection,
      secondInspection,
      thirdInspection,
      fourthInspection,
      question,
      gateName,
    };
    return data;
  };

  const disabled =
    data?.status === "PASSED_THE_INSPECTION" ||
    data?.status === "VIP" ||
    data?.status === "FAILED_THE_INSPECTION";

  return (
    <CDrawer
      onClose={() => {
        onClose();
        setSelectedId?.(undefined);
      }}
      visible={visible}
      title={t("vehicle-inspection-record")}
      width={xl ? "65vw" : "80vw"}
      form={form}
      onSave={onFinish}
      forceRender={false}
      onCancel={() => {
        onClose();
        setSelectedId?.(undefined);
      }}
      hide={{
        save: data?.status === "PASSED_THE_INSPECTION",
        saveAndContinue: true,
      }}
      loading={isFetching}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item hidden name="id">
          <CInput />
        </Form.Item>
        <Form.Item hidden name="status">
          <CInput />
        </Form.Item>
        <Row>
          <Col
            span={10}
            style={{
              height: "calc(100vh - 188px)",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div
              style={{
                height: "calc(100vh - 188px)",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <DescriptionField item={data} />
              <Divider className="!my-3" />
              <ProductTypeField form={form} disabled={disabled} />
              <Divider className="!my-3" />
              <DateField item={data} />
            </div>
          </Col>
          <Col span={1} className="center">
            <Divider
              style={{ height: "calc(100vh - 185px)" }}
              type="vertical"
            />
          </Col>
          <Col span={13}>
            <RadioField form={form} disabled={disabled} />
          </Col>
        </Row>
      </Form>
    </CDrawer>
  );
};

const DateField = ({ item }: { item?: IVehicleCondition }) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const {
    createdAt,
    passedTheInspection,
    firstInspection,
    secondInspection,
    thirdInspection,
    fourthInspection,
  } = item || {};

  const createdAtDate = formatDate({
    date: createdAt,
    format: "normal-time",
  });

  const passedTheInspectionDate = formatDate({
    date: passedTheInspection,
    format: "normal-time",
  });

  const firstInspectionDate = formatDate({
    date: firstInspection,
    format: "normal-time",
  });
  const secondInspectionDate = formatDate({
    date: secondInspection,
    format: "normal-time",
  });
  const thirdInspectionDate = formatDate({
    date: thirdInspection,
    format: "normal-time",
  });
  const fourthInspectionDate = formatDate({
    date: fourthInspection,
    format: "normal-time",
  });

  return (
    <Row gutter={[6, 6]}>
      <Col span={24}>
        <Typography.Title level={5} className="!text-primary-dark">
          {t("date")}
        </Typography.Title>
      </Col>
      {createdAt && (
        <React.Fragment>
          <Col>
            <Typography.Text type="secondary">
              {t("waiting-for-inspection")}
            </Typography.Text>
          </Col>
          <Col>:</Col>
          <Col>
            <Typography.Text>{createdAtDate}</Typography.Text>
          </Col>
          <Col span={24}></Col>
        </React.Fragment>
      )}

      {passedTheInspection && (
        <React.Fragment>
          <Col>
            <Typography.Text>{t("passed-the-review")}</Typography.Text>
          </Col>
          <Col>:</Col>
          <Col>
            <Typography.Text>
              {passedTheInspection ? passedTheInspectionDate : "-"}
            </Typography.Text>
          </Col>
          <Col span={24}></Col>
        </React.Fragment>
      )}

      {firstInspection && (
        <React.Fragment>
          <Col>
            <Typography.Text>{t("1st-time")}</Typography.Text>
          </Col>
          <Col>:</Col>
          <Col>
            <Typography.Text>
              {firstInspection ? firstInspectionDate : "-"}
            </Typography.Text>
          </Col>
          <Col span={24}></Col>
        </React.Fragment>
      )}

      {secondInspection && (
        <React.Fragment>
          <Col>
            <Typography.Text>{t("2nd-time")}</Typography.Text>
          </Col>
          <Col>:</Col>
          <Col>
            <Typography.Text>
              {secondInspection ? secondInspectionDate : "-"}
            </Typography.Text>
          </Col>
          <Col span={24}></Col>
        </React.Fragment>
      )}

      {thirdInspection && (
        <React.Fragment>
          <Col>
            <Typography.Text>{t("3rd-time")}</Typography.Text>
          </Col>
          <Col>:</Col>
          <Col>
            <Typography.Text>
              {thirdInspection ? thirdInspectionDate : "-"}
            </Typography.Text>
          </Col>
          <Col span={24}></Col>
        </React.Fragment>
      )}

      {fourthInspection && (
        <React.Fragment>
          <Col>
            <Typography.Text>{t("4th-time")}</Typography.Text>
          </Col>
          <Col>:</Col>
          <Col>
            <Typography.Text>
              {fourthInspection ? fourthInspectionDate : "-"}
            </Typography.Text>
          </Col>
          <Col span={24}></Col>
        </React.Fragment>
      )}
    </Row>
  );
};

const DescriptionField = ({ item }: { item?: IVehicleCondition }) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const { createdAt, customerEmployee, vehicle } = item || {};

  const name =
    customerEmployee?.firstName && customerEmployee.lastName
      ? `${customerEmployee?.firstName} ${customerEmployee?.lastName}`
      : "-";

  const date = formatDate({
    date: createdAt,
    format: "normal-time",
  });

  const licensePlate = vehicle?.licensePlate || "-";
  const transportName = customerEmployee?.companyName || "-";
  return (
    <Row gutter={[6, 6]}>
      <Col span={24}>
        <Typography.Title level={5} className="!text-primary-dark">
          {t("description")}
        </Typography.Title>
      </Col>
      <Col span={24}></Col>
      <Col>
        <Typography.Text type="secondary">{t("entry-date")}</Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text>{date}</Typography.Text>
      </Col>
      <Col span={24}></Col>

      <Col>
        <Typography.Text type="secondary">{t("full-name")}</Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text>{name}</Typography.Text>
      </Col>
      <Col span={24}></Col>

      <Col>
        <Typography.Text type="secondary">{t("phone-number")}</Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text>
          {customerEmployee?.phoneNumber || "-"}
        </Typography.Text>
      </Col>
      <Col span={24}></Col>

      <Col>
        <Typography.Text type="secondary">
          {t("company-name")} / {t("transport-name")}
        </Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text>{transportName}</Typography.Text>
      </Col>
      <Col span={24}></Col>

      <Col>
        <Typography.Text type="secondary">
          {t("license-plate-number")}
        </Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text>{licensePlate}</Typography.Text>
      </Col>
      <Col span={24}></Col>

      <Col>
        <Typography.Text type="secondary">{t("inspector")}</Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text>{"-"}</Typography.Text>
      </Col>
      <Col span={24}></Col>
    </Row>
  );
};

const ProductTypeField: React.FC<IProps> = ({ disabled }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Typography.Title level={5} className="!text-primary-dark">
        {t("product-type")}
      </Typography.Title>
      <Form.Item
        label={t("previous-cargo")}
        className="!mt-3"
        name="previousLoading"
        style={{ width: "99%" }}
      >
        <CInput.TextArea
          rows={4}
          placeholder={t("previous-cargo")}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item name="checkbox" noStyle>
        <Checkbox.Group disabled={disabled}>
          <Row gutter={[0, 6]}>
            <Col span={24}>
              <Checkbox value="tenthQuestion">
                {t("vegetable-oil-truck-pack-oil")}
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="eleventhQuestion">
                {t("vegetable-oil-truck")}
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </React.Fragment>
  );
};

const WrapperPDPA = styled.div<{ height?: boolean }>`
  width: 100%;
  border: 1px solid #e4e4e4;
`;

const RadioField: React.FC<IProps> = ({ form, disabled }) => {
  const { t } = useTranslation();
  const [ellipsis, setEllipsis] = useState(false);
  const status = Form.useWatch("status", form);

  const onClickShowInput = () => {
    setEllipsis(!ellipsis);
  };

  return (
    <div
      style={{
        height: "calc(100vh - 188px)",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <Typography.Title level={5} className="!text-primary-dark">
        {t("assessment-form")}
      </Typography.Title>
      {status === "VIP" && (
        <WrapperPDPA className="pl-3 pr-4 py-3 !mt-4 !rounded-app">
          <Row wrap={false}>
            <Col span={23}>
              <Typography.Text>
                {t("truck-inspection-assessment-form")}
              </Typography.Text>
            </Col>
            <Col className="!flex flex-col justify-center" span={1}>
              {ellipsis ? (
                <DownOutlined onClick={onClickShowInput} />
              ) : (
                <UpOutlined onClick={onClickShowInput} />
              )}
            </Col>
          </Row>
        </WrapperPDPA>
      )}
      <div hidden={ellipsis}>
        <div className="!mt-4">{`1. ${t("general-cleanliness", {
          ns: "message",
        })}`}</div>
        <FormInputRadio keyName="One" form={form} disabled={disabled} />

        <div className="!mt-4">{`2. ${t(
          "condition-of-the-car-floor-tray-rooftop",
          { ns: "message" }
        )}`}</div>
        <FormInputRadio keyName="Two" form={form} disabled={disabled} />

        <div className="!mt-4">{`3. ${t("condition-of-the-blanket", {
          ns: "message",
        })}`}</div>
        <FormInputRadio keyName="Three" form={form} disabled={disabled} />

        <div className="!mt-4">{`4. ${t(
          "insect-or-insect-remains-must-not-be-found",
          { ns: "message" }
        )}`}</div>
        <FormInputRadio keyName="Four" form={form} disabled={disabled} />

        <div className="!mt-4">{`5. ${t("safety-equipment", {
          ns: "message",
        })}`}</div>
        <FormInputRadio keyName="Five" form={form} disabled={disabled} />

        <div className="!mt-4">{`6. ${t("dress-check", {
          ns: "message",
        })}`}</div>
        <FormInputRadio keyName="Six" form={form} disabled={disabled} />

        <div className="!mt-4">{`7. ${t("alcohol-level-measurement", {
          ns: "message",
        })}`}</div>
        <FormInputRadio keyName="Seven" form={form} disabled={disabled} />

        <div className="!mt-4">{`8. ${t(
          "through-the-spraying-point-for-disinfection",
          { ns: "message" }
        )}`}</div>
        <FormInputRadio keyName="Eight" form={form} disabled={disabled} />

        <div className="!mt-4">{`9. ${t(
          "wash-your-hands-and-dip-your-feet-in-the-designated-spots",
          { ns: "message" }
        )}`}</div>
        <FormInputRadio keyName="Nine" form={form} disabled={disabled} />
        <div className="!mt-4 text-[12px]">
          <span className="text-[#DB1212] text-[12px]">***</span>
          {t("conditions-for-vehicle-inspection")}
        </div>
      </div>
      {(status === "VIP" ||
        status === "PASSED_THE_INSPECTION" ||
        status === "FAILED_THE_INSPECTION") && (
        <VipCheck form={form} disabled={status !== "VIP"} />
      )}
    </div>
  );
};

const VipCheck: React.FC<IProps> = ({ form, disabled }) => {
  const { t } = useTranslation();
  const watchQualityControl = Form.useWatch("qualityControl", form);

  if (watchQualityControl === true) {
    form.setFieldsValue({ gateName: undefined });
  }

  return (
    <React.Fragment>
      <Form.Item
        rules={[{ required: true }]}
        name="qualityControl"
        label={t("quality-control-officer")}
        className="!mt-5"
      >
        <CRadio.Group
          optionType="default"
          options={[
            { label: t("passed-the-review"), value: true },
            { label: t("decline"), value: false },
          ]}
          disabled={disabled}
        />
      </Form.Item>
      {watchQualityControl === false && (
        <Form.Item
          rules={[{ required: watchQualityControl === false }]}
          name="gateName"
          label={t("gate-name")}
          className="!mt-5"
        >
          <CSelect
            placeholder={t("gate-name")}
            valueOptions={{
              values: [
                {
                  gateName: "GATE_3_IN_TRUCK_CASE",
                  title: t("gete-3-in-truck"),
                },
                {
                  gateName: "CAR_GATE_1_OPEN_BARRIER",
                  title: t("car-gate-1-open"),
                },
              ],
              titleKey: "title",
              valueKey: "gateName",
            }}
            disabled={disabled}
          />
        </Form.Item>
      )}

      <Form.Item name="description" label={t("callback-note")}>
        <CInput.TextArea
          placeholder={t("callback-note")}
          rows={4}
          disabled={disabled}
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default InspectionDescriptionDrawer;
