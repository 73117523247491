import { useQueryClient } from "@tanstack/react-query";
import { Form, Input, Typography } from "antd";
import { FormInstance } from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { ColumnsType } from "antd/lib/table";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import CCard from "components/display/card";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteJobType,
  useDeleteJobTypeBulk,
  useGetSettingJobType,
  usePatchJobTypeId,
  usePostJobType,
} from "services/setting-job-type";
import {
  ISettingJobTypeParams,
  ISttingJobType,
} from "services/setting-job-type/interface";
const JobType = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState<ISettingJobTypeParams>({
    page: 1,
    limit: 10,
  });

  const data = useGetSettingJobType(params);
  const post_data = usePostJobType();
  const qClient = useQueryClient();
  const del_data = useDeleteJobType();
  const del_data_bulk = useDeleteJobTypeBulk();
  const patch_data = usePatchJobTypeId();

  const { formatDate, translationObject } = useAppLanguage();

  const onClose = () => {
    setVisible(!visible);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    const { id } = values;
    if (!values.status) values.status = "true";
    if (id) {
      return patch_data.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-job-type"]);
          onClose();
        },
        onError: ({ meesage }: any) => {
          fireNotification({ type: "error", description: meesage });
        },
      });
    }

    return post_data.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        qClient.invalidateQueries(["get-job-type"]);
        onClose();
      },
      onError: ({ meesage }: any) => {
        fireNotification({ type: "error", description: meesage });
      },
    });
  };

  const elements: ISearchBoxElement[] = [
    {
      name: "jobType",
      label: t("job-type-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "createdAt",
      label: t("created-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "updatedAt",
      label: t("updated-at"),
      input: {
        type: "RangePicker",
      },
    },

    {
      name: "isActive",
      label: t("status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              { key: true, value: t("active") },
              { key: false, value: t("in-active") },
            ],
            valueKey: "key",
            titleKey: "value",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<ISttingJobType> = [
    {
      title: t("job-type-name"),
      width: "40%",
      render: (_, rc) => {
        const { jobType } = translationObject({
          object: rc,
          keys: ["jobType"],
        });
        return (
          <Typography.Text ellipsis={{ tooltip: jobType }}>
            {jobType}
          </Typography.Text>
        );
      },
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      width: "20%",
      render: (_, rc) => {
        if (!rc.createdAt) return <span>-</span>;
        const date = formatDate({ date: rc.createdAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      width: "20%",
      render: (_, rc) => {
        if (!rc.updatedAt) return <span>-</span>;
        const date = formatDate({ date: rc.updatedAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },

    {
      title: t("status"),
      dataIndex: "isActive",
      width: "20%",
      render: (status) => {
        if (status === null || status === undefined) return <span>-</span>;
        return (
          <CCard.ActiveInActive status={status ? "active" : "in-active"} />
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const { updatedAt, createdAt } = values || {};
    setParams({
      ...params,
      ...values,
      startUpdatedAt: updatedAt?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endUpdatedAt: updatedAt?.[1]?.format("YYYY-MM-DD 23:59:59"),
      startCreatedAt: createdAt?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endCreatedAt: createdAt?.[1]?.format("YYYY-MM-DD 23:59:59"),
    });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <DrawerFormInput
        form={form}
        visible={visible}
        onClose={onClose}
        onFinish={onFinish}
      />
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        onAdd={{
          onClick: () => {
            setVisible(!visible);
          },
        }}
        onRowClick={(dataSource: any) => {
          form.setFieldsValue(dataSource);
          setVisible(!visible);
        }}
        action={{
          bulkDelete: {
            mutation: del_data_bulk,
            invalidateQueries: ["get-job-type"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: del_data,
          invalidateQueries: ["get-job-type"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        query={data}
        columns={columns}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        title={t("job-type-list")}
      />
    </PageContent>
  );
};

interface IDrawerForm {
  form: FormInstance;
  visible: boolean;
  onClose: () => void;
  onFinish: (value: any) => void;
}

const DrawerFormInput: React.FC<IDrawerForm> = ({
  form,
  visible,
  onClose,
  onFinish,
}) => {
  const { t } = useTranslation();
  const jobTypeTH = (
    <span>
      {t("job-type-name")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const jobTypeEN = (
    <span>
      {t("job-type-name")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );
  const descriptionTh = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const descriptionEn = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );
  const titleForm = form.getFieldValue("id")
    ? t("edit-job-type")
    : t("add-job-type");
  return (
    <CDrawer
      form={form}
      title={titleForm}
      placement="right"
      onClose={onClose}
      size="large"
      visible={visible}
      width="40vw"
      onSave={onFinish}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        className="mb-4"
        initialValues={{ isActive: true }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="jobTypeTh"
          label={jobTypeTH}
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("job-type")} />
        </Form.Item>
        <Form.Item
          name="jobTypeEn"
          label={jobTypeEN}
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("job-type")} />
        </Form.Item>
        <Form.Item
          label={t("status")}
          name="isActive"
          rules={[{ required: true }]}
        >
          <CSelect.IsActive />
        </Form.Item>
        <Form.Item name="descriptionTh" label={descriptionTh}>
          <TextArea
            placeholder={t("description")}
            autoSize={{
              minRows: 6,
              maxRows: 6,
            }}
          />
        </Form.Item>
        <Form.Item name="descriptionEn" label={descriptionEn}>
          <TextArea
            placeholder={t("description")}
            autoSize={{
              minRows: 6,
              maxRows: 6,
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};
export default JobType;
