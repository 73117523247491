import { useQueryClient } from "@tanstack/react-query";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import DeviceStatusCard from "components/display/card/device-status-card";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteAccessControls,
  useDeleteAccessControlBulk,
  useGetAccessControls,
  useExportAccessControls,
  usePostAccessControlsGenerateHCP,
} from "services/access-control";
import {
  IAccessControl,
  IAccessControlParams,
} from "services/access-control/interface";

const ResourceAccessControlEquipmentPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IAccessControlParams>({
    page: 1,
    limit: 10,
  });

  const data_accessControls = useGetAccessControls(params);
  const delete_access_control = useDeleteAccessControls();
  const delete_access_controls = useDeleteAccessControlBulk();
  const export_access_control = useExportAccessControls();

  const post_hcp = usePostAccessControlsGenerateHCP();
  const qClient = useQueryClient();
  const { setAppLoading } = useAppLoading();

  const elements: ISearchBoxElement[] = [
    {
      name: "devName",
      label: t("equipment-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "devIp",
      label: t("internet-protocol-address"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "devPort",
      label: t("port"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "devCode",
      label: t("serial-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "macAddress",
      label: t("mac-address"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "status",
      label: t("network-status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              {
                key: 0,
                label: t("unknow"),
              },
              {
                key: 1,
                label: t("online-status"),
              },
              {
                key: 2,
                label: t("offline-status"),
              },
            ],
            valueKey: "key",
            titleKey: "label",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<IAccessControl> = [
    {
      title: t("equipment-name"),
      ellipsis: true,
      dataIndex: "devName",
      render: (_, rc) => {
        const devName = rc.devName || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: devName }}>
            {devName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("internet-protocol-address"),
      dataIndex: "devIp",
      render: (_, rc) => {
        return rc.devIp || "-";
      },
    },
    {
      title: t("port"),
      dataIndex: "devPort",
      render: (_, rc) => {
        return rc.devPort || "-";
      },
    },
    {
      title: t("serial-number"),
      ellipsis: true,
      dataIndex: "devCode",
      render: (_, rc) => {
        const devCode = rc.devCode || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: devCode }}>
            {devCode}
          </Typography.Text>
        );
      },
    },
    {
      title: t("mac-address"),
      ellipsis: true,
      dataIndex: "macAddress",
      render: (_, rc) => {
        return rc.macAddress || "-";
      },
    },
    {
      title: t("network-status"),
      dataIndex: "status",
      align: "center",
      render: (status) => {
        return (
          <div className="!flex !justify-center">
            <DeviceStatusCard
              status={status?.toLocaleUpperCase() || "OFFLINE"}
            />
          </div>
        );
      },
    },
  ];

  const onSearch = (FormItem: any) => {
    setParams({ ...params, ...FormItem });
  };

  const onRefetch = () => {
    post_hcp.mutate(
      {},
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "resource-management" });
          qClient.invalidateQueries(["get-access-controls"]);
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "resource-management",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        rowKey="id"
        onAdd="resource-management.access-control-equipment-add"
        onRowClick="resource-management.access-control-equipment-edit"
        query={data_accessControls}
        loading={data_accessControls?.isFetching}
        action={{
          bulkDelete: {
            mutation: delete_access_controls,
            invalidateQueries: ["get-access-controls"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
          excels: {
            export: {
              mutation: export_access_control,
            },
          },
        }}
        onDelete={{
          mutation: delete_access_control,
          invalidateQueries: ["get-access-controls"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        columns={columns}
        title={t("access-control-equipment-list")}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        extraHeaderButton={[
          {
            children: t("fetch-new-data"),
            theme: "gray",
            loading : post_hcp?.isLoading,
            onClick: () => {
              onRefetch();
            },
          },
        ]}
      />
    </PageContent>
  );
};

export default ResourceAccessControlEquipmentPage;
