import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { postMediaObject } from "services/media-object";
import { IVisitor, IVisitorParams } from "./interface";
import { dataUrlToFile, resizeImageFileSize } from "tools/file";

export const useGetVisitors = (
  params?: IVisitorParams
): UseQueryResult<IResponse<IVisitor[]>> => {
  return useQuery(
    ["get-visitors", params],
    async () => {
      const res = await axios.get("/visitor", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetVisitor = (
  id?: string | number
): UseQueryResult<IVisitor> => {
  return useQuery(
    ["get-visitor", id],
    async () => {
      const res = await axios.get(`/visitor/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostVisitor = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    data = await appendImages(data);
    const res = await axios.post("/visitor", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchVisitor = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    data = await appendImages(data);
    const res = await axios.patch(`/visitor/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteVisitor = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/visitor/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteVisitors = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.delete(`/visitor/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

const appendImages = async (values: any): Promise<any> => {

  let { profile, itemPictures, identityCardPicture, profiles, ...rest } =
    values || {};

  if (profile && typeof profile === "object") {
    profile = await postMediaObject(profile);
    rest = { ...rest, profile };
  }

  if (itemPictures && itemPictures.length > 0) {
    const itemPicturesList: number[] = await Promise.all(
      itemPictures.map(async (item: any): Promise<number> => {
        let id: number;
        if (!item.id) {
          id = await postMediaObject(item);
        } else {
          id = item.id;
        }
        return id;
      })
    );
    rest = { ...rest, itemPictures: itemPicturesList };
  }

  if (identityCardPicture) {
    if (typeof identityCardPicture === "object") {
      identityCardPicture = await postMediaObject(identityCardPicture);
      rest = { ...rest, identityCardPicture };
    }

    var urlRegx = new RegExp(
      "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    const isUrl = urlRegx.test(identityCardPicture);
    if (isUrl == true) {
      try {
        const file = await dataUrlToFile(identityCardPicture);

        const resized = await resizeImageFileSize({
          file: file,
          maxHeight: 500,
          maxWidth: 500,
        });

        identityCardPicture = resized;
        identityCardPicture = await postMediaObject(identityCardPicture);
        rest = { ...rest, identityCardPicture };
      } catch (e) {
      }

    }
  }

  if (profiles?.length < 1) {
    rest = { ...rest, profiles: [] };
  }

  if (profiles && profiles?.length > 0) {
    const itemPicturesList: number[] = await Promise.all(
      profiles.map(async (item: any): Promise<number> => {
        let id: number;
        if (!item.id) {
          id = await postMediaObject(item);
        } else {
          id = item.id;
        }
        return id;
      })
    );
    rest = { ...rest, profiles: itemPicturesList };
  }

  return rest;
};

export const useExportTemplateVisitors = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/template/visitor`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportExcelVisitors = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/visitor/import/visitor`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportProfileVisitors = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    data = await appendImages(data);
    const res = await axios.post("/visitor/upload-profile", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
