import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import { parkingVehicleColor } from "constant";
// import { ITheme, ThemeOptions, ThemeTypes } from "components/constrant/theme";
import LineChart, { TLineChartColor } from "components/chart/line-chart";
export interface ILabelLineChart {
  text: string;
  color: string;
}

const ChartWrapper = styled.div`
  border-radius: 5px;
`;

const Donut = styled.div<{ color: string }>`
  display: inline-block;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 2px solid ${({ color }) => color};
`;

export const DonutLabel = ({
  label,
  color,
  text = "gray",
}: {
  label: string;
  color: string;
  text?: TLineChartColor;
}) => {
  return (
    <Row gutter={12} align="middle" className="mx-2">
      <Col>
        <Donut color={color} className="center mx-2" />
      </Col>
      <Col>
        <span className={`color-${text} mr-4`}>{label}</span>
      </Col>
    </Row>
  );
};
interface ChartProps {
  title?: string;
  subTitle?: string;
  series: { name: string; data: number[] }[];
  categories: string[];
  labels?: ILabelLineChart[];
  theme?: any;
  color?: TLineChartColor;
}

const defaultLabel = [
  { color: "#E0B33C", text: "car" },
  { color: "blue", text: "motorcycle" },
];

const ParkingLineChartCard = ({
  title,
  subTitle = "number-of-vehicles",
  categories,
  series,
  labels = defaultLabel,
  theme = "default",
  color = "gray",
}: ChartProps) => {
  const { t } = useTranslation();

  return (
    <ChartWrapper className="px-2 pt-4">
      <Row>
        <Col span={24} className="ml-3">
          <Typography.Title className="color-white mb-1" level={4}>
            {title}
          </Typography.Title>
        </Col>
        <Col span={12}>
          <span className={`color-${color} ml-3`}>{t(subTitle)}</span>
        </Col>
        <Col span={12}>
          <div className="flex justify-end align-center">
            {labels.map((item, index) => {
              return (
                <DonutLabel
                  key={index}
                  color={item.color}
                  label={t(item.text)}
                  text={color}
                />
              );
            })}
          </div>
        </Col>
      </Row>
      <LineChart categories={categories} series={series} color={color} />
    </ChartWrapper>
  );
};

export default ParkingLineChartCard;
