import Icon from "@ant-design/icons";
import { Col, Row } from "antd";
import { IconQrCode, IConFaceAccess } from "assets/icons/general";
import styled from "styled-components";

type TAccessibility = "IDENTITY_CARD" | "FACE_RECOGNITION";

const Wrapper = styled(Row)<{ $active: boolean }>`
  width: 35px;
  height: 35px;
  border: 1px solid ${({ $active }) => ($active ? "#48B074" : "#2E384D")};
  border-radius:5px;
  .anticon {
    path *,
    g * {
      fill: ${({ $active }) => ($active ? "#48B074" : "#2E384D")};
    }
  }
`;

const AccessibilityCardTable = ({ active }: { active?: TAccessibility }) => {
  return (
    <Row gutter={[8, 6]} justify="center">
      <Col>
        <Wrapper justify="center" align="middle" $active={active === "IDENTITY_CARD" ? true : false}>
          <Icon component={IconQrCode} style={{ fontSize: "30px" }}/>
        </Wrapper>
      </Col>
      <Col>
        <Wrapper justify="center" align="middle" $active={active === "FACE_RECOGNITION" ? true : false}>
          <Icon component={IConFaceAccess} style={{ fontSize: "35px" }}/>
        </Wrapper>
      </Col>
    </Row>
  );
};

export default AccessibilityCardTable;
