import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IAccessibility,
  IAccessibilityInGroup,
  IAccessibilityNotInGroup,
  IAccessibilityNotInGroupParams,
  IAccessibilityParams,
} from "./interface";

export const useGetAccessibilitys = (
  params?: IAccessibilityParams
): UseQueryResult<IResponse<IAccessibility[]>> => {
  return useQuery(
    ["get-accessibilitys", params],
    async () => {
      const { wait } = params || {};
      if (wait) return;
      const res = await axios.get("/accessibility", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetAccessibility = (
  id?: string | number
): UseQueryResult<IAccessibility> => {
  return useQuery(["get-accessibility", id], async () => {
    if (!id) return;
    const res = await axios.get(`/accessibility/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

// export const usePatchAccessibility = (): UseMutationResult => {
//   return useMutation(async (data) => {
//     const res = await axios.patch(``, data);
//     if (res.status === 200) {
//       return;
//     }
//     throwResponse(res);
//   });
// };

export const useDeleteAccessibility = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/accessibility/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteAccessibilityBulk = () => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/accessibility/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetAccessibilityInGroup = (
  params?: IAccessibilityParams
): UseQueryResult<IResponse<IAccessibilityInGroup[]>> => {
  return useQuery(
    ["get-in-group-accessibility", params],
    async () => {
      const res = await axios.get("/accessibility/in-group", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetAccessibilityNotInGroup = (
  params?: IAccessibilityNotInGroupParams
): UseQueryResult<IResponse<IAccessibilityNotInGroup[]>> => {
  return useQuery(
    ["get-not-in-group-accessibility", params],
    async () => {
      const res = await axios.get("/accessibility/not-in-group", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostAddPersonAccess = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post("/accessibility/add-person-to-access", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchRemovePersonInAccess = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.patch(
      `/accessibility/remove-person-in-access`,
      data
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePostAccessFromHCP = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post(
      "/accessibility/generate-access-level-from-hcp",
      data
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
