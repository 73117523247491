import { AimOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

interface PleaseSelectDataPageProps {
  height?: string | number;
  icon?: boolean;
}

const PleaseSelectDataPage: React.FC<PleaseSelectDataPageProps> = ({
  height = "100%",
  icon = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className="center flex-col" style={{ height }}>
      {icon ? (
        <AimOutlined
          className="!text-primary-dark"
          style={{ height: 40, width: 40, fontSize: 40 }}
        />
      ) : null}
      <Typography.Text className="!mt-3">
        {t("please-select-data", { ns: "message" })}
      </Typography.Text>
    </div>
  );
};

export default PleaseSelectDataPage;
