import { useQueryClient } from "@tanstack/react-query";
import { Col, Form, Row, Typography } from "antd";
import CDrawer from "components/display/c-drawer";
import CCard from "components/display/card";
import CInput from "components/input/c-input";
import CInputColor from "components/input/c-input-color";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import withFaceComparisonProvider, {
  useFaceComparison,
} from "provider/face-comparison";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteFaceCompareGroup,
  useGetFaceCompareGroups,
  useGetFaceCompareGroup,
  usePatchFaceCompareGroup,
  usePostFaceCompareGroup,
} from "services/face-compare-group";
import {
  IFaceCompareGroup,
  IFaceCompareGroupParams,
} from "services/face-compare-group/interface";
import FaceComparisonContent from "./content";

const FaceComparisonPage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <Sidebar />
        </Col>
        <Col span={18} className="p-5" style={{ height:"calc(100vh - 200px)", overflowY: "auto" }}>
          <FaceComparisonContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const qClient = useQueryClient();
  const { faceComparison, setFaceComparison, visible, toggle, setAction } =
    useFaceComparison();

  const [params, setParams] = useState<IFaceCompareGroupParams>({});
  const { data } = useGetFaceCompareGroups(params);
  const delete_face_group = useDeleteFaceCompareGroup();

  const { setAppLoading } = useAppLoading();

  const content = () => {
    return data?.data.map((item: IFaceCompareGroup) => {
      return (
        <CCard.SlashHeader
          active={faceComparison === item}
          className="mb-4"
          title={item.name}
          key={item?.id}
          onClick={() => {
            setFaceComparison(item);
          }}
        >
          <Row
            align="middle"
            className="px-3 py-2"
            style={{ height: 86 }}
            gutter={12}
          >
            <Col>
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t("list") }}
              >
                {t("list")} :
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>{`${item?.lengthFaceInformations}  ${t(
                "list"
              )}`}</Typography.Text>
            </Col>
            <Col span={24}></Col>
            <Col>
              <Typography.Text type="secondary">{t("color")} :</Typography.Text>
            </Col>
            <Col className="center">
              <div
                className="inline-block rounded-app"
                style={{
                  width: 15,
                  height: 15,
                  backgroundColor: `${item.color}`,
                }}
              ></div>
            </Col>
            <Col>
              <Typography.Text type="secondary" style={{ fontSize: 10 }}>
                {item.color}
              </Typography.Text>
            </Col>
          </Row>
        </CCard.SlashHeader>
      );
    });
  };

  const onDelete = async () => {
    const confirm = await fireConfirmDelete();
    if (confirm) {
      delete_face_group.mutate(
        { id: faceComparison?.id },
        {
          onSuccess: () => {
            qClient.invalidateQueries(["get-face-compare-group"]);
            qClient.invalidateQueries(["get-face-compare-group-id"]);
            fireNotification({ type: "success", menu: "access-control" });
            setFaceComparison(undefined);
          },
          onError: ({ message }: any) => {
            fireNotification({
              type: "error",
              description: message,
              menu: "access-control",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    }
  };

  return (
    <React.Fragment>
      <DrawerAddFaceCompareGroup visible={visible} toggle={toggle} />
      <InfiniteSidebar
        title={t("face-comparison-group")}
        actionButtonGroup={{
          onAdd: () => {
            setAction?.("add");
            toggle();
            setFaceComparison(undefined);
          },
          onEdit: () => {
            setAction?.("edit");
            toggle();
          },
          onDelete: onDelete,
          hide: { edit: !faceComparison, del: !faceComparison },
        }}
        infiniteScroll={{
          render: content,
          className: "px-5",
          search: {
            onChange: (e) => {
              setParams({ ...params, name: e });
            },
          },
        }}
      />
    </React.Fragment>
  );
};

const DrawerAddFaceCompareGroup = ({
  visible,
  toggle,
}: {
  visible: boolean;
  toggle: () => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const qClient = useQueryClient();
  const { faceComparison, action } = useFaceComparison();
  const id = faceComparison?.id;

  const { data } = useGetFaceCompareGroup(id);
  const post_face_group = usePostFaceCompareGroup();
  const patch_face_group = usePatchFaceCompareGroup();

  const { setAppLoading } = useAppLoading();

  useEffect(() => {
    initail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, visible]);

  const initail = () => {
    if (action === "add") {
      form.resetFields();
    }
    if (data && action === "edit") {
      form.setFieldsValue({
        ...data,
        color: data?.color ? data?.color : undefined,
      });
    }
  };

  const onSave = (value: any) => {
    if (id) {
      patch_face_group.mutate(
        { id: faceComparison?.id, ...value },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: "access-control" });
            qClient.invalidateQueries(["get-face-compare-group"]);
            qClient.invalidateQueries(["get-face-compare-group-id"]);
            toggle();
          },
          onError: ({ message }: any) => {
            fireNotification({
              type: "error",
              description: message,
              menu: "access-control",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    } else {
      post_face_group.mutate(value, {
        onSuccess: () => {
          fireNotification({ type: "success", menu:"access-control" });
          qClient.invalidateQueries(["get-face-compare-group"]);
          qClient.invalidateQueries(["get-face-compare-group-id"]);
          toggle();
        },
        onError: ({ message }: any) => {
          fireNotification({ type: "error", description: message , menu:"access-control"});
        },
        onSettled: () => {
          setAppLoading(false);
        },
      });
    }
  };

  const onClose = () => {
    toggle();
  };

  const title = action === "add" ? "add-group" : "edit-group";
  const loading = patch_face_group.isLoading || post_face_group.isLoading;

  return (
    <CDrawer
      title={t(title)}
      form={form}
      width="40vw"
      visible={visible}
      onCancel={onClose}
      onClose={onClose}
      onSave={onSave}
      loading={loading}
    >
      <Form form={form} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("face-comparison-group")}
              name="name"
              rules={[{ required: true }]}
            >
              <CInput />
            </Form.Item>
          </Col>
          <Col span={3} className="py-1">
            <Typography.Text>{t("status")}</Typography.Text>
          </Col>
          <Col span={21}>
            <Form.Item name="color">
              <CInputColor />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CDrawer>
  );
};

export default withFaceComparisonProvider(FaceComparisonPage);
