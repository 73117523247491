import { throwResponse } from "./../../config/axios/index";
import { IResponse } from "./../../config/axios/interface";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { IUser, IUserParams } from "./interface";
import axios from "axios";
import { postMediaObject } from "services/media-object";

export const useGetUsers = (
  params?: IUserParams
): UseQueryResult<IResponse<IUser[]>> => {
  return useQuery(
    ["get-users", params],
    async () => {
      const res = await axios.get("/user", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetScrollUsers = (
  params?: IUserParams
): UseInfiniteQueryResult<IResponse<IUser[]>, Error> => {
  return useInfiniteQuery(
    ["get-users", params],
    async ({ pageParam }) => {
      const res = await axios.get("/user", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetUser = (id?: number | string): UseQueryResult<IUser> => {
  return useQuery(
    ["get-user", id],
    async () => {
      const res = await axios.get(`/user/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostUser = (): UseMutationResult => {
  return useMutation(async ({ profile, ...data }: any) => {
    if (typeof profile === "object") {
      profile = await postMediaObject(profile);
      data = { ...data, profile };
    }
    const res = await axios.post("/user", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchUser = (): UseMutationResult => {
  return useMutation(async ({ id, profile, ...data }: any) => {
    if (typeof profile === "object") {
      profile = await postMediaObject(profile);
      data = { ...data, profile };
    }
    const res = await axios.patch(`/user/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteUser = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/user/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteUsers = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.delete(`/user/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportTemplateUsers = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/template/user`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportExcelUsers = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/user/import/user`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportProfileUsers = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    data = await appendImages(data);
    const res = await axios.post("/user/upload-profile", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

const appendImages = async (values: any): Promise<any> => {
  let { profiles, ...rest } = values || {};

  if (profiles?.length < 1) {
    rest = { ...rest, profiles: [] };
  }

  if (profiles && profiles?.length > 0) {
    const itemPicturesList: number[] = await Promise.all(
      profiles.map(async (item: any): Promise<number> => {
        let id: number;
        if (!item.id) {
          id = await postMediaObject(item);
        } else {
          id = item.id;
        }
        return id;
      })
    );
    rest = { ...rest, profiles: itemPicturesList };
  }

  return rest;
};