import UserWithPDPACreateAndEditPage from "components/menu/user-management/create-user-pdpa";
import { usePageRoute } from "provider/page-route";
import {
  useGetReceiver,
  usePatchReceiver,
  usePostReceiver,
} from "services/receiver";

const UserConsigneeCreateAndEditPage = () => {
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const post_receiver = usePostReceiver();
  const patch_receiver = usePatchReceiver();
  const data = useGetReceiver(id);

  return (
    <UserWithPDPACreateAndEditPage
      menu="receiver"
      action={{
        get: data,
        post: post_receiver,
        patch: patch_receiver,
        invalidateQueries: ["get-receivers", "get-receiver"],
      }}
    />
  );
};

export default UserConsigneeCreateAndEditPage;
