import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IDoor, IDoorParams } from "./interface";

export const useGetDoors = (
  params?: IDoorParams
): UseQueryResult<IResponse<IDoor[]>> => {
  return useQuery(
    ["get-doors", params],
    async () => {
      const res = await axios.get("/door", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetDoor = (id?: string | number): UseQueryResult<IDoor> => {
  return useQuery(
    ["get-door", id],
    async () => {
      const res = await axios.get(`/door/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const usePostGetDoor = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.post("/door/get-door", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePostUpdateDoor = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.post("/door/update/door-state", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
