import { Checkbox, Col, Form, Row, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import CInput from "components/input/c-input";
import CRangePicker from "components/input/c-range-picker";
import CSelect from "components/input/c-select";
import CSelectJobPosition from "components/input/c-select/job-position";
import dayjs from "dayjs";
import { useUserReport } from "provider/user-report";
import React from "react";
import { useTranslation } from "react-i18next";

const SideBarEmployee = () => {
  const { t } = useTranslation();
  const { columnsKey, setColumnsKey, setSearch, search } = useUserReport();

  const option = [

    {
      key: "date",
      title: t("entry-date"),
    },
    {
      key: "identityCode",
      title: t("employee-id"),
    },
    {
      key: "name",
      title: t("full-name"),
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
    },
    {
      key: "email",
      title: t("email"),
    },
    {
      key: "position",
      title: t("job-position"),
    },
    {
      key: "licensePlateNumber",
      title: t("license-plate"),
    },
    {
      key: "vehicleBrand",
      title: t("vehicle-brand"),
    },
    {
      key: "vehicleModel",
      title: t("vehicle-series"),
    },
    {
      key: "startDate",
      title: t("start-date"),
    },

    {
      key: "identificationNumber",
      title: t("identification-card-number"),
    },
    {
      key: "prefix",
      title: t("name-title"),
    },
    {
      key: "cardCode",
      title: t("swipe-card-code"),
    },
    {
      key: "department.titleTh",
      title: t("department"),
    },
  ];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setColumnsKey(checkedValues as string[]);
  };

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item label={t("entry-date")} name="date">
              <CRangePicker
                onChange={(e) => {
                  const sDate = e
                    ? dayjs(e?.[0]).startOf("day").toISOString()
                    : undefined;
                  const eDate = e
                    ? dayjs(e?.[1])?.endOf("day").toISOString()
                    : undefined;
                  setSearch({ ...search, startDate: sDate, endDate: eDate });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="name" label={t("full-name")}>
              <CInput
                placeholder={t("full-name")}
                onChange={(e) => {
                  const name = e.target.value ? e.target.value : undefined;
                  setSearch({ ...search, name: name });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="phoneNumber" label={t("phone-number")}>
              <CSelect
                allowClear
                open={false}
                placeholder={t("phone-number")}
                mode="tags"
                maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, phoneNumber: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="email" label={t("email")}>
              <CSelect
                allowClear
                open={false}
                placeholder={t("email")}
                mode="tags"
                maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, email: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="position" label={t("job-position")}>
              <CSelectJobPosition
                mode="tags"
                onChange={(e) => {
                  setSearch({ ...search, position: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="licensePlateNumber" label={t("license-plate")}>
              <CSelect
                allowClear
                placeholder={t("license-plate")}
                mode="tags"
                maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, licensePlateNumber: e });
                }}
                open={false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="vehicleBrand" label={t("vehicle-brand")}>
              <CSelect.VehicleBrand
                allowClear
                mode="tags"
                maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, vehicleBrand: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="vehicleModel" label={t("vehicle-series")}>
              <CSelect
                allowClear
                placeholder={t("vehicle-series")}
                mode="tags"
                maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, vehicleModel: e });
                }}
                open={false}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="mb-4">
            <Typography.Title level={5}>
              {t("required-information")}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onChange}
              defaultValue={columnsKey}
            >
              <Row gutter={[0, 16]}>
                {option?.map((item, index) => {
                  return (
                    <Col span={24} key={index}>
                      <Checkbox value={item?.key}>{t(item?.title)}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default SideBarEmployee;
