import { usePageRoute } from "provider/page-route";
import {
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IFloor } from "services/floor/interface";
import { IParking } from "services/parking/interface";
import { IVehicleFloorContext } from "./interface";

const Context = createContext<IVehicleFloorContext>({} as IVehicleFloorContext);

const withVehicleFloorProvider = (Component: ComponentType) => {
  return () => {
    const [selectedFloor, setSelectedFloor] = useState<IFloor>();
    const [selectedParking, setSelectedParking] = useState<IParking>();

    const { getCurrentParams } = usePageRoute();
    const { id, levelName, levelNumber, topLevel } = getCurrentParams();

    useEffect(() => {
      setSelectedFloor(undefined);
      setSelectedParking(undefined);
    }, [id]);

    return (
      <Context.Provider
        value={{
          levelId: id,
          levelNumber: levelNumber,
          levelName: levelName,
          topLevel: topLevel,
          selectedFloor,
          selectedParking,
          setSelectedFloor,
          setSelectedParking,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useVehicleFloor = () => useContext(Context);
export default withVehicleFloorProvider;
