import { throwResponse } from "./../../config/axios/index";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { IVehicleBlackList, IVehicleBlackListParams } from "./interface";
import { IResponse } from "config/axios/interface";
import { postMediaObject } from "services/media-object";

export const useGetVehicleBlackLists = (
  params?: IVehicleBlackListParams
): UseQueryResult<IResponse<IVehicleBlackList[]>> => {
  return useQuery(
    ["vehicle-black-list", "get-all", params],
    async () => {
      const res = await axios.get(`/vehicle-blacklist`, { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
      enabled: params?.enabled,
    }
  );
};

export const useGetVehicleBlackList = (
  id?: string | number
): UseQueryResult<IVehicleBlackList> => {
  return useQuery(
    ["vehicle-black-list", "get-one", id],
    async () => {
      const res = await axios.get(`/vehicle-blacklist/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostVehicleBlackList = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ vehicleImage, ...data }: any) => {
    if (typeof vehicleImage === "object") {
      const id = await postMediaObject(vehicleImage);
      data = { ...data, vehicleImage: id };
    }
    const res = await axios.post(`/vehicle-blacklist`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchVehicleBlackList = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, vehicleImage, ...data }: any) => {
    // if (typeof vehicleImage === "object") {
    //   const id = await postMediaObject(vehicleImage);
    //   data = { ...data, vehicleImage: id };
    // }
    const res = await axios.patch(`/vehicle-blacklist/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteVehicleBlackList = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/vehicle-blacklist/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteVehicleBlackLists = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.delete(`/vehicle-blacklist/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportExcelVehicleBlackList = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      "/export-excel/vehicle/blacklist-report-excel",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
