import { Badge, Col, Divider, Row, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import CardHomeList, { IconHomeStlye } from "components/home/card-list-home";
import { IdcardFilled, PieChartFilled, UndoOutlined } from "@ant-design/icons";
import PieChartHome from "components/home/pie-chart-home";
import { MenuSchedule } from "assets/icons/general";
import LineChartHome, { LabelStyle } from "components/home/line-chart-home";
import ColumnChartHome from "components/home/column-chart-home";
import CardHomeData from "components/home/card-data-home";
import CImage from "components/display/c-image";
import CRangePicker from "components/input/c-range-picker";
import dayjs from "dayjs";
import { useAppLoading } from "provider/app-loading";
import CButton from "components/button/c-button";
import {
  IAccessDashboard,
  IDashboardParams,
  IGraphInOutDoor,
} from "services/dashboard/interface";
import { useGetAccossDashboards } from "services/dashboard";
import { useAppLanguage } from "provider/app-language";
import CEmpty from "components/display/c-empty";
import tw from "twin.macro";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayoutStyle = styled.div`
  ${tw`rounded-app`}
  background-color: #fff;
  box-shadow: 0px 1px 6px #0000000d;
`;

const TextStyle = styled.span`
  color: #9a9a9a;
  font-size: 18px;
`;

function getFromLS(key: string) {
  let ls = [];
  if (localStorage) {
    try {
      const lc = localStorage.getItem("access-layout");
      ls = JSON.parse(lc || "[]") || {};
    } catch (e) {}
  }
  return ls[key];
}

function saveToLS(key: string, value: any) {
  if (localStorage) {
    localStorage.setItem(
      "access-layout",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

const originalLayouts = getFromLS("layouts") || {};

const sDate = dayjs().startOf("month").toISOString();
const eDate = dayjs().endOf("month").toISOString();

const AccessControlHomePage = () => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    layouts: JSON.parse(JSON.stringify(originalLayouts)),
  });

  const [params, setParams] = useState<IDashboardParams>({
    startDate: sDate,
    endDate: eDate,
  });
  const { data } = useGetAccossDashboards(params);
  const { setAppLoading } = useAppLoading();

  const [startDate, setStartDate] = useState<string>(sDate);
  const [endDate, setEndDate] = useState<string>(eDate);

  const onLayoutChange = (layout: any, layouts: any) => {
    saveToLS("layouts", layouts);
    setState({ layouts });
  };

  const onSearch = (values: any) => {
    const startDate = values
      ? dayjs(values[0])?.startOf("day").toISOString()
      : sDate;
    const endDate = values
      ? dayjs(values[1])?.endOf("day").toISOString()
      : eDate;
    values = { ...values, startDate, endDate };
    setStartDate(startDate);
    setEndDate(endDate);
    setParams({ ...params, ...values });
  };

  const refreshPage = () => {
    setAppLoading(true);
    window.location.reload();
  };

  const onReset = () => {
    setState({ layouts: [] });
    setAppLoading(true);
    window.location.reload();
  };

  const innerHeight = window.innerHeight;
  const layout =
    innerHeight > 929
      ? Number(((innerHeight - 64 - 90 - 70) / 4).toFixed(0))
      : Number(((929 - 64 - 90 - 70) / 4).toFixed(0));

  return (
    <div className="w-full h-full">
      <Row className="h-[7%] mb-2">
        <Col span={13} className="pl-3 !flex !items-center">
          <Typography.Title level={4} style={{ color: "#425F4E" }}>
            {t("accessibility")}
          </Typography.Title>
        </Col>
        <Col span={6} className="!flex !items-center">
          <CRangePicker onChange={onSearch} />
        </Col>
        <Col span={3} className="px-2 !flex !items-center">
          <CButton
            theme="yellow"
            children={t("save-to-change")}
            onClick={refreshPage}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={2} className="!flex !items-center">
          <CButton
            children={t("reset")}
            icon={<UndoOutlined />}
            onClick={onReset}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row className="h-[93%]">
        <Col span={24}>
          <ResponsiveReactGridLayout
            layouts={state.layouts}
            onLayoutChange={onLayoutChange}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            style={{ height: "100%" }}
            rowHeight={layout}
            className="layout"
          >
            <GridLayoutStyle
              key="total-access-control"
              data-grid={{ x: 0, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <TotalOfAccessControls
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="access-control-with-card"
              data-grid={{ x: 3, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                title="access-control-with-card"
                theme="green"
                value={data?.totalAllDoorLogIDCard}
                valueType="count-time"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="access-control-with-face"
              data-grid={{ x: 6, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                title="access-control-with-face"
                theme="yellow"
                value={data?.totalAllDoorLogFaceRecognition}
                valueType="count-time"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="access-control-with-fingerprint"
              data-grid={{ x: 9, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                title="access-control-with-fingerprint"
                theme="yellow"
                value={data?.totalAllDoorLogFingerPrint}
                valueType="count-time"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="count-access-in-period"
              data-grid={{ x: 0, y: 1, w: 6, h: 3, maxH: 3, minH: 3, minW: 6 }}
            >
              <CountOfAccessInPeriod
                data={data?.graphInOutDoor || []}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="most-face-access"
              data-grid={{ x: 6, y: 1, w: 3, h: 2, maxH: 2, minH: 2, minW: 3 }}
            >
              <MostFaceAccess
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="most-door-access"
              data-grid={{ x: 6, y: 2, w: 3, h: 2, maxH: 2, minH: 2, minW: 3 }}
            >
              <MostDoorAccess
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="access-of-user-type"
              data-grid={{ x: 0, y: 2, w: 6, h: 1, maxH: 1, minW: 6 }}
            >
              <AccessOfUserType
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="most-person-access"
              data-grid={{ x: 9, y: 2, w: 3, h: 4, maxH: 4, minH: 4, minW: 3 }}
            >
              <MostUserAccess
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
          </ResponsiveReactGridLayout>
        </Col>
      </Row>
    </div>
  );
};

const TotalOfAccessControls = ({
  data,
  startDate,
  endDate,
}: {
  data?: IAccessDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const card = Number(data?.totalAllDoorLogIDCard || 0);
  const face = Number(data?.totalAllDoorLogFaceRecognition || 0);
  const fingerprint = Number(data?.totalAllDoorLogFingerPrint || 0);
  const sum = Number(card + face + fingerprint).toLocaleString();
  return (
    <Row className="p-5 h-full">
      <Col span={3} className="center">
        <IconHomeStlye justify="center" align="middle" $color="green">
          <PieChartFilled style={{ fontSize: "24px", color: "#425F4E" }} />
        </IconHomeStlye>
      </Col>
      <Col span={21} className="!flex !items-center">
        <Typography.Text
          ellipsis
          className="font-bold px-2"
          style={{ fontSize: "20px" }}
        >
          {t("all-access-control")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <PieChartHome
          height={80}
          series={[card, face, fingerprint]}
          colors={["#425F4E", "#438860", "#BF9C3D"]}
          labels={[]}
        />
      </Col>
      <Col span={12} className="text-end py-2">
        <Typography.Title ellipsis level={2} style={{ color: "#425F4E" }}>
          {sum}
        </Typography.Title>
      </Col>
      <Col span={4} className="!flex !items-center px-2">
        <Typography.Text ellipsis>{t("count-time")}</Typography.Text>
      </Col>
      <Col span={2} className="center">
        <MenuSchedule className="w-[22px] h-[22px]" />
      </Col>
      <Col span={7} className="px-2 center">
        <Typography.Text
          ellipsis={{
            tooltip: `${formatDate({
              date: startDate,
              format: "normal",
            })} - ${formatDate({ date: endDate, format: "normal" })}`,
          }}
          style={{ color: "#9A9A9A", fontSize: "18px" }}
        >
          {`${formatDate({ date: startDate, format: "normal" })} - ${formatDate(
            { date: endDate, format: "normal" }
          )}`}
        </Typography.Text>
      </Col>
      <Col span={4} className="center">
        <Badge color="#425F4E" text={<TextStyle>{t("card")}</TextStyle>} />
      </Col>
      <Col span={5} className="center">
        <Badge color="#438860" text={<TextStyle>{t("face")}</TextStyle>} />
      </Col>
      <Col span={6} className="center">
        <Badge
          color="#BF9C3D"
          text={<TextStyle>{t("fingerprint")}</TextStyle>}
        />
      </Col>
    </Row>
  );
};

const CountOfAccessInPeriod = ({
  data,
  startDate,
  endDate,
}: {
  data?: IGraphInOutDoor[];
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const getCategory = () => {
    return Array(24)
      .fill(undefined)
      .map((_, i) => i++)
      .filter((e) => !(e % 2));
  };

  const categories = getCategory().map((e) =>
    e >= 10 ? `${e}:00` : `0${e}:00`
  );

  const getHour = (hour: string) => {
    return dayjs(hour, "HH:mm:ssZZ").hour();
  };

  const doorIn = () => {
    const values = getCategory()?.map((item) => {
      const count = data?.find((e) => getHour(e?.timeInterval) === item);
      const count_2 = data?.find((e) => getHour(e?.timeInterval) === item - 1);
      if (count_2)
        return (
          Number(count?.totalInDoorLog?.count || 0) +
          Number(count_2?.totalInDoorLog?.count || 0)
        );
      if (count) return Number(count?.totalInDoorLog?.count || 0);
      return 0;
    });

    return values;
  };

  const doorOut = () => {
    const values = getCategory()?.map((item) => {
      const count = data?.find((e) => getHour(e?.timeInterval) === item);
      const count_2 = data?.find((e) => getHour(e?.timeInterval) === item - 1);
      if (count_2)
        return (
          Number(count?.totalOutDoorLog?.count || 0) +
          Number(count_2?.totalOutDoorLog?.count || 0)
        );
      if (count) return Number(count?.totalOutDoorLog?.count || 0);
      return 0;
    });

    return values;
  };

  return (
    <Row className="p-5 h-full">
      <Col span={24}>
        <Typography.Text
          ellipsis
          className="font-bold"
          style={{ fontSize: "20px" }}
        >
          {t("count-of-access-period")}
        </Typography.Text>
      </Col>
      <Col span={1} className="center">
        <MenuSchedule className="w-[22px] h-[22px]" />
      </Col>
      <Col span={14} className="text-start">
        <span style={{ color: "#9A9A9A", fontSize: "18px" }}>
          {`${formatDate({ date: startDate, format: "normal" })} - ${formatDate(
            { date: endDate, format: "normal" }
          )}`}
        </span>
      </Col>
      <Col span={4} className="!flex !justify-end">
        <LabelStyle color="#438860" text={t("entrance")} />
      </Col>
      <Col span={4} className="!flex !justify-end">
        <LabelStyle color="#BF9C3D" text={t("departure")} />
      </Col>
      <Col span={24}>
        <LineChartHome
          height={450}
          categories={categories}
          series={[
            {
              name: t("entrance"),
              data: doorIn(),
            },
            {
              name: t("departure"),
              data: doorOut(),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

const MostFaceAccess = ({
  data,
  startDate,
  endDate,
}: {
  data?: IAccessDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const categories = data?.totalAllDoorLogFaceOften?.map((item) => {
    const firstName = item?.doorLog_first_name || "";
    const lastName = item?.doorLog_last_name || "";
    const fullName = [firstName, lastName];
    if (!firstName && !lastName) return "";
    return fullName;
  });

  const customerEmployee = data?.totalAllDoorLogFaceOften?.map((item) => {
    const count = item?.customerEmployee;
    return Number(count || 0);
  });

  return (
    <Row className="p-5 h-full">
      <Col span={24}>
        <Typography.Text
          ellipsis
          className="font-bold"
          style={{ fontSize: "20px" }}
        >
          {t("most-4-face-access")}
        </Typography.Text>
      </Col>
      <Col span={2} className="center">
        <MenuSchedule className="w-[22px] h-[22px]" />
      </Col>
      <Col className="text-start">
        <span style={{ color: "#9A9A9A", fontSize: "18px" }}>
          {`${formatDate({ date: startDate, format: "normal" })} - ${formatDate(
            { date: endDate, format: "normal" }
          )}`}
        </span>
      </Col>
      <Col span={24}>
        <ColumnChartHome
          height={270}
          categories={categories}
          series={[
            { name: t("number-of-accessibility"), data: customerEmployee },
          ]}
        />
      </Col>
    </Row>
  );
};

const MostDoorAccess = ({
  data,
  startDate,
  endDate,
}: {
  data?: IAccessDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const categories = data?.totalAllDoorLogDoorOften?.map((item) => {
    const firstName = item?.doorLog_first_name || "";
    const lastName = item?.doorLog_last_name || "";
    const fullName = [firstName, lastName];
    if (!firstName && !lastName) return "";
    return fullName;
  });

  const doorCount = data?.totalAllDoorLogDoorOften?.map((item) => {
    return Number(item?.door || 0);
  });

  return (
    <Row className="p-5 h-full">
      <Col span={24}>
        <Typography.Text
          ellipsis
          className="font-bold"
          style={{ fontSize: "20px" }}
        >
          {t("most-4-door-access")}
        </Typography.Text>
      </Col>
      <Col span={2} className="center">
        <MenuSchedule className="w-[22px] h-[22px]" />
      </Col>
      <Col className="text-start">
        <span style={{ color: "#9A9A9A", fontSize: "18px" }}>
          {`${formatDate({ date: startDate, format: "normal" })} - ${formatDate(
            { date: endDate, format: "normal" }
          )}`}
        </span>
      </Col>
      <Col span={24}>
        <ColumnChartHome
          height={270}
          categories={categories}
          series={[{ name: t("number-of-accessibility"), data: doorCount }]}
        />
      </Col>
    </Row>
  );
};

const AccessOfUserType = ({
  data,
  startDate,
  endDate,
}: {
  data?: IAccessDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const guest = Number(data?.totalAllDoorLogPersonType?.[0]?.totalVisitor) || 0;
  const contractor =
    Number(data?.totalAllDoorLogPersonType?.[0]?.totalContractor) || 0;
  const sum = Number(guest + contractor).toLocaleString();
  return (
    <CardHomeData
      title="user-type-access"
      startDate={startDate}
      endDate={endDate}
    >
      <Row className="h-full">
        <Col span={6}>
          <PieChartHome
            height={95}
            series={[guest, contractor]}
            colors={["#BF9C3D", "#438860"]}
            labels={[t("guests"), t("contractor-full-time")]}
          />
        </Col>
        <Col span={8} className="!flex !items-center center">
          <Typography.Title level={2}>
            {`${sum}  ${t("count-time")}`}
          </Typography.Title>
        </Col>
        <Col span={10}>
          <Row gutter={[0, 6]}>
            <Col span={4}>
              <IconHomeStlye justify="center" align="middle" $color="yellow">
                <IdcardFilled style={{ fontSize: "18px", color: "#BF9C3D" }} />
              </IconHomeStlye>
            </Col>
            <Col span={14} className="!flex !items-center">
              <Typography.Text style={{ color: "#9A9A9A" }}>
                {t("guests")}
              </Typography.Text>
            </Col>
            <Col span={6} className="text-end">
              <Typography.Title level={4} style={{ color: "#BF9C3D" }}>
                {`${Number(guest).toLocaleString()}  ${t("count-time")}`}
              </Typography.Title>
            </Col>
            <Col span={4}>
              <IconHomeStlye justify="center" align="middle" $color="green">
                <IdcardFilled style={{ fontSize: "18px", color: "#438860" }} />
              </IconHomeStlye>
            </Col>
            <Col span={14} className="!flex !items-center">
              <Typography.Text ellipsis style={{ color: "#9A9A9A" }}>
                {t("contractor-full-time")}
              </Typography.Text>
            </Col>
            <Col span={6} className="text-end">
              <Typography.Title level={4} style={{ color: "#438860" }}>
                {`${Number(contractor).toLocaleString()}  ${t("count-time")}`}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardHomeData>
  );
};

const MostUserAccess = ({
  data,
  startDate,
  endDate,
}: {
  data?: IAccessDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  return (
    <CardHomeData
      title="most-7-person-access"
      startDate={startDate}
      endDate={endDate}
    >
      <div className="h-full">
        {data?.totalAllDoorLogOften?.length === 0 ? (
          <div style={{ height: 570 }} className="center">
            <CEmpty />
          </div>
        ) : (
          <>
            {data?.totalAllDoorLogOften?.map((item, index) => {
              const firstName = item?.doorLog_first_name || "";
              const lastName = item?.doorLog_last_name || "";
              return (
                <Row key={index} gutter={[12, 12]} className="my-3">
                  <Col span={6}>
                    <CImage src="" />
                  </Col>
                  <Col span={10}>
                    {!firstName && !lastName ? (
                      <span>-</span>
                    ) : (
                      <Typography.Text ellipsis style={{ fontSize: "14px" }}>
                        {`${t(firstName)}  ${t(lastName)}`}
                      </Typography.Text>
                    )}
                    <br />
                    <Typography.Text
                      style={{ fontSize: "14px", color: "#9A9A9A" }}
                    >
                      {t("ไม่มีข้อมูล")}
                    </Typography.Text>
                  </Col>
                  <Col span={4} className="text-end">
                    <Typography.Title level={5} style={{ color: "#438860" }}>
                      {Number(item?.customerEmployee || 0).toLocaleString()}
                    </Typography.Title>
                  </Col>
                  <Col span={4}>
                    <Typography.Text
                      style={{ fontSize: "14px", color: "#9A9A9A" }}
                    >
                      {t("count-time")}
                    </Typography.Text>
                  </Col>
                  <Divider className="!m-0" />
                </Row>
              );
            })}
          </>
        )}
      </div>
    </CardHomeData>
  );
};

export default AccessControlHomePage;
