import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetRoles } from "services/role";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

export interface CSelectRoleProps extends CSelectProps {
  isActive?: boolean;
}

const CSelectRole: FC<CSelectRoleProps> = ({ isActive, ...props }) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetRoles}
      valueKey="id"
      titleKey="title"
      searchKey="title"
      placeholder={t("role")}
      queryParams={{
        isActive: typeof isActive === "undefined" ? true : isActive,
      }}
      translation
    />
  );
};

export default CSelectRole;
