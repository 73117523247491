import { TreeSelect, TreeSelectProps } from "antd";
import { FC } from "react";
import styled from "styled-components";

const StyledTreeSelect = styled(TreeSelect)`
  width: 100%;
  height: 45px;

  .ant-select-selector {
    height: 100% !important;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    margin: auto;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
     input {
       margin: auto;
     }
   }
`;

interface CTreeSelectProps extends TreeSelectProps {
  hidden?: boolean;
}

const CTreeSelect: FC<CTreeSelectProps> = ({ hidden, ...props }) => {
  if (hidden) return null;
  return <StyledTreeSelect {...props} />;
};

export default CTreeSelect;
