import { Col, Divider, Row, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ParkingLineChartCard from "../../../../components/parking-management/over-view/line-chart-card";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { IVehicleReportParams } from "../../../../services/vehicle-report/interface";

import {
  EXPORT_VEHICLE_TRAFFIC_REPORTS,
  useGETTrafficReport,
} from "services/vehicle-report";
import dayjs from "config/dayjs";
import TableActionExcelButton from "components/display/c-table/action-excel-button";
import { useGetVehicleTypes } from "services/setting-vehicle-type";

export const parkingVehicleColor = {
  carOrVisitor: "#32B1F2",
  motorcycleOrMember: "#E0B33C",
  officer: "#FBB819",
};

const CardWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid #e7e7e7;
`;

const SDate = dayjs().startOf("day").toISOString();
const EDate = dayjs().endOf("day").toISOString();

const VehicleTrafficReport = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IVehicleReportParams>({
    startDate: SDate,
    endDate: EDate,
  });
  const { data } = useGETTrafficReport(params);
  const data_vehicleType = useGetVehicleTypes({ pagination: false });

  const getCategory = () => {
    return Array(24)
      .fill(undefined)
      .map((_, i) => i++)
      .filter((e) => !(e % 2));
  };

  const getCount = (type: "reportIn" | "reportOut"): number[] => {
    const values =
      getCategory().map((item) => {
        const count = data?.data[`${type}`].reduce((p, n) => {
          const timeInterval = dayjs(n.timeInterval, "HH:mm:ssZZ").hour();
          if (timeInterval === item) return p + Number(n.count);
          if (timeInterval - 1 === item) return p + Number(n.count);
          return p;
        }, 0);
        return count || 0;
      }) || [];

    values.pop();
    values.push(values[0]);

    return values;
  };

  const getStatistics = () => {
    const category = getCategory().map((e) =>
      e >= 10 ? `${e}:00` : `0${e}:00`
    );

    const entrance = getCount("reportIn");
    const departure = getCount("reportOut");

    return { category, entrance, departure };
  };

  const get_statistics = getStatistics();

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("date"),
      input: {
        type: "RangePicker",
        options: {
          search: true,
          getPopupContainer: (e: HTMLElement) => e,
        },
      },
    },
    {
      name: "vehicleType",
      label: t("car-type"),
      input: {
        type: "Select",
        options: {
          placeholder: t("car-type"),
          valueOptions: {
            values: data_vehicleType?.data?.data || [],
            valueKey: "vehicleTh",
            titleKey: "vehicleTh",
          },
        },
      },
    },
  ];

  const onSearch = (values: any) => {
    const startDate = values.date
      ? dayjs(values.date[0]).startOf("day").toISOString()
      : SDate;
    const endDate = values?.date
      ? dayjs(values.date[1]).endOf("day").toISOString()
      : EDate;
    values = { ...values, startDate, endDate };

    delete values.date;

    setParams({ ...params, ...values });
  };

  const getAverage = (statistic: number[]) => {
    const quantity = statistic.slice(0, -1).length;
    const total = statistic.slice(0, -1).reduce((p, n) => p + n, 0);
    return total / quantity;
  };

  const entrance = getAverage(get_statistics.entrance);
  const departure = getAverage(get_statistics.departure);

  return (
    <PageContent className="p-5  " breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <TrafficVolumeReport
        statistic={get_statistics}
        card={{
          total: data?.data.vehicleCount || 0,
          entrance: Math.round(entrance) || 0,
          departure: Math.round(departure) || 0,
        }}
      />
    </PageContent>
  );
};

const TrafficVolumeReport = ({
  statistic,
  card,
}: {
  statistic: { category: string[]; entrance: number[]; departure: number[] };
  card?: { total: number; entrance: number; departure: number };
}) => {
  const { t } = useTranslation();
  const export_vehicle_traffic = EXPORT_VEHICLE_TRAFFIC_REPORTS();

  return (
    <div className="!bg-white mt-4">
      <Row className="p-4" align="middle">
        <Col span={23} className="d-flex content-center">
          <Typography.Title level={4}>
            {t("vehicle-management.entrance-departure-traffic-volume-report", {
              ns: "menu",
            })}
          </Typography.Title>
        </Col>
        <Col span={1} className="d-flex justify-end">
          <TableActionExcelButton
            resetSelectRowKey={() => []}
            actions={{
              export: { mutation: export_vehicle_traffic },
            }}
          />
        </Col>
      </Row>
      <Divider className="m-0" />
      <TrafficVolumeStatistics statistic={statistic} />
      <Divider className="m-0" />
      <TotalVehicleAndAverageParkingTime
        entrance={card?.entrance}
        departure={card?.departure}
      />
    </div>
  );
};

const TrafficVolumeStatistics = ({
  statistic,
}: {
  statistic: { category: string[]; entrance: number[]; departure: number[] };
}) => {
  const { t } = useTranslation();

  return (
    <ParkingLineChartCard
      subTitle={t("number-of-vehicles")}
      categories={statistic?.category}
      series={[
        {
          name: t("entrance"),
          data: statistic.entrance,
        },
        {
          name: t("departure"),
          data: statistic.departure,
        },
      ]}
      labels={[
        { color: parkingVehicleColor.carOrVisitor, text: "entrance" },
        { color: parkingVehicleColor.motorcycleOrMember, text: "departure" },
      ]}
      color="primary"
    />
  );
};

const TotalVehicleAndAverageParkingTime = ({
  entrance,
  departure,
}: {
  entrance?: number;
  departure?: number;
}) => {
  return (
    <Row gutter={12} className="p-4 mx-4">
      <Col span={6}>
        <TotalCard label="entrance-2-hour-per-car" value={`${entrance || 0}`} />
      </Col>
      <Col span={6}>
        <TotalCard
          label="departure-2-hour-per-car"
          value={`${departure || 0}`}
        />
      </Col>
    </Row>
  );
};

const TotalCard = ({ label, value }: { label: string; value: string }) => {
  const { t } = useTranslation();

  return (
    <CardWrapper className="p-4">
      <span>{t(label)}</span>
      <Typography.Title level={4}>{value}</Typography.Title>
    </CardWrapper>
  );
};
export default VehicleTrafficReport;
