import { fireNotification } from "components/popup/notification";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { fileToDataUrl, resizeImageFileSize } from "tools/file";
import { Image, Row } from "antd";
import tw from "twin.macro";
import { UploadOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  ${tw`rounded-app`};
  height: 250px;
  background-color: #fafafa;
  cursor: pointer;
  border: 1px dotted #cccccc;
  overflow: hidden;
  ${tw`transition-all`};
  ${tw`hover:border-primary`};
`;

const accepts = {
  array: ["jpg", "jpeg", "png", "webp"],
  string: ".jpg,.jpeg,.png,.webp",
};

export interface UploadAreaProps {
  style?: CSSProperties;
  className?: string;
  onChange?: (v: any) => void;
  value?: string | File;
  readonly?: boolean;
}

const UploadArea: FC<UploadAreaProps> = ({
  style,
  className,
  onChange,
  value,
  readonly,
}) => {
  const { t } = useTranslation("menu");
  const ref = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState<undefined | string>();

  useEffect(() => {
    if (typeof value === "object") return;
    setImageUrl(value);
  }, [value]);

  const onPicked = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files?.length > 0) {
      try {
        const image = files[0];
        const extension = image.name.split(".").pop()?.toLocaleLowerCase();
        if (!extension || !accepts.array.includes(extension)) {
          throw new Error(t("only-jpg-jpeg-png-format-support"));
        }

        const base64 = await fileToDataUrl(image);
        if (typeof base64 !== "string") {
          throw new Error(t("error-occured"));
        }

        const resized = await resizeImageFileSize({
          file: image,
          maxHeight: 500,
          maxWidth: 500,
        });
        setImageUrl(base64);
        onChange?.(resized);
      } catch (err: any) {
        fireNotification({ type: "error", description: err?.message });
      }
    }
  };

  const clearPreviousValue = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const openPickFileDialog = () => {
    if (readonly) return;
    ref.current?.click();
  };
  return (
    <Wrapper onClick={openPickFileDialog} style={style} className={className}>
      {value ? (
        <Image
          className="!h-full !w-full"
          wrapperStyle={{ borderColor: "none" }}
          wrapperClassName="!h-full !w-full"
          style={{ objectFit: "contain" }}
          src={imageUrl}
          preview={false}
        />
      ) : null}
      <input
        accept={accepts.string}
        multiple={false}
        type="file"
        ref={ref}
        style={{ display: "none" }}
        onChange={onPicked}
        onClick={clearPreviousValue}
      />
      <Row style={{ height: "100%" }} className="center">
        <UploadOutlined style={{ fontSize: "75px", color: "#cccccc" }} />
      </Row>
    </Wrapper>
  );
};

export default UploadArea;
