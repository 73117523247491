import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IUserGroup,
  IUserGroupInGroup,
  IUserGroupInGroupParams,
  IUserGroupNotInGroup,
  IUserGroupNotInGroupParams,
  IUserGroupParams,
} from "./interface";

export const useGetUserGroupsrScroll = (
  params?: IUserGroupParams
): UseInfiniteQueryResult<IResponse<IUserGroup[]>, Error> => {
  return useInfiniteQuery(
    ["get-user-groups-scroll", params],
    async ({ pageParam }) => {
      const res = await axios.get("/user-group", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};

export const useGetUserGroups = (
  params?: IUserGroupParams
): UseQueryResult<IResponse<IUserGroup[]>> => {
  return useQuery(
    ["get-user-groups", params],
    async () => {
      const res = await axios.get("/user-group", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetUserGroup = (id?: number, type?: string): UseQueryResult<IUserGroup> => {
  return useQuery(["get-user-group", id], async () => {
    if (!id) return;
    console.log(type);
    const res = await axios.get(`/user-group/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
    // if(type == "DEFAULT") {
    //   console.log("SSSSS");
    //   const res = await axios.get(`/user-group/in-employee-group/${id}`);
    //   if (res.status === 200) {
    //     return res.data.data;
    //   }
    //   throwResponse(res);
    // } else {
    //   const res = await axios.get(`/user-group/${id}`);
    //   if (res.status === 200) {
    //     return res.data.data;
    //   }
    //   throwResponse(res);
    // }

  });
};


export const usePostUserGroup = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post("/user-group", data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchUserGroup = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/user-group/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteUserGroup = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/user-group/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetUserGroupNotInGroup = (
  params?: IUserGroupNotInGroupParams
): UseQueryResult<IResponse<IUserGroupNotInGroup[]>> => {
  return useQuery(
    ["get-user-group-not-in", params],
    async () => {
      const { wait } = params || {};
      if (wait) return;
      const res = await axios.get(
        `/user-group/not-in-group`,
        { params }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const usePostUserToGroup = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.post(`/user-group/add-user-to-group/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetGroupInGroup = (
  params?: IUserGroupInGroupParams,
): UseQueryResult<IResponse<IUserGroupInGroup[]>> => {
  return useQuery(
    ["get-user-group-in-group", params],
    async () => {

      if (params?.slug == "DEFAULT") {
        const res = await axios.get(`/user-group/in-employee-group/${params?.groupId}`);
        if (res.status === 200) {
          return res.data;
        }
        throwResponse(res);
      } else {
        const res = await axios.get(`/user-group/in-group/${params?.groupId}`, {
          params,
        });
        if (res.status === 200) {
          return res.data;
        }
        throwResponse(res);
      }

    },


    { keepPreviousData: true, enabled: !!params?.groupId }
  );
};




export const usePatchRemoveUser = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/user-group/remove-user/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
