import Icon, {
  DownOutlined,
  LoadingOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Row, Col, Typography, Popover, Grid } from "antd";
import { usePageRoute } from "provider/page-route";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IPageSidebarItem } from "./interface";
import tw from "twin.macro";
import { useNotification } from "provider/notification-app";
import numeral from "numeral";

interface WrapperProps {
  $active: boolean;
}

const DownArrow = styled(DownOutlined)`
  font-size: 12px;
  font-weight: bold;
`;
const UpArrow = styled(UpOutlined)`
  font-size: 12px;
  font-weight: bold;
`;
const PageSidebarItemContainer = styled(Row)<WrapperProps>`
  cursor: pointer;
  padding-left: 22px;
  padding-right: 22px;
  transition: all 0.3s ease-out;
  color: white;
  ${({ $active }) => ($active ? tw`bg-primary-dark` : "")}
  svg * {
    ${({ $active }) => ($active ? tw`fill-colors-yellow` : "")};
  }
  .ant-typography {
    ${({ $active }) => ($active ? tw`!text-yellow` : tw`!text-white`)};
  }
  &:hover {
    ${tw`bg-primary-dark`};
    svg * {
      ${tw`fill-colors-yellow`};
    }
    .ant-typography {
      ${tw`!text-yellow`};
    }
    &:before {
      content: " ";
      ${tw`border-l-4 border-yellow`}
      position: absolute;
      left: 0px;
      top: 0px;
      height: 54px;
    }
  }
  &:before {
    content: " ";
    ${({ $active }) => ($active ? tw`border-l-4 border-yellow` : "")}
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
  }
`;
const PageSidebarItemInner = styled.div<{ $visible: boolean }>`
  transition: max-height ${(props) => (props.$visible ? "1s" : "0.2s")};
  overflow: hidden;
  max-height: ${(props) => (props.$visible ? "1000px" : "0px")};
`;

const SiderNewCount = styled.span`
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 25px;
  border-radius: 50px;
  background-color: #fc0002;
  right: 30px;
  color: white;
`;

const PageSidebarItem = ({ menu }: { menu: IPageSidebarItem }) => {
  const { t } = useTranslation("menu");
  const [visible, setVisible] = useState<boolean>(false);

  const { sidebarNoti, dispatchNoti } = useNotification();
  const { getCurrentKey, getCurrentParams, dispatch } = usePageRoute();
  const { xl } = Grid.useBreakpoint();

  const { children, params, loading, ...rest } = menu;

  const toggleSubMenu = () => {
    if (xl) {
      setVisible(!visible);
    }
  };

  const dispatchPage = () => {
    if (count && count.length > 0) {
      if (rest.key === "user-management.pending-list") {
        dispatchNoti?.({
          action: "clear",
          type: "sidebar",
          key: ["user-management.pending-list"],
        });
      }

      if (rest.key === "vehicle-inspection.spray-check") {
        dispatchNoti?.({
          action: "clear",
          type: "sidebar",
          key: ["vehicle-inspection.spray-check"],
        });
      }

      if (rest.key === "vehicle-inspection.vehicle-inspection") {
        dispatchNoti?.({
          action: "clear",
          type: "sidebar",
          key: ["vehicle-inspection.vehicle-inspection"],
        });
      }

      if (rest.key === "vehicle-inspection.broken-car") {
        dispatchNoti?.({
          action: "clear",
          type: "sidebar",
          key: ["vehicle-inspection.broken-car"],
        });
      }

      if (rest.key === "vehicle-inspection.check-quick-lab") {
        dispatchNoti?.({
          action: "clear",
          type: "sidebar",
          key: ["vehicle-inspection.check-quick-lab"],
        });
      }

      if (rest.key === "vehicle-inspection.failed-inspection-vehicle") {
        dispatchNoti?.({
          action: "clear",
          type: "sidebar",
          key: ["vehicle-inspection.failed-inspection-vehicle"],
        });
      }
    }
    dispatch({
      action: "replaceAll",
      pageKey: rest.key,
      params: params?.value,
    });
  };

  const onClick = () => {
    if (children && children.length) {
      return toggleSubMenu();
    }
    return dispatchPage();
  };

  const getExpandIcon = () => {
    if (!children || !children.length) return null;
    if (visible) {
      return <UpArrow />;
    }
    return <DownArrow />;
  };

  const getResponsiveContent = () => {
    if (xl || !children) return null;
    return children.map((item, index) => {
      return <ResponsiveSidebar item={item} key={index} />;
    });
  };

  const getMenuIcon = () => {
    if (loading) {
      return <LoadingOutlined style={{ color: "white", fontSize: 16 }} />;
    }
    if (rest.icon) {
      return <Icon component={rest.icon} style={{ fontSize: 20 }} />;
    }
    return null;
  };

  const currentKey = getCurrentKey(0);
  const currentParams = getCurrentParams() as any;

  const getIsActive = () => {
    if (params && rest.key === currentKey) {
      const { key, value } = params;
      return currentParams[key] === value[key];
    }
    return rest.key === currentKey;
  };

  const active = getIsActive();
  const expand = getExpandIcon();
  const icon = getMenuIcon();
  const responsiveContent = getResponsiveContent();
  const count = sidebarNoti[rest.key];

  const element = (
    <div className="relative">
      <PageSidebarItemContainer
        $active={active}
        className="py-4"
        onClick={onClick}
        align="middle"
      >
        <Col xl={4} xs={24}>
          {icon}
        </Col>
        <Col xl={18} xs={0}>
          <Typography.Text
            ellipsis={{ tooltip: t(rest.title) }}
            className="ml-3 text-[20px]"
          >
            {t(rest.title)}
          </Typography.Text>
        </Col>
        {count && count.length > 0 && (
          <Col xl={2} xs={0} className="text-end">
            <SiderNewCount>{numeral(count.length).format("0,0")}</SiderNewCount>
          </Col>
        )}
        <Col xl={2} xs={0} className="text-end">
          {expand}
        </Col>
      </PageSidebarItemContainer>
      <PageSidebarItemInner hidden={!xl} $visible={visible}>
        {children?.map((item, index) => {
          return <PageSidebarItem menu={item} key={`${item.key}-${index}`} />;
        })}
      </PageSidebarItemInner>
    </div>
  );

  if (xl) {
    return element;
  }

  return (
    <Popover
      placement="rightTop"
      trigger={["click"]}
      showArrow={false}
      content={responsiveContent}
      transitionName=""
      overlayClassName="responsive-side-popover"
    >
      {element}
    </Popover>
  );
};

const ResponsiveSidebar: FC<{ item: IPageSidebarItem }> = ({ item }) => {
  const { t } = useTranslation("menu");
  const { getCurrentKey, getCurrentParams, dispatch } = usePageRoute();

  const currentKey = getCurrentKey(0);
  const currentParams = getCurrentParams() as any;

  const getActive = () => {
    if (item.params && item.key === currentKey) {
      const { key, value } = item.params;
      return currentParams[key] === value[key];
    }
    return item.key === currentKey;
  };

  const onClick = () => {
    dispatch({
      action: "replaceAll",
      pageKey: item.key,
      params: item.params?.value,
    });
  };

  const active = getActive();

  return (
    <div className="relative">
      <PageSidebarItemContainer
        className="py-4 bg-primary-light"
        style={{ width: 200 }}
        $active={active}
        onClick={onClick}
      >
        <Col span={24}>
          <Typography.Text
            className="!text-primary-dark"
            ellipsis={{ tooltip: t(item.title) }}
          >
            {t(item.title)}
          </Typography.Text>
        </Col>
      </PageSidebarItemContainer>
    </div>
  );
};

export default PageSidebarItem;
