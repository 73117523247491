import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserGroupsrScroll } from "services/user-group";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

const CSelectGroupPerson: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useGetUserGroupsrScroll}
      valueKey="id"
      titleKey="title"
      searchKey="search"
      placeholder={t("setting.group-of-persons", { ns: "menu" })}
    />
  );
};

export default CSelectGroupPerson;
