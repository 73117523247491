import { IUserTypeSelectData } from "components/input/c-user-type";
import { ComponentType, createContext, useContext, useState } from "react";
import { IFaceComparisonContext } from "./interface";

const Context = createContext<IFaceComparisonContext>(
  {} as IFaceComparisonContext
);

const withFaceComparisonProvider = (Component: ComponentType) => {
  return () => {
    const [faceComparison, setFaceComparison] = useState();
    const [userType, setUserType] = useState<IUserTypeSelectData>({
      title: "user-management.guest",
      userType: "VISITOR",
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [action, setAction] = useState<"add" | "edit">("add");

    const toggle = () => {
      setVisible(!visible);
    };

    return (
      <Context.Provider
        value={{
          faceComparison,
          userType,
          setFaceComparison,
          setUserType,
          visible,
          toggle,
          action,
          setAction
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useFaceComparison = () => useContext(Context);
export default withFaceComparisonProvider;
