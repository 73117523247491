import { Col, Form, Row } from "antd";
import PageContent from "components/layout/page-content";
import UserReportProvider, {
  userKey,
  useUserReport,
} from "provider/user-report";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import UserManagementReportContent from "./content";
import SideBarEmployee from "./side-bar/side-bar-employee";
import SideBarCustomer from "./side-bar/side-bar-customer";
import SideBarUser from "./side-bar/side-bar-user";
import SideBarBlacklist from "./side-bar/side-bar-blacklist";
import CSelect from "components/input/c-select";

const SidebarWrapper = styled.div`
  ${tw`bg-white shadow-sm`};
  height: calc(100vh - 104px);
`;

const UserManagementReport = () => {
  return (
    <PageContent breadcrumb>
      <UserReportProvider>
        <Row>
          <Col span={6}>
            <SideBar />
          </Col>
          <Col
            span={18}
            style={{ overflowY: "auto", height: "calc(100vh - 180px)" }}
          >
            <UserManagementReportContent />
          </Col>
        </Row>
      </UserReportProvider>
    </PageContent>
  );
};

const sideBarUser = {
  CUSTOMER: <SideBarCustomer />,
  EMPLOYEE: <SideBarEmployee />,
  USER: <SideBarUser />,
  BLACKLIST: <SideBarBlacklist />,
};

const SideBar = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { personType, setPersonType, setSearch, setColumnsKey } =
    useUserReport();

  const onPersonType = (e: any) => {
    setPersonType(e);
    setColumnsKey(userKey);
    setSearch({});
  };

  return (
    <SidebarWrapper
      className="p-5"
      style={{ overflowY: "auto", height: "calc(100vh - 180px)" }}
    >
      <Form form={form} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              name="personType"
              label={`${t("select-person")}`}
              rules={[{ required: true }]}
              initialValue="CUSTOMER"
            >
              <CSelect
                onChange={(e) => onPersonType(e)}
                valueOptions={{
                  values: [
                    {
                      label: t("person"),
                      value: "CUSTOMER",
                    },
                    {
                      label: t("employee"),
                      value: "EMPLOYEE",
                    },
                    {
                      label: t("user-management.staff", { ns: "menu" }),
                      value: "USER",
                    },
                    {
                      label: t("blacklist-person"),
                      value: "BLACKLIST",
                    },
                  ],
                  valueKey: "value",
                  titleKey: "label",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            {personType === "CUSTOMER"
              ? sideBarUser["CUSTOMER"]
              : personType === "EMPLOYEE"
              ? sideBarUser["EMPLOYEE"]
              : personType === "USER"
              ? sideBarUser["USER"]
              : sideBarUser["BLACKLIST"]}
          </Col>
        </Row>
      </Form>
    </SidebarWrapper>
  );
};

export default UserManagementReport;
