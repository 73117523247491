import {
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IItem, CheckQuickLabContext } from "./interface";
import io from "socket.io-client";
import { checkQuicklab } from "config/axios";
import { ICheckQuickLab } from "services/check-quick-lab/interface";
import { useQueryClient } from "@tanstack/react-query";

const Context = createContext<CheckQuickLabContext>({} as CheckQuickLabContext);

const withCheckQuickLab = (Component: ComponentType) => {
  return () => {
    const qClient = useQueryClient();

    const [checkQuick, setCheckQuick] = useState<ICheckQuickLab | undefined>();
    const [keyStatus, setKeyStatus] = useState();
    const [numberOfItems, setNumberOfItems] = useState<IItem>({
      WAITING_FOR_INSPECT: 0,
      PASSED_THE_INSPECTION: 0,
      FAILED_THE_INSPECTION: 0,
      VIP: 0,
    });

    useEffect(() => {
      const socket = io(checkQuicklab.replace("http", "ws"), {
        transports: ["websocket"],
      });

      socket.on(`check-quicklab`, (v: ICheckQuickLab) => {
        console.log("check-quicklab", v);
        setCheckQuick(v);
        qClient.invalidateQueries(["get-check-quicklabs"]);
        qClient.invalidateQueries(["get-check-quicklab"]);
      });

      return () => {
        socket.disconnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkQuick]);

    useEffect(() => {
      setCheckQuick(undefined);
    }, [checkQuick]);

    return (
      <Context.Provider
        value={{ numberOfItems, setNumberOfItems, keyStatus, setKeyStatus }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useCheckQuickLab = () => useContext(Context);
export default withCheckQuickLab;
