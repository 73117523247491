import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import withStatusQueueCar, {
  useStatusQueueCar,
} from "provider/status-queue-car";
import {
  IListStatusQueue,
  KeyStatus,
} from "provider/status-queue-car/interface";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import StatusQueueCarContent from "./content";

const listStatusQueue: IListStatusQueue[] = [
  { id: 1, statusKey: "ALL", name: "all" },
  {
    id: 2,
    statusKey: "CHECK_SPRAY",
    name: "check-the-spray",
    vehicleQueueStatus: "VEHICLE_SPRAY",
  },
  {
    id: 3,
    statusKey: "CHECK_CAR_CONDITIOPN",
    name: "check-the-condition-of-the-car",
    vehicleQueueStatus: "VEHICLE_CONDITION",
  },
  {
    id: 4,
    statusKey: "VEHICLE_QUICKLAB",
    name: "ckeck-quick-lab",
    vehicleQueueStatus: "VEHICLE_QUICKLAB",
  },
  {
    id: 5,
    statusKey: "WAITING_QUEUE_IN",
    name: "waiting-for-the-weighing-queue-in",
    // vehicleQueueStatus: "WAITING_QUEUE_IN",
  },
  {
    id: 6,
    statusKey: "WAIT_FOR_LOAD",
    name: "wait-for-load-release",
    // vehicleQueueStatus: "WAIT_FOR_LOAD",
  },
  {
    id: 7,
    statusKey: "START_LOADING",
    name: "start-loading-release",
    // vehicleQueueStatus: "START_LOADING",
  },
  {
    id: 8,
    statusKey: "FINISH_LOADING",
    name: "finish-loading-release",
    // vehicleQueueStatus: "FINISH_LOADING",
  },
  {
    id: 9,
    statusKey: "WEIGHED_OUT",
    name: "weighed-out",
    // vehicleQueueStatus: "WEIGHED_OUT",
  },
  {
    id: 10,
    statusKey: "TVOP",
    name: "the-car-has-successfully-left-the-TVOP",
    // vehicleQueueStatus: "TVOP",
  },
];

const StatusQueueCarPage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <SideBar />
        </Col>
        <Col span={18} className="px-5 pt-5">
          <StatusQueueCarContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const SideBar = () => {
  const { t } = useTranslation();
  const { setKeyStatus, keyStatus, numberOfItems } = useStatusQueueCar();

  useEffect(() => {
    setKeyStatus(listStatusQueue[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = listStatusQueue.map((item) => {
    const number = numberOfItems[item.statusKey as KeyStatus];
    return (
      <CCard.SlashHeader
        active={keyStatus === item}
        className="mb-4"
        title={t(item.name)}
        key={item.id}
        onClick={() => {
          setKeyStatus(item);
        }}
      >
        <Row
          align="middle"
          className="px-3 py-2"
          style={{ height: 86 }}
          gutter={12}
        >
          <Col>
            <Typography.Text type="secondary" ellipsis={{ tooltip: t("list") }}>
              {t("list")} :
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{`${number || 0} ${t("list")}`}</Typography.Text>
          </Col>
        </Row>
      </CCard.SlashHeader>
    );
  });

  return (
    <React.Fragment>
      <InfiniteSidebar title={t("status-queue-car")} content={content} />
    </React.Fragment>
  );
};

export default withStatusQueueCar(StatusQueueCarPage);
