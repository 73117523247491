import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FindVehicleDisplayCar from "./display-car";
import FindVehicleSearchCar from "./search-car";
// import tw from "twin.macro";

const Wrapper = styled.div`
  height: calc(100vh - 104px);
`;

const VehicleFindVehicle = () => {
  const { t } = useTranslation();
  return (
    <Wrapper className="p-5">
      <Row align="middle" style={{ height: 45 }}>
        <Col span={24}>
          <Typography.Title level={4}>
            {t("vehicle-management.parking-camera", { ns: "menu" })}
          </Typography.Title>
        </Col>
      </Row>
      <Row className="mt-5" gutter={12}>
        <Col span={12}>
          <FindVehicleSearchCar />
        </Col>
        <Col span={12}>
          <FindVehicleDisplayCar />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default VehicleFindVehicle;
