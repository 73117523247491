import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import Accessibility from "components/access-control/accessibility-card";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import { typeOfUser } from "config/axios/interface";
import { useAppLanguage } from "provider/app-language";
import { useDoorRealtime } from "provider/door-realtime";
import { ITransitionDoorAccess } from "provider/door-realtime/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const RealtimeDoor = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const { transitions } = useDoorRealtime();
  const [params, setParams] = useState({ limit: 10, page: 1 });
  const className = "mt-5 !p-5 bg-white rounded-app shadow w-full";

  const columns: ColumnsType<ITransitionDoorAccess> = [
    {
      title: t("time"),
      dataIndex: "time",
      ellipsis: true,
      width: 200,
      render: (_, rc) => {
        const time = rc?.actionTime;
        return (
          <span>
            {time
              ? formatDate({ date: rc?.actionTime, format: "nomal-full-time" })
              : "/"}
          </span>
        );
      },
    },
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc?.imageUrl} />;
      },
    },
    {
      title: t("full-name"),
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        const { firstName, lastName } = rc || {};
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      key: "userType",
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        const userTypeKey = rc?.personType;
        if (!userTypeKey) return <span>-</span>;
        const text = t(typeOfUser[userTypeKey]);
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      title: t("record-type"),
      dataIndex: "actionType",
      ellipsis: true,
      width: 200,
      render: (_, rc) => {
        return <span>{rc?.actionType ? t(rc?.actionType) : "-"}</span>;
      },
    },
    {
      title: t("origin-of-the-event"),
      dataIndex: "doorName",
      ellipsis: true,
      width: 200,
      render: (_, rc) => {
        return <span>{rc?.door?.doorName || "-"}</span>;
      },
    },
    {
      title: t("face-mask"),
      dataIndex: "maskStatus",
      ellipsis: true,
      width: 200,
      render: (_, rc) => {
        const text = rc?.maskStatus
          ? rc.maskStatus === "FALSE"
            ? "not-wear"
            : "wear"
          : "no-data";
        return <span>{t(text)}</span>;
      },
    },
    {
      title: t("temperature"),
      dataIndex: "temperature",
      ellipsis: true,
      width: 200,
      render: (_, rc) => {
        return <span>{rc?.temperature || t("no-data")}</span>;
      },
    },
    {
      title: t("accessibility"),
      dataIndex: "eventType",
      width: 200,
      render: (_, rc) => (
        <Accessibility type={rc?.eventType ? [rc?.eventType] : []} />
      ),
    },
  ];

  return (
    <PageContent className={className}>
      <CTable
        scroll={{ x: 1200 }}
        selectAble={false}
        columns={columns}
        rowKey="id"
        wrapper={false}
        dataSource={transitions}
        pagination={{
          size: "small",
          pageSize: params.limit,
          total: transitions.length,
          current: params.page,
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
          onShowSizeChange(page, limit) {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default RealtimeDoor;
