import { Col, Row } from "antd";
import PageContent from "components/layout/page-content";
import withUserPendingListProvider from "provider/user-pending-list";
import TableContent from "./content";
import PendingListSidebar from "./sidebar";

const UserPendingListPage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <PendingListSidebar />
        </Col>
        <Col
          span={18}
          className="p-5"
          style={{ height: "calc(100vh - 200px)", overflowY: "auto" }}
        >
          <TableContent />
        </Col>
      </Row>
    </PageContent>
  );
};

export default withUserPendingListProvider(UserPendingListPage);
