import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useTranslation } from "react-i18next";

const VehicleParkingList = () => {
  const { t } = useTranslation();

  const elements: ISearchBoxElement[] = [
    {
      name: "a",
      label: t("license-plate-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "b",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "c",
      label: t("entry-date"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "d",
      label: t("exit-date"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "e",
      label: t("parking-lot"),
      input: {
        type: "Select",
      },
    },
    {
      name: "f",
      label: t("access-type"),
      input: {
        type: "Select",
      },
    },
  ];

  const columns: ColumnsType = [
    {
      title: t("license-plate-image"),
    },
    {
      title: t("license-plate-number"),
    },
    {
      title: t("entry-date"),
    },
    {
      title: t("exit-date"),
    },
    {
      title: t("full-name"),
    },
    {
      title: t("parking-lot"),
    },
    {
      title: t("status"),
    },
    {
      title: t("user-type"),
    },
  ];

  const onSearch = (values: any) => {
    //
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        title={t("vehicle-management.parking-list", { ns: "menu" })}
        columns={columns}
      />
    </PageContent>
  );
};

export default VehicleParkingList;
