import { Calendar, Col, Divider, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useGetCheckSprays } from "services/check-the-spray";
import styled from "styled-components";
import tw from "twin.macro";
import SprayCheckCount from "./spray-count";
import { useSprayCheckProvider } from "provider/inspection-spray-check";

const SprayCheckSidebarWrapper = styled.div`
  ${tw`bg-white shadow-sm`};
  height: calc(100vh - 104px);
`;

const SprayCheckSidebar = () => {
  const { t } = useTranslation();
  const data_check_spray = useGetCheckSprays({ pagination: false });

  return (
    <SprayCheckSidebarWrapper>
      <div className="p-5">
        <Typography.Title level={5} className="!text-primary-dark">
          {t("vehicle-inspection.spray-check", { ns: "menu" })}
        </Typography.Title>
      </div>
      <Divider className="!m-0" />
      <div className="p-5">
        <Row gutter={12}>
          <Col span={12}>
            <SprayCheckCount
              sprayed={true}
              count={data_check_spray?.data?.liquidSprayTrue}
            />
          </Col>
          <Col span={12}>
            <SprayCheckCount
              sprayed={false}
              count={data_check_spray?.data?.liquidSprayFalse}
            />
          </Col>
          <Col span={24} className="pt-5">
            <CalendarCheckSpray />
          </Col>
        </Row>
      </div>
    </SprayCheckSidebarWrapper>
  );
};

const CalendarStyle = styled(Calendar)`
  background: #ebebeb;
  border: 1px solid #e4e4e4;
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 3px 5px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 30px;
  }
  .ant-select-arrow {
    height: 18px;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    display: flex;
    align-items: flex-end;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    display: flex;
    align-items: flex-end;
  }
  .ant-radio-group {
    font-size: 20px;
  }
  .ant-radio-group-small .ant-radio-button-wrapper {
    height: 30px;
    padding: 3px 5px;
  }
  .ant-picker-calendar {
    font-size: 20px;
  }
`;

const CalendarCheckSpray = () => {
  const { selectDate, setSelectDate } = useSprayCheckProvider();

  return (
    <div className="center">
      <CalendarStyle
        fullscreen={false}
        value={selectDate}
        onSelect={(e) => setSelectDate(e)}
      />
    </div>
  );
};

export default SprayCheckSidebar;
