import { Col, Row } from "antd";
import SideBarSelectCameraGroup from "./sidebar/sidebar";
import CameraGroupProvider from "provider/all-video";
import PageContent from "components/layout/page-content";
import AllVideosContentPage from "./content";

const AllVideosPage = () => {
  return (
    <PageContent breadcrumb>
      <Row className="h-full w-full flex bg-[#F5F5F5]">
        <Col span={6}>
          <SideBarSelectCameraGroup />
        </Col>
        <Col span={18}>
          <AllVideosContentPage />
        </Col>
      </Row>
    </PageContent>
  );
};

const Wrapper = () => {
  return (
    <CameraGroupProvider>
      <AllVideosPage />
    </CameraGroupProvider>
  );
};

export default Wrapper;
