import { Row, Col, Typography } from "antd";
import CButton from "components/button/c-button";
import CIndicator from "components/display/c-indicator";
import Container from "components/display/container";
import ErrorPage from "components/pages/error-page";
import PleaseSelectDataPage from "components/pages/select-data-page";
import { useAppLanguage } from "provider/app-language";
import { useRole } from "provider/role";
import { useTranslation } from "react-i18next";
import { useGetRole } from "services/role";
import RoleMenuList from "./menu-list";

const RoleDetail = () => {
  const { t } = useTranslation();
  const { selectedRole, toggle } = useRole();
  const { translationObject } = useAppLanguage();

  const { data, isLoading, isError, error } = useGetRole(selectedRole?.id);

  if (!selectedRole) {
    return <PleaseSelectDataPage />;
  }

  if (isLoading && selectedRole) {
    return <CIndicator />;
  }

  if (!data || isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <Container scrollY height="calc(100vh - 218px)">
      <Row align="middle" className="mb-5">
        <Col>
          <Typography.Title level={4} className="!text-primary-dark">
            {translationObject({ object: data, keys: ["title"] }).title}
          </Typography.Title>
        </Col>
        <Col span={4} className="ml-auto">
          <CButton className="w-full" theme="white" onClick={toggle}>
            {t("edit")}
          </CButton>
        </Col>
      </Row>
      <RoleMenuList role={data} />
    </Container>
  );
};

export default RoleDetail;
