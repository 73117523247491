import { CopyFilled } from "@ant-design/icons";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Col, Divider, Form, Row, Tooltip, Typography } from "antd";
import CButton from "components/button/c-button";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import CUpload from "components/input/c-upload";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { registerUrl } from "config/axios";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  usePostOrganization,
  usePatchOrganization,
  useGetOrganization,
} from "services/user-company";

const UserCompanyCreateAndEditPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const qClient = useQueryClient();

  const { setAppLoading } = useAppLoading();
  const { getCurrentKey, getCurrentParams, dispatch } = usePageRoute();
  const { id } = getCurrentParams();

  const post_organization = usePostOrganization();
  const patch_organization = usePatchOrganization();
  const { data, isLoading } = useGetOrganization(id);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      profile: data?.profile?.url,
      userType: data?.customer?.userType,
      username: data?.customer?.username,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const pageKey = getCurrentKey();

  const onSave = (values: any) => {
    const { userType, username, password } = values;
    values = { ...values, customer: { userType, username, password } };
    delete values.userType;
    delete values.username;
    delete values.password;
    mutate({
      mutation: id ? patch_organization : post_organization,
      invalidateQueries: ["get-organization", "get-organizations"],
      values: values,
    });
  };

  const _onSaveAndContinue = (values: any) => {
    const { userType, username, password } = values;
    values = { ...values, customer: { userType, username, password } };
    delete values.userType;
    delete values.username;
    delete values.password;
    mutate({
      mutation: id ? patch_organization : post_organization,
      invalidateQueries: ["get-organization", "get-organizations"],
      values: values,
      SaveAndContinue: true,
    });
  };

  const mutate = ({
    mutation,
    invalidateQueries = [],
    values,
    SaveAndContinue = false,
  }: {
    mutation?: UseMutationResult;
    invalidateQueries?: string[];
    values?: any;
    SaveAndContinue?: boolean;
  }) => {
    setAppLoading(true);
    mutation?.mutate(values, {
      onSuccess: () => {
        invalidateQueries.forEach((e) => qClient.invalidateQueries([e]));
        fireNotification({ type: "success", menu: "user-management" });
        if (!SaveAndContinue) dispatch({ action: "back" });
        if (SaveAndContinue) form?.resetFields();
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
          menu: "user-management",
        });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  const getLink = (): string => {
    switch (data?.customer?.userType) {
      case "CONTRACTOR":
        return `${registerUrl}/#/contractor`;
      case "RECEIVER":
        return `${registerUrl}/#/consignee`;
      case "SUPPLIER":
        return `${registerUrl}/#/supplier`;
      case "VISITOR":
        return `${registerUrl}/#/guest`;
      default:
        return "";
    }
  };

  const linkUrl = getLink();

  const _copyToClipboard = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(`${linkUrl}`);
    fireNotification({
      type: "success",
      menu: "user-management",
      description: `${t("copy")} ${linkUrl}`,
    });
  };

  return (
    <PageContent
      loading={isLoading && !!id}
      breadcrumb={{
        form,
        mutate: {
          onSaveAndContinue: !id ? _onSaveAndContinue : false,
          onSave: onSave,
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title level={5} className="!text-primary-dark">
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form layout="vertical" className="!p-5" form={form} initialValues={{}}>
          <Row gutter={24}>
            <Col span={6}>
              <div>
                <Typography.Text className="!text-primary">
                  {t("basic-information")}
                </Typography.Text>

                <Typography.Text className="!text-[#f82602] pl-3">
                  ** {t("recommended-size", { ns: "message" })}
                </Typography.Text>

                <Form.Item
                  className="!mt-3 !flex justify-center"
                  name="profile"
                >
                  <CUpload.Avatar />
                </Form.Item>
              </div>
            </Col>
            <Col span={18}>
              <Row gutter={[24, 0]}>
                <Form.Item name="id" hidden>
                  <CInput />
                </Form.Item>
                <Col span={24}>
                  <Form.Item
                    label={t("company-name")}
                    rules={[{ required: true }]}
                    name="name"
                  >
                    <CInput placeholder={t("company-name")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("phone-number")}
                    rules={[{ required: true }]}
                    name="phoneNumber"
                  >
                    <CInput placeholder={t("phone-number")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("email")}
                    rules={[{ type: "email" }]}
                    name="email"
                  >
                    <CInput placeholder={t("email")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t("address")} name="address">
                    <CInput.TextArea rows={5} placeholder={t("address")} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={24} className="pt-4">
            <Col span={6} className="!flex justify-start">
              <div>
                <Typography.Text className="!text-primary">
                  {t("name-of-contact-person")}
                </Typography.Text>
              </div>
            </Col>
            <Col span={18} className="pt-3.5">
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item
                    label={t("first-name")}
                    rules={[{ required: true }]}
                    name="contactFirstName"
                  >
                    <CInput placeholder={t("first-name")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("last-name")}
                    rules={[{ required: true }]}
                    name="contactLastName"
                  >
                    <CInput placeholder={t("last-name")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("phone-number")}
                    rules={[{ required: true }]}
                    name="contactPhoneNumber"
                  >
                    <CInput placeholder={t("phone-number")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("email")}
                    rules={[{ type: "email" }]}
                    name="contactEmail"
                  >
                    <CInput placeholder={t("email")} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={24} className="pt-4">
            <Col span={6} className="!flex justify-start">
              <div>
                <Typography.Text className="!text-primary">
                  {`${t("person-type")} & ${t("password-setting")}`}
                </Typography.Text>
              </div>
            </Col>
            <Col span={18} className="pt-3.5">
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Form.Item
                    label={t("person-type")}
                    rules={[{ required: true }]}
                    name="userType"
                  >
                    <CSelect.UserType />
                  </Form.Item>
                </Col>
                {id && (
                  <Col span={24}>
                    <div className="w-full flex mb-4">
                      <CInput readOnly value={linkUrl} />
                      <Tooltip overlay={<span>{t("copy")}</span>}>
                        <CButton
                          className="ml-1"
                          style={{ height: 45, width: 45 }}
                          theme="gray"
                          onClick={_copyToClipboard}
                          icon={<CopyFilled />}
                        />
                      </Tooltip>
                    </div>
                  </Col>
                )}
                <Col span={12}>
                  <Form.Item
                    label={t("username")}
                    rules={[{ required: true }]}
                    name="username"
                  >
                    <CInput placeholder={t("username")} readOnly={id} />
                  </Form.Item>
                </Col>
                <Col span={12} hidden={id}>
                  <Form.Item
                    label={t("password")}
                    rules={[{ required: !id }]}
                    name="password"
                  >
                    <CInput placeholder={t("password")} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageContent>
  );
};

export default UserCompanyCreateAndEditPage;
