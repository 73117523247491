import { IDataNoti } from "pages/setting/notification/data-noti";
import { ComponentType, createContext, useContext, useState } from "react";
import { NotificationSettingContext } from "./interface";

const Context = createContext<NotificationSettingContext>(
  {} as NotificationSettingContext
);

const withNotificationSetting = (Component: ComponentType) => {
  return () => {
    const [keyStatus, setKeyStatus] = useState<IDataNoti>();

    return (
      <Context.Provider value={{ keyStatus, setKeyStatus }}>
        <Component />
      </Context.Provider>
    );
  };
};

export const useNotificationSetting = () => useContext(Context);
export default withNotificationSetting;
