import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import CIndicator from "components/display/c-indicator";
import ErrorPage from "components/pages/error-page";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { ComponentType, createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetFloor, usePatchFloor } from "services/floor";
import { useDeleteMark, usePatchMark, usePostMark } from "services/mark";
import { FloorEditCursorTypes, IVehicleFloorEditContext } from "./interface";

const Context = createContext<IVehicleFloorEditContext>(
  {} as IVehicleFloorEditContext
);

const withVehicleFloorEdit = (Component: ComponentType) => {
  return () => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { getCurrentParams } = usePageRoute();
    const { setAppLoading } = useAppLoading();
    const { id } = getCurrentParams();

    const [cursorType, setCursorType] = useState<FloorEditCursorTypes>();

    const { data, isLoading, isError, error } = useGetFloor(id);
    const patch_floor = usePatchFloor();
    const post_mark = usePostMark();
    const patch_mark = usePatchMark();
    const del_mark = useDeleteMark();
    const qClient = useQueryClient();

    const invalidates = () => {
      qClient.invalidateQueries(["get-floors"]);
      qClient.invalidateQueries(["get-floor"]);
      qClient.invalidateQueries(["get-marks"]);
      qClient.invalidateQueries(["get-mark"]);
    };

    const onUploadImage = (file: File) => {
      setAppLoading(true);
      patch_floor.mutate(
        { id, mediaObject: file },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: "vehicle-management" });
            invalidates();
          },
          onError: ({ message }) => {
            fireNotification({
              type: "error",
              message,
              menu: "vehicle-management",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    };

    const onDeleteImage = async () => {
      const confirm = await fireConfirmDelete({
        title: t("you-are-about-to-delete-the-map-image", { ns: "message" }),
      });
      if (!confirm) return;
      setAppLoading(true);
      patch_floor.mutate(
        { id, mediaObject: null },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: "vehicle-management" });
            invalidates();
          },
          onError: ({ message }) => {
            fireNotification({
              type: "error",
              message,
              menu: "vehicle-management",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    };

    const onUpdateMark = (mark: any, callback: Function) => {
      setAppLoading(true);
      patch_mark.mutate(
        {
          ...mark,
          id: mark?.id,
          encodeDevice: mark?.encodeDevice || null,
          pin: mark?.pin || null,
        },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: "vehicle-management" });
            invalidates();
          },
          onError: ({ message }) => {
            fireNotification({
              type: "error",
              message,
              menu: "vehicle-management",
            });
          },
          onSettled: () => {
            setAppLoading(false);
            callback();
          },
        }
      );
    };

    const onCreateMark = (mark: any, callback: Function) => {
      setAppLoading(true);
      post_mark.mutate(
        {
          ...mark,
          floor: data?.id,
        },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: "vehicle-management" });
            callback();
            invalidates();
          },
          onError: ({ message }) => {
            fireNotification({
              type: "error",
              message,
              menu: "vehicle-management",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    };

    const onDeleteMark = async (id: number) => {
      const confirm = await fireConfirmDelete();
      if (!confirm) return;
      setAppLoading(true);
      del_mark.mutate(
        { id },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: "vehicle-management" });
            invalidates();
          },
          onError: ({ message }) => {
            fireNotification({
              type: "error",
              message,
              menu: "vehicle-management",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    };

    if (isLoading) {
      return (
        <div style={{ height: "calc(100vh - 104px)" }}>
          <CIndicator />
        </div>
      );
    }

    if (isError || !data) {
      return (
        <div style={{ height: "calc(100vh - 104px)" }}>
          <ErrorPage error={error} />
        </div>
      );
    }

    return (
      <Context.Provider
        value={{
          form,
          cursorType,
          floor: data,
          setCursorType,
          onUploadImage,
          onDeleteImage,
          onCreateMark,
          onUpdateMark,
          onDeleteMark,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useVehicleFloorEdit = () => useContext(Context);
export default withVehicleFloorEdit;
