import { Form } from "antd";
import CDrawer from "components/display/c-drawer";
import CInput from "components/input/c-input";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePatchParking, usePostParking } from "services/parking";
import { IParking } from "services/parking/interface";

interface AddParkingDrawerProps {
  visible: boolean;
  onClose: () => void;
  parking?: IParking;
  resetParking: () => void;
}

const AddParkingDrawer: FC<AddParkingDrawerProps> = ({
  visible,
  onClose,
  resetParking,
  parking,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const post_parking = usePostParking();
  const patch_parking = usePatchParking();

  useEffect(() => {
    if (parking) {
      form.setFieldsValue(parking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parking]);

  return (
    <CDrawer
      width="40vw"
      title={parking ? t("edit-parking") : t("add-parking")}
      visible={visible}
      onClose={onClose}
      form={form}
      afterVisibleChange={(e) => {
        if (!e) {
          resetParking();
          form.resetFields();
        }
      }}
      mutation={{
        mutate: parking ? patch_parking : post_parking,
        invalidateQueries: ["get-parkings", "get-parking", "parking-count"],
        onBeforeSubmit: (values) => {
          if (values.inExit || values.inExit === 0) {
            values.inExit = `${values.inExit}`;
          }
          return values;
        },
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item hidden name="id">
          <CInput />
        </Form.Item>
        <Form.Item
          label={t("parking-name")}
          name="parkingName"
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("parking-name")} />
        </Form.Item>
        <Form.Item
          label={t("number-of-entrance-and-exit")}
          name="numberOfEntryExit"
          rules={[{ required: true }]}
        >
          <CInput.Number
            min={0}
            placeholder={t("number-of-entrance-and-exit")}
          />
        </Form.Item>
        <Form.Item
          label={t("capacity")}
          name="capacity"
          rules={[{ required: true }]}
        >
          <CInput.Number min={0} placeholder={t("capacity")} />
        </Form.Item>
        <Form.Item
          label={t("parking-available")}
          name="unoccupied"
          rules={[{ required: true }]}
        >
          <CInput.Number min={0} placeholder={t("parking-available")} />
        </Form.Item>
        <Form.Item
          label={t("parking-avalable-for-visitor-vehicle")}
          name="parkingVisit"
        >
          <CInput.Number
            placeholder={t("parking-avalable-for-visitor-vehicle")}
          />
        </Form.Item>
        <Form.Item
          label={t("parking-avalable-for-registered-vehicle")}
          name="parkingRegister"
        >
          <CInput.Number
            placeholder={t("parking-avalable-for-registered-vehicle")}
          />
        </Form.Item>

        <Form.Item
          label={t("parking-avalable-for-staff")}
          name="parkingEmployee"
        >
          <CInput.Number placeholder={t("parking-avalable-for-staff")} />
        </Form.Item>

        <Form.Item
          label={t("parking-avalable-for-motorcycle")}
          name="parkingMotor"
        >
          <CInput.Number placeholder={t("parking-avalable-for-motorcycle")} />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default AddParkingDrawer;
