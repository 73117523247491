import Icon from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { VideoNoData } from "assets/icons/general";
import CImage from "components/display/c-image";
import CModal from "components/display/c-modal";
import {
  findDataBasic,
  findDataValue,
} from "components/menu/vehicle-management/transition-description";
import { useAppLanguage } from "provider/app-language";
import { useDisplayScreen } from "provider/display-screen";
import { useVehicleSocket } from "provider/vehicle-socket";
import {
  IReceiveVehicleSocket,
  IVehicleSocketPerson,
} from "provider/vehicle-socket/interface";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import ImageProfileGeneral from "./image-general";
import ImageProfileTruck from "./image-truck";

const BoxDiv = styled.div`
  ${tw`bg-[#707070] !flex flex-col justify-center text-center w-full cursor-pointer`}
`;

export interface IData {
  vehicle?: IReceiveVehicleSocket;
  person?: IVehicleSocketPerson;
}

const EntranceDisplayScreen = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<IData | undefined>();
  const { visibleEntrance, setVisibleEntrance } = useDisplayScreen();
  const { detect, person, selected, setDetect } = useVehicleSocket();

  useEffect(() => {
    if (
      detect?.encodeDevice?.cameraIndexCode ===
        selected?.vehicle?.encodeDevice?.cameraIndexCode ||
      person?.encodeDevice?.cameraIndexCode ===
        selected?.generalFace?.encodeDevice?.cameraIndexCode
    ) {
      setData({ vehicle: detect, person: person });
    } else {
      setData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detect, person, visibleEntrance]);

  const onClose = () => {
    setDetect?.(undefined);
    setData(undefined);
    setVisibleEntrance?.(!visibleEntrance);
  };
  return (
    <CModal.FullScreen visible={visibleEntrance} onCancel={onClose}>
      <div style={{ height: "100vh" }} className=" text-white">
        <Row>
          <Col
            span={8}
            className="h-[100%] bg-[#1D1D1D]"
            style={{ height: "48vh" }}
          >
            <ImageVehicle data={data} />
          </Col>
          <Col
            span={8}
            className="bg-[#151515] h-[100%]"
            style={{ height: "48vh" }}
          >
            <ImageProfileGeneral data={data} />
          </Col>
          <Col
            span={8}
            className="bg-[#1D1D1D] h-[100%]"
            style={{ height: "48vh" }}
          >
            <ImageProfileTruck data={data} />
          </Col>
          <Col span={12} className="bg-[#181818]" style={{ height: "48vh" }}>
            <ImageLicensePlateNumber data={data} />
          </Col>
          <Col span={12} className="bg-[#171717]" style={{ height: "48vh" }}>
            <DetailVehicle data={data} />
          </Col>
          <Col
            span={20}
            className="pl-3 !flex flex-col justify-center bg-[#222222]"
            style={{ height: "4vh" }}
          >
            {t("entrance")}
          </Col>
          <Col
            span={2}
            className="bg-[#222222]"
            style={{ height: "4vh" }}
          ></Col>
          <Col span={2} className="w-full">
            <BoxDiv onClick={onClose} style={{ height: "4vh" }}>
              {t("out")}
            </BoxDiv>
          </Col>
        </Row>
      </div>
    </CModal.FullScreen>
  );
};

export const HeaderImage = styled.div`
  ${tw`absolute inset-x-0 top-0 h-12 z-[90] !opacity-25`}
  background: #000;
`;

export const HeaderText = styled.div`
  ${tw`absolute inset-x-0 top-0 h-12 z-[100] !flex flex-col justify-center`}
`;

const ImageVehicle = ({ data }: { data?: IData }) => {
  const { t } = useTranslation();
  const { selected } = useVehicleSocket();
  const { formatDate } = useAppLanguage();

  const { vehicle } = data || {};
  return (
    <div className="relative h-[100%]">
      <HeaderImage></HeaderImage>
      <HeaderText>
        <Row className="px-6">
          <Col flex="auto">
            <span className="text-[18px] pr-3">{t("image-capture")}</span>
            <span>{selected?.vehicle?.markName || "-"}</span>
          </Col>
          <Col span={12} className="text-end !flex flex-col justify-center">
            {vehicle?.createdAt
              ? formatDate({
                  format: "nomal-full-time",
                  date: vehicle.createdAt,
                })
              : ""}
          </Col>
        </Row>
      </HeaderText>
      {(!vehicle?.detectImage || !vehicle?.detectImage?.url) && (
        <div className="center h-full">
          <div className="center flex-col" style={{ height: "100%" }}>
            <Icon component={VideoNoData} style={{ fontSize: 100 }} />
            <Typography.Text
              className={`mt-4 text-center !text-white`}
              style={{ fontSize: 24 }}
            >
              {t("waiting-for-image-detection", { ns: "message" })}
            </Typography.Text>
          </div>
        </div>
      )}
      {vehicle?.detectImage?.url && (
        <CImage
          src={vehicle?.detectImage?.url}
          height="100%"
          width="100%"
          rounded={false}
        />
      )}
    </div>
  );
};

const HeaderLicensePlateNumber = styled.div<{ bg?: boolean }>`
  ${tw`h-12 !flex flex-col justify-center`}
  background: ${({ bg }) => (bg ? "#707070" : "#808080")};
`;

const ImageLicensePlateNumber = ({ data }: { data?: IData }) => {
  const { t } = useTranslation();
  const { selected } = useVehicleSocket();
  const { formatDate } = useAppLanguage();

  const { vehicle } = data || {};
  return (
    <div className="h-full">
      <HeaderLicensePlateNumber bg>
        <Row className="px-6">
          <Col flex="auto">
            <span className="text-[18px] pr-3">{t("license-plate-image")}</span>
            <span>{selected?.vehicle?.markName || "-"}</span>
          </Col>
          <Col span={12} className="text-end !flex flex-col justify-center">
            {vehicle?.createdAt
              ? formatDate({
                  format: "nomal-full-time",
                  date: vehicle.createdAt,
                })
              : ""}
          </Col>
        </Row>
      </HeaderLicensePlateNumber>
      <div style={{ height: "calc(100vh - 52vh - 2.5rem)" }}>
        <Row className="p-10">
          <Col span={24} className="!flex justify-center text-[18px]">
            {t("license-plate-number")}
            <span className="px-2">:</span>
            <span className="font-bold">{vehicle?.licensePlate || "-"}</span>
          </Col>
          <Col span={24} className="!flex justify-center">
            <div className="pt-10">
              {(!vehicle?.detectImage || !vehicle?.detectImage?.url) && (
                <div className="center flex-col" style={{ height: "100%" }}>
                  <Icon component={VideoNoData} style={{ fontSize: 100 }} />
                  <Typography.Text
                    className={`mt-4 text-center !text-white`}
                    style={{ fontSize: 24 }}
                  >
                    {t("waiting-for-image-detection", { ns: "message" })}
                  </Typography.Text>
                </div>
              )}
              {vehicle?.detectImage?.url && (
                <CImage
                  src={vehicle?.licensePlateImage?.url}
                  className="rounded-app"
                  height={160}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const DetailVehicle = ({ data }: { data?: IData }) => {
  const { t } = useTranslation();
  const { vehicle } = data || {};
  const { name, phoneNumber } = findDataBasic({ value: vehicle });
  const { perSonType, trailerLicensePlateNumber, weight, trailerWeight } =
    findDataValue({ value: vehicle?.customerEmployee });

  return (
    <div>
      <HeaderLicensePlateNumber>
        <span className="text-[18px] px-6">{t("basic-information")}</span>
      </HeaderLicensePlateNumber>
      <Row className="px-10 py-10">
        <Col span={9}>
          <TextPreview label={t("full-name")} value={name} />
          <TextPreview
            className="mt-3"
            label={t("person-type")}
            value={perSonType ? t(perSonType) : undefined}
          />
          <TextPreview
            className="mt-3"
            label={t("trailer-head-license-plate-number")}
            value={vehicle?.licensePlate}
          />
          <TextPreview
            className="mt-3"
            label={t("trailer-head-weight")}
            value={weight}
          />
        </Col>
        <Col span={9}>
          <TextPreview label={t("phone-number")} value={phoneNumber} />
          <TextPreview
            className="mt-3"
            label={t("vehicle-type")}
            value={vehicle?.vehicleType}
          />
          <TextPreview
            className="mt-3"
            label={t("trailer-tail-license-plate-number")}
            value={trailerLicensePlateNumber}
          />
          <TextPreview
            className="mt-3"
            label={t("trailer-tail-weight")}
            value={trailerWeight}
          />
        </Col>
        <Col span={6} className="center flex-col">
          <QrPreview data={data} />
        </Col>
      </Row>
    </div>
  );
};

const QrPreview = ({ data }: { data?: IData }) => {
  const { t } = useTranslation();
  const { vehicle } = data || {};

  if (!vehicle) return null;
  if (!vehicle?.customerEmployee?.uuid) {
    return <Typography.Text>Missing user UUID</Typography.Text>;
  }

  return (
    <React.Fragment>
      <QRCode
        height={200}
        className="my-3"
        value={vehicle.customerEmployee.uuid}
        renderAs="canvas"
      />
      <Typography.Text className="!text-white">{t("qr-code")}</Typography.Text>
    </React.Fragment>
  );
};

const TextPreview: React.FC<{
  value?: string;
  label: string;
  className?: string;
}> = ({ value, label, className = "" }) => {
  return (
    <div className={className}>
      <div className="text-[14px]">{label} : </div>
      <div className="text-[18px] font-bold"> {value || "-"} </div>
    </div>
  );
};

// interface IFindData {
//   fullName?: string;
//   phoneNumber?: string;
//   perSonType?: string;
//   vehicleType?: IVehicleType;
//   licensePlateNumber?: string;
//   trailerLicensePlateNumber?: string;
//   weight?: string;
//   trailerWeight?: string;
// }

// const findDataValue = ({ value }: { value?: ICustomerEmployee }): IFindData => {
//   if (value) {
//     const { firstName, lastName, phoneNumber, userType } = value;
//     const fullName =
//       !firstName && !lastName ? undefined : `${firstName} ${lastName}`;
//     let perSonType = typeOfUser[userType];

//     if (value?.userType === "SUPPLIER") {
//       const {
//         licensePlateNumber,
//         trailerLicensePlateNumber,
//         weight,
//         trailerWeight,
//         vehicleType,
//       } = value?.supplierDetail || {};
//       return {
//         fullName,
//         phoneNumber,
//         perSonType,
//         licensePlateNumber,
//         trailerLicensePlateNumber,
//         weight: weight?.toString(),
//         trailerWeight: trailerWeight?.toString(),
//         vehicleType,
//       };
//     }
//     if (value?.userType === "VISITOR") {
//       const {
//         licensePlateNumber,
//         trailerLicensePlateNumber,
//         weight,
//         trailerWeight,
//         vehicleType,
//       } = value?.visitorsDetail || {};
//       return {
//         fullName,
//         phoneNumber,
//         perSonType,
//         licensePlateNumber,
//         trailerLicensePlateNumber,
//         weight: weight?.toString(),
//         trailerWeight: trailerWeight?.toString(),
//         vehicleType,
//       };
//     }
//     if (value?.userType === "RECEIVER") {
//       const { licensePlateNumber, trailerLicensePlateNumber, vehicleType } =
//         value?.receiverDetail || {};
//       return {
//         fullName,
//         phoneNumber,
//         perSonType,
//         licensePlateNumber,
//         trailerLicensePlateNumber,
//         vehicleType,
//       };
//     }
//     return { fullName, phoneNumber, perSonType };
//   }

//   return {};
// };
export default EntranceDisplayScreen;
