import { CloseOutlined } from "@ant-design/icons";
import { Col, Drawer, DrawerProps, FormInstance, Row, Typography } from "antd";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import CButton from "components/button/c-button";
import { fireNotification } from "components/popup/notification";
import { usePageRoute } from "provider/page-route";
import { useAppLoading } from "provider/app-loading";

export interface IBaseVisible {
  visible: boolean;
  onClose: () => void;
}

const StyledDrawer = styled(Drawer)<{
  $action?: boolean;
  $divider?: boolean;
}>`
  font-family: kanit-regular;
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .c-title {
    padding-left: 12px;
    font-size: 16px;
  }
  .c-header {
    height: 55px;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    .anticon {
      font-size: 15px;
    }
  }
  .c-body {
    padding: 24px;
    height: ${({ $action }) =>
      $action ? "calc(100vh - 137px)" : "calc(100vh - 57px)"};
    overflow-y: auto;
  }
  .c-footer {
    height: ${({ $divider }) => ($divider ? "80px" : "0px")};
    border-top: ${({ $divider }) =>
      $divider ? "1px solid #00000029" : "1px solid #ffffff"};
  }
`;
interface CDrawerProps extends DrawerProps {
  children?: ReactNode;
  onSave?: (values: any) => void;
  onSaveAndContinue?: (values: any) => void;
  onCancel?: () => void;
  loading?: boolean;
  form?: FormInstance;
  resetFormAfterClose?: boolean;
  mutation?: {
    mutate: UseMutationResult;
    invalidateQueries?: string[];
    onBeforeSubmit?: (v: any) => any;
    onAfterSubmit?: (v: any) => void;
  };
  hide?: {
    cancel?: boolean;
    save?: boolean;
    saveAndContinue?: boolean;
  };
  className?: string;
}

const CDrawer: FC<CDrawerProps> = (props) => {
  const {
    children,
    title,
    onSave,
    onSaveAndContinue,
    onCancel,
    loading,
    form,
    mutation,
    forceRender = true,
    hide = {
      saveAndContinue: true,
    },
    resetFormAfterClose = true,
    className,
    width = "40vw",
    ...rest
  } = props;
  const _close = rest?.onClose as any;
  const { t } = useTranslation();

  const { pageKey } = usePageRoute();
  const { setAppLoading } = useAppLoading();

  const action = !!(onSave || onSaveAndContinue || onCancel || form);
  const qClient = useQueryClient();

  const _onSave = async () => {
    const { mutate, onBeforeSubmit, invalidateQueries, onAfterSubmit } =
      mutation || {};
    let values = await form?.validateFields();
    if (onSave) {
      return onSave(values);
    }
    if (onBeforeSubmit) {
      values = onBeforeSubmit(values);
    }
    setAppLoading(true);
    mutate?.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success", menu: pageKey });
        _onCancel();
        invalidateQueries?.forEach((k) => qClient.invalidateQueries([k]));
        onAfterSubmit?.(values);
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
          menu: pageKey,
        });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  const _onSaveAndContinue = async () => {
    const { mutate, onBeforeSubmit, invalidateQueries, onAfterSubmit } =
      mutation || {};
    let values = await form?.validateFields();
    if (onSaveAndContinue) {
      return onSaveAndContinue(values);
    }
    if (onBeforeSubmit) {
      values = onBeforeSubmit(values);
    }
    setAppLoading(true);
    mutate?.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success", menu: pageKey });
        invalidateQueries?.forEach((k) => qClient.invalidateQueries([k]));
        form?.resetFields();
        onAfterSubmit?.(values);
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
          menu: pageKey,
        });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  const _onCancel = () => {
    if (onCancel) {
      return onCancel();
    }
    form?.resetFields();
    _close();
  };

  return (
    <StyledDrawer
      {...rest}
      width={width}
      forceRender={forceRender}
      closeIcon={null}
      $action={action}
      $divider={action}
      afterVisibleChange={(e) => {
        if (!e && resetFormAfterClose) {
          form?.resetFields();
        }
        rest.afterVisibleChange?.(e);
      }}
    >
      <div className="c-header">
        <CloseOutlined
          className="cursor-pointer !text-primary-dark !text-[19px]"
          onClick={_close}
        />
        <Typography.Title level={5} className="c-title !text-primary-dark">
          {title}
        </Typography.Title>
      </div>
      <div className={`c-body ${className}`}>{children}</div>

      <div className="c-footer" hidden={!action}>
        <Row
          gutter={12}
          align="middle"
          justify="center"
          className="h-full w-full"
        >
          <Col span={6} hidden={!form || hide.cancel}>
            <CButton
              theme="white"
              onClick={_onCancel}
              className="w-full"
              loading={loading}
            >
              {t("cancel")}
            </CButton>
          </Col>
          <Col hidden={!form || hide.saveAndContinue}>
            <CButton
              onClick={_onSaveAndContinue}
              className="w-full"
              loading={loading}
              theme="white"
            >
              <Typography.Text
                ellipsis={{ tooltip: t("save-and-continue") }}
                className="!text-white"
              >
                {t("save-and-continue")}
              </Typography.Text>
            </CButton>
          </Col>
          <Col span={6} hidden={!form || hide.save}>
            <CButton
              theme="yellow"
              onClick={_onSave}
              className="w-full"
              loading={loading}
            >
              {t("save")}
            </CButton>
          </Col>
        </Row>
      </div>
    </StyledDrawer>
  );
};

export default CDrawer;
