import { Transfer, TransferProps } from "antd";
import { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Tras = Transfer as any;
const StyledTransfer = styled(Tras)`
  .ant-transfer-list-header {
    background-color: #e4e4e4;
    ${tw`!py-6`};
  }
  .ant-transfer-list-header-dropdown > svg {
    display: none;
  }
  .ant-transfer-operation > button {
    width: 40px;
    height: 30px;
  }
  .ant-transfer-list-body-search-wrapper {
    ${tw`!p-0`};
  }
  .ant-input-affix-wrapper {
    height: 40px;
    border-radius: 0px;
    border-color: #d9d9d9 !important;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-left: none;
    border-right: none;
    box-shadow: none !important;
  }
  .ant-checkbox {
    input,
    .ant-checkbox-inner {
      height: 20px !important;
      width: 20px !important;
    }
  }
  .ant-transfer-list-content-item-text {
    ${tw`flex`};
  }
`;

const CTransfer: FC<TransferProps<any>> = (props) => {
  return (
    <StyledTransfer
      listStyle={{
        height: 400,
        width: 550,
      }}
      {...props}
    />
  );
};

export default CTransfer;
