import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetScrollUsers } from "services/user";
import { UserMenuTypes } from "services/user/interface";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

export interface CSelectUserProps extends CSelectProps {
  userType?: UserMenuTypes;
}

const CSelectUser: FC<CSelectUserProps> = ({ userType, ...props }) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      placeholder={t("person")}
      {...props}
      useQuery={useGetScrollUsers}
      valueKey="id"
      titleKey="firstName"
      searchKey="name"
      translation
      queryParams={{ userType }}
    />
  );
};

export default CSelectUser;
