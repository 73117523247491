import { useQueryClient } from "@tanstack/react-query";
import { Col, Divider, Form, Row, Typography } from "antd";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { FormInstance } from "rc-field-form";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetCheckQuicklab,
  usePatchCheckQuicklab,
} from "services/check-quick-lab";
import { IQuestionForm } from "services/check-quick-lab/interface";
import { useGetSettingQuickLaps } from "services/setting-parameter-of-quick-lap";
import { ITopics } from "services/setting-parameter-of-quick-lap/interface";
import styled from "styled-components";

const CheckQuickLabEdit = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { formatDate } = useAppLanguage();
  const { setAppLoading } = useAppLoading();
  const qClient = useQueryClient();

  const { getCurrentParams, pageKey, dispatch } = usePageRoute();
  const { id } = getCurrentParams();

  const { data, isFetching } = useGetCheckQuicklab(id);
  const patch_check_quicklab = usePatchCheckQuicklab();
  const dataQuicklab = useGetSettingQuickLaps({
    pagination: false,
    isActive: true,
  });

  useEffect(() => {
    initailValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initailValue = () => {
    if (!data) return;
    const { description, status, questionForm, id } = data || {};
    questionForm?.map((item: any) => {
      const title = item?.title ? item.title : "notTitle";
      form.setFieldValue(title, item);
      return true;
    });

    form.setFieldsValue({
      id,
      description,
      status: status !== "WAITING_FOR_INSPECT" ? status : undefined,
    });
  };

  const onFinish = (values: any) => {
    const { description, status, id } = values;

    const questionForm = titleSet?.map((item) => {
      return values[item];
    });

    const dataValue = {
      id,
      description,
      status,
      questionForm: questionForm,
    };

    setAppLoading(true);
    patch_check_quicklab.mutate(
      { ...dataValue },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: pageKey });
          qClient.invalidateQueries(["get-check-quicklabs"]);
          qClient.invalidateQueries(["get-check-quicklab"]);
          dispatch({ action: "back" });
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: pageKey,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const { firstName, lastName, companyName } =
    data?.customerEmployee || {};
  const date = data?.createdAt
    ? formatDate({
        date: data?.createdAt,
        format: "nomal-full-time",
        keySeparator: "/",
      })
    : "-";
  const name = firstName && lastName ? firstName + " " + lastName : "-";
  const company = companyName || "-";
  const licensePlateNumber = data?.vehicle?.licensePlate || "-";

  const titleSet = dataQuicklab.data?.data
    .map((item) => {
      return item?.topics.map((e) => (e.title ? e.title : "notTitle"));
    })
    .flat();

  const disabled = data?.status !== "WAITING_FOR_INSPECT";

  return (
    <PageContent
      breadcrumb={{
        form,
        mutate: {
          onSaveAndContinue: false,
          onSave: disabled ? false : onFinish,
        },
      }}
      className="p-5"
      loading={isFetching}
    >
      <Form
        className="bg-white rounded-app"
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item name="id" hidden>
          <CInput />
        </Form.Item>
        <Row className="px-8 py-5">
          <Typography.Title level={4} style={{ color: "#48B074" }}>
            {t("ckeck-quick-lab")}
          </Typography.Title>
        </Row>
        <Divider className="!m-0" />
        <Row className="p-8 text-center">
          <Col span={24}>
            <Typography.Title level={4}>
              {t("quality-assurance-section")}
            </Typography.Title>
            <Typography.Text style={{ color: "#8F949D" }}>
              {t("result-sheet-quick-lab")}
            </Typography.Text>
          </Col>
        </Row>
        <Row className="px-8 pb-5">
          <Typography.Text style={{ color: "#48B074" }} className="py-3">
            {t("basic-information")}
          </Typography.Text>
          <Col span={24} className="p-5" style={{ backgroundColor: "#F5F5F5" }}>
            <Row gutter={[24, 16]}>
              <Col span={8}>
                <Row>
                  <Typography.Text style={{ color: "#8F949D" }}>
                    {`${t("date-get-material")} : `}
                  </Typography.Text>
                  <Typography.Text className="px-3">{date}</Typography.Text>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Typography.Text style={{ color: "#8F949D" }}>
                    {`${t("full-name")} : `}
                  </Typography.Text>
                  <Typography.Text className="px-3">{name}</Typography.Text>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Typography.Text style={{ color: "#8F949D" }}>
                    {`${t("plate-number")} : `}
                  </Typography.Text>
                  <Typography.Text className="px-3">
                    {licensePlateNumber}
                  </Typography.Text>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Typography.Text style={{ color: "#8F949D" }}>
                    {`${`${t("company-name")} / ${t("transport-name")}`} : `}
                  </Typography.Text>
                  <Typography.Text className="px-3">{company}</Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="px-8 py-5">
          <Typography.Text style={{ color: "#48B074" }} className="py-3">
            {t("parameters-quick-lab")}
          </Typography.Text>
        </Row>
        <QuestionForm disabled={disabled} />
        <Row className="px-8 py-5">
          <Typography.Text style={{ color: "#48B074" }} className="py-3">
            {t("check-status-quick-lab")}
          </Typography.Text>
          <Col span={24}>
            <Form.Item
              name="status"
              label={t("status")}
              rules={[{ required: true }]}
            >
              <CSelect.StatusQuicklab disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </PageContent>
  );
};

const QuestionForm = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();

  const data = useGetSettingQuickLaps({ pagination: false, isActive: true });

  const input = data.data?.data.map((item, index) => {
    const { parameterName } = translationObject({
      object: item,
      keys: ["parameterName"],
    });

    return (
      <Row className="px-8 py-5" gutter={[16, 16]} align="middle" key={item.id}>
        <Col span={6}>
          <Typography.Text>{parameterName}</Typography.Text>
        </Col>
        {item.topics.map((e, eIndex) => {
          if (!e.title) {
            return (
              <Col span={4} key={eIndex}>
                <Form.Item
                  shouldUpdate={(prev, next) => prev.notTitle !== next.notTitle}
                  className="!m-0 !p-0"
                >
                  {(form: FormInstance) => {
                    const validateStatus: IQuestionForm =
                      form.getFieldValue("notTitle");
                    return (
                      <Form.Item
                        label={parameterName}
                        name="notTitle"
                        rules={[{ required: true }]}
                        validateStatus={validateStatus?.status}
                        hasFeedback
                      >
                        <InputData
                          item={e}
                          text={parameterName}
                          disabled={disabled}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            );
          }
          return (
            <Col span={4} key={eIndex}>
              <Form.Item
                shouldUpdate={(prev, next) =>
                  prev[e?.title || ""] !== next[e?.title || ""]
                }
                className="!m-0 !p-0"
              >
                {(form: FormInstance) => {
                  const validateStatus: IQuestionForm = !e?.title
                    ? "error"
                    : form.getFieldValue([e?.title]);
                  return (
                    <Form.Item
                      label={e.title}
                      name={e.title}
                      rules={[{ required: true }]}
                      validateStatus={validateStatus?.status}
                      hasFeedback
                    >
                      <InputData item={e} disabled={disabled} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          );
        })}
        {index + 1 < data.data.data.length && <Divider className="!m-0" />}
      </Row>
    );
  });
  return (
    <React.Fragment>
      {input}
      <Row className="px-8 pt-5" align="middle">
        <Col span={24}>
          <Form.Item name="description" label={t("details")}>
            <CInput.TextArea
              placeholder={t("details")}
              rows={4}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const InputStyle = styled(CInput.Number)`
  .ant-input-number {
    background-color: unset !important;
    height: 43px !important;
  }
  .ant-input-number-suffix {
    padding-bottom: 5px !important;
  }
`;

const InputData = ({
  value,
  onChange,
  item,
  text,
  disabled,
}: {
  value?: IQuestionForm;
  onChange?: any;
  item: ITopics;
  text?: string;
  disabled: boolean;
}) => {
  const onChangeValues = (e: any) => {
    const values = checkSecondTopics(item, e);
    onChange(values);
  };
  return (
    <InputStyle
      value={value?.value}
      placeholder={text ? text : item.title}
      onChange={onChangeValues}
      disabled={disabled}
    />
  );
};

const checkSecondTopics = (item: ITopics, value: number): IQuestionForm => {
  const findValue = (item?.secondTopics || []).find(
    (e) => value >= (e?.min || 0) && value <= (e?.max || 0)
  );

  const findStatus: { [K in string]: "success" | "error" } = {
    PASSED: "success",
    FAILED: "error",
    VIP: "success",
  };
  const status = findValue?.checkSign
    ? findStatus[findValue?.checkSign]
    : "error";

  return { id: item.id, title: item.title, value: value, status: status };
};

export default CheckQuickLabEdit;
