import { Switch, SwitchProps } from "antd";
import styled from "styled-components";

const StyleSwitch = styled(Switch)``;

// interface CSwitchProps extends SwitchProps {
//   onChange?: any;
//   value?: boolean;
//   className?: string;
// }

const CSwitch = (props: SwitchProps) => {
  return (
    <StyleSwitch
      {...props}
    />
  );
};

export default CSwitch;
