import UserWithPDPACreateAndEditPage from "components/menu/user-management/create-user-pdpa";
import { usePageRoute } from "provider/page-route";
import { useGetSupplier, usePatchSupplier } from "services/supplier";

const PendingSupplierTypeEditPage = () => {
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const data_supplier = useGetSupplier(id);
  const patch = usePatchSupplier();

  return (
    <UserWithPDPACreateAndEditPage
      menu="supplier"
      action={{ get: data_supplier, patch }}
      pendingListPage
      notDisablePending={data_supplier.data?.acceptStatus === "PENDING"}
    />
  );
};

export default PendingSupplierTypeEditPage;
