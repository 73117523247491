import { Col, Divider, Row, Typography } from "antd";
import CImage from "components/display/c-image";
import { FC, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { BorderCarImage } from "assets/images";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import CButtonIcon from "components/button/c-button-icon";
import Icon, { EditFilled, EyeFilled } from "@ant-design/icons";
import { BinIcon } from "assets/icons/general";
import { IFloor } from "services/floor/interface";
import { useDeleteFloor, useGetFloor } from "services/floor";
import { useAppLoading } from "provider/app-loading";
import { fireNotification } from "components/popup/notification";
import { usePageRoute } from "provider/page-route";
import { fireConfirmDelete } from "components/popup/confirm";
import { useQueryClient } from "@tanstack/react-query";
import CModal from "components/display/c-modal";
import ImageMarker from "react-image-marker";
import { IMark } from "services/mark/interface";
import MapPinMarkerComponent from "../edit/step-3/map-pin-marker/marker-component";
import CIndicator from "components/display/c-indicator";

const Wrapper = styled.div`
  ${tw`bg-white rounded-app shadow mb-4`};
  overflow-x: hidden;
`;

const VehicleFloorDesciptionCard: FC<IFloor> = ({
  floorName,
  carCapacity,
  carUnoccupied,
  motorCapacity,
  id,
  motorUnoccupied,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const { setAppLoading } = useAppLoading();
  const { pageKey, dispatch } = usePageRoute();

  const del_floor = useDeleteFloor();
  const qClient = useQueryClient();

  const totalCapacity = (carCapacity || 0) + (motorCapacity || 0);
  const totalAvailable = (carUnoccupied || 0) + (motorUnoccupied || 0);

  const onDelete = async () => {
    const confirm = await fireConfirmDelete();
    if (!confirm) return;

    setAppLoading(true);
    del_floor.mutate(
      { id },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: pageKey });
          qClient.invalidateQueries(["get-floor"]);
          qClient.invalidateQueries(["get-floors"]);
          qClient.invalidateQueries(["parking-count"]);
        },
        onError: ({ message }) => {
          fireNotification({ type: "error", message, menu: pageKey });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const onEdit = () => {
    dispatch({
      action: "push",
      pageKey: "vehicle-management.floors-edit",
      params: { id, floorName },
    });
  };

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <Wrapper>
      <ViewMapDetailModal id={id} visible={visible} onClose={toggle} />
      <Row className="px-5 py-3" align="middle" gutter={6}>
        <Col className="text-[20px]">{floorName || "x"}</Col>
        <Col className="ml-auto">
          <CButtonIcon
            theme="white"
            style={{ width: 30, height: 30 }}
            icon={<EyeFilled />}
            onClick={toggle}
          />
        </Col>
        <Col>
          <CButtonIcon
            theme="white"
            style={{ width: 30, height: 30 }}
            icon={<EditFilled />}
            onClick={onEdit}
          />
        </Col>
        <Col>
          <CButtonIcon
            theme="white"
            style={{ width: 30, height: 30 }}
            icon={<Icon component={BinIcon} />}
            onClick={onDelete}
          />
        </Col>
      </Row>
      <Divider className="!m-0" />
      <Row className="p-5" gutter={6}>
        <Col span={4}>
          <div
            style={{ backgroundColor: "#F8F8F8" }}
            className="p-4 rounded-app center"
          >
            <CImage width={80} height="auto" src={BorderCarImage} />
          </div>
        </Col>
        <Col span={4} className="center flex-col">
          <Typography.Title level={5}>
            {numeral(totalCapacity).format("0,0")}
          </Typography.Title>
          <Typography.Text type="secondary">
            {t("total-parking-area")}
          </Typography.Text>
        </Col>
        <Col span={1} className="center">
          <Divider type="vertical" style={{ height: 69 }} />
        </Col>
        <Col span={4} className="center flex-col">
          <Typography.Title level={5}>
            {numeral(totalAvailable).format("0,0")}
          </Typography.Title>
          <Typography.Text type="secondary">
            {t("available-parking-area")}
          </Typography.Text>
        </Col>
        <Col span={1} className="center">
          <Divider type="vertical" style={{ height: 69 }} />
        </Col>
        <Col span={10} className="center">
          {/* comming soon... */}
        </Col>
      </Row>
    </Wrapper>
  );
};

const ViewMapDetailModal: FC<{
  visible: boolean;
  onClose: () => void;
  id: number;
}> = ({ visible, onClose, id }) => {
  const { t } = useTranslation();
  const { data, isFetching, isError, error } = useGetFloor(id);

  const getMarkers = (marks: IMark[]) => {
    return marks.map((item) => {
      return { ...item, top: Number(item.yAxis), left: Number(item.xAxis) };
    });
  };

  const getContent = () => {
    if (isFetching) {
      return <CIndicator height={400} />;
    }

    if (isError || !data) {
      return (
        <div style={{ height: 400 }} className="center">
          <Typography.Text>{error?.message}</Typography.Text>
        </div>
      );
    }
    const marks = getMarkers(data.marks || []);
    return (
      <ImageMarker
        markers={marks}
        src={data.mediaObject?.url || ""}
        markerComponent={(props: any) => {
          const mark = props as IMark;
          return (
            <MapPinMarkerComponent
              cursorPointer
              mark={mark}
              animationKey={0}
              onClick={() => {
                console.log(mark);
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <CModal title={t("map")} width={800} visible={visible} onCancel={onClose}>
      {getContent()}
    </CModal>
  );
};

export default VehicleFloorDesciptionCard;
