import { IUserTypeSelectData } from "components/input/c-user-type";
import { ComponentType, createContext, useContext, useState } from "react";
import { AccessibilityDeterminedByPeopleContext } from "./interface";

const Context = createContext<AccessibilityDeterminedByPeopleContext>(
  {} as AccessibilityDeterminedByPeopleContext
);

const withAccessibilityDeterminedPeople = (Component: ComponentType) => {
  return () => {
    const [accessibility, setAccessibility] = useState();
    const [userType, setUserType] = useState<IUserTypeSelectData>({
      title: "user-management.guest",
      userType: "GUEST",
    });

    return (
      <Context.Provider
        value={{ userType, setUserType, accessibility, setAccessibility }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useAccessibilityDeterminedPeople = () => useContext(Context);
export default withAccessibilityDeterminedPeople;
