import { throwResponse } from "config/axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
} from "@tanstack/react-query";
import { IParking, IParkingParams } from "./interface";
import { IResponse } from "config/axios/interface";
import { UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

export const useGetParkings = (
  params?: IParkingParams
): UseQueryResult<IResponse<IParking[]>> => {
  return useQuery(
    ["get-parkings", params],
    async () => {
      const res = await axios.get("/parking", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useParkingsScroll = (
  params?: IParkingParams,
): UseInfiniteQueryResult<IResponse<IParking[]>, Error> => {
  return useInfiniteQuery(
    ["get-parkings", params],
    async ({ pageParam }) => {
      const res = await axios.get("/parking", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetParking = (id?: string): UseQueryResult<IParking> => {
  return useQuery(
    ["get-parking", id],
    async () => {
      const res = await axios.get(`/parking/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostParking = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post(`/parking`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchParking = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/parking/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteParking = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/parking/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportExcelParking = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post("/export-excel/parking/summary-report-excel", data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
