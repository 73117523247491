import { CameraFilled } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import CImage from "components/display/c-image";
import CSpace from "components/display/c-space";
import Container from "components/display/container";
import { useTranslation } from "react-i18next";

const FindVehicleDisplayCar = () => {
  const { t } = useTranslation();
  return (
    <Container padding="p-5" height="calc(100vh - 209px)">
      <Row>
        <Col>
          <Typography.Title level={3}>{t("camera")}</Typography.Title>
        </Col>
        <Col className="ml-auto">
          <CSpace>
            <CameraFilled />
            <Typography.Text>Camera</Typography.Text>
          </CSpace>
        </Col>
      </Row>
      <CImage
        className="mt-5"
        wrapperStyle={{ height: "calc(100% - 60px)" }}
        style={{ objectFit: "cover" }}
        height="100%"
        width='100%'
        src={
          "http://driving-tests.org/wp-content/uploads/2012/02/empty-space.jpg"
        }
      />
    </Container>
  );
};

export default FindVehicleDisplayCar;
