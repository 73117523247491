import { TableColumnsType, Divider, Form } from "antd";
import CAvatar from "components/display/c-avatar";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import CInput from "components/input/c-input";
import CUserTypeSelect from "components/input/c-user-type";
import { ISearchBoxElement } from "components/layout/page-search-box";
import { useAccessibilityDeterminedPeople } from "provider/accessibility-determined-by-people";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

const AccessibilityDeterminedByPersonContent = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const { userType, setUserType, accessibility } =
    useAccessibilityDeterminedPeople();

  if (!accessibility) return null;

  const toggle = () => {
    setVisible(!visible);
  };

  const elements: ISearchBoxElement[] = [
    {
      name: "a",
      label: t("identification-card-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "b",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "c",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: TableColumnsType = [
    {
      title: t("profile-picture"),
      width: 120,
      align: "center",
      render: () => {
        return <CAvatar />;
      },
    },
    {
      title: t("identification-card-number"),
    },
    {
      title: t("full-name"),
    },
    {
      title: t("phone-number"),
    },
  ];

  const onSearch = (values: any) => {
    //
  };

  return (
    <div className="bg-white">
      <CreateAndEditDrawer visible={visible} onClose={toggle} />
      <div className="p-5">
        <CUserTypeSelect onChange={setUserType} value={userType} />
      </div>
      <Divider className="!m-0" />
      <CTable
        action={{
          searchBox: { elements: elements, onFinish: onSearch, colSpan: 12 },
        }}
        wrapperClassName="!mt-0"
        title={t(userType.title, { ns: "menu" })}
        columns={columns}
        onAdd={{ onClick: toggle }}
      />
    </div>
  );
};

const CreateAndEditDrawer: FC<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const columns: TableColumnsType = [
    {
      title: t("profile-picture"),
      width: 120,
      align: "center",
      render: () => {
        return <CAvatar />;
      },
    },
    {
      title: t("identification-card-number"),
    },
    {
      title: t("full-name"),
    },
    {
      title: t("phone-number"),
    },
  ];

  return (
    <CDrawer
      title={t("add")}
      form={form}
      visible={visible}
      onClose={onClose}
      width="40vw"
    >
      <Form form={form} layout="vertical">
        <Form.Item label={t("search")} name="search">
          <CInput.Debounce />
        </Form.Item>
        <Form.Item name="select">
          <CTable.Select columns={columns} />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default AccessibilityDeterminedByPersonContent;
