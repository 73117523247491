import { useQueryClient } from "@tanstack/react-query";
import { TableColumnsType, Divider, Form, Typography } from "antd";
import CAvatar from "components/display/c-avatar";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import CUserTypeSelect from "components/input/c-user-type";
import { ISearchBoxElement } from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { useFaceComparison } from "provider/face-comparison";
import { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteFaceInformation,
  useGetFaceInformations,
  useGetFaceInformationsNotIn,
  usePostFaceInformation,
} from "services/face-information";
import {
  IFaceInformation,
  IFaceInformationNotIn,
  IFaceInformationNotInParams,
  IFaceInformationParams,
} from "services/face-information/interface";

const FaceComparisonContent = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const { userType, setUserType, faceComparison } = useFaceComparison();

  const [params, setParams] = useState<IFaceInformationParams>({});

  const data_face_information = useGetFaceInformations({
    ...params,
    group: faceComparison?.id,
    userType: userType?.userType,
  });

  const delete_face_information = useDeleteFaceInformation();

  if (!faceComparison) {
    return (
      <div style={{ height: "calc(100vh - 218px)" }} className="center">
        {t("please-select")}
      </div>
    );
  }

  const toggle = () => {
    setVisible(!visible);
  };

  const elements: ISearchBoxElement[] = [
    {
      name: "identityNumber",
      label: t("identification-card-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: TableColumnsType<IFaceInformation> = [
    {
      title: t("profile-picture"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc?.facePic?.url} />;
      },
    },
    {
      title: t("identification-card-number"),
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis>
            {rc?.identityNumber || "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.user?.firstNameEn || "";
        const lastName = rc?.user?.lastNameEn || "";
        if (!firstName && !lastName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis>{rc?.phoneNumber || "-"}</Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values, page: 1 });
  };

  return (
    <Container height="auto" padding="p-0">
      <CreateAndEditDrawer visible={visible} toggle={toggle} />
      <div className="p-5">
        <CUserTypeSelect onChange={setUserType} value={userType} />
      </div>
      <Divider className="!m-0" />
      <CTable
        selectAble={false}
        shadow={false}
        action={{
          searchBox: { elements: elements, onFinish: onSearch, colSpan: 12 },
        }}
        onDelete={{
          mutation: delete_face_information,
          invalidateQueries: [
            "get-face-informations",
            "get-face-compare-group",
            "get-face-compare-group-id",
          ],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        wrapperClassName="!mt-0"
        title={t(userType.title, { ns: "menu" })}
        columns={columns}
        onAdd={{ onClick: toggle }}
        query={data_face_information}
        loading={data_face_information.isFetching}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </Container>
  );
};

const CreateAndEditDrawer: FC<{ visible: boolean; toggle: any }> = ({
  visible,
  toggle,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { faceComparison } = useFaceComparison();

  const [params, setParams] = useState<IFaceInformationNotInParams>({});

  const { data, isFetching } = useGetFaceInformationsNotIn({
    ...params,
    faceCompareGroup: faceComparison?.id,
  });

  const qClient = useQueryClient();
  const post_face_information = usePostFaceInformation();

  const { setAppLoading } = useAppLoading();

  const columns: TableColumnsType<IFaceInformationNotIn> = [
    {
      title: t("profile-picture"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url} />;
      },
    },
    {
      title: t("identification-card-number"),
      render: (_, rc) => {
        return <Typography.Text ellipsis>{rc?.identityCode}</Typography.Text>;
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstNameEn || "";
        const lastName = rc?.lastNameEn || "";
        if (!firstName && !lastName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        return <Typography.Text>{rc?.phoneNumber || "-"}</Typography.Text>;
      },
    },
  ];

  const onFinish = (value: any) => {
    post_face_information.mutate(
      { faceCompareGroup: faceComparison?.id, user: value.select },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "access-control" });
          qClient.invalidateQueries(["get-face-informations"]);
          qClient.invalidateQueries(["get-face-compare-group"]);
          qClient.invalidateQueries(["get-face-compare-group-id"]);
          form.resetFields();
          toggle();
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "access-control",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const onSearch = (e: any) => {
    setParams({ ...params, search: e.target.value });
  };

  const onClose = () => {
    toggle();
  };

  return (
    <CDrawer
      title={t("add")}
      form={form}
      onSave={onFinish}
      visible={visible}
      onClose={onClose}
      width="40vw"
      loading={post_face_information?.isLoading}
    >
      <Form form={form} layout="vertical">
        <Form.Item label={t("search")} name="search">
          <CInput onChange={onSearch} />
        </Form.Item>
        <Form.Item name="select">
          <CTable.Select
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isFetching}
            pagination={{
              total: data?.count,
              current: params?.page,
              pageSize: params.limit,
              onChange: (page, limit) => {
                setParams({ ...params, page, limit });
              },
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default FaceComparisonContent;
