import { Col, Divider, Row, Typography } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled(Row)`
  height: 45px;
  width: 100%;
  background: white;
  cursor: pointer;
`;

interface EditBlockProps {
  value?: string;
  onClick?: () => void;
}

const EditBlock: FC<EditBlockProps> = ({ value, onClick }) => {
  const { t } = useTranslation();
  return (
    <Container onClick={onClick} align="middle" className="px-5 rounded-app">
      <Col style={{ maxWidth: "70%" }}>
        <Typography.Text ellipsis>{value || t("select")}</Typography.Text>
      </Col>
      <Col className="ml-auto text-[20px]">
        <Divider type="vertical" style={{ height: 25 }} /> {t("edit")}
      </Col>
    </Container>
  );
};

export default EditBlock;
