import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ISettingQuickLap, ISettingQuickLapParams } from "./interface";

export const useGetSettingQuickLaps = (
  params: ISettingQuickLapParams
): UseQueryResult<IResponse<ISettingQuickLap[]>> => {
  return useQuery(
    ["get-qucik-laps", params],
    async () => {
      const res = await axios.get("/quicklab", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostSettingQuickLap = (): UseMutationResult =>{
  return useMutation(async (values:any)=>{
    const res = await axios.post("/quicklab", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  })
}
export const useDeleteSettingQuickLap = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/quicklab/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
export const useDeleteSettingQuickLaps = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/quicklab/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchSettingQuickLap = (): UseMutationResult => {
  return useMutation(async ({ id, ...values }: any) => {
    const res = await axios.patch(`/quicklab/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetSettingQuickLap = (
  id?: number | string
): UseQueryResult<ISettingQuickLap, Error> => {
  return useQuery(
    ["get-qucik-lap", id],
    async () => {
      const res = await axios.get(`/quicklab/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!id,
    }
  );
};