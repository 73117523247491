import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import withNotificationSetting, {
  useNotificationSetting,
} from "provider/notification-setting";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import ContentPage from "./content";
import { dataNoti, IDataNoti } from "./data-noti";

const NotificationPage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <SideBar />
        </Col>
        <Col span={18} className="px-5 pt-1">
          <ContentPage />
        </Col>
      </Row>
    </PageContent>
  );
};

const StyleText = styled.div`
  ${tw`p-3 text-[20px]`}
`;

const DownArrow = styled(DownOutlined)`
  font-size: 12px;
  font-weight: bold;
`;
const UpArrow = styled(UpOutlined)`
  font-size: 12px;
  font-weight: bold;
`;
const PageSidebarItemInner = styled.div<{ $visible: boolean }>`
  ${tw`rounded-app `}
  transition: max-height ${(props) => (props.$visible ? "1s" : "0.2s")};
  overflow: hidden;
  max-height: ${(props) => (props.$visible ? "1000px" : "0px")};
  background-color: #f5f5f5;
`;

const SideBar = () => {
  const { t } = useTranslation();
  const content = dataNoti.map((item, index) => {
    return <ContentItem item={item} key={index} />;
  });

  return (
    <React.Fragment>
      <InfiniteSidebar title={t("notification-list")} content={content} />
    </React.Fragment>
  );
};

const ContentItem = ({ item }: { item: IDataNoti }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState<IDataNoti>();
  const [visible, setVisible] = useState<boolean>(false);
  const toggleSubMenu = () => {
    setVisible(!visible);
  };

  const getExpandIcon = (item: IDataNoti) => {
    if (visible) {
      return <UpArrow />;
    }
    return <DownArrow />;
  };

  const expand = getExpandIcon(item);

  return (
    <div className="mb-3">
      <CCard.BasicCard
        active={key === item}
        onClick={() => {
          setKey(item);
          toggleSubMenu();
        }}
      >
        <Row justify="space-between" className="px-3 py-4">
          <Col span={20}>
            <Typography.Text ellipsis={{ tooltip: t(item.title) }}>
              {t(item.title)}
            </Typography.Text>
          </Col>
          <Col>{expand}</Col>
        </Row>
      </CCard.BasicCard>
      <PageSidebarItemInner
        // hidden={!xl}
        $visible={visible && item === key}
        className={visible && item === key ? "mt-2" : ""}
      >
        {item.children?.map((itemChildren, indexChildren) => {
          return <SindBarContent item={itemChildren} key={indexChildren} />;
        })}
      </PageSidebarItemInner>
    </div>
  );
};

const SindBarContent = ({ item }: { item: IDataNoti }) => {
  const { t } = useTranslation();
  const { keyStatus, setKeyStatus } = useNotificationSetting();

  useEffect(() => {
    setKeyStatus(dataNoti[0].children?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="px-3 cursor-pointer"
      onClick={() => {
        setKeyStatus(item);
      }}
    >
      <StyleText
        className={keyStatus === item ? "text-[#2F9A5D]" : "text-[#202A3C]"}
      >
        {t(item.title)}
      </StyleText>
    </div>
  );
};

export default withNotificationSetting(NotificationPage);
