import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { TVehicleQueueStatus } from "services/status-queue-car/interface";
import styled from "styled-components";

type Type = {
  bg: string;
  color: string;
  text: string;
};

const BLOCK_STATUS_QUEUE_CAR: { [K in TVehicleQueueStatus]: Type } = {
  VEHICLE_SPRAY: { bg: "#EAF7EC", color: "#438860", text: "check-the-spray" },
  VEHICLE_CONDITION: {
    bg: "#FFEDF2",
    color: "#FF3366",
    text: "check-the-condition-of-the-car",
  },
  VEHICLE_QUICKLAB: {
    bg: "#F7F7FD",
    color: "#8C88FF",
    text: "ckeck-quick-lab",
  },
  WAITING_QUEUE_IN: {
    bg: "#E8F3FF",
    color: "#4A90E2",
    text: "waiting-for-the-weighing-queue-in",
  },
  WAIT_FOR_LOAD: {
    bg: "#FFF2EA",
    color: "#F7751F",
    text: "wait-for-load-release",
  },
  START_LOADING: {
    bg: "#FFF8ED",
    color: "#F9A91C",
    text: "start-loading-release",
  },
  FINISH_LOADING: {
    bg: "#FFFDE6",
    color: "#FFD806",
    text: "finish-loading-release",
  },
  WEIGHED_OUT: {
    bg: "#EFFEFF",
    color: "#14D9E2",
    text: "weighed-out",
  },
  TVOP: {
    bg: "#F8FFED",
    color: "#9BD646",
    text: "the-car-has-successfully-left-the-TVOP",
  },
};

interface IBlock {
  value: TVehicleQueueStatus;
}

const BlockStyle = styled(Typography.Text)<{ bg: string; color: string }>`
  height: 35px;
  width: 80%;
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
  padding: 10px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

const BlockColorQueueStatus = ({ value }: IBlock) => {
  const { t } = useTranslation();
  const theme = BLOCK_STATUS_QUEUE_CAR[value];

  return (
    <BlockStyle
      bg={theme?.bg}
      color={theme?.color}
      ellipsis={{ tooltip: t(theme?.text) }}
    >
      {t(theme?.text)}
    </BlockStyle>
  );
};

export default BlockColorQueueStatus;
