import ReactApexChart from "react-apexcharts";

const ColumnChartHome = ({
  categories,
  series,
  height = 250,
  colors,
}: {
  categories ?: any[];
  series ?: { name : string , data: any}[];
  height?: number;
  colors?: string[];
}) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ["#425F4E", "#438860", "#BF9C3D", "#E0B33C"],
    plotOptions: {
      bar: {
        columnWidth: "55%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value.toString();
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        type="bar"
        options={options}
        series={series}
        height={height}
      />
    </div>
  );
};

export default ColumnChartHome;
