import { InfoCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Col, Divider, Form, Row, Tooltip, Typography } from "antd";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";

import { usePageRoute } from "provider/page-route";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetAccessControl,
  usePatchAccessControls,
  usePostAccessControls,
} from "services/access-control";
import { IAccessControl } from "services/access-control/interface";

const ResourceAccessControlEquipmentCreateAndEditPage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();
  const post_access_control = usePostAccessControls();
  const { data } = useGetAccessControl(id);
  const patch_access_control = usePatchAccessControls();
  const qClient = useQueryClient();
  const { setAppLoading } = useAppLoading();

  // const statusDevice = [
  //   {
  //     value: 0,
  //     label: t("unknow"),
  //   },
  //   {
  //     value: 1,
  //     label: t("online-status"),
  //   },
  //   {
  //     value: 2,
  //     label: t("offline-status"),
  //   },
  // ];

  const onBeforeSubmit = (values: IAccessControl) => {
    if (!values.treatyType) {
      return { ...values, treatyType: "hiksdk_net" };
    } else {
      return { ...values };
    }
  };

  useEffect(() => {
    if (data) {
      const status =
        data?.status === "online" ? 1 : data?.status === "offline" ? 2 : 0;
      form.setFieldsValue({ ...data, status });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const pageKey = getCurrentKey();

  const onSaveAndContinue = (value: any) => {
    post_access_control.mutate(value, {
      onSuccess: () => {
        fireNotification({ type: "success", menu: "resource-management" });
        qClient.invalidateQueries(["get-access-control"]);
        qClient.invalidateQueries(["get-access-controls"]);
        form?.resetFields();
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
          menu: "resource-management",
        });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  return (
    <PageContent
      breadcrumb={{
        form,
        mutate: {
          mutation: id ? patch_access_control : post_access_control,
          invalidateQueries: ["get-access-control", "get-access-controls"],
          onSaveAndContinue: id ? false : onSaveAndContinue,
          onBeforeUpdate: onBeforeSubmit,
        },
      }}
    >
      <div className="m-5">
        <Form
          initialValues={{ isActive: true }}
          form={form}
          layout="vertical"
          className="bg-white m-4"
        >
          <Form.Item hidden name="id">
            <CInput />
          </Form.Item>
          <div className="py-3 px-5">
            <Typography.Title level={3} className="!text-green-600">
              {t(pageKey || "", { ns: "menu" })}
            </Typography.Title>
          </div>
          <Divider className="!m-0" />
          <div className="py-4 px-16">
            <Row gutter={[12, 6]}>
              <Col span={24}>
                <Typography.Title level={5} className="!text-green-600">
                  {t("basic-information")}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="devName"
                  label={t("equipment-name")}
                  rules={[{ required: true }]}
                >
                  <CInput placeholder={t("equipment-name")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="devCode" label={t("serial-number")}>
                  <CInput placeholder={t("serial-number")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="devIp"
                  label={t("internet-protocol-address")}
                  rules={[{ required: true }]}
                >
                  <CInput placeholder={t("internet-protocol-address")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="devPort"
                  label={t("port")}
                  rules={[{ required: true }]}
                >
                  <CInput placeholder={t("port")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="macAddress"
                  label={t("mac address")}
                  rules={[{ required: true }]}
                >
                  <CInput placeholder={t("mac address")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="treatyType" label={t("treaty-type")}>
                  <CInput
                    defaultValue={"hiksdk_net"}
                    placeholder={t("treaty-type")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="status" label={t("status")}>
                  <CSelect
                    placeholder={t("status")}
                    valueOptions={{
                      values: [
                        { id: 0, title: t("unknow") },
                        { id: 1, title: t("online-status") },
                        { id: 2, title: t("offline-status") },
                      ],
                      valueKey: "id",
                      titleKey: "title",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gateName"
                  label={t("position-device")}
                  rules={[{ required: true }]}
                >
                  <CSelect.GateName />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Typography.Title level={5} className="!text-green-600">
                  {t("password-setting")}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="username"
                  label={t("username")}
                  rules={[{ required: true }]}
                >
                  <CInput placeholder={t("username")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="password"
                  label={
                    <span className="w-full !truncate">
                      {t("password")}
                      <Tooltip placement="topLeft" title={t("pass-hash")}>
                        <span className="!text-red-500 !text-center pl-4 !justify-center !items-center ">
                          <InfoCircleOutlined /> {t("pass-hash")}
                        </span>
                      </Tooltip>
                    </span>
                  }
                  rules={[{ required: true }]}
                >
                  <CInput.Password
                    visibilityToggle={false}
                    placeholder={t("password")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </PageContent>
  );
};

export default ResourceAccessControlEquipmentCreateAndEditPage;
