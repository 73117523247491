import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ITermsCondition, ITermsConditionParams } from "./interface";

export const useGetTermAndCondition = (
  params?: ITermsConditionParams
): UseQueryResult<IResponse<ITermsCondition[]>, Error> => {
  return useQuery(
    ["get-terms-condition", params],
    async () => {
      const res = await axios.get("/terms-and-conditions");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePatchTermAndCondition = (): UseMutationResult<
  IResponse<ITermsCondition[]>
> => {
  return useMutation(
    ["patch-terms-condition"],
    async ({ id, ...values }: any) => {
      const res = await axios.patch(`/terms-and-conditions/${id}`, values);
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    }
  );
};
