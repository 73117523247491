import { Col, Row, Typography } from "antd";
import CIndicator from "components/display/c-indicator";
import CCard from "components/display/card";
import Container from "components/display/container";
import { fireNotification } from "components/popup/notification";
import { useVehicleSocket } from "provider/vehicle-socket";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequestVideoUrl } from "services/stream-video";
import styled from "styled-components";

const TransitionDualVideo = () => {
  const { t } = useTranslation();
  return (
    <Container height={400}>
      <Row gutter={[0, 12]}>
        <Col span={8} className="text-center">
          <Typography.Text>{t("vehicle-video")}</Typography.Text>
        </Col>
        <Col span={8} className="text-center">
          <Typography.Text>
            {t("face-video")} ({t("truck")})
          </Typography.Text>
        </Col>
        <Col span={8} className="text-center">
          <Typography.Text>
            {t("face-video")} ({t("general")})
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Player />
        </Col>
        {/* <Col span={24}>
          <Row gutter={6} style={{ height: 50 }} align="middle">
            <LicensePlatePreview />
          </Row>
        </Col> */}
      </Row>
    </Container>
  );
};



const size = {
  height: 335,
  width: "100%",
};
const PlayerWrapper = styled.div`
  height: ${size.height}px;
  .parent-wnd {
    display: flex;
    div:nth-child(4) {
      display: none !important;
    }
    div {
      width: 33.33333333% !important;
      height: 100% !important;
          canvas {
              width: 100%;
              height: 100%;
              border: 1px solid red !important;
            }
    }
`;

const Player = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { selected } = useVehicleSocket();
  const [jsDecoder, setJsDecoder] = useState<any>();
  const truckFaceRequest = useRequestVideoUrl();
  const generalFaceRequest = useRequestVideoUrl();
  const vehicleRequest = useRequestVideoUrl();

  const prevTruckFace = useRef<number | null>(null);
  const prevGeneralFace = useRef<number | null>(null);
  const prevVehicle = useRef<number | null>(null);

  useEffect(() => {
    if (!ref) return;
    initJsDecoderInstance();

    return () => {
      if (jsDecoder) {
        //
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    requestVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const initJsDecoderInstance = () => {
    let js = new window.JSPlugin({
      szId: "wind-player",
      iType: 1,
      iWidth: ref.current?.clientWidth,
      iHeight: 350,
      iMaxSplit: 2,
      iCurrentSplit: 2,
      szBasePath: process.env.PUBLIC_URL + "/dist",
      oStyle: {
        border: "#343434",
        borderSelect: "red",
        background: "#4C4B4B",
      },
    });
    setJsDecoder(js);
  };

  const requestVideo = () => {
    if (!selected) return;

    const { id: vehicleId } = selected.vehicle.encodeDevice || {};
    const { id: generalId } = selected.generalFace?.encodeDevice || {};
    const { id: truckId } = selected.truckFace?.encodeDevice || {};

    if (vehicleId && vehicleId !== prevVehicle.current) {
      prevVehicle.current = vehicleId;
      requestVehicle(vehicleId);
    }

    if (generalId && generalId !== prevGeneralFace.current) {
      prevGeneralFace.current = generalId;
      requestGeneralFace(generalId)
    }

    if (truckId && truckId !== prevTruckFace.current) {
      prevTruckFace.current = truckId;
      requestTruckFace(truckId)
    }
  };

  const requestGeneralFace = (id: number) => {
    generalFaceRequest.mutate(
      { encodeDeviceId: id, streamType: "SUB" },
      {
        onSuccess: ({ url }) => {
          jsDecoder?.JS_Play(url, { playURL: url }, 2);
        },
        onError: ({ message }) => {
          fireNotification({
            message,
            menu: "vehicle-management",
            type: "error",
          });
        },
      }
    );
  };

  const requestTruckFace = (id: number) => {
    truckFaceRequest.mutate(
      { encodeDeviceId: id, streamType: "SUB" },
      {
        onSuccess: ({ url }) => {
          jsDecoder?.JS_Play(url, { playURL: url }, 1);
        },
        onError: ({ message }) => {
          fireNotification({
            message,
            menu: "vehicle-management",
            type: "error",
          });
        },
      }
    );
  };

  const requestVehicle = (id: number) => {
    vehicleRequest.mutate(
      { encodeDeviceId: id, streamType: "SUB" },
      {
        onSuccess: ({ url }) => {
          console.log(url)
          jsDecoder?.JS_Play(url, { playURL: url }, 0);
        },
        onError: ({ message }) => {
          fireNotification({
            message,
            menu: "vehicle-management",
            type: "error",
          });
        },
      }
    );
  };

  const isLoading =
    vehicleRequest.isLoading ||
    truckFaceRequest.isLoading ||
    generalFaceRequest.isLoading;

  return (
    <PlayerWrapper ref={ref}>
      <CCard.EmptySelectVideo
        style={{ height: "100%", width: "100%" }}
        type="empty"
        hidden={!!selected}
      />
      <CIndicator height="100%" hidden={!isLoading} />
      <div
        hidden={!selected || isLoading}
        style={{ height: "100%", width: "100%" }}
        id="wind-player"
      ></div>
    </PlayerWrapper>
  );
};

export default TransitionDualVideo;
