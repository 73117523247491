import React, { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const StyledContainer = styled.div<{
  height: string | number;
  bgColor: string;
  $scroll: boolean;
}>`
  height: ${({ height }) =>
    typeof height === "number" ? `${height}px` : height};
  min-height: 1px;
  background-color: ${({ bgColor }) => bgColor};
  ${tw`rounded-app shadow-sm`};
  overflow-y: ${({ $scroll }) => ($scroll ? "auto" : "")};
  // overflow-x: hidden;
`;

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: string | number;
  bgColor?: string;
  scrollY?: boolean;
  padding?: "p-0" | "p-1" | "p-2" | "p-3" | "p-4" | "p-5";
}

const Container: FC<ContainerProps> = ({
  height = 300,
  children,
  bgColor = "white",
  scrollY = false,
  padding = "p-4",
  className,
  ...rest
}) => {
  return (
    <StyledContainer
      $scroll={scrollY}
      bgColor={bgColor}
      height={height}
      className={`${padding} ${className}`}
      {...rest}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
