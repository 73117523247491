import { Checkbox, Col, Form, Row, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import CInput from "components/input/c-input";
import CRangePicker from "components/input/c-range-picker";
import CSelect from "components/input/c-select";
import CSelectCompanyNameTags from "components/input/c-select/company-name-tags";
import PageContent from "components/layout/page-content";
import dayjs from "config/dayjs";
import AccessReportDoorLogProvider, {
  useReportDoorLog,
} from "provider/report-door-log";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import AccesscontrolAllReportContent from "./content";

const SidebarWrapper = styled.div`
  ${tw`bg-white shadow-sm`};
  height: calc(100vh - 104px);
`;

const AccesscontrolAllReport = () => {
  return (
    <PageContent breadcrumb>
      <AccessReportDoorLogProvider>
        <Row>
          <Col span={6}>
            <SideBar />
          </Col>
          <Col
            span={18}
            style={{ overflowY: "auto", height: "calc(100vh - 180px)" }}
          >
            <AccesscontrolAllReportContent />
          </Col>
        </Row>
      </AccessReportDoorLogProvider>
    </PageContent>
  );
};

const SideBar = () => {
  const { t } = useTranslation();
  const { columnsKey, setColumnsKey, search, setSearch } = useReportDoorLog();

  const option = [
    {
      key: "date",
      title: t("entry-date"),
    },
    {
      key: "fullName",
      title: t("full-name"),
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
    },
    {
      key: "actionType",
      title: t("save-type"),
    },
    {
      key: "maskStatus",
      title: t("face-mask"),
    },
    {
      key: "companyName",
      title: t("company-or-shipping"),
    },
    {
      key: "temperatureStatus",
      title: t("temperature-status"),
    },
    {
      key: "doorName",
      title: t("source-of-events"),
    },
    {
      key : "userType",
      title: t("person-type"),
    }
  ];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setColumnsKey(checkedValues as string[]);
  };

  return (
    <SidebarWrapper
      className="p-5"
      style={{ overflowY: "auto", height: "calc(100vh - 180px)" }}
    >
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item name="date" label={t("entry-date")}>
              <CRangePicker
                onChange={(e) => {
                  const sDate = e
                    ? dayjs(e?.[0]).startOf("day").toISOString()
                    : undefined;
                  const eDate = e
                    ? dayjs(e?.[1])?.endOf("day").toISOString()
                    : undefined;
                  setSearch({ ...search, startDate: sDate, endDate: eDate });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="name" label={t("full-name")}>
              <CInput
                placeholder={t("full-name")}
                onChange={(e) => {
                  const name = e.target.value ? e.target.value : undefined;
                  setSearch({ ...search, fullName: name });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="phoneNumber" label={t("phone-number")}>
              <CSelect
                open={false}
                allowClear
                placeholder={t("phone-number")}
                mode="tags"
                maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, phoneNumber: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="actionType" label={t("save-type")}>
              <CInput
                placeholder={t("save-type")}
                onChange={(e) => {
                  const action = e.target.value ? e.target.value : undefined;
                  setSearch({ ...search, actionType: action });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="maskStatus" label={t("face-mask")}>
              <CSelect
                allowClear
                placeholder={t("face-mask")}
                valueOptions={{
                  values: [
                    {
                      value: false,
                      title: t("not-wear"),
                    },
                    {
                      value: true,
                      title: t("wear"),
                    },
                  ],
                  valueKey: "value",
                  titleKey: "title",
                }}
                onChange={(e) => setSearch({ ...search, maskStatus: e })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="companyName" label={t("company-or-shipping")}>
              <CSelectCompanyNameTags
                onChange={(e) => {
                  setSearch({ ...search, companyName: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="temperatureStatus" label={t("temperature-status")}>
              <CSelect
                allowClear
                placeholder={t("face-mask")}
                valueOptions={{
                  values: [
                    {
                      value: false,
                      title: t("not-pass"),
                    },
                    {
                      value: true,
                      title: t("pass"),
                    },
                  ],
                  valueKey: "value",
                  titleKey: "title",
                }}
                onChange={(e) => setSearch({ ...search, temperatureStatus: e })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="doorName" label={t("source-of-events")}>
              <CSelect
                open={false}
                allowClear
                placeholder={t("source-of-events")}
                mode="tags"
                maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, doorName: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="mb-4">
            <Typography.Title level={5}>
              {t("required-information")}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onChange}
              defaultValue={columnsKey}
            >
              <Row gutter={[0, 16]}>
                {option?.map((item, index) => {
                  return (
                    <Col span={24} key={index}>
                      <Checkbox value={item?.key}>{t(item?.title)}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </Form>
    </SidebarWrapper>
  );
};

export default AccesscontrolAllReport;
