import { useQueryClient } from "@tanstack/react-query";
import { Form, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import Container from "components/display/container";
import { fireNotification } from "components/popup/notification";
import { AcceptStatusType } from "config/axios/interface";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetAccessibilitys } from "services/accessibility";
import {
  IAccessibility,
  IAccessibilityParams,
} from "services/accessibility/interface";
import {
  useDeleteAccessibilyInPerson,
  usePostAccessibilyPerson,
  useGetAccessibilyInPerson,
} from "services/contractor";
import { IAccessibilityPersonParams } from "services/contractor/interface";

const AccessConTrol = ({
  pending,
  groupId,
  acceptStatus,
}: {
  pending: boolean;
  groupId?: number;
  acceptStatus?: AcceptStatusType;
}) => {
  const { t } = useTranslation();
  const qClient = useQueryClient();
  const { pageKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();
  const { setAppLoading } = useAppLoading();

  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState<IAccessibilityPersonParams>({
    page: 1,
    limit: 10,
    customerId: id,
  });
  const [selectRowsKey, setSelectRowsKey] = useState<any[]>([]);

  const { data, isFetching } = useGetAccessibilyInPerson({
    ...params,
  });
  const deleteAccessibilyInPerson = useDeleteAccessibilyInPerson();

  useEffect(() => {
    setSelectRowsKey([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns: ColumnsType<IAccessibility> = [
    {
      title: t("accessibility-name"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.accessibilityName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc.accessibilityName }}>
            {rc.accessibilityName}
          </Typography.Text>
        );
      },
    },
  ];

  const onAdd = () => {
    // if (!pending) {
      setVisible(!visible);
    // }
  };

  const rowSelection: TableRowSelection<object> = {
    onChange: setSelectRowsKey,
    selectedRowKeys: selectRowsKey,
    columnWidth: "50px",
  };

  const onDelete = () => {
    setAppLoading(true);
    deleteAccessibilyInPerson.mutate(
      {
        customerId: id,
        accessibilityId: selectRowsKey,
      },
      {
        onSuccess: () => {
          qClient.invalidateQueries(["get-accessibility-person"]);
          fireNotification({ type: "success", menu: pageKey });
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: pageKey,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const accessControl = data?.accessibilityUsers?.map((item) => item.id) || [];

  if (acceptStatus === "PENDING") {
    return (
      <Container className="mt-5 ">
        <div className="mt-5">
          <Typography.Title
            className="!text-primary-dark"
            level={4}
            ellipsis={{ tooltip: t("accessibility-management-list") }}
          >
            {t("accessibility-management-list")}
          </Typography.Title>
        </div>
        <Typography.Text className="center h-[80%] text-[24px]">
          {t("approval-user")}
        </Typography.Text>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <ModalSccessControl
        visible={visible}
        onClose={onAdd}
        defaultData={{ accessControl }}
      />
      <CTable
        rowKey="id"
        columns={columns}
        dataSource={data?.accessibilityUsers || []}
        rowSelection={rowSelection}
        title={t("accessibility-management-list")}
        extraHeaderButton={[
          {
            children: t("cancel-device"),
            theme: "gray",
            onClick: onDelete,
            disabled: selectRowsKey?.length === 0 || pending,
          },
          {
            children: `+ ${t("add-device")}`,
            theme: "yellow",
            onClick: onAdd,
            disabled: !groupId,
          },
        ]}
        loading={isFetching}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </React.Fragment>
  );
};

const ModalSccessControl = ({
  visible,
  onClose,
  defaultData,
}: {
  visible: boolean;
  onClose: () => void;
  defaultData?: { accessControl?: number[]; accessRecord?: number[] };
}) => {
  const { t } = useTranslation();
  const [formAccess] = Form.useForm();
  const { setAppLoading } = useAppLoading();
  const qClient = useQueryClient();
  const { pageKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  useEffect(() => {
    setSelectRowsKey(defaultData?.accessControl || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData, visible]);

  const [selectRowsKey, setSelectRowsKey] = useState<any[]>([]);

  const [params, setParams] = useState<IAccessibilityParams>({
    page: 1,
    limit: 12,
  });
  const data_accessibility = useGetAccessibilitys({
    ...params,
    wait: !visible,
  });

  const postContractorAccessControl = usePostAccessibilyPerson();

  const columnEquipmentList: ColumnsType<IAccessibility> = [
    {
      title: t("accessibility-name"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.accessibilityName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc.accessibilityName }}>
            {rc.accessibilityName}
          </Typography.Text>
        );
      },
    },
  ];

  const onFinish = () => {
    setAppLoading(true);
    postContractorAccessControl.mutate(
      {
        customerId: id,
        accessibilityId: selectRowsKey,
      },
      {
        onSuccess: () => {
          qClient.invalidateQueries(["get-accessibility-person"]);
          fireNotification({ type: "success", menu: pageKey });
          onCancel();
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: pageKey,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const rowSelection: TableRowSelection<object> = {
    onChange: setSelectRowsKey,
    selectedRowKeys: selectRowsKey,
    columnWidth: "50px",
  };

  const onCancel = () => {
    setSelectRowsKey([]);
    onClose();
  };

  return (
    <CDrawer
      form={formAccess}
      title={t("add-accessibility")}
      visible={visible}
      onCancel={onCancel}
      onSave={onFinish}
      width="40vw"
      size="large"
      placement="right"
      onClose={onCancel}
      forceRender={false}
    >
      <Form form={formAccess} onFinish={onFinish}>
        <Typography.Title level={5} className="!text-primary-dark !mb-2">
          {t("accessibility-list")}
        </Typography.Title>
        <Form.Item noStyle name="equipment">
          <CTable
            rowSelection={rowSelection}
            rowKey="id"
            wrapper={false}
            columns={columnEquipmentList}
            query={data_accessibility}
            loading={data_accessibility.isFetching}
            pagination={{
              onChange: (page, limit) => {
                setParams({ ...params, page, limit });
              },
              pageSizeOptions: ["12", "24", "60", "120"],
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default AccessConTrol;
