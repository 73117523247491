import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "../../config/axios";
import { IResponse } from "../../config/axios/interface";
import { ISupplierType, ISupplierTypeParams } from "./interface";

export const useGetSupplierTypes = (
  params?: ISupplierTypeParams
): UseQueryResult<IResponse<ISupplierType[]>> => {
  return useQuery(
    ["get-supplier-types", params],
    async () => {
      const res = await axios.get("/supplier-type", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostSupplierType = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/supplier-type", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useScrollGetSupplierTypes = (): UseInfiniteQueryResult<
  IResponse<ISupplierType[]>
> => {
  return useInfiniteQuery(
    ["supplier-type"],
    async () => {
      const res = await axios.get("/supplier-type");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useXScrollGetSupplierTypes = (
  params?: ISupplierTypeParams
): UseInfiniteQueryResult<IResponse<ISupplierType[]>, Error> => {
  return useInfiniteQuery(
    ["get-supplier-types", params],
    async ({ pageParam }) => {
      const res = await axios.get("/supplier-type", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};