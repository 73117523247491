import { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AvatarHolderImage } from "assets/images";
import { useTranslation } from "react-i18next";
import { fileToDataUrl, resizeImageFileSize } from "tools/file";
import { fireNotification } from "components/popup/notification";
import tw from "twin.macro";
import CImage from "components/display/c-image";

const Wrapper = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 1px solid rgb(220, 220, 220, 1);
  overflow: hidden;
  background-color: rgb(245, 245, 245, 1);
  ${tw`inline-flex items-center justify-center cursor-pointer`};
  ${tw`rounded-app`};
`;

const accepts = {
  array: ["jpg", "jpeg", "png", "webp"],
  string: ".jpg,.jpeg,.png,.webp",
};

export interface UploadAvatarProps {
  onChange?: (v: any) => void;
  value?: string | File;
  readonly?: boolean;
  width?: string;
  height?: string;
  disabled?: boolean;
}

const UploadAvatar: FC<UploadAvatarProps> = ({
  value,
  onChange,
  readonly,
  width = "200px",
  height = "200px",
  disabled = false,
}) => {
  const { t } = useTranslation("message");
  const ref = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState<undefined | string>();

  useEffect(() => {
    if (typeof value === "object") return;
    setImageUrl(value);
  }, [value]);

  const onPicked = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files?.length > 0) {
      try {
        const image = files[0];
        const extension = image.name.split(".").pop()?.toLocaleLowerCase();
        if (!extension || !accepts.array.includes(extension)) {
          throw new Error(t("only-jpg-jpeg-png-format-support"));
        }

        const base64 = await fileToDataUrl(image);
        if (typeof base64 !== "string") {
          throw new Error(t("error-occured"));
        }
        const resized = await resizeImageFileSize({
          file: image,
          maxHeight: 500,
          maxWidth: 500,
        });
        setImageUrl(base64);
        onChange?.(resized);
      } catch (err: any) {
        fireNotification({ type: "error", description: err?.message });
      }
    }
  };

  const clearPreviousValue = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const openPickFileDialog = () => {
    if (readonly) return;
    ref.current?.click();
  };

  return (
    <Wrapper onClick={openPickFileDialog} width={width} height={height}>
      <CImage
        className="!h-full !w-full"
        src={imageUrl || AvatarHolderImage}
        preview={false}
        // style={{ objectFit: "contain" }}
      />
      <input
        accept={accepts.string}
        multiple={false}
        type="file"
        ref={ref}
        style={{ display: "none" }}
        onChange={onPicked}
        onClick={clearPreviousValue}
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default UploadAvatar;
