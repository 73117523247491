import { Button, Typography } from "antd";
import styled from "styled-components";

interface IGroupPersonList {
  id: number;
  groupName: string;
  style?: React.CSSProperties;
  activeKey?: string | number | number[];
  onClick?: () => void;
}

const VisitorGroupStyle = styled(Button)<{ $active: number }>`
  width: 100%;
  height: 50px;
  text-align: start;
  border: 1px solid ${({$active}) => ($active ? "#438860" : "#E3E3E3")};

  &:hover,
  &:active,
  &:focus {
    background-color: #fff;
    border-color: #438860;
    color: #2e3c52;
  }
`;

const GroupPersonList = ({
  id,
  groupName,
  activeKey,
  onClick,
}: IGroupPersonList) => {
  return (
    <div className="mt-2">
      <VisitorGroupStyle
        $active={activeKey === id ? 1 : 0}
        onClick={onClick}
      >
        <Typography.Text ellipsis={{tooltip: groupName}}>{groupName || ""}</Typography.Text>
      </VisitorGroupStyle>
    </div>
  );
};

export default GroupPersonList;
