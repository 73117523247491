import {
  MenuResourceAccessControlEquipment,
  MenuResourceEncodingEquipment,
  MenuResourceAllVideo,
} from "./../../assets/icons/menu/resource-management/index";
import ResourceAccessControlEquipmentPage from "pages/resource-management/equipment-and-server/access-control-equipment";
import ResourceAccessControlEquipmentCreateAndEditPage from "pages/resource-management/equipment-and-server/access-control-equipment/create-and-edit";
import ResourceEncodeDevicePage from "pages/resource-management/equipment-and-server/encoding-equipment";
import ResourceEncodeDeviceCreateAndEditPage from "pages/resource-management/equipment-and-server/encoding-equipment/create-and-edit";
import { IPageRoute, ISubMenu } from "router/interface";
import { TSubPageResourceManagement } from "./interface";
import ResourceAllVideos from "pages/resource-management/all-videos";
export const RESOURCE_MANAGEMENT_MENUS: ISubMenu<TSubPageResourceManagement>[] =
  [
    {
      key: "resource-management.access-control-equipment",
      title: "resource-management.access-control-equipment",
      code: "060100",
      icon: MenuResourceAccessControlEquipment,
    },
    {
      key: "resource-management.encode-device",
      title: "resource-management.encode-device",
      code: "060200",
      icon: MenuResourceEncodingEquipment,
    },
    {
      key: "resource-management.all-videos",
      title: "resource-management.all-videos",
      code: "060300",
      icon: MenuResourceAllVideo,
    },
  ];

export const RESOURCE_MANAGEMENT_ROUTES: IPageRoute<TSubPageResourceManagement>[] =
  [
    {
      key: "resource-management.encode-device",
      element: ResourceEncodeDevicePage,
    },
    {
      key: "resource-management.encode-device-add",
      element: ResourceEncodeDeviceCreateAndEditPage,
    },
    {
      key: "resource-management.encode-device-edit",
      element: ResourceEncodeDeviceCreateAndEditPage,
    },
    {
      key: "resource-management.access-control-equipment",
      element: ResourceAccessControlEquipmentPage,
    },
    {
      key: "resource-management.access-control-equipment-add",
      element: ResourceAccessControlEquipmentCreateAndEditPage,
    },
    {
      key: "resource-management.access-control-equipment-edit",
      element: ResourceAccessControlEquipmentCreateAndEditPage,
    },
    {
      key: "resource-management.all-videos",
      element: ResourceAllVideos,
    },
  ];
