import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import { IVehicleDashboard, IDashboardParams, IVehicleInspectionDashboard, IUserDashboard, IAccessDashboard } from "./interface";

export const useGetVehicleDashboards = (
  params?: IDashboardParams
): UseQueryResult<IVehicleDashboard> => {
  return useQuery(
    ["get-vehicle-dashboards", params],
    async () => {
      const res = await axios.get("/dashboard/vehicle", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetVehicleInspecDashboards = (
  params?: IDashboardParams
): UseQueryResult<IVehicleInspectionDashboard> => {
  return useQuery(
    ["get-vehicle-inspection-dashboards", params],
    async () => {
      const res = await axios.get("/dashboard/vehicle-condition", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetUserDashboards = (
  params?: IDashboardParams
): UseQueryResult<IUserDashboard> => {
  return useQuery(
    ["get-user-dashboards", params],
    async () => {
      const res = await axios.get("/dashboard/user-visitor", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetUserDashboardsV2 = (
  params?: IDashboardParams
): UseQueryResult<IUserDashboard> => {
  return useQuery(
    ["get-user-dashboards", params],
    async () => {
      const res = await axios.get("/dashboard/v2/user-visitor", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetAccossDashboards = (
  params?: IDashboardParams
): UseQueryResult<IAccessDashboard> => {
  return useQuery(
    ["get-access-dashboards", params],
    async () => {
      const res = await axios.get("/dashboard/accessibility", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};
