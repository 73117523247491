import Chart from "react-apexcharts";
// import { parkingVehicleColor } from "../../constant";
export type TLineChartColor = "primary" | "gray";

interface LineChartProps {
  options?: ApexCharts.ApexOptions;
  categories: string[] | any[];
  series: { name: string; data: number[] }[];
  color?: TLineChartColor;
  type?: "category" | "datetime" | "numeric";
}

const textColors = { gray: "#c1c5cb", primary: "#232D3F" };
const colors = ["#559BEF", "#E0B33C"];

const LineChart = ({
  categories,
  series,
  color = "gray",
  type,
}: LineChartProps) => {
  const options: ApexCharts.ApexOptions = {
    xaxis: {
      type: type,
      categories: categories,
      labels: {
        style: {
          colors: textColors[color],
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: textColors[color],
          fontSize: "14px",
        },
        offsetY: 3,
        formatter: (value) => {
          if (value) return numformat(value) || `${value}`;
          return `${value}`;
        },
      },
    },
    grid: {
      strokeDashArray: 0.5,
    },
    chart: {
      fontFamily: "kanit-regular",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value.toString();
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      lineCap: "butt",
      width: 3,
      colors: colors,
    },
    fill: {
      pattern: {
        style: "squares",
      },
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        colorStops: [
          [
            {
              offset: 0.4,
              color: colors[0],
              opacity: 0.3,
            },
            {
              offset: 100,
              color: "transparent",
              opacity: 1,
            },
          ],
          [
            {
              offset: 0.4,
              color: colors[1],
              opacity: 0.3,
            },
            {
              offset: 100,
              color: "transparent",
              opacity: 1,
            },
          ],
        ],
      },
    },
  };

  return <Chart height={380} type="area" options={options} series={series} />;
};

const numformat = (n: number): string | undefined => {
  if (n < 1e3) return `${n}`;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export default LineChart;
