import { Form, Col, Typography, Row } from "antd";
import CButton from "components/button/c-button";
import CInput from "components/input/c-input";
import CRadio from "components/input/c-radio";
import CSelect from "components/input/c-select";
import React from "react";
import { useTranslation } from "react-i18next";
import { TMenu } from "../create-user-pdpa";

const DriverInformation = ({
  pending,
  getIdCard,
  isLoading,
  menu,
}: {
  pending: boolean;
  getIdCard: () => void;
  isLoading: boolean;
  menu?: TMenu;
}) => {
  const { t } = useTranslation();
  const wIdCardType = Form.useWatch("identityCodeType");
  const label = wIdCardType
    ? wIdCardType === "ID_CARD"
      ? t("identification-card")
      : t("other")
    : " ";

  return (
    <React.Fragment>
      <Col span={24} className="mb-4">
        <Typography.Text className="!text-primary-dark">
          {t("visitor-name")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="firstName"
          label={`${t("first-name")}`}
        >
          <CInput placeholder={`${t("first-name")}`} disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="lastName"
          label={`${t("last-name")}`}
        >
          <CInput placeholder={`${t("last-name")}`} disabled={pending} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="phoneNumber"
          label={t("phone-number")}
        >
          <CInput placeholder={t("phone-number")} disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("gender")} name="gender">
          <CSelect.Gender disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          // rules={[{ required: menu === "visitor" }]}
          hidden={menu !== "visitor"}
          name="groupId"
          label={
            <Row gutter={[6, 0]}>
              <Col>{t("setting.group-of-persons", { ns: "menu" })}</Col>
              <Col>
                <Typography.Text
                  ellipsis={{
                    tooltip: t("not-select-group-not-sent-face-scan", {
                      ns: "message",
                    }),
                  }}
                  className="!text-[#FF6F6F]"
                >
                  ( {t("not-select-group-not-sent-face-scan", { ns: "message" })} )
                </Typography.Text>
              </Col>
            </Row>
          }
        >
          <CSelect.GroupPerson disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item
          name="identityCodeType"
          label={t("card-number")}
          rules={[{ required: true }]}
        >
          <CRadio.Group
            optionType="default"
            options={[
              { label: t("identification-card"), value: "ID_CARD" },
              { label: t("other"), value: "OTHER" },
            ]}
            disabled={pending}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: wIdCardType, whitespace: true }]}
          name="identityCode"
          label={label}
        >
          <CInput disabled={!wIdCardType || pending} />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label=" ">
          <CButton
            className="w-full"
            theme="gray"
            disabled={wIdCardType !== "ID_CARD"}
            onClick={getIdCard}
            loading={isLoading}
          >
            {t("read-card")}
          </CButton>
        </Form.Item>
      </Col>
      <Col span={24}></Col>
    </React.Fragment>
  );
};

export default DriverInformation;
