import { Tabs } from "antd";
import { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const StyledTab = styled(Tabs)`
  ${tw`shadow`};
  border-top: 1px solid rgb(245, 245, 245, 0.5);
  position: sticky;
  top: 74px;
  left: 0;
  right: 0;
  z-index: 100;
  &&& {
    .ant-tabs-nav-wrap {
      background-color: white;
    }
    .ant-tabs-tab {
      border-top-color: unset !important;
      border-bottom-color: unset !important;
      background-color: unset !important;
      clip-path: unset !important;
      width: unset !important;
      button {
        position: absolute;
        right: 10px;
      }
      padding-left: 20px;
      padding-right: 20px;
    }
    .ant-tabs-nav-wrap {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 10px;
    }
    .ant-tabs-ink-bar {
      //   background-color: #f82602;
    }
    .ant-tabs-tab-active {
      border-top-color: unset !important;
      border-bottom-color: unset !important;
      background-color: unset !important;
    }
    .ant-tabs-tab:not(:first-child) {
      margin-left: unset !important;
    }
  }
`;

export interface CSpyTabProps {
  items?: { id: string; title: string }[];
  onChange?: (e: string) => void;
  activeKey?: string;
}

const CSpyTab: FC<CSpyTabProps> = ({ items, onChange, activeKey }) => {
  return (
    <StyledTab
      onChange={(e) => {
        const section = document.querySelector(e);
        section?.scrollIntoView({ behavior: "smooth", block: "center" });
        onChange?.(e);
      }}
      activeKey={activeKey}
    >
      {items?.map((item) => {
        return <StyledTab.TabPane key={item.id} tab={item.title} />;
      })}
    </StyledTab>
  );
};

export default CSpyTab;
