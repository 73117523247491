import { EditFilled, SettingFilled } from "@ant-design/icons";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Col, Row, Tooltip, TooltipProps } from "antd";
import { BinIcon } from "assets/icons/general";
import { CButtonProps } from "components/button/c-button";
import CButtonIcon from "components/button/c-button-icon";
import { ButtonThemeTypes } from "components/button/theme";
import PageSearchBox, {
  PageSearchBoxProps,
} from "components/layout/page-search-box";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { personType } from "../c-modal/modal-profile";
import TableActionExcelButton from "./action-excel-button";

interface IObj {
  hidden: boolean;
  onClick?: (v?: any) => any;
  icon: React.ReactNode;
  btnProps?: CButtonProps;
  tooltipProps?: TooltipProps;
}

interface IAction {
  mutation?: UseMutationResult;
  invalidateQueries?: string[];
  onClick?: (v: any) => any;
  onLastItemInPage?: () => void;
  btnProps?: CButtonProps;
  tooltipProps?: TooltipProps;
  key?: string;
  additionParams?: any;
  typeUser?: personType;
}

interface IExcelAction {
  fileName?: string;
  mutation?: UseMutationResult<Blob, Error>;
  onClick?: (v: any[]) => void;
  extraValue?: Record<string, any>;
  invalidateQueries?: string[];
  list?: { key: string; child: string[]; listName: string };
  notShowModal?: boolean;
  typeUser?: personType;
}

export type TableExcelActionTypes = {
  export?: IExcelAction;
  import?: IExcelAction;
  template?: IExcelAction;
  importProfile?: IAction;
};
export interface TableActionProps {
  searchBox?: PageSearchBoxProps;
  bulkDelete?: IAction;
  setting?: IAction;
  edit?: IAction;
  excels?: TableExcelActionTypes;
  selectRowsKey: any[];
  resetSelectRowKey: () => void;
  extraActions?: IAction[];
  buttonTheme?: ButtonThemeTypes;
  shouldGoBackAfterDelete?: boolean;
}

const TableAction: FC<TableActionProps> = ({
  excels,
  edit,
  bulkDelete,
  setting,
  selectRowsKey,
  shouldGoBackAfterDelete,
  searchBox,
  extraActions,
  buttonTheme,
  resetSelectRowKey,
}) => {
  const { t } = useTranslation();

  const { setAppLoading } = useAppLoading();
  const { pageKey } = usePageRoute();
  const qClient = useQueryClient();

  const onBulkDelete = async () => {
    if (!bulkDelete || !selectRowsKey.length) return;
    const confirm = await fireConfirmDelete();
    if (confirm) {
      const {
        mutation,
        invalidateQueries,
        onLastItemInPage,
        key,
        additionParams,
      } = bulkDelete;
      setAppLoading(true);
      mutation?.mutate(
        { [key || "ids"]: selectRowsKey, ...additionParams },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: pageKey });
            invalidateQueries?.forEach((e) => qClient.invalidateQueries([e]));
            resetSelectRowKey();
            if (shouldGoBackAfterDelete) {
              onLastItemInPage?.();
            }
          },
          onError: ({ message }: any) => {
            fireNotification({ type: "error", description: message });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    }
  };

  const obj: {
    [key: string]: IObj;
  } = {
    edit: {
      hidden: !edit,
      onClick: edit?.onClick,
      icon: <EditFilled />,
      tooltipProps: { overlay: t("edit"), ...edit?.tooltipProps },
      btnProps: { theme: buttonTheme, ...edit?.btnProps },
    },
    setting: {
      hidden: !setting,
      onClick: setting?.onClick,
      icon: <SettingFilled />,
      tooltipProps: { overlay: t("setting"), ...setting?.tooltipProps },
      btnProps: { theme: buttonTheme, ...setting?.btnProps },
    },
    delete: {
      hidden: !bulkDelete,
      onClick: bulkDelete?.onClick || onBulkDelete,
      icon: <BinIcon />,
      tooltipProps: { overlay: t("delete-all"), ...bulkDelete?.tooltipProps },
      btnProps: { theme: buttonTheme, ...bulkDelete?.btnProps },
    },
  };

  const getPageSearchBox = () => {
    if (!searchBox) return null;
    return <PageSearchBox.WithPopover {...searchBox} />;
  };

  const pageSearchBox = getPageSearchBox();

  return (
    <Row gutter={12}>
      {extraActions?.map((item, index) => {
        return (
          <Col key={index} hidden={item.btnProps?.hidden}>
            <Tooltip {...item.tooltipProps}>
              <CButtonIcon {...item.btnProps} />
            </Tooltip>
          </Col>
        );
      })}
      <Col hidden={!pageSearchBox}>{pageSearchBox}</Col>
      <Col hidden={!excels}>
        <TableActionExcelButton
          buttonTheme={buttonTheme}
          resetSelectRowKey={resetSelectRowKey}
          actions={excels}
        />
      </Col>
      {Object.values(obj).map((item, index) => {
        return (
          <Col hidden={item.hidden} key={index}>
            <Tooltip {...item.tooltipProps}>
              <CButtonIcon
                onClick={item.onClick}
                icon={item.icon}
                {...item.btnProps}
              />
            </Tooltip>
          </Col>
        );
      })}
    </Row>
  );
};

export default TableAction;
