import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Form, Input, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { IStock, IStockParams } from "services/setting-stock/interface";
import {
  useDeleteStock,
  useDeleteStocks,
  useGetStock,
  usePatchStockId,
  usePostStock,
} from "services/setting-stock";
import { useQueryClient } from "@tanstack/react-query";
import CSelect from "components/input/c-select";
import { FormInstance } from "antd/es/form/Form";
import CInput from "components/input/c-input";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import CCard from "components/display/card";
import CDrawer from "components/display/c-drawer";

const Stock = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { formatDate, translationObject } = useAppLanguage();
  const [params, setParams] = useState<IStockParams>({
    page: 1,
    limit: 10,
  });

  const del_stock = useDeleteStock();
  const del_stocks = useDeleteStocks();
  const get_stocks = useGetStock(params);

  const toggle = () => {
    setVisible(!visible);
  };

  const elements: ISearchBoxElement[] = [
    {
      name: "stockCode",
      label: t("stock-code"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "stock",
      label: t("stock"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "createdAt",
      label: t("created-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "updatedAt",
      label: t("updated-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "status",
      label: t("status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              { key: true, value: t("active") },
              { key: false, value: t("in-active") },
            ],
            valueKey: "key",
            titleKey: "value",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<IStock> = [
    {
      title: t("stock-code"),
      dataIndex: "stockCode",
    },
    {
      title: t("stock"),
      render: (_, rc) => {
        const { stock } = translationObject({ object: rc, keys: ["stock"] });
        return (
          <Typography.Text ellipsis={{ tooltip: stock }}>
            {stock}
          </Typography.Text>
        );
      },
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      render: (_, rc) => {
        if (!rc.createdAt) return <span>-</span>;
        const date = formatDate({ date: rc.createdAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      render: (_, rc) => {
        if (!rc.updatedAt) return <span>-</span>;
        const date = formatDate({ date: rc.updatedAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      width: "20%",
      render: (status: "true" | "false") => {
        return (
          <CCard.ActiveInActive
            status={status === "true" ? "active" : "in-active"}
          />
        );
      },
    },
  ];

  const onRowClick = (values: any) => {
    form.setFieldsValue(values);
    toggle();
  };

  const onSearch = (FormItem: any) => {
    setParams({
      ...params,
      ...FormItem,
      startUpdatedAt: FormItem["updatedAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endUpdatedAt: FormItem["updatedAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
      startCreatedAt: FormItem["createdAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endCreatedAt: FormItem["createdAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
    });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <CreateAndEditDrawer visible={visible} onClose={toggle} form={form} />
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        onAdd={{
          onClick: toggle,
        }}
        onRowClick={onRowClick}
        action={{
          bulkDelete: {
            mutation: del_stocks,
            invalidateQueries: ["get-stocks"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: del_stock,
          invalidateQueries: ["get-stocks"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        query={get_stocks}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        columns={columns}
        title={t("stock-list")}
      />
    </PageContent>
  );
};

const CreateAndEditDrawer = ({
  visible,
  onClose,
  form,
}: {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
}) => {
  const { t } = useTranslation();
  const post_Stock = usePostStock();
  const patch_Stock = usePatchStockId();
  const qClient = useQueryClient();

  const onFinish = (values: any) => {
    const { id } = values;
    if (!values.status) values.status = "true";
    if (id) {
      return patch_Stock.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-stocks"]);
          onClose();
          form.resetFields();
        },
        onError: ({ meesage }: any) => {
          fireNotification({ type: "error", description: meesage });
        },
      });
    }

    return post_Stock.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        qClient.invalidateQueries(["get-stocks"]);
        onClose();
        form.resetFields();
      },
      onError: ({ meesage }: any) => {
        fireNotification({ type: "error", description: meesage });
      },
    });
  };

  // const statusDevice = [
  //   {
  //     value: "true",
  //     label: t("online"),
  //   },
  //   {
  //     value: "false",
  //     label: t("offline"),
  //   },
  // ];

  const stockCode = <span>{t("stock-code")}</span>;
  const stockTh = (
    <span>
      {t("stock")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const stockEn = (
    <span>
      {t("stock")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );
  const descriptionTh = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const descriptionEn = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );

  const titleForm = form.getFieldValue("id") ? t("edit-stock") : t("add-stock");

  return (
    <CDrawer
      form={form}
      title={titleForm}
      placement="right"
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      size="large"
      visible={visible}
      width="40vw"
      onSave={onFinish}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="stockCode"
          label={stockCode}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput placeholder={`${t("stock-code")}`} />
        </Form.Item>

        <Form.Item
          name="stockTh"
          label={stockTh}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput placeholder={t("stock")} />
        </Form.Item>
        <Form.Item
          name="stockEn"
          label={stockEn}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput placeholder={`${t("stock")} (${t("language-en")})`} />
        </Form.Item>
        <Form.Item label={t("status")} name="status">
          <CSelect
            placeholder={t("status")}
            defaultValue="true"
            valueOptions={{
              values: [
                { id: "true", title: t("active") },
                { id: "false", title: t("in-active") },
              ],
              valueKey: "id",
              titleKey: "title",
            }}
          />
        </Form.Item>
        <Form.Item name="descriptionTh" label={descriptionTh}>
          <CInput.TextArea placeholder={t("description")} rows={6} />
        </Form.Item>
        <Form.Item name="descriptionEn" label={descriptionEn}>
          <TextArea
            placeholder={`${t("description")} (${t("language-en")})`}
            rows={6}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default Stock;
