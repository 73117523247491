import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";
import { IFaceTask, IFaceTaskParams } from "./interface";

export const useGetFaceTasks = (
  params?: IFaceTaskParams
): UseQueryResult<IResponse<IFaceTask[]>> => {
  return useQuery(
    ["get-face-tasks", params],
    async () => {
      // const res = await digitalInstance.get("/face-task", { params });
      // if (res.status === 200) {
      //   return res.data;
      // }
      // throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetFaceTaskID = (id?: number): UseQueryResult<IFaceTask> => {
  return useQuery(["get-face-task-id", id], async () => {
    if (!id) return;
    // const res = await digitalInstance.get(`/face-task/${id}`);
    // if (res.status === 200) {
    //   return res.data.data;
    // }
    // throwResponse(res);
  });
};

export const usePostFaceTask = (): UseMutationResult => {
  return useMutation(async (data) => {
    // const res = await digitalInstance.post("/face-task", data);
    // if (res.status === 200) {
    //   return res.data;
    // }
    // throwResponse(res);
  });
};

export const usePatchFaceTask = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    // const res = await digitalInstance.patch(`/face-task/${id}`, data);
    // if (res.status === 200) {
    //   return;
    // }
    // throwResponse(res);
  });
};

export const useDeleteFaceTaskID = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    // const res = await digitalInstance.delete(`/face-task/${id}`);
    // if (res.status === 200) {
    //   return;
    // }
    // throwResponse(res);
  });
};


