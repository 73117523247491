import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { Col, MenuProps, Row, TableProps } from "antd";
import {
  ColumnsType,
  ColumnType,
  TablePaginationConfig,
  TableRowSelection,
} from "antd/lib/table/interface";
import { CButtonProps } from "components/button/c-button";
import { IResponse } from "config/axios/interface";
import { usePageRoute } from "provider/page-route";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TSubPage } from "router/interface";
import styled from "styled-components";
import { TableActionProps } from "./action";
import { GetComponentProps } from "rc-table/lib/interface";
import SelectTable, { SelectTableProps } from "./select-table";
import { StyledTable } from "./styled-table";
import TableActionDeleteButton from "./action-delete-button";
import TableActionSettingButton from "./action-setting-button";
import CTableHeader from "./c-table-header";

const TableWrapper = styled.div`
  background-color: white;
`;
type deleteFunction = (rc: any) => void;
export type onDeleteType =
  | deleteFunction
  | {
      mutation?: UseMutationResult;
      invalidateQueries?: string[];
      key?: string;
      onLastItemInPage?: () => void;
    };
export type onAddType =
  | TSubPage
  | {
      onClick?: (selected: any[], resetSelect: () => void) => void;
      label?: string;
      pageKey?: TSubPage;
    };

export interface ExtranHeaderButtonProps extends Omit<CButtonProps, "onClick"> {
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, s: any[]) => void;
}

interface CTableProps
  extends Omit<TableProps<any>, "title" | "rowKey" | "onRow"> {
  query?: UseQueryResult<IResponse<any[]>>;
  rowKey?: string;
  wrapper?: boolean;
  title?: string;
  shadow?: boolean;
  action?: Omit<TableActionProps, "selectRowsKey" | "resetSelectRowKey">;
  selectAble?: boolean;
  onAdd?: onAddType;
  onDelete?: onDeleteType;
  onSetting?: MenuProps;
  onRowClick?: ((v: any) => void) | TSubPage;
  wrapperClassName?: string;
  extraHeaderButton?: ExtranHeaderButtonProps[];
  extraElement?: JSX.Element;
}

type NotationTypes = {
  Select: FC<SelectTableProps>;
};

const CTable: FC<CTableProps> & NotationTypes = ({
  wrapper = true,
  action,
  query,
  title,
  selectAble = true,
  shadow = true,
  onAdd,
  rowKey = "id",
  columns: clmn,
  dataSource,
  onDelete,
  onSetting,
  onRowClick,
  pagination,
  wrapperClassName,
  extraHeaderButton,
  extraElement,
  ...rest
}) => {
  const { t } = useTranslation();
  const [selectRowsKey, setSelectRowsKey] = useState<any[]>([]);

  const { dispatch } = usePageRoute();

  const rowSelection: TableRowSelection<object> = {
    onChange: setSelectRowsKey,
    selectedRowKeys: selectRowsKey,
    columnWidth: "50px",
  };

  const shouldGoBackAfterDelete = (): boolean => {
    const source = query?.data?.data || dataSource || [];
    const page = query?.data?.page || 0;

    return (
      (source.length <= 1 && page > 1) ||
      selectRowsKey.length >= (dataSource?.length || 0)
    );
  };

  const getActionColumn = (): ColumnType<any> => {
    // const goBack = shouldGoBackAfterDelete();
    return {
      title: t("table-action"),
      align: "center",
      width: 120,
      ellipsis: true,
      render: (_, rc) => {
        return (
          <Row gutter={6} justify="center">
            <Col hidden={!onSetting}>
              <TableActionSettingButton menuProps={onSetting} record={rc} />
            </Col>
            <Col hidden={!onDelete}>
              <TableActionDeleteButton
                shouldGoBackAfterDelete={_shouldGoBackAfterDelete}
                onDelete={onDelete}
                record={rc}
              />
            </Col>
          </Row>
        );
      },
    };
  };

  const onRow: GetComponentProps<object> = (record) => {
    return {
      onClick: (e) => {
        e.stopPropagation();
        if (!onRowClick) return;
        if (typeof onRowClick === "function") {
          return onRowClick(record);
        }
        return dispatch({
          action: "push",
          pageKey: onRowClick,
          params: record,
        });
      },
    };
  };

  const getColumns = (c?: ColumnsType<any>) => {
    if (!c) return [];
    if (!onDelete && !onSetting) return c;
    const action = getActionColumn();
    return [...c, action];
    // return [...c, {render: () => {

    // }}]
  };

  const getPagination = (): TablePaginationConfig | false => {
    if (query) {
      const { count, page, limit } = query.data || {};
      return {
        total: count || 0,
        current: page || 1,
        pageSize: limit || 10,
        size: "small",
        showTotal: () => `${t("total")} ${count || 0} ${t("items")}`,
        showSizeChanger: true,
        ...pagination,
      };
    }
    if (pagination) {
      return {
        size: "small",
        showSizeChanger: true,
        ...pagination,
      };
    }
    return false;
  };

  const _dataSource = query?.data?.data || dataSource || [];
  const _columns = getColumns(clmn);
  const _pagination = getPagination();
  const _loading = query?.isFetching || rest.loading;
  const _shouldGoBackAfterDelete = shouldGoBackAfterDelete();

  const element = (
    <StyledTable
      rowKey={rowKey}
      dataSource={_dataSource}
      loading={_loading}
      rowSelection={selectAble ? rowSelection : undefined}
      columns={_columns}
      rowClassName="cursor-pointer"
      onRow={onRow}
      pagination={_pagination}
      {...rest}
    />
  );

  if (wrapper) {
    return (
      <TableWrapper
        className={`p-5 mt-5 rounded-app ${
          shadow ? "shadow" : ""
        } ${wrapperClassName}`}
      >
        <CTableHeader
          title={title}
          action={action}
          onAdd={onAdd}
          extraHeaderButton={extraHeaderButton}
          selectRowsKey={selectRowsKey}
          extraElement={extraElement}
          shouldGoBackAfterDelete={_shouldGoBackAfterDelete}
          resetSelectRowKey={() => setSelectRowsKey([])}
        />
        {element}
      </TableWrapper>
    );
  }
  return element;
};

CTable.Select = SelectTable;

export default CTable;
