import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import CCard from "components/display/card";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteUser,
  useDeleteUsers,
  useExportTemplateUsers,
  useGetUsers,
  useImportExcelUsers,
  useImportProfileUsers,
} from "services/user";
import { IUser, IUserParams } from "services/user/interface";

const UserStaffPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IUserParams>({ limit: 10, page: 1 });
  const { translationObject } = useAppLanguage();

  const get_users = useGetUsers(params);
  const del_user = useDeleteUser();
  const del_users = useDeleteUsers();
  const export_template = useExportTemplateUsers();
  const import_excel = useImportExcelUsers();
  const import_profile = useImportProfileUsers();

  const elements: ISearchBoxElement[] = [
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "email",
      label: t("email"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "position",
      label: t("job-position"),
      input: {
        type: "SelectJobPosition",
        options: {},
      },
    },
    {
      name: "role",
      label: t("role"),
      input: {
        type: "SelectRole",
      },
    },
  ];

  const columns: ColumnsType<IUser> = [
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc.profile?.url} />;
      },
    },
    {
      title: t("full-name"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const { firstName, lastName } = translationObject({
          object: rc,
          keys: ["firstName", "lastName"],
        });
        const text = `${firstName} ${lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      width: 120,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis={{ tooltip: rc.phoneNumber }}>
            {rc.phoneNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("email"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis={{ tooltip: rc.email }}>
            {rc.email || "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: t("position"),
      align: "center",
      width: 150,
      render: (_, rc) => {
        const { positionName } = translationObject({
          object: rc,
          keys: ["position.positionName"],
        });

        return <CCard.WithBorder label={positionName} type="warning" />;
      },
    },
    {
      title: t("role"),
      align: "center",
      width: 150,
      render: (_, rc) => {
        const { title } = translationObject({
          object: rc,
          keys: ["role.title"],
        });

        return <CCard.WithBorder label={title} type="success" />;
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        scroll={{ x: 1200 }}
        query={get_users}
        title={t("staff-list")}
        columns={columns}
        onDelete={{
          mutation: del_user,
          invalidateQueries: ["get-users", "get-user"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        action={{
          bulkDelete: {
            mutation: del_users,
            invalidateQueries: ["get-users", "get-user"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
          excels: {
            template: {
              mutation: export_template,
              fileName: "users template",
            },
            import: {
              mutation: import_excel,
              invalidateQueries: ["get-users", "get-user"],
            },
            importProfile: {
              mutation: import_profile,
              invalidateQueries: ["get-users", "get-user"],
              typeUser : "user"
            },
          },
        }}
        onAdd="user-management.staff-add"
        onRowClick="user-management.staff-edit"
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default UserStaffPage;
