import { Col, Row, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import styled from "styled-components";
import CardHomeData from "components/home/card-data-home";
import dayjs from "dayjs";
import PieChartHome from "components/home/pie-chart-home";
import LineChartHome, { LabelStyle } from "components/home/line-chart-home";
import CardHomeList from "components/home/card-list-home";
import { MenuSchedule } from "assets/icons/general";
import { useGetVehicleInspecDashboards } from "services/dashboard";
import {
  IDashboardParams,
  IGraphVehicle,
  IVehicleInspectionDashboard,
} from "services/dashboard/interface";
import CRangePicker from "components/input/c-range-picker";
import { useAppLoading } from "provider/app-loading";
import CButton from "components/button/c-button";
import { UndoOutlined } from "@ant-design/icons";
import { useAppLanguage } from "provider/app-language";
import tw from "twin.macro";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayoutStlye = styled.div`
  ${tw`rounded-app`}
  background-color: #fff;
  box-shadow: 0px 1px 6px #0000000d;
`;

function getFromLS(key: string) {
  let ls = [];
  if (localStorage) {
    try {
      const lc = localStorage.getItem("vehicle-inspact-layout");
      ls = JSON.parse(lc || "[]") || {};
    } catch (e) {}
  }
  return ls[key];
}

function saveToLS(key: string, value: any) {
  if (localStorage) {
    localStorage.setItem(
      "vehicle-inspact-layout",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

const originalLayouts = getFromLS("layouts") || {};

const sDate = dayjs().startOf("month").toISOString();
const eDate = dayjs().endOf("month").toISOString();

const VehicleInspectionHomePage = () => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    layouts: JSON.parse(JSON.stringify(originalLayouts)),
  });

  const [params, setParams] = useState<IDashboardParams>({
    startDate: sDate,
    endDate: eDate,
  });
  const { data } = useGetVehicleInspecDashboards(params);
  const { setAppLoading } = useAppLoading();

  const [startDate, setStartDate] = useState<string>(sDate);
  const [endDate, setEndDate] = useState<string>(eDate);

  const onLayoutChange = (layout: any, layouts: any) => {
    saveToLS("layouts", layouts);
    setState({ layouts });
  };

  const onSearch = (values: any) => {
    const startDate = values
      ? dayjs(values[0])?.startOf("day").toISOString()
      : sDate;
    const endDate = values
      ? dayjs(values[1])?.endOf("day").toISOString()
      : eDate;
    values = { ...values, startDate, endDate };
    setStartDate(startDate);
    setEndDate(endDate);
    setParams({ ...params, ...values });
  };

  const refreshPage = () => {
    setAppLoading(true);
    window.location.reload();
  };

  const onReset = () => {
    setState({ layouts: [] });
    setAppLoading(true);
    window.location.reload();
  };

  const innerHeight = window.innerHeight;
  const layout =
    innerHeight > 929
      ? Number(((innerHeight - 64 - 90 - 70) / 4).toFixed(0))
      : Number(((929 - 64 - 90 - 70) / 4).toFixed(0));

  return (
    <div className="w-full h-full">
      <Row className="h-[7%] mb-2">
        <Col span={13} className="pl-4 !flex !items-center">
          <Typography.Title level={4} style={{ color: "#425F4E" }}>
            {t("vehicle-inspection-process")}
          </Typography.Title>
        </Col>
        <Col span={6} className="!flex !items-center">
          <CRangePicker onChange={onSearch} />
        </Col>
        <Col span={3} className="px-2 !flex !items-center">
          <CButton
            theme="yellow"
            children={t("save-to-change")}
            onClick={refreshPage}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={2} className="!flex !items-center">
          <CButton
            children={t("reset")}
            icon={<UndoOutlined />}
            onClick={onReset}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row className="h-[93%]">
        <Col span={24}>
          <ResponsiveReactGridLayout
            layouts={state.layouts}
            onLayoutChange={onLayoutChange}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            style={{ height: "100%" }}
            rowHeight={layout}
            className="layout"
          >
            <GridLayoutStlye
              key="count-of-spray"
              data-grid={{ x: 0, y: 0, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <CountOfSpray
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-vehicle-pass-fail"
              data-grid={{ x: 3, y: 0, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <CountOfVehiclePassAndFail
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-quick-lab"
              data-grid={{ x: 6, y: 0, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <CountOfQuickLab
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-vehicle-wait-weighing"
              data-grid={{ x: 9, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CounutVehicleWaitForWeighing
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-vehicle-wait-loading"
              data-grid={{ x: 9, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CountVehicleForLoading startDate={startDate} endDate={endDate} />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-vehicle-start-loading"
              data-grid={{ x: 0, y: 2, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CountVehicleStartToLoad
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-vehicle-completed-loading"
              data-grid={{ x: 3, y: 2, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CountVehicleCompletedLoading
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-vehicle-weight-out"
              data-grid={{ x: 0, y: 3, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CountVehicleWeightOut startDate={startDate} endDate={endDate} />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-vehicle-broken"
              data-grid={{ x: 3, y: 3, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CountVehicleBroken
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStlye>
            <GridLayoutStlye
              key="count-vehicle-get-inspaction"
              data-grid={{ x: 6, y: 2, w: 6, h: 2, minH: 2, maxH: 2, minW: 6 }}
            >
              <CountVehicleGetInspaction
                data={data?.graphVehicle || []}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStlye>
          </ResponsiveReactGridLayout>
        </Col>
      </Row>
    </div>
  );
};

const CountOfSpray = ({
  data,
  startDate,
  endDate,
}: {
  data?: IVehicleInspectionDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const sprayTrue = Number(
    data?.totalCheckSpray?.[0]?.totalCheckSprayTrue || 0
  );
  const sprayFalse = Number(
    data?.totalCheckSpray?.[0]?.totalCheckSprayFalse || 0
  );
  const sum = Number(sprayTrue + sprayFalse).toLocaleString();
  return (
    <CardHomeData title="count-spray" startDate={startDate} endDate={endDate}>
      <Row gutter={[12, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[
              t("spray-sterilizing-solution"),
              t("not-spray-sterilizing-solution"),
            ]}
            series={[sprayTrue, sprayFalse]}
            colors={["#425F4E", "#BF9C3D"]}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>{`${sum || 0} ${t(
            "cars"
          )}`}</Typography.Title>
        </Col>
        <Col span={12}>
          <LabelStyle color="#425F4E" text={t("spray-sterilizing-solution")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Title level={5} style={{ color: "#425F4E" }}>
            {Number(sprayTrue).toLocaleString()}
          </Typography.Title>
        </Col>
        <Col span={12}>
          <LabelStyle
            color="#BF9C3D"
            text={t("not-spray-sterilizing-solution")}
          />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Title level={5} style={{ color: "#BF9C3D" }}>
            {Number(sprayFalse).toLocaleString()}
          </Typography.Title>
        </Col>
      </Row>
    </CardHomeData>
  );
};

const CountOfVehiclePassAndFail = ({
  data,
  startDate,
  endDate,
}: {
  data?: IVehicleInspectionDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const pass = Number(
    data?.totalVehicleCondition?.[0]?.totalVehicleStatusPassed || 0
  );
  const fail = Number(
    data?.totalVehicleCondition?.[0]?.totalVehicleStatusFailed || 0
  );
  const sum = Number(pass + fail).toLocaleString();
  return (
    <CardHomeData
      title="count-of-vehicle-pass-and-fail"
      startDate={startDate}
      endDate={endDate}
    >
      <Row gutter={[12, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[t("passed-the-inspection"), t("fail-the-inspection")]}
            colors={["#425F4E", "#BF9C3D"]}
            series={[pass, fail]}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>{`${sum || 0} ${t(
            "cars"
          )}`}</Typography.Title>
        </Col>
        <Col span={12}>
          <LabelStyle color="#425F4E" text={t("passed-the-inspection")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Title level={5} style={{ color: "#425F4E" }}>
            {Number(pass).toLocaleString()}
          </Typography.Title>
        </Col>
        <Col span={12}>
          <LabelStyle color="#BF9C3D" text={t("fail-the-inspection")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Title level={5} style={{ color: "#BF9C3D" }}>
            {Number(fail).toLocaleString()}
          </Typography.Title>
        </Col>
      </Row>
    </CardHomeData>
  );
};

const CountOfQuickLab = ({
  data,
  startDate,
  endDate,
}: {
  data?: IVehicleInspectionDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const pass = Number(
    data?.totalCheckQuicklab?.[0]?.totalVehicleStatusPassed || 0
  );
  const fail = Number(
    data?.totalCheckQuicklab?.[0]?.totalVehicleStatusFailed || 0
  );
  const statusVip = Number(
    data?.totalCheckQuicklab?.[0]?.totalVehicleStatusVip || 0
  );
  const sum = Number(pass + fail + statusVip).toLocaleString();
  return (
    <CardHomeData
      title="count-vehicle-pass-quick-lab"
      startDate={startDate}
      endDate={endDate}
    >
      <Row className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            colors={["#425F4E", "#438860", "#BF9C3D"]}
            labels={[t("pass"), t("not-pass"), t("conditionally-passed")]}
            series={[pass, fail, statusVip]}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>{`${sum || 0} ${t(
            "cars"
          )}`}</Typography.Title>
        </Col>
        <Col span={12}>
          <LabelStyle color="#425F4E" text={t("pass")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Title level={5} style={{ color: "#425F4E" }}>
            {Number(pass).toLocaleString()}
          </Typography.Title>
        </Col>
        <Col span={12}>
          <LabelStyle color="#438860" text={t("not-pass")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Title level={5} style={{ color: "#438860" }}>
            {Number(fail).toLocaleString()}
          </Typography.Title>
        </Col>
        <Col span={12}>
          <LabelStyle color="#BF9C3D" text={t("conditionally-passed")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Title level={5} style={{ color: "#BF9C3D" }}>
            {Number(statusVip).toLocaleString()}
          </Typography.Title>
        </Col>
      </Row>
    </CardHomeData>
  );
};

const CounutVehicleWaitForWeighing = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="count-vehicle-wait-weight"
      theme="yellow"
      value="0"
      valueType="cars"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const CountVehicleForLoading = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="count-vehicle-wait-loading"
      theme="yellow"
      value="0"
      valueType="cars"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const CountVehicleStartToLoad = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="count-vehicle-start-loading"
      theme="deepGreen"
      value="0"
      valueType="cars"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const CountVehicleCompletedLoading = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="count-vehicle-completed-loading"
      theme="green"
      value="0"
      valueType="cars"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const CountVehicleWeightOut = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="count-vehicle-weight-out"
      theme="blue"
      value="0"
      valueType="cars"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const CountVehicleBroken = ({
  data,
  startDate,
  endDate,
}: {
  data?: IVehicleInspectionDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="count-vehicle-broken"
      theme="red"
      value={data?.totalAllVehicleDisrepair}
      valueType="cars"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const CountVehicleGetInspaction = ({
  data,
  startDate,
  endDate,
}: {
  data?: IGraphVehicle[];
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const getCategory = () => {
    return Array(24)
      .fill(undefined)
      .map((_, i) => i++)
      .filter((e) => !(e % 2));
  };

  const categories = getCategory().map((e) =>
    e >= 10 ? `${e}:00` : `0${e}:00`
  );

  const getHour = (hour: string) => {
    return dayjs(hour, "HH:mm:ssZZ").hour();
  };

  const getSupplier = () => {
    const values = getCategory()?.map((item) => {
      const count = data?.find((e) => getHour(e?.timeInterval) === item);
      const count2 = data?.find((e) => getHour(e?.timeInterval) === item - 1);
      if (count2)
        return (
          Number(count?.totalSupplier?.count || 0) +
          Number(count2?.totalSupplier?.count || 0)
        );
      if (count) return Number(count?.totalSupplier?.count || 0);
      return 0;
    });

    return values;
  };

  const getReceivers = () => {
    const values = getCategory()?.map((item) => {
      const count = data?.find((e) => getHour(e?.timeInterval) === item);
      const count2 = data?.find((e) => getHour(e?.timeInterval) === item - 1);
      if (count2)
        return (
          Number(count?.totalReceivers?.count || 0) +
          Number(count2?.totalReceivers?.count || 0)
        );
      if (count) return Number(count?.totalReceivers?.count || 0);
      return 0;
    });

    return values;
  };

  return (
    <Row className="p-5">
      <Col span={24}>
        <Typography.Text
          ellipsis
          className="font-bold"
          style={{ fontSize: "20px" }}
        >
          {t("count-vehicle-get-inspaction")}
        </Typography.Text>
      </Col>
      <Col span={1} className="center">
        <MenuSchedule className="w-[22px] h-[22px]" />
      </Col>
      <Col span={11} className="text-start">
        <span style={{ color: "#9A9A9A", fontSize: "18px" }}>
          {`${formatDate({ date: startDate, format: "normal" })} - ${formatDate(
            { date: endDate, format: "normal" }
          )}`}
        </span>
      </Col>
      <Col span={6} className="!flex !justify-end">
        <LabelStyle color="#438860" text={t("supplier-deliver-goods")} />
      </Col>
      <Col span={6} className="!flex !justify-end">
        <LabelStyle color="#BF9C3D" text={t("consignee-list")} />
      </Col>
      <Col span={24}>
        <LineChartHome
          height={280}
          categories={categories}
          series={[
            {
              name: t("supplier-deliver-goods"),
              data: getSupplier(),
            },
            {
              name: t("consignee-list"),
              data: getReceivers(),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default VehicleInspectionHomePage;
