import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ICameraGroup, ICameraGroupParams } from "./interface";

export const useGetCameraGroups = (
  params?: ICameraGroupParams
): UseQueryResult<IResponse<ICameraGroup[]>> => {
  return useQuery(
    ["get-camera-groups", params],
    async () => {
      const res = await axios.get("/camera-group", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetCameraDevicesScroll = (
  params?: ICameraGroupParams
): UseInfiniteQueryResult<IResponse<ICameraGroup[]>> => {
  return useInfiniteQuery(
    ["get-camera-groups-scroll", params],
    async ({ pageParam }) => {
      const res = await axios.get("/camera-group", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetEncodeDevice = (
  id?: string | number
): UseQueryResult<ICameraGroup> => {
  return useQuery(["get-camera-group", id], async () => {
    if (!id) return;
    const res = await axios.get(`/camera-group/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const usePostCameraGroup = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post("/camera-group", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchCameraGroup = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/camera-group/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteCameraGroup = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/camera-group/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteCameraGroupBulk = () => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/camera-group/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
