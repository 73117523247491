import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "../../config/axios";
import { IResponse } from "../../config/axios/interface";
import { IPosition, IPositionParams } from "./interface";

export const useGetPosition = (
  params: IPositionParams
): UseQueryResult<IResponse<IPosition[]>> => {
  return useQuery(
    ["get-positions", params],
    async () => {
      const res = await axios.get("/position", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostPosition = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/position", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchPositionId = (): UseMutationResult => {
  return useMutation(async ({ id, ...values }: any) => {
    const res = await axios.patch(`/position/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePosition = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/position/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePositions = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/position/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useScrollGetPositions = (
  params?: IPositionParams
): UseInfiniteQueryResult<IResponse<IPosition[]>> => {
  return useInfiniteQuery(
    ["get-positions", params],
    async ({ pageParam }) => {
      const res = await axios.get("/position", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};
