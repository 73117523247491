import { Checkbox, Col, Form, Row, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import CInput from "components/input/c-input";
import CRangePicker from "components/input/c-range-picker";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import dayjs from "dayjs";
import withreportVehicleInspection, {
  useReportVehicleInspection,
} from "provider/report-vehicle-inspection";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReportContent from "./content";

const VehicleInspectionReport = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <Sidebar />
        </Col>
        <Col span={18} className="px-5">
          <ReportContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { columnsKey, setColumnsKey, setParams, params } =
    useReportVehicleInspection();

  useEffect(() => {
    form.setFieldsValue({ vehicleQueueStatus: "VEHICLE_SPRAY" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plainOptions = [
    {
      key: "date",
      title: t("date"),
    },
    {
      key: "licensePlate",
      title: t("license-plate-number"),
    },
    {
      key: "name",
      title: t("full-name"),
    },
    {
      key: "phoneNumber",
      title: t("phone"),
    },
    {
      key: "companyName",
      title: `${t("company-name")} / ${t("transport-name")}`,
    },
    {
      key: "userType",
      title: t("person-type"),
    },
  ];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setColumnsKey(checkedValues as string[]);
  };

  return (
    <Row
      className="p-5"
      style={{
        backgroundColor: "#fff",
        height: "calc(100vh - 180px)",
        overflowY: "auto",
      }}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("status")}
              rules={[{ required: true }]}
              name="vehicleQueueStatus"
              initialValue="VEHICLE_SPRAY"
            >
              <CSelect
                placeholder={t("status")}
                valueOptions={{
                  values: [
                    {
                      vehicleQueueStatus: "VEHICLE_SPRAY",
                      label: t("check-the-spray"),
                    },
                    {
                      vehicleQueueStatus: "VEHICLE_CONDITION",
                      label: t("check-the-condition-of-the-car"),
                    },
                    {
                      vehicleQueueStatus: "VEHICLE_QUICKLAB",
                      label: t("ckeck-quick-lab"),
                    },
                  ],
                  titleKey: "label",
                  valueKey: "vehicleQueueStatus",
                }}
                className="w-100"
                onChange={(e) => {
                  setParams?.({
                    ...params,
                    vehicleQueueStatus: e,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("date")} name="date">
              <CRangePicker
                className="w-100"
                onChange={(e) => {
                  const sDate = e
                    ? dayjs(e?.[0]).startOf("day").toISOString()
                    : undefined;
                  const eDate = e
                    ? dayjs(e?.[1])?.endOf("day").toISOString()
                    : undefined;
                  setParams?.({ ...params, startDate: sDate, endDate: eDate });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="licensePlate" label={t("license-plate-number")}>
              <CSelect
                allowClear
                placeholder={t("license-plate-number")}
                mode="tags"
                onChange={(e) => {
                  setParams?.({
                    ...params,
                    licensePlate: e,
                  });
                }}
                open={false}
                multipleMode
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={`${t("company-name")} / ${t("transport-name")}`}
              name="companyName"
            >
              <CSelect.CompanyTage
                placeholder={`${t("company-name")} / ${t("transport-name")}`}
                multipleMode
                onChange={(e) => {
                  setParams?.({
                    ...params,
                    companyName: e,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("full-name")} name="fullName">
              <CInput
                placeholder={t("full-name")}
                onChange={(e) => {
                  setParams?.({
                    ...params,
                    fullName: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("phone")} name="phoneNumber">
              <CSelect
                allowClear
                placeholder={t("phone")}
                mode="tags"
                onChange={(e) => {
                  setParams?.({
                    ...params,
                    phoneNumber: e,
                  });
                }}
                open={false}
                multipleMode
              />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item label={t("inspector")}>
              <CInput placeholder={t("inspector")} />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Typography.Title level={5}>
              {t("required-information")}
            </Typography.Title>
          </Col>
          <Col span={24} className="pt-4">
            <Checkbox.Group defaultValue={columnsKey} onChange={onChange}>
              <Row gutter={[0, 12]}>
                {plainOptions.map((item, index) => {
                  return (
                    <Col span={24} key={index}>
                      <Checkbox value={item?.key}>{item.title}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default withreportVehicleInspection(VehicleInspectionReport);
