export const MenuResourceAccessControlEquipment = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.448"
      height="25.448"
      viewBox="0 0 25.448 25.448"
      {...props}
    >
      <path
        fill="#fff"
        d="M6.9 3.446a3.419 3.419 0 01-1.3 2.693 3.393 3.393 0 01-2.154.761A3.453 3.453 0 010 3.457a3.388 3.388 0 011.018-2.428v-.011A3.441 3.441 0 016.9 3.446z"
        transform="translate(15.651 5.842)"
      ></path>
      <path
        fill="#fff"
        d="M19.266 15.85a5.987 5.987 0 01-4.061 1.58H4.2A4.341 4.341 0 010 12.987a4.315 4.315 0 013.287-4.3A6.575 6.575 0 016.394.973a7.052 7.052 0 017.571.265 4.874 4.874 0 00-.562.488l-.456.477v.064a4.937 4.937 0 00-1.018 3.011 5.05 5.05 0 005.047 5.037 4.957 4.957 0 003.128-1.1 4.222 4.222 0 00.456-.415 5.886 5.886 0 01-1.294 7.05z"
        data-name="Vector"
        transform="translate(2.131 4.011)"
      ></path>
    </svg>
  );
};

export const MenuResourceEncodingEquipment = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/video-octagon" transform="translate(-108 -636)">
        <g>
          <path
            fill="#fff"
            d="M16.59 3.85L10.65.42a3.163 3.163 0 00-3.15 0L1.57 3.85A3.149 3.149 0 000 6.58v6.84a3.174 3.174 0 001.57 2.73l5.94 3.43a3.163 3.163 0 003.15 0l5.94-3.43a3.149 3.149 0 001.57-2.73V6.58a3.192 3.192 0 00-1.58-2.73zm-5.26 7.55l-1.04.6-1.04.6c-1.33.77-2.42.14-2.42-1.4V8.8c0-1.54 1.09-2.17 2.42-1.4l1.04.6 1.04.6c1.33.77 1.33 2.03 0 2.8z"
            data-name="Vector"
            transform="translate(110.92 638)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuResourceAllVideo = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.01"
      height="23.01"
      viewBox="0 0 23.01 23.01"
      {...props}
    >
      <g
        id="vuesax_bold_video"
        data-name="vuesax/bold/video"
        transform="translate(-108 -252)"
      >
        <g id="video" transform="translate(108 252)">
          <path
            id="Vector"
            d="M18.6,2.8a2.156,2.156,0,0,0-2.339.355l-1.409,1C14.745,1.17,13.451,0,10.306,0H4.554C1.275,0,0,1.275,0,4.554v7.67a4.233,4.233,0,0,0,4.554,4.554h5.752c3.145,0,4.439-1.17,4.544-4.151l1.409,1a2.638,2.638,0,0,0,1.524.556,1.744,1.744,0,0,0,.815-.2,2.164,2.164,0,0,0,1.055-2.119V4.918A2.164,2.164,0,0,0,18.6,2.8Zm-9.731,5a1.8,1.8,0,1,1,1.8-1.8A1.806,1.806,0,0,1,8.868,7.795Z"
            transform="translate(1.678 3.116)"
            fill="#fff"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H23.01V23.01H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
