import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";
import provinces from "components/data/provinces.json";

const CSelectProvince: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  const data = provinces.data;

  return (
    <CSelect
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => (option?.value ?? "").includes(input)}
      placeholder={t("plate-province")}
      {...props}
      valueOptions={{
        values: data,
        titleKey: "province",
        valueKey: "province",
      }}
    />
  );
};

export default CSelectProvince;
