import Icon from "@ant-design/icons";
import { BarrierOpenIcon, BarrierCloseIcon } from "assets/icons/general";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CRadioGroupProps, StyledRadioGroup } from "./c-radio-group";

type BarrierActionTypes = "close-barrier" | "open-barrier";

const CRadioGroupBarrier: FC<CRadioGroupProps> = (props) => {
  return (
    <StyledRadioGroup
      {...props}
      height='45px'
      buttonStyle="solid"
      optionType="button"
      options={[
        { label: <Label type="open-barrier" />, value: true },
        { label: <Label type="close-barrier" />, value: false },
      ]}
    />
  );
};

const icons: { [K in BarrierActionTypes]: () => JSX.Element } = {
  "close-barrier": BarrierCloseIcon,
  "open-barrier": BarrierOpenIcon,
};

const Label: FC<{ type: BarrierActionTypes }> = ({ type }) => {
  const { t } = useTranslation();
  return (
    <div className="center">
      <Icon component={icons[type]} /> <span className="ml-1">{t(type)}</span>
    </div>
  );
};

export default CRadioGroupBarrier;
