import { createContext, FC, ReactNode, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  AppLanguageTypes,
  DateFormatType,
  DATE_FORMATS,
  IAppLanguageContext,
} from "./interface";
import { ValidateMessages } from "rc-field-form/lib/interface";

import { Locale } from "antd/lib/locale-provider";
import locale_en from "antd/lib/locale/en_US";
import locale_th from "antd/lib/locale/th_TH";

import dayjs from "dayjs";
import dayjs_th from "dayjs/locale/th";
import dayjs_en from "dayjs/locale/en";
import { ConfigProvider } from "antd";

const AppLanguageContext = createContext<IAppLanguageContext>(
  {} as IAppLanguageContext
);

const AppLanguageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n, t } = useTranslation("form");
  const lang = i18n.language as AppLanguageTypes;

  useEffect(() => {
    dayjs.locale(days[lang]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const validateMessages: ValidateMessages = {
    required: t("required"),
    types: {
      number: t("number"),
      email: t("email"),
    },
    whitespace: t("whitespace"),
  };

  const antd: { [K in AppLanguageTypes]: Locale } = {
    th: locale_th,
    en: locale_en,
  };

  const days: { [K in AppLanguageTypes]: ILocale } = {
    th: dayjs_th,
    en: dayjs_en,
  };

  const changeLanguage = (value: AppLanguageTypes) => {
    i18n.changeLanguage(value);
    localStorage.setItem("tkc-language", value);
  };

  const capitalizeFirstLetter = (str?: string) => {
    if (!str) return "Th";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const translationObject = ({
    object,
    keys,
    empty = "",
  }: {
    object: any;
    keys: string[];
    empty?: string;
  }): Record<string, any> => {
    const suffix = capitalizeFirstLetter(lang);
    return keys.reduce((r, c) => {
      if (c.includes(".")) {
        const split = c.split(".");
        const left = [...split].slice(1).join(".");
        const obj = object?.[split?.[0]];
        const value = translationObject({ object: obj, keys: [left] });
        return { ...r, ...value };
      }
      const value = object?.[`${c}${suffix}`] || empty;
      return { ...r, [c]: value };
    }, {});
  };

  const formatDate = ({
    date,
    format = "normal",
    keySeparator = " ",
  }: {
    date?: dayjs.Dayjs | string;
    format?: DateFormatType;
    keySeparator?: string;
  }) => {
    const fmt = DATE_FORMATS[format][lang].replaceAll("/", keySeparator);
    return dayjs(date).locale(lang).format(fmt);
  };

  return (
    <AppLanguageContext.Provider
      value={{ lang, changeLanguage, translationObject, formatDate }}
    >
      <ConfigProvider
        input={{ autoComplete: "off" }}
        form={{ validateMessages }}
        locale={antd[lang]}
      >
        {children}
      </ConfigProvider>
    </AppLanguageContext.Provider>
  );
};

export const useAppLanguage = () => useContext(AppLanguageContext);
export default AppLanguageProvider;
