import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Form, Input, Radio, RadioChangeEvent, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { IReciving } from "services/setting-reciving-point/interface";
import { useQueryClient } from "@tanstack/react-query";
import CSelect from "components/input/c-select";
import { FormInstance } from "antd/es/form/Form";
import CInput from "components/input/c-input";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";

import CCard from "components/display/card";
import CDrawer from "components/display/c-drawer";
import {
  useDeleteVehicleType,
  useDeleteVehicleTypes,
  useGetVehicleTypes,
  usePatchVehicleType,
  usePostVehicleType,
} from "services/setting-vehicle-type";
import { IVehicleTypeParams } from "services/setting-vehicle-type/interface";

const RecivingPoint = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { formatDate, translationObject } = useAppLanguage();
  const [params, setParams] = useState<IVehicleTypeParams>({
    page: 1,
    limit: 10,
  });

  const del_id = useDeleteVehicleType();
  const del_bluk = useDeleteVehicleTypes();
  const get_vehicleType = useGetVehicleTypes(params);

  const toggle = () => {
    setVisible(!visible);
  };

  const elements: ISearchBoxElement[] = [
    {
      name: "vehicle",
      label: t("vehicle-type"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "createdAt",
      label: t("created-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "updatedAt",
      label: t("updated-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "status",
      label: t("status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              { key: true, value: t("active") },
              { key: false, value: t("in-active") },
            ],
            valueKey: "key",
            titleKey: "value",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<IReciving> = [
    {
      title: t("vehicle-type"),
      render: (_, rc) => {
        const { vehicle } = translationObject({
          object: rc,
          keys: ["vehicle"],
        });
        return (
          <Typography.Text ellipsis={{ tooltip: vehicle }}>
            {vehicle}
          </Typography.Text>
        );
      },
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      render: (_, rc) => {
        if (!rc.createdAt) return <span>-</span>;
        const date = formatDate({ date: rc.createdAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      render: (_, rc) => {
        if (!rc.updatedAt) return <span>-</span>;
        const date = formatDate({ date: rc.updatedAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("liquid-spray"),
      dataIndex: "liquidSpray",
      align: "center",
      width: "20%",
      render: (liquidspray: true | false) => {
        return (
          <CCard.LiquidSprayCard
            liquidspray={liquidspray ? "spray" : "do-not-spray"}
          />
        );
      },
    },
    {
      title: t("number-of-packages"),
      dataIndex: "packageQuantity",
      align: "center",
      render: (item) => {
        return item || 0;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      width: "20%",
      render: (status: "true" | "false") => {
        return (
          <CCard.ActiveInActive
            status={status === "true" ? "active" : "in-active"}
          />
        );
      },
    },
  ];

  const onRowClick = (values: any) => {
    form.setFieldsValue(values);
    toggle();
  };

  const onSearch = (FormItem: any) => {
    setParams({
      ...params,
      ...FormItem,
      startUpdatedAt: FormItem["updatedAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endUpdatedAt: FormItem["updatedAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
      startCreatedAt: FormItem["createdAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endCreatedAt: FormItem["createdAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
    });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <CreateAndEditDrawer visible={visible} onClose={toggle} form={form} />
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        onAdd={{
          onClick: toggle,
        }}
        onRowClick={onRowClick}
        action={{
          bulkDelete: {
            mutation: del_bluk,
            invalidateQueries: ["get-vehivle-types", "get-vehivle-type"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: del_id,
          invalidateQueries: ["get-vehivle-types", "get-vehivle-type"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        query={get_vehicleType}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        columns={columns}
        title={t("vehicle-type-list")}
      />
    </PageContent>
  );
};

const CreateAndEditDrawer = ({
  visible,
  onClose,
  form,
}: {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
}) => {
  const { t } = useTranslation();
  const post_Reciving = usePostVehicleType();
  const patch_Reciving = usePatchVehicleType();
  const qClient = useQueryClient();

  const onFinish = (values: any) => {
    const { id } = values;
    if (!values.status) values.status = "true";
    if (id) {
      return patch_Reciving.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-vehivle-types"]);
          qClient.invalidateQueries(["get-vehivle-type"]);
          onClose();
          form.resetFields();
        },
        onError: ({ meesage }: any) => {
          fireNotification({ type: "error", description: meesage });
        },
      });
    }

    return post_Reciving.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        qClient.invalidateQueries(["get-vehivle-types"]);
        qClient.invalidateQueries(["get-vehivle-type"]);
        onClose();
        form.resetFields();
      },
      onError: ({ meesage }: any) => {
        fireNotification({ type: "error", description: meesage });
      },
    });
  };

  // const statusDevice = [
  //   {
  //     value: "true",
  //     label: t("online"),
  //   },
  //   {
  //     value: "false",
  //     label: t("offline"),
  //   },
  // ];

  // const pickupCode = <span>{t("vehicle-type")}</span>;
  const vehicleTh = (
    <span>
      {t("vehicle-type")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const vehicleEn = (
    <span>
      {t("vehicle-type")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );
  const descriptionTh = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const descriptionEn = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );

  const titleForm = form.getFieldValue("id")
    ? t("edit-vehicle-type")
    : t("add-vehicle-type");

  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <CDrawer
      form={form}
      title={titleForm}
      placement="right"
      onClose={() => {
        form.resetFields();
        onClose();
        setValue(1);
      }}
      size="large"
      visible={visible}
      width="40vw"
      onSave={onFinish}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        {/* <Form.Item
          name="pickupCode"
          label={pickupCode}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput placeholder={`${t("vehicle-type")}`} />
        </Form.Item> */}

        <Form.Item
          name="vehicleTh"
          label={vehicleTh}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput placeholder={t("vehicle-type")} />
        </Form.Item>
        <Form.Item
          name="vehicleEn"
          label={vehicleEn}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput placeholder={`${t("vehicle-type")} (${t("language-en")})`} />
        </Form.Item>

        <Form.Item label={t("status")} name="status">
          <CSelect
            placeholder={t("status")}
            defaultValue="true"
            valueOptions={{
              values: [
                { id: "true", title: t("active") },
                { id: "false", title: t("in-active") },
              ],
              valueKey: "id",
              titleKey: "title",
            }}
          />
        </Form.Item>

        <Form.Item
          name="packageQuantity"
          label={t("number-of-packages-containing")}
        >
          <CInput.Number placeholder={t("number-of-packages-containing")} />
        </Form.Item>

        <Form.Item name="liquidSpray">
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={true}>{t("spray")}</Radio>
            <Radio value={false}>{t("do-not-spray")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="descriptionTh" label={descriptionTh}>
          <CInput.TextArea placeholder={t("description")} rows={6} />
        </Form.Item>
        <Form.Item name="descriptionEn" label={descriptionEn}>
          <TextArea
            placeholder={`${t("description")} (${t("language-en")})`}
            rows={6}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default RecivingPoint;
