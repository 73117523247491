import UserWithPDPACreateAndEditPage from "components/menu/user-management/create-user-pdpa";
import { usePageRoute } from "provider/page-route";
import {
  useGetVisitor,
  usePatchVisitor,
  usePostVisitor,
} from "services/visitor";

const UserGuestCreateAndEditPage = () => {
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const post_visitor = usePostVisitor();
  const patch_visitor = usePatchVisitor();
  const data_visitor = useGetVisitor(id);

  return (
    <UserWithPDPACreateAndEditPage
      menu="visitor"
      action={{
        get: data_visitor,
        post: post_visitor,
        patch: patch_visitor,
        invalidateQueries: ["get-visitors", "get-visitor"],
      }}
    />
  );
};

export default UserGuestCreateAndEditPage;
