import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { FC } from "react";
import styled from "styled-components";

const StyledTextArea = styled(Input.TextArea)``;

const CTextArea: FC<TextAreaProps> = (props) => {
  return <StyledTextArea {...props} />;
};

export default CTextArea;
