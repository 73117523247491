import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetScheduleGroups } from "services/schedule-group";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";


const CSelectScheduleGroup: FC<CSelectProps> = (props) => {
    const { t } = useTranslation();
    return (
      <CSelectPagination
        placeholder={t("manage-table-group")}
        {...props}
        useQuery={useScrollGetScheduleGroups}
        valueKey="id"
        titleKey="groupTable"
        // searchKey="name"
        translation
        showSearch={false}
      />
    );
}

export default CSelectScheduleGroup