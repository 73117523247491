import { Card, Typography } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const StyledCard = styled(Card)<{ $isActive: boolean }>`
  ${tw`w-[140px] !p-1 shadow-sm text-center`};
  ${({ $isActive }) =>
    $isActive
      ? tw`!bg-white !border-green-500`
      : tw`!bg-white  !border-red-500`};

  span {
    ${({ $isActive }) => ($isActive ? tw`!text-green-500` : tw`!text-red-500`)};
  }
`;

export interface LiquidSprayCardProps {
  liquidspray: "spray" | "do-not-spray";
}

const LiquidSprayCard: FC<LiquidSprayCardProps> = ({ liquidspray }) => {
  const { t } = useTranslation();
  return (
    <StyledCard
      bodyStyle={{ padding: 0, margin: "auto" }}
      $isActive={liquidspray === "spray"}
    >
      <Typography.Text ellipsis={{ tooltip: t(liquidspray) }}>
        {t(liquidspray)}
      </Typography.Text>
    </StyledCard>
  );
};

export default LiquidSprayCard;
