import { Row, Col, Typography, Form, RadioChangeEvent } from "antd";
import CDrawer, { IBaseVisible } from "components/display/c-drawer";
import EditBlock from "components/display/edit-block";
import CRadioGroupBarrier from "components/input/c-radio/c-radio-group-barrier";
import CRadioRequireFaceDetection from "components/input/c-radio/require-face-detect";
import CSelect from "components/input/c-select";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { useVehicleSocket } from "provider/vehicle-socket";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBarrierGateControl } from "services/encode-device";
import { IMark } from "services/mark/interface";
import { usePatchMatchDevice, usePostMatchDevice } from "services/match-device";
import { IVehicleCompany } from "services/vehicle-company/inteface";
import { ICustomerEmployee } from "services/vehicle-inspection/interface";

type HeaderTypes =
  | "vehicle-management.entrance-screen"
  | "vehicle-management.departure-screen";

type TPayload = ICustomerEmployee | IVehicleCompany | undefined;

interface TransitionHeaderProps {
  type: HeaderTypes;
}

const TransitionHeader: FC<TransitionHeaderProps> = ({ type }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  // const [vModal, setVModal] = useState<boolean>(false);
  const [openBarrier, setOpenBarrier] = useState<boolean>(false);

  const { selected, requireFace, setRequireFace, matchDevice, detect } =
    useVehicleSocket();
  const { setAppLoading } = useAppLoading();

  const { mutate } = useBarrierGateControl();
  const patch_match_device = usePatchMatchDevice();

  const toggle = () => {
    setVisible(!visible);
  };

  // const toggleModal = () => {
  //   setVModal(!vModal);
  // };

  const getDriver = () => {
    let user: TPayload = detect?.customerEmployee;
    if (detect?.isVehicleCompany) user = detect.vehicleCompany;
    return {
      firstName: user?.firstName,
      lastName: user?.lastName,
      tel: user?.phoneNumber,
      companyName: undefined,
    };
  };

  const onBarrierChange = (e: RadioChangeEvent) => {
    const { id } = selected?.vehicle?.encodeDevice || {};
    if (!id) {
      return fireNotification({
        type: "error",
        message: "missing encode device",
      });
    }

    const { value } = e.target;

    const payload = {
      vehicle: detect?.vehicle?.id,
      detectImage: detect?.detectImage?.id,
      driver: getDriver(),
    };

    setAppLoading(true);
    mutate(
      { id: id, action: value ? "open" : "close", payload },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "vehicle-management" });
          setOpenBarrier(value);
          setTimeout(() => {
            setOpenBarrier(false);
          }, 2000);
        },
        onError: ({ message }) => {
          fireNotification({
            type: "error",
            message,
            menu: "vehicle-management",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const onRequireFaceChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setAppLoading(true);
    patch_match_device.mutate(
      {
        id: matchDevice?.id,
        requireFace: value,
      },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "vehicle-management" });
          setRequireFace(value);
        },
        onError: ({ message }) => {
          fireNotification({
            type: "error",
            message,
            menu: "vehicle-management",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  return (
    <React.Fragment>
      <DeviceSelectDrawer
        type={type === "vehicle-management.entrance-screen" ? "IN" : "OUT"}
        onClose={toggle}
        visible={visible}
      />
      <Row gutter={12} align="middle">
        <Col>
          <Typography.Title level={4}>
            {t(type, { ns: "menu" })}
          </Typography.Title>
        </Col>
        <Col className="ml-auto">
          {matchDevice ? (
            <CRadioRequireFaceDetection
              value={requireFace}
              onChange={onRequireFaceChange}
            />
          ) : null}
        </Col>
        <Col hidden={!selected}>
          <CRadioGroupBarrier value={openBarrier} onChange={onBarrierChange} />
        </Col>
        <Col span={4}>
          <EditBlock value={selected?.vehicle?.markName} onClick={toggle} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const DeviceSelectDrawer: FC<IBaseVisible & { type: "IN" | "OUT" }> = ({
  visible,
  onClose,
  type,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { setSelected, selected, requireFace, setMatchDevice } =
    useVehicleSocket();
  const [itn, setItn] = useState<{
    vehicle?: IMark;
    truckFace?: IMark;
    generalFace?: IMark;
  }>();

  const post_match_device = usePostMatchDevice();
  const { setAppLoading } = useAppLoading();

  useEffect(() => {
    if (selected) {
      form.setFieldsValue({
        vehicle: selected?.vehicle?.id,
        generalFace: selected.generalFace?.id,
        truckFace: selected.truckFace?.id,
      });
      setItn(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, visible]);

  const onSave = () => {
    const { vehicle, truckFace, generalFace } = itn || {};
    if (!vehicle) {
      return fireNotification({
        type: "warning",
        menu: "vehicle-management",
        message: "please select vehicle camera!",
      });
    }

    if (!vehicle.encodeDevice) {
      return fireNotification({
        type: "warning",
        menu: "vehicle-management",
        message: "encode-device of vehicle camera is missing!",
      });
    }
    setAppLoading(true);
    const faceDevice = [
      truckFace?.encodeDevice?.id,
      generalFace?.encodeDevice?.id,
    ].filter((e) => !!e);
    post_match_device.mutate(
      {
        anprDevice: vehicle.encodeDevice.id,
        faceDevice: faceDevice,
        requireFace: requireFace,
      },
      {
        onSuccess: (data) => {
          setMatchDevice(data);
          fireNotification({ type: "success", menu: "vehicle-management" });
          setSelected({ vehicle, truckFace, generalFace });
          onClose();
        },
        onError: ({ message }) => {
          fireNotification({
            type: "error",
            menu: "vehicle-management",
            message,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
    // if (truckFace && !truckFace.encodeDevice) {
    //   return fireNotification({
    //     type: "warning",
    //     menu: "vehicle-management",
    //     message: "encode-device of face camera is missing!",
    //   });
    // }
  };

  return (
    <CDrawer
      form={form}
      title={t("select-device")}
      onClose={onClose}
      visible={visible}
      onSave={onSave}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          rules={[{ required: true }]}
          name="vehicle"
          label={t("vehicle-camera")}
        >
          <CSelect.EntranceDepartureCamera
            barrierType={type}
            placeholder={t("vehicle-camera")}
            onSelectCallback={(vehicle) => {
              setItn({ ...itn, vehicle });
            }}
          />
        </Form.Item>
        <Form.Item
          name="truckFace"
          label={`${t("face-camera")} (${t("truck")})`}
        >
          <CSelect.EntranceDepartureCamera
            barrierType={type}
            placeholder={`${t("face-camera")} (${t("truck")})`}
            onSelectCallback={(truckFace) => {
              setItn({ ...itn, truckFace });
            }}
          />
        </Form.Item>
        <Form.Item
          name="generalFace"
          label={`${t("face-camera")} (${t("general")})`}
        >
          <CSelect.EntranceDepartureCamera
            barrierType={type}
            placeholder={`${t("face-camera")} (${t("general")})`}
            onSelectCallback={(generalFace) => {
              setItn({ ...itn, generalFace });
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default TransitionHeader;
