import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserContactTypes } from "services/user/interface";
import CRadioGroup, { CRadioGroupProps } from "./c-radio-group";

const CRadioContactType: FC<CRadioGroupProps> = (props) => {
  const { t } = useTranslation();

  const options: { label: string; value: UserContactTypes }[] = [
    {
      label: t("pick-up"),
      value: "PICKUP",
    },
    {
      label: t("deliver"),
      value: "DELIVER",
    },
    {
      label: t("coordination"),
      value: "COORDINATION",
    },
    {
      label: t("apply-for-work"),
      value: "APPLY_FOR_WORK",
    },
    {
      label: t("meeting"),
      value: "MEETING",
    },
    {
      label: t("working"),
      value: "WORKING",
    },
    {
      label: t("other"),
      value: "OTHER",
    },
  ];

  return (
    <CRadioGroup
      spaceSize={24}
      optionType="default"
      options={options}
      {...props}
    />
  );
};
export default CRadioContactType;
