import { TableColumnsType, Typography } from "antd";
import CTable from "components/display/c-table";
import { useAppLanguage } from "provider/app-language";
import { useReportDoorLog } from "provider/report-door-log";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportReportDoorLog,
  useGetReportDoorLogs,
} from "services/report-door-log";
import {
  IReportDoorLog,
  IReportDoorLogParams,
} from "services/report-door-log/interface";
import styled from "styled-components";
import tw from "twin.macro";

const TemperatureStatusCard = styled.div<{
  color: "FALSE" | "TRUE" | undefined;
}>`
  ${tw`rounded-app`}
  height: 35px;
  width: 80%;
  background-color: ${({ color }) =>
    color === "TRUE" ? "#EAF7EC" : "#FFE6E6"};
  color: ${({ color }) => (color === "TRUE" ? "#47B174" : "#DB1212")};
  border: 1px solid ${({ color }) => (color === "TRUE" ? "#47B174" : "#DB1212")};
  padding: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

const AccesscontrolAllReportContent = () => {
  const { t } = useTranslation();
  const { columnsKey, search } = useReportDoorLog();

  const [params, setParams] = useState<IReportDoorLogParams>({
    page: 1,
    limit: 10,
  });

  const get_report_doorLog = useGetReportDoorLogs({
    ...params,
    startDate: search?.startDate,
    endDate: search?.endDate,
    fullName: search?.fullName,
    phoneNumber: search?.phoneNumber,
    actionType: search?.actionType,
    maskStatus: search?.maskStatus,
    companyName: search?.companyName,
    temperatureStatus: search?.temperatureStatus,
    doorName: search?.doorName,
  });

  const { formatDate } = useAppLanguage();
  const export_report = useExportReportDoorLog();

  const columns: TableColumnsType<IReportDoorLog> = [
    {
      key: "date",
      title: t("entry-date"),
      render: (_, rc) => {
        const date = formatDate({
          date: rc?.createdAt,
          format: "nomal-full-time",
        });

        return (
          <Typography.Text ellipsis={{ tooltip: date }}>
            {date || "-"}
          </Typography.Text>
        );
      },
    },
    {
      key: "fullName",
      title: t("full-name"),
      ellipsis: true,
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return "-";

        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
      render: (_, rc) => {
        const phoneNumber = rc?.phoneNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: phoneNumber }}>
            {phoneNumber}
          </Typography.Text>
        );
      },
    },
    {
      key: "actionType",
      title: t("save-type"),
      render: (_, rc) => {
        const actionType = rc?.actionType === "door" ? t("door") : rc?.actionType;
        if (!rc?.actionType) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: actionType }}>
            {actionType}
          </Typography.Text>
        );
      },
    },
    {
      key: "maskStatus",
      title: t("face-mask"),
      render: (_, rc) => {
        const text = rc?.maskStatus
          ? rc.maskStatus === "FALSE"
            ? t("not-wear")
            : t("wear")
          : t("no-data");
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      key: "companyName",
      title: t("company-or-shipping"),
      render: (_, rc) => {
        const company = rc?.companyName || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: company }}>
            {company}
          </Typography.Text>
        );
      },
    },
    {
      key: "temperatureStatus",
      title: t("temperature-status"),
      render: (_, rc) => {
        const status = rc?.temperatureStatus;
        return (
          <TemperatureStatusCard color={status}>
            {status === "FALSE" ? t("not-pass") : t("pass")}
          </TemperatureStatusCard>
        );
      },
    },
    {
      key: "doorName",
      title: t("source-of-events"),
      render: (_, rc) => {
        const doorName = rc?.door?.doorName || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: doorName }}>
            {doorName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      key: "userType",
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        const userTypeKey = rc?.personType;
        if (!userTypeKey) return <span>-</span>;
        const type =
          userTypeKey === "VISITOR"
            ? t("guests")
            : userTypeKey === "CONTRACTOR"
            ? t("contractor-full-time")
            : userTypeKey === "SUPPLIER"
            ? t("supplier-deliver-goods")
            : userTypeKey === "RECEIVER"
            ? t("consignee")
            : t("employee");
        return (
          <Typography.Text ellipsis={{ tooltip: type }}>{type}</Typography.Text>
        );
      },
    },
  ];

  const _columns = columns.filter((item) => {
    return columnsKey.some((e) => e === item.key);
  });

  const topic = _columns
    ?.map((item) => {
      if (item?.key === "date") return "createdAt";
      if (item?.key === "fullName") return ["firstName", "lastName"];
      if (item?.key === "phoneNumber") return "phoneNumber";
      if (item?.key === "companyName") return "companyName";
      if (item?.key === "doorName") return "door.doorName";
      if (item?.key === "userType") return "personType";

      return item?.key;
    })
    .flat();

  return (
    <div className="p-5">
      <CTable
        scroll={{ x: 1200 }}
        selectAble={false}
        title={t("list-all")}
        columns={_columns}
        query={get_report_doorLog}
        pagination={{
          onChange: (page, limit) => {
            setParams({ page, limit });
          },
        }}
        action={{
          excels: {
            export: {
              mutation: export_report,
              extraValue: { topic: topic },
            },
          },
        }}
      />
    </div>
  );
};

export default AccesscontrolAllReportContent;
