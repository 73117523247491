import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IEncodeDevice,
  IEncodeDeviceFloorParams,
  IEncodeDeviceParams,
} from "./interface";

export const useGetEncodeDevices = (
  params?: IEncodeDeviceParams
): UseQueryResult<IResponse<IEncodeDevice[]>> => {
  return useQuery(
    ["get-encode-devices", params],
    async () => {
      const res = await axios.get("/encode-device", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetEncodeDevicesScroll = (
  params?: IEncodeDeviceParams
): UseInfiniteQueryResult<IResponse<IEncodeDevice[]>> => {
  return useInfiniteQuery(
    ["get-encode-devices-scroll", params],
    async ({ pageParam }) => {
      const res = await axios.get("/encode-device", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetEncodeDevice = (
  id?: string | number
): UseQueryResult<IEncodeDevice> => {
  return useQuery(["get-encode-device", id], async () => {
    if (!id) return;
    const res = await axios.get(`/encode-device/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const useGetEncodeDeviceList = (): UseMutationResult => {
  return useMutation(async () => {
    const res = await axios.post("/hik/get-encode-device-list");
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePostEncodeDevice = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post("/encode-device", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchEncodeDevice = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/encode-device/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteEncodeDevice = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/encode-device/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteEncodeDeviceBulk = () => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/encode-device/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetDeviceNotInFloor = (
  params?: IEncodeDeviceFloorParams
): UseQueryResult<IResponse<IEncodeDevice[]>> => {
  return useQuery(
    ["get-encode-devices", params],
    async () => {
      const res = await axios.get(`/floor/device/not-in-floor`, { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      enabled: !!params?.floorId,
      keepPreviousData: true,
    }
  );
};

export const useBarrierGateControl = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.post(
      `/encode-device/${id}/barrier-gate-control`,
      data
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportEncodeDevice = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`export-excel/encode-device-export-excel`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetEncodeDevicesFromHcp = (): UseQueryResult => {
  return useQuery(
    ["get-encode-devices-from-hcp"],
    async () => {
      const res = await axios.get("/encode-device/get-encode-device-from-hcp");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
      enabled: false
    }
  );
};