import { Col, Divider, Row, Typography } from "antd";
import CButton from "components/button/c-button";
import { useVehicleFloorEdit } from "provider/vehicle-floor-edit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IPin } from "services/pin/interface";
import styled from "styled-components";
import FloorEditStep2 from "../step-2";
import FloorEditAction from "./action";
import FloorEditAddPinDrawer from "./add-pin-drawer";
import FloorEditMapPinMarker from "./map-pin-marker";
import FloorEditTablePin from "./table-pin";

const MapImageMarkerWrapper = styled.div`
  height: 433px;
  .image-marker {
    height: 100%;
  }
`;

const FloorEditStep3 = () => {
  const { t } = useTranslation();
  const { floor } = useVehicleFloorEdit();
  const [visible, setVisible] = useState<boolean>(false);
  const [pin, setPin] = useState<IPin>();

  const toggle = () => {
    setVisible(!visible);
  };

  if (!floor.mediaObject) {
    return <FloorEditStep2 />;
  }

  return (
    <div style={{ height: 500 }} className='overflow-hidden'>
      <FloorEditAddPinDrawer
        resetPin={() => setPin(undefined)}
        visible={visible}
        pin={pin}
        onClose={toggle}
      />
      <Row>
        <Col span={15}>
          <FloorEditAction />
        </Col>
        <Col className="center">
          <Divider type="vertical" className="!m-0" style={{ height: 67 }} />
        </Col>
        <Col flex="auto">
          <Row className="h-full px-3" align="middle">
            <Col span={16}>
              <Typography.Title level={5}>
                {t("parking-lot-type-management")}
              </Typography.Title>
            </Col>
            <Col span={8}>
              <CButton theme="white" className="w-full" onClick={toggle}>
                + {t("add")}
              </CButton>
            </Col>
          </Row>
        </Col>
        <Divider className="!m-0" />
        <Col span={15}>
          <MapImageMarkerWrapper className="center">
            <FloorEditMapPinMarker />
          </MapImageMarkerWrapper>
        </Col>
        <Col className="center">
          <Divider type="vertical" className="!m-0" style={{ height: 433 }} />
        </Col>
        <Col flex="auto">
          <div className="p-4" style={{ height: 433 }}>
            <FloorEditTablePin
              onEdit={(p) => {
                setPin(p);
                toggle();
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FloorEditStep3;
