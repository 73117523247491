import React, {
  ComponentType,
  createContext,
  useContext,
  useState,
} from "react";
import { IDisplayScreenContext } from "./interface";

const Context = createContext<IDisplayScreenContext>(
  {} as IDisplayScreenContext
);

const withDisplayScreenProvider = (Component: ComponentType) => {
  return () => {
    const [visibleEntrance, setVisibleEntrance] = useState<boolean>(false);
    const [visibleDeparture, setVisibleDeparture] = useState<boolean>(false);
    return (
      <Context.Provider
        value={{
          visibleEntrance,
          setVisibleEntrance,
          visibleDeparture,
          setVisibleDeparture,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useDisplayScreen = () => useContext(Context);
export default withDisplayScreenProvider;
