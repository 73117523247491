export const MenuSettingRole = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="#fff"
        data-name="vuesax/bold/frame"
        transform="translate(-236 -188)"
      >
        <path
          d="M16.82 4.957v1.83A1.967 1.967 0 0116 8.4L5 16.457a2 2 0 01-2.38-.01l-1.44-1.08A3.339 3.339 0 010 13.007v-8.05a3.162 3.162 0 011.91-2.75L7.38.157a3.447 3.447 0 012.06 0l5.47 2.05a3.162 3.162 0 011.91 2.75z"
          transform="translate(239.59 190.003)"
        ></path>
        <path
          d="M9.814.193A1 1 0 0111.4 1v1.88a3.317 3.317 0 01-1.18 2.35L4.75 9.32a3.024 3.024 0 01-1.76.53 2.961 2.961 0 01-1.76-.54L.4 8.693a1 1 0 01.01-1.61z"
          data-name="Vector"
          transform="translate(245.006 200.147)"
        ></path>
      </g>
    </svg>
  );
};

export const MenuSettingPosition = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/user-octagon" transform="translate(-620 -188)">
        <g>
          <path
            fill="#fff"
            d="M16.59 3.85L10.65.42a3.163 3.163 0 00-3.15 0L1.57 3.85A3.149 3.149 0 000 6.58v6.84a3.174 3.174 0 001.57 2.73l5.94 3.43a3.163 3.163 0 003.15 0l5.94-3.43a3.149 3.149 0 001.57-2.73V6.58a3.192 3.192 0 00-1.58-2.73zM9.08 5.34a2.33 2.33 0 11-2.33 2.33 2.326 2.326 0 012.33-2.33zm2.68 9.32H6.4a1 1 0 01-.83-1.57 4.209 4.209 0 013.51-1.69 4.209 4.209 0 013.51 1.69 1.009 1.009 0 01-.83 1.57z"
            data-name="Vector"
            transform="translate(622.92 190)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuSettingPDPA = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/security-safe" transform="translate(-364 -316)">
        <g>
          <path
            fill="#fff"
            d="M17.82 9.113V4.722a2.4 2.4 0 00-1.39-2.06L10.86.382a5.187 5.187 0 00-3.91 0l-5.57 2.28A2.411 2.411 0 000 4.722v4.39a11.524 11.524 0 008.4 10.81 1.96 1.96 0 001.02 0 11.524 11.524 0 008.4-10.809zm-8.16 1.75v2.63a.75.75 0 11-1.5 0v-2.63a2.5 2.5 0 111.5 0z"
            data-name="Vector"
            transform="translate(367.09 318.008)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuSettingSchedule = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="vuesax_bold_calendar"
        data-name="vuesax/bold/calendar"
        transform="translate(-492 -188)"
      >
        <g id="calendar">
          <path
            id="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(492 188)"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M13.711,2.31V.75a.75.75,0,0,0-1.5,0v1.5h-6.5V.75a.75.75,0,0,0-1.5,0V2.31A4.248,4.248,0,0,0,0,6.56a.5.5,0,0,0,.5.53h16.92a.5.5,0,0,0,.5-.53A4.248,4.248,0,0,0,13.711,2.31Z"
            transform="translate(495.039 189.25)"
            fill="#fff"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M17,0H1A1,1,0,0,0,0,1V7.16c0,3,1.5,5,5,5h8c3.5,0,5-2,5-5V1A1,1,0,0,0,17,0ZM6.21,8.37a1.576,1.576,0,0,1-.15.12.757.757,0,0,1-.18.09.636.636,0,0,1-.18.06,1.355,1.355,0,0,1-.2.02,1,1,0,0,1-.38-.08,1.032,1.032,0,0,1-.33-.21,1.014,1.014,0,0,1,0-1.42,1.032,1.032,0,0,1,.33-.21.956.956,0,0,1,.58-.06.636.636,0,0,1,.18.06.757.757,0,0,1,.18.09l.15.12a1.014,1.014,0,0,1,0,1.42Zm0-3.5a1.014,1.014,0,0,1-1.42,0,1.014,1.014,0,0,1,0-1.42,1,1,0,0,1,1.09-.21.933.933,0,0,1,.33.21,1.014,1.014,0,0,1,0,1.42Zm3.5,3.5A1,1,0,0,1,8.29,6.95,1,1,0,0,1,9.71,8.37Zm0-3.5-.15.12a.757.757,0,0,1-.18.09.636.636,0,0,1-.18.06,1.355,1.355,0,0,1-.2.02.99.99,0,0,1-1-1,1.052,1.052,0,0,1,.29-.71.933.933,0,0,1,.33-.21,1,1,0,0,1,1.09.21,1.014,1.014,0,0,1,0,1.42Zm3.5,3.5a1,1,0,0,1-1.42-1.42,1,1,0,0,1,1.42,1.42Zm0-3.5-.15.12a.757.757,0,0,1-.18.09.636.636,0,0,1-.18.06,1.5,1.5,0,0,1-.2.02.99.99,0,0,1-1-1,1.052,1.052,0,0,1,.29-.71,1.032,1.032,0,0,1,.33-.21.956.956,0,0,1,.58-.06.636.636,0,0,1,.18.06.757.757,0,0,1,.18.09l.15.12a1.014,1.014,0,0,1,0,1.42Z"
            transform="translate(495 197.84)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export const MenuSettingJobType = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/truck" transform="translate(-684 -188)">
        <g fill="#fff">
          <path
            d="M12 .92v8.31a1.852 1.852 0 01-1.85 1.85H1a1 1 0 01-1-1V3.69A3.687 3.687 0 013.69 0h7.38a.92.92 0 01.93.92z"
            data-name="Vector"
            transform="translate(686 190)"
          ></path>
          <path
            d="M19.5 10.5a.5.5 0 01.5.5v1a3 3 0 01-3 3 3 3 0 00-6 0H9a3 3 0 00-6 0 3 3 0 01-3-3v-2a1 1 0 011-1h9.5A2.5 2.5 0 0013 6.5V1a1 1 0 011-1h.84a2.016 2.016 0 011.74 1.01l.64 1.12a.252.252 0 01-.22.37A2.5 2.5 0 0014.5 5v3a2.5 2.5 0 002.5 2.5z"
            data-name="Vector"
            transform="translate(686 193)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(690 206)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(698 206)"
          ></path>
          <path
            d="M4 3.53V5H1a1 1 0 01-1-1V1a1 1 0 011-1h1.29l1.45 2.54a2 2 0 01.26.99z"
            data-name="Vector"
            transform="translate(702 197)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuSettingScale = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/timer" transform="translate(-236 -188)">
        <g fill="#fff">
          <path
            d="M8.67 0a8.675 8.675 0 108.67 8.68A8.681 8.681 0 008.67 0zm.75 8.35a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0z"
            transform="translate(239.33 192.65)"
          ></path>
          <path
            d="M6.5 1.45H.72A.717.717 0 010 .73.726.726 0 01.72 0H6.5a.717.717 0 01.72.72.726.726 0 01-.72.73z"
            data-name="Vector"
            transform="translate(244.39 190)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuSettingPickup = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/location" transform="translate(-428 -188)">
        <path
          fill="#fff"
          d="M17.5 6.7A8.626 8.626 0 008.88 0h-.01A8.624 8.624 0 00.25 6.69c-1.17 5.16 1.99 9.53 4.85 12.28a5.422 5.422 0 007.55 0c2.86-2.75 6.02-7.11 4.85-12.27zm-8.62 5.01a3.15 3.15 0 113.15-3.15 3.15 3.15 0 01-3.15 3.15z"
          transform="translate(431.12 189.75)"
        ></path>
      </g>
    </svg>
  );
};

export const MenuSettingWarehouse = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/3d-square" transform="translate(-300 -252)">
        <g data-name="3d-square">
          <path
            fill="#fff"
            d="M14.19 0H5.81C2.17 0 0 2.18 0 5.82v8.37C0 17.83 2.17 20 5.81 20h8.37c3.64 0 5.81-2.17 5.81-5.81V5.82C20 2.18 17.83 0 14.19 0zM9.57 14.79a.847.847 0 01-.41.73.812.812 0 01-.45.13.924.924 0 01-.39-.09l-3.5-1.75A1.509 1.509 0 014 12.47V9.16a.847.847 0 01.41-.73.867.867 0 01.84-.04l3.5 1.75a1.5 1.5 0 01.83 1.34v3.31zm-.21-5.35L5.6 7.41a.87.87 0 01-.44-.77.882.882 0 01.44-.77l3.76-2.03a1.359 1.359 0 011.27 0l3.76 2.03a.894.894 0 010 1.54l-3.76 2.03a1.321 1.321 0 01-.64.16 1.283 1.283 0 01-.63-.16zM16 12.47a1.488 1.488 0 01-.83 1.34l-3.5 1.75a.86.86 0 01-.39.09.812.812 0 01-.45-.13.847.847 0 01-.41-.73v-3.31a1.488 1.488 0 01.83-1.34l3.5-1.75a.867.867 0 01.84.04.847.847 0 01.41.73z"
            data-name="Vector"
            transform="translate(302 254.33)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuSettingParameter = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="MenuSettingParameterclip-Parameter">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="Parameter" clipPath="url(#MenuSettingParameterclip-Parameter)">
        <g
          id="vuesax_bold_cpu-setting"
          data-name="vuesax/bold/cpu-setting"
          transform="translate(-229 -186.999)"
        >
          <g id="cpu-setting" transform="translate(232 190)">
            <path
              id="Vector"
              d="M.73,33.085A44.739,44.739,0,0,0,0,40.545,45.6,45.6,0,0,1,.73,33.085ZM0,89.2a44.739,44.739,0,0,0,.73,7.46A45.6,45.6,0,0,1,0,89.2Zm33.166,39.815a43.768,43.768,0,0,0,7.379.73A44.62,44.62,0,0,1,33.166,129.014ZM33.166.73A44.621,44.621,0,0,1,40.545,0,43.769,43.769,0,0,0,33.166.73Zm63.493,0A44.739,44.739,0,0,0,89.2,0,45.6,45.6,0,0,1,96.659.73Zm32.355,32.355a45.6,45.6,0,0,1,.73,7.46A44.738,44.738,0,0,0,129.014,33.085Z"
              transform="translate(32.436 32.436)"
              fill="#fff"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M162.179,51.086a6.023,6.023,0,0,1-6.082,6.082H145.961v21l-5.271-3.081a14.886,14.886,0,0,0-11.434-1.622,16.28,16.28,0,0,0-5.514,2.514V62.763A24.4,24.4,0,0,0,99.416,38.437H62.763A24.4,24.4,0,0,0,38.437,62.763V99.416a24.4,24.4,0,0,0,24.327,24.327h5.352v1.7A16.353,16.353,0,0,0,84.5,141.664a16.612,16.612,0,0,0-1.784,4.3H57.249V156.1a6.082,6.082,0,0,1-12.163,0v-11.92a40.574,40.574,0,0,1-27-27h-12a6.082,6.082,0,0,1,0-12.163H16.218V87.171H6.082a6.082,6.082,0,0,1,0-12.163H16.218V57.168H6.082A6.082,6.082,0,0,1,6.082,45h12a40.574,40.574,0,0,1,27-27V6.082a6.082,6.082,0,0,1,12.163,0V16.218H75.008V6.082a6.082,6.082,0,0,1,12.163,0V16.218h17.84V6.082a6.082,6.082,0,0,1,12.163,0v12A40.346,40.346,0,0,1,144.1,45h12A6.073,6.073,0,0,1,162.179,51.086Z"
              transform="translate(16.218 16.218)"
              fill="#fff"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M77.765,28.637c-5.514,0-8.677-2.757-8.677-6.812a10.882,10.882,0,0,1,1.622-5.433,8.1,8.1,0,0,0-3-11.109L60.331.985A7.177,7.177,0,0,0,50.519,3.58l-.487.811c-3.892,6.73-10.217,6.73-14.11,0l-.487-.811A7.083,7.083,0,0,0,25.705.985l-7.46,4.3a8.143,8.143,0,0,0-4.136,7.055,8.287,8.287,0,0,0,1.135,4.054,11.064,11.064,0,0,1,1.7,5.433c0,4.054-3.244,6.812-8.758,6.812A8.2,8.2,0,0,0,0,36.746v7.622a8.2,8.2,0,0,0,8.19,8.109c5.514,0,8.758,2.757,8.758,6.812a11.064,11.064,0,0,1-1.7,5.433,8.1,8.1,0,0,0,3,11.109l7.46,4.3a7.083,7.083,0,0,0,9.731-2.595l.487-.811c3.892-6.73,10.217-6.73,14.11,0l.487.811a7.177,7.177,0,0,0,9.812,2.595l7.379-4.3a8.143,8.143,0,0,0,4.136-7.055,8.287,8.287,0,0,0-1.135-4.054,10.882,10.882,0,0,1-1.622-5.433c0-4.054,3.162-6.812,8.677-6.812a8.15,8.15,0,0,0,8.19-8.109V36.746A8.15,8.15,0,0,0,77.765,28.637ZM42.978,54.5A13.947,13.947,0,1,1,56.925,40.557,13.947,13.947,0,0,1,42.978,54.5Z"
              transform="translate(92.442 97.295)"
              fill="#fff"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M68.926,18.407V30.246a13.531,13.531,0,0,0-6.244-3.406,14.665,14.665,0,0,0-11.434,1.541l-7.379,4.3A16.161,16.161,0,0,0,35.6,46.789a15.935,15.935,0,0,0,2.189,8.19A16.335,16.335,0,0,0,21.651,68.926H18.407A18.342,18.342,0,0,1,0,50.519V18.407A18.342,18.342,0,0,1,18.407,0H50.519A18.342,18.342,0,0,1,68.926,18.407Z"
              transform="translate(62.845 62.845)"
              fill="#fff"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0H194.615V194.615H0Z"
              transform="translate(194.615 194.615) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const MenuSettingTypeSupplier = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="MenuSettingTypeSupplierclip-type_sup">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="type_sup" clipPath="url(#MenuSettingTypeSupplierclip-type_sup)">
        <g
          id="vuesax_bold_personalcard"
          data-name="vuesax/bold/personalcard"
          transform="translate(-174 -254)"
        >
          <g id="personalcard" transform="translate(172 252)">
            <path
              id="Vector"
              d="M135.717,0H33.929A33.812,33.812,0,0,0,0,33.675v85.332a33.812,33.812,0,0,0,33.929,33.675H135.717a33.812,33.812,0,0,0,33.929-33.675V33.675A33.812,33.812,0,0,0,135.717,0ZM55.135,35.371A19.594,19.594,0,1,1,35.541,54.966,19.657,19.657,0,0,1,55.135,35.371Zm32.827,80.5a4.293,4.293,0,0,1-3.138,1.357H25.447a4.293,4.293,0,0,1-3.138-1.357,4.411,4.411,0,0,1-1.1-3.223A30.1,30.1,0,0,1,48.265,85.756a73.448,73.448,0,0,1,13.657,0A30.022,30.022,0,0,1,88.98,112.645,4.025,4.025,0,0,1,87.962,115.869Zm56.238.763H127.235a6.362,6.362,0,1,1,0-12.724H144.2a6.362,6.362,0,0,1,0,12.724Zm0-33.929H110.27a6.362,6.362,0,0,1,0-12.724H144.2a6.362,6.362,0,1,1,0,12.724Zm0-33.929H101.788a6.362,6.362,0,0,1,0-12.724H144.2a6.362,6.362,0,1,1,0,12.724Z"
              transform="translate(16.965 25.447)"
              fill="#fff"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M0,0H203.576V203.576H0Z"
              transform="translate(203.576 203.576) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
