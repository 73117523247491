import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectUserType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("contact-type")}
      valueOptions={{
        values: [
          {
            label: t("guests"),
            value: "VISITOR",
          },
          {
            label: t("contractor-full-time"),
            value: "CONTRACTOR",
          },
          {
            label: t("contractor-job"),
            value: "WORKING",
          },
          {
            label: t("supplier-deliver-goods"),
            value: "SUPPLIER",
          },
          {
            label: t("consignee"),
            value: "RECEIVER",
          },

        ],
        valueKey: "value",
        titleKey: "label",
      }}
      {...props}
    />
  );
};

export default CSelectUserType;
