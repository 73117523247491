import { Row, Col, Typography, Divider, Form } from "antd";
import { DefaultOptionType } from "rc-tree-select/lib/TreeSelect";
import CIndicator from "components/display/c-indicator";
import CCard from "components/display/card";
import Container from "components/display/container";
import CSelect from "components/input/c-select";
import CTreeSelect from "components/input/c-tree-select";
import ErrorPage from "components/pages/error-page";
import PleaseSelectDataPage from "components/pages/select-data-page";
import { useParkingCamera } from "provider/vehicle-parking-camera";
import { useTranslation } from "react-i18next";
import { useFloorRoot, useParkingFloorCount } from "services/floor";
import { IFloorRoot } from "services/floor/interface";
import React, { useState } from "react";
import CDrawer from "components/display/c-drawer";
import CButton from "components/button/c-button";

const VehicleParkingCameraHeader = () => {
  return (
    <Row gutter={12}>
      <Col span={6}>
        <SelectParkingCard />
      </Col>
      <CardContent />
    </Row>
  );
};

const CardContent = () => {
  const { t } = useTranslation();
  const { selectedFloor, selectedParking } = useParkingCamera();
  const { data, isLoading, isError, error } = useParkingFloorCount({
    floorId: selectedFloor,
    parkingId: selectedParking?.id,
    enabled: !!selectedParking,
  });

  if (!selectedParking || !selectedFloor) {
    return (
      <Col span={18}>
        <Container height={139}>
          <PleaseSelectDataPage icon={false} />
        </Container>
      </Col>
    );
  }

  if (isLoading) {
    return (
      <Col span={18}>
        <Container height={139}>
          <CIndicator />
        </Container>
      </Col>
    );
  }

  if (isError || !data) {
    return (
      <Col span={18}>
        <Container height={139}>
          <ErrorPage error={error} />
        </Container>
      </Col>
    );
  }

  const { motor, markType, car } = data;

  return (
    <React.Fragment>
      <Col span={6}>
        <CCard.Percent
          value={motor.capacity - motor.unoccupied}
          max={motor.capacity}
          title={t("motorcycle")}
        />
      </Col>
      <Col span={6}>
        <CCard.Percent
          value={car.capacity - car.unoccupied}
          max={car.capacity}
          title={t("car")}
        />
      </Col>
      <Col span={6}>
        <CCard.ParkingType markType={markType} />
      </Col>
    </React.Fragment>
  );
};

const SelectParkingCard = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const { selectedParking } = useParkingCamera();

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <React.Fragment>
      <SelectParkingFloorDrawer onClose={toggle} visible={visible} />
      <Container height="auto" padding="p-0">
        <div className="p-4">
          <Typography.Title level={5} ellipsis>
            {t("select-parking-and-floor", { ns: "message" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />

        <Row
          style={{ height: 82 }}
          justify="space-between"
          align="middle"
          className="p-4"
        >
          <Col>
            <CButton style={{ height: 30 }} onClick={toggle}>
              {t("select-data", { ns: "message" })}
            </CButton>
          </Col>
          <Col className="ml-auto">
            <Typography.Text>
              {selectedParking?.parkingName || "-"}
            </Typography.Text>
          </Col>
          {/* {!selectedFloor || !selectedParking ? (
            <Col span={24} className="text-center">
              <CButton style={{ height: 30 }} onClick={toggle}>
                {t("please-select-data", { ns: "message" })}
              </CButton>
            </Col>
          ) : (
            <Col span={24} className="text-center">
              <Typography.Text>{selectedParking.parkingName}</Typography.Text>
            </Col>
          )} */}
        </Row>
      </Container>
    </React.Fragment>
  );
};

const SelectParkingFloorDrawer: React.FC<{
  visible: boolean;
  onClose: () => void;
}> = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { setSelectedParking, setSelectedFloor } = useParkingCamera();

  return (
    <CDrawer
      form={form}
      visible={visible}
      onClose={onClose}
      onSave={(values) => {
        setSelectedFloor(values.floor);
        onClose();
      }}
      title={t("select-parking-and-floor", { ns: "message" })}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          rules={[{ required: true }]}
          name="parking"
          label={t("vehicle-management.parkings", { ns: "menu" })}
        >
          <CSelect.Parking
            onSelectCallback={(value) => {
              setSelectedFloor(undefined);
              setSelectedParking(value);
              form.resetFields(["floor"]);
            }}
          />
        </Form.Item>
        <Form.Item name="floor" rules={[{ required: true }]} label={t("floor")}>
          <FloorTreeSelect />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

const FloorTreeSelect: React.FC<{ onChange?: () => void; value?: any }> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const { selectedParking } = useParkingCamera();

  const { data, isLoading } = useFloorRoot({
    enabled: !!selectedParking,
    parkingId: selectedParking?.id,
  });

  const tranformTree = (dataSource: IFloorRoot[]): DefaultOptionType[] => {
    return dataSource.map((item) => {
      let children: DefaultOptionType[] = [];
      if (item.children && item.children.length) {
        children = tranformTree(item.children);
      }
      return {
        label: item.floorName,
        value: item.id,
        children,
      };
    });
  };

  const treeData = tranformTree(data || []);

  return (
    <CTreeSelect
      loading={isLoading}
      disabled={!selectedParking}
      placeholder={t("floor")}
      treeData={treeData}
      onChange={onChange}
      value={value}
    />
  );
};

export default VehicleParkingCameraHeader;
