import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IReportVehicleInspection,
  IReportVehicleInspectionParams,
  IVehicleCondition,
  IVehicleConditionParams,
} from "./interface";

export const useVehicleConditionsScroll = (
  params?: IVehicleConditionParams
): UseInfiniteQueryResult<IResponse<IVehicleCondition[]>, Error> => {
  return useInfiniteQuery(
    ["get-vehicle-conditions", params],
    async ({ pageParam }) => {
      const res = await axios.get("/vehicle-condition", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};

export const useGetVehicleConditions = (
  params?: IVehicleConditionParams
): UseQueryResult<IResponse<IVehicleCondition[]>> => {
  return useQuery(
    ["get-vehicle-conditions", params],
    async () => {
      const res = await axios.get("/vehicle-condition", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled, keepPreviousData: true }
  );
};

export const useGetVehicleCondition = (
  id?: string | number
): UseQueryResult<IVehicleCondition> => {
  return useQuery(
    ["get-vehicle-condition", id],
    async () => {
      const res = await axios.get(`/vehicle-condition/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePatchVehicleCondition = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/vehicle-condition/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetVehicleConditionsFailed = (
  params?: IVehicleConditionParams
): UseQueryResult<IResponse<IVehicleCondition[]>> => {
  return useQuery(
    ["get-vehicle-conditions", params],
    async () => {
      const res = await axios.get("/vehicle-condition/failed-inspection", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled, keepPreviousData: true }
  );
};

export const useExportVehicleConditionsFailed = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/vehicle/conditions-report-export-excel`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

//report vehicle inspection
export const useExportReportVehicleCheck = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/report-vehicle/check-quick-lab`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
export const useExportReportVehicleSpray = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/report-vehicle/check-the-spray`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportReportVehicleCondition = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/report-vehicle/vehicle-condition`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetReportVehicleInspections = (
  params?: IReportVehicleInspectionParams
): UseQueryResult<IResponse<IReportVehicleInspection[]>> => {
  return useQuery(
    ["get-vehicle-inspections", params],
    async () => {
      const res = await axios.get("/report/report-vehicle-queue", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled, keepPreviousData: true }
  );
};
