import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";
import vehicle from "components/data/vehicle.json";

const CSelectVehicleBrand: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  const data = vehicle.Results;

  return (
    <CSelect
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.value ?? "").includes(input.toUpperCase())
      }
      placeholder={t("vehicle-brand")}
      {...props}
      valueOptions={{
        values: data,
        titleKey: "Make_Name",
        valueKey: "Make_Name",
      }}
   
    />
  );
};

export default CSelectVehicleBrand;
