import { Col, Row, Typography } from "antd";
import CInfiniteScroll from "components/display/c-infinite-scroll";
import Container from "components/display/container";
import { useVehicleInspection } from "provider/vehicle-inspection";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useVehicleConditionsScroll } from "services/vehicle-condition";
import {
  InspectionTypes,
  IVehicleCondition,
} from "services/vehicle-condition/interface";
import styled from "styled-components";
import tw from "twin.macro";
import InspectionDescriptionCard from "./description-card";
import InspectionDescriptionDrawer from "./description-drawer";

const InspectionBoardCardWrapper = styled.div`
  ${tw`mr-4 rounded-app p-3`};
  width: 410px;
  height: calc(100vh - 274px);
  background-color: #e6e6e6;
  display: inline-block;
  //   overflow-y: auto;
`;

const ListCountWrapper = styled.div`
  ${tw`rounded-app py-1`};
  width: 80px;
  color: white;
  font-size: 17px;
  text-align: center;
`;

interface InspectionBoardCardProps {
  title: string;
  menuKey: InspectionTypes;
}

const colorList: { [K in InspectionTypes]: string } = {
  WAITING_FOR_INSPECT: "#88A5CE",
  PASSED_THE_INSPECTION: "#2F9A5D",
  FAILED_THE_1ST_INSPECTION: "#DB1212",
  FAILED_THE_2ND_INSPECTION: "#DB1212",
  FAILED_THE_3RD_INSPECTION: "#DB1212",
  VIP: "#202A3C",
  FAILED_THE_INSPECTION: "#DB1212",
};

const InspectionBoardCard: React.FC<InspectionBoardCardProps> = ({
  menuKey,
  title,
}) => {
  const { t } = useTranslation();
  const { params } = useVehicleInspection();

  const [visible, setVisible] = useState<boolean>(false);
  const { setSelectedId } = useVehicleInspection();
  const toggle = () => {
    setVisible(!visible);
  };

  const scroll = useVehicleConditionsScroll({
    ...params,
    status: menuKey,
  });

  return (
    <InspectionBoardCardWrapper>
      <Container height="auto">
        <Row align="middle">
          <Col>
            <Typography.Title level={5}>{t(title)}</Typography.Title>
          </Col>
          <Col className="ml-auto">
            <ListCountWrapper style={{ backgroundColor: colorList[menuKey] }}>
              {scroll.data?.pages?.[0]?.count || 0} {t("list")}
            </ListCountWrapper>
          </Col>
        </Row>
      </Container>
      <InspectionDescriptionDrawer visible={visible} onClose={toggle} />
      <div
        className="mt-3"
        style={{ height: "calc(100% - 67px)", overflowY: "auto" }}
      >
        <CInfiniteScroll
          height="calc(100vh - 370px)"
          mutation={scroll}
          render={(data: IVehicleCondition[]) => {
            return data.map((item) => {
              return (
                <InspectionDescriptionCard
                  menuKey={menuKey}
                  key={item.id}
                  item={item}
                  onClick={() => {
                    toggle();
                    setSelectedId?.(item.id);
                  }}
                />
              );
            });
          }}
        />
      </div>
    </InspectionBoardCardWrapper>
  );
};

export default InspectionBoardCard;
