import Icon from "@ant-design/icons";
import { Row, Col, Typography } from "antd";
import { VideoNoData } from "assets/icons/general";
import CImage from "components/display/c-image";
import { useAppLanguage } from "provider/app-language";
import { useVehicleSocket } from "provider/vehicle-socket";
import React from "react";
import { useTranslation } from "react-i18next";
import { HeaderImage, HeaderText, IData } from "./entrance-display-screen";

const ImageProfileTruck = ({ data }: { data?: IData }) => {
  const { t } = useTranslation();
  const { selected } = useVehicleSocket();
  const { formatDate } = useAppLanguage();
  const createdAt = data?.person?.faceImages?.createdAt;
  return (
    <div className="relative h-[100%]">
      <HeaderImage></HeaderImage>
      <HeaderText>
        <Row className="px-6">
          <Col flex="auto">
            <span className="text-[18px] pr-3">
              {t("face-picture")} ({t("truck")})
            </span>
            <span>{selected?.vehicle?.markName || "-"}</span>
          </Col>
          <Col span={12} className="text-end !flex flex-col justify-center">
            {createdAt
              ? formatDate({
                  format: "nomal-full-time",
                  date: createdAt,
                })
              : ""}
          </Col>
        </Row>
      </HeaderText>
      <ImageDetact data={data} />
    </div>
  );
};

const ImageDetact = ({ data }: { data?: IData }) => {
  const { selected } = useVehicleSocket();
  const url = data?.person?.faceImages?.url;
  if (!data?.person?.faceImages || !data?.person?.faceImages?.url) {
    return <WaitingDetact />;
  }
  if (selected?.truckFace?.encodeDevice?.id !== data?.person?.encodeDevice?.id)
    return null;
  return (
    <React.Fragment>
      <CImage src={url} height="100%" width="100%" rounded={false} />
    </React.Fragment>
  );
};

const WaitingDetact = () => {
  const { t } = useTranslation();
  return (
    <div className="center h-full">
      <div className="center flex-col" style={{ height: "100%" }}>
        <Icon component={VideoNoData} style={{ fontSize: 100 }} />
        <Typography.Text
          className={`mt-4 text-center !text-white`}
          style={{ fontSize: 24 }}
        >
          {t("waiting-for-image-detection", { ns: "message" })}
        </Typography.Text>
      </div>
    </div>
  );
};

export default ImageProfileTruck;
