import { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generateCalendar from "antd/es/calendar/generateCalendar";
import styled from "styled-components";
import { FC } from "react";
import { CalendarProps } from "antd";
const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

const StyledCalendar = styled(Calendar)`
  .gAplIc {
    height: 100px;
  }
  .krcIut {
    height: 40px;
  }
  .anticon {
    font-size: 16px;
  }
  .ant-picker-content {
    font-size: 20px;
  }
`;

const CCalendar: FC<CalendarProps<Dayjs>> = (props) => {
  return <StyledCalendar {...props} />;
};

export default CCalendar;
