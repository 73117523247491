import { IResponse } from "config/axios/interface";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { IVehicleDetect, IVehicleDetectParams } from "./interface";
import { throwResponse } from "config/axios";

export const useGetVehicleDetects = (
  params?: IVehicleDetectParams
): UseQueryResult<IResponse<IVehicleDetect[]>> => {
  return useQuery(
    ["vehicle-detect", "get-all", params],
    async () => {
      const res = await axios.get("/vehicle-detect", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled, keepPreviousData: true }
  );
};

export const useGetVehicleDetect = (
  id?: string | number
): UseQueryResult<IVehicleDetect, Error> => {
  return useQuery(
    ["vehicle-detect", "get-one", id],
    async () => {
      const res = await axios.get(`/vehicle-detect/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePatchVehicleDetect = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/vehicle-detect/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportExcelVehicleDetect = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      "/export-excel/vehicle/summary-report-excel",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
