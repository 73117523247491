import { useQueryClient } from "@tanstack/react-query";
import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import { useAppLoading } from "provider/app-loading";
import { useAppRoleMenu } from "provider/app-role-menu";
import { useRole } from "provider/role";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppMenuType } from "router/interface";
import { useDeleteRole, useScrollGetRoles } from "services/role";
import { IRoleMenu } from "services/role-menu/interface";
import { IRole, IRoleParams } from "services/role/interface";
import RoleCreateAndEditDrawer from "./drawer";

const RoleSidebar = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IRoleParams>();

  const { selectedRole, setSelectedRole, visible, toggle } = useRole();
  const { translationObject } = useAppLanguage();
  const { baseMenus } = useAppRoleMenu();
  const { setAppLoading } = useAppLoading();

  const scroll_roles = useScrollGetRoles(params);
  const del_role = useDeleteRole();
  const qClient = useQueryClient();

  const onDelete = async () => {
    const confirm = await fireConfirmDelete();
    if (confirm) {
      setAppLoading(true);
      del_role.mutate(
        { id: selectedRole?.id },
        {
          onSuccess: () => {
            qClient.invalidateQueries(["get-roles"]);
            qClient.invalidateQueries(["get-role"]);
            fireNotification({ type: "success" });
            setSelectedRole(undefined);
          },
          onError: ({ message }) => {
            fireNotification({ type: "error", description: message });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    }
  };

  const sumIsActive = (
    roleMenus: IRoleMenu[],
    menus?: AppMenuType[]
  ): number => {
    return (
      menus?.reduce((r, c) => {
        const find = roleMenus.find((e) => e.menu?.code === c.code);
        const isActive = find?.isActive || false;
        if ("element" in c && !c.children && isActive) {
          return r + 1;
        }
        if (c.children && c.children.length) {
          return r + sumIsActive(roleMenus, c.children);
        }
        if (isActive) {
          return r + 1;
        }
        return r;
      }, 0) || 0
    );
  };

  const render = (data: any[]) => {
    return data?.map((item: IRole) => {
      const sum = sumIsActive(item.roleMenus, baseMenus);
      return (
        <CCard.SlashHeader
          active={item.id === selectedRole?.id}
          className="mb-4"
          title={translationObject({ object: item, keys: ["title"] }).title}
          key={item.id}
          onClick={() => setSelectedRole(item)}
        >
          <Row
            align="middle"
            className="px-3 py-2"
            style={{ height: 86 }}
            gutter={12}
          >
            <Col>
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t("menu-accessibility") }}
              >
                {t("menu-accessibility")} :
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text ellipsis={{ tooltip: `${sum} ${t("menu")}` }}>
                {`${sum} ${t("menu")}`}
              </Typography.Text>
            </Col>
            <Col span={24}></Col>
            <Col>
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t("status") }}
              >
                {t("status")} :
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text
                type={item.isActive ? "success" : "danger"}
                ellipsis={{
                  tooltip: t(item.isActive ? "active" : "in-active"),
                }}
              >
                {t(item.isActive ? "active" : "in-active")}
              </Typography.Text>
            </Col>
          </Row>
        </CCard.SlashHeader>
      );
    });
  };

  return (
    <React.Fragment>
      <RoleCreateAndEditDrawer visible={visible} onClose={toggle} />
      <InfiniteSidebar
        title={t("role-list")}
        actionButtonGroup={{
          onAdd: () => {
            toggle();
            setSelectedRole(undefined);
          },
          onDelete: onDelete,
          hide: { edit: true, del: !selectedRole },
        }}
        infiniteScroll={{
          mutation: scroll_roles,
          render: render,
          className: "px-5",
          search: {
            placeholder: t('role-name'),
            onChange: (e) => {
              setParams({ ...params, title: e });
            },
          },
        }}
      />
    </React.Fragment>
  );
};

export default RoleSidebar;
