import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import BlockColor from "components/display/card/block-color";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteContractor,
  useDeleteContractors,
  useExportTemplateContractor,
  useGetContractors,
  useImportExcelContractor,
  useImportProfileContractor,
} from "services/contractor";
import { IContractor, IContractorParams } from "services/contractor/interface";

const UserContractorPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IContractorParams>();

  const { translationObject } = useAppLanguage();

  const get_contractors = useGetContractors(params);
  const del_contractor = useDeleteContractor();
  const del_contractors = useDeleteContractors();
  const export_template = useExportTemplateContractor();
  const import_excel = useImportExcelContractor();
  const import_profile = useImportProfileContractor();

  const elements: ISearchBoxElement[] = [
    {
      name: "identityCode",
      label: t("contractor-id"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "companyName",
      label: t("affiliation"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "licensePlate",
      label: t("license-plate"),
      input: {
        type: "Input",
        options: {
          search: true,
          placeholder: t("license-plate"),
        },
      },
    },
    {
      name: "jobType",
      label: t("work-type"),
      input: {
        type: "SelectJobType",
      },
    },
    // {
    //   name: "acceptStatus",
    //   label: t("status"),
    //   input: {
    //     type: "SelectAcceptStatus",
    //   },
    // },
    {
      name: "identificationNumber",
      label: t("identification-card-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: ColumnsType<IContractor> = [
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url} />;
      },
    },
    {
      title: t("contractor-id"),
      align: "center",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.identityCode) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.identityCode }}>
            {rc?.identityCode}
          </Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      align: "center",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.firstName && !rc?.lastName) return "-";
        const name = `${rc?.firstName} ${rc?.lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
        );
      },
    },
    {
      title: t("affiliation"),
      align: "center",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.organizationCustomer?.organization?.name) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.organizationCustomer?.organization?.name }}>
            {rc?.organizationCustomer?.organization?.name}
          </Typography.Text>
        );
      },
    },
    {
      title: t("license-plate"),
      align: "center",
      dataIndex: "licenseplate",
      render: (_, rc) => {
        if (
          !rc.vehicleInfos ||
          (rc?.vehicleInfos && rc?.vehicleInfos.length <= 0)
        )
          return "-";

        const licensePlateNumber = (rc?.vehicleInfos || [])?.map(
          (e, index) =>
            `${e.licensePlateNumber}${index + 1 < (rc.vehicleInfos?.length || 0) ? " , " : " "
            }`
        );

        return (
          <Typography.Text ellipsis={{ tooltip: licensePlateNumber }}>
            {licensePlateNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("work-type"),
      align: "center",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const { contractorDetail } = rc || {};
        if (!rc?.contractorDetail?.jobType) return <span>-</span>;
        const { jobType } = translationObject({
          object: contractorDetail?.jobType,
          keys: ["jobType"],
        });
        return (
          <Typography.Text ellipsis={{ tooltip: jobType }}>
            {jobType}
          </Typography.Text>
        );
      },
    },
    // {
    //   title: t("identification-card-number"),
    //   align: "center",
    //   ellipsis: { showTitle: false },
    //   render: (_, rc) => {

    //     if (!rc?.identificationNumber) return <span>-</span>;
    //     return (
    //       <Typography.Text ellipsis={{ tooltip: rc?.identificationNumber }}>
    //         {rc?.identificationNumber}
    //       </Typography.Text>
    //     );
    //   },
    // },
    // {
    //   title: t("formation"),
    //   align: "center",
    //   ellipsis: { showTitle: false },
    //   render: (_, rc) => {
    //     if (!rc?.registerType) return <span>-</span>;
    //     return <BlockColor value={rc.registerType} type="registerType" />;
    //   },
    // },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values, page: 1 });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        rowKey="id"
        scroll={{ x: 1200 }}
        query={get_contractors}
        title={t("contractor-list")}
        columns={columns}
        onDelete={{
          mutation: del_contractor,
          invalidateQueries: ["get-contractors", "get-contractor"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        action={{
          bulkDelete: {
            mutation: del_contractors,
            invalidateQueries: ["get-contractors", "get-contractor"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
          excels: {
            template: {
              mutation: export_template,
              fileName: "contractor template",
            },
            import: {
              mutation: import_excel,
              invalidateQueries: ["get-contractors", "get-contractor"],
              typeUser: "contractor",
            },
            importProfile: {
              mutation: import_profile,
              invalidateQueries: ["get-contractors", "get-contractor"],
              typeUser: "contractor",
            },
          },
        }}
        onAdd="user-management.contractor-add"
        onRowClick="user-management.contractor-edit"
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default UserContractorPage;
