import { useQueryClient } from "@tanstack/react-query";
import { Col, Divider, Form, Row, Typography } from "antd";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import CUpload from "components/input/c-upload";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetUsersBlackList,
  usePatchUserBlackList,
  usePostUserBlackList,
} from "services/user-blacklist";

const UserBlacklistPersonCreateAndEditPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();
  const qClient = useQueryClient();
  const { dispatch } = usePageRoute();
  const { setAppLoading } = useAppLoading();

  const post_user_blacklist = usePostUserBlackList();
  const patch_user_blacklist = usePatchUserBlackList();
  const { data, isLoading } = useGetUsersBlackList(id);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      backlistPresonImage: data?.backlistPresonImage?.url,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const pageKey = getCurrentKey();

  const onSave = (values: any) => {
    if (id) {
      patch_user_blacklist.mutate(
        { id : id , ...values },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: "user-management" });
            qClient.invalidateQueries(["get-user-blacklists"]);
            qClient.invalidateQueries(["get-user-blacklist"]);
            dispatch({ action: "back" });
          },
          onError: ({ message }: any) => {
            fireNotification({
              type: "error",
              description: message,
              menu: "user-management",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    } else {
      post_user_blacklist.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "user-management" });
          qClient.invalidateQueries(["get-user-blacklists"]);
          qClient.invalidateQueries(["get-user-blacklist"]);
          dispatch({ action: "back" });
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "user-management",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      });
    }
  };

  const onSaveAndContinue = (values: any) => {
    post_user_blacklist.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success", menu: "user-management" });
        qClient.invalidateQueries(["get-user-blacklists"]);
        qClient.invalidateQueries(["get-user-blacklist"]);
        form?.resetFields();
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
          menu: "user-management",
        });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  return (
    <PageContent
      loading={isLoading && !!id}
      breadcrumb={{
        form,
        mutate: {
          onSave: onSave,
          onSaveAndContinue: id ? false : onSaveAndContinue,
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title level={5} className="!text-primary-dark">
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form
          layout="vertical"
          className="!p-5"
          form={form}
          initialValues={{ userType: "EMPLOYEE", productList: false }}
        >
          <Row gutter={24}>
            <Col span={6} className="!flex justify-center">
              <div>
                <Typography.Text className="!text-primary">
                  {t("basic-information")}
                </Typography.Text>
                <Form.Item className="!mt-3" name="backlistPresonImage">
                  <CUpload.Avatar />
                </Form.Item>
              </div>
            </Col>
            <Col span={18}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item
                    label={`${t("first-name")} `}
                    rules={[{ required: true }]}
                    name="firstName"
                  >
                    <CInput placeholder={`${t("first-name")} `} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={`${t("last-name")} `}
                    rules={[{ required: true }]}
                    name="lastName"
                  >
                    <CInput placeholder={`${t("last-name")} `} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("email")}
                    rules={[{ required: true }, { type: "email" }]}
                    name="email"
                  >
                    <CInput placeholder={t("email")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("phone")}
                    rules={[{ required: true }]}
                    name="phoneNumber"
                  >
                    <CInput placeholder={t("phone")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("identity-card-number")}
                    rules={[{ required: true }]}
                    name="identityCardNumber"
                  >
                    <CInput placeholder={t("identity-card-number")} />
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
                <Col span={24}>
                  <Form.Item label={t("address")} name="address">
                    <CInput.TextArea rows={5} placeholder={t("address")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t("note")} name="details">
                    <CInput.TextArea rows={5} placeholder={t("note")} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageContent>
  );
};

export default UserBlacklistPersonCreateAndEditPage;
