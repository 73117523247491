import { CaretRightFilled } from "@ant-design/icons";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { Col, Row, Spin, Typography } from "antd";
import CIndicator from "components/display/c-indicator";
import CInfiniteScroll from "components/display/c-infinite-scroll";
import { IResponse } from "config/axios/interface";
import numeral from "numeral";
import { useNotificationHeader } from "provider/notification-header";
import { MenuNot, TMenu } from "provider/notification-header/interface";
import React from "react";
import { useTranslation } from "react-i18next";
import { INotificationHeader } from "services/notification-header/interface";
import styled from "styled-components";
import tw from "twin.macro";
import { SubCard } from "./sub-card-notification";

const MainContainer = styled.div`
  ${tw`rounded-app`}
  width: 350px;
  overflow: hidden;
  display: inline-block;
  background-color: white;
  height: 350px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
`;

const SubContainer = styled.div<{ show?: number }>`
  ${tw`rounded-app`}
  width: 350px;
  opacity: ${({ show }) => (show ? "1" : "0")};
  overflow-x: hidden;
  overflow-y: ${({ show }) => (show ? "auto" : "hidden")};
  display: inline-block;
  transition: all 0.3s ease-out;
  height: ${({ show }) => (show ? "350px" : "0px")};
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
`;

const NotificationPanel = () => {
  const { selectMenu, mapData, getLoadingNotRead } = useNotificationHeader();
  const mapDataManu = mapData();

  return (
    <div className="flex">
      <Spin
        className="center !bg-[white] h-full rounded-app"
        spinning={getLoadingNotRead}
        indicator={<CIndicator height={50} className="rounded-app" />}
      >
        <MainContainer className="mr-1" id="height">
          {mapDataManu
            .filter((e) => e.isActive)
            .map((item) => {
              return <MainCard item={item} key={item.id} />;
            })}
        </MainContainer>
      </Spin>
      <SubContainer show={!!selectMenu ? 1 : 0}>
        <SubCardContainer />
      </SubContainer>
    </div>
  );
};

const MainCardContainer = styled.div<{ active?: number }>`
  &:before {
    content: " ";
    border-left: ${({ active }) => (active ? "4px solid red" : "")};
    position: absolute;
    left: 0;
    top: 2px;
    bottom: 2px;
  }
  background-color: ${({ active }) => (active ? "#FBFBFB" : "white")};
  position: relative;
  cursor: pointer;
  height: 50px;
  &:hover {
    background-color: #fbfbfb;
    &:before {
      content: " ";
      border-left: 4px solid red;
      position: absolute;
      left: 0;
      top: 2px;
      bottom: 2px;
    }
  }
`;
const ArrowContainer = styled(CaretRightFilled)<{ active?: number }>`
  transition: all 0.2s ease-out;
  transform: ${({ active }) => (active ? `rotate(180deg)` : "")};
`;

const MainCard = ({ item }: { item: MenuNot }) => {
  const { t } = useTranslation();
  const { selectMenu, setSelectMenu } = useNotificationHeader();

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (item.keyNoti === selectMenu) {
      return setSelectMenu?.(undefined);
    }
    setSelectMenu?.(item.keyNoti);
  };

  return (
    <MainCardContainer
      active={selectMenu === item.keyNoti ? 1 : 0}
      className="px-3"
      onClick={onClick}
    >
      <Row align="middle" gutter={12} className="h-full">
        <Col span={18}>
          <Typography.Text ellipsis={{ tooltip: t(item.lable) }}>
            {t(item.lable)}
          </Typography.Text>
        </Col>
        <Col span={4}>
          <div
            style={{
              backgroundColor: item.notRead === 0 ? "#E9E9E9" : "#f82602",
              color: item.notRead === 0 ? "" : "#ffffff",
            }}
            className="center"
          >
            {numeral(item.notRead).format("0,0")}
          </div>
        </Col>
        <Col span={2} className="center">
          <ArrowContainer
            style={{ fontSize: 12 }}
            active={selectMenu === item.keyNoti ? 1 : 0}
          />
        </Col>
      </Row>
    </MainCardContainer>
  );
};

// Card Menu Notification
const SubCardContainer = () => {
  const { selectMenu, queryData } = useNotificationHeader();
  const findDataMenu: {
    [K in TMenu]?: UseInfiniteQueryResult<
      IResponse<INotificationHeader[]>,
      Error
    >;
  } = {
    FAILED_FACE_SCAN: queryData.queryFailedFaceScan,
    FAILED_THE_3RD_OF_VEHICLE_CONDITION: queryData.queryFailed3rdVehicle,
    LICENSE_PLATE_DOES_NOT_PASS: queryData.queryLicensePlateNotPass,
    NO_MASK: queryData.queryNoMask,
    SCAN_THE_FACE_THROUGH: queryData.queryPassFaceScan,
    TEMPERATURE_DOES_NOT_PASS: queryData.queryTemperatureNotPass,
    VIP_REJECT_CAR: queryData.queryVIPRejectCar,
  };
  const query = selectMenu ? findDataMenu[selectMenu] : undefined;
  return (
    <React.Fragment>
      <CInfiniteScroll
        mutation={query}
        height={"350px"}
        backgroundColor="white"
        className="rounded-app"
        render={(data: INotificationHeader[]) => {
          return (data || [])
            .sort((a, b) => b.id - a.id)
            .map((item, index) => {
              return <SubCard item={item} key={index} />;
            });
        }}
      />
    </React.Fragment>
  );
};

export default NotificationPanel;
