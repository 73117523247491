import { DeleteFilled, EditFilled, MoreOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Badge, Col, Dropdown, Menu, Popover, Row, Typography } from "antd";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import { useAppLoading } from "provider/app-loading";
import { useSettingSchedule } from "provider/setting-schedule";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteSchedule } from "services/setting-schedule";
import { ISchedule } from "services/setting-schedule/interface";
import styled from "styled-components";

interface ScheduleDescriptionCardProps {
  schedules: ISchedule[];
  onEdit: (v: ISchedule) => void;
}

const ScheduleDescriptionCardWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  text-align: start;
  background-color: #eaf7ec;
  height: 30px;
  &:before {
    content: " ";
    border-left: 3px solid #438860;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
  }
`;

const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  top: 9px;
  right: 6px;
  // font-size: 12px;
  cursor: pointer;
`;

const ScheduleDescriptionCard: FC<ScheduleDescriptionCardProps> = ({
  schedules,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const { setAppLoading } = useAppLoading();

  const delete_schedule = useDeleteSchedule();
  const qClient = useQueryClient();

  const { setAction } = useSettingSchedule();

  if (schedules.length <= 0) return null;

  const onDelete = async (id: number) => {
    const confirm = await fireConfirmDelete();
    if (!confirm) return;
    delete_schedule.mutate(
      { id },
      {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-schedules"]);
          qClient.invalidateQueries(["get-schedule"]);
        },
        onError: ({ message }) => {
          fireNotification({ type: "error", message });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  if (schedules.length === 1) {
    const menu = (
      <Menu
        items={[
          {
            key: "edit",
            label: t("edit"),
            onClick: () => {
              onEdit(schedules[0]);
              setAction?.("edit");
            },
          },
          {
            key: "delete",
            label: t("delete"),
            onClick: () => onDelete(schedules[0].id),
          },
        ]}
      />
    );

    return (
      <ScheduleDescriptionCardWrapper
        className="px-3 flex justify-center flex-col cursor-default"
        onClick={(e) => e.stopPropagation()}
      >
        <Typography.Title style={{ fontSize: 14 }}>
          {schedules[0].scheduleName}
        </Typography.Title>
        <Typography.Title style={{ fontSize: 12 }}>
          {formatDate({ date: schedules[0].startDate, format: "time" })} -{" "}
          {formatDate({ date: schedules[0].endDate, format: "time" })}
        </Typography.Title>
        <Dropdown overlay={menu}>
          <MoreIcon className="!text-[20px]" />
        </Dropdown>
      </ScheduleDescriptionCardWrapper>
    );
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover
        showArrow={false}
        trigger={["hover"]}
        content={
          <PopoverContent
            onEdit={onEdit}
            schedules={schedules}
            onDelete={onDelete}
          />
        }
        overlayInnerStyle={{ width: 300 }}
        transitionName=""
        getPopupContainer={(e) => e}
        zIndex={10}
      >
        <ScheduleDescriptionCardWrapper
          className="px-3"
          onClick={(e) => e.stopPropagation()}
        >
          <Row justify="space-between" className="h-full" align="middle">
            <Col>
              <Typography.Text style={{ fontSize: 12 }}>
                {t("number-of-activities")}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text
                style={{
                  fontSize: 12,
                  border: "2px solid #eb144c",
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 3,
                  paddingRight: 3,
                  backgroundColor: "#ef2e55",
                  color: "#FFFFFF",
                }}
              >
                {schedules.length}
              </Typography.Text>
            </Col>
          </Row>
        </ScheduleDescriptionCardWrapper>
      </Popover>
    </div>
  );
};

const PopoverContent: FC<{
  schedules: ISchedule[];
  onDelete: (v: number) => void;
  onEdit: (v: ISchedule) => void;
}> = ({ schedules, onDelete, onEdit }) => {
  const { formatDate } = useAppLanguage();
  const { setAction } = useSettingSchedule();
  return (
    <Row gutter={[12, 6]} align="middle">
      {schedules.map((item) => {
        const date = `${formatDate({
          date: item.startDate,
          format: "time",
        })} - ${formatDate({ date: item.endDate, format: "time" })}`;
        return (
          <React.Fragment key={item.id}>
            <Col span={2}>
              <Badge color="geekblue" />
            </Col>
            <Col span={8}>
              <Typography.Text ellipsis={{ tooltip: item.scheduleName }}>
                {item.scheduleName}
              </Typography.Text>
            </Col>
            <Col span={10}>
              <Typography.Text ellipsis={{ tooltip: date }}>
                {date}
              </Typography.Text>
            </Col>
            <Col className="ml-auto center" span={2}>
              <EditFilled
                onClick={() => {
                  onEdit(item);
                  setAction?.("edit");
                }}
                className="cursor-pointer"
                style={{ fontSize: 18 }}
              />
            </Col>
            <Col span={2} className="center">
              <DeleteFilled
                className="cursor-pointer"
                style={{ fontSize: 18 }}
                onClick={() => onDelete(item.id)}
              />
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default ScheduleDescriptionCard;
