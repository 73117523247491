import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { postMediaObject } from "services/media-object";
import { IMediaObject } from "services/media-object/interface";
import {
  IAccessibilityPerson,
  IAccessibilityPersonParams,
  IContractor,
  IContractorParams,
} from "./interface";


export const useGetContractors = (
  params?: IContractorParams
): UseQueryResult<IResponse<IContractor[]>> => {
  return useQuery(
    ["get-contractors", params],
    async () => {
      const res = await axios.get("/contractor", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetContractor = (
  id?: string | number
): UseQueryResult<IContractor> => {
  return useQuery(
    ["get-contractor", id],
    async () => {
      const res = await axios.get(`/contractor/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostContractor = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    // const resOrganization = await axios.get(`/organization?pagination=false`);
    // let organizationId = 0;
    // for (var i=0; i < resOrganization.data.data.length; i++) {
    //   if(resOrganization.data.data[i].name == data.companyName) {
    //     organizationId = resOrganization.data.data[i].id
    //   }
    // }
    // data.organizationId = organizationId;
    data = await appendImages(data);
    const res = await axios.post("/contractor", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchContractor = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    // const resOrganization = await axios.get(`/organization?pagination=false`);
    // let organizationId = 0;
    // for (var i=0; i < resOrganization.data.data.length; i++) {
    //   if(resOrganization.data.data[i].name == data.companyName) {
    //     organizationId = resOrganization.data.data[i].id
    //   }
    // }
    // data.organizationId = organizationId;
    data = await appendImages(data);
    const res = await axios.patch(`/contractor/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteContractor = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/contractor/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteContractors = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.delete(`/contractor/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

const appendImages = async (values: any): Promise<any> => {
  let {
    profile,
    contractorImages,
    identityCardPicture,
    vehicles,
    profiles,
    ...rest
  } = values || {};

  if (profile && typeof profile === "object") {
    profile = await postMediaObject(profile);
    rest = { ...rest, profile };
  }

  if (contractorImages?.length < 1) {
    rest = { ...rest, contractorImages: [] };
  }

  if (contractorImages && contractorImages?.length > 0) {
    const itemPicturesList: number[] = await Promise.all(
      contractorImages.map(async (item: any): Promise<number> => {
        let id: number;
        if (!item.id) {
          id = await postMediaObject(item);
        } else {
          id = item.id;
        }
        return id;
      })
    );
    rest = { ...rest, contractorImages: itemPicturesList };
  }

  if (identityCardPicture && typeof identityCardPicture === "object") {
    identityCardPicture = await postMediaObject(identityCardPicture);
    rest = { ...rest, identityCardPicture };
  }

  if (vehicles) {
    vehicles = await Promise.all(
      vehicles.map(async (item: any) => {
        if (item.vehicleImage) {
          const vehicleImageData = await Promise.all(
            item.vehicleImage.map(async (e: File | IMediaObject) => {
              if ("id" in e) return e.id;
              const id = await postMediaObject(e);
              return id;
            })
          );
          return { ...item, vehicleImage: vehicleImageData };
        }
        return { ...item, vehicleImage: [] };
      })
    );
    rest = { ...rest, vehicles };
  }

  if (profiles?.length < 1) {
    rest = { ...rest, profiles: [] };
  }

  if (profiles && profiles?.length > 0) {
    const itemPicturesList: number[] = await Promise.all(
      profiles.map(async (item: any): Promise<number> => {
        let id: number;
        if (!item.id) {
          id = await postMediaObject(item);
        } else {
          id = item.id;
        }
        return id;
      })
    );
    rest = { ...rest, profiles: itemPicturesList };
  }

  return rest;
};

// contractor access control
export const usePostAccessibilyPerson = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/accessibility/add-access-to-person", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetAccessibilyInPerson = (
  params?: IAccessibilityPersonParams
): UseQueryResult<IAccessibilityPerson> => {
  return useQuery(
    ["get-accessibility-person", params],
    async () => {
      const res = await axios.get("/accessibility/in-person", {
        params,
      });
      if (res.status === 200) {
        const accessibilityUsers = res.data.data.accessibilityUsers?.map(
          (e: any) => e.accessibility
        );
        return { ...res.data.data, accessibilityUsers };
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useDeleteAccessibilyInPerson = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.patch(
      `/accessibility/remove-access-in-person`,
      data
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportTemplateContractor = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/template/contractor`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportExcelContractor = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/contractor/import/contractor`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportProfileContractor = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    data = await appendImages(data);
    const res = await axios.post("/contractor/upload-profile", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
