import { useQueryClient } from "@tanstack/react-query";
import { Divider, Form, FormInstance, Input, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import CCard from "components/display/card";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import { useAccessibilityDeterminedByGroup } from "provider/accessibility-determined-by-group";
import { useAppLanguage } from "provider/app-language";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteListSupplier,
  useDeleteListSuppliers,
  useGetListSupplier,
  usePatchListSupplierId,
  usePostListSupplier,
} from "services/setting-list-supplier";
import {
  IListSupplier,
  IListSupplierParams,
} from "services/setting-list-supplier/interface";
import { useGetSupplierTypes } from "services/supplier-type";

const AccessibilityDeterminedByGroupContent = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const { translationObject } = useAppLanguage();
  const { group } = useAccessibilityDeterminedByGroup();
  const [params, setParams] = useState<IListSupplierParams>({
    page: 1,
    limit: 10,
  });

  const del_list_supplier = useDeleteListSupplier();
  const del_list_suppliers = useDeleteListSuppliers();
  const get_list_suppliers = useGetListSupplier(params);
  const get_SupplierType = useGetSupplierTypes(group);

  useEffect(() => {
    setParams({ ...params, supplierId: group });
  }, [group]);

  const typeid = get_SupplierType.data?.data.filter((e) => e.id === group)[0];

  if (!group) {
    return (
      <div style={{ height: "calc(100vh - 218px)" }} className="center">
        {t("please-select-a-supplier-type")}
      </div>
    );
  }

  const toggle = () => {
    setVisible(!visible);
  };

  const { title } = translationObject({ object: typeid, keys: ["title"] });

  const elements: ISearchBoxElement[] = [
    {
      name: "title",
      label: title,
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "isActive",
      label: t("status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              { key: true, value: t("active") },
              { key: false, value: t("in-active") },
            ],
            valueKey: "key",
            titleKey: "value",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<IListSupplier> = [
    {
      title: title,
      render: (_, rc) => {
        const { title } = translationObject({ object: rc, keys: ["title"] });
        return (
          <Typography.Text ellipsis={{ tooltip: title }}>
            {title}
          </Typography.Text>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      width: "20%",
      render: (status: true | false) => {
        return (
          <CCard.ActiveInActive
            status={status === true ? "active" : "in-active"}
          />
        );
      },
    },
  ];

  const onRowClick = (values: any) => {
    form.setFieldsValue(values);
    toggle();
  };

  const onSearch = (FormItem: any) => {
    setParams({
      ...params,
      ...FormItem,
    });
  };

  return (
    <Container scrollY height="calc(100vh - 218px)">
      <CreateAndEditDrawer visible={visible} onClose={toggle} form={form} />
      <PageSearchBox
        shadow={false}
        colSpan={8}
        onFinish={onSearch}
        elements={elements}
      />
      <Divider className="!m-0" />
      <CTable
        onAdd={{
          onClick: toggle,
        }}
        onRowClick={onRowClick}
        action={{
          bulkDelete: {
            mutation: del_list_suppliers,
            invalidateQueries: ["get-list-suppliers", "get-supplier-types"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        shadow={false}
        onDelete={{
          mutation: del_list_supplier,
          invalidateQueries: ["get-list-suppliers", "get-supplier-types"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        query={get_list_suppliers}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        title={title}
        wrapperClassName="!mt-0"
        columns={columns}
      />
    </Container>
  );
};

const CreateAndEditDrawer = ({
  visible,
  onClose,
  form,
}: {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
}) => {
  const { t } = useTranslation();
  const post_ListSupplier = usePostListSupplier();
  const patch_ListSupplier = usePatchListSupplierId();
  const { group } = useAccessibilityDeterminedByGroup();
  const qClient = useQueryClient();

  const onFinish = (values: any) => {
    const { id } = values;

    if (values.isActive === undefined) values.isActive = true;
    if (id) {
      return patch_ListSupplier.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-list-suppliers"]);
          onClose();
          form.resetFields();
        },
        onError: ({ meesage }: any) => {
          fireNotification({ type: "error", description: meesage });
        },
      });
    }

    return post_ListSupplier.mutate(
      { values: values, id: group },

      {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-list-suppliers"]);
          onClose();
          form.resetFields();
          qClient.invalidateQueries(["get-supplier-types"]);
        },
        onError: ({ meesage }: any) => {
          fireNotification({ type: "error", description: meesage });
        },
      }
    );
  };

  const supplierTh = (
    <>
      {group === 2 ? (
        <span>
          {t("transport-of-product")}
          <span className="text-red-500"> ({t("language-th")})</span>
        </span>
      ) : (
        <span>
          {t("product-name-materials")}
          <span className="text-red-500"> ({t("language-th")})</span>
        </span>
      )}
    </>
  );

  const supplierEn = (
    <>
      {group === 2 ? (
        <span>
          {t("transport-of-product")}
          <span className="text-red-500"> ({t("language-en")})</span>
        </span>
      ) : (
        <span>
          {t("product-name-materials")}
          <span className="text-red-500"> ({t("language-en")})</span>
        </span>
      )}
    </>
  );

  const titleForm = form.getFieldValue("id")
    ? t("edit-products-materials")
    : t("add-products-materials");

  return (
    <CDrawer
      form={form}
      title={titleForm}
      placement="right"
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      size="large"
      visible={visible}
      width="40vw"
      onSave={onFinish}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="titleTh"
          label={supplierTh}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput
            placeholder={`${t("product-name-materials")} (${t("language-th")})`}
          />
        </Form.Item>
        <Form.Item
          name="titleEn"
          label={supplierEn}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput
            placeholder={`${t("product-name-materials")} (${t("language-en")})`}
          />
        </Form.Item>
        <Form.Item label={t("status")} name="isActive">
          <CSelect
            placeholder={t("status")}
            defaultValue={true}
            valueOptions={{
              values: [
                { id: true, title: t("active") },
                { id: false, title: t("in-active") },
              ],
              valueKey: "id",
              titleKey: "title",
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default AccessibilityDeterminedByGroupContent;
