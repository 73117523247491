import withDoorRealtime from "provider/door-realtime";
import DoorListPage from "./door-header";
import RealtimeDoor from "./realtime-door";

const RealtimeDoorMonitoringPage = () => {
  return (
    <div className="p-5">
      <DoorListPage />
      <RealtimeDoor/>
    </div>
  );
};

export default withDoorRealtime(RealtimeDoorMonitoringPage);
