import { PieChartFilled } from "@ant-design/icons/lib/icons";
import { Col, Row, Typography } from "antd";
import { MenuSchedule } from "assets/icons/general";
import { useAppLanguage } from "provider/app-language";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

type ColorType = "deepGreen" | "green" | "yellow" | "red" | "blue";

const CardStyle = styled.div`
  ${tw`rounded-app !h-full`}
  background-color: #fff;
`;

export const IconHomeStlye = styled(Row)<{ $color: ColorType }>`
  background-color: ${({ $color }) =>
    $color === "deepGreen"
      ? "#EAF7EC"
      : $color === "green"
      ? "#EAF7EC"
      : $color === "yellow"
      ? "#FFF6E0"
      : $color === "red"
      ? "#FFE6E6"
      : "#EAF4F7"};
  border-radius: 10px;
  width: 35px;
  height: 35px;
`;

const CardHomeList = ({
  title,
  value,
  valueType,
  startDate,
  endDate,
  theme = "deepGreen",
}: {
  title: string;
  theme: ColorType;
  value?: number | string;
  valueType?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  return (
    <CardStyle className="p-5">
      <Row className="h-full">
        <Col span={3} className="!flex !items-center">
          <IconHomeStlye justify="center" align="middle" $color={theme}>
            <PieChartFilled
              style={{
                fontSize: "18px",
                color:
                  theme === "deepGreen"
                    ? "#425F4E"
                    : theme === "green"
                    ? "#438860"
                    : theme === "yellow"
                    ? "#BF9C3D"
                    : theme === "red"
                    ? "#DB1212"
                    : "#436088",
              }}
            />
          </IconHomeStlye>
        </Col>
        <Col span={21} className="!flex !items-center">
          <Typography.Text
            ellipsis
            className="font-bold px-2"
            style={{ fontSize: "20px" }}
          >
            {t(title || "")}
          </Typography.Text>
        </Col>
        <Col span={8} className="py-2">
          <Typography.Title
            level={2}
            ellipsis
            style={{
              color:
                theme === "deepGreen"
                  ? "#425F4E"
                  : theme === "green"
                  ? "#438860"
                  : theme === "yellow"
                  ? "#BF9C3D"
                  : theme === "red"
                  ? "#DB1212"
                  : "#436088",
            }}
          >
            {Number(value || 0).toLocaleString()}
          </Typography.Title>
        </Col>
        <Col span={16} className="!flex !items-center px-2">
          <Typography.Text ellipsis>{t(valueType || "")}</Typography.Text>
        </Col>
        <Col className="center">
          <MenuSchedule className="w-[22px] h-[22px]" />
        </Col>
        <Col className="px-2 center">
          <span style={{ color: "#9A9A9A", fontSize: "18px" }}>
            {`${formatDate({
              date: startDate,
              format: "normal",
            })} - ${formatDate({ date: endDate, format: "normal" })}`}
          </span>
        </Col>
      </Row>
    </CardStyle>
  );
};

export default CardHomeList;
