import { Col, Row } from "antd";
import CheckSpraySocketProvider from "provider/check-spray-socket";
import withSprayCheckProvider from "provider/inspection-spray-check";
import SprayCheckContent from "./content";
import SprayCheckSidebar from "./sidebar";

const VehicleInspectionSprayCheckPage = () => {
  return (
    <CheckSpraySocketProvider>
      <Row>
        <Col span={6}>
          <SprayCheckSidebar />
        </Col>
        <Col span={18} className="p-5" style={{ height:"calc(100vh - 120px)", overflowY: "auto" }}>
          <SprayCheckContent />
        </Col>
      </Row>
    </CheckSpraySocketProvider>
  );
};

export default withSprayCheckProvider(VehicleInspectionSprayCheckPage);
