import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import withAccessibilityDeterminedByGroup, {
  useAccessibilityDeterminedByGroup,
} from "provider/accessibility-determined-by-group";
import { useAppLanguage } from "provider/app-language";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetSupplierTypes } from "services/supplier-type";
import AccessibilityDeterminedByGroupContent from "./content";

const Supplierpage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <Sidebar />
        </Col>
        <Col span={18} className="p-5">
          <AccessibilityDeterminedByGroupContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();

  const { group, setGroup } = useAccessibilityDeterminedByGroup();
  const get_SupplierType = useGetSupplierTypes(group);
  const { translationObject } = useAppLanguage();

  const content = get_SupplierType.data?.data
    ?.sort((a, b) => (a.id < b.id ? -1 : 0))
    .map((item, index) => {
      const lengthListSupplier =
        get_SupplierType.data?.data[index].lengthListSupplier;
      const { title } = translationObject({ object: item, keys: ["title"] });
      return (
        <CCard.SlashHeader
          active={group === item.id}
          className="mb-4"
          title={title}
          key={item.id}
          onClick={() => {
            setGroup(item.id);
          }}
        >
          <Row
            align="middle"
            className="px-3 py-2"
            style={{ height: 86 }}
            gutter={12}
          >
            <Col>
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t("list") }}
              >
                {t("set-by-group-of-person")} :
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>{`${lengthListSupplier} ${t(
                "list"
              )}`}</Typography.Text>
            </Col>
          </Row>
        </CCard.SlashHeader>
      );
    });

  return (
    <React.Fragment>
      <InfiniteSidebar title={t("job-type-supplier")} content={content} />
    </React.Fragment>
  );
};

export default withAccessibilityDeterminedByGroup(Supplierpage);
