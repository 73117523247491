import { useQueryClient } from "@tanstack/react-query";
import { Col, Form, Row, Typography } from "antd";
import CDrawer from "components/display/c-drawer";
import CCard from "components/display/card";
import CInput from "components/input/c-input";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import withAccessibilityDeterminedGroupOfPersons, {
  useAccessibilityDeterminedGroupOfPersons,
} from "provider/accessibility-determined-by-group-persons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteUserGroup,
  useGetUserGroup,
  useGetUserGroups,
  usePatchUserGroup,
  usePostUserGroup,
} from "services/user-group";
import { IUserGroup, IUserGroupParams } from "services/user-group/interface";
import GroupContent from "./content";

const GroupOfPersons = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <GroupSidebar />
        </Col>
        <Col span={18} className="p-5" style={{ height:"calc(100vh - 200px)", overflowY: "auto" }}>
          <GroupContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const GroupSidebar = () => {
  const { t } = useTranslation();
  const qClient = useQueryClient();
  const { userGroup, setUserGroup  , visible, toggle, setAction,  } =
    useAccessibilityDeterminedGroupOfPersons();

  const [params, setParams] = useState<IUserGroupParams>({page:1,limit:10});
  const { data } = useGetUserGroups(params)
  const delete_user_group  = useDeleteUserGroup();;

  const render = () => {
    return data?.data.map((item: IUserGroup) => {
      return (
        
        <CCard.BasicCard
          active={userGroup === item}
          className="mb-4"
          key={item?.id}
          onClick={() => {
            setUserGroup(item);
          }}
        >
          <Row align="middle" className="px-3 py-4 " gutter={12}>
            <Col>
              <Typography.Text>{`${item.title}`}</Typography.Text>
            </Col>
          </Row>
        </CCard.BasicCard>
      );
    });
  };

  const onDelete = async () => {
    const confirm = await fireConfirmDelete();
    if (confirm) {
      delete_user_group.mutate(
        { id: userGroup?.id },
        {
          onSuccess: () => {
            qClient.invalidateQueries(["get-user-groups"]);
            qClient.invalidateQueries(["get-user-group"]);
            fireNotification({ type: "success" });
            setUserGroup(undefined);
          },
          onError: ({ message }: any) => {
            fireNotification({ type: "error", description: message });
          },
        }
      );
    }
  };

  return (
    <React.Fragment>
      <DrawerUserGroup visible={visible} toggle={toggle} />
      <InfiniteSidebar
        title={t("group-of-persons-list")}
        actionButtonGroup={{
          onAdd: () => {
            setAction?.("add");
            toggle();
            setUserGroup(undefined);
          },
          onEdit: () => {
            setAction?.("edit");
            toggle();
          },
          onDelete: onDelete,
          hide: { edit: !userGroup, del: !userGroup },
        }}
        infiniteScroll={{
          render: render,
          className: "px-5",
          search: {
            onChange: (e) => {
              setParams({ ...params, search: e });
            },
          },
        }}
      />
    </React.Fragment>
  );
};

const DrawerUserGroup = ({
  visible,
  toggle,
}: {
  visible: boolean;
  toggle: () => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { userGroup, action } = useAccessibilityDeterminedGroupOfPersons();
  const id = userGroup?.id;
  const slug = userGroup?.slug;
  const qClient = useQueryClient();

  const {data} = useGetUserGroup(id , slug)
  const post_user_group = usePostUserGroup();
  const patch_user_group = usePatchUserGroup();

  useEffect(() => {
    initail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data,visible]);

  const initail = () => {
    if (action === "add") {
      form.resetFields();
    }
    if (data && action === "edit") {
      form.setFieldsValue({
        ...data,
      });
    }
  };

  const onSave = (values: any) => {
    if (id) {
      patch_user_group.mutate(
        { id: userGroup?.id, ...values },
        {
          onSuccess: () => {
            fireNotification({ type: "success" , menu : "user-management"});
            qClient.invalidateQueries(["get-user-groups"]);
            qClient.invalidateQueries(["get-user-group"]);

            toggle();
          },
          onError: ({ message }: any) => {
            fireNotification({ type: "error", description: message });
          },
        }
      );
    } else {
      post_user_group.mutate(
        { title: values.title },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu : "user-management" });
            qClient.invalidateQueries(["get-user-groups"]);
            qClient.invalidateQueries(["get-user-group"]);
            toggle();
          },
          onError: ({ message }: any) => {
            fireNotification({ type: "error", description: message });
          },
        }
      );
    }
  };

  const onClose = () => {
    toggle();
  };

  const title = action === "add" ? "add-group" : "edit-group";
  const loading = patch_user_group.isLoading || post_user_group.isLoading;

  return (
    <CDrawer
      width="40vw"
      title={t(title)}
      form={form}
      visible={visible}
      onCancel={onClose}
      onClose={onClose}
      onSave={onSave}
      loading={loading}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("group-name")}
              name="title"
              rules={[{ required: true }]}
            >
              <CInput />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CDrawer>
  );
};

export default withAccessibilityDeterminedGroupOfPersons(GroupOfPersons);
