import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IEmployeeReport,
  IEmployeeReportParams,
  ICustomerReport,
  IUsersReportParams,
  IUserReport,
  IBlacklistReport,
} from "./interface";

export const useGetCustomersReport = (
  params?: IUsersReportParams
): UseQueryResult<IResponse<ICustomerReport[]>> => {
  return useQuery(
    ["get-customer-reports", params],
    async () => {
      const res = await axios.get(
        "/report/report-user-management/customer-employee",
        { params }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetEmployeeReport = (
  params?: IEmployeeReportParams
): UseQueryResult<IResponse<IEmployeeReport[]>> => {
  return useQuery(
    ["get-employee-reports", params],
    async () => {
      const res = await axios.get("/report/report-user-management/employee", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetUserReport = (
  params?: IUsersReportParams
): UseQueryResult<IResponse<IUserReport[]>> => {
  return useQuery(
    ["get-user-reports", params],
    async () => {
      const res = await axios.get("/report/report-user-management/user", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetBlacklistReport = (
  params?: IUsersReportParams
): UseQueryResult<IResponse<IBlacklistReport[]>> => {
  return useQuery(
    ["get-blacklist-reports", params],
    async () => {
      const res = await axios.get(
        "/report/report-user-management/blacklist-person",
        { params }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useExportCustomerReport = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/report-user-management/customer-employee`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportEmployeeReport = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/report-user-management/employee`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportUserReport = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/report-user-management/user`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportBlacklistReport = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/report-user-management/blacklist-person`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
