import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IAccessControl, IAccessControlParams } from "./interface";

export const useGetAccessControls = (
  params?: IAccessControlParams
): UseQueryResult<IResponse<IAccessControl[]>> => {
  return useQuery(
    ["get-access-controls", params],
    async () => {
      const { wait } = params || {};
      if (wait) return;
      const res = await axios.get("/access-control", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetAccessControl = (
  id?: string | number
): UseQueryResult<IAccessControl> => {
  return useQuery(["get-access-control", id], async () => {
    if (!id) return;
    const res = await axios.get(`/access-control/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const useGetAccessControlList = (): UseMutationResult => {
  return useMutation(async () => {
    const res = await axios.post("/hik/get-access-control-list");
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePostAccessControls = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post("/access-control", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchAccessControls = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/access-control/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteAccessControls = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/access-control/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteAccessControlBulk = () => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/access-control/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetAccessControlsNotIn = (
  params?: IAccessControlParams
): UseQueryResult<IResponse<IAccessControl[]>> => {
  return useQuery(
    ["get-access-controls_not_in", params],
    async () => {
      const res = await axios.get("/access-control/not-in-parking-card", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useExportAccessControls = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `export-excel/access-control-export-excel`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};


export const usePostAccessControlsGenerateHCP = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post("/access-control/generate-access-control-from-hcp", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
