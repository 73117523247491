import { Col, Divider, Row, Typography } from "antd";
import { OnlineICon, OnsiteICon } from "assets/icons/web";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TSubPage } from "router/interface";

type TRegisterType = "ONSITE" | "ONLINE";
type TIConType = {
  bg: string;
  color: string;
  text: string;
  icon: ReactElement;
};
const REGISTER_TYPE_ICON: { [K in TRegisterType]: TIConType } = {
  ONSITE: {
    bg: "#FFF6E0",
    color: "#E0B33C",
    text: "regis-onsite",
    icon: <OnsiteICon height={25} width={25} />,
  },
  ONLINE: {
    bg: "#EAF7EC",
    color: "#47B174",
    text: "regis-online",
    icon: <OnlineICon height={25} width={25} />,
  },
};

const DefaultInformation = ({
  pageKey,
  registerType,
}: {
  pageKey?: TSubPage;
  registerType: TRegisterType;
}) => {
  const { t } = useTranslation();
  const type = REGISTER_TYPE_ICON[registerType];
  return (
    <React.Fragment>
      <Row gutter={12} className="p-5 !flex  items-center">
        <Col span={8}>
          <Typography.Title level={5} className="!text-primary-dark">
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </Col>
        <Col span={13}></Col>
        <Col span={1} className="flex justify-center">
          <div
            style={{
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: type.bg,
              borderRadius: "100%",
            }}
          >
            {type.icon}
          </div>
        </Col>
        <Col span={2}>
          <span style={{ color: type.color, fontSize: 20 }}>
            {t(type.text)}
          </span>
        </Col>
      </Row>
      <Divider className="!m-0" />
    </React.Fragment>
  );
};
export default DefaultInformation;
