import PreAppLoadingPage from "components/pages/pre-app-loading-page";
import { baseUrl } from "config/axios";
import { createContext, FC, ReactNode, useContext, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { PUBLIC_PATHS } from "router";
import { Logout, useGetMe } from "services/auth";
import { IAuthProviderContext } from "./interface";
import io from "socket.io-client";
import { IRole } from "services/role/interface";

const AuthProviderContext = createContext<IAuthProviderContext>({});

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  const isPublic = PUBLIC_PATHS.some((e) => e === pathname);
  const { isLoading, data, isError } = useGetMe(isPublic);

  useEffect(() => {
    if (isPublic || !data) return;
    const socket = io(baseUrl.replace("http", "ws"), {
      transports: ["websocket"],
    });
    socket.on("on-changed", (role: IRole) => {
      if (data?.role?.id === role.id && !role.isActive) {
        return Logout();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [isPublic, data]);

  if (isLoading && !isPublic) {
    return <PreAppLoadingPage />;
  }

  if (isError && !isPublic) {
    return <Navigate to="/login" replace />;
  }

  return (
    <AuthProviderContext.Provider value={{ userMe: data }}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export const useAuth = () => useContext(AuthProviderContext);
export default AuthProvider;
