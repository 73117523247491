import { Button, ButtonProps } from "antd";
import styled from "styled-components";
import tw from "twin.macro";
import { ButtonThemeTypes, BUTTON_THEME } from "./theme";

export interface CButtonProps extends ButtonProps {
  theme?: ButtonThemeTypes;
}

export const StyledButton = styled(Button)<{ thm: ButtonThemeTypes }>`
  height: 45px;
  ${tw`inline-flex justify-center items-center`};
  color: ${({ thm }) => BUTTON_THEME[thm].color};
  background-color: ${({ thm }) => BUTTON_THEME[thm].bg};
  border: 1px solid
    ${({ thm }) => BUTTON_THEME[thm].border || BUTTON_THEME[thm].bg};
  span {
    font-size: 20px;
    color: ${({ thm }) => BUTTON_THEME[thm].color};
  }
  // remove splash effect
  &:after {
    display: none;
  }
  &:hover,
  &:active,
  &:focus {
    border: 1px solid
      ${({ thm }) => BUTTON_THEME[thm].hoverBg || BUTTON_THEME[thm].bg};
    background-color: ${({ thm }) =>
      BUTTON_THEME[thm].hoverBg || BUTTON_THEME[thm].bg};
    color: ${({ thm }) => BUTTON_THEME[thm].color};
  }
  &.ant-btn-primary[disabled] {
    background: ${({ thm }) => BUTTON_THEME[thm].bg};
    border-color: ${({ thm }) => BUTTON_THEME[thm].bg};
    color: ${({ thm }) => BUTTON_THEME[thm].color};
  }
`;

const CButton: React.FC<CButtonProps> = ({ theme, ...rest }) => {
  return <StyledButton thm={theme || "primary"} {...rest} />;
};

export default CButton;
