import {
  CalendarFilled,
  ClockCircleFilled,
  DollarOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Col, Form, Input, Row, Typography } from "antd";
import CButton from "components/button/c-button";
import CSpace from "components/display/c-space";
import Container from "components/display/container";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledInput = styled(Input)`
  height: 60px;
  font-size: 20px;
  padding: 20px;
`;

const SearchButton = styled(CButton)`
  height: 60px;
  min-width: 60px;
  span {
    font-size: 25px;
  }
`;

const FindVehicleSearchCar = () => {
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <Container scrollY padding="p-0" height="calc(100vh - 209px)">
      <Row gutter={[12, 24]}>
        <Col span={24} className="mt-5">
          <Typography.Title level={3} className="text-center">
            {t("search-license-plate-number")}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <InputField onFinish={onFinish} />
        </Col>
        <Col span={24}>
          <Row gutter={12} className="px-5">
            <Col>
              <Typography.Title level={5} className="!text-gray-500">
                {t("card-number")}
              </Typography.Title>
            </Col>
            <Col>
              <Typography.Title level={5}>#A2214-0</Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col span={12}>
          <HalfBlock title={t("parking-lot")} value="Parking 01" />
          <HalfBlock title={t("parking-floor")} value="Floor A3" bottom />
        </Col>
        <Col span={12}>
          <FullBlock title={t("parking-zone")} value="P01" />
        </Col>
        <Col span={24}>
          <TimeBlock />
        </Col>
      </Row>
    </Container>
  );
};


const TimeBlock: FC = () => {
  const { t } = useTranslation();
  return (
    <Row className="p-5 border-b" gutter={12}>
      <Col span={4}>
        <Typography.Text>{t("entry-time")}</Typography.Text>
      </Col>
      <Col span={20}>
        <Row gutter={[0, 12]}>
          <Col span={12}>
            <CSpace>
              <CalendarFilled />
              <Typography.Text>01 ม.ค. 2564</Typography.Text>
            </CSpace>
          </Col>
          <Col span={12}>
            <CSpace>
              <ClockCircleFilled />
              <Typography.Text>09:12:00</Typography.Text>
            </CSpace>
          </Col>
          <Col span={24}>
            <CSpace>
              <ClockCircleFilled />
              <Typography.Text>0 วัน 1 ชั่วโมง 15 นาที</Typography.Text>
            </CSpace>
          </Col>
          <Col span={24}>
            <CSpace>
              <DollarOutlined />
              <Typography.Text>20 บาท</Typography.Text>
            </CSpace>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const HalfBlock: FC<{ title: string; value: string; bottom?: boolean }> = ({
  title,
  value,
  bottom,
}) => {
  return (
    <Row
      gutter={[0, 6]}
      className={`p-5 border-t border-r ${bottom ? "border-b" : ""}`}
      style={{ height: 100 }}
    >
      <Col span={24}>
        <Typography.Text>{title}</Typography.Text>
      </Col>
      <Col span={24}>
        <Typography.Title level={5}>{value}</Typography.Title>
      </Col>
    </Row>
  );
};

const FullBlock: FC<{ title: string; value: string }> = ({ title, value }) => {
  return (
    <div
      style={{ height: 200 }}
      className="flex flex-col items-center justify-center p-5 border-t border-b"
    >
      <Typography.Text>{title}</Typography.Text>
      <Typography.Title level={1}>{value}</Typography.Title>
    </div>
  );
};

const InputField: FC<{ onFinish: (v: any) => void }> = ({ onFinish }) => {
  const { t } = useTranslation();
  return (
    <Form onFinish={onFinish} className="!flex !px-5">
      <Form.Item name="licensePlate" noStyle>
        <StyledInput
          className="!mr-3"
          placeholder={t("license-plate-number")}
        />
      </Form.Item>
      <SearchButton
        htmlType="submit"
        theme="primary"
        icon={<SearchOutlined />}
      />
    </Form>
  );
};

export default FindVehicleSearchCar;
