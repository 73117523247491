import { Col, Popover, Row, TableColumnsType, Typography } from "antd";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import { useAppLanguage } from "provider/app-language";
import { useUserReport } from "provider/user-report";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportEmployeeReport,
  useGetEmployeeReport,
} from "services/users-report";
import {
  IEmployeeReport,
  IEmployeeReportParams,
} from "services/users-report/interface";

const UserManagementReportEmployee = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IEmployeeReportParams>({});
  const { columnsKey, search } = useUserReport();
  const { formatDate, translationObject } = useAppLanguage();

  const data_employee_report = useGetEmployeeReport({
    ...params,
    startDate: search?.startDate,
    endDate: search?.endDate,
    name: search?.name,
    phoneNumber: search?.phoneNumber,
    email: search?.email,
    position: search?.position,
    licensePlateNumber: search?.licensePlateNumber,
    vehicleBrand: search?.vehicleBrand,
    vehicleModel: search?.vehicleModel,
  });

  const export_employee = useExportEmployeeReport();

  const columns: TableColumnsType<IEmployeeReport> = [

    {
      key: "date",
      title: t("entry-date"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const date = formatDate({
          date: rc.createdAt,
          format: "nomal-full-time",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      key: "identityCode",
      title: t("employee-id"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const identity = rc?.identityCode || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: identity }}>
            {identity}
          </Typography.Text>
        );
      },
    },
    {
      key: "name",
      title: t("full-name"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const phoneNumber = rc?.phoneNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: phoneNumber }}>
            {phoneNumber}
          </Typography.Text>
        );
      },
    },
    {
      key: "email",
      title: t("email"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const email = rc?.email || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: email }}>
            {email}
          </Typography.Text>
        );
      },
    },
    {
      key: "position",
      title: t("position"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const positionJOb = rc.position?.positionNameTh || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: positionJOb }}>
            {positionJOb}
          </Typography.Text>
        );
      },
    },
    {
      key: "licensePlateNumber",
      title: t("license-plate"),
      ellipsis: { showTitle: false },
      align: "center",
      render: (_, rc) => {
        const licensePlate = rc?.vehicleInfos?.map(
          (item) => item?.licensePlateNumber
        );
        const count = licensePlate?.length || 0;

        const content = (
          <div>
            {rc?.vehicleInfos?.map((i) => {
              return (
                <Row gutter={6}>
                  <Col>
                    <Typography.Text ellipsis className="!text-gray-500">
                      {`${t("license-plate")} :`}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text ellipsis>
                      {i?.licensePlateNumber || "-"}
                    </Typography.Text>
                  </Col>
                </Row>
              );
            })}
          </div>
        );

        return (
          <Popover content={content} title={t("license-plate")}>
            {`${count} ${t("list")}`}
          </Popover>
        );
      },
    },
    {
      key: "vehicleBrand",
      title: t("vehicle-brand"),
      ellipsis: { showTitle: false },
      align: "center",
      render: (_, rc) => {
        const vehicleBrand = rc?.vehicleInfos?.map(
          (item) => item?.vehicleBrand
        );

        let arr = [];

        for (var i = 0; i < (vehicleBrand?.length || 0); i++) {
          if (arr.indexOf(vehicleBrand?.[i]) < 0) {
            arr.push(vehicleBrand?.[i]);
          } else {
            // c.push( a[i] );
          }
        }

        const content = arr?.map((i) => {
          return (
            <Row gutter={6}>
              <Col>
                <Typography.Text ellipsis className="!text-gray-500">
                  {`${t("vehicle-brand")} :`}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text ellipsis>{i || "-"}</Typography.Text>
              </Col>
            </Row>
          );
        });

        return (
          <Popover content={content} title={t("vehicle-brand")}>
            {`${arr?.length || 0} ${t("list")}`}
          </Popover>
        );
      },
    },
    {
      key: "vehicleModel",
      title: t("vehicle-series"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const vehicleModel = rc?.vehicleInfos?.map(
          (item) => item?.vehicleModel
        );

        let a = [];

        for (var i = 0; i < (vehicleModel?.length || 0); i++) {
          if (a.indexOf(vehicleModel?.[i]) < 0) {
            a.push(vehicleModel?.[i]);
          } else {
            // c.push( a[i] );
          }
        }

        const content = a?.map((i) => {
          return (
            <Row gutter={6}>
              <Col>
                <Typography.Text ellipsis className="!text-gray-500">
                  {`${t("vehicle-series")} :`}
                </Typography.Text>
              </Col>
              <Col>{i || "-"}</Col>
            </Row>
          );
        });

        return (
          <Popover content={content} title={t("vehicle-brand")}>
            {`${a?.length || 0} ${t("list")}`}
          </Popover>
        );
      },
    },
    {
      key: "startDate",
      ellipsis: { showTitle: false },
      title: t("start-date"),
      render: (_, rc) => {
        if (!rc?.startDate) return "-";
        const date = formatDate({ date: rc?.startDate, format: "normal" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    // {
    //   key: "identityCode",
    //   title: t("employee-id"),
    //   ellipsis: { showTitle: false },
    //   render: (_, rc) => {
    //     const identity = rc?.identityCode || "-";
    //     return (
    //       <Typography.Text ellipsis={{ tooltip: identity }}>
    //         {identity}
    //       </Typography.Text>
    //     );
    //   },
    // },
    {
      key: "identificationNumber",
      title: t("identification-card-number"),
      ellipsis: { showTitle: false },

      render: (_, rc) => {
        const identificationNumber = rc?.identificationNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: identificationNumber }}>
            {identificationNumber}
          </Typography.Text>
        );
      },
    },
    {
      key: "prefix",
      title: t("name-title"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const prefix = rc?.prefix;
        const check =
          prefix === "MISTER"
            ? t("mister")
            : prefix === "MRS"
              ? t("mrs")
              : prefix === "MISS"
                ? t("miss")
                : "-";
        return (
          <Typography.Text ellipsis={{ tooltip: check }}>
            {check}
          </Typography.Text>
        );
      },
    },
    {
      key: "cardCode",
      title: t("swipe-card-code"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const cardCode = rc?.cardCode || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: cardCode }}>
            {cardCode}
          </Typography.Text>
        );
      },
    },
    {
      key: "department.titleTh",
      title: t("department"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const { title } = translationObject({
          object: rc?.department,
          keys: ["title"],
        });
        return (
          <Typography.Text ellipsis={{ tooltip: title || "-" }}>
            {title || "-"}
          </Typography.Text>
        );
      },
    },
  ];

  const _columns = columns.filter((item) => {
    return columnsKey.some((e) => e === item.key);
  });

  console.log("_columns : ", _columns)
  const topic = _columns
    .map((i) => {
      if (i?.key === "profile") return "profile.url";
      if (i?.key === "date") return "createdAt";
      if (i?.key === "name") return ["firstName", "lastName"];
      if (i?.key === "position") return "position.positionNameTh";
      if (i?.key === "licensePlateNumber")
        return "vehicleInfos.licensePlateNumber";
      if (i?.key === "vehicleBrand") return "vehicleInfos.vehicleBrand";
      if (i?.key === "vehicleModel") return "vehicleInfos.vehicleModel";
      return i?.key;
    })
    .flat();
  console.log("topic : ", topic)

  return (
    <CTable
      scroll={{ x: 2000 }}
      selectAble={false}
      title={t("list-all")}
      columns={_columns}
      query={data_employee_report}
      pagination={{
        onChange: (page, limit) => {
          setParams({ ...params, page, limit });
        },
      }}
      action={{
        excels: {
          export: {
            mutation: export_employee,
            extraValue: { topic: topic, userType: search?.userType },
            notShowModal: true,
          },
        },
      }}
    />
  );
};

export default UserManagementReportEmployee;
