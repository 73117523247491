import {
  DeleteFilled,
  EyeFilled,
  FileAddFilled,
  FilePdfFilled,
  LeftOutlined,
  RightOutlined,
  SaveFilled,
} from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import CImage from "components/display/c-image";
import { fireNotification } from "components/popup/notification";
import { usePageRoute } from "provider/page-route";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IMediaObject } from "services/media-object/interface";
import styled from "styled-components";
import { fileToDataUrl, resizeImageFileSize } from "tools/file";
import tw from "twin.macro";
import { AcceptFileTypes } from ".";

import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import CModal from "components/display/c-modal";
import AppLoadingLoader from "components/layout/app-loading-loader";
import ErrorPage from "components/pages/error-page";
import EmptyPage from "components/pages/empty-page";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface CUploadListProps {
  accepts?: AcceptFileTypes[];
  onChange?: (v: (File | IMediaObject)[]) => void;
  value?: (File | IMediaObject)[];
  id?: string;
  disabled?: boolean;
}

const CUploadListWrapper = styled.div`
  ${tw`border p-3 rounded-app`};
  min-height: 1px;
`;

const CUploadList: FC<CUploadListProps> = ({
  accepts = [".jpeg", ".jpg", ".pdf", ".png"],
  id,
  onChange,
  value,
  disabled = false,
}) => {
  const onRemove = (uuid?: number) => {
    const next = value?.filter((_, index) => {
      // if ("url" in e) {
      //   return e.id !== uuid;
      // }
      return index !== uuid;
    });
    onChange?.(next || []);
  };

  const onAdd = (files: File[]) => {
    if (disabled) return;
    const next = [...(value || []), ...files];
    onChange?.(next);
  };

  return (
    <CUploadListWrapper id={id}>
      <Row gutter={[12, 12]}>
        {value?.map((item, index) => {
          return (
            <Col key={index}>
              <UploadBox
                onRemove={onRemove}
                onAdd={onAdd}
                file={item}
                type="file"
                keyItem={index}
                disabled={disabled}
              />
            </Col>
          );
        })}
        <Col>
          <UploadBox
            onRemove={onRemove}
            onAdd={onAdd}
            accepts={accepts}
            type="empty"
            disabled={disabled}
          />
        </Col>
      </Row>
    </CUploadListWrapper>
  );
};

const UploadBoxWrapper = styled.div<{ $hover?: boolean; $empty?: boolean }>`
  ${tw`border border-dashed rounded-app`};
  width: 100px;
  height: 100px;
  cursor: ${({ $empty }) => ($empty ? "pointer" : "")};

  &:hover {
    #delete-mask {
      ${({ $hover }) => ($hover ? tw`visible opacity-100` : "")}
    }
    .ant-typography {
      color: white !important;
    }
  }
`;

const DeleteMaskWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  transition: visibility 0s, opacity 0.1s linear;
  background: rgba(0, 0, 0, 0.8);
  ${tw`invisible opacity-0 rounded-app`};
`;

interface UploadBoxProps {
  type: "file" | "empty";
  file?: File | IMediaObject;
  accepts?: AcceptFileTypes[];
  onAdd: (v: File[]) => void;
  onRemove: (id?: number) => void;
  keyItem?: number;
  disabled?: boolean;
}

const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];

const UploadBox: FC<UploadBoxProps> = ({
  type,
  accepts,
  file,
  onRemove,
  onAdd,
  keyItem,
  disabled = false,
}) => {
  const { t } = useTranslation("menu");
  const { pageKey } = usePageRoute();
  const ref = useRef<HTMLInputElement>(null);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<IMediaObject>();

  // on file select
  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;
    const fileArr = Object.values(files).map((e) => e);
    const fileArrCheckSize = fileArr.filter((e) => {
      const isLt2M = e.size / 1024 / 1024 < 2;
      const notImage = !validImageTypes.includes(e.type);
      if (!isLt2M && notImage) {
        return fireNotification({
          type: "error",
          description: t("images-cannot-larger-2mb", { ns: "message" }),
          menu: pageKey,
        });
      }
      return e;
    });
    const resizeImage = fileArrCheckSize.map(async (e) => {
      const isImage = validImageTypes.includes(e.type);
      if (isImage)
        return await resizeImageFileSize({
          file: e,
          maxHeight: 500,
          maxWidth: 500,
        });
      return e;
    });
    const fileArrRe = await Promise.all(resizeImage);
    onAdd(fileArrRe as File[]);
  };

  // clear prev selected file
  const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const openFilePickerDialog = () => {
    if (disabled) return;
    ref.current?.click();
  };

  //on preview image
  const handlePreview = async (file: IMediaObject) => {
    setPreviewImage(file);
    return setPreviewOpen(!previewOpen);
  };

  const handleCancel = () => setPreviewOpen(!previewOpen);

  //on download
  const onDownload = async (file: IMediaObject) => {
    return window.open(file.url, "_parent");
  };

  if (type === "empty" && accepts) {
    return (
      <UploadBoxWrapper
        className="center"
        onClick={openFilePickerDialog}
        $empty={type === "empty"}
      >
        <FileAddFilled style={{ fontSize: 20, color: "#A8A8A8" }} />
        <input
          accept={accepts.join(",")}
          multiple={true}
          type="file"
          ref={ref}
          style={{ display: "none" }}
          onChange={onFileSelect}
          onClick={onClick}
        />
      </UploadBoxWrapper>
    );
  }

  if (file) {
    const getFileDescription = () => {
      if ("url" in file) {
        return { key: keyItem, fileName: file.filename };
      }
      return { key: keyItem, fileName: file.name };
    };

    const { key, fileName } = getFileDescription();

    return (
      <UploadBoxWrapper $hover className="relative">
        <FilePreview file={file} />
        <DeleteMaskWrapper className="center" id="delete-mask">
          <Row justify="space-around" className="w-full p-2">
            {"url" in file && (
              <>
                <EyeFilled
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => {
                    handlePreview(file);
                  }}
                />
                <SaveFilled
                  style={{ color: "red", fontSize: 20 }}
                  onClick={() => {
                    onDownload(file);
                  }}
                />
              </>
            )}

            <DeleteFilled
              style={{ color: "red", fontSize: 20 }}
              onClick={() => {
                onRemove(key);
              }}
            />
          </Row>
        </DeleteMaskWrapper>
        <Typography.Text
          style={{
            fontSize: 12,
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            // backgroundColor: "white",
            zIndex: 2,
          }}
          className="px-1"
          ellipsis={{ tooltip: fileName }}
          type="secondary"
        >
          {fileName}
        </Typography.Text>
        <ModalPreview
          previewOpen={previewOpen}
          handleCancel={handleCancel}
          previewImage={previewImage}
        />
      </UploadBoxWrapper>
    );
  }

  return null;
};

const PdfWrapper = styled.div<{ $hover?: boolean }>`
  height: calc(100vh - 120px);
  width: 100%;
  overflow-y: auto;
  &:hover {
    #page-pdf {
      ${({ $hover }) => ($hover ? tw`visible opacity-100` : "")}
    }
  }
`;

const PageMaskWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 88%;
  left: 37%;
  transition: visibility 0s, opacity 0.1s linear;
  background: #ffffff;
  width: 25%;
  height: 45px;
  ${tw`invisible opacity-0 rounded-app shadow-[0px 10px 20px 5px rgba(0,0,0,0.10)] `};
`;

const Left = styled.div<{ $hover?: boolean }>`
  ${tw`w-full h-full`}
  &:hover {
    ${({ $hover }) =>
      $hover
        ? `background: #c1c1c1;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;`
        : ""}
  }
`;

const Right = styled.div<{ $hover?: boolean }>`
  ${tw`w-full h-full`}
  &:hover {
    ${({ $hover }) =>
      $hover
        ? `background: #c1c1c1;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;`
        : ""}
  }
`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

const ModalPreview: FC<{
  previewOpen: boolean;
  handleCancel: () => void;
  previewImage?: IMediaObject;
}> = ({ previewImage, previewOpen, handleCancel }) => {
  const pdf =
    previewImage?.filename.split(".").pop()?.toLocaleLowerCase() === "pdf";
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (pdf) {
      setPageNumber(1);
    }
  }, [pdf, previewOpen]);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const classN = "!flex justify-center w-full h-full";

  const loading = (
    <div className={classN}>
      <AppLoadingLoader />
    </div>
  );

  const error = (
    <div className={classN}>
      <ErrorPage />
    </div>
  );

  const noData = (
    <div className={classN}>
      <EmptyPage />
    </div>
  );

  const props = { loading: loading, error: error, noData: noData };

  return (
    <CModal
      visible={previewOpen}
      title={previewImage?.filename}
      footer={null}
      onCancel={handleCancel}
      closable
      forceRender={false}
      className={pdf ? "!w-[750px]" : ""}
    >
      {pdf ? (
        <PdfWrapper $hover>
          <Document
            file={previewImage?.url}
            onLoadSuccess={onDocumentLoadSuccess}
            {...props}
            options={options}
          >
            <Page pageNumber={pageNumber} width={680} {...props} />
          </Document>
          <PageMaskWrapper id="page-pdf">
            <Row className="w-full h-full">
              <Col span={6}>
                <Left
                  className="center"
                  $hover={pageNumber > 1}
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <LeftOutlined />
                </Left>
              </Col>
              <Col span={12} className="center">
                {pageNumber} of {numPages}
              </Col>
              <Col span={6}>
                <Right
                  className="center"
                  $hover={(numPages || 1) > pageNumber}
                  onClick={() => {
                    if ((numPages || 1) > pageNumber) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <RightOutlined />
                </Right>
              </Col>
            </Row>
          </PageMaskWrapper>
        </PdfWrapper>
      ) : (
        <CImage src={previewImage?.url} />
      )}
    </CModal>
  );
};

const FilePreview: FC<{ file: File | IMediaObject }> = ({ file }) => {
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    if ("url" in file) {
      return setSrc(file.url);
    }
    const convert = async () => {
      const base = await fileToDataUrl(file);
      if (typeof base !== "string") return;
      setSrc(base);
    };
    if (file?.type?.includes("image")) {
      convert();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  if ("url" in file) {
    if (file.filename.split(".").pop()?.toLocaleLowerCase() === "pdf") {
      return (
        <div className="h-full center">
          <FilePdfFilled style={{ fontSize: 20, color: "red" }} />
        </div>
      );
    }
    return <CImage src={file.url} placeholder={null} className="rounded-app" />;
  }

  if (file?.type?.includes("image")) {
    return <CImage src={src} placeholder={null} className="rounded-app" />;
  }

  if (file?.type?.includes("pdf")) {
    return (
      <div className="h-full center">
        <FilePdfFilled style={{ fontSize: 20, color: "red" }} />
      </div>
    );
  }

  return null;
};

export default CUploadList;
