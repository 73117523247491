import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetPositions } from "services/position";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

export interface CSelectJobPositionProps extends CSelectProps {
  isActive?: boolean;
}

const CSelectJobPosition: FC<CSelectJobPositionProps> = ({
  isActive,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetPositions}
      valueKey="id"
      titleKey="positionCode"
      searchKey="name"
      placeholder={t("job-position")}
      queryParams={{
        status: typeof isActive === "undefined" ? true : isActive,
        limit: 100,
      }}
      // translation
    />
  );
};
export default CSelectJobPosition;
