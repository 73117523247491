import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IEmailTemplate, IEmailTemplateParams } from "./interface";

export const useGetEmailTemplates = (
  params?: IEmailTemplateParams
): UseQueryResult<IResponse<IEmailTemplate[]>, Error> => {
  return useQuery(
    ["get-email-templates", params],
    async () => {
      const res = await axios.get("/template-email", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetEmailTemplate = (
  id?: string
): UseQueryResult<IEmailTemplate, Error> => {
  return useQuery(
    ["get-email-template", id],
    async () => {
      const res = await axios.get(`/template-email/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetScrollEmailTemplates = (
  params?: IEmailTemplateParams
): UseInfiniteQueryResult<IResponse<IEmailTemplate[]>, Error> => {
  return useInfiniteQuery(
    ["get-email-templates", params],
    async ({ pageParam }) => {
      const res = await axios.get("/template-email", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const usePostEmailTemplate = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ html, template, ...data }: any) => {
    html = await html;
    template = await template;
    data = { ...data, template, html };
    const res = await axios.post(`/template-email`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchEmailTemplate = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/template-email/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteEmailTemplate = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/template-email/${id}`);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteEmailTemplates = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/template-email/bulk`, { data });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
