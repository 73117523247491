import { Col, Row } from "antd";
import PageContent from "components/layout/page-content";
import withRoleProvider from "provider/role";
import RoleDetail from "./detail";
import RoleSidebar from "./sidebar";

const Role = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <RoleSidebar />
        </Col>
        <Col span={18} className='p-5'>
          <RoleDetail />
        </Col>
      </Row>
    </PageContent>
  );
};

export default withRoleProvider(Role);
