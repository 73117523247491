import {
  CameraFilled,
  ExpandAltOutlined,
  PlaySquareOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Row } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useCameraGroup } from "provider/all-video";
import { useTranslation } from "react-i18next";
import TransitionVideo from "components/menu/resource-mamagement/transition-all-videos";
import { Playback } from "./playback";
import CButtonIcon from "components/button/c-button-icon";
import { fireNotification } from "components/popup/notification";

const AllVideosWrapper = styled.div<{ height: string }>`
  ${tw`w-full overflow-auto`}
  height: ${({ height }) => height};
`;

const TabIcon = styled(Button)<{ $selected?: boolean }>`
  ${tw`w-[108px] h-[45px]`};
  color: ${({ $selected }) => ($selected ? "#ffffff" : "#E4E4E4")};
  background: ${({ $selected }) => ($selected ? "#438860" : "#ffffff")};
  &:hover {
    ${tw`text-[#438860] bg-[#ffffff]`};
  }
  &:focus {
    ${tw`text-[#ffffff] bg-[#438860] border-[#438860]`};
  }
`;

const AllVideosContentPage = () => {
  return (
    <React.Fragment>
      <Tab />
      <Divider className="!m-0" />
      <Content />
    </React.Fragment>
  );
};

const Tab = () => {
  const { t } = useTranslation();
  const { getContent, setContent, setFullScreen } = useCameraGroup();
  const { jsDecoderPlayback } = useCameraGroup();

  const fullScreen = () => {
    const element = document.getElementById("wind-player");
    element?.requestFullscreen();
    setFullScreen(true);
  };

  const capture = () => {
    jsDecoderPlayback.JS_CapturePicture(0, "img", "JPEG").then(
      () => {},
      () => {
        fireNotification({
          type: "error",
          menu: "resource-management",
        });
      }
    );
  };

  return (
    <Row className="py-[0.6rem]">
      <Col span={12} className="!flex justify-end px-1">
        <TabIcon
          $selected={getContent === "all"}
          onClick={() => {
            // jsDecoder?.JS_Stop(0);
            setContent("all");
          }}
        >
          <VideoCameraOutlined />
        </TabIcon>
      </Col>
      <Col span={12}>
        <Row>
          <Col span={12} className="px-1">
            <TabIcon
              $selected={getContent === "playback"}
              onClick={() => {
                setContent("playback");
              }}
            >
              <PlaySquareOutlined />
            </TabIcon>
          </Col>
          <Col span={12} className="!flex justify-end items-center pr-4">
            <CButtonIcon
              onClick={fullScreen}
              style={{ width: 120 }}
              icon={<ExpandAltOutlined />}
              hidden={getContent !== "all"}
            >
              {t("open-windows")}
            </CButtonIcon>
            <CButtonIcon
              onClick={capture}
              style={{ width: 60 }}
              icon={<CameraFilled />}
              hidden={getContent !== "playback"}
            >
              {t("take")}
            </CButtonIcon>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Content = () => {
  const { getContent, setSelected } = useCameraGroup();

  useEffect(() => {
    setSelected(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContent]);

  // const getHeight = () => {
  //   if (getContent === "playback") return "calc(100vh - 347px)";
  //   return "calc(100vh - 247px)";
  // };

  // const height = getHeight();

  if (getContent === "playback") {
    return <Playback />;
  }

  return (
    <AllVideosWrapper height={"calc(100vh - 247px)"}>
      <TransitionVideo />
    </AllVideosWrapper>
  );
};

export default AllVideosContentPage;
