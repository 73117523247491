import { useQueryClient } from "@tanstack/react-query";
import { Col, Form, Row, Spin, TimePicker, Typography } from "antd";
import CDatePicker from "components/input/c-date-picker";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import { fireNotification } from "components/popup/notification";
import dayjs, { Dayjs } from "dayjs";
import { useAppLoading } from "provider/app-loading";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetSchedules,
  usePatchScheduleId,
  usePostSchedule,
} from "services/setting-schedule";
import styled from "styled-components";
import CCalendar from "components/input/c-calendar";
import ScheduleDescriptionCard from "./schedule-description-card";
import { useSettingSchedule } from "provider/setting-schedule";
import { ISchedule } from "services/setting-schedule/interface";
import CModal from "components/display/c-modal";
import CModalFooter from "components/display/c-modal/modal-footer";

const border = "1px solid rgba(217, 217, 217, 0.5)";

const StyledCalendar = styled(CCalendar)`
  th {
    height: 40px !important;
    border-top: ${border};
    border-left: ${border};
    border-bottom: ${border};
  }
  th:last-child {
    border-right: ${border};
  }
  tr td {
    border-top: ${border};
    border-left: ${border};
  }
  tr:last-child {
    border-bottom: ${border};
  }
  tr > td:nth-child(7) {
    border-right: ${border};
  }
`;

const DateCellWrapper = styled.div`
  height: 80px;
  min-width: 100px;
  padding-right: 12px;
  padding-top: 12px;
  position: relative;
`;

interface SettingScheduleCalendarProps {
  startDate: string;
  endDate: string;
}

const SettingScheduleCalendar: FC<SettingScheduleCalendarProps> = ({
  startDate,
  endDate,
}) => {
  // const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<ISchedule | undefined>();

  const { type, currentTime, setAction, selectedGroupTable } =
    useSettingSchedule();

  const { data, isFetching } = useGetSchedules({
    startDate: startDate,
    endDate: endDate,
    groupTable: selectedGroupTable?.id,
    pagination: false,
  });

  const toggle = () => {
    setVisible(!visible);
    setAction?.("add");
  };

  const dateFullCellRender = (value: Dayjs) => {
    const sche = (data || []).filter((item) => {
      const st = dayjs(item.startDate, "YYYY-MM-DD").startOf("D");
      const et = dayjs(item.endDate, "YYYY-MM-DD").endOf("D");
      return value >= st && value <= et;
    });

    return (
      <DateCellWrapper
        onClick={() => {
          toggle();
          // form.resetFields();
          // setCurrent(value);
        }}
      >
        <Typography.Text style={{ color: "unset" }}>
          {value.date()}
        </Typography.Text>
        <ScheduleDescriptionCard
          schedules={sche}
          onEdit={(schedule) => {
            toggle();
            setSelected(schedule);
          }}
        />
      </DateCellWrapper>
    );
  };

  return (
    <Spin spinning={isFetching}>
      <AddScheduleModal
        visible={visible}
        onClose={toggle}
        setSelected={setSelected}
        selected={selected}
      />
      <StyledCalendar
        headerRender={() => null}
        dateFullCellRender={dateFullCellRender}
        value={currentTime[type].startOf(type)}
      />
    </Spin>
  );
};

const AddScheduleModal: FC<{
  visible: boolean;
  onClose: () => void;
  selected?: ISchedule;
  setSelected: (v: ISchedule | undefined) => void;
}> = ({ visible, onClose, selected, setSelected }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { setAppLoading } = useAppLoading();

  const post_schedule = usePostSchedule();
  const patch_schedule = usePatchScheduleId();
  const qClient = useQueryClient();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const init = () => {
    if (!selected) return;
    const start = dayjs(selected.startDate);
    const end = dayjs(selected.endDate);

    form.setFieldsValue({
      ...selected,
      startDate: start,
      endDate: end,
      startTime: start,
      endTime: end,
      groupTable: selected?.groupTable?.id || null,
    });
  };

  const onFinish = async () => {
    const values = await form.validateFields();
    const { startDate, startTime, endTime, endDate } = values as {
      startDate: Dayjs;
      startTime: Dayjs;
      endTime: Dayjs;
      endDate: Dayjs;
    };

    const start = startDate
      .clone()
      .hour(startTime.hour())
      .minute(startTime.minute())
      .second(startTime.second());

    const end = endDate
      .clone()
      .hour(endTime.hour())
      .minute(endTime.minute())
      .second(endTime.second());

    setAppLoading(true);
    if (selected) {
      return onUpdate({
        ...values,
        startDate: start,
        endDate: end,
        groupTable: values?.groupTable || null,
      });
    }
    return onCreate({ ...values, startDate: start, endDate: end });
  };

  const onUpdate = (values: any) => {
    patch_schedule.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success", menu: "setting" });
        qClient.invalidateQueries(["get-schedules"]);
        qClient.invalidateQueries(["get-schedule"]);
        onClose();
      },
      onError: ({ message }) => {
        fireNotification({ type: "error", message, menu: "setting" });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  const onCreate = (values: any) => {
    post_schedule.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success", menu: "setting" });
        qClient.invalidateQueries(["get-schedules"]);
        qClient.invalidateQueries(["get-schedule"]);
        onClose();
      },
      onError: ({ message }) => {
        fireNotification({ type: "error", message, menu: "setting" });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  return (
    <CModal
      visible={visible}
      onCancel={onClose}
      width={700}
      title={selected ? t("edit-schedule") : t("add-schedule")}
      afterClose={() => {
        form.resetFields();
        setSelected(undefined);
      }}
      footer={
        <CModalFooter
          submit={{ onClick: onFinish }}
          cancel={{ onClick: onClose }}
        />
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item name="id" hidden>
          <CInput />
        </Form.Item>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label={t("add-topic")}
              name="scheduleName"
              rules={[{ required: true }]}
            >
              <CInput placeholder={t("add-topic")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("start-date")}
              name="startDate"
              rules={[{ required: true }]}
            >
              <CDatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("start-time")}
              name="startTime"
              rules={[{ required: true }]}
            >
              <TimePicker style={{ width: "100%", height: "45px" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("end-date")}
              name="endDate"
              rules={[{ required: true }]}
            >
              <CDatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("end-time")}
              name="endTime"
              rules={[{ required: true }]}
            >
              <TimePicker style={{ width: "100%", height: "45px" }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="groupTable" label={t("group")}>
              <CSelect.ScheduleGroup />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={`${t("description")} (EN)`}
              name="descriptionEn"
              rules={[{ required: true }]}
            >
              <CInput.TextArea
                placeholder={`${t("description")} (EN)`}
                rows={5}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={`${t("description")} (TH)`}
              name="descriptionTh"
              rules={[{ required: true }]}
            >
              <CInput.TextArea
                placeholder={`${t("description")} (TH)`}
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CModal>
  );
};

export default SettingScheduleCalendar;
