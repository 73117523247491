export const MenuUserPendingListIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/tick-circle" transform="translate(-748 -188)">
        <g>
          <path
            fill="#fff"
            d="M10 0a10 10 0 1010 10A10.016 10.016 0 0010 0zm4.78 7.7l-5.67 5.67a.748.748 0 01-1.06 0l-2.83-2.83a.75.75 0 111.06-1.06l2.3 2.3 5.14-5.14a.75.75 0 011.06 1.06z"
            transform="translate(750 190)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuUserGuestIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="#fff" transform="translate(-172 -252)">
        <path
          d="M4.75 0a4.746 4.746 0 00-.12 9.49.807.807 0 01.22 0h.07A4.746 4.746 0 004.75 0z"
          transform="translate(176.25 254)"
        ></path>
        <path
          d="M12.12 1.395a9.929 9.929 0 00-10.15 0A3.947 3.947 0 000 4.625a3.914 3.914 0 001.96 3.21 9.239 9.239 0 005.08 1.41 9.239 9.239 0 005.08-1.41 3.945 3.945 0 001.96-3.23 3.937 3.937 0 00-1.96-3.21z"
          data-name="Vector"
          transform="translate(173.96 264.755)"
        ></path>
        <path
          d="M5.88 3.283a3.579 3.579 0 01-3.13 3.87H2.7a.468.468 0 00-.17.02A3.588 3.588 0 010 6.343a4.576 4.576 0 001.5-3.8A4.641 4.641 0 00.73.363a3.59 3.59 0 015.15 2.92z"
          data-name="Vector"
          transform="translate(186.11 256.057)"
        ></path>
        <path
          d="M8 4.09a3.046 3.046 0 01-1.74 2.38 7.058 7.058 0 01-3.51.78 3.569 3.569 0 001.22-2.32A3.94 3.94 0 002.3 1.55 8.255 8.255 0 000 .29a7.815 7.815 0 016.7 1.17A3.038 3.038 0 018 4.09z"
          data-name="Vector"
          transform="translate(185.99 264.5)"
        ></path>
      </g>
    </svg>
  );
};

export const MenuUserContractorIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/people" transform="translate(-620 -252)">
        <g fill="#fff">
          <path
            d="M2.99 5.77a.739.739 0 00-.21 0 2.9 2.9 0 11.21 0z"
            data-name="Vector"
            transform="translate(634.54 254)"
          ></path>
          <path
            d="M4.19 4.693a6.209 6.209 0 01-4.14.84 6.531 6.531 0 00.59-2.69A6.145 6.145 0 000 .063a6.237 6.237 0 014.18.83 2.119 2.119 0 01.01 3.8z"
            data-name="Vector"
            transform="translate(636.6 262.007)"
          ></path>
          <path
            d="M2.78 5.77a.739.739 0 01.21 0 2.885 2.885 0 10-.21 0z"
            data-name="Vector"
            transform="translate(623.66 254)"
          ></path>
          <path
            d="M4.555 2.843a6.512 6.512 0 00.59 2.72 6.179 6.179 0 01-3.96-.86 2.124 2.124 0 010-3.81 6.141 6.141 0 014-.85 6.358 6.358 0 00-.63 2.8z"
            data-name="Vector"
            transform="translate(621.995 262.007)"
          ></path>
          <path
            d="M3.57 6.87a1.129 1.129 0 00-.26 0 3.45 3.45 0 11.26 0z"
            data-name="Vector"
            transform="translate(628.55 261)"
          ></path>
          <path
            d="M1.132.862a2.053 2.053 0 000 3.67 6.111 6.111 0 006.26 0 2.053 2.053 0 000-3.67 6.111 6.111 0 00-6.26 0z"
            data-name="Vector"
            transform="translate(627.737 269.078)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuUserSupplierIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="vuesax_bold_truck-fast"
        data-name="vuesax/bold/truck-fast"
        transform="translate(-748 -188)"
      >
        <g id="truck-fast">
          <path
            id="Vector"
            d="M19.5,10.5a.5.5,0,0,1,.5.5v1a3,3,0,0,1-3,3,3,3,0,0,0-6,0H9a3,3,0,0,0-6,0,3,3,0,0,1-3-3V10A1,1,0,0,1,1,9h9.5A2.5,2.5,0,0,0,13,6.5V1a1,1,0,0,1,1-1h.84a2.016,2.016,0,0,1,1.74,1.01l.64,1.12A.252.252,0,0,1,17,2.5,2.5,2.5,0,0,0,14.5,5V8A2.5,2.5,0,0,0,17,10.5Z"
            transform="translate(750 193)"
            fill="#fff"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
            transform="translate(754 206)"
            fill="#fff"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
            transform="translate(762 206)"
            fill="#fff"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M4,3.53V5H1A1,1,0,0,1,0,4V1A1,1,0,0,1,1,0H2.29L3.74,2.54A2,2,0,0,1,4,3.53Z"
            transform="translate(766 197)"
            fill="#fff"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(748 188)"
            fill="none"
            opacity="0"
          />
          <g id="Group">
            <path
              id="Vector-6"
              data-name="Vector"
              d="M11.08,0H3.69A3.691,3.691,0,0,0,.07,2.98H4.44a.683.683,0,0,1,.68.69.674.674,0,0,1-.68.68H0V5.73H2.6a.691.691,0,0,1,.69.69.683.683,0,0,1-.69.68H0V8.48H.77a.691.691,0,0,1,.69.69.683.683,0,0,1-.69.68H0v.23a1,1,0,0,0,1,1h9.15A1.852,1.852,0,0,0,12,9.23V.92A.918.918,0,0,0,11.08,0Z"
              transform="translate(750 190)"
              fill="#fff"
            />
            <path
              id="Vector-7"
              data-name="Vector"
              d="M1.82,0H.69A.691.691,0,0,0,0,.69a.683.683,0,0,0,.69.68H1.75V.71A4.167,4.167,0,0,1,1.82,0Z"
              transform="translate(748.25 192.98)"
              fill="#fff"
            />
            <path
              id="Vector-8"
              data-name="Vector"
              d="M1.6,0H.69A.691.691,0,0,0,0,.69a.683.683,0,0,0,.69.68H1.75V0Z"
              transform="translate(748.25 195.73)"
              fill="#fff"
            />
            <path
              id="Vector-9"
              data-name="Vector"
              d="M1.6,0H.69A.691.691,0,0,0,0,.69a.683.683,0,0,0,.69.68H1.75V0Z"
              transform="translate(748.25 198.48)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const MenuUserConsigneeIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="vuesax_bold_truck"
        data-name="vuesax/bold/truck"
        transform="translate(-684 -188)"
      >
        <g id="truck">
          <path
            id="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(684 188)"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M12,.92V9.23a1.852,1.852,0,0,1-1.85,1.85H1a1,1,0,0,1-1-1V3.69A3.687,3.687,0,0,1,3.69,0h7.38A.92.92,0,0,1,12,.92Z"
            transform="translate(686 190)"
            fill="#fff"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M19.5,10.5a.5.5,0,0,1,.5.5v1a3,3,0,0,1-3,3,3,3,0,0,0-6,0H9a3,3,0,0,0-6,0,3,3,0,0,1-3-3V10A1,1,0,0,1,1,9h9.5A2.5,2.5,0,0,0,13,6.5V1a1,1,0,0,1,1-1h.84a2.016,2.016,0,0,1,1.74,1.01l.64,1.12A.252.252,0,0,1,17,2.5,2.5,2.5,0,0,0,14.5,5V8A2.5,2.5,0,0,0,17,10.5Z"
            transform="translate(686 193)"
            fill="#fff"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
            transform="translate(690 206)"
            fill="#fff"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
            transform="translate(698 206)"
            fill="#fff"
          />
          <path
            id="Vector-6"
            data-name="Vector"
            d="M4,3.53V5H1A1,1,0,0,1,0,4V1A1,1,0,0,1,1,0H2.29L3.74,2.54A2,2,0,0,1,4,3.53Z"
            transform="translate(702 197)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export const MenuUserEmployeeIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/user-octagon" transform="translate(-620 -188)">
        <g>
          <path
            fill="#fff"
            d="M16.59 3.85L10.65.42a3.163 3.163 0 00-3.15 0L1.57 3.85A3.149 3.149 0 000 6.58v6.84a3.174 3.174 0 001.57 2.73l5.94 3.43a3.163 3.163 0 003.15 0l5.94-3.43a3.149 3.149 0 001.57-2.73V6.58a3.192 3.192 0 00-1.58-2.73zM9.08 5.34a2.33 2.33 0 11-2.33 2.33 2.326 2.326 0 012.33-2.33zm2.68 9.32H6.4a1 1 0 01-.83-1.57 4.209 4.209 0 013.51-1.69 4.209 4.209 0 013.51 1.69 1.009 1.009 0 01-.83 1.57z"
            data-name="Vector"
            transform="translate(622.92 190)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuUserStaffIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="vuesax_bold_frame"
        data-name="vuesax/bold/frame"
        transform="translate(-108 -252)"
      >
        <g id="frame">
          <path
            id="Vector"
            d="M4.75,0a4.746,4.746,0,0,0-.12,9.49.807.807,0,0,1,.22,0h.07A4.746,4.746,0,0,0,4.75,0Z"
            transform="translate(115.25 254)"
            fill="#fff"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M12.12,1.395a9.929,9.929,0,0,0-10.15,0A3.947,3.947,0,0,0,0,4.625a3.914,3.914,0,0,0,1.96,3.21,9.239,9.239,0,0,0,5.08,1.41,9.239,9.239,0,0,0,5.08-1.41,3.945,3.945,0,0,0,1.96-3.23A3.937,3.937,0,0,0,12.12,1.395Z"
            transform="translate(112.96 264.755)"
            fill="#fff"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(132 276) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export const MenuCheckInIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 100 100"
    {...props}
  >
    <defs>
      <clipPath id="clip-check_in">
        <path d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-check_in)">
      <path
        d="m76.711 51.857-5.329-5.329a6.072 6.072 0 0 1-2.053-4.5 7.208 7.208 0 0 1 2.14-5.373l5.242-5.242c4.543-4.543 6.246-8.911 4.8-12.361-1.4-3.407-5.722-5.285-12.1-5.285h-45.64v-3.755a3.3 3.3 0 0 0-3.276-3.276 3.3 3.3 0 0 0-3.276 3.276v80.807a3.3 3.3 0 0 0 3.276 3.276 3.3 3.3 0 0 0 3.276-3.276V69.504h45.648c6.29 0 10.527-1.922 11.968-5.373s-.221-7.775-4.676-12.274Z"
        fill="#fff"
        data-name="vuesax/bold/flag"
      />
    </g>
  </svg>
);

export const MenuUserBlacklist = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 100 100"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
    <g fill="#fff" data-name="vuesax/bold/profile-delete" clipPath="url(#a)">
      <path d="M49.999 4.151a21.758 21.758 0 0 0-.55 43.511 3.7 3.7 0 0 1 1.009 0h.321a21.762 21.762 0 0 0-.78-43.511Z" />
      <path
        data-name="Vector"
        d="M73.291 59.861c-12.792-8.532-33.653-8.532-46.537 0-5.823 3.893-9.032 9.166-9.032 14.805s3.209 10.866 8.986 14.718c6.419 4.31 14.855 6.465 23.291 6.465s16.872-2.155 23.291-6.465c5.777-3.9 8.986-9.124 8.986-14.809-.045-5.64-3.208-10.867-8.985-14.714Zm-14.397 18.84a3.459 3.459 0 0 1 0 4.86 3.431 3.431 0 0 1-4.86 0l-4.035-4.035-4.034 4.035a3.431 3.431 0 0 1-4.86 0 3.459 3.459 0 0 1 0-4.86l4.035-4.035-4.035-4.035a3.437 3.437 0 0 1 4.86-4.86L50 69.806l4.035-4.035a3.437 3.437 0 0 1 4.86 4.86l-4.035 4.035Z"
      />
    </g>
  </svg>
);
