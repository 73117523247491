import {
  StepBackwardOutlined,
  StepForwardOutlined,
  PlayCircleFilled,
  CalendarFilled,
  PauseCircleFilled,
} from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import CButtonIcon from "components/button/c-button-icon";
import CSpace from "components/display/c-space";
import dayjs from "dayjs";
import { useCameraGroup } from "provider/all-video";
import { useAppLanguage } from "provider/app-language";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetEncodeDevice } from "services/encode-device";
import styled from "styled-components";

interface IResize {
  width?: string;
  height?: string;
}

const Wrapper = styled.div`
  height: calc(100vh - 243px);
`;

const CButtonIconResize = styled(CButtonIcon)<IResize>`
  border: 0px solid black;
  &:hover {
    border: 0px solid black;
  }
  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;

export const Playback = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const { jsDecoderPlayback, setJsDecoderPlayback, selected } =
    useCameraGroup();

  useEffect(() => {
    if (!ref.current) return;
    initPlayer();

    return () => {
      destroyPlayer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    play();

    return () => {
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected?.selectPeriod]);

  const play = () => {
    let timeOut = 0;
    if (!jsDecoderPlayback) return;
    const { beginTime, endTime } = selected?.selectPeriod || {};
    if (!beginTime || !endTime) return;
    if (playing) {
      stop();
      timeOut = 2000;
    }
    const start = beginTime?.substring(0, 19) + "Z";
    const end = endTime?.substring(0, 19) + "Z";
    setTimeout(() => {
      jsDecoderPlayback?.JS_Play(
        selected?.playerUrl,
        { playURL: selected?.playerUrl },
        0,
        start,
        end
      );
    }, timeOut);
    setPlaying(true);
  };

  const stop = () => {
    if (!jsDecoderPlayback) return;
    jsDecoderPlayback.JS_Stop(0);
    setPlaying(false);
  };

  const initPlayer = () => {
    let js = new window.JSPlugin({
      szId: "all-video-player",
      iType: 1,
      iWidth: ref.current!.clientWidth,
      iHeight: ref.current!.clientHeight,
      iMaxSplit: 1,
      iCurrentSplit: 1,
      szBasePath: process.env.PUBLIC_URL + "/dist",
      oStyle: {
        border: "tranparent",
        borderSelect: "tranparent",
        background: "#4C4B4B",
      },
    });
    setJsDecoderPlayback(js);
  };

  const destroyPlayer = () => {
    setJsDecoderPlayback(null);
  };

  return (
    <Wrapper>
      <div
        ref={ref}
        className="bg-black"
        style={{ height: "calc(100% - 63px)", width: "100%" }}
        id="all-video-player"
      ></div>
      <Controller playing={playing} setPlaying={(e) => setPlaying(e)} />
    </Wrapper>
  );
};

const Controller = ({
  playing,
  setPlaying,
}: {
  playing: boolean;
  setPlaying: (e: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { jsDecoderPlayback, selected, setSelected } = useCameraGroup();
  const { formatDate } = useAppLanguage();
  const get_encode_device = useGetEncodeDevice(selected?.encodeDeviceId);

  const pause = () => {
    if (!jsDecoderPlayback) return;
    jsDecoderPlayback.JS_Pause(0);
  };

  const resume = () => {
    if (!jsDecoderPlayback) return;
    jsDecoderPlayback.JS_Resume(0);
  };

  const slow = () => {
    if (!jsDecoderPlayback) return;
    jsDecoderPlayback.JS_Slow(0);
  };

  const fast = () => {
    if (!jsDecoderPlayback) return;
    jsDecoderPlayback.JS_Fast(0);
  };

  const stop = () => {
    if (!jsDecoderPlayback) return;
    setPlaying(false);
    jsDecoderPlayback.JS_Stop(0);
    setSelected({ ...selected, selectPeriod: undefined });
  };

  const onClickPausePlay = () => {
    if (!selected?.selectPeriod) return;
    playing ? pause() : resume();
    setPlaying(!playing);
  };

  const getDevName = () => {
    if (!get_encode_device.data?.devName) return t("please-select-camera");
    return get_encode_device.data?.devName;
  };
  const getDate = () => {
    if (!selected?.date) return t("please-select-camera");
    return formatDate({
      date: dayjs(selected?.date),
      format: "normal",
    });
  };

  const devName = getDevName();
  const date = getDate();

  return (
    <div className="bg-black p-4">
      <Row align="middle">
        <Col span={4}>
          <Typography.Text
            ellipsis={{ tooltip: devName }}
            className="!text-white"
          >
            {devName}
          </Typography.Text>
        </Col>
        <Col span={4} className="!flex justify-end items-center">
          <Typography.Text ellipsis={{ tooltip: date }} className="!text-white">
            {date}
          </Typography.Text>
          <CalendarFilled
            className="ml-2"
            hidden={!selected?.date}
            style={{ color: "white" }}
          />
        </Col>
        <Col span={8} className="text-center">
          <CSpace size={20}>
            <div className="w-[15px] h-[15px]"></div>
            <CButtonIconResize
              width="15px"
              height="15px"
              style={{ width: 25, height: 25 }}
              onClick={slow}
              icon={<StepBackwardOutlined />}
            />
            <CButtonIconResize
              width="35px"
              height="35px"
              style={{ width: 30, height: 30 }}
              onClick={onClickPausePlay}
              icon={playing ? <PauseCircleFilled /> : <PlayCircleFilled />}
            />
            <CButtonIconResize
              width="15px"
              height="15px"
              style={{ width: 25, height: 25 }}
              onClick={fast}
              icon={<StepForwardOutlined />}
            />
            <CButtonIconResize
              width="20px"
              height="20px"
              style={{ width: 20, height: 20 }}
              onClick={stop}
              icon={
                <svg>
                  <circle cx={10} cy={10} r={10} />
                  <rect
                    x={5}
                    y={5}
                    width={10}
                    height={10}
                    style={{ fill: "white" }}
                  />
                </svg>
              }
            />
          </CSpace>
        </Col>
        <Col span={8}></Col>
      </Row>
    </div>
  );
};
