import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IDoorLog, IDoorLogParams } from "./interface";

export const useGetDoorLogs = (
  params?: IDoorLogParams
): UseQueryResult<IResponse<IDoorLog[]>, Error> => {
  return useQuery(
    ["get-door-logs", params],
    async () => {
      const res = await axios.get("/door-log", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useExportDoorLog = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/door-log-export-excel`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
