import { Card, Typography } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const StyledCard = styled(Card)<{ $isActive: boolean }>`
  ${tw`w-[100px] !p-1 shadow-sm text-center`};
  ${({ $isActive }) =>
    $isActive
      ? tw`!bg-green-100 !border-green-500`
      : tw`!bg-red-100 !border-red-500`};

  span {
    ${({ $isActive }) => ($isActive ? tw`!text-green-500` : tw`!text-red-500`)};
  }
`;

export interface OnlineOfflineCardProps {
  status: "online" | "offline";
}

const OnlineOfflineCard: FC<OnlineOfflineCardProps> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <StyledCard
      bodyStyle={{ padding: 0, margin: "auto" }}
      $isActive={status === "online"}
    >
      <Typography.Text ellipsis={{ tooltip: t(status) }}>
        {t(status)}
      </Typography.Text>
    </StyledCard>
  );
};

export default OnlineOfflineCard;
