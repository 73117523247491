import { Row, Col } from "antd";
import CButton, { CButtonProps } from "components/button/c-button";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface CModalFooterProps {
  submit?: CButtonProps;
  cancel?: CButtonProps;
}

const CModalFooter: FC<CModalFooterProps> = ({ submit, cancel }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={12} justify="center" className="py-1">
      <Col md={8} lg={6}>
        <CButton
          style={{ height: 40 }}
          className="w-full"
          theme="white"
          {...cancel}
        >
          {cancel?.children || t("cancel")}
        </CButton>
      </Col>
      <Col md={8} lg={6}>
        <CButton
          style={{ height: 40 }}
          className="w-full"
          theme="yellow"
          {...submit}
        >
          {submit?.children || t("save")}
        </CButton>
      </Col>
    </Row>
  );
};

export default CModalFooter;
