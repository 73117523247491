import withParkingCameraProvider from "provider/vehicle-parking-camera";
import styled from "styled-components";
import VehicleParkingCameraContent from "./content";
import VehicleParkingCameraHeader from "./header";

const Wrapper = styled.div`
  height: calc(100vh - 104px);
`;

const VehicleParkingCamera = () => {
  return (
    <Wrapper className="p-5">
      <VehicleParkingCameraHeader />
      <VehicleParkingCameraContent />
    </Wrapper>
  );
};

export default withParkingCameraProvider(VehicleParkingCamera);
