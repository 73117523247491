import {
  MenuUserBlacklist,
  MenuUserConsigneeIcon,
  MenuUserContractorIcon,
  MenuUserEmployeeIcon,
  MenuUserGuestIcon,
  MenuUserPendingListIcon,
  MenuUserStaffIcon,
  MenuUserSupplierIcon,
} from "assets/icons/menu/user-management";
import UserManagementBlacklistPersonPage from "pages/user-management/blacklist-person";
import UserBlacklistPersonCreateAndEditPage from "pages/user-management/blacklist-person/create-and-edit";
import UserManagementCheckinPage from "pages/user-management/check-in";
import UserCompanyPage from "pages/user-management/company";
import UserCompanyCreateAndEditPage from "pages/user-management/company/create-and-edit";
import UserConsigneePage from "pages/user-management/consignee";
import UserConsigneeCreateAndEditPage from "pages/user-management/consignee/create-and-edit";
import UserContractorPage from "pages/user-management/contractor";
import UserContractorCreateAndEditPage from "pages/user-management/contractor/create-and-edit";
import UserEmployeePage from "pages/user-management/employee";
import UserEmployeeCreateAndEditPage from "pages/user-management/employee/create-and-edit";
import UserGuestPage from "pages/user-management/guest";
import UserGuestCreateAndEditPage from "pages/user-management/guest/create-and-edit";
import UserPendingListPage from "pages/user-management/pending-list";
import UserStaffPage from "pages/user-management/staff";
import UserStaffCreateAndEditPage from "pages/user-management/staff/create-and-edit";
import UserSupplierPage from "pages/user-management/supplier";
import UserSupplierCreateAndEditPage from "pages/user-management/supplier/create-and-edit";
import { IPageRoute, ISubMenu } from "./../interface";
import { TSubPageUserManagement } from "./interface";
import PendingGuestTypeEditPage from "pages/user-management/pending-list/edit-page/guest-type";
import PendingContructorTypeEditPage from "pages/user-management/pending-list/edit-page/contractor-type";
import { MenuAccessControlReport } from "assets/icons/menu/access-control";
import UserManagementReport from "pages/user-management/report";
import PendingReceiverTypeEditPage from "pages/user-management/pending-list/edit-page/receiver-type";
import PendingSupplierTypeEditPage from "pages/user-management/pending-list/edit-page/supplier-type";

export const USER_MANAGEMENT_MENUS: ISubMenu<TSubPageUserManagement>[] = [
  {
    key: "user-management.guest",
    title: "user-management.guest",
    icon: MenuUserGuestIcon,
    code: "020100",
  },
  {
    key: "user-management.contractor",
    title: "user-management.contractor",
    icon: MenuUserContractorIcon,
    code: "020200",
  },
  {
    key: "user-management.supplier",
    title: "user-management.supplier",
    icon: MenuUserSupplierIcon,
    code: "020300",
  },
  {
    key: "user-management.consignee",
    title: "user-management.consignee",
    icon: MenuUserConsigneeIcon,
    code: "020400",
  },
  {
    key: "user-management.check-in",
    title: "user-management.check-in",
    icon: MenuUserConsigneeIcon,
    code: "020600",
  },
  {
    key: "user-management.pending-list",
    title: "user-management.pending-list",
    icon: MenuUserPendingListIcon,
    code: "020700",
  },
  {
    key: "user-management.employee",
    title: "user-management.employee",
    icon: MenuUserEmployeeIcon,
    code: "020800",
  },
  {
    key: "user-management.staff",
    title: "user-management.staff",
    icon: MenuUserStaffIcon,
    code: "020900",
  },

  {
    key: "user-management.blacklist-person",
    title: "user-management.blacklist-person",
    icon: MenuUserBlacklist,
    code: "021000",
  },
  {
    key: "user-management.company",
    title: "user-management.company",
    icon: MenuUserPendingListIcon,
    code: "021100",
  },
  {
    key: "usre-management.report-list",
    title: "usre-management.report-list",
    icon: MenuAccessControlReport,
    code: "021200",
  },
];

export const USER_MANAGEMENT_ROUTES: IPageRoute<TSubPageUserManagement>[] = [
  {
    key: "user-management.guest",
    element: UserGuestPage,
  },
  {
    key: "user-management.guest-add",
    element: UserGuestCreateAndEditPage,
  },
  {
    key: "user-management.guest-edit",
    element: UserGuestCreateAndEditPage,
  },
  {
    key: "user-management.contractor",
    element: UserContractorPage,
  },
  {
    key: "user-management.contractor-add",
    element: UserContractorCreateAndEditPage,
  },
  {
    key: "user-management.contractor-edit",
    element: UserContractorCreateAndEditPage,
  },
  {
    key: "user-management.supplier",
    element: UserSupplierPage,
  },
  {
    key: "user-management.supplier-add",
    element: UserSupplierCreateAndEditPage,
  },
  {
    key: "user-management.supplier-edit",
    element: UserSupplierCreateAndEditPage,
  },
  {
    key: "user-management.consignee",
    element: UserConsigneePage,
  },
  {
    key: "user-management.consignee-add",
    element: UserConsigneeCreateAndEditPage,
  },
  {
    key: "user-management.consignee-edit",
    element: UserConsigneeCreateAndEditPage,
  },
  {
    key: "user-management.employee",
    element: UserEmployeePage,
  },
  {
    key: "user-management.employee-add",
    element: UserEmployeeCreateAndEditPage,
  },
  {
    key: "user-management.employee-edit",
    element: UserEmployeeCreateAndEditPage,
  },
  {
    key: "user-management.staff",
    element: UserStaffPage,
  },
  {
    key: "user-management.staff-add",
    element: UserStaffCreateAndEditPage,
  },
  {
    key: "user-management.staff-edit",
    element: UserStaffCreateAndEditPage,
  },
  {
    key: "user-management.pending-list",
    element: UserPendingListPage,
  },
  {
    key: "user-management.check-in",
    element: UserManagementCheckinPage,
  },
  {
    key: "user-management.blacklist-person",
    element: UserManagementBlacklistPersonPage,
  },
  {
    key: "user-management.blacklist-person-add",
    element: UserBlacklistPersonCreateAndEditPage,
  },
  {
    key: "user-management.blacklist-person-edit",
    element: UserBlacklistPersonCreateAndEditPage,
  },
  {
    key: "user-management.company",
    element: UserCompanyPage,
  },
  {
    key: "user-management.company-add",
    element: UserCompanyCreateAndEditPage,
  },
  {
    key: "user-management.company-edit",
    element: UserCompanyCreateAndEditPage,
  },
  {
    key: "usre-management.pending-guest-edit",
    element: PendingGuestTypeEditPage,
  },
  {
    key: "usre-management.pending-contractor-edit",
    element: PendingContructorTypeEditPage,
  },
  {
    key: "usre-management.pending-receiver-edit",
    element: PendingReceiverTypeEditPage,
  },
  {
    key: "usre-management.pending-supplier-edit",
    element: PendingSupplierTypeEditPage,
  },
  {
    key: "usre-management.report-list",
    element: UserManagementReport,
  },
];
