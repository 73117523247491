import dayjs from "config/dayjs";
import { ICameraGroup } from "services/camera-group/interface";
// import crypto from "crypto";

export const setCameraSelectToLocal = (cameraGroup: ICameraGroup[]) => {
  const str = JSON.stringify(cameraGroup);
  localStorage.setItem("cameraGroup", str);
};

export const getCameraSelectFromLocal = (): ICameraGroup[] => {
  const str = localStorage.getItem("cameraGroup");
  if (!str) return [];
  const arr = JSON.parse(str);
  return arr;
};

export const splitRangePicker = ({
  arrays,
  startKey,
  endKey,
}: {
  arrays?: dayjs.Dayjs[];
  startKey: string;
  endKey: string;
}): object => {
  if (!arrays) return { [startKey]: undefined, [endKey]: undefined };
  const start = arrays[0].startOf("date");
  const end = arrays[1].endOf("date");
  return {
    [startKey]: start.format("YYYY-MM-DD HH:mm"),
    [endKey]: end.format("YYYY-MM-DD HH:mm"),
  };
};

export const percentage = (
  value?: number,
  maximum?: number,
  decimal: number = 2
): number => {
  if (value === 0 || maximum === 0) return 0;
  if (!value || !maximum) return 0;
  return Number(((100 * value) / maximum).toFixed(decimal));
};

// export const getUUID = () => {
//   return crypto.randomBytes(20).toString("hex");
// };
