import { TSubPageVehicleInSpection } from "./interface";
import { IPageRoute, ISubMenu } from "router/interface";
import VehicleInspectionInSpectionPage from "pages/vehicle-inspection/vehicle-inspection";
import VehicleInspectionOverviewPage from "pages/vehicle-inspection/overview";
import VehicleInspectionSprayCheckPage from "pages/vehicle-inspection/spray-check";
import BrokenCarPage from "pages/vehicle-inspection/broken-car";
import CheckQuickLabPage from "pages/vehicle-inspection/check-quick-lab";
import StatusQueueCarPage from "pages/vehicle-inspection/status-queue-car";
import CheckQuickLabEdit from "pages/vehicle-inspection/check-quick-lab/check-quick-lab-edit";
import VehicleInspectionFailedInspectionPage from "pages/vehicle-inspection/failed-inspection-vehicle";
import {
  MenuInspectionBrokenVehicleIcon,
  MenuInspectionFailedIcon,
  // MenuInspectionOverviewIcon,
  MenuInspectionQueueIcon,
  MenuInspectionQuickLabIcon,
  MenuInspectionSprayCheckIcon,
  MenuInspectionVehicleIcon,
} from "assets/icons/menu/vehicle-inspection";
import StatusQueueCarDetail from "pages/vehicle-inspection/status-queue-car/status-queue-car-detail.tsx";
import VehicleInspectionReport from "pages/vehicle-inspection/vehicle-inspection-report";
import BrokenCarDetailPage from "pages/vehicle-inspection/broken-car/broken-car-edit";
import { MenuAccessControlReport } from "assets/icons/menu/access-control";

export const VEHICLE_INSPECTION_MENUS: ISubMenu<TSubPageVehicleInSpection>[] = [
  // {
  //   key: "vehicle-inspection.overview",
  //   title: "vehicle-inspection.overview",
  //   code: "",
  //   icon: MenuInspectionOverviewIcon,
  // },
  {
    key: "vehicle-inspection.spray-check",
    title: "vehicle-inspection.spray-check",
    code: "030100",
    icon: MenuInspectionSprayCheckIcon,
  },
  {
    key: "vehicle-inspection.vehicle-inspection",
    title: "vehicle-inspection.vehicle-inspection",
    code: "030200",
    icon: MenuInspectionVehicleIcon,
  },
  {
    key: "vehicle-inspection.failed-inspection-vehicle",
    title: "vehicle-inspection.failed-inspection-vehicle",
    code: "030300",
    icon: MenuInspectionFailedIcon,
  },
  {
    key: "vehicle-inspection.check-quick-lab",
    title: "vehicle-inspection.check-quick-lab",
    code: "030400",
    icon: MenuInspectionQuickLabIcon,
  },
  {
    key: "vehicle-inspection.status-queue-car",
    title: "vehicle-inspection.status-queue-car",
    code: "030500",
    icon: MenuInspectionQueueIcon,
  },
  {
    key: "vehicle-inspection.broken-car",
    title: "vehicle-inspection.broken-car",
    code: "030600",
    icon: MenuInspectionBrokenVehicleIcon,
  },
  {
    key: "vehicle-inspection.report",
    title: "vehicle-inspection.report",
    code: "030700",
    icon: MenuAccessControlReport,
  },
];

export const VEHICLE_INSPECTION_ROUTES: IPageRoute<TSubPageVehicleInSpection>[] =
  [
    {
      key: "vehicle-inspection.overview",
      element: VehicleInspectionOverviewPage,
    },
    {
      key: "vehicle-inspection.spray-check",
      element: VehicleInspectionSprayCheckPage,
    },
    {
      key: "vehicle-inspection.failed-inspection-vehicle",
      element: VehicleInspectionFailedInspectionPage,
    },
    {
      key: "vehicle-inspection.vehicle-inspection",
      element: VehicleInspectionInSpectionPage,
    },
    {
      key: "vehicle-inspection.check-quick-lab",
      element: CheckQuickLabPage,
    },
    {
      key: "vehicle-inspection.check-quick-lab-edit",
      element: CheckQuickLabEdit,
    },
    {
      key: "vehicle-inspection.status-queue-car",
      element: StatusQueueCarPage,
    },
    {
      key: "vehicle-inspection.status-queue-car-detail",
      element: StatusQueueCarDetail,
    },
    {
      key: "vehicle-inspection.broken-car",
      element: BrokenCarPage,
    },
    {
      key: "vehicle-inspection.broken-car-detail",
      element: BrokenCarDetailPage,
    },
    {
      key: "vehicle-inspection.report",
      element: VehicleInspectionReport,
    },
  ];
