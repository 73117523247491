import { CSSProperties, FC, ReactNode } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div<{ $active?: boolean }>`
  border: 1px solid #e4e4e4;
  ${tw`bg-white border rounded-app transition-all cursor-pointer `};
  ${({ $active }) => ($active ? tw`border-primary-dark` : tw`border-gray-200`)}
  .title {
    ${({ $active }) => ($active ? tw`!text-white` : "")};
  }
  .oblique {
    ${tw`transition-all`};
    ${({ $active }) => ($active ? tw`bg-primary-dark` : tw`bg-gray-100`)};
    width: 65%;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
  }

  &:hover {
    background: #f5f5f5;
  }

  &:focus {
  }
`;

export interface BasicCardProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  active?: boolean;
  onClick?: () => void;
}

const BasicCard: FC<BasicCardProps> = ({
  children,
  className,
  style,
  active,
  onClick,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      className={`shadow ${className}`}
      style={style}
      $active={active}
    >
      {children}
    </Wrapper>
  );
};

export default BasicCard;
