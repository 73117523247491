import RouteContent from "components/layout/route-content";
import { useAppRoleMenu } from "provider/app-role-menu";
import withPageRoute, { usePageRoute } from "provider/page-route";

const VehicleInspectionPage = () => {
  const { getMenuByKey } = useAppRoleMenu();
  const { getCurrentElement } = usePageRoute();

  const element = getCurrentElement();
  const { menus, initial } = getMenuByKey("vehicle-inspection");

  return (
    <RouteContent menus={menus} initialPage={initial}>
      {element}
    </RouteContent>
  );
};

export default withPageRoute(VehicleInspectionPage, "vehicle-inspection");
