import { Form, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import CCard from "components/display/card";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteSettingQuickLap,
  useDeleteSettingQuickLaps,
  useGetSettingQuickLaps,
} from "services/setting-parameter-of-quick-lap";
import {
  ISettingQuickLap,
  ISettingQuickLapParams,
} from "services/setting-parameter-of-quick-lap/interface";
import POQCreateAndEditDrawerProps from "./drawer";

const ParameterOfQuickLap = () => {
  const { t } = useTranslation();
  // const {visible,toggle} = useParameter();
  const [visible, setVisible] = useState<boolean>(false);

  const toggle = () => {
    setVisible(!visible);
  };

  const [params, setParams] = useState<ISettingQuickLapParams>({
    page: 1,
    limit: 10,
  });
  const [form] = Form.useForm();
  const data = useGetSettingQuickLaps(params);
  const { formatDate, translationObject } = useAppLanguage();
  const delete_data = useDeleteSettingQuickLap();
  const delete_datas = useDeleteSettingQuickLaps();

  const elements: ISearchBoxElement[] = [
    {
      name: "parameterName",
      label: t("list-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },

    {
      name: "createdAt",
      label: t("created-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "updatedAt",
      label: t("updated-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "isActive",
      label: t("status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              { key: true, value: t("active") },
              { key: false, value: t("in-active") },
            ],
            valueKey: "key",
            titleKey: "value",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<ISettingQuickLap> = [
    {
      title: t("list-name"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const { parameterName } = translationObject({
          object: rc,
          keys: ["parameterName"],
        });
        return (
          <Typography.Text ellipsis={{ tooltip: parameterName }}>
            {parameterName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      render: (_, rc) => {
        if (!rc.createdAt) return <span>-</span>;
        const date = formatDate({ date: rc.createdAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      render: (_, rc) => {
        if (!rc.updatedAt) return <span>-</span>;
        const date = formatDate({ date: rc.updatedAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "isActive",
      width: "20%",
      render: (status: true | false) => {
        return (
          <CCard.ActiveInActive
            status={status === true ? "active" : "in-active"}
          />
        );
      },
    },
  ];

  const onSearch = (FormItem: any) => {
    setParams({
      ...params,
      ...FormItem,
      startUpdatedAt: FormItem["updatedAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endUpdatedAt: FormItem["updatedAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
      startCreatedAt: FormItem["createdAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endCreatedAt: FormItem["createdAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
    });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        rowKey="id"
        onAdd={{
          onClick: () => {
            toggle();
          },
        }}
        onRowClick={(dataSource: any) => {
          toggle();
          form.setFieldsValue(dataSource);
        }}
        columns={columns}
        query={data}
        action={{
          bulkDelete: {
            mutation: delete_datas,
            invalidateQueries: ["get-qucik-laps"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: delete_data,
          invalidateQueries: ["get-qucik-laps"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        title={t("parameter-of-quick-lap-list")}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
      <POQCreateAndEditDrawerProps
        visible={visible}
        onClose={toggle}
        form={form}
      />
    </PageContent>
  );
};
export default ParameterOfQuickLap;
