import { Badge, Col, Divider, Image, Row, Typography } from "antd";
import CCard from "components/display/card";
import Container from "components/display/container";
import { useAppLanguage } from "provider/app-language";
import { useVehicleSocket } from "provider/vehicle-socket";
import { IReceiveVehicleSocket } from "provider/vehicle-socket/interface";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type TransitionTypes = "entrance" | "departure";
interface CaptureCardProps extends IReceiveVehicleSocket {
  type: TransitionTypes;
}

const TransitionCaptureList: FC<{ type: TransitionTypes }> = ({ type }) => {
  const { receives, selected } = useVehicleSocket();

  if (!selected) {
    return (
      <Container height={500} className="center">
        <CCard.EmptySelectVideo style={{ height: 340 }} type="empty" />
      </Container>
    );
  }

  if (receives.length <= 0) {
    return (
      <Container height={500} className="center">
        <CCard.EmptySelectVideo style={{ height: 340 }} type="wait" />
      </Container>
    );
  }

  return (
    <Container height={500} scrollY>
      <Row gutter={[12, 12]}>
        {receives.map((item) => {
          return (
            <Col span={4} key={item.id}>
              <CaptureCard {...item} type={type} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

const CaptureCard: FC<CaptureCardProps> = ({ type, dateTime, createdAt, vehicleType, ...props }) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  return (
    <Container height="auto" padding="p-3" className="!bg-gray-100">
      <Row align="middle" gutter={[6, 6]}>
        <Col span={24}>
          <Image
            src={props.licensePlateImage?.url}
            className="rounded-app"
            width="100%"
            height={70}
            style={{ objectFit: "cover" }}
            preview={false}
          />
        </Col>
        <Col span={12}>
          <Typography.Title level={5}>{props.licensePlate}</Typography.Title>
        </Col>
        <Col className="ml-auto">
          <Badge color="#2BB53B" /> <span>{t(type)}</span>
        </Col>
        <Divider className="!my-3" />
        <Description left={t("time")} right={formatDate({date: createdAt, format: 'normal-time'})} />
        {/* <Description left={t("exit-time")} right="01 Jan 1996 09:00:00" /> */}
        <Description left={t("type")} right={vehicleType} />
      </Row>
    </Container>
  );
};

const Description: FC<{ left: string; right?: string }> = ({ left, right }) => {
  return (
    <React.Fragment>
      <Col span={8}>
        <Typography.Text style={{ fontSize: 12 }} ellipsis>
          {left} :{" "}
        </Typography.Text>
      </Col>
      <Col span={16}>
        <Typography.Text style={{ fontSize: 12 }} ellipsis>
          {right || "-"}
        </Typography.Text>
      </Col>
    </React.Fragment>
  );
};

export default TransitionCaptureList;
