import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import withCheckQuickLab, { useCheckQuickLab } from "provider/check-quick-lab";
import { KeyStatus } from "provider/check-quick-lab/interface";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CheckQuickLabContent from "./content";

const statusQuickLab = [
  { id: 1, keyStatus: "WAITING_FOR_INSPECT", label: "inspect" },
  { id: 2, keyStatus: "PASSED_THE_INSPECTION", label: "pass" },
  { id: 3, keyStatus: "FAILED_THE_INSPECTION", label: "not-pass" },
  { id: 4, keyStatus: "VIP", label: "conditionally-passed" },
];

const CheckQuickLabPage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <SideBar />
        </Col>
        <Col span={18} className="px-5 pt-5">
          <CheckQuickLabContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const SideBar = () => {
  const { t } = useTranslation();
  const { keyStatus, setKeyStatus, numberOfItems } = useCheckQuickLab();

  useEffect(() => {
    setKeyStatus(statusQuickLab[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = statusQuickLab.map((item) => {
    const number = numberOfItems[item.keyStatus as KeyStatus];
    return (
      <CCard.SlashHeader
        active={keyStatus === item}
        className="mb-4"
        title={t(item.label)}
        key={item.id}
        onClick={() => {
          setKeyStatus(item);
        }}
      >
        <Row
          align="middle"
          className="px-3 py-2"
          style={{ height: 86 }}
          gutter={12}
        >
          <Col>
            <Typography.Text type="secondary" ellipsis={{ tooltip: t("list") }}>
              {t("list")} :
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{`${number || 0} ${t("list")}`}</Typography.Text>
          </Col>
        </Row>
      </CCard.SlashHeader>
    );
  });

  return (
    <React.Fragment>
      <InfiniteSidebar title={t("ckeck-quick-lab")} content={content} />
    </React.Fragment>
  );
};

export default withCheckQuickLab(CheckQuickLabPage);
