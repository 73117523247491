import { TableProps } from "antd";
import { FC } from "react";
import { StyledTable } from "./styled-table";

export interface SelectTableProps
  extends Omit<TableProps<any>, "onChange" | "value" | "rowSelection"> {
  onChange?: (value: any[]) => void;
  value?: any[];
  type?: "checkbox" | "radio";
  checkStrictly?: boolean;
}

const SelectTable: FC<SelectTableProps> = ({
  onChange,
  value,
  type,
  checkStrictly,
  ...rest
}) => {
  return (
    <StyledTable
      {...rest}
      rowSelection={{
        type: type,
        checkStrictly: checkStrictly,
        onChange: onChange,
        selectedRowKeys: value,
        fixed: "left",
        columnWidth: 50,
      }}
    />
  );
};

export default SelectTable;
