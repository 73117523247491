import { createContext, ReactNode, useContext, useState } from "react";
import { ISearchReport, IUserReportContext, TPersonReport } from "./interface";

const Context = createContext<IUserReportContext>({} as IUserReportContext);

export const userKey = [
  "identityCode",
  "date",
  "name",
  "phoneNumber",
  "licensePlateNumber",
  "companyName",
  "registerType",
  "userType",
  "email",
  "position",
  "licensePlateNumber",
  "vehicleBrand",
  "vehicleModel",
  "address",
  "identityCardNumber",
  "startDate",
  "identityCode",
  "identificationNumber",
  "prefix",
  "cardCode",
  "department.titleTh",
];

const UserReportProvider = ({ children }: { children: ReactNode }) => {
  const [columnsKey, setColumnsKey] = useState<string[]>(userKey);
  const [personType, setPersonType] = useState<TPersonReport>("CUSTOMER");
  const [search, setSearch] = useState<ISearchReport>({});

  return (
    <Context.Provider
      value={{
        columnsKey,
        setColumnsKey,
        personType,
        setPersonType,
        search,
        setSearch,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useUserReport = () => useContext(Context);
export default UserReportProvider;
