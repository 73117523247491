import { IEmployee, IEmployeeParams } from "./interface";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { postMediaObject } from "services/media-object";
import { IMediaObject } from "services/media-object/interface";

export const useGetEmployees = (
  params?: IEmployeeParams
): UseQueryResult<IResponse<IEmployee[]>, Error> => {
  return useQuery(
    ["get-employees", params],
    async () => {
      const res = await axios.get("/employee", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetEmployee = (
  id?: string
): UseQueryResult<IEmployee, Error> => {
  return useQuery(
    ["get-employee", id],
    async () => {
      const res = await axios.get(`/employee/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostEmployee = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ ...data }: any) => {
    data = await appendMediaObjects(data);
    const res = await axios.post(`/employee`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchEmployee = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    data = await appendMediaObjects(data);
    const res = await axios.patch(`/employee/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteEmployee = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/employee/${id}`);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteEmployees = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/employee/bulk`, { data });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useScrollGetEmployees = (
  params?: IEmployeeParams
): UseInfiniteQueryResult<IResponse<IEmployee[]>> => {
  return useInfiniteQuery(
    ["get-employees", params],
    async ({ pageParam }) => {
      const res = await axios.get("/employee", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        const data = res.data.data.map((item: any) => {
          return { ...item, fullName: `${item.firstName} ${item.lastName}` };
        });
        res.data = { ...res.data, data };
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

const appendMediaObjects = async ({ profile, vehicles, ...rest }: any) => {
  if (typeof profile === "object") {
    profile = await postMediaObject(profile);
    rest = { ...rest, profile };
  }
  // if (vehicleImage && vehicleImage.length) {
  //   vehicleImage = await Promise.all(
  //     vehicleImage.map(async (item: File | IMediaObject) => {
  //       if ("id" in item) return item.id;
  //       const id = await postMediaObject(item);
  //       return id;
  //     })
  //   );
  //   rest = { ...rest, vehicleImage };
  // }
  // if (!vehicleImage) {
  //   rest = { ...rest, vehicleImage: [] };
  // }
  if (vehicles) {
    vehicles = await Promise.all(
      vehicles.map(async (item: any) => {
        if (item.vehicleImage) {
          const vehicleImageData = await Promise.all(
            item.vehicleImage.map(async (e: File | IMediaObject) => {
              if ("id" in e) return e.id;
              const id = await postMediaObject(e);
              return id;
            })
          );
          return { ...item, vehicleImage: vehicleImageData };
        }
        return { ...item, vehicleImage: [] };
      })
    );
    rest = { ...rest, vehicles };
  }
  return rest;
};

export const useExportTemplateEmployee = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/template/employee`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportExcelEmployee = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/employee/import/employee`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportProfileEmployee = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    data = await appendImages(data);
    const res = await axios.post("/employee/upload-profile", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

const appendImages = async (values: any): Promise<any> => {
  let { profiles, ...rest } = values || {};

  if (profiles?.length < 1) {
    rest = { ...rest, profiles: [] };
  }

  if (profiles && profiles?.length > 0) {
    const itemPicturesList: number[] = await Promise.all(
      profiles.map(async (item: any): Promise<number> => {
        let id: number;
        if (!item.id) {
          id = await postMediaObject(item);
        } else {
          id = item.id;
        }
        return id;
      })
    );
    rest = { ...rest, profiles: itemPicturesList };
  }

  return rest;
};
