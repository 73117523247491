import { Row, Col, Typography, Image } from "antd";
import CCard from "components/display/card";
import Container from "components/display/container";
import { useVehicleSocket } from "provider/vehicle-socket";
import React from "react";
import { useTranslation } from "react-i18next";

const TransitionImageCapture = () => {
  const { t } = useTranslation();

  return (
    <Container height={280}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Typography.Text>{t("image-capture")}</Typography.Text>
        </Col>
        <Col span={24}>
          <Row gutter={12}>
            <Col span={8}>
              <VehicleCapture />
            </Col>
            <Col span={8}>
              <FaceTruckCapture />
            </Col>
            <Col span={8}>
              <FaceGeneralCapture />
            </Col>
          </Row>
        </Col>
        <Col span={24} className="!flex items-center"></Col>
      </Row>
    </Container>
  );
};

const FaceGeneralCapture = () => {
  const { t } = useTranslation();
  const { selected, person, face } = useVehicleSocket();

  if (!selected) {
    return <CCard.EmptySelectVideo style={{ height: 200 }} type="empty" />;
  }

  if (!face) {
    return <CCard.EmptySelectVideo style={{ height: 200 }} type="wait" />;
  }

  // if (selected.generalFace?.encodeDevice?.id !== person.encodeDevice?.id)
  //   return null;

  return (
    <React.Fragment>
      <Image
        className="rounded-app"
        style={{ objectFit: "cover" }}
        preview={false}
        width="100%"
        src={face?.url}
        height={160}
      />
      <Typography.Text
        className="text-center block mt-4"
        style={{ fontSize: 12 }}
      >
        {t("face-picture")} ({t("general")})
      </Typography.Text>
    </React.Fragment>
  );
};

const FaceTruckCapture = () => {
  const { t } = useTranslation();
  const { selected, person } = useVehicleSocket();

  if (!selected) {
    return <CCard.EmptySelectVideo style={{ height: 200 }} type="empty" />;
  }

  if (!person) {
    return <CCard.EmptySelectVideo style={{ height: 200 }} type="wait" />;
  }

  if (selected.truckFace?.encodeDevice?.id !== person.encodeDevice?.id)
    return null;

  return (
    <React.Fragment>
      <Image
        className="rounded-app"
        style={{ objectFit: "cover" }}
        preview={false}
        width="100%"
        src={person.faceImages?.url}
        height={160}
      />
      <Typography.Text
        className="text-center block mt-4"
        style={{ fontSize: 12 }}
      >
        {t("face-picture")} ({t("truck")})
      </Typography.Text>
    </React.Fragment>
  );
};

const VehicleCapture = () => {
  const { t } = useTranslation();
  const { selected, receives } = useVehicleSocket();

  if (!selected) {
    return <CCard.EmptySelectVideo style={{ height: 200 }} type="empty" />;
  }

  if (receives.length <= 0) {
    return <CCard.EmptySelectVideo style={{ height: 200 }} type="wait" />;
  }

  return (
    <React.Fragment>
      <Image
        className="rounded-app"
        style={{ objectFit: "cover" }}
        preview={false}
        width="100%"
        src={receives?.[0].detectImage?.url}
        height={160}
      />
      <Typography.Text
        className="text-center block mt-4"
        style={{ fontSize: 12 }}
      >
        {t("vehicle-picture")}
      </Typography.Text>
    </React.Fragment>
  );
};

export default TransitionImageCapture;
