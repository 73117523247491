import { Col, Typography, Form } from "antd";
import CInput from "components/input/c-input";
import CTextArea from "components/input/c-input/text-area";
import CSelect from "components/input/c-select";
import React from "react";
import { useTranslation } from "react-i18next";

const EmployeeField = ({ pending }: { pending: boolean }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={24} className="mb-2">
        <Typography.Text className="!text-primary">
          {t("driver-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="firstName"
          label={`${t("first-name")}`}
        >
          <CInput placeholder={`${t("first-name")}`} disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="lastName"
          label={`${t("last-name")}`}
        >
          <CInput placeholder={`${t("last-name")}`} disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="identityCode"
          label={t("identity-number-receiver")}
        >
          <CInput placeholder={t("identity-number-receiver")} disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="phoneNumber"
          label={t("phone-number")}
        >
          <CInput placeholder={t("phone-number")} disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("gender")} name="gender">
          <CSelect.Gender disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="poNumber"
          rules={[{ required: true }]}
          label={t("po-number")}
        >
          <CInput placeholder={t("po-number")} disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="numberOfBag"
          label={t("amount")}
          rules={[
            {
              pattern: new RegExp(/^[0-9]*$/),
              message: t("number-format-is-invalid", { ns: "message" }),
            },
          ]}
        >
          <CInput placeholder={t("amount")} suffix="Bag" disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="numberOfBluk"
          label={<label style={{ color: "#ffffff00" }}>{t("blank")}</label>}
          rules={[
            {
              pattern: new RegExp(/^[0-9]*$/),
              message: t("number-format-is-invalid", { ns: "message" }),
            },
          ]}
        >
          <CInput suffix="Bulk" disabled={pending} placeholder={t("amount")} />
        </Form.Item>
      </Col>
      <Col span={8}></Col>
      <Col span={24}>
        <Form.Item name="description" label={t("note")}>
          <CTextArea rows={5} placeholder={t("note")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default EmployeeField;
