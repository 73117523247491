import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { IMediaObject } from "services/media-object/interface";
import styled from "styled-components";
import tw from "twin.macro";
import CImage from "./c-image";

interface LicensePlateImageProps {
  value?: IMediaObject;
}

const LicensePlateImageWrapper = styled.div`
  height: 200px;
  border: 1px solid;
  ${tw`border-primary-dark rounded-app`};
`;
const LicensePlateImage: React.FC<LicensePlateImageProps> = ({ value }) => {
  const { t } = useTranslation();
  if (!value) {
    return (
      <LicensePlateImageWrapper className="center">
        <Typography.Text>
          {t("license-plate-image-not-found", { ns: "message" })}
        </Typography.Text>
      </LicensePlateImageWrapper>
    );
  }

  return (
    <LicensePlateImageWrapper className="p-3">
      <CImage src={value.url} />
    </LicensePlateImageWrapper>
  );
};

export default LicensePlateImage;
