import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { FC } from "react";
import { CSSProperties } from "react";

interface CIndicatorProps {
  hidden?: boolean;
  style?: CSSProperties;
  size?: number;
  height?: string | number;
  className?: string;
  spinning?: boolean;
}

const CIndicator: FC<CIndicatorProps> = ({
  size = 30,
  height = "100%",
  hidden,
  className,
  style,
  spinning = true,
}) => {
  
  if (hidden) return null;

  return (
    <div
      className={`center ${className}`}
      style={{ ...style, height }}
    >
      <Spin
        spinning={spinning}
        indicator={
          <LoadingOutlined className="!text-yellow" style={{ fontSize: size }} />
        }
      />
    </div>
  );
};

export default CIndicator;
