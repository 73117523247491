import { Upload, UploadProps } from "antd";
import { FC } from "react";
import styled from "styled-components";
import UploadAvatar, { UploadAvatarProps } from "./avatar";
import CDragger, { UploadAreaProps } from "./area";
import CUploadList, { CUploadListProps } from "./list";
import CUploadCapture, { CUploadCaptureProps } from "./capture";

const StyledUpload = styled(Upload)``;

export type AcceptFileTypes = ".pdf" | ".jpeg" | ".jpg" | ".png" | ".xlsx";
type NotationTypes = {
  Avatar: FC<UploadAvatarProps>;
  Area: FC<UploadAreaProps>;
  List: FC<CUploadListProps>;
  Capture: FC<CUploadCaptureProps>;
};

const CUpload: FC<UploadProps> & NotationTypes = (props) => {
  return <StyledUpload {...props} />;
};

CUpload.Avatar = UploadAvatar;
CUpload.Area = CDragger;
CUpload.List = CUploadList;
CUpload.Capture = CUploadCapture;

export default CUpload;
