import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import { useAppLanguage } from "provider/app-language";
import { useUserReport } from "provider/user-report";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportBlacklistReport,
  useGetBlacklistReport,
} from "services/users-report";
import {
  IBlacklistReport,
  IUsersReportParams,
} from "services/users-report/interface";

const UserManagementBlacklistReport = () => {
  const { t } = useTranslation();
  const { columnsKey, search } = useUserReport();
  const { formatDate } = useAppLanguage();

  const [params, setParams] = useState<IUsersReportParams>({});
  const data_blacklist = useGetBlacklistReport({
    ...params,
    identityCardNumber: search?.identityCardNumber,
    name: search?.name,
    phoneNumber: search?.phoneNumber,
    email: search?.email,
    address: search?.address,
    startDate: search?.startDate,
    endDate: search?.endDate,
  });

  const export_blacklist = useExportBlacklistReport();

  const columns: ColumnsType<IBlacklistReport> = [
    {
      key: "date",
      title: t("entry-date"),
      render: (_, rc) => {
        const date = formatDate({
          date: rc?.createdAt,
          format: "nomal-full-time",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      key: "name",
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      key: "identityCardNumber",
      title: t("identification-card-number"),
      render: (_, rc) => {
        const identityCode = rc?.identityCardNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: identityCode }}>
            {identityCode}
          </Typography.Text>
        );
      },
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
      render: (_, rc) => {
        const phone = rc.phoneNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: phone }}>
            {phone}
          </Typography.Text>
        );
      },
    },
    {
      key: "address",
      title: t("address"),
      render: (_, rc) => {
        const address = rc?.address || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: address }}>
            {address}
          </Typography.Text>
        );
      },
    },
    {
      key: "email",
      title: t("email"),
      render: (_, rc) => {
        const email = rc?.email || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: email }}>
            {email}
          </Typography.Text>
        );
      },
    },
  ];

  const _columns = columns.filter((item) => {
    return columnsKey.some((e) => e === item.key);
  });

  const topic = _columns
    .map((i) => {
      if (i?.key === "profile") return "backlistPresonImage.url";
      if (i?.key === "date") return "createdAt";
      if (i?.key === "name") return ["firstName", "lastName"];
      return i?.key;
    })
    .flat();

  return (
    <CTable
      scroll={{ x: 1100 }}
      selectAble={false}
      title={t("list-all")}
      query={data_blacklist}
      loading={data_blacklist?.isFetching}
      columns={_columns}
      pagination={{
        onChange: (page, limit) => {
          setParams({ ...params, page, limit });
        },
      }}
      action={{
        excels: {
          export: {
            mutation: export_blacklist,
            extraValue: { topic: topic, userType: search?.userType },
            notShowModal: true,
          },
        },
      }}
    />
  );
};

export default UserManagementBlacklistReport;
