import { TableColumnsType, Typography } from "antd";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import BlockColor from "components/display/card/block-color";
import { useAppLanguage } from "provider/app-language";
import { useUserReport } from "provider/user-report";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportCustomerReport,
  useGetCustomersReport,
} from "services/users-report";
import {
  ICustomerReport,
  IUsersReportParams,
} from "services/users-report/interface";

const UserManagementCustomerReport = () => {
  const { t } = useTranslation();
  const { columnsKey, search } = useUserReport();
  const { formatDate } = useAppLanguage();

  const [params, setParams] = useState<IUsersReportParams>({
    page: 1,
    limit: 10,
  });

  const data_userReport = useGetCustomersReport({
    ...params,
    name: search?.name,
    registerType: search?.registerType,
    userType: search?.userType,
    companyName: search?.companyName,
    phoneNumber: search?.phoneNumber,
    startDate: search?.startDate,
    endDate: search?.endDate,
  });

  const export_customer = useExportCustomerReport();


  const columns: TableColumnsType<ICustomerReport> = [
    {
      key: "date",
      title: t("entry-date"),
      render: (_, rc) => {
        const date = formatDate({
          date: rc.createdAt,
          format: "nomal-full-time",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      key: "identityCode",
      title: t("id"),
      render: (_, rc) => {
        if (!rc?.identityCode) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.identityCode }}>
            {rc?.identityCode}
          </Typography.Text>
        );
      },
    },
    {
      key: "name",
      title: t("full-name"),
      ellipsis: true,
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
      render: (_, rc) => {
        const phone = rc.phoneNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: phone }}>
            {phone}
          </Typography.Text>
        );
      },
    },
    {
      key: "companyName",
      title: t("company-or-shipping"),
      render: (_, rc) => {
        const companyName = (!!rc?.companyName && rc?.companyName !== "null") ? rc?.companyName : "-";
        return (
          <Typography.Text ellipsis={{ tooltip: companyName }}>
            {companyName}
          </Typography.Text>
        );
      },
    },
    {
      key: "startDate",
      title: t("start-date"),
      render: (_, rc) => {
        const date =
          rc?.userType === "VISITOR"
            ? rc?.visitorsDetail?.startDate
            : rc?.userType === "CONTRACTOR"
              ? rc?.contractorDetail?.startDate
              : rc?.userType === "SUPPLIER"
                ? rc?.supplierDetail?.startDate
                : rc?.receiverDetail?.startDate
        const startDate = date ? formatDate({
          date: rc.createdAt,
          format: "nomal-full-time",
        }) : null
        return (
          <Typography.Text ellipsis={{ tooltip: startDate }}>{startDate}</Typography.Text>
        );
      },
    },
    {
      key: "endDate",
      title: t("end-date"),
      render: (_, rc) => {
        const date =
          rc?.userType === "VISITOR"
            ? rc?.visitorsDetail?.endDate
            : rc?.userType === "CONTRACTOR"
              ? rc?.contractorDetail?.endDate
              : rc?.userType === "SUPPLIER"
                ? rc?.supplierDetail?.endDate
                : rc?.receiverDetail?.endDate
        const endDate = date ? formatDate({
          date: rc.createdAt,
          format: "nomal-full-time",
        }) : null
        return (
          <Typography.Text ellipsis={{ tooltip: endDate }}>{endDate}</Typography.Text>
        );
      },
    },
    {
      key: "registerType",
      title: t("formation"),
      align: "center",
      width: 150,
      render: (_, rc) => {
        if (!rc?.registerType) return <span>-</span>;
        return <BlockColor value={rc?.registerType} type="registerType" />;
      },
    },
    {
      key: "userType",
      title: t("person-type"),
      render: (_, rc) => {
        const type =
          rc?.userType === "VISITOR"
            ? t("guests")
            : rc?.userType === "CONTRACTOR"
              ? t("contractor-full-time")
              : rc?.userType === "SUPPLIER"
                ? t("supplier-deliver-goods")
                : t("consignee");
        return (
          <Typography.Text ellipsis={{ tooltip: type }}>{type}</Typography.Text>
        );
      },
    },
  ];

  //   if () {
  //   columns.push({
  //     key: "identityCode",
  //     title: t("contractor-id"),
  //     render: (_, rc) => {
  //       if (!rc?.identityCode) return "-";
  //       return (
  //         <Typography.Text ellipsis={{ tooltip: rc?.identityCode }}>
  //           {rc?.identityCode}
  //         </Typography.Text>
  //       );
  //     },
  //   });
  // }

  const _columns = columns.filter((item) => {
    return columnsKey.some((e) => e === item.key);
  });

  const topic = _columns
    .map((i) => {
      if (i?.key === "profile") return "profile.url";
      if (i?.key === "date") return "createdAt";
      if (i?.key === "name") return ["firstName", "lastName"];
      return i?.key;
    })
    .flat();

  // console.log("search :; ", search?.userType)

  let newTopic = topic;
  let type = "";

  (search?.userType?.includes("CONTRACTOR")) ? type = 'contractors' :
    (search?.userType?.includes("RECEIVER")) ? type = 'receivers' :
      type = 'supplierAndVisitors'
  if (type) {
    if (topic?.includes("startDate")) newTopic[topic.indexOf("startDate")] = `${type}.startDate`
    if (topic?.includes("endDate")) newTopic[topic.indexOf("endDate")] = `${type}.endDate`
  }


  return (
    <React.Fragment>
      <CTable
        scroll={{ x: 1200 }}
        selectAble={false}
        title={t("list-all")}
        columns={_columns}
        query={data_userReport}
        loading={data_userReport?.isFetching}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        action={{
          excels: {
            export: {
              mutation: export_customer,
              extraValue: { topic: newTopic, userType: search?.userType },
              notShowModal: true,
            },
          },
        }}
      />
    </React.Fragment>
  );
};

export default UserManagementCustomerReport;
