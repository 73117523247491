import { Divider, Typography } from "antd";
import { CSSProperties, FC, ReactNode } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div<{ $active?: boolean }>`
  ${tw`bg-white border rounded-app transition-all cursor-pointer`};
  ${({ $active }) => ($active ? tw`border-primary-dark` : tw`border-gray-200`)}
  .title {
    ${({ $active }) => ($active ? tw`!text-white` : "")};
  }
  .oblique {
    ${tw`transition-all rounded-tl-[0.8rem]`};
    ${({ $active }) => ($active ? tw`bg-primary-dark` : tw`bg-gray-100`)};
    width: 65%;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);

  }

  &:hover {
    ${tw`border-primary-dark`};
    .title {
      ${tw`!text-white`};
    }
    .oblique {
      ${tw`bg-primary-dark`};
    }
  }
`;

export interface SlashHeaderCardProps {
  title: string;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  active?: boolean;
  onClick?: () => void;
}

const SlashHeaderCard: FC<SlashHeaderCardProps> = ({
  children,
  title,
  className,
  style,
  active,
  onClick,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      className={`shadow ${className}`}
      style={style}
      $active={active}
    >
      <div className="px-3 py-2 oblique">
        <Typography.Text className="title" ellipsis={{ tooltip: title }}>
          {title}
        </Typography.Text>
      </div>
      <Divider className="!m-0" />
      {children}
    </Wrapper>
  );
};

export default SlashHeaderCard;
