import { Modal, ModalProps } from "antd";
import { FC } from "react";
import styled from "styled-components";
import CModalBarrierAction, {
  CModalBarrierActionProps,
} from "./barrier-action";
import CModalFullScreen, { CModalFullScreenProps } from "./full-screen";

type NonationTypes = {
  // ConfirmDelete:
  BarrierAction: FC<CModalBarrierActionProps>;
  FullScreen: FC<CModalFullScreenProps>;
};

const StyledModal = styled(Modal)`
  .anticon-close > svg {
    margin: auto;
    display: block;
    width: 15px;
    height: 15px;
  }
`;

export interface CModalProps extends ModalProps {}

const CModal: FC<CModalProps> & NonationTypes = ({
  footer = null,
  closable = false,
  centered = true,
  ...props
}) => {
  return (
    <StyledModal
      centered={centered}
      closable={closable}
      footer={footer}
      {...props}
      transitionName=""
    />
  );
};

CModal.BarrierAction = CModalBarrierAction;
CModal.FullScreen = CModalFullScreen;
export default CModal;
