import { useQueryClient } from "@tanstack/react-query";
import { notificationHeader } from "components/popup/notification";
import { socketUrl } from "config/axios";
import { useAppLanguage } from "provider/app-language";
import { useAuth } from "provider/auth";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { INotificationHeader } from "services/notification-header/interface";
import io from "socket.io-client";
import { SubCard } from "./element-noti";
import { INotification, INotificationSocketContext } from "./interface";

const Context = createContext<INotificationSocketContext>(
  {} as INotificationSocketContext
);

const NotificationSocket = ({ children }: { children: ReactNode }) => {
  const qClient = useQueryClient();
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const [notification, setNotification] = useState<INotification>();
  const { userMe } = useAuth();

  useEffect(() => {
    const socket = io(socketUrl.replace("http", "ws"), {
      transports: ["websocket"],
    });

    socket.on(
      `failed-the-3rd-of-vehicle-condition-${userMe?.id}`,
      ({ notifications }: { notifications: INotificationHeader }) => {
        console.log("failed-the-3rd-of-vehicle-condition", notifications);
        const createdAt = notifications.createdAt
          ? formatDate({
              date: notifications.createdAt,
              format: "nomal-full-time",
              keySeparator: " ",
            })
          : "-";
        notificationHeader({
          element: SubCard({ item: { ...notifications, createdAt } }),
          message: t("failed-vehicle-inspection-more-than-3-time"),
        });
        qClient.invalidateQueries(["get-notifications"]);
        qClient.invalidateQueries(["get-notifications-scroll"]);
        setNotification({
          ...notification,
          FAILED_THE_3RD_OF_VEHICLE_CONDITION: notifications,
        });
      }
    );

    socket.on(
      `vip-reject-car-${userMe?.id}`,
      ({ notifications }: { notifications: INotificationHeader }) => {
        console.log("vip-reject-car", notifications);
        const createdAt = notifications.createdAt
          ? formatDate({
              date: notifications.createdAt,
              format: "nomal-full-time",
              keySeparator: " ",
            })
          : "-";
        notificationHeader({
          element: SubCard({ item: { ...notifications, createdAt } }),
          message: t("vip-reject-car"),
        });
        qClient.invalidateQueries(["get-notifications"]);
        qClient.invalidateQueries(["get-notifications-scroll"]);
        setNotification({
          ...notification,
          VIP_REJECT_CAR: notifications,
        });
      }
    );

    socket.on(
      `no-mask-${userMe?.id}`,
      ({ notifications }: { notifications: INotificationHeader }) => {
        console.log("no-mask", notifications);
        const createdAt = notifications.createdAt
          ? formatDate({
              date: notifications.createdAt,
              format: "nomal-full-time",
              keySeparator: " ",
            })
          : "-";
        notificationHeader({
          element: SubCard({ item: { ...notifications, createdAt } }),
          message: t("not-wearing-a-mask"),
        });
        qClient.invalidateQueries(["get-notifications"]);
        qClient.invalidateQueries(["get-notifications-scroll"]);
        setNotification({
          ...notification,
          NO_MASK: notifications,
        });
      }
    );

    socket.on(
      `temperature-does-not-pass-${userMe?.id}`,
      ({ notifications }: { notifications: INotificationHeader }) => {
        console.log("temperature-does-not-pass", notifications);
        const createdAt = notifications.createdAt
          ? formatDate({
              date: notifications.createdAt,
              format: "nomal-full-time",
              keySeparator: " ",
            })
          : "-";
        notificationHeader({
          element: SubCard({ item: { ...notifications, createdAt } }),
          message: t("temperature-does-not-pass"),
        });
        qClient.invalidateQueries(["get-notifications"]);
        qClient.invalidateQueries(["get-notifications-scroll"]);
        setNotification({
          ...notification,
          TEMPERATURE_DOES_NOT_PASS: notifications,
        });
      }
    );

    socket.on(
      `scan-the-face-through-${userMe?.id}`,
      ({ notifications }: { notifications: INotificationHeader }) => {
        console.log("scan-the-face-through", notifications);
        const createdAt = notifications.createdAt
          ? formatDate({
              date: notifications.createdAt,
              format: "nomal-full-time",
              keySeparator: " ",
            })
          : "-";
        notificationHeader({
          element: SubCard({ item: { ...notifications, createdAt } }),
          message: t("pass-face-scan"),
        });
        qClient.invalidateQueries(["get-notifications"]);
        qClient.invalidateQueries(["get-notifications-scroll"]);
        setNotification({
          ...notification,
          SCAN_THE_FACE_THROUGH: notifications,
        });
      }
    );

    socket.on(
      `failed-face-scan-${userMe?.id}`,
      ({ notifications }: { notifications: INotificationHeader }) => {
        console.log("failed-face-scan", notifications);
        const createdAt = notifications.createdAt
          ? formatDate({
              date: notifications.createdAt,
              format: "nomal-full-time",
              keySeparator: " ",
            })
          : "-";
        notificationHeader({
          element: SubCard({ item: { ...notifications, createdAt } }),
          message: t("failed-face-scan"),
        });
        qClient.invalidateQueries(["get-notifications"]);
        qClient.invalidateQueries(["get-notifications-scroll"]);
        setNotification({
          ...notification,
          FAILED_FACE_SCAN: notifications,
        });
      }
    );

    socket.on(
      `license-plate-does-not-pass-${userMe?.id}`,
      ({ notifications }: { notifications: INotificationHeader }) => {
        console.log("license-plate-does-not-pass", notifications);
        const createdAt = notifications.createdAt
          ? formatDate({
              date: notifications.createdAt,
              format: "nomal-full-time",
              keySeparator: " ",
            })
          : "-";
        notificationHeader({
          element: SubCard({ item: { ...notifications, createdAt } }),
          message: t("license-plate-does-not-pass"),
        });
        qClient.invalidateQueries(["get-notifications"]);
        qClient.invalidateQueries(["get-notifications-scroll"]);
        setNotification({
          ...notification,
          LICENSE_PLATE_DOES_NOT_PASS: notifications,
        });
      }
    );

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNotification(undefined);
  }, []);

  return (
    <Context.Provider value={{ ...notification }}>{children}</Context.Provider>
  );
};

export const useNotificationSocket = () => useContext(Context);
export default NotificationSocket;
