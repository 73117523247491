import { Col, Divider, Row, Typography } from "antd";
import CButton from "components/button/c-button";
import { usePageRoute } from "provider/page-route";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ExtranHeaderButtonProps, onAddType } from ".";
import TableAction, { TableActionProps } from "./action";

export interface CTableHeaderProps {
  title?: string;
  action?: Omit<TableActionProps, "selectRowsKey" | "resetSelectRowKey">;
  extraHeaderButton?: ExtranHeaderButtonProps[];
  selectRowsKey?: any[];
  resetSelectRowKey?: () => void;
  onAdd?: onAddType;
  shouldGoBackAfterDelete?: boolean;
  extraElement?: JSX.Element;
}

const CTableHeader: FC<CTableHeaderProps> = ({
  title,
  action,
  extraHeaderButton,
  selectRowsKey = [],
  resetSelectRowKey = () => {},
  shouldGoBackAfterDelete,
  onAdd,
  extraElement,
}) => {
  const { t } = useTranslation();
  const { dispatch } = usePageRoute();

  const onAddButtonClick = () => {
    if (typeof onAdd === "string") {
      return dispatch({ action: "push", pageKey: onAdd });
    }
    if (typeof onAdd !== "string" && onAdd?.pageKey) {
      return dispatch({ action: "push", pageKey: onAdd.pageKey });
    }
    return onAdd?.onClick?.(selectRowsKey, resetSelectRowKey);
  };

  const label = typeof onAdd === "string" ? {} : onAdd;

  return (
    <Row align="middle" className="mb-5" gutter={6}>
      <Col span={10}>
        <Typography.Title
          level={4}
          className="!text-primary-dark"
          ellipsis={{ tooltip: title }}
        >
          {title}
        </Typography.Title>
      </Col>
      <Col className="ml-auto">
        <Row>
          {extraElement ? <Row className="pr-4">{extraElement}</Row> : null}
          <TableAction
            {...action}
            shouldGoBackAfterDelete={shouldGoBackAfterDelete}
            selectRowsKey={selectRowsKey}
            resetSelectRowKey={resetSelectRowKey}
          />
        </Row>
      </Col>
      <Col style={{ width: "auto" }} className="text-center" hidden={!onAdd}>
        <Divider type="vertical" className="!m-0" style={{ height: 40 }} />
      </Col>
      {extraHeaderButton?.map((item, index) => {
        return (
          <Col key={index} hidden={item.hidden}>
            <CButton
              className="w-full"
              {...item}
              onClick={(e) => {
                item.onClick?.(e, selectRowsKey);
              }}
            />
          </Col>
        );
      })}
      <Col hidden={!onAdd}>
        <CButton theme="yellow" className="w-full" onClick={onAddButtonClick}>
          {label?.label || `+ ${t("add")}`}
        </CButton>
      </Col>
    </Row>
  );
};

export default CTableHeader;
