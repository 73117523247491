import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Col, Form, ModalProps, Row, Typography } from "antd";
import CUpload from "components/input/c-upload";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CModal from ".";
import CModalFooter from "./modal-footer";

export type personType =
  | "contractor"
  | "consignee"
  | "supplier"
  | "guest"
  | "employee"
  | "user";
export interface CModalUploadProfileProps extends ModalProps {
  mutation?: UseMutationResult;
  invalidateQueries?: string[];
  typeUser?: personType;
}

const CModalUploadProfile: FC<CModalUploadProfileProps> = ({
  mutation,
  invalidateQueries,
  typeUser,
  ...props
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { setAppLoading } = useAppLoading();
  const { pageKey } = usePageRoute();
  const qClient = useQueryClient();

  const onFinish = async (e: React.MouseEvent<HTMLElement>) => {
    if (!mutation) return;

    const values = await form.validateFields();

    setAppLoading(true);

    mutation?.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success", menu: pageKey });
        invalidateQueries?.forEach((e) => qClient.invalidateQueries([e]));
        form.resetFields();
        props?.onCancel?.(e);
      },
      onError: ({ message }: any) => {
        fireNotification({ type: "error", menu: pageKey, message });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  return (
    <CModal
      {...props}
      forceRender
      closable
      title={t("import-profile")}
      width="40vw"
      footer={
        <CModalFooter
          submit={{ onClick: onFinish }}
          cancel={{ onClick: props.onCancel }}
        />
      }
    >
      <Form form={form} layout="vertical">
        <Row gutter={[0, 8]} className="mb-5">
          <Col span={24}>
            <ExclamationCircleOutlined
              className="pr-2"
              style={{ color: "#FC0002" }}
            />
            <Typography.Text className="font-bold">
              {t("image-file-naming")}
            </Typography.Text>
          </Col>
          {typeUser === "consignee" ? (
            <Col span={24}>
              <Typography.Text className="pl-5">
                {`${t("consignee")} : `}
              </Typography.Text>
              <Typography.Text>
                {t("file-name-matching-consignee-id", { ns: "message" })}
              </Typography.Text>
            </Col>
          ) : typeUser === "contractor" ? (
            <Col span={24}>
              <Typography.Text className="pl-5">
                {`${t("contractor-full-time")} : `}
              </Typography.Text>
              <Typography.Text>
                {t("file-name-matching-employee-id", { ns: "message" })}
              </Typography.Text>
            </Col>
          ) : typeUser === "supplier" ? (
            <Col span={24}>
              <Typography.Text className="pl-5">
                {`${t("supplier-type")} : `}
              </Typography.Text>
              <Typography.Text>
                {t("file-name-matching-identity-code", { ns: "message" })}
              </Typography.Text>
            </Col>
          ) : typeUser === "guest" ? (
            <Col span={24}>
              <Typography.Text className="pl-5">
                {`${t("guest")} : `}
              </Typography.Text>
              <Typography.Text>
                {t("file-name-matching-identity-code", { ns: "message" })}
              </Typography.Text>
            </Col>
          ) : typeUser === "employee" ? (
            <Col span={24}>
              <Typography.Text className="pl-5">
                {`${t("employee")} : `}
              </Typography.Text>
              <Typography.Text>
                {t("file-name-matching-employee-id", { ns: "message" })}
              </Typography.Text>
            </Col>
          ) : (
            <Col>
              <Col span={24}>
                <Typography.Text className="pl-5">
                  {`${t("user")} : `}
                </Typography.Text>
                <Typography.Text>
                  {t("file-name-matching-employee-id", { ns: "message" })}
                </Typography.Text>
              </Col>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <span>{t("upload-image-files")}</span>
              <span className="text-red-500 ml-2">
                {t("maximum-image-file-size-20-mb-image", { ns: "message" })}
              </span>
            </div>
            <Form.Item name="profiles" noStyle>
              <CUpload.List accepts={[".jpeg", ".jpg", ".png"]} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CModal>
  );
};

export default CModalUploadProfile;
