import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ICheckSpray, ICheckSprayParams } from "./interface";

export const useGetCheckSprays = (
  params?: ICheckSprayParams
): UseQueryResult<IResponse<ICheckSpray[]>> => {
  return useQuery(
    ["get-check-spray", params],
    async () => {
      const res = await axios.get("/check-the-spray", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled, keepPreviousData: true }
  );
};

export const useExportExcelSprayRecevier = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/spray-receiver-report-export-excel`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportExcelSpraySupplier = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/spray-supplier-report-export-excel`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportExcelSprayEmployee = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/spray-employee-report-export-excel`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

