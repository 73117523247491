export const MenuInspectionOverviewIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.182"
      height="27.182"
      viewBox="0 0 27.182 27.182"
      {...props}
    >
      <g data-name="vuesax/bold/element-3" transform="translate(-620 -252)">
        <g fill="#fff" transform="translate(620 252)">
          <path
            d="M9.627 7.384V2.243C9.627.646 8.9 0 7.1 0H2.526C.725 0 0 .646 0 2.243v5.13c0 1.608.725 2.243 2.526 2.243H7.1c1.8.011 2.527-.635 2.527-2.232z"
            transform="translate(15.29 2.265)"
          ></path>
          <path
            d="M9.627 7.1V2.526C9.627.725 8.9 0 7.1 0H2.526C.725 0 0 .725 0 2.526V7.1c0 1.8.725 2.527 2.526 2.527H7.1c1.8 0 2.527-.727 2.527-2.527z"
            data-name="Vector"
            transform="translate(15.29 15.29)"
          ></path>
          <path
            d="M9.627 7.384V2.243C9.627.646 8.9 0 7.1 0H2.526C.725 0 0 .646 0 2.243v5.13c0 1.608.725 2.243 2.526 2.243H7.1c1.8.011 2.527-.635 2.527-2.232z"
            data-name="Vector"
            transform="translate(2.265 2.265)"
          ></path>
          <path
            d="M9.627 7.1V2.526C9.627.725 8.9 0 7.1 0H2.526C.725 0 0 .725 0 2.526V7.1c0 1.8.725 2.527 2.526 2.527H7.1c1.8 0 2.527-.727 2.527-2.527z"
            data-name="Vector"
            transform="translate(2.265 15.29)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuInspectionSprayCheckIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/broom" transform="translate(-236 -700)">
        <g>
          <g fill="#fff">
            <path
              d="M5.082 3.155L3.8 1.035a2.09 2.09 0 00-2.32-.96 2.048 2.048 0 00-1.19 3.02l1.32 2.17a.516.516 0 00.69.17l2.61-1.59a.5.5 0 00.172-.69z"
              data-name="Vector"
              transform="translate(239.738 701.745)"
            ></path>
            <path
              d="M14.108 8.538l-2.81-3.8a4.046 4.046 0 00-4.28-1.51c0-.01-.01-.01-.01-.02L5.348.478a1.02 1.02 0 00-1.39-.33l-3.47 2.11a1.007 1.007 0 00-.34 1.39L1.8 6.378a.01.01 0 00.01.01A4.046 4.046 0 001.2 10.9l2.08 4.24a2.5 2.5 0 003.43 1.15.186.186 0 00.07-.27l-1.56-2.58a.765.765 0 01.25-1.05.783.783 0 011.05.25l1.57 2.58a.208.208 0 00.27.07l.96-.58a.208.208 0 00.07-.27l-1.57-2.58a.763.763 0 011.3-.8l1.57 2.58a.208.208 0 00.27.07l.96-.58a.208.208 0 00.07-.27l-1.57-2.58a.763.763 0 011.3-.8l1.58 2.57a.2.2 0 00.27.06 2.488 2.488 0 00.538-3.572z"
              data-name="Vector"
              transform="translate(241.632 705.672)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const MenuInspectionVehicleIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/truck-time" transform="translate(-172 -252)">
        <g fill="#fff">
          <path
            d="M19.5 10.5a.5.5 0 01.5.5v1a3 3 0 01-3 3 3 3 0 00-6 0H9a3 3 0 00-6 0 3 3 0 01-3-3v-2a1 1 0 011-1h9.5A2.5 2.5 0 0013 6.5V1a1 1 0 011-1h.84a2.016 2.016 0 011.74 1.01l.64 1.12a.252.252 0 01-.22.37A2.5 2.5 0 0014.5 5v3a2.5 2.5 0 002.5 2.5z"
            transform="translate(174 257)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(178 270)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(186 270)"
          ></path>
          <path
            d="M4 3.53V5H1a1 1 0 01-1-1V1a1 1 0 011-1h1.29l1.45 2.54a2 2 0 01.26.99z"
            data-name="Vector"
            transform="translate(190 261)"
          ></path>
          <path
            d="M11.08 0H3.69A3.687 3.687 0 000 3.69v6.39a1 1 0 001 1h9.15A1.852 1.852 0 0012 9.23V.92a.918.918 0 00-.92-.92zm-3.7 5.91a.751.751 0 01-.36.64l-1.25.75a.724.724 0 01-.39.11.754.754 0 01-.64-.36.746.746 0 01.25-1.03l.89-.53V4.41a.75.75 0 111.5 0z"
            data-name="Vector"
            transform="translate(174 254)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuInspectionFailedIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/timer" transform="translate(-236 -188)">
        <g fill="#fff">
          <path
            d="M8.67 0a8.675 8.675 0 108.67 8.68A8.681 8.681 0 008.67 0zm.75 8.35a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0z"
            transform="translate(239.33 192.65)"
          ></path>
          <path
            d="M6.5 1.45H.72A.717.717 0 010 .73.726.726 0 01.72 0H6.5a.717.717 0 01.72.72.726.726 0 01-.72.73z"
            data-name="Vector"
            transform="translate(244.39 190)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuInspectionQuickLabIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/security-safe" transform="translate(-364 -316)">
        <g>
          <path
            fill="#fff"
            d="M17.82 9.113V4.722a2.4 2.4 0 00-1.39-2.06L10.86.382a5.187 5.187 0 00-3.91 0l-5.57 2.28A2.411 2.411 0 000 4.722v4.39a11.524 11.524 0 008.4 10.81 1.96 1.96 0 001.02 0 11.524 11.524 0 008.4-10.809zm-8.16 1.75v2.63a.75.75 0 11-1.5 0v-2.63a2.5 2.5 0 111.5 0z"
            data-name="Vector"
            transform="translate(367.09 318.008)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuInspectionBrokenVehicleIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/truck-remove" transform="translate(-236 -252)">
        <g fill="#fff">
          <path
            d="M19.5 10.5a.5.5 0 01.5.5v1a3 3 0 01-3 3 3 3 0 00-6 0H9a3 3 0 00-6 0 3 3 0 01-3-3v-2a1 1 0 011-1h9.5A2.5 2.5 0 0013 6.5V1a1 1 0 011-1h.84a2.016 2.016 0 011.74 1.01l.64 1.12a.252.252 0 01-.22.37A2.5 2.5 0 0014.5 5v3a2.5 2.5 0 002.5 2.5z"
            data-name="Vector"
            transform="translate(238 257)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(242 270)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(250 270)"
          ></path>
          <path
            d="M4 3.53V5H1a1 1 0 01-1-1V1a1 1 0 011-1h1.29l1.45 2.54a2 2 0 01.26.99z"
            data-name="Vector"
            transform="translate(254 261)"
          ></path>
          <path
            d="M11.08 0H3.69A3.687 3.687 0 000 3.69v6.39a1 1 0 001 1h9.15A1.852 1.852 0 0012 9.23V.92a.918.918 0 00-.92-.92zM7.6 7.12a.774.774 0 01-1.06 0l-.53-.53-.55.55a.748.748 0 01-1.06 0 .754.754 0 010-1.06l.55-.55L4.42 5a.754.754 0 010-1.06.767.767 0 011.07 0l.52.53.5-.51a.757.757 0 011.07 1.07l-.51.5.53.52a.767.767 0 010 1.07z"
            data-name="Vector"
            transform="translate(238 253.98)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuInspectionQueueIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        data-name="vuesax/bold/folder-connection"
        transform="translate(-104 -318)"
      >
        <g>
          <path
            fill="#fff"
            d="M7.75 14h2.75c2.8 0 3.5-.7 3.5-3.5V6.3c0-2.8-.7-3.5-3.5-3.5H8.4a1.113 1.113 0 01-1.12-.56L6.23.84A1.657 1.657 0 004.55 0H3.5C.7 0 0 .7 0 3.5v7C0 13.3.7 14 3.5 14h2.75v3a.7.7 0 00.03.14 2 2 0 00-1.14 1.14.7.7 0 00-.14-.03H1a.75.75 0 000 1.5h4a.7.7 0 00.14-.03 1.991 1.991 0 003.72 0 .7.7 0 00.14.03h4a.75.75 0 000-1.5H9a.7.7 0 00-.14.03 2 2 0 00-1.14-1.14.7.7 0 00.03-.14v-3z"
            data-name="Vector"
            transform="translate(109 320)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuInspectionReportIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        data-name="vuesax/bold/folder-connection"
        transform="translate(-104 -318)"
      >
        <g>
          <path
            fill="#fff"
            d="M7.75 14h2.75c2.8 0 3.5-.7 3.5-3.5V6.3c0-2.8-.7-3.5-3.5-3.5H8.4a1.113 1.113 0 01-1.12-.56L6.23.84A1.657 1.657 0 004.55 0H3.5C.7 0 0 .7 0 3.5v7C0 13.3.7 14 3.5 14h2.75v3a.7.7 0 00.03.14 2 2 0 00-1.14 1.14.7.7 0 00-.14-.03H1a.75.75 0 000 1.5h4a.7.7 0 00.14-.03 1.991 1.991 0 003.72 0 .7.7 0 00.14.03h4a.75.75 0 000-1.5H9a.7.7 0 00-.14.03 2 2 0 00-1.14-1.14.7.7 0 00.03-.14v-3z"
            data-name="Vector"
            transform="translate(109 320)"
          ></path>
        </g>
      </g>
    </svg>
  );
};
