import { Col, Divider, Row, Typography } from "antd";
import Form from "antd/lib/form";
import Container from "components/display/container";
import LicensePlateImage from "components/display/license-plate-image";
import VehicleImage from "components/display/vehicle-image";
import CDatePicker from "components/input/c-date-picker";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import dayjs from "dayjs";
import { usePageRoute } from "provider/page-route";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetVehicleBlackList,
  usePatchVehicleBlackList,
  usePostVehicleBlackList,
} from "services/vehicle-blacklist";

const VehicleBannedCreateAndEditPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const patch_banned = usePatchVehicleBlackList();
  const post_banned = usePostVehicleBlackList();
  const { data, isLoading } = useGetVehicleBlackList(id);

  useEffect(() => {
    if (!data) return;
    const { lastDetect } = data.vehicle || {};
    form.setFieldsValue({
      ...data,
      detectImage: lastDetect?.licensePlateImage,
      detectDevice: lastDetect?.encodeDevice?.devName,
      detectLicensePlate: data.licensePlate,
      detectDate: lastDetect?.detectDateTime
        ? dayjs(lastDetect.detectDateTime)
        : null,
      // date: [dayjs(data.startDate), dayjs(data.endDate)],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const pageKey = getCurrentKey();

  return (
    <PageContent
      loading={isLoading && !!id}
      breadcrumb={{
        form,
        shadow: false,
        mutate: {
          mutation: id ? patch_banned : post_banned,
          invalidateQueries: ["vehicle-black-list"],
          onSaveAndContinue: !id,
          onBeforeUpdate: (values) => {
            if (id) {
              delete values.detectDate;
              delete values.detectDevice;
              delete values.detectLicensePlate;
            }
            return values;
          },
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title
            id="staff-general"
            level={5}
            className="!text-primary-dark"
          >
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form layout="vertical" className="!p-5" form={form}>
          <Form.Item hidden name="id">
            <CInput />
          </Form.Item>
          <Row gutter={[12, 6]}>
            <LicensePlate />
            <Vehicle />
            {/* <VehicleOwner /> */}
            <Accessibility />
          </Row>
        </Form>
      </Container>
    </PageContent>
  );
};

const Vehicle = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col span={24} className="mt-4">
        <Typography.Text className="!text-primary">
          {t("vehicle-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item name="vehicleImage" label={t("vehicle-picture")}>
          <VehicleImage />
        </Form.Item>
      </Col>
      <Col span={16}></Col>
      <Col span={8}>
        <Form.Item name="vehicleBrand" label={t("vehicle-brand")}>
          <CSelect.VehicleBrand />
        </Form.Item>
      </Col>
      {/* <Col span={8}>
        <Form.Item label={t("vehicle-series")}>
          <CInput placeholder={t("vehicle-series")} />
        </Form.Item>
      </Col> */}
      <Col span={8}>
        <Form.Item name="color" label={t("color")}>
          <CInput placeholder={t("color")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("license-plate-number")} name="licensePlate">
          <CInput placeholder={t("license-plate-number")} />
        </Form.Item>
      </Col>
      {/* <Col span={8}>
        <Form.Item label={t("plate-province")}>
          <CSelect.PlateProvince />
        </Form.Item>
      </Col> */}
    </React.Fragment>
  );
};

const LicensePlate = () => {
  const { t } = useTranslation();
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  if (!id) return null;

  return (
    <React.Fragment>
      <Col span={24}>
        <Typography.Text className="!text-primary">
          {t("license-plate-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item name="detectImage">
          <LicensePlateImage />
        </Form.Item>
      </Col>
      <Col span={16}></Col>
      <Col span={8}>
        <Form.Item
          name="detectDevice"
          label={t("license-plate-detection-camera")}
        >
          <CInput readOnly placeholder={t("license-plate-detection-camera")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("license-plate")} name="detectLicensePlate">
          <CInput readOnly placeholder={t("license-plate")} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item label={t("entry-date")} name="detectDate">
          <CDatePicker placeholder={t("entry-date")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

// const VehicleOwner = () => {
//   const { t } = useTranslation();

//   return (
//     <React.Fragment>
//       <Col span={24} className="mt-4">
//         <Typography.Text className="!text-primary">
//           {t("vehicle-owner-information")}
//         </Typography.Text>
//       </Col>
//       <Col span={8}>
//         <Form.Item label={t("first-name")}>
//           <CInput placeholder={t("first-name")} />
//         </Form.Item>
//       </Col>
//       <Col span={8}>
//         <Form.Item label={t("last-name")}>
//           <CInput placeholder={t("last-name")} />
//         </Form.Item>
//       </Col>
//       <Col span={8}>
//         <Form.Item label=" ">
//           <Row gutter={12}>
//             <Col span={8}>
//               <CButton className="w-full">{t("search")}</CButton>
//             </Col>
//             <Col span={8}>
//               <CButton theme="gray" className="w-full">
//                 {t("clear-data")}
//               </CButton>
//             </Col>
//           </Row>
//         </Form.Item>
//       </Col>
//       <Col span={8}>
//         <Form.Item label={t("phone-number")}>
//           <CInput placeholder={t("phone-number")} />
//         </Form.Item>
//       </Col>
//     </React.Fragment>
//   );
// };

const Accessibility = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col span={24} className="mt-4">
        <Typography.Text className="!text-primary">
          {t("accessibility-information")}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Form.Item name="description" label={t("note")}>
          <CInput.TextArea rows={6} placeholder={t("note")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default VehicleBannedCreateAndEditPage;
