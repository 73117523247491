import { Typography } from "antd";
import { AcceptStatusType } from "config/axios/interface";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

type TBlock = "temperature" | "registerType" | "acceptStatus" | "isCheckin";
type TTemperature = "true" | "false";
type TRegisterType = "ONSITE" | "ONLINE";
type TCheckIn = "true" | "false";

type Type = {
  bg: string;
  color: string;
  text: string;
};

const BLOCK_TYPE_TEMPERATURE: { [K in TTemperature]: Type } = {
  true: { bg: "#EAF7EC", color: "#47B174", text: "pass" },
  false: { bg: "#FFE6E6", color: "#DB1212", text: "not-pass" },
};

const BLOCK_TYPE_CHECK_IN: { [K in TCheckIn]: Type } = {
  true: { bg: "#EAF7EC", color: "#47B174", text: "✔" },
  false: { bg: "#FFE6E6", color: "#DB1212", text: "✖" },
};

const BLOCK_TYPE_REGISTER_TYPE: { [K in TRegisterType]: Type } = {
  ONSITE: { bg: "#FFF6E0", color: "#E0B33C", text: "regis-onsite" },
  ONLINE: { bg: "#EAF7EC", color: "#47B174", text: "regis-online" },
};

const BLOCK_TYPE_ACCEPT_STATUS: { [K in AcceptStatusType]: Type } = {
  APPROVE: { bg: "#EAF7EC", color: "#47B174", text: "approve" },
  PENDING: { bg: "#FFF6E0", color: "#E0B33C", text: "approval-pending" },
  REJECT: { bg: "#FFE6E6", color: "#DB1212", text: "disapproval" },
};

const BLOCK_TYPE_THEME: {
  [K in TBlock]: { [T in any]: Type };
} = {
  registerType: BLOCK_TYPE_REGISTER_TYPE,
  temperature: BLOCK_TYPE_TEMPERATURE,
  acceptStatus: BLOCK_TYPE_ACCEPT_STATUS,
  isCheckin: BLOCK_TYPE_CHECK_IN,
};

interface IBlock {
  value: string;
  type: TBlock;
}

const BlockStyle = styled(Typography.Text) <{ bg: string; color: string }>`
  height: 35px;
  width: 80%;
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
  padding: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  ${tw`rounded-app`}
`;

const BlockColor = ({ value, type }: IBlock) => {
  const { t } = useTranslation();
  const theme = BLOCK_TYPE_THEME[type][value];

  if(value == null) {
    return (<div></div>);
  } else {
    return (
      <BlockStyle
        bg={theme.bg}
        color={theme.color}
        ellipsis={{ tooltip: t(theme.text) }}
      >
        {t(theme.text)}
      </BlockStyle>
    );
  }


  
};

export default BlockColor;
