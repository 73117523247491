import { useQueryClient } from "@tanstack/react-query";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { IVehicleInspectionContext } from "./interface";
import { vehicleInspectionUrl } from "config/axios";
import { IVehicleCondition } from "services/vehicle-condition/interface";
import io from "socket.io-client";

const Context = createContext<IVehicleInspectionContext>({});

const VehicleInspectionSocketProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [vehicleInspection, setVehicleInspection] = useState<
    IVehicleCondition | undefined
  >();
  const qClient = useQueryClient();
  
  useEffect(() => {
    const socket = io(vehicleInspectionUrl.replace("http", "ws"), {
      transports: ["websocket"],
    });

    socket.on(`vehicle-condition`, (v: IVehicleCondition) => {
      console.log("vehicle-condition", v);
      setVehicleInspection(v);
      qClient.invalidateQueries(["get-vehicle-conditions"]);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleInspection]);

  useEffect(() => {
    setVehicleInspection(undefined);
  }, [vehicleInspection]);

  return (
    <Context.Provider value={{ vehicleInspection }}>
      {children}
    </Context.Provider>
  );
};

export const useVehicleInspectionSocket = () => useContext(Context);
export default VehicleInspectionSocketProvider;
