import Icon, { CommentOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  Col,
  Divider,
  Form,
  FormInstance,
  Row,
  TableColumnsType,
  Typography,
} from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { BrokenCarIcon } from "assets/icons/general";
import CModal from "components/display/c-modal";
import CModalFooter from "components/display/c-modal/modal-footer";
import CTable from "components/display/c-table";
import BlockColorQueueStatus from "components/display/card/status-queue-car";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import { typeOfUser } from "config/axios/interface";
import { useAppLanguage } from "provider/app-language";
import { usePageRoute } from "provider/page-route";
import { useStatusQueueCar } from "provider/status-queue-car";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportVehiclesQueue,
  useGetVehiclesQueues,
  usePostVehiclesBroken,
} from "services/status-queue-car";
import {
  IVehicleQueue,
  IVehicleQueueParams,
} from "services/status-queue-car/interface";

const StatusQueueCarContent = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { keyStatus, setNumberOfItems } = useStatusQueueCar();
  const { formatDate, translationObject } = useAppLanguage();

  const [selectRowsKey, setSelectRowsKey] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [params, setParams] = useState<IVehicleQueueParams>({
    page: 1,
    limit: 10,
  });

  const { data, isFetching } = useGetVehiclesQueues({
    ...params,
    vehicleQueueStatus: keyStatus?.vehicleQueueStatus,
  });
  const export_queue = useExportVehiclesQueue();

  useEffect(() => {
    setParams({ ...params, vehicleQueueStatus: keyStatus?.vehicleQueueStatus });
    setSelectRowsKey([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyStatus]);

  useEffect(() => {
    setNumberOfItems?.({
      CHECK_SPRAY: data?.countVehicleSpray || 0,
      CHECK_CAR_CONDITIOPN: data?.countVehicleCondition || 0,
      ALL: data?.countAllVehicleQueue || 0,
      VEHICLE_QUICKLAB: data?.conutVehicleQuicklab || 0,
      WAITING_QUEUE_IN: 0,
      WAIT_FOR_LOAD: 0,
      START_LOADING: 0,
      FINISH_LOADING: 0,
      WEIGHED_OUT: 0,
      TVOP: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const rowSelection: TableRowSelection<object> = {
    onChange: setSelectRowsKey,
    selectedRowKeys: selectRowsKey,
    columnWidth: "50px",
  };

  // const disabled = keyStatus?.statusKey === "ALL";

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("date"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
    {
      name: "fullname",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "licensePlate",
      label: t("license-plate"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "productGroups",
      label: t("material-type"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "userType",
      label: t("person-type"),
      input: {
        type: "SelectUserType",
      },
    },
    // {
    //   name: "vehicleQueueStatus",
    //   label: t("status"),
    //   input: {
    //     type: "SelectStatusQueueCar",
    //     options: { disabled: !disabled },
    //   },
    // },
  ];

  const columns: TableColumnsType<IVehicleQueue> = [
    {
      title: t("date"),
      render: (_, rc) => {
        const { updatedAt } = rc;
        const date = formatDate({
          date: updatedAt,
          format: "nomal-full-time",
          keySeparator: " ",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.customerEmployeeDetail?.firstName;
        const lastName = rc?.customerEmployeeDetail?.lastName;
        if (!firstName && !lastName) return <span>-</span>;
        const name = `${firstName} ${lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
        );
      },
    },
    {
      title: t("license-plate"),
      render: (_, rc) => {
        let licensePlate = rc?.licensePlate || "-";

        return (
          <Typography.Text ellipsis={{ tooltip: licensePlate }}>
            {licensePlate}
          </Typography.Text>
        );
      },
    },
    {
      title: t("material-type"),
      render: (_, rc) => {
        let productGroup = "-";
        const customerEmployee = rc?.customerEmployeeDetail;
        switch (customerEmployee?.userType) {
          case "RECEIVER":
            productGroup =
              customerEmployee?.receivers?.[0]?.productGroups || "-";
            break;
          case "SUPPLIER":
            const { supplierAndVisitors } = customerEmployee || {};
            const supplierJobTypes =
              supplierAndVisitors?.[0]?.supplierJobTypes?.map((e) => {
                const { title } = translationObject({
                  object: e.supplierType,
                  keys: ["title"],
                });
                return title;
              });
            productGroup =
              (supplierAndVisitors?.[0]?.supplierJobTypes
                ? supplierJobTypes?.toString()
                : "-") || "-";
            break;
          default:
            productGroup = "-";
            break;
        }
        return (
          <Typography.Text ellipsis={{ tooltip: productGroup }}>
            {productGroup}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      render: (_, rc) => {
        const userTypeKey = rc?.customerEmployeeDetail?.userType;

        if (!userTypeKey) return <span>-</span>;
        const text = t(typeOfUser[userTypeKey]);
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      title: t("status"),
      align: "center",
      render: (_, rc) => {
        if (!rc?.vehicleQueueStatus) return <span>-</span>;
        return (
          <div className="w-full !flex justify-center">
            <BlockColorQueueStatus value={rc.vehicleQueueStatus} />
          </div>
        );
      },
    },
  ];

  const onClose = () => {
    setVisible(!visible);
    form.resetFields();
  };

  const onSearch = (values: any) => {
    console.log(values);

    const date = values.date;
    delete values.date;

    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  const onBrokenCar = () => {
    onClose();
    form.setFieldsValue({ vehicles: selectRowsKey });
  };

  const onImportERP = () => {
    ///
  };

  return (
    <Container scrollY height="calc(100vh - 200px)" padding="p-0">
      <CModalBrokenCar form={form} visible={visible} onClose={onClose} />
      <PageSearchBox elements={elements} onFinish={onSearch} shadow={false} />
      <Divider className="!m-0" />
      <CTable
        rowKey="id"
        rowSelection={rowSelection}
        shadow={false}
        scroll={{ x: 1200 }}
        title={t("status-queue-car-list")}
        columns={columns}
        loading={isFetching}
        dataSource={data?.data || []}
        onRowClick="vehicle-inspection.status-queue-car-detail"
        action={{
          excels: {
            export: {
              fileName: `${t("status-queue-car-list")}`,
              mutation: export_queue,
              extraValue: { vehicleQueueStatus: keyStatus?.vehicleQueueStatus },
            },
          },
        }}
        extraHeaderButton={[
          {
            children: <span className="pt-1">{t("broken-car")}</span>,
            theme: "gray",
            icon: (
              <Icon
                component={BrokenCarIcon}
                style={{ color: "#438860", fontSize: "20px" }}
              />
            ),
            onClick: onBrokenCar,
            disabled: selectRowsKey.length < 1,
          },
          {
            children: <span className="pt-1">{t("import-erp")}</span>,
            theme: "gray",
            icon: (
              <Icon
                component={BrokenCarIcon}
                style={{ color: "#438860", fontSize: "20px" }}
              />
            ),
            onClick: onImportERP,
            disabled: true,
          },
        ]}
        pagination={{
          total: data?.count,
          current: params?.page,
          pageSize: params.limit,
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </Container>
  );
};

interface BrokenCarModalProps {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
}

const CModalBrokenCar: FC<BrokenCarModalProps> = ({
  visible,
  onClose,
  form,
}) => {
  const { t } = useTranslation();
  const qClient = useQueryClient();
  const { pageKey } = usePageRoute();
  const post_broken_car = usePostVehiclesBroken();

  const onFinish = (values: any) => {
    post_broken_car.mutate(
      { ...values },
      {
        onSuccess: (_) => {
          qClient.invalidateQueries(["get-vehicles-queues"]);
          qClient.invalidateQueries(["get-vehicles-queue"]);
          qClient.invalidateQueries(["get-vehicles-brokens"]);
          fireNotification({ type: "success", menu: pageKey });
          onClose();
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: pageKey,
          });
        },
      }
    );
  };

  return (
    <CModal
      footer={
        <CModalFooter
          submit={{ onClick: () => form.submit() }}
          cancel={{ onClick: onClose }}
        />
      }
      visible={visible}
      onCancel={onClose}
      width={500}
      bodyStyle={{ minHeight: 345 }}
      forceRender={false}
      closable
    >
      <Row>
        <Col span={24} className="!flex justify-center">
          <CommentOutlined style={{ fontSize: 64 }} />
        </Col>
        <Col span={24} className="!flex justify-center">
          <div className="text-2xl my-4">{t("broken-car-note")}</div>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="vehicles" hidden>
          <CInput />
        </Form.Item>
        <Form.Item name="description" rules={[{ required: true }]}>
          <CInput.TextArea rows={7} placeholder={t("note")} />
        </Form.Item>
      </Form>
    </CModal>
  );
};

export default StatusQueueCarContent;
