import { IMediaObject } from "./../../services/media-object/interface";

export interface IResponse<T> {
  data: T;
  count: number;
  page: number;
  limit: number;
  pageCount: number;
  liquidSprayTrue?: number;
  liquidSprayFalse?: number;
  countAllVehicleQueue?: number;
  countVehicleSpray?: number;
  countVehicleCondition?: number;
  conutVehicleQuicklab?: number;
  countStatusFailed?: number;
  countStatusPassed?: number;
  countStatusVip?: number;
  countStatusWaiting?: number;
  unread?: {
    totalFailed3rdTime?: string;
    totalFailedFaceScan?: string;
    totalFailedLicensePlate: string;
    totalNoMask?: string;
    totalScanTheFaceThrough?: string;
    totalTemperatureDoesNotPass?: string;
    totalVipRejectCar?: string;
  };
}

export interface IPagination {
  page?: number;
  limit?: number;
  pagination?: boolean;
  enabled?: boolean;
}

export interface IBaseService {
  id: number;
  createdAt: string;
  updatedAt: string;
}
export interface IInfiniteScroll<T> {
  hasMore: boolean;
  page: number;
  data: T;
  undefinded?: boolean;
}

export interface IBaseUser {
  uuid: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  acceptStatus: AcceptStatusType;
  userType: UserTypeKey;
  identityCode: string;
  isAllowList: boolean;
  status: boolean;
  registerType: RegisterUserType;
  profile: IMediaObject;
  acceptPdpa: boolean;
  companyName: string;
  identityCodeType: IdentityCodeType;
  gender: IGender;
  mask: TMask;
  temperature: string;
  temperatureVerify: boolean;
  isCheckin: boolean | string;
  qrcodeNumber?: string;
  employeeName?: string;
  workAreaId?: number;
}

export type UserTypeKey =
  | "VISITOR"
  | "CONTRACTOR"
  | "SUPPLIER"
  | "RECEIVER"
  | "EMPLOYEE";
export type RegisterUserType = "ONLINE" | "ONSITE";
export type AcceptStatusType = "APPROVE" | "PENDING" | "REJECT";
export type IdentityCodeType = "ID_CARD";
export type IGender = "MALE" | "FEMALE" | "OTHER";
export type TMask = "WEARMASK" | "NOMASK";
export type ISubject =
  | "PICKUP"
  | "DELIVER"
  | "CONTACT"
  | "APPLY_JOB"
  | "MEETING"
  | "WORKING"
  | "OTHER";

export const typeOfUser: { [K in UserTypeKey]: string } = {
  RECEIVER: "consignee",
  CONTRACTOR: "contractor-full-time",
  VISITOR: "guests",
  SUPPLIER: "supplier-deliver-goods",
  EMPLOYEE: "employee",
};
