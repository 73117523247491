import { IUserTypeSelectData } from "components/input/c-user-type";
import { ComponentType, createContext, useContext, useState } from "react";
import { IUserPendingListContext } from "./interface";

const Context = createContext<IUserPendingListContext>(
  {} as IUserPendingListContext
);

const withUserPendingListProvider = (Component: ComponentType) => {
  return () => {
    const [selected, setSelected] = useState<IUserTypeSelectData>({
      userType: "VISITOR",
      title: "user-management.guest"
    });

    return (
      <Context.Provider value={{ selected, onSelect: setSelected}}>
        <Component />
      </Context.Provider>
    );
  };
};

export const useUserPendingList = () => useContext(Context);
export default withUserPendingListProvider;
