import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Col, Form, Row, Typography, Image, Divider } from "antd";
import { IdentityCard } from "assets/images";
import CButton from "components/button/c-button";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import AppLoadingLoader from "components/layout/app-loading-loader";
import PageContent from "components/layout/page-content";
import EmptyPage from "components/pages/empty-page";
import { fireNotification } from "components/popup/notification";
import { AcceptStatusType } from "config/axios/interface";
import dayjs from "dayjs";
import { parse } from "mrz";
import { useAppLanguage } from "provider/app-language";
import { useAppLoading } from "provider/app-loading";
import React from "react";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetCustomerCheckins,
  usePostCustomerCheckins,
  usePostIdCard,
  usePostIdPassport,
  usePostSerialPort,
} from "services/check-in";
import {
  ICustomerCheckin,
  ICustomerCheckinParams,
  IIdCardPDK,
  IIdPassportMRZ,
  // IPassportMRZData,
} from "services/check-in/interface";
import styled from "styled-components";
import tw from "twin.macro";

const UserManagementCheckinPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [params, setParams] = useState<ICustomerCheckinParams>({
    identityCode: "",
  });

  const postIdCard = usePostIdCard();
  const postIdPassport = usePostIdPassport();
  const { data: postPort } = usePostSerialPort();
  const { data, isFetching } = useGetCustomerCheckins({ ...params });

  const onClick = () => {
    const values = form.getFieldsValue();
    setParams({ ...params, ...values });
  };

  const onClear = () => {
    form.resetFields();
    form.submit();
  };

  const getIdCard = () => {
    // console.log(postIdCard)
    // if(postIdCard.data?.id !== "") {
    postIdCard.mutate(
      {},
      {
        onSuccess: (data: IIdCardPDK) => {
          if (data.id) {
            mapKeyIdCard(data);
            onClick();
          } else {
            postPort?.forEach((port) => {
              postIdPassport.mutate(
                { comPort: port },
                {
                  onSuccess: (data: IIdPassportMRZ) => {
                   const splitData = data.passData.split("\r\n").filter((item) => item);
                    if (splitData.length > 0) {
                      const result = parse(splitData);
                      const dataResult = result.fields 
                      form.setFieldsValue({ identityCode: dataResult.documentNumber });
                      onClick();
                    }else{
                      fireNotification({
                        type: "error",
                        description: t("insert-your-id-card"),
                      });
                    }
                  },
                  onError: ({ message }: any) => {
                    fireNotification({ type: "error", description: message });
                  },
                }
              );
            });
          }
        },
        onError: ({ message }: any) => {
          fireNotification({ type: "error", description: message });
        },
      }
    );

    // }
    // } else {
    // postPort?.forEach((port)=>{

    //   postIdPassport.mutate(
    //     {comPort:port},
    //     {
    //       onSuccess: (data: IIdPassportMRZ) => {
    //         console.log(data.passData.split("\g\r\n"))
    //         // if (data.id) {
    //         //   mapKeyIdCard(data);
    //         // } else {
    //         //   fireNotification({
    //         //     type: "error",
    //         //     description: t("insert-your-id-card"),
    //         //   });
    //         // }
    //       },
    //       onError: ({ message }: any) => {
    //         fireNotification({ type: "error", description: message });
    //       },
    //     }
    //     );
    //   }) ;
    // }
  };


  const mapKeyIdCard = (data: IIdCardPDK) => {
    const dataIdCard = {
      iNum: data.txtINum,
      idNum: data.id,
      titleT: data.txtTitle_T,
      nameT: data.txtname_T,
      middleNameT: data.txtMiddlename_T,
      lastNameT: data.txtLastName_T,
      titleE: data.txtTitle_E,
      nameE: data.txtName_E,
      middleNameE: data.txtMiddlename_E,
      lastNameE: data.txtLastName_E,
      bDate: data.txtBDate,
      houseNo: data.txthouseNo,
      moo: data.txtMoo,
      trok: data.txtTrok,
      soi: data.txtSoi,
      thanon: data.txtThanon,
      tambon: data.txtTambon,
      amphoe: data.txtAmphoe,
      province: data.txtProvince,
      gender: data.txtGender,
      iDate: data.txtIDate,
      xDate: data.txtXDate,
      iPlace: data.txtIPlace,
      cardImage: data.image,
      cardType: "IDCARD",
    };

    form.setFieldsValue({ identityCode: dataIdCard.idNum });
  };

  // const listHeight = Number(document.getElementById("form")?.offsetHeight);

  return (
    <PageContent breadcrumb>
      <div className="m-5">
        <Form id="form" form={form} onFinish={onClick}>
          <Container height="100%" className=" center flex-col w-full">
            <Row className="w-full !flex justify-center">
              <Typography.Title level={2} className="!text-primary-dark pr-2">
                CHECK IN
              </Typography.Title>
              <Typography.Title level={2}>เช็คอิน</Typography.Title>
            </Row>
            <Row gutter={[12, 12]} className="!flex justify-center pt-5 w-full">
              <Col span={6}></Col>
              <Col span={12}>
                <Form.Item name="identityCode" noStyle>
                  <CInput placeholder={t("identification-card-number")} />
                </Form.Item>
              </Col>
              <Col span={6}></Col>

              <Col span={6}></Col>
              <Col span={4}>
                <CButton
                  style={{ height: 45, width: "100%" }}
                  theme="primary"
                  onClick={getIdCard}
                  loading={postIdCard.isLoading || isFetching}
                >
                  {t("retrieve-id-card-information")}
                </CButton>
              </Col>
              <Col span={4}>
                <CButton
                  style={{ height: 45, width: "100%" }}
                  theme="yellow"
                  // htmlType="submit"
                  loading={postIdCard.isLoading || isFetching}
                  onClick={onClick}
                >
                  {t("search")}
                </CButton>
              </Col>
              <Col span={4}>
                <CButton
                  style={{ height: 45, width: "100%" }}
                  theme="gray"
                  loading={postIdCard.isLoading || isFetching}
                  onClick={onClear}
                >
                  {t("clear")}
                </CButton>
              </Col>
              <Col span={6}></Col>
            </Row>

            <Row className="!flex justify-center pt-5 w-full h-full">
              <Col xl={3} lg={2} sm={1}></Col>
              <Col xl={18} lg={20} sm={22} className="w-full">
                <Row
                  gutter={[12, 12]}
                  className="!flex justify-start pt-5 w-full"
                >
                  {!params.identityCode ? (
                    <Image src={IdentityCard} preview={false} height="100%" />
                  ) : (
                    <ListCheckInWrapper
                      className="px-2 pb-3 w-full "
                      // height={`${listHeight}px`}
                      height={`calc(100vh - 250px - 175px)`}
                      gutter={[12, 0]}
                    >
                      {isFetching && (
                        <div className="!flex ju justify-center w-full h-full">
                          <AppLoadingLoader />
                        </div>
                      )}
                      {(data?.length || 0) <= 0 && !isFetching && <EmptyPage />}
                      {(data?.length || 0) > 0 && (
                        <React.Fragment>
                          {data?.map((item: ICustomerCheckin) => {
                            return (
                              <Card
                                booking={item}
                                key={item.id}
                                onClear={onClear}
                              />
                            );
                          })}
                        </React.Fragment>
                      )}
                    </ListCheckInWrapper>
                  )}
                </Row>
              </Col>
              <Col xl={3} lg={2} sm={1}></Col>
            </Row>
          </Container>
        </Form>
      </div>
    </PageContent>
  );
};

const ListCheckInWrapper = styled(Row) <{ height?: string }>`
  height: ${({ height }) => height};
  min-hegiht: 1px;
  ${tw`rounded-app`};
  overflow-y: auto;
`;

const Detail = styled.div<{ bgColor?: string }>`
  height: 100%;
  background-color: ${({ bgColor }) => bgColor};
  padding: 0.75rem;
`;

const statusIcon: { [k in AcceptStatusType]: ReactElement } = {
  APPROVE: <CheckCircleFilled style={{ fontSize: 52, color: "#47B174" }} />,
  PENDING: <ClockCircleFilled style={{ fontSize: 52, color: "#0888CC" }} />,
  REJECT: <CloseCircleFilled style={{ fontSize: 52, color: "#DB1212" }} />,
};

const statusText: { [k in AcceptStatusType]: string } = {
  APPROVE: "approve",
  PENDING: "approval-pending",
  REJECT: "disapproval",
};

const statusColor: { [k in AcceptStatusType]: { text: string; bg: string } } = {
  APPROVE: { text: "#47B174", bg: "#EAF7EC" },
  PENDING: { text: "#0888CC", bg: "#EAF4F7" },
  REJECT: { text: "#DB1212", bg: "#FFE6E6" },
};

const StyledContainer = styled.div`
  min-height: 1px;
  ${tw`rounded-app shadow-sm`};
`;

const BoxShadowDiv = styled(StyledContainer)`
  box-shadow: 1px 1px 21px 2px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 1px 1px 21px 2px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 1px 1px 21px 2px rgba(0, 0, 0, 0.11);
`;

const Card = ({
  booking,
  onClear,
}: {
  booking: ICustomerCheckin;
  onClear: () => void;
}) => {
  const { t } = useTranslation();
  const { setAppLoading } = useAppLoading();
  const { formatDate } = useAppLanguage();

  const post_checkin = usePostCustomerCheckins();

  const checkIn = () => {
    setAppLoading(true);
    post_checkin.mutate(
      { customerId: booking.id },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "user-management" });
          onClear();
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "user-management",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const date = formatDate({
    date: booking.createdAt,
    format: "full-month-time",
  });

  return (
    <Col xl={12} lg={24} sm={24} className="mt-3 w-full">
      <BoxShadowDiv className="w-full p-4">
        <Row gutter={[12, 0]}>
          <Col span={17}>
            <Detail bgColor="#f5f5f5">
              <LableLeft
                title={t("identity-card-number")}
                value={booking?.identityCode || "-"}
              />
              <LableLeft
                title={t("full-name")}
                value={`${booking?.firstName} ${booking?.lastName}`}
              />
              <LableLeft
                title={t("phone-number")}
                value={booking?.phoneNumber || "-"}
              />
              <LableLeft title={t("request-time")} value={date} />
            </Detail>
          </Col>
          <Col span={7}>
            <Detail
              bgColor={statusColor[booking?.acceptStatus]?.bg}
              className="!flex justify-center"
            >
              <LableRight
                text={t(statusText[booking?.acceptStatus])}
                icon={statusIcon[booking?.acceptStatus]}
                color={statusColor[booking?.acceptStatus]?.text}
              />
            </Detail>
          </Col>
          <Divider
            style={{ borderTop: "1px solid #f5f5f5" }}
            className="!my-2"
          />
          <Col xl={8} lg={4} sm={0}></Col>
          <Col xl={8} lg={16} sm={24}>
            <CButton
              style={{ height: 45, width: "100%" }}
              theme="yellow"
              onClick={checkIn}
              loading={post_checkin.isLoading}
              disabled={booking?.acceptStatus !== "APPROVE"}
            >
              {t("check-in")}
            </CButton>
          </Col>
          <Col xl={8} lg={4} sm={0}></Col>
        </Row>
      </BoxShadowDiv>
    </Col>
  );
};

const LableStyle = styled.div`
  .title {
    font-size: 14px;
    color: #8f949d;
  }
  .value {
    font-size: 14px;
    color: #202a3c;
  }
`;

const LableLeft = ({ title, value }: { title: string; value: string }) => {
  return (
    <LableStyle className="mb-2">
      <span className="title pr-1">{title}</span>:
      <span className="value pl-1"> {value}</span>
    </LableStyle>
  );
};

const LableRight = ({
  text,
  icon,
  color,
}: {
  text: string;
  icon: ReactElement<any, any>;
  color: string;
}) => {
  return (
    <Row>
      <Col span={24} className="!flex justify-center pt-4">
        {icon}
      </Col>
      <Col span={24}>
        <div className="!flex justify-center" style={{ color: color }}>
          {text}
        </div>
      </Col>
    </Row>
  );
};

export default UserManagementCheckinPage;
