import { TableColumnsType, Typography } from "antd";
import AccessibilityCardTable from "components/display/access-card-table";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { typeOfUser } from "config/axios/interface";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useExportDoorLog, useGetDoorLogs } from "services/door-log";
import { IDoorLog } from "services/door-log/interface";
import { IDoorParams } from "services/door/interface";

const AccessibilityReportPage = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState<IDoorParams>({ page: 1, limit: 10 });
  const data_accessibility = useGetDoorLogs(params);
  const export_excel = useExportDoorLog();

  const { formatDate } = useAppLanguage();

  const elements: ISearchBoxElement[] = [
    {
      name: "identityCode",
      label: t("identification-card-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "fullname",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "actionType",
      label: t("save-type"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "doorName",
      label: t("source-of-events"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "date",
      label: t("date"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
  ];

  const columns: TableColumnsType<IDoorLog> = [
    {
      title: t("profile-picture"),
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc?.imageUrl} />;
      },
    },
    {
      title: t("identification-card-number"),
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis>{rc?.identityCode || "-"}</Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return "-";

        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      key: "userType",
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        const userTypeKey = rc?.personType;
        if (!userTypeKey) return <span>-</span>;
        const text = t(typeOfUser[userTypeKey]);
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      title: t("save-type"),
      render: (_, rc) => {
        const actionType = rc?.actionType === "door" ? t("door") : rc?.actionType;
        if (!rc?.actionType) return "-";
        return <Typography.Text ellipsis>{actionType}</Typography.Text>;
      },
    },
    {
      title: t("accessibility"),
      align: "center",
      render: (_, rc) => {
        return <AccessibilityCardTable active={rc?.eventType || ""} />;
      },
    },
    {
      title: t("source-of-events"),
      render: (_, rc) => {
        return <Typography.Text>{rc?.door?.doorName || "-"}</Typography.Text>;
      },
    },
    {
      title: t("date"),
      render: (_, rc) => {
        const date = formatDate({
          date: rc?.actionTime,
          format: "nomal-full-time",
        });

        return (
          <Typography.Text ellipsis={{ tooltip: date }}>
            {date || "-"}
          </Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    delete values.date;

    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        scroll={{ x: 1200 }}
        title={t("access-control.accessibility-report", { ns: "menu" })}
        selectAble={false}
        columns={columns}
        query={data_accessibility}
        action={{
          excels: {
            export: {
              mutation: export_excel,
            },
          },
        }}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default AccessibilityReportPage;
