import { Divider, TableColumnsType, Typography } from "antd";
import CTable from "components/display/c-table";
import Container from "components/display/container";
import CUserTypeSelect from "components/input/c-user-type";
import { ISearchBoxElement } from "components/layout/page-search-box";
import dayjs from "config/dayjs";
import { useAppLanguage } from "provider/app-language";
import { useSprayCheckProvider } from "provider/inspection-spray-check";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportExcelSprayRecevier,
  useExportExcelSpraySupplier,
  useGetCheckSprays,
} from "services/check-the-spray";
import {
  ICheckSpray,
  ICheckSprayParams,
} from "services/check-the-spray/interface";
import CheckSprayEmployeeContent from "./content-employee";

const contentPage = {
  "spray-employee": <CheckSprayEmployeeContent />,
};

const SprayCheckContent = () => {
  const { t } = useTranslation();
  const { userType, setUserType, selectDate } = useSprayCheckProvider();

  const SDate = selectDate
    ? dayjs(selectDate).startOf("day").toISOString()
    : undefined;
  const EDate = selectDate
    ? dayjs(selectDate).endOf("day").toISOString()
    : undefined;

  const [params, setParams] = useState<ICheckSprayParams>({
    page: 1,
    limit: 10,
  });

  const data_check_spray = useGetCheckSprays({
    ...params,
    startDate: SDate,
    endDate: EDate,
    userType: userType?.userType,
  });

  const { formatDate } = useAppLanguage();

  const export_receiver = useExportExcelSprayRecevier();
  const export_supplier = useExportExcelSpraySupplier();

  const elements: ISearchBoxElement[] = [
    {
      label: t("license-plate-number"),
      name: "licensePlate",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("full-name"),
      name: "name",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("phone-number"),
      name: "phoneNumber",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("company-name"),
      name: "companyName",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("status"),
      name: "liquidSpray",
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              {
                key: true,
                label: t("active-spray"),
              },
              {
                key: false,
                label: t("no-activ-spray"),
              },
            ],
            valueKey: "key",
            titleKey: "label",
          },
        },
      },
    },
  ];

  const columns: TableColumnsType<ICheckSpray> = [
    {
      title: t("transaction-date"),
      render: (_, rc) => {
        const date = formatDate({
          date: rc?.createdAt,
          format: "normal-time",
        });
        return <Typography.Text>{date}</Typography.Text>;
      },
    },
    {
      title: t("license-plate-number"),
      render: (_, rc) => {
        return (
          <Typography.Text>{rc?.vehicle?.licensePlate || "-"}</Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.customerEmployee?.firstName || "";
        const lastName = rc?.customerEmployee?.lastName || "";
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        const phoneNumber = rc?.customerEmployee?.phoneNumber || "-";
        return <Typography.Text ellipsis>{phoneNumber}</Typography.Text>;
      },
    },
    {
      title: t("company-name"),
      render: (_, rc) => {
        const companyName = rc?.customerEmployee?.companyName || "-";
        return <Typography.Text>{companyName || "-"}</Typography.Text>;
      },
    },
    {
      title: t("status"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        const supplierStatus = rc?.sprayStatus;
        const receiverStatus = rc?.sprayStatus;
        return (
          <>
            {(supplierStatus || receiverStatus) === true ? (
              <div
                className="!p-1 shadow-sm text-center rounded-app"
                style={{
                  backgroundColor: "#EAF7EC",
                  border: "2px solid #47B174",
                }}
              >
                <Typography.Text ellipsis style={{ color: "#47B174" }}>
                  {t("active-spray")}
                </Typography.Text>
              </div>
            ) : (
              <div
                className="!p-1 shadow-sm text-center rounded-app"
                style={{
                  backgroundColor: "#FFF6E0",
                  border: "2px solid #E0B33C",
                }}
              >
                <Typography.Text ellipsis style={{ color: "#E0B33C" }}>
                  {t("no-activ-spray")}
                </Typography.Text>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    delete values.date;

    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  return (
    <Container height="auto" padding="p-0">
      <div className="p-5">
        <CUserTypeSelect
          accepts={["RECEIVER", "SUPPLIER", "EMPLOYEE"]}
          onChange={setUserType}
          value={userType}
        />
      </div>
      <Divider className="!mt-3 !mb-0" />
      {userType?.userType === "EMPLOYEE" ? (
        <>{contentPage["spray-employee"]}</>
      ) : (
        <CTable
          wrapperClassName="!mt-0"
          title={
            userType.userType === "RECEIVER"
              ? t("user-management.consignee", { ns: "menu" })
              : userType.userType === "SUPPLIER"
              ? t("user-management.supplier", { ns: "menu" })
              : ""
          }
          action={{
            searchBox: { elements: elements, onFinish: onSearch },
            excels: {
              export: {
                mutation:
                  userType?.userType === "RECEIVER"
                    ? export_receiver
                    : export_supplier,
              },
            },
          }}
          columns={columns}
          shadow={false}
          query={data_check_spray}
          pagination={{
            onChange: (page, limit) => {
              setParams({ ...params, page, limit });
            },
          }}
          selectAble={false}
        />
      )}
    </Container>
  );
};

export default SprayCheckContent;
