import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { UserTypeKey } from "config/axios/interface";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteOrganization,
  useDeleteOrganizations,
  useGetOrganizations,
} from "services/user-company";
import {
  IOrganization,
  IOrganizationParams,
} from "services/user-company/interface";

const userType: { [K in UserTypeKey]: string } = {
  RECEIVER: "consignee",
  CONTRACTOR: "contractor-full-time",
  VISITOR: "guests",
  SUPPLIER: "supplier-deliver-goods",
  EMPLOYEE: "",
};

const UserCompanyPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IOrganizationParams>();

  const get_users = useGetOrganizations(params);
  const del_user = useDeleteOrganization();
  const del_users = useDeleteOrganizations();

  const elements: ISearchBoxElement[] = [
    {
      name: "name",
      label: t("company-name"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },

    {
      name: "email",
      label: t("email"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "contactName",
      label: t("name-of-contact-person"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "userType",
      label: t("person-type"),
      input: {
        type: "SelectUserType",
      },
    },
  ];

  const columns: ColumnsType<IOrganization> = [
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url} />;
      },
    },
    {
      title: t("company-name"),
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        if (!rc?.name) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.name }}>
            {rc?.name}
          </Typography.Text>
        );
      },
    },

    {
      title: t("phone-number"),
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        if (!rc?.phoneNumber) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.phoneNumber }}>
            {rc?.phoneNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("email"),
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        if (!rc?.email) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.email }}>
            {rc?.email}
          </Typography.Text>
        );
      },
    },
    {
      title: t("name-of-contact-person"),
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        if (!rc?.contactName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.contactName }}>
            {rc?.contactName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      width: 200,
      render: (_, rc) => {
        if (!rc?.customer?.userType) return <span>-</span>;
        const text = t(userType[rc?.customer?.userType]);
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        scroll={{ x: "auto" }}
        query={get_users}
        title={t("guest-list")}
        columns={columns}
        onDelete={{
          mutation: del_user,
          invalidateQueries: ["get-organizations", "get-organization"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        action={{
          bulkDelete: {
            mutation: del_users,
            invalidateQueries: ["get-organizations", "get-organization"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onAdd="user-management.company-add"
        onRowClick="user-management.company-edit"
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default UserCompanyPage;
