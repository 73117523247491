import { Col, Row } from "antd";
import PageContent from "components/layout/page-content";
import withRealTimeFaceMonitoring from "provider/realtime-face-monitoring";
import RealtimeFaceMonitoringContent from "./content";
import RealtimeFaceMonitoringSidebar from "./sidebar";

const RealtimeFaceMonitoringPage = () => {
  return (
    <PageContent>
      <Row>
        <Col span={6}>
          <RealtimeFaceMonitoringSidebar />
        </Col>
        <Col span={18} className='p-5'>
          <RealtimeFaceMonitoringContent />
        </Col>
      </Row>
    </PageContent>
  );
};

export default withRealTimeFaceMonitoring(RealtimeFaceMonitoringPage);
