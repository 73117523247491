import { PlusOutlined, EditOutlined, DeleteFilled, VideoCameraAddOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { FC } from "react";
import CButton from "./c-button";

export interface ActionButtonGroupProps {
  onAdd?: () => void;
  onEdit?: () => void;
  onAddCamera?:()=>void;
  onDelete?: () => void;
  hide?: {
    add?: boolean;
    edit?: boolean;
    addCamera?:boolean;
    del?: boolean;
  };
}

const ActionButtonGroup: FC<ActionButtonGroupProps> = ({
  onAdd,
  onEdit,
  onAddCamera ,
  onDelete,
  hide,
}) => {
  const { add, edit, addCamera = true ,del } = hide || {};

  return (
    <Row gutter={6}>
      <Col hidden={add}>
        <CButton
          onClick={onAdd}
          style={{ height: 35, width: 35 }}
          theme="white"
          icon={<PlusOutlined />}
        />
      </Col>
      <Col hidden={edit}>
        <CButton
          onClick={onEdit}
          style={{ height: 35, width: 35 }}
          theme="white"
          icon={<EditOutlined />}
        />
      </Col>
      <Col hidden={addCamera}>
        <CButton
          onClick={onAddCamera}
          style={{ height: 35, width: 35 }}
          theme="white"
          icon={<VideoCameraAddOutlined />}
        />
      </Col>
      <Col hidden={del}>
        <CButton
          onClick={onDelete}
          style={{ height: 35, width: 35 }}
          theme="white"
          icon={<DeleteFilled />}
        />
      </Col>
    </Row>
  );
};

export default ActionButtonGroup;
