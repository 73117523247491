import { Table } from "antd";
import styled from "styled-components";
import tw from "twin.macro";

export const StyledTable = styled(Table)`
  th {
    background-color: #ebebeb !important;
    color: black !important;
    font-size: 20px;
  }
  //on row hover
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #f0faf3;
  }

  .ant-pagination-item-link {
    font-size: 18px;
    ${tw`flex items-center justify-center`};
  }

  // on row selection
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #f0faf3;
  }

  .ant-table th.ant-table-selection-column {
    font-size: 22px;
  }
  .ant-table-tbody > tr > td {
    padding: 12px 16px;
    font-size: 20px;
  }

  .ant-select,
  .ant-pagination,
  .ant-pagination-total-text {
    font-size: 20px;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    ${tw`flex justify-center items-center`};
    .ant-pagination-item-ellipsis {
      ${tw`flex justify-center items-center`};
    }
    .ant-pagination-item-container {
      ${tw`flex`};
    }
  }

  .ant-select-single.ant-select-sm: not(.ant-select-customize-input)
    .ant-select-selector {
    height: 30px;
    padding: 4px 7px;
  }

  .ant-table-row-expand-icon {
    width: 23px;
    height: 23px;
  }
  .ant-table-row-expand-icon::before {
    top: 10px;
  }
  .ant-table-row-expand-icon::after {
    left: 10px;
  }
`;
