import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import BlockColor from "components/display/card/block-color";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IUserParams } from "services/user/interface";
import {
  useDeleteVisitor,
  useDeleteVisitors,
  useExportTemplateVisitors,
  useGetVisitors,
  useImportExcelVisitors,
  useImportProfileVisitors,
} from "services/visitor";
import { IVisitor } from "services/visitor/interface";

const UserGuestPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IUserParams>({ page: 1, limit: 10 });

  const get_Visitors = useGetVisitors(params);
  const del_Visitor = useDeleteVisitor();
  const del_Visitors = useDeleteVisitors();
  const export_template = useExportTemplateVisitors();
  const import_excel = useImportExcelVisitors();
  const import_profile = useImportProfileVisitors();

  const elements: ISearchBoxElement[] = [
    {
      name: "identityCode",
      label: t("identity-card-number"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "companyName",
      label: t("organization"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "licensePlateNumber",
      label: t("license-plate-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    // {
    //   name: "temperatureVerify",
    //   label: t("temperature"),
    //   input: {
    //     type: "Select",
    //     options: {
    //       valueOptions: {
    //         values: [
    //           { key: true, value: t("pass") },
    //           { key: false, value: t("not-pass") },
    //         ],
    //         valueKey: "key",
    //         titleKey: "value",
    //       },
    //     },
    //   },
    // },
    {
      name: "employeeName",
      label: t("contact-recipient"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },

    {
      name: "registerType",
      label: t("formation"),
      input: {
        type: "SelectRegisterType",
      },
    },
  ];

  const columns: ColumnsType<IVisitor> = [
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url} />;
      },
    },
    {
      title: t("identity-card-number"),
      dataIndex: "identityCode",
      align: "center",
      ellipsis: true,
      render: (_, rc) => {
        if (!rc?.identityCode) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.identityCode }}>
            {rc?.identityCode}
          </Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      align: "center",
      width: 200,
      render: (_, rc) => {
        if (!rc?.firstName && !rc?.lastName) return <div>-</div>;
        const text = `${rc?.firstName} ${rc?.lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      align: "center",
      width: 200,
      dataIndex: "phoneNumber",
      render: (_, rc) => {
        if (!rc?.phoneNumber) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.phoneNumber }}>
            {rc?.phoneNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("organization"),
      align: "center",
      dataIndex: "d",
      render: (_, rc) => {
        if (!rc?.companyName) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.companyName }}>
            {rc?.companyName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("license-plate"),
      align: "center",
      dataIndex: "e",
      render: (_, rc) => {
        if (!rc?.visitorsDetail?.licensePlateNumber) return "-";
        return (
          <Typography.Text
            ellipsis={{ tooltip: rc?.visitorsDetail?.licensePlateNumber }}
          >
            {rc?.visitorsDetail?.licensePlateNumber}
          </Typography.Text>
        );
      },
    },
    // {
    //   title: t("temperature"),
    //   align: "center",
    //   dataIndex: "e",
    //   width: 200,
    //   render: (_, rc) => {
    //     if (typeof rc?.temperatureVerify !== "boolean") return <span>-</span>;
    //     return (
    //       <BlockColor value={`${rc?.temperatureVerify}`} type="temperature" />
    //     );
    //   },
    // }
    {
      title: t("contact-recipient"),
      align: "center",
      width: 200,
      render: (_, rc) => {
        if (!rc.visitorsDetail) {
          return <span>-</span>
        } else {
          if (!rc.visitorsDetail.employee) return <span>-</span>;
          const { employee } = rc?.visitorsDetail;
          const name = `${employee?.firstName} ${employee?.lastName}`;
          return (
            <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
          );
        }
      },
    },
    {
      title: t("check-in"),
      align: "center",
      render: (_, rc) => {
        if (rc?.isCheckin == null) return <span>-</span>;
        return <BlockColor value={rc?.isCheckin.toString()} type="isCheckin" />;
      },
    },

    {
      title: t("formation"),
      align: "center",
      // width: 200,
      render: (_, rc) => {
        if (!rc?.registerType) return <span>-</span>;
        return <BlockColor value={rc?.registerType} type="registerType" />;
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({
      ...params,
      ...values,
      page: 1,
    });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        scroll={{ x: 1500 }}
        query={get_Visitors}
        title={t("guest-list")}
        columns={columns}
        onDelete={{
          mutation: del_Visitor,
          invalidateQueries: ["get-visitors", "get-visitor"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        action={{
          bulkDelete: {
            mutation: del_Visitors,
            invalidateQueries: ["get-visitors", "get-visitor"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
          excels: {
            template: {
              mutation: export_template,
              fileName: "visitor template",
            },
            import: {
              mutation: import_excel,
              invalidateQueries: ["get-visitors", "get-visitor"],
            },
            importProfile: {
              mutation: import_profile,
              invalidateQueries: ["get-visitors", "get-visitor"],
              typeUser: "guest",
            },
          },
        }}
        onAdd="user-management.guest-add"
        onRowClick="user-management.guest-edit"
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default UserGuestPage;
