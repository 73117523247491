import { useQueryClient } from "@tanstack/react-query";
import { Divider } from "antd";
import CInfiniteScroll from "components/display/c-infinite-scroll";
import CTableHeader from "components/display/c-table/c-table-header";
import PleaseSelectDataPage from "components/pages/select-data-page";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { useVehicleFloor } from "provider/vehicle-floor";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportExcelChildrenFloor,
  useExportExcelParentFloor,
  useFloorScroll,
} from "services/floor";
import { IFloor } from "services/floor/interface";
import { useDeleteMenuLevel } from "services/menu-level";
import { IMenuLevel } from "services/menu-level/interface";
import styled from "styled-components";
import tw from "twin.macro";
import FloorAddLevelDrawer from "./add-level-drawer";
import CreateAndEditFloorDrawer from "./create-and-edit-drawer";
import VehicleFloorDesciptionCard from "./description";

const Wrapper = styled.div`
  ${tw`p-5`};
  height: calc(100vh - 357px);
  background-color: #f1f1f1;
`;

const VehicleFloorContent = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleLevel, setVisibleLevel] = useState<boolean>(false);
  const [menuLevel, setMenuLevel] = useState<IMenuLevel>();

  const {
    selectedParking,
    selectedFloor,
    topLevel,
    levelNumber,
    levelId,
    levelName,
  } = useVehicleFloor();
  const { setAppLoading } = useAppLoading();
  const { dispatch, pageKey } = usePageRoute();

  const del_level = useDeleteMenuLevel();
  const qClient = useQueryClient();
  const export_parent = useExportExcelParentFloor();
  const export_children = useExportExcelChildrenFloor();

  const toggle = () => {
    setVisible(!visible);
  };

  const toggleLevel = () => {
    setVisibleLevel(!visibleLevel);
  };

  const onDeleteLevel = async () => {
    const confirm = await fireConfirmDelete();
    if (!confirm) return;
    setAppLoading(true);
    del_level.mutate(
      { id: levelId },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: pageKey });
          qClient.invalidateQueries(["get-menu-levels"]);
          qClient.invalidateQueries(["get-menu-level"]);
          dispatch({ action: "back" });
        },
        onError: ({ message }) => {
          fireNotification({
            type: "error",
            message,
            menu: pageKey,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const getOnAddFunction = () => {
    const firstLevel = levelNumber === 1 && selectedParking;
    const otherLevel = selectedParking && selectedFloor;
    if (firstLevel || otherLevel) {
      return { onClick: toggle, label: `+ ${t("add-floor")}` };
    }
    return;
  };

  const getExportExcel = () => {
    if (levelNumber === 1) {
      if (!selectedParking) return;
      return {
        export: {
          mutation: export_parent,
          extraValue: { parkingId: selectedParking.id },
        },
      };
    }
    if (!selectedParking || !selectedFloor) return;
    return {
      export: {
        mutation: export_children,
        extraValue: {
          parkingId: selectedParking.id,
          parentId: selectedFloor.id,
        },
      },
    };
  };

  const onAdd = getOnAddFunction();
  const export_excel = getExportExcel();

  return (
    <Wrapper>
      <CreateAndEditFloorDrawer onClose={toggle} visible={visible} />
      <FloorAddLevelDrawer
        menuLevel={menuLevel}
        onClose={toggleLevel}
        visible={visibleLevel}
        onAfterFinish={() => {
          setMenuLevel(undefined);
        }}
      />
      <CTableHeader
        title={t("list")}
        onAdd={onAdd}
        extraHeaderButton={[
          {
            children: `+ ${t("add-level")}`,
            theme: "white",
            onClick: toggleLevel,
            hidden: levelNumber < topLevel,
          },
        ]}
        action={{
          buttonTheme: "white",
          excels: export_excel,
          edit: {
            tooltipProps: { overlay: t("edit-level") },
            onClick: () => {
              const _menu = { id: levelId, levelName } as IMenuLevel;
              setMenuLevel(_menu);
              toggleLevel();
            },
          },
          bulkDelete: {
            tooltipProps: { overlay: t("delete-level") },
            btnProps: { hidden: levelNumber < topLevel },
            onClick: onDeleteLevel,
          },
        }}
      />
      <Divider className="!my-3" />
      <div style={{ height: "calc(100% - 70px)", overflowY: "auto" }}>
        <Content />
      </div>
    </Wrapper>
  );
};

const Content = () => {
  const { levelNumber } = useVehicleFloor();

  switch (levelNumber) {
    case 1:
      return <FirstLevel />;
    default:
      return <SecondLevel />;
  }
};

const FirstLevel = () => {
  const { selectedParking } = useVehicleFloor();
  const scroll = useFloorScroll({
    enabled: !!selectedParking,
    parkingId: selectedParking?.id,
  });

  if (!selectedParking) {
    return <PleaseSelectDataPage />;
  }

  return (
    <CInfiniteScroll
      height="100%"
      mutation={scroll}
      render={(data: IFloor[]) => {
        return data.map((item) => {
          return <VehicleFloorDesciptionCard {...item} key={item.id} />;
        });
      }}
    />
  );
};

const SecondLevel = () => {
  const { selectedFloor } = useVehicleFloor();
  const scroll = useFloorScroll({
    parentFloorId: selectedFloor?.id,
    enabled: !!selectedFloor?.id,
  });

  if (!selectedFloor) {
    return <PleaseSelectDataPage />;
  }

  return (
    <CInfiniteScroll
      height="100%"
      mutation={scroll}
      render={(data: IFloor[]) => {
        return data.map((item) => {
          return <VehicleFloorDesciptionCard {...item} key={item.id} />;
        });
      }}
    />
  );
};

export default VehicleFloorContent;
