import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import { ISearchBoxElement } from "components/layout/page-search-box";
import dayjs from "dayjs";
import { useAppLanguage } from "provider/app-language";
import { useUserPendingList } from "provider/user-pending-list";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetReqContarctor,
  useGetReqVisitor,
  useGetReqReceiver,
  useGetReqSupplier,
} from "services/req-approve-customer-employee";
import { IReqApproveParams } from "services/req-approve-customer-employee/interface";

const RejectedTabel = () => {
  const { t } = useTranslation();
  const [paramsVisitor, setParamsVisitor] = useState<IReqApproveParams>({
    acceptStatus: "REJECT",
  });
  const [paramsContractor, setParamsContractor] = useState<IReqApproveParams>({
    acceptStatus: "REJECT",
  });
  const [paramsReceiver, setParamsReceiver] = useState<IReqApproveParams>({
    acceptStatus: "REJECT",
  });
  const [paramsSupplier, setParamsSupplier] = useState<IReqApproveParams>({
    acceptStatus: "REJECT",
  });

  const { selected } = useUserPendingList();
  const { formatDate } = useAppLanguage();

  const get_visitor = useGetReqVisitor(paramsVisitor);
  const get_contractors = useGetReqContarctor(paramsContractor);
  const get_suppliers = useGetReqSupplier(paramsSupplier);
  const get_receivers = useGetReqReceiver(paramsReceiver);

  const get_user =
    selected?.userType === "VISITOR"
      ? get_visitor
      : selected?.userType === "CONTRACTOR"
        ? get_contractors
        : selected?.userType === "RECEIVER"
          ? get_receivers
          : selected?.userType === "SUPPLIER"
            ? get_suppliers
            : undefined;

  if (!selected) return null;

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("registration-date"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "userApproveName",
      label: t("staff"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  if (selected?.userType === "CONTRACTOR") {
    elements.push(
      {
        name: "phoneNumber",
        label: t("phone-number"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      },
      {
        name: "identityCode",
        label: t("contractor-id"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      }
    );
  } else {
    elements.push(
      {
        name: "identityCode",
        label: t("identification-card-number-passport-other"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      },
      {
        name: "employeeName",
        label: t("contact-recipient"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      }
    );
  }

  const columns: ColumnsType<any> = [
    {
      title: t("registration-date"),
      width: 200,
      render: (_, rc) => {
        const date = formatDate({
          date: rc.createdAt,
          format: "nomal-full-time",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc.profile?.url} />;
      },
    },
  ];

  if (selected?.userType === "CONTRACTOR") {
    columns.push(
      {
        title: t("identification-card-number-passport-other"),
        align: "center",
        render: (_, rc) => {
          return (
            <Typography.Text ellipsis>
              {rc?.employeeName || "-"}
            </Typography.Text>
          );
        },
      },
      {
        title: t("full-name"),
        ellipsis: true,
        width: 200,
        align: "center",
        render: (_, rc) => {
          const firstName = rc?.firstName || "";
          const lastName = rc?.lastName || "";

          if (!firstName && !lastName) return "-";
          const fullName = `${firstName} ${lastName}`;

          return (
            <Typography.Text ellipsis={{ tooltip: fullName }}>
              {fullName}
            </Typography.Text>
          );
        },
      },
      {
        title: t("phone-number"),
        align: "center",
        render: (_, rc) => {
          return (
            <Typography.Text ellipsis={{ tooltip: rc.phoneNumber || "-" }}>
              {rc.phoneNumber || "-"}
            </Typography.Text>
          );
        },
      },
      {
        title: t("staff"),
        render: (_, rc) => {
          const firstName =
            rc?.reqApproveDetail?.userApprove?.firstNameTh || "";
          const lastName = rc?.reqApproveDetail?.userApprove?.lastNameTh || "";

          if (!firstName && !lastName) return "-";

          return (
            <Typography.Text
              ellipsis
            >{`${firstName} ${lastName}`}</Typography.Text>
          );
        },
      }
    );
  } else {
    columns.push(
      {
        title: t("contact-recipient"),
        align: "center",
        render: (_, rc) => {
          const { visitorsDetail } = rc || {};
          const employee = visitorsDetail?.employee;

          const firstName = employee?.firstName;
          const lastName = employee?.lastName;

          if (!firstName && !lastName) return "-";
          const fullName = `${firstName} ${lastName}`;

          return <Typography.Text ellipsis>{fullName}</Typography.Text>;
        },
      },
      {
        title: t("full-name"),
        ellipsis: true,
        width: 200,
        align: "center",
        render: (_, rc) => {
          const firstName = rc?.firstName || "";
          const lastName = rc?.lastName || "";

          if (!firstName && lastName) return "-";
          const fullName = `${firstName} ${lastName}`;

          return (
            <Typography.Text ellipsis={{ tooltip: fullName }}>
              {fullName}
            </Typography.Text>
          );
        },
      },
      {
        title: t("phone-number"),
        align: "center",
        render: (_, rc) => {
          return (
            <Typography.Text ellipsis={{ tooltip: rc.phoneNumber || "-" }}>
              {rc.phoneNumber || "-"}
            </Typography.Text>
          );
        },
      },
      {
        title: t("staff"),
        render: (_, rc) => {
          const firstName =
            rc?.reqApproveDetail?.userApprove?.firstNameTh || "";
          const lastName = rc?.reqApproveDetail?.userApprove?.lastNameTh || "";

          if (!firstName && !lastName) return "-";

          return (
            <Typography.Text
              ellipsis
            >{`${firstName} ${lastName}`}</Typography.Text>
          );
        },
      }
    );
  }

  const onSearch = (values: any) => {
    const startDate = values.date
      ? dayjs(values.date[0]).startOf("day")
      : undefined;
    const endDate = values.date
      ? dayjs(values.date[1]).endOf("day")
      : undefined;
    values = { ...values, startDate, endDate };

    delete values.date;

    switch (selected?.userType) {
      case "VISITOR":
        return setParamsVisitor({ ...paramsVisitor, ...values });
      case "CONTRACTOR":
        return setParamsContractor({ ...paramsContractor, ...values });
      case "RECEIVER":
        return setParamsReceiver({ ...paramsReceiver, ...values });
      case "SUPPLIER":
        return setParamsSupplier({ ...paramsSupplier, ...values });
    }
  };

  const rowClickPage =
    selected?.userType === "VISITOR"
      ? "usre-management.pending-guest-edit"
      : selected?.userType === "CONTRACTOR"
        ? "usre-management.pending-contractor-edit"
        : selected?.userType === "RECEIVER"
          ? "usre-management.pending-receiver-edit"
          : selected?.userType === "SUPPLIER"
            ? "usre-management.pending-supplier-edit"
            : undefined;

  return (
    <CTable
      scroll={{ x: 1000 }}
      selectAble={false}
      title={t("rejected-list")}
      columns={columns}
      query={get_user}
      onRowClick={rowClickPage}
      action={{
        searchBox: { elements: elements, onFinish: onSearch },
        // excels: {},
      }}
      pagination={{
        onChange: (page, limit) => {
          switch (selected?.userType) {
            case "VISITOR":
              return setParamsVisitor({ ...paramsVisitor, page, limit });
            case "CONTRACTOR":
              return setParamsContractor({ ...paramsContractor, page, limit });
            case "RECEIVER":
              return setParamsReceiver({ ...paramsReceiver, page, limit });
            case "SUPPLIER":
              return setParamsSupplier({ ...paramsSupplier, page, limit });
          }
        },
      }}
    />
  );
};

export default RejectedTabel;
