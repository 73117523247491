import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";

import {
  IBusinessPartner,
  IBusinessPartnerParams,
  IPickupPoint,
  IPickupPointParams,
  IWorkArea,
  IWorkAreaParams,
} from "services/master/interface";

export const useGetPickupPoints = (
  params?: IPickupPointParams
): UseInfiniteQueryResult<IResponse<IPickupPoint[]>, Error> => {
  return useInfiniteQuery(
    ["get-pickup-points", params],
    async () => {
      const res = await axios.get("/pickup-point", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetPickupPoint = (
  id?: number
): UseQueryResult<IResponse<IPickupPoint>, Error> => {
  return useQuery(
    ["get-pickup-point", id],
    async () => {
      const res = await axios.get(`/pickup-point/${id}`);
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetBusinessPartner = (
  params?: IBusinessPartnerParams
): UseInfiniteQueryResult<IResponse<IBusinessPartner[]>, Error> => {
  return useInfiniteQuery(
    ["get-business-partner", params],
    async () => {
      const res = await axios.get("/business-partner", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetWorkArea = (
  params?: IWorkAreaParams
): UseInfiniteQueryResult<IResponse<IWorkArea[]>, Error> => {
  return useInfiniteQuery(
    ["get-work-area", params],
    async () => {
      const res = await axios.get("/work-area", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};
