import { Dayjs } from "dayjs";
import styled from "styled-components";
import { FC } from "react";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker, {
  PickerProps,
} from "antd/es/date-picker/generatePicker";
import { useTranslation } from "react-i18next";

export const CDate = generatePicker<Dayjs>(dayjsGenerateConfig);

const StyledDatePicker = styled(CDate)`
  height: 45px;
  width: 100%;
`;

type CDatePickerProps = PickerProps<Dayjs> & {
  showTime?: boolean;
};

const CDatePicker: FC<CDatePickerProps> = (props) => {
  const language = useTranslation().i18n.language as "en" | "th";

  const format: { en: string; th: string } = {
    en: `DD MMM YYYY${props.showTime ? " HH:mm" : ""}`,
    th: `DD MMM BBBB${props.showTime ? " HH:mm" : ""}`,
  };

  return (
    <StyledDatePicker
      format={format[language]}
      {...props}
      getPopupContainer={(e) => e}
    />
  );
};

export default CDatePicker;
