import { Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IMediaObject } from "services/doorway-emergency/interface";
import styled from "styled-components";
import CImage from "./c-image";

type SizeTypes = "small" | "default";
const sizes = {
  small: {
    height: 50,
    width: 100,
  },
  default: {
    height: 200,
    width: 400,
  },
};

const Wrapper = styled.div<{
  bordered: number;
  size: SizeTypes;
  width?: number;
}>`
  border: ${({ bordered }) => (bordered ? "1px solid #f0f0f0" : "")};
  padding: ${({ bordered }) => (bordered ? "1rem" : "")};
  height: ${({ size }) => sizes[size].height}px;
  width: ${({ width }) => width}px;
  position: relative;
  overflow: hidden;
  // display: inline-block;
`;

interface PlateNumberImageProps {
  value?: IMediaObject;
  bordered?: boolean;
  holder?: string;
  size?: "small" | "default";
}

const VehicleDetectImage = ({
  value,
  size = "small",
  bordered = true,
  holder,
}: PlateNumberImageProps) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const message = holder
    ? holder
    : t("license-plate-not-found", { ns: "message" });

  if (!value) {
    return (
      <Wrapper
        className="center"
        size={size}
        bordered={bordered ? 1 : 0}
        onClick={(e) => e.stopPropagation()}
        style={{ width: "100%" }}
      >
        <Typography.Text>{message}</Typography.Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      className="center"
      size={size}
      bordered={bordered ? 1 : 0}
      onClick={(e) => e.stopPropagation()}
    >
      <CImage
        onClick={(e) => e.stopPropagation()}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        wrapperStyle={{ height: "100%" }}
        className="pointer"
        preview={{
          visible: visible,
          onVisibleChange: setVisible,
          maskClassName: "d-none",
        }}
        src={value?.url}
      />
    </Wrapper>
  );
};

const VehicleDetectImageCar = ({
  value,
  size = "small",
  bordered = true,
  holder,
}: PlateNumberImageProps) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const message = holder ? holder : t("image-not-found", { ns: "message" });

  if (!value) {
    return (
      <Wrapper
        className="center"
        size={size}
        bordered={bordered ? 1 : 0}
        onClick={(e) => e.stopPropagation()}
        style={{ width: "100%" }}
      >
        <Typography.Text>{message}</Typography.Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      className="center"
      size={size}
      bordered={bordered ? 1 : 0}
      onClick={(e) => e.stopPropagation()}
    >
      <CImage
        onClick={(e) => e.stopPropagation()}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        wrapperStyle={{ height: "100%" }}
        className="pointer"
        preview={{
          visible: visible,
          onVisibleChange: setVisible,
          maskClassName: "d-none",
        }}
        src={value?.url}
      />
    </Wrapper>
  );
};

VehicleDetectImage.Car = VehicleDetectImageCar;

export default VehicleDetectImage;
