import { Typography, Divider, Row, Col, Form } from "antd";
import Container from "components/display/container";
import CUpload from "components/input/c-upload";
import { useTranslation } from "react-i18next";

const ImageField = ({ pending }: { pending: boolean }) => {
  const { t } = useTranslation();
  return (
    <Container height="100%" padding="p-0" className="mt-4">
      <div className="p-5">
        <Typography.Title level={5} className="!text-primary-dark">
          {t("data-file")}
        </Typography.Title>
      </div>
      <Divider className="!m-0" />
      <div className="p-5">
        <Row gutter={24}>
          {/* <Col span={4}>
            <CButton icon={<CameraFilled />} className="w-full">
              {t("take-card-picture")}
            </CButton>
          </Col> */}
          <Col span={24}>
            <Form.Item name="identityCardPicture" label={t("id-card-picture")}>
              <CUpload.Area readonly={pending} className="py-4" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ImageField;
