import { useQueryClient } from "@tanstack/react-query";
import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import withSettingScheduleProvider, {
  useSettingSchedule,
} from "provider/setting-schedule";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteScheduleGroup,
  useGetScheduleGroups,
} from "services/schedule-group";
import { IScheduleGroup } from "services/schedule-group/interface";
import SettingScheduleContent from "./content";
import CreateAndEditDrawer from "./drawer-sidebar";

const SettingSchedulePage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={18} className="p-5">
          <SettingScheduleContent />
        </Col>
        <Col span={6}>
          <Sidebar />
        </Col>
      </Row>
    </PageContent>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { selectedGroupTable, setSelectedGroupTable, setAction } =
    useSettingSchedule();
  const [visible, setVisible] = useState<boolean>(false);

  const { data } = useGetScheduleGroups();
  const delete_grouptable = useDeleteScheduleGroup();
  const qClient = useQueryClient();

  const toggle = () => {
    setVisible(!visible);
  };

  const render = () => {
    return data?.map((item: IScheduleGroup) => {
      return (
        <CCard.BasicCard
          active={selectedGroupTable?.id === item.id}
          className="mb-4"
          key={item.id}
          onClick={() => {
            if (item.id === selectedGroupTable?.id) {
              return setSelectedGroupTable(undefined);
            }
            return setSelectedGroupTable(item);
          }}
        >
          <Row align="middle" className="px-3 py-4 " gutter={12}>
            <Col>
              <div
                style={{ background: "red", width: "15px", height: "15px" }}
              />
            </Col>
            <Col>
              <Typography.Text>{`${item?.groupTableTh}`}</Typography.Text>
            </Col>
          </Row>
        </CCard.BasicCard>
      );
    });
  };

  const onDelete = async () => {
    const confirm = await fireConfirmDelete();
    if (confirm) {
      delete_grouptable.mutate(
        { id: selectedGroupTable?.id },
        {
          onSuccess: () => {
            qClient.invalidateQueries(["get-schedule-groups"]);
            fireNotification({ type: "success", menu: "setting" });
            setSelectedGroupTable(undefined);
          },
          onError: ({ message }: any) => {
            fireNotification({ type: "error", description: message });
          },
        }
      );
    }
  };

  return (
    <React.Fragment>
      <CreateAndEditDrawer visible={visible} onClose={toggle} />
      <InfiniteSidebar
        actionButtonGroup={{
          onAdd: () => {
            setAction?.("add");
            setSelectedGroupTable(undefined);
            toggle();
          },
          onEdit: () => {
            setAction?.("edit");
            toggle();
          },
          onDelete: onDelete,
          hide: { edit: !selectedGroupTable, del: !selectedGroupTable },
        }}
        title={t("group-management")}
        infiniteScroll={{ render: render, className: "px-5" }}
      />
    </React.Fragment>
  );
};

export default withSettingScheduleProvider(SettingSchedulePage);
