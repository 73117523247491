import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import axios, {throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IFaceCompareGroup, IFaceCompareGroupParams } from "./interface";

export const useGetFaceCompareGroups = (
  params?: IFaceCompareGroupParams
): UseQueryResult<IResponse<IFaceCompareGroup[]>> => {
  return useQuery(
    ["get-face-compare-group", params],
    async () => {
      const res = await axios.get("/face-compare-group", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetFaceCompareGroup = (id?:number) : UseQueryResult<IFaceCompareGroup> => {
  return useQuery(["get-face-compare-group-id",id] , async () => {
    if (!id) return;
    const res = await axios.get(`/face-compare-group/${id}`);
    if (res.status === 200 ) {
      return res.data.data;
    }
    throwResponse(res);
  })
};

export const usePostFaceCompareGroup  = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post("/face-compare-group", data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchFaceCompareGroup = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/face-compare-group/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteFaceCompareGroup = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/face-compare-group/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

