import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectStatusQueueCar: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("status")}
      {...props}
      valueOptions={{
        values: [
          { title: t("all"), value: "" },
          { title: t("check-the-spray"), value: "VEHICLE_SPRAY" },
          {
            title: t("check-the-condition-of-the-car"),
            value: "VEHICLE_CONDITION",
          },
          { title: t("ckeck-quick-lab"), value: "CHECK_QUICK_LAB" },
          {
            title: t("waiting-for-the-weighing-queue-in"),
            value: "WAITING_QUEUE_IN",
          },
          { title: t("wait-for-load-release"), value: "WAIT_FOR_LOAD" },
          { title: t("start-loading-release"), value: "START_LOADING" },
          { title: t("finish-loading-release"), value: "FINISH_LOADING" },
          { title: t("weighed-out"), value: "WEIGHED_OUT" },
          { title: t("the-car-has-successfully-left-the-TVOP"), value: "TVOP" },
        ],
        valueKey: "value",
        titleKey: "title",
      }}
    />
  );
};

export default CSelectStatusQueueCar;
