export const ExcelIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.647"
      height="23.091"
      data-name="Group 21377"
      viewBox="0 0 24.647 23.091"
      {...props}
    >
      <defs>
        <clipPath id="excel-clip-path">
          <path
            fill="#c4c4c4"
            d="M0 0H24.647V23.091H0z"
            data-name="Rectangle 19134"
          ></path>
        </clipPath>
      </defs>
      <g
        fill="#c4c4c4"
        clipPath="url(#excel-clip-path)"
        data-name="Group 21376"
      >
        <path
          d="M0 20.213V2.882a1.009 1.009 0 01.777-.594c1.467-.258 2.929-.542 4.394-.818L12.917.017a.766.766 0 01.945.76c.01.449 0 .9 0 1.347v.951H23.7c.669 0 .946.28.946.953v15.04c0 .675-.275.953-.945.953h-9.837v2.225a.782.782 0 01-.994.825Q6.882 21.949.893 20.835A1.061 1.061 0 010 20.213m23.09-1.745V4.627h-9.211v1.53h2.223a.772.772 0 11.007 1.54H13.881v1.54h2.183a.776.776 0 11.01 1.54h-2.197v1.54h2.246a.772.772 0 11-.01 1.54H13.88V15.4h2.229a.772.772 0 11-.006 1.538H13.875v1.528zM8.159 11.341c.05-.067.083-.112.118-.155q1.144-1.473 2.288-2.942a.807.807 0 00.2-.714.74.74 0 00-.6-.584.769.769 0 00-.795.333L7.312 9.918l-.184.23c-.072-.078-.128-.133-.179-.193-.577-.655-1.149-1.319-1.73-1.97A.768.768 0 104.067 9c.211.252.431.5.648.743L6.159 11.4c-.207.265-.4.517-.6.77-.506.652-1.018 1.3-1.519 1.957a.756.756 0 00.275 1.205.774.774 0 00.971-.3c.583-.745 1.161-1.494 1.742-2.24.05-.065.105-.128.168-.2l.354.4c.623.712 1.241 1.427 1.872 2.133a.754.754 0 001.094.086.763.763 0 00.058-1.1c-.1-.125-.211-.243-.316-.363l-2.1-2.4"
          data-name="Path 21860"
          transform="translate(0 .001)"
        ></path>
        <path
          d="M14.853 5.98c-.248 0-.5.006-.745 0a.768.768 0 11-.008-1.537q.77-.015 1.538 0a.768.768 0 11-.017 1.535c-.257.007-.513 0-.77 0"
          data-name="Path 21863"
          transform="translate(5.158 1.717)"
        ></path>
        <path
          d="M14.869 8.2c-.257 0-.513.007-.77 0a.767.767 0 01-.012-1.534c.52-.012 1.042-.012 1.562 0a.767.767 0 01-.01 1.534c-.257.008-.513 0-.77 0"
          data-name="Path 21864"
          transform="translate(5.158 2.576)"
        ></path>
        <path
          d="M14.857 10.422c-.257 0-.513.007-.77 0a.768.768 0 01-.759-.771.759.759 0 01.745-.761q.793-.021 1.585 0a.759.759 0 01.748.782.768.768 0 01-.756.75c-.264.008-.528 0-.793 0"
          data-name="Path 21865"
          transform="translate(5.158 3.436)"
        ></path>
        <path
          d="M14.864 12.644c-.257 0-.513.007-.77 0a.767.767 0 010-1.534c.52-.011 1.042-.012 1.562 0a.767.767 0 01-.019 1.534c-.257.007-.513 0-.77 0"
          data-name="Path 21866"
          transform="translate(5.158 4.296)"
        ></path>
      </g>
    </svg>
  );
};

export const BinIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.179"
      height="19.914"
      viewBox="0 0 16.179 19.914"
      {...props}
    >
      <defs>
        <clipPath id="BinIconclip-path">
          <path
            fill="#c4c4c4"
            d="M0 0H16.179V19.914H0z"
            data-name="Rectangle 20746"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 23097">
        <g
          fill="#c4c4c4"
          clipPath="url(#BinIconclip-path)"
          data-name="Group 23094"
        >
          <path
            d="M2.341 14.33a4.576 4.576 0 01-.563-.2 1.735 1.735 0 01-1.031-1.491c-.082-1.258-.132-2.521-.191-3.78l-.3-6.234a39.983 39.983 0 01-.036-.794c-.009-.164.064-.259.236-.254h13.607c.327 0 .363.041.345.373-.073 1.472-.136 2.949-.209 4.425-.1 2.067-.191 4.139-.3 6.211a1.812 1.812 0 01-1.535 1.714.459.459 0 00-.068.032zm1.245-7.156V11.218a.619.619 0 001.227.127 1.158 1.158 0 00.018-.25V3.262v-.177a.606.606 0 00-.4-.532.62.62 0 00-.845.618v4m3.108-.018v4.064a.619.619 0 001.227.127 1.18 1.18 0 00.014-.254V3.257v-.173a.622.622 0 00-1.24.086v3.985m3.112 0V11.217a.622.622 0 001.231.127 1.564 1.564 0 00.014-.254V3.257a1.082 1.082 0 000-.173.606.606 0 00-.4-.532.619.619 0 00-.845.618v3.985"
            data-name="Path 25688"
            transform="translate(.775 5.585)"
          ></path>
          <path
            d="M4.961 2.494c.018-.341.009-.654.055-.959A1.824 1.824 0 016.756.014a84.429 84.429 0 012.663 0A1.847 1.847 0 0111.2 1.786c.009.227 0 .454 0 .709h3.349a1.551 1.551 0 011.626 1.635q.007.566 0 1.131a.619.619 0 01-.659.654H.663a.608.608 0 01-.654-.654 9.99 9.99 0 01.036-1.476 1.488 1.488 0 011.518-1.29h3.399m1.263-.014h3.73c0-.227.009-.441 0-.654a.606.606 0 00-.6-.572q-1.261-.014-2.522 0a.61.61 0 00-.6.572c-.009.214 0 .427 0 .654"
            data-name="Path 25689"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const BarrierOpenIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.625"
      height="25.421"
      viewBox="0 0 23.625 25.421"
      {...props}
    >
      <g data-name="Group 27994" transform="translate(20131.73 21292.325)">
        <path
          fill="#fff"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M-20127.375-21267.414c-1.287 0-2.568.006-3.855.006v-1.734a.875.875 0 01.684-.572c.072-.01.088-.053.088-.115v-12.807c0-.678.27-.957.941-.957.715.006 1.428.006 2.143.012v3.066c-.457 0-.914.006-1.371.006-.137-.006-.178.037-.178.174.01.457.01.914.016 1.365-.006.516-.006 1.029-.006 1.539 0 .2.006.383-.01.578-.006.154.055.2.191.2-.137 0-.2-.045-.187-.2.012-.2.006-.383.006-.578h2.316c.768 0 1.533 0 2.3.006 0 2.479.006 4.953 0 7.428 0 .184.031.268.227.32a.705.705 0 01.545.725c.006.457 0 .914.006 1.371 0 .137-.037.178-.178.178-1.225-.011-2.453-.011-3.678-.011zm0-4.473q0-2.235.008-4.473-.011 2.239-.01 4.476zm-.912-4.768h.424-.424c-.143 0-.283 0-.42.006h.209c.07 0 .137-.008.209-.006zm.432 0a.441.441 0 00.051 0 .441.441 0 01-.057-.004zm.27-.008h0zm.213-3.85v-3.066c.783-.006 1.57-.012 2.354-.006a.737.737 0 01.725.74c.006.777.006 1.555.006 2.332z"
          data-name="Union 3"
        ></path>
        <path
          fill="#fff"
          d="M0 0H5.7V11H0z"
          data-name="Rectangle 23081"
          transform="translate(-20130 -21282)"
        ></path>
        <g data-name="Group 27993" transform="translate(-8.813 -6.436)">
          <path
            fill="#fafafa"
            d="M365.187 84.369h-4.573c.027-.089.107-.135.167-.2q.657-.674 1.319-1.343a.121.121 0 01.033-.009l3.054.01z"
            data-name="Path 30860"
            transform="rotate(-30 -49739.514 27587.813)"
          ></path>
          <path
            fill="#fafafa"
            d="M390.937 56.875l-3.054-.01 1.514-1.529h1.54z"
            data-name="Path 30861"
            transform="rotate(-30 -49678.215 27622.888)"
          ></path>
          <path
            fill="#fafafa"
            d="M43 55.193h3.083l.723.008c-.01.1-.1.139-.157.2q-.659.675-1.324 1.344h-1.55a.777.777 0 00-1.555 0h-.756c0-.456 0-.911-.01-1.367 0-.136.041-.179.177-.177.455 0 .911 0 1.367-.005"
            data-name="Path 30863"
            transform="rotate(-30 -49841.552 27012.368)"
          ></path>
          <path
            fill="#fafafa"
            d="M110.9 56.881c.442-.447.885-.894 1.324-1.344.059-.06.147-.1.157-.2h3.132L114 56.869a.107.107 0 00-.033.009h-3.065"
            data-name="Path 30864"
            transform="rotate(-30 -49809.013 27134.825)"
          ></path>
          <path
            fill="#fafafa"
            d="M223.056 55.341h3.132l-1.514 1.53a.108.108 0 00-.033.009h-3.066c.374-.38.751-.757 1.121-1.141a1.493 1.493 0 00.36-.4"
            data-name="Path 30865"
            transform="rotate(-30 -49756.766 27329.825)"
          ></path>
          <path
            fill="#fafafa"
            d="M336.865 55.346l-1.514 1.529a.12.12 0 00-.033.009h-3.066c.374-.38.751-.757 1.121-1.14a1.489 1.489 0 00.36-.4h3.132"
            data-name="Path 30866"
            transform="rotate(-30 -49704.502 27524.845)"
          ></path>
          <path
            fill="#fafafa"
            d="M171.071 55.341a1.493 1.493 0 01-.36.4c-.37.384-.747.761-1.121 1.141h-.051l-3.016-.014 1.515-1.53h3.033"
            data-name="Path 30867"
            transform="rotate(-30 -49782.758 27232.82)"
          ></path>
          <path
            fill="#fafafa"
            d="M281.746 55.345a1.489 1.489 0 01-.36.4c-.37.384-.747.761-1.121 1.14h-.051l-3.016-.014 1.515-1.53h3.033"
            data-name="Path 30868"
            transform="rotate(-30 -49730.51 27427.84)"
          ></path>
          <path
            fill="#fafafa"
            d="M85.425 84.533l-2.3-.006c.058-.064.115-.13.175-.192L84.619 83H87.735c-.1.11-.2.22-.307.329-.388.408-.816.777-1.178 1.21h-.824"
            data-name="Path 30870"
            transform="rotate(-30 -49870.858 27098.914)"
          ></path>
          <path
            fill="#fafafa"
            d="M195.146 83h3.117c-.1.11-.2.22-.307.329-.388.408-.815.777-1.178 1.21h-3.138L195.146 83"
            data-name="Path 30871"
            transform="rotate(-30 -49818.677 27293.654)"
          ></path>
          <path
            fill="#fafafa"
            d="M305.824 82.992h3.117l-1.319 1.343c-.06.061-.14.106-.167.2h-3.136l1.505-1.534"
            data-name="Path 30872"
            transform="rotate(-30 -49766.417 27488.679)"
          ></path>
          <path
            fill="#fafafa"
            d="M143.795 82.838l-1.505 1.533h-3.03c.363-.432.79-.8 1.178-1.21.1-.109.2-.219.307-.329a.108.108 0 01.033-.009l3.016.014"
            data-name="Path 30873"
            transform="rotate(-30 -49844.062 27197.754)"
          ></path>
          <path
            fill="#fafafa"
            d="M254.472 82.838l-1.505 1.534h-3.029c.363-.432.79-.8 1.178-1.21.1-.109.2-.219.307-.329a.108.108 0 01.033-.009l3.016.014"
            data-name="Path 30874"
            transform="rotate(-30 -49791.797 27392.8)"
          ></path>
          <path
            fill="#fafafa"
            d="M45.384 83.006l-1.321 1.34c-.061.062-.117.128-.175.192h-2.314v-1.537h.756a.777.777 0 001.555 0h1.5"
            data-name="Path 30875"
            transform="rotate(-30 -49890.496 27025.706)"
          ></path>
          <path
            fill="#e9e9e9"
            d="M56.774 69.957a.777.777 0 11-.765-.77.789.789 0 01.765.77"
            data-name="Path 30876"
            transform="rotate(-30 -49859.707 27043.226)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const BarrierCloseIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.348"
      height="17.189"
      viewBox="0 0 25.348 17.189"
      {...props}
    >
      <g data-name="Group 27995" transform="translate(20131.73 21284.094)">
        <path
          fill="#fff"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M-20127.375-21267.414c-1.287 0-2.568.006-3.855.006v-1.734a.875.875 0 01.684-.572c.072-.01.088-.053.088-.115v-12.807c0-.678.27-.957.941-.957.715.006 1.428.006 2.143.012v3.066c-.457 0-.914.006-1.371.006-.137-.006-.178.037-.178.174.01.457.01.914.016 1.365-.006.516-.006 1.029-.006 1.539 0 .2.006.383-.01.578-.006.154.055.2.191.2-.137 0-.2-.045-.187-.2.012-.2.006-.383.006-.578h2.316c.768 0 1.533 0 2.3.006 0 2.479.006 4.953 0 7.428 0 .184.031.268.227.32a.705.705 0 01.545.725c.006.457 0 .914.006 1.371 0 .137-.037.178-.178.178-1.225-.011-2.453-.011-3.678-.011zm0-4.473q0-2.235.008-4.473-.011 2.239-.01 4.476zm-.912-4.768h.424-.424c-.143 0-.283 0-.42.006h.209c.07 0 .137-.008.209-.006zm.432 0a.441.441 0 00.051 0 .441.441 0 01-.057-.004zm.27-.008h0zm.213-3.85v-3.066c.783-.006 1.57-.012 2.354-.006a.737.737 0 01.725.74c.006.777.006 1.555.006 2.332z"
          data-name="Union 3"
        ></path>
        <path
          fill="#fff"
          d="M0 0H5.7V11H0z"
          data-name="Rectangle 23081"
          transform="translate(-20130 -21282)"
        ></path>
        <g data-name="Group 27993" transform="rotate(30 -20110.967 -21294.273)">
          <path
            fill="#fafafa"
            d="M365.187 84.369h-4.573c.027-.089.107-.135.167-.2q.657-.674 1.319-1.343a.121.121 0 01.033-.009l3.054.01z"
            data-name="Path 30860"
            transform="rotate(-30 -49739.514 27587.813)"
          ></path>
          <path
            fill="#fafafa"
            d="M390.937 56.875l-3.054-.01 1.514-1.529h1.54z"
            data-name="Path 30861"
            transform="rotate(-30 -49678.215 27622.888)"
          ></path>
          <path
            fill="#fafafa"
            d="M43 55.193h3.083l.723.008c-.01.1-.1.139-.157.2q-.659.675-1.324 1.344h-1.55a.777.777 0 00-1.555 0h-.756c0-.456 0-.911-.01-1.367 0-.136.041-.179.177-.177.455 0 .911 0 1.367-.005"
            data-name="Path 30863"
            transform="rotate(-30 -49841.552 27012.368)"
          ></path>
          <path
            fill="#fafafa"
            d="M110.9 56.881c.442-.447.885-.894 1.324-1.344.059-.06.147-.1.157-.2h3.132L114 56.869a.107.107 0 00-.033.009h-3.065"
            data-name="Path 30864"
            transform="rotate(-30 -49809.013 27134.825)"
          ></path>
          <path
            fill="#fafafa"
            d="M223.056 55.341h3.132l-1.514 1.53a.108.108 0 00-.033.009h-3.066c.374-.38.751-.757 1.121-1.141a1.493 1.493 0 00.36-.4"
            data-name="Path 30865"
            transform="rotate(-30 -49756.766 27329.825)"
          ></path>
          <path
            fill="#fafafa"
            d="M336.865 55.346l-1.514 1.529a.12.12 0 00-.033.009h-3.066c.374-.38.751-.757 1.121-1.14a1.489 1.489 0 00.36-.4h3.132"
            data-name="Path 30866"
            transform="rotate(-30 -49704.502 27524.845)"
          ></path>
          <path
            fill="#fafafa"
            d="M171.071 55.341a1.493 1.493 0 01-.36.4c-.37.384-.747.761-1.121 1.141h-.051l-3.016-.014 1.515-1.53h3.033"
            data-name="Path 30867"
            transform="rotate(-30 -49782.758 27232.82)"
          ></path>
          <path
            fill="#fafafa"
            d="M281.746 55.345a1.489 1.489 0 01-.36.4c-.37.384-.747.761-1.121 1.14h-.051l-3.016-.014 1.515-1.53h3.033"
            data-name="Path 30868"
            transform="rotate(-30 -49730.51 27427.84)"
          ></path>
          <path
            fill="#fafafa"
            d="M85.425 84.533l-2.3-.006c.058-.064.115-.13.175-.192L84.619 83H87.735c-.1.11-.2.22-.307.329-.388.408-.816.777-1.178 1.21h-.824"
            data-name="Path 30870"
            transform="rotate(-30 -49870.858 27098.914)"
          ></path>
          <path
            fill="#fafafa"
            d="M195.146 83h3.117c-.1.11-.2.22-.307.329-.388.408-.815.777-1.178 1.21h-3.138L195.146 83"
            data-name="Path 30871"
            transform="rotate(-30 -49818.677 27293.654)"
          ></path>
          <path
            fill="#fafafa"
            d="M305.824 82.992h3.117l-1.319 1.343c-.06.061-.14.106-.167.2h-3.136l1.505-1.534"
            data-name="Path 30872"
            transform="rotate(-30 -49766.417 27488.679)"
          ></path>
          <path
            fill="#fafafa"
            d="M143.795 82.838l-1.505 1.533h-3.03c.363-.432.79-.8 1.178-1.21.1-.109.2-.219.307-.329a.108.108 0 01.033-.009l3.016.014"
            data-name="Path 30873"
            transform="rotate(-30 -49844.062 27197.754)"
          ></path>
          <path
            fill="#fafafa"
            d="M254.472 82.838l-1.505 1.534h-3.029c.363-.432.79-.8 1.178-1.21.1-.109.2-.219.307-.329a.108.108 0 01.033-.009l3.016.014"
            data-name="Path 30874"
            transform="rotate(-30 -49791.797 27392.8)"
          ></path>
          <path
            fill="#fafafa"
            d="M45.384 83.006l-1.321 1.34c-.061.062-.117.128-.175.192h-2.314v-1.537h.756a.777.777 0 001.555 0h1.5"
            data-name="Path 30875"
            transform="rotate(-30 -49890.496 27025.706)"
          ></path>
          <path
            fill="#e9e9e9"
            d="M56.774 69.957a.777.777 0 11-.765-.77.789.789 0 01.765.77"
            data-name="Path 30876"
            transform="rotate(-30 -49859.707 27043.226)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const CarWithCircleIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      {...props}
    >
      <defs>
        <filter
          id="car_with_circle_id"
          width="44"
          height="44"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3"></feOffset>
          <feGaussianBlur result="blur" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g data-name="Group 25762" transform="translate(9 6)">
        <g filter="url(#car_with_circle_id)" transform="translate(-9 -6)">
          <path
            fill="#f9ec16"
            d="M13 26a13.1 13.1 0 01-2.62-.264 12.929 12.929 0 01-4.648-1.956 13.038 13.038 0 01-4.71-5.72 12.934 12.934 0 01-.757-2.44 13.126 13.126 0 010-5.24A12.929 12.929 0 012.22 5.732a13.038 13.038 0 015.72-4.71 12.934 12.934 0 012.44-.758 13.126 13.126 0 015.24 0 12.929 12.929 0 014.648 1.956 13.038 13.038 0 014.71 5.72 12.935 12.935 0 01.757 2.44 13.127 13.127 0 010 5.24 12.929 12.929 0 01-1.956 4.648 13.038 13.038 0 01-5.72 4.71 12.934 12.934 0 01-2.44.757A13.1 13.1 0 0113 26zm0-24.752a11.839 11.839 0 00-2.368.239 11.688 11.688 0 00-4.2 1.768 11.786 11.786 0 00-4.261 5.171 11.693 11.693 0 00-.685 2.206 11.867 11.867 0 000 4.737 11.687 11.687 0 001.768 4.2 11.786 11.786 0 005.171 4.258 11.693 11.693 0 002.206.685 11.867 11.867 0 004.737 0 11.687 11.687 0 004.2-1.768 11.786 11.786 0 004.258-5.171 11.693 11.693 0 00.685-2.206 11.867 11.867 0 000-4.737 11.688 11.688 0 00-1.768-4.2 11.786 11.786 0 00-5.171-4.258 11.693 11.693 0 00-2.206-.685A11.839 11.839 0 0013 1.248z"
            data-name="Exclusion 1"
            transform="translate(9 6)"
          ></path>
        </g>
        <path
          fill="#f9ec16"
          d="M12.05 8.575l-.308-.775-.547-1.369A3.062 3.062 0 008.337 4.5H4.849a3.062 3.062 0 00-2.857 1.934L1.444 7.8l-.309.772A1.754 1.754 0 000 10.214v1.319a1.744 1.744 0 00.44 1.152v1.485a.879.879 0 00.879.879H2.2a.879.879 0 00.879-.879v-.879h7.033v.879a.879.879 0 00.879.879h.879a.879.879 0 00.879-.879v-1.486a1.743 1.743 0 00.44-1.152v-1.318a1.754 1.754 0 00-1.139-1.639zM3.624 7.087a1.319 1.319 0 011.224-.829h3.489a1.319 1.319 0 011.224.829l.547 1.369H3.077l.547-1.369zM2.2 11.527a.876.876 0 110-1.753 1.691 1.691 0 011.319 1.314c-.003.527-.794.439-1.319.439zm8.791 0c-.527 0-1.319.088-1.319-.438a1.691 1.691 0 011.319-1.314.876.876 0 110 1.753z"
          data-name="Icon awesome-car-alt"
          transform="translate(6.407 3.226)"
        ></path>
      </g>
    </svg>
  );
};

export const FaceScanningIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.761"
      height="21.408"
      data-name="Group 43915"
      viewBox="0 0 19.761 21.408"
      {...props}
    >
      <g
        fill="#8f949d"
        data-name="Icon ionic-ios-qr-scanner"
        transform="rotate(90 9.88 9.88)"
      >
        <path
          d="M6.484 0H3.458A3.459 3.459 0 000 3.458v3.026a.72.72 0 101.441 0V3.407a1.975 1.975 0 01.6-1.348 1.965 1.965 0 011.364-.618h3.079a.72.72 0 000-1.441z"
          data-name="Path 30332"
        ></path>
        <path
          d="M3.8 0H.72a.72.72 0 000 1.441H3.8a1.965 1.965 0 011.364.618 1.975 1.975 0 01.6 1.348v3.077a.72.72 0 001.441 0V3.407A3.411 3.411 0 003.8 0z"
          data-name="Path 30333"
          transform="translate(14.204)"
        ></path>
        <path
          d="M6.484 5.764H3.407a1.965 1.965 0 01-1.364-.618 1.975 1.975 0 01-.6-1.348V.72A.72.72 0 000 .72V3.8a3.411 3.411 0 003.407 3.4h3.077a.72.72 0 100-1.441z"
          data-name="Path 30334"
          transform="translate(0 12.557)"
        ></path>
        <path
          d="M6.484 0a.723.723 0 00-.72.72V3.8a1.975 1.975 0 01-.6 1.348 1.965 1.965 0 01-1.364.616H.72a.72.72 0 000 1.436h3.026A3.462 3.462 0 007.2 3.746V.72A.723.723 0 006.484 0z"
          data-name="Path 30335"
          transform="translate(14.204 12.557)"
        ></path>
      </g>
      <path
        fill="#8f949d"
        d="M6.84 7.73v-.705a3.819 3.819 0 001.71-3.178C8.55 1.723 8.55 0 5.985 0S3.42 1.723 3.42 3.847a3.819 3.819 0 001.71 3.178v.705C2.23 7.967 0 9.392 0 11.115h11.97c0-1.723-2.23-3.148-5.13-3.385z"
        data-name="Icon metro-user"
        transform="translate(3.895 4.774)"
      ></path>
    </svg>
  );
};

export const FilterIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="vuesax_bold_filter-search"
        data-name="vuesax/bold/filter-search"
        transform="translate(-492 -700)"
      >
        <g id="filter-search">
          <path
            id="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(516 724) rotate(180)"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M9.58,8.24,8.64,7.3A4.719,4.719,0,1,0,7.3,8.64l.94.94a.966.966,0,0,0,1.36,0A.967.967,0,0,0,9.58,8.24Z"
            transform="translate(503.14 710)"
            fill="#dbdbdb"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M17.16,2.02V4.24a3.815,3.815,0,0,1-1,2.33l-.18.16a.536.536,0,0,1-.53.1c-.2-.07-.4-.12-.6-.17a5.752,5.752,0,0,0-1.39-.16,6.252,6.252,0,0,0-6.25,6.25,6.158,6.158,0,0,0,.9,3.22,5.992,5.992,0,0,0,1.96,2.01.42.42,0,0,1,.12.65c-.07.06-.14.11-.21.16l-1.4.91a2.029,2.029,0,0,1-3.09-1.72V12.63a3.9,3.9,0,0,0-.8-2.12L.9,6.47A3.391,3.391,0,0,1,0,4.45V2.12A2.039,2.039,0,0,1,1.99,0H15.17A2.015,2.015,0,0,1,17.16,2.02Z"
            transform="translate(494.42 702)"
            fill="#dbdbdb"
          />
        </g>
      </g>
    </svg>
  );
};

export const VideoNoData = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="video-no-data-b">
          <circle
            data-name="Ellipse 17675"
            cx={85.594}
            cy={85.594}
            r={85.594}
            transform="rotate(-45 146.118 60.524)"
            fill="none"
          />
        </clipPath>
        <clipPath id="video-no-data-a">
          <path d="M0 0h200v200H0z" />
        </clipPath>
        <linearGradient
          id="video-no-data-c"
          y1={1}
          x2={0.002}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#e6e7e8" />
          <stop offset={1} stopColor="#dcddde" />
        </linearGradient>
      </defs>
      <g data-name="Group 33197" clipPath="url(#video-no-data-a)">
        <g data-name="Group 33191" opacity={0.169}>
          <g
            data-name="Group 33190"
            transform="translate(-21.049 -21.048)"
            clipPath="url(#video-no-data-b)"
          >
            <path
              data-name="Rectangle 25677"
              transform="translate(35.454 35.454)"
              fill="url(#video-no-data-c)"
              d="M0 0h171.188v171.188H0z"
            />
          </g>
        </g>
        <g data-name="Group 33193" opacity={0.357}>
          <g data-name="Group 33195">
            <path
              data-name="Path 36569"
              d="M117.012 90.201v29.433a2.19 2.19 0 0 1-2.2 2.174h-42.9a12.047 12.047 0 0 1-12.091-11.985V80.39a2.19 2.19 0 0 1 2.2-2.174h42.9a12.046 12.046 0 0 1 12.091 11.985m23.175-9.917v39.456c0 2.625-1.458 2.307-2.731 1.352l-17.713-12.913V91.872l17.713-12.94c1.538-1.273 2.731-.955 2.731 1.352"
              fill="#fc0002"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ParkerTime = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="clipTimer">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="Timer" clipPath="url(#clipTimer)">
        <g
          id="undraw_time_management_re_tk5w"
          transform="translate(5.928 -1.004)"
        >
          <path
            id="Path_42639"
            data-name="Path 42639"
            d="M447.316,659.837a.454.454,0,0,1-.1.285.3.3,0,0,1-.231.118h-184.5a.412.412,0,0,1,0-.805h184.5a.3.3,0,0,1,.231.118A.454.454,0,0,1,447.316,659.837Z"
            transform="translate(-260.669 -481.638)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_17996"
            data-name="Ellipse 17996"
            cx="72.754"
            cy="72.754"
            r="72.754"
            transform="translate(21.656 15.524)"
            fill="#e6e6e6"
          />
          <path
            id="Path_42640"
            data-name="Path 42640"
            d="M481.367,272.495A62.516,62.516,0,0,1,456.1,322.749c-.525.393-1.059.775-1.6,1.147a62.613,62.613,0,0,1-71.475,0c-.538-.372-1.073-.755-1.6-1.147a62.609,62.609,0,0,1,0-100.509c.524-.393,1.059-.775,1.6-1.147a62.613,62.613,0,0,1,71.475,0c.538.372,1.073.755,1.6,1.147A62.516,62.516,0,0,1,481.367,272.495Z"
            transform="translate(-324.355 -184.217)"
            fill="#fff"
          />
          <circle
            id="Ellipse_17997"
            data-name="Ellipse 17997"
            cx="57.188"
            cy="57.188"
            r="57.188"
            transform="translate(37.222 31.09)"
            fill="#2f9a5d"
          />
          <path
            id="Path_42641"
            data-name="Path 42641"
            d="M531.813,226.365c-.867,0-1.729.023-2.586.061A57.186,57.186,0,0,1,526.8,340.52c1.651.143,3.32.221,5.008.221a57.188,57.188,0,0,0,0-114.376Z"
            transform="translate(-437.253 -195.115)"
            opacity="0.2"
          />
          <path
            id="Path_42642"
            data-name="Path 42642"
            d="M538.024,213.892a1.864,1.864,0,0,0-1.861,1.861v8.121a1.861,1.861,0,1,0,3.722,0v-8.121a1.864,1.864,0,0,0-1.861-1.861Z"
            transform="translate(-443.444 -186.864)"
            fill="#fff"
          />
          <path
            id="Path_42643"
            data-name="Path 42643"
            d="M635.558,244.132c-.524-.393-1.059-.775-1.6-1.147a1.86,1.86,0,0,0-1.658.809l-4.572,6.71a1.861,1.861,0,1,0,3.076,2.095l4.572-6.71A1.849,1.849,0,0,0,635.558,244.132Z"
            transform="translate(-503.813 -206.109)"
            fill="#fff"
          />
          <path
            id="Path_42644"
            data-name="Path 42644"
            d="M691.151,334.535a1.859,1.859,0,0,0-2.423-1.022l-7.526,3.056a1.861,1.861,0,0,0,1.4,3.448l7.526-3.056A1.865,1.865,0,0,0,691.151,334.535Z"
            transform="translate(-538.636 -265.915)"
            fill="#fff"
          />
          <path
            id="Path_42645"
            data-name="Path 42645"
            d="M438.38,250.5l-4.572-6.71a1.86,1.86,0,0,0-1.658-.809c-.538.372-1.073.755-1.6,1.147a1.849,1.849,0,0,0,.179,1.756l4.572,6.71a1.861,1.861,0,0,0,3.076-2.095Z"
            transform="translate(-373.477 -206.109)"
            fill="#fff"
          />
          <path
            id="Path_42646"
            data-name="Path 42646"
            d="M379.13,336.57l-7.526-3.056a1.861,1.861,0,0,0-1.4,3.448l7.526,3.056a1.861,1.861,0,1,0,1.4-3.448Z"
            transform="translate(-332.876 -265.915)"
            fill="#fff"
          />
          <path
            id="Path_42647"
            data-name="Path 42647"
            d="M538.024,540.892a1.864,1.864,0,0,0-1.861,1.861v8.121a1.861,1.861,0,1,0,3.722,0v-8.121A1.864,1.864,0,0,0,538.024,540.892Z"
            transform="translate(-443.444 -403.21)"
            fill="#fff"
          />
          <path
            id="Path_42648"
            data-name="Path 42648"
            d="M635.379,523.5l-4.572-6.71a1.861,1.861,0,0,0-3.076,2.095l4.572,6.71a1.86,1.86,0,0,0,1.658.809c.538-.372,1.073-.755,1.6-1.147A1.849,1.849,0,0,0,635.379,523.5Z"
            transform="translate(-503.813 -386.726)"
            fill="#fff"
          />
          <path
            id="Path_42649"
            data-name="Path 42649"
            d="M690.13,439.57l-7.526-3.056a1.861,1.861,0,1,0-1.4,3.448l7.526,3.056a1.861,1.861,0,0,0,1.4-3.448Z"
            transform="translate(-538.636 -334.061)"
            fill="#fff"
          />
          <path
            id="Path_42650"
            data-name="Path 42650"
            d="M437.889,516.3a1.861,1.861,0,0,0-2.585.491l-4.572,6.71a1.849,1.849,0,0,0-.179,1.756c.524.393,1.059.775,1.6,1.147a1.86,1.86,0,0,0,1.658-.809l4.572-6.71a1.86,1.86,0,0,0-.491-2.585Z"
            transform="translate(-373.477 -386.727)"
            fill="#fff"
          />
          <path
            id="Path_42651"
            data-name="Path 42651"
            d="M380.152,437.535a1.859,1.859,0,0,0-2.423-1.022l-7.526,3.056a1.861,1.861,0,0,0,1.4,3.448l7.526-3.056A1.865,1.865,0,0,0,380.152,437.535Z"
            transform="translate(-332.876 -334.06)"
            fill="#fff"
          />
          <path
            id="Path_42652"
            data-name="Path 42652"
            d="M469.117,385.831a3.9,3.9,0,0,1-3.184,4.489l-33.026,5.618a3.892,3.892,0,1,1-1.305-7.673l33.026-5.618a3.9,3.9,0,0,1,4.489,3.184Z"
            transform="translate(-372.122 -298.477)"
            fill="#3f3d56"
          />
          <path
            id="Path_42653"
            data-name="Path 42653"
            d="M573.523,422.872a2.541,2.541,0,0,1-3.589.014l-35.552-35.273a2.538,2.538,0,0,1,3.575-3.6l35.552,35.273A2.541,2.541,0,0,1,573.523,422.872Z"
            transform="translate(-441.77 -298.927)"
            fill="#3f3d56"
          />
          <circle
            id="Ellipse_17998"
            data-name="Ellipse 17998"
            cx="4.399"
            cy="4.399"
            r="4.399"
            transform="translate(90.011 83.879)"
            fill="#ccc"
          />
          <path
            id="Path_42654"
            data-name="Path 42654"
            d="M378.512,146.024c-6.753,10.616-17.055,17.662-29.761,22.29a2.389,2.389,0,0,1-3.344-.48h0A20.981,20.981,0,1,1,378.8,142.425l.191.255a2.389,2.389,0,0,1-.48,3.344Z"
            transform="translate(-314.402 -134.027)"
            fill="#e6e6e6"
          />
          <path
            id="Path_42655"
            data-name="Path 42655"
            d="M628.547,142.425a20.981,20.981,0,1,1,33.394,25.409h0a2.389,2.389,0,0,1-3.344.48c-11.8-4.516-21.468-12.331-29.761-22.29a2.389,2.389,0,0,1-.48-3.344Z"
            transform="translate(-504.125 -134.027)"
            fill="#e6e6e6"
          />
          <path
            id="Path_42656"
            data-name="Path 42656"
            d="M619.246,580.7a1.864,1.864,0,0,0-.715,2.533l10.741,19.194a1.861,1.861,0,1,0,3.248-1.818l-10.741-19.194A1.864,1.864,0,0,0,619.246,580.7Z"
            transform="translate(-497.783 -429.39)"
            fill="#e6e6e6"
          />
          <path
            id="Path_42657"
            data-name="Path 42657"
            d="M432.269,581.412l-10.741,19.194a1.861,1.861,0,0,0,3.248,1.818l10.741-19.194a1.861,1.861,0,1,0-3.248-1.818Z"
            transform="translate(-367.444 -429.388)"
            fill="#e6e6e6"
          />
          <circle
            id="Ellipse_17999"
            data-name="Ellipse 17999"
            cx="5.753"
            cy="5.753"
            r="5.753"
            transform="translate(127.911 166.446)"
            fill="#e6e6e6"
          />
          <circle
            id="Ellipse_18000"
            data-name="Ellipse 18000"
            cx="5.753"
            cy="5.753"
            r="5.753"
            transform="translate(131.972 19.246)"
            fill="#e6e6e6"
          />
          <circle
            id="Ellipse_18001"
            data-name="Ellipse 18001"
            cx="5.753"
            cy="5.753"
            r="5.753"
            transform="translate(88.319 5.71)"
            fill="#e6e6e6"
          />
          <circle
            id="Ellipse_18002"
            data-name="Ellipse 18002"
            cx="5.753"
            cy="5.753"
            r="5.753"
            transform="translate(46.697 18.569)"
            fill="#e6e6e6"
          />
          <circle
            id="Ellipse_18003"
            data-name="Ellipse 18003"
            cx="5.753"
            cy="5.753"
            r="5.753"
            transform="translate(50.419 166.446)"
            fill="#e6e6e6"
          />
          <path
            id="Path_42665"
            data-name="Path 42665"
            d="M458.7,593.34l.247.626H458.7Z"
            transform="translate(-303.482 -392.556)"
            fill="#2f2e41"
          />
        </g>
      </g>
    </svg>
  );
};

export const BrokenCarIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/truck-remove" transform="translate(-236 -252)">
        <g>
          <path
            d="M19.5 10.5a.5.5 0 01.5.5v1a3 3 0 01-3 3 3 3 0 00-6 0H9a3 3 0 00-6 0 3 3 0 01-3-3v-2a1 1 0 011-1h9.5A2.5 2.5 0 0013 6.5V1a1 1 0 011-1h.84a2.016 2.016 0 011.74 1.01l.64 1.12a.252.252 0 01-.22.37A2.5 2.5 0 0014.5 5v3a2.5 2.5 0 002.5 2.5z"
            data-name="Vector"
            transform="translate(238 257)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(242 270)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(250 270)"
          ></path>
          <path
            d="M4 3.53V5H1a1 1 0 01-1-1V1a1 1 0 011-1h1.29l1.45 2.54a2 2 0 01.26.99z"
            data-name="Vector"
            transform="translate(254 261)"
          ></path>
          <path
            d="M11.08 0H3.69A3.687 3.687 0 000 3.69v6.39a1 1 0 001 1h9.15A1.852 1.852 0 0012 9.23V.92a.918.918 0 00-.92-.92zM7.6 7.12a.774.774 0 01-1.06 0l-.53-.53-.55.55a.748.748 0 01-1.06 0 .754.754 0 010-1.06l.55-.55L4.42 5a.754.754 0 010-1.06.767.767 0 011.07 0l.52.53.5-.51a.757.757 0 011.07 1.07l-.51.5.53.52a.767.767 0 010 1.07z"
            data-name="Vector"
            transform="translate(238 253.98)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const AddMapIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="76"
      height="57"
      viewBox="0 0 76 57"
      {...props}
    >
      <image
        width="76"
        height="57"
        data-name="Image 28"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAAA5CAYAAACceM1ZAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAIRSURBVHhe7ZrrioJQFIW30/1edP0REfREPWkvVkT96j4zS46DMxi5TKOa9YF40GgfvvZRk+V9fmMiNh9uL2IiYSQSRiJhJBJGImEkEkYiYSQSRiJhJA/7a3Q+n+1wONhut7PtdmudTseq1arl83n3iXQJ10OttMhU2OVy8Se93+/teDy6o2ar1cqNzOr1urXbbSuXy5bL5dzRZFyr1+v13Oh+MhGGCWPD5KMICwvTarWs2Wz6ncdwq95TCsMvivbHpG995TVhAei0QF6pVHJHf8PUexphp9PpZ9JYDnG5JSxMsVj05TUaDfM8L1G9pxG2XC7diIMRFqbb7boRR5rC9FhBImEkEkYiYSQSRiJhJBJGImEkEkYiYSQSRiJhJBJGImEkEkYiYSQSRiJhJG/3ijrN19FRqMNI3rbDFouFvw+Yz+dudB/qMBIJI5EwkpcVhmtV1JY16jCSl71LBt30927Iwt491WEkEkYiYSQPvYYhHIdoJrJdm83G1uu1OxOPqGvYNV72SR8hOEQwEcxFKK5QKPjiBoOBzWYzG41GmQWDsyAzYYhdIvCLroCwqMAvRCFdCHHj8dgqlYo7cxt0d3hDtyKdmDWpCwsilugoLL+41Go1m0wmNp1O/VR1XJLWS0oqwrDsMFlMGkFeLLukYLkOh0O/6/r9fuRyTbMey10X/f+IHitIJIxEwkgkjMLsCyHs7tuVuGZEAAAAAElFTkSuQmCC"
      ></image>
    </svg>
  );
};

export const MenuSchedule = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="vuesax_bold_calendar"
        data-name="vuesax/bold/calendar"
        transform="translate(-492 -188)"
      >
        <g id="calendar">
          <path
            id="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(492 188)"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M13.711,2.31V.75a.75.75,0,0,0-1.5,0v1.5h-6.5V.75a.75.75,0,0,0-1.5,0V2.31A4.248,4.248,0,0,0,0,6.56a.5.5,0,0,0,.5.53h16.92a.5.5,0,0,0,.5-.53A4.248,4.248,0,0,0,13.711,2.31Z"
            transform="translate(495.039 189.25)"
            fill="#8F949D"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M17,0H1A1,1,0,0,0,0,1V7.16c0,3,1.5,5,5,5h8c3.5,0,5-2,5-5V1A1,1,0,0,0,17,0ZM6.21,8.37a1.576,1.576,0,0,1-.15.12.757.757,0,0,1-.18.09.636.636,0,0,1-.18.06,1.355,1.355,0,0,1-.2.02,1,1,0,0,1-.38-.08,1.032,1.032,0,0,1-.33-.21,1.014,1.014,0,0,1,0-1.42,1.032,1.032,0,0,1,.33-.21.956.956,0,0,1,.58-.06.636.636,0,0,1,.18.06.757.757,0,0,1,.18.09l.15.12a1.014,1.014,0,0,1,0,1.42Zm0-3.5a1.014,1.014,0,0,1-1.42,0,1.014,1.014,0,0,1,0-1.42,1,1,0,0,1,1.09-.21.933.933,0,0,1,.33.21,1.014,1.014,0,0,1,0,1.42Zm3.5,3.5A1,1,0,0,1,8.29,6.95,1,1,0,0,1,9.71,8.37Zm0-3.5-.15.12a.757.757,0,0,1-.18.09.636.636,0,0,1-.18.06,1.355,1.355,0,0,1-.2.02.99.99,0,0,1-1-1,1.052,1.052,0,0,1,.29-.71.933.933,0,0,1,.33-.21,1,1,0,0,1,1.09.21,1.014,1.014,0,0,1,0,1.42Zm3.5,3.5a1,1,0,0,1-1.42-1.42,1,1,0,0,1,1.42,1.42Zm0-3.5-.15.12a.757.757,0,0,1-.18.09.636.636,0,0,1-.18.06,1.5,1.5,0,0,1-.2.02.99.99,0,0,1-1-1,1.052,1.052,0,0,1,.29-.71,1.032,1.032,0,0,1,.33-.21.956.956,0,0,1,.58-.06.636.636,0,0,1,.18.06.757.757,0,0,1,.18.09l.15.12a1.014,1.014,0,0,1,0,1.42Z"
            transform="translate(495 197.84)"
            fill="#8F949D"
          />
        </g>
      </g>
    </svg>
  );
};

export const IConCard = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
      <defs>
        <clipPath id="card1-clip-path">
          <path d="M0 0h200v200H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#card1-clip-path)">
        <path
          d="M160.808 43H38.255A12.258 12.258 0 0 0 26 55.255v89.872a12.258 12.258 0 0 0 12.255 12.255h122.553a12.258 12.258 0 0 0 12.255-12.255V55.255A12.258 12.258 0 0 0 160.808 43ZM70.936 67.51A16.34 16.34 0 1 1 54.6 83.85a16.355 16.355 0 0 1 16.336-16.34Zm28.6 60.459c0 2.706-2.553 4.9-5.719 4.9H48.059c-3.166 0-5.719-2.2-5.719-4.9v-4.9c0-8.119 7.685-14.706 17.157-14.706h1.277a26.3 26.3 0 0 0 20.323 0h1.277c9.472 0 17.157 6.587 17.157 14.706Zm57.191-13.481a2.049 2.049 0 0 1-2.043 2.043h-36.77a2.049 2.049 0 0 1-2.043-2.043v-4.084a2.049 2.049 0 0 1 2.043-2.043h36.766a2.049 2.049 0 0 1 2.043 2.043Zm0-16.34a2.049 2.049 0 0 1-2.043 2.043h-36.77a2.049 2.049 0 0 1-2.042-2.041v-4.086a2.049 2.049 0 0 1 2.043-2.043h36.765a2.049 2.049 0 0 1 2.043 2.043Zm0-16.34a2.049 2.049 0 0 1-2.047 2.042h-36.766a2.049 2.049 0 0 1-2.043-2.043v-4.084a2.049 2.049 0 0 1 2.043-2.043h36.766a2.049 2.049 0 0 1 2.043 2.043Z"
          fill="#2e384d"
          opacity={0.504}
        />
      </g>
    </svg>
  );
};

export const IConFaceAccess = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
      <defs>
        <clipPath id="face-access-clip-path">
          <path d="M0 0h200v200H0z" />
        </clipPath>
      </defs>
      <g fill="#969ba5" clipPath="url(#face-access-clip-path)">
        <path d="M152.18 78.512V62.56a18.236 18.236 0 0 0-18.231-18.232h-15.953a3.8 3.8 0 0 0 0 7.6h16.224a10.411 10.411 0 0 1 7.108 3.174 10.36 10.36 0 0 1 3.252 7.186v16.224a3.8 3.8 0 1 0 7.6 0ZM152.18 139.23v-16.222a3.8 3.8 0 1 0-7.6 0v16.222a10.36 10.36 0 0 1-3.256 7.19 10.41 10.41 0 0 1-7.108 3.174h-16.22a3.8 3.8 0 0 0 0 7.6h16.224a17.982 17.982 0 0 0 17.96-17.964ZM55.596 78.512V62.288a10.36 10.36 0 0 1 3.256-7.19 10.411 10.411 0 0 1 7.108-3.17h16.222a3.8 3.8 0 0 0 0-7.6H65.96A17.982 17.982 0 0 0 48 62.288v16.224a3.8 3.8 0 1 0 7.6 0ZM85.982 153.392a3.809 3.809 0 0 0-3.8-3.8H65.96a10.41 10.41 0 0 1-7.108-3.174 10.36 10.36 0 0 1-3.256-7.19v-16.22a3.8 3.8 0 1 0-7.6 0v15.951a18.254 18.254 0 0 0 18.235 18.232h15.951a3.809 3.809 0 0 0 3.8-3.799ZM104.595 110.246v-3.717c4.965-2.8 9.015-9.773 9.015-16.75 0-11.2 0-20.283-13.522-20.283s-13.523 9.081-13.523 20.283c0 6.977 4.05 13.952 9.015 16.75v3.717c-15.29 1.25-27.044 8.763-27.044 17.85h63.1c0-9.083-11.754-16.6-27.044-17.846Z" />
      </g>
    </svg>
  );
};

export const IconCard = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="card-clip-path">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="a" clip-path="url(#card-clip-path)">
        <path
          d="M134.808,2.25H12.255A12.258,12.258,0,0,0,0,14.505v89.872a12.258,12.258,0,0,0,12.255,12.255H134.808a12.258,12.258,0,0,0,12.255-12.255V14.505A12.258,12.258,0,0,0,134.808,2.25ZM44.936,26.76A16.34,16.34,0,1,1,28.6,43.1,16.355,16.355,0,0,1,44.936,26.76Zm28.6,60.459c0,2.706-2.553,4.9-5.719,4.9H22.059c-3.166,0-5.719-2.2-5.719-4.9v-4.9c0-8.119,7.685-14.706,17.157-14.706h1.277a26.3,26.3,0,0,0,20.323,0h1.277c9.472,0,17.157,6.587,17.157,14.706Zm57.191-13.481a2.049,2.049,0,0,1-2.043,2.043H91.914a2.049,2.049,0,0,1-2.043-2.043V69.654a2.049,2.049,0,0,1,2.043-2.043H128.68a2.049,2.049,0,0,1,2.043,2.043Zm0-16.34a2.049,2.049,0,0,1-2.043,2.043H91.914A2.049,2.049,0,0,1,89.872,57.4V53.314a2.049,2.049,0,0,1,2.043-2.043H128.68a2.049,2.049,0,0,1,2.043,2.043Zm0-16.34A2.049,2.049,0,0,1,128.68,43.1H91.914a2.049,2.049,0,0,1-2.043-2.043V36.973a2.049,2.049,0,0,1,2.043-2.043H128.68a2.049,2.049,0,0,1,2.043,2.043Z"
          transform="translate(26 40.75)"
          fill="#2e3c52"
        />
      </g>
    </svg>
  );
};

export const IconFaceID = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="face-id-clip-path">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="a" clip-path="url(#face-id-clip-path)">
        <g transform="translate(48 44.328)">
          <g transform="translate(104.18 0) rotate(90)">
            <path
              d="M34.184,0H18.232A18.236,18.236,0,0,0,0,18.231V34.184a3.8,3.8,0,0,0,7.6,0V17.96a10.411,10.411,0,0,1,3.174-7.108A10.36,10.36,0,0,1,17.96,7.6H34.184a3.8,3.8,0,1,0,0-7.6Z"
              transform="translate(0 0)"
              fill="#2e3c52"
            />
            <path
              d="M20.022,0H3.8a3.8,3.8,0,1,0,0,7.6H20.022a10.36,10.36,0,0,1,7.19,3.256,10.41,10.41,0,0,1,3.174,7.108V34.184a3.8,3.8,0,0,0,7.6,0V17.96A17.982,17.982,0,0,0,20.022,0Z"
              transform="translate(74.88 0)"
              fill="#2e3c52"
            />
            <path
              d="M34.184,30.386H17.96a10.36,10.36,0,0,1-7.19-3.256A10.411,10.411,0,0,1,7.6,20.022V3.8A3.8,3.8,0,0,0,0,3.8V20.022a17.982,17.982,0,0,0,17.96,17.96H34.184a3.8,3.8,0,1,0,0-7.6Z"
              transform="translate(0 66.198)"
              fill="#2e3c52"
            />
            <path
              d="M34.184,0a3.809,3.809,0,0,0-3.8,3.8V20.022a10.41,10.41,0,0,1-3.174,7.108,10.36,10.36,0,0,1-7.19,3.256H3.8a3.8,3.8,0,1,0,0,7.6H19.751A18.254,18.254,0,0,0,37.983,19.751V3.8A3.809,3.809,0,0,0,34.184,0Z"
              transform="translate(74.88 66.198)"
              fill="#2e3c52"
            />
          </g>
          <path
            d="M36.059,40.75V37.033c4.965-2.8,9.015-9.773,9.015-16.75,0-11.2,0-20.283-13.522-20.283S18.029,9.081,18.029,20.283c0,6.977,4.05,13.952,9.015,16.75V40.75C11.754,42,0,49.513,0,58.6H63.1c0-9.083-11.754-16.6-27.044-17.846Z"
            transform="translate(20.536 25.168)"
            fill="#2e3c52"
          />
        </g>
      </g>
    </svg>
  );
};

export const IconFingerScan = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="finger-scan-clip-path">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="a" clip-path="url(#finger-scan-clip-path)">
        <path
          d="M89.212,17.126a2.657,2.657,0,0,1-1.315-.343c-10.98-5.662-20.474-8.064-31.855-8.064a65.428,65.428,0,0,0-31.855,8.064,2.866,2.866,0,0,1-2.745-5.033A71.81,71.81,0,0,1,56.042,3C68.223,3,78.86,5.688,90.527,11.693a2.895,2.895,0,0,1-1.315,5.433ZM7.373,47.151a2.98,2.98,0,0,1-1.659-.515,2.852,2.852,0,0,1-.686-4,59.129,59.129,0,0,1,21.446-18.7,66.016,66.016,0,0,1,58.963-.057,59.354,59.354,0,0,1,21.446,18.587,2.872,2.872,0,1,1-4.69,3.317A53.689,53.689,0,0,0,82.806,28.965a60.262,60.262,0,0,0-53.759.057A54.254,54.254,0,0,0,9.6,45.95,2.493,2.493,0,0,1,7.373,47.151ZM43.117,116.18a2.683,2.683,0,0,1-2-.858,57.815,57.815,0,0,1-11.5-15.1,50.768,50.768,0,0,1-6-24.821c0-16.986,14.526-30.826,32.37-30.826s32.37,13.84,32.37,30.826a2.86,2.86,0,1,1-5.719,0c0-13.84-11.953-25.107-26.651-25.107S29.334,61.563,29.334,75.4a44.8,44.8,0,0,0,5.319,22.018,54.133,54.133,0,0,0,10.58,13.84,2.936,2.936,0,0,1,0,4.061A3.137,3.137,0,0,1,43.117,116.18ZM84.122,105.6a30.8,30.8,0,0,1-17.729-5.09A30.45,30.45,0,0,1,52.782,75.4a2.859,2.859,0,1,1,5.719,0A24.6,24.6,0,0,0,69.6,95.763a25.076,25.076,0,0,0,14.526,4.061,37.8,37.8,0,0,0,5.948-.572,2.872,2.872,0,0,1,.972,5.662,39.051,39.051,0,0,1-6.92.686Zm-11.5,11.781a3.347,3.347,0,0,1-.743-.114c-9.093-2.516-15.041-5.891-21.275-12.01A41.73,41.73,0,0,1,38.2,75.4c0-9.265,7.892-16.814,17.615-16.814S73.427,66.138,73.427,75.4c0,6.119,5.319,11.095,11.9,11.095s11.9-4.976,11.9-11.095c0-21.561-18.587-39.061-41.463-39.061-16.242,0-31.111,9.036-37.8,23.048A36.791,36.791,0,0,0,14.579,75.4,56.834,56.834,0,0,0,18.41,96.049a2.791,2.791,0,0,1-1.659,3.66,2.837,2.837,0,0,1-3.66-1.659A63.714,63.714,0,0,1,8.917,75.4a42.966,42.966,0,0,1,3.889-18.53c7.606-15.956,24.477-26.308,42.95-26.308,26.021,0,47.182,20.074,47.182,44.78,0,9.265-7.892,16.814-17.615,16.814S67.708,84.611,67.708,75.346c0-6.119-5.319-11.095-11.9-11.095s-11.9,4.976-11.9,11.095a36.016,36.016,0,0,0,10.695,25.793c5.433,5.376,10.637,8.35,18.7,10.58a2.839,2.839,0,0,1,2,3.489,2.79,2.79,0,0,1-2.688,2.173Z"
          transform="translate(44.505 39.809)"
          fill="#2e3c52"
        />
      </g>
    </svg>
  );
};

export const IconQrCode = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="qr-code-clip-path">
          <rect width="113.465" height="112.862" fill="#2e3c52" />
        </clipPath>
        <clipPath id="qr-code-clip-path">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="b" clip-path="url(#qr-code1-clip-path)">
        <g transform="translate(43 43.999)">
          <g transform="translate(0 0)" clip-path="url(#qr-code1-clip-path)">
            <path
              d="M0,7.694c1.008-2.866,1.916-3.5,4.969-3.5q19.509,0,39.017,0a7.746,7.746,0,0,1,2.088.23,3.793,3.793,0,0,1,2.607,3.946q.022,20.114,0,40.242c0,2.693-1.5,4.191-4.206,4.191q-20.07.022-40.126,0C1.93,52.8.951,52,0,49.376Zm41,4.22H7.763V45.142H41Z"
              transform="translate(0 56.132)"
              fill="#2e3c52"
            />
            <path
              d="M0,3.053C.792.763,2.463,0,4.811,0c13.006.058,26.011.029,39,.029.374,0,.749,0,1.109.029A3.718,3.718,0,0,1,48.566,4q.022,20.33,0,40.674a3.726,3.726,0,0,1-3.644,3.946,9.424,9.424,0,0,1-1.109.029c-12.991,0-26-.014-39,.029C2.463,48.7.792,47.919,0,45.629ZM7.662,40.948H40.918V7.72H7.662Z"
              transform="translate(0 0.006)"
              fill="#2e3c52"
            />
            <path
              d="M4.191,24.314V4.7C4.191,1.442,5.617,0,8.843,0H48.192c3.183,0,4.623,1.426,4.638,4.58V44.046c-.014,3.154-1.455,4.58-4.638,4.58q-19.681.022-39.348,0c-3.226,0-4.652-1.44-4.652-4.681V24.314M45.124,7.722H11.9V40.935H45.124Z"
              transform="translate(56.171 0.033)"
              fill="#2e3c52"
            />
            <path
              d="M6.094,17.429V4.193H19.186V17.242c-.389.014-.807.043-1.2.043-3.529.014-7.086,0-10.629.014-.432,0-.864.072-1.311.1l.043.029"
              transform="translate(81.1 56.205)"
              fill="#2e3c52"
            />
            <rect
              width="13.035"
              height="13.049"
              transform="translate(87.252 99.812)"
              fill="#2e3c52"
            />
            <path
              d="M17.227,17.484a8.031,8.031,0,0,0-1.181-.158c-3.5-.014-7.014,0-10.514,0h-1.3V4.19H17.17a3.893,3.893,0,0,1,.115.591V16.85a4.9,4.9,0,0,1-.086.663l.029-.029"
              transform="translate(56.774 56.165)"
              fill="#2e3c52"
            />
            <path
              d="M5.294,18.324c-.029-3.615-.043-7.23-.072-10.845,0-.749-.043-1.484-.058-2.218l-.029.029a3.32,3.32,0,0,1,.72-.187H17.825a1.836,1.836,0,0,1,.533.144l-.029-.029V18.3H5.251Z"
              transform="translate(68.837 68.388)"
              fill="#2e3c52"
            />
            <rect
              width="13.006"
              height="13.02"
              transform="translate(100.46 73.585)"
              fill="#2e3c52"
            />
            <path
              d="M17.327,6.018v13.02H4.235V6.047H17.37l-.043-.029"
              transform="translate(56.761 80.665)"
              fill="#2e3c52"
            />
            <path
              d="M14.361,5.424V17.667c0,.475,0,.879-.677.879C9.622,18.516,5.561,18.516,1.5,18.5c-.058,0-.13-.058-.259-.13V5.424Z"
              transform="translate(16.619 72.704)"
              fill="#2e3c52"
            />
            <rect
              width="13.006"
              height="13.049"
              transform="translate(17.787 17.802)"
              fill="#2e3c52"
            />
            <rect
              width="13.006"
              height="13.078"
              transform="translate(78.193 17.802)"
              fill="#2e3c52"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconVehicleManagementHome = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/truck-time" transform="translate(-172 -252)">
        <g fill="#fff">
          <path
            d="M19.5 10.5a.5.5 0 01.5.5v1a3 3 0 01-3 3 3 3 0 00-6 0H9a3 3 0 00-6 0 3 3 0 01-3-3v-2a1 1 0 011-1h9.5A2.5 2.5 0 0013 6.5V1a1 1 0 011-1h.84a2.016 2.016 0 011.74 1.01l.64 1.12a.252.252 0 01-.22.37A2.5 2.5 0 0014.5 5v3a2.5 2.5 0 002.5 2.5z"
            transform="translate(174 257)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(178 270)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(186 270)"
          ></path>
          <path
            d="M4 3.53V5H1a1 1 0 01-1-1V1a1 1 0 011-1h1.29l1.45 2.54a2 2 0 01.26.99z"
            data-name="Vector"
            transform="translate(190 261)"
          ></path>
          <path
            d="M11.08 0H3.69A3.687 3.687 0 000 3.69v6.39a1 1 0 001 1h9.15A1.852 1.852 0 0012 9.23V.92a.918.918 0 00-.92-.92zm-3.7 5.91a.751.751 0 01-.36.64l-1.25.75a.724.724 0 01-.39.11.754.754 0 01-.64-.36.746.746 0 01.25-1.03l.89-.53V4.41a.75.75 0 111.5 0z"
            data-name="Vector"
            transform="translate(174 254)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const IconVehicleInspectionHome = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/broom" transform="translate(-236 -700)">
        <g>
          <g fill="#fff">
            <path
              d="M5.082 3.155L3.8 1.035a2.09 2.09 0 00-2.32-.96 2.048 2.048 0 00-1.19 3.02l1.32 2.17a.516.516 0 00.69.17l2.61-1.59a.5.5 0 00.172-.69z"
              data-name="Vector"
              transform="translate(239.738 701.745)"
            ></path>
            <path
              d="M14.108 8.538l-2.81-3.8a4.046 4.046 0 00-4.28-1.51c0-.01-.01-.01-.01-.02L5.348.478a1.02 1.02 0 00-1.39-.33l-3.47 2.11a1.007 1.007 0 00-.34 1.39L1.8 6.378a.01.01 0 00.01.01A4.046 4.046 0 001.2 10.9l2.08 4.24a2.5 2.5 0 003.43 1.15.186.186 0 00.07-.27l-1.56-2.58a.765.765 0 01.25-1.05.783.783 0 011.05.25l1.57 2.58a.208.208 0 00.27.07l.96-.58a.208.208 0 00.07-.27l-1.57-2.58a.763.763 0 011.3-.8l1.57 2.58a.208.208 0 00.27.07l.96-.58a.208.208 0 00.07-.27l-1.57-2.58a.763.763 0 011.3-.8l1.58 2.57a.2.2 0 00.27.06 2.488 2.488 0 00.538-3.572z"
              data-name="Vector"
              transform="translate(241.632 705.672)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconUseManagementHome = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="vuesax_bold_frame"
        data-name="vuesax/bold/frame"
        transform="translate(-108 -252)"
      >
        <g id="frame">
          <path
            id="Vector"
            d="M4.75,0a4.746,4.746,0,0,0-.12,9.49.807.807,0,0,1,.22,0h.07A4.746,4.746,0,0,0,4.75,0Z"
            transform="translate(115.25 254)"
            fill="#fff"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M12.12,1.395a9.929,9.929,0,0,0-10.15,0A3.947,3.947,0,0,0,0,4.625a3.914,3.914,0,0,0,1.96,3.21,9.239,9.239,0,0,0,5.08,1.41,9.239,9.239,0,0,0,5.08-1.41,3.945,3.945,0,0,0,1.96-3.23A3.937,3.937,0,0,0,12.12,1.395Z"
            transform="translate(112.96 264.755)"
            fill="#fff"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(132 276) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export const DownloadICon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="download-icon">
          <path d="M0 0h100v100H0z" />
        </clipPath>
      </defs>
      <g data-name="vuesax/bold/receive-square" clipPath="url(#download-icon)">
        <path
          data-name="Vector"
          d="M69.091 4.153H30.742c-16.658 0-26.589 9.931-26.589 26.589v38.3c0 16.711 9.931 26.638 26.589 26.638h38.3c16.658 0 26.589-9.931 26.589-26.589V30.742c.049-16.658-9.878-26.589-26.54-26.589Zm-35.329 41.1a3.453 3.453 0 0 1 4.851 0l7.871 7.871V24.792a3.432 3.432 0 1 1 6.865 0V53.12l7.871-7.867a3.43 3.43 0 0 1 4.851 4.851L52.342 63.828a3.551 3.551 0 0 1-1.1.732 3.283 3.283 0 0 1-1.327.275 3.448 3.448 0 0 1-1.327-.275 3.551 3.551 0 0 1-1.1-.732L33.762 50.099a3.453 3.453 0 0 1 0-4.846Zm44.711 28.552a90.356 90.356 0 0 1-28.556 4.622 90.356 90.356 0 0 1-28.556-4.622 3.446 3.446 0 0 1-2.152-4.352 3.392 3.392 0 0 1 4.344-2.147 83.9 83.9 0 0 0 52.765 0 3.446 3.446 0 0 1 4.351 2.147 3.49 3.49 0 0 1-2.196 4.352Z"
          fill="red"
        />
      </g>
    </svg>
  );
};
