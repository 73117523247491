import { menuDataNoti } from "provider/notification-header";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectNotificationType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("setting.notification", { ns: "menu" })}
      {...props}
      valueOptions={{
        valueKey: "keyNoti",
        titleKey: "lable",
        values: menuDataNoti,
        translationI18n: true,
      }}
      multipleMode
    />
  );
};

export default CSelectNotificationType;
