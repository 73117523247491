import React, { useState } from "react";
import { IVehicleReportParams } from "services/vehicle-report/interface";
import { IVehicleReportContext, vehicleReportChoice } from "./interface";

const init = Object.keys(vehicleReportChoice).map((itemKey) => {
  const { key } = vehicleReportChoice[itemKey];
  return key;
});

const Context = React.createContext<IVehicleReportContext>(
  {} as IVehicleReportContext
);

const withVehicleReport = (Component: React.ComponentType) => {
  return () => {
    const [params, setParams] = useState<IVehicleReportParams>({
      limit: 10,
      page: 1,
    });
    const [selectKeys, setSelectKeys] = useState<string[]>(init);

    return (
      <Context.Provider
        value={{ selectKeys, setSelectKeys, params, setParams }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useVehicleReport = () => React.useContext(Context);
export default withVehicleReport;
