import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectRegisterType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("formation")}
      valueOptions={{
        values: [
          {
            label: t("regis-onsite"),
            value: "ONSITE",
          },
          {
            label: t("regis-online"),
            value: "ONLINE",
          },
          // {
          //   label: t("all"),
          //   value: undefined,
          // },
        ],
        valueKey: "value",
        titleKey: "label",
      }}
      allowClear
      {...props}
    />
  );
};

export default CSelectRegisterType;
