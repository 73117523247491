import { RightOutlined, UpOutlined } from "@ant-design/icons";
import { Col, Form, Row, Typography } from "antd";
import CButton from "components/button/c-button";
import Container from "components/display/container";
import CRadio from "components/input/c-radio";
import dayjs from "dayjs";
import { useAppLanguage } from "provider/app-language";
import QRCode from "qrcode.react";
import { FormInstance } from "rc-field-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetPrivacyPolicy } from "services/privacy-policy";
import { useGetPDPA } from "services/setting-pdpa";
import { useGetTermAndCondition } from "services/terms-and-condition";
import styled from "styled-components";
import { TMenu } from "../create-user-pdpa";
import TemperatureInformation from "./temperature-information";

const WrapperPDPA = styled.div<{ $height?: boolean }>`
  height: ${({ $height }) => ($height ? "43px" : "100%")};
  width: 100%;
  background-color: #f5f5f5;
`;

const WrapperDiv = styled.div`
  a {
    background-color: #f5f5f5 !important;
  }
`;

interface IEllipsis {
  pdpa: boolean;
  terms: boolean;
  privacy: boolean;
}

const PdpaInformation = ({
  pending,
  form,
  menu,
}: {
  pending: boolean;
  form: FormInstance;
  menu: TMenu;
}) => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();

  const [ellipsis, setEllipsis] = useState<IEllipsis>({
    pdpa: true,
    terms: true,
    privacy: true,
  });

  const dataPDPA = useGetPDPA();
  const dataPrivacy = useGetPrivacyPolicy();
  const dataTerms = useGetTermAndCondition();

  const acceptPdpa = Form.useWatch("acceptPdpa", form);
  const menuCheckQr = menu === "receiver" || menu === "supplier";

  const hidden =
    menuCheckQr && typeof acceptPdpa === "boolean"
      ? false
      : acceptPdpa !== false;

  const findTrans = (value: any) => {
    const { header, description } = translationObject({
      object: value,
      keys: ["header", "description"],
    });
    return { header, description };
  };

  const pdpa = findTrans(dataPDPA.data?.data?.[0]);
  const terms = findTrans(dataTerms.data?.data?.[0]);
  const privacy = findTrans(dataPrivacy.data?.data?.[0]);

  const onClickShowPDPA = () => {
    setEllipsis({ ...ellipsis, pdpa: !ellipsis.pdpa });
  };

  const onClickShowTerms = () => {
    setEllipsis({ ...ellipsis, terms: !ellipsis.terms });
  };

  const onClickShowPrivacy = () => {
    setEllipsis({ ...ellipsis, privacy: !ellipsis.privacy });
  };

  const downloadQRCode = () => {
    const day = dayjs().format("DD MMM YYYY");
    // Generate download with use canvas and stream
    let canvas = document.getElementById("qr-gen") as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qrcode ${day}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const hiddenValue =
    (!dataTerms.data?.data ||
      (dataTerms.data.data && dataTerms.data.data.length <= 0)) &&
    (!dataPrivacy.data?.data ||
      (dataPrivacy.data.data && dataPrivacy.data.data.length <= 0)) &&
    (!dataPDPA.data?.data ||
      (dataPDPA.data.data && dataPDPA.data.data.length <= 0));

  return (
    <Container height="100%" padding="p-0" className="mb-4">
      {/* pdpa */}
      <Row justify="space-around" className="w-full px-5">
        <Col flex="auto">
          <div className="pb-3" hidden={!hiddenValue}></div>
          <div
            className=" pt-5 pb-3"
            hidden={
              !dataPDPA.data?.data ||
              (dataPDPA.data.data && dataPDPA.data.data.length <= 0)
            }
          >
            <WrapperPDPA
              className="pl-3 pr-4 rounded-app"
              $height={!pdpa.description}
            >
              <Row wrap={false}>
                <Col className="!flex flex-col justify-start pt-5" span={1}>
                  {ellipsis.pdpa ? (
                    <RightOutlined
                      onClick={onClickShowPDPA}
                      spin={dataPDPA.isFetching}
                    />
                  ) : (
                    <UpOutlined
                      onClick={onClickShowPDPA}
                      spin={dataPDPA.isFetching}
                    />
                  )}
                </Col>
                <Col className="pt-3 text-[20px]" span={23}>
                  {pdpa.header}
                  <Typography.Paragraph ellipsis={ellipsis.pdpa}>
                    <WrapperDiv
                      hidden={ellipsis.pdpa}
                      dangerouslySetInnerHTML={{ __html: pdpa.description }}
                    />
                  </Typography.Paragraph>
                </Col>
              </Row>
            </WrapperPDPA>
          </div>
          {/* terms */}
          <div
            className=" pb-3"
            hidden={
              !dataTerms.data?.data ||
              (dataTerms.data.data && dataTerms.data.data.length <= 0)
            }
          >
            <WrapperPDPA
              className="pl-3 pr-4 rounded-app"
              $height={!terms.description}
            >
              <Row wrap={false}>
                <Col className="!flex flex-col justify-start pt-5" span={1}>
                  {ellipsis.terms ? (
                    <RightOutlined
                      onClick={onClickShowTerms}
                      spin={dataTerms.isFetching}
                    />
                  ) : (
                    <UpOutlined
                      onClick={onClickShowTerms}
                      spin={dataTerms.isFetching}
                    />
                  )}
                </Col>
                <Col className="pt-3 text-[20px]" span={23}>
                  {terms.header}
                  <Typography.Paragraph ellipsis={ellipsis.terms}>
                    <WrapperDiv
                      hidden={ellipsis.terms}
                      dangerouslySetInnerHTML={{ __html: terms.description }}
                    />
                  </Typography.Paragraph>
                </Col>
              </Row>
            </WrapperPDPA>
          </div>
          {/* privacy */}
          <div
            className=" pb-3"
            hidden={
              !dataPrivacy.data?.data ||
              (dataPrivacy.data.data && dataPrivacy.data.data.length <= 0)
            }
          >
            <WrapperPDPA
              className="pl-3 pr-4 rounded-app"
              $height={!privacy.description}
            >
              <Row wrap={false}>
                <Col className="!flex flex-col justify-start pt-5" span={1}>
                  {ellipsis.privacy ? (
                    <RightOutlined
                      onClick={onClickShowPrivacy}
                      spin={dataPrivacy.isFetching}
                    />
                  ) : (
                    <UpOutlined
                      onClick={onClickShowPrivacy}
                      spin={dataPrivacy.isFetching}
                    />
                  )}
                </Col>
                <Col className="pt-3 text-[20px]" span={23}>
                  {privacy.header}
                  <Typography.Paragraph ellipsis={ellipsis.privacy}>
                    <WrapperDiv
                      hidden={ellipsis.privacy}
                      dangerouslySetInnerHTML={{ __html: privacy.description }}
                    />
                  </Typography.Paragraph>
                </Col>
              </Row>
            </WrapperPDPA>
          </div>
        </Col>
        <Col className="center flex-col pl-5 pt-4 " hidden={hidden}>
          <Form.Item name="qrcodeNumber" noStyle>
            <QRCodeShow downloadQRCode={downloadQRCode} hidden={hidden} />
          </Form.Item>
        </Col>
      </Row>
      <div className="px-5 pb-4">
        <Form.Item name="acceptPdpa" rules={[{ required: true }]}>
          <CRadio.Group
            optionType="default"
            options={[
              { label: t("consent"), value: true },
              { label: t("not-consent"), value: false },
            ]}
            disabled={pending}
          />
        </Form.Item>
      </div>
      
    </Container>
  );
};

const QRCodeShow = ({
  onChange,
  value,
  downloadQRCode,
  hidden,
}: {
  onChange?: any;
  value?: any;
  downloadQRCode: () => void;
  hidden: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div hidden={hidden}>
      <div>
        <QRCode
          id="qr-gen"
          height={200}
          className="my-3"
          value={value}
          renderAs="canvas"
        />
      </div>

      <div>
        <CButton onClick={downloadQRCode} theme="gray">
          {t("download-qrcode")}
        </CButton>
      </div>
    </div>
  );
};
export default PdpaInformation;
