import { Divider, Form, Typography } from "antd";
import ActionButtonGroup, {
  ActionButtonGroupProps,
} from "components/button/action-button-group";
import CInfiniteScroll, {
  CInfiniteScrollProps,
} from "components/display/c-infinite-scroll";
import CInput from "components/input/c-input";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: white;
`;

interface InfiniteProps extends CInfiniteScrollProps {
  search?: {
    title?: string;
    placeholder?: string;
    onChange: (value: string) => void;
  };
}

interface InfiniteSidebarProps {
  title: string;
  content?: ReactNode;
  infiniteScroll?: InfiniteProps;
  actionButtonGroup?: ActionButtonGroupProps;
}

const InfiniteSidebar: FC<InfiniteSidebarProps> = ({
  title,
  content,
  infiniteScroll,
  actionButtonGroup,
}) => {
  const { t } = useTranslation();
  const { search } = infiniteScroll || {};

  const getHeight = () => {
    if (infiniteScroll?.search && actionButtonGroup) {
      return "calc(100vh - 417px)";
    }
    if (infiniteScroll?.search) {
      return "calc(100vh - 360px)";
    }
    if (actionButtonGroup) {
      return "calc(100vh - 320px)";
    }
    return 0;
  };

  const infiniteHeight = getHeight();

  return (
    <Wrapper className="shadow">
      <div className="p-5">
        <Typography.Title
          ellipsis={{ tooltip: title }}
          level={5}
          className="!text-primary"
        >
          {title}
        </Typography.Title>
      </div>
      <Divider className="!m-0" />
      <Form
        hidden={!!content || (!actionButtonGroup && !infiniteScroll)}
        layout="vertical"
        className="!px-5 !pt-5"
      >
        <Form.Item hidden={!actionButtonGroup} className="!mb-5">
          <ActionButtonGroup {...actionButtonGroup} />
        </Form.Item>
        <Form.Item
          hidden={!infiniteScroll?.search}
          className="!mb-5"
          label={search?.title || t("search")}
        >
          <CInput.Debounce
            search
            placeholder={search?.placeholder || ""}
            onChange={(e) => {
              const { onChange } = infiniteScroll?.search || {};
              onChange?.(e);
            }}
          />
        </Form.Item>
      </Form>
      {infiniteScroll && (
        <CInfiniteScroll height={infiniteHeight} {...infiniteScroll} />
      )}
      <div
        hidden={!content}
        className="p-5"
        style={{ height: "calc(100vh - 245px)", overflow: "auto" }}
      >
        {content}
      </div>
    </Wrapper>
  );
};

export default InfiniteSidebar;
