import { Row, Col, Typography } from "antd";
import CProgress from "components/display/c-progress";
import CCard from "components/display/card";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { percentage } from "tools/utils";

interface VehicleFloorSidebarCardProps {
  title: string;
  maxValue: number;
  value: number;
  onClick: () => void;
  active: boolean;
}

const VehicleFloorSidebarCard: FC<VehicleFloorSidebarCardProps> = ({
  title,
  value,
  maxValue,
  onClick,
  active,
}) => {
  const { t } = useTranslation();

  const percent = percentage(value, maxValue);

  return (
    <CCard.SlashHeader
      title={title}
      className="mb-4 overflow-hidden"
      onClick={onClick}
      active={active}
    >
      <Row
        align="middle"
        className="px-3 py-2"
        style={{ height: 86 }}
        gutter={12}
        justify="space-between"
      >
        <Col>
          <Typography.Text type="secondary">{`${t(
            "occupancy-rate"
          )}: ${percent}%`}</Typography.Text>
        </Col>
        <Col>
          <CProgress percent={percent} type="circle" width={60} />
        </Col>
      </Row>
    </CCard.SlashHeader>
  );
};

export default VehicleFloorSidebarCard;
