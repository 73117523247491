import { Col, Row, Typography } from "antd";
import { BarrierCloseIcon } from "assets/icons/general";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import { useTranslation } from "react-i18next";
import Icon from "@ant-design/icons";
import styled from "styled-components";
import tw from "twin.macro";
import withAccessibilityDeterminedAccessLevel, {
  useAccessibilityDeterminedAccessLevel,
} from "provider/accessibility-determined-by-access-level";
import AccessibilityDeterminedByAccessLevelContent from "./content";
import { useGetAccessibilitys } from "services/accessibility";
import { IAccessibilityParams } from "services/accessibility/interface";
import { useState } from "react";

const StyledIcon = styled(Icon)`
  svg * {
    ${tw`fill-colors-primary-dark`};
  }
  font-size: 28px;
`;

const AccessibilityDeterminedByAccessLevelPage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <Sidebar />
        </Col>
        <Col span={18} className="p-5" style={{ height:"calc(100vh - 200px)", overflowY: "auto" }}>
          <AccessibilityDeterminedByAccessLevelContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { accessibility, setAccessibility} = useAccessibilityDeterminedAccessLevel();

  const [params, setParams] = useState<IAccessibilityParams>({});
  const data_accessibility = useGetAccessibilitys(params);

  const content = () => {
    return data_accessibility?.data?.data.map((item) => {
      return (
        <CCard.SlashHeader
          active={accessibility === item}
          className="mb-4"
          title={item?.accessibilityName}
          key={item.id}
          onClick={() => {
            setAccessibility(item);
          }}
        >
          <Row
            align="middle"
            className="px-3 py-2"
            style={{ height: 86 }}
            gutter={12}
          >
            <Col>
              <StyledIcon component={BarrierCloseIcon} />
            </Col>
            <Col>
              <Typography.Text
                type="secondary"
                ellipsis={{ tooltip: t("list") }}
              >
                {`${t("list")}`} :
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>{`${item?.lengthInGroup || 0} ${t("list")}`}</Typography.Text>
            </Col>
          </Row>
        </CCard.SlashHeader>
      );
    });
  };

  return (
    <InfiniteSidebar
      title={t("accessibility-management-list")}
      infiniteScroll={{
        render: content,
        className: "px-5",
        search: {
          onChange: (e) => {
            setParams({ ...params, search: e });
          },
        },
      }}
    />
  );
};

export default withAccessibilityDeterminedAccessLevel(
  AccessibilityDeterminedByAccessLevelPage
);
