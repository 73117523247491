import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";
import { useScrollGetDepartments } from "services/department";
import { IDepartmentParams } from "services/department/interface";

export interface CSelectDepartmentProps extends CSelectProps {
  queryParams: IDepartmentParams;
}

const CSelectDepartment: FC<CSelectDepartmentProps> = ({
  queryParams,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelect.Pagination
      {...props}
      translation
      valueKey="id"
      titleKey="title"
      searchKey="titleName"
      placeholder={t("department")}
      useQuery={useScrollGetDepartments}
      queryParams={{ ...queryParams ,
        limit: 100,
      }}
    />
  );
};

export default CSelectDepartment;
