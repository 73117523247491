import { useQueryClient } from "@tanstack/react-query";
import { Col, Row, Typography } from "antd";
import CAvatar from "components/display/c-avatar";
import CImage from "components/display/c-image";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import { useNavigator } from "provider/navigator";
import { useNotificationHeader } from "provider/notification-header";
import { useTranslation } from "react-i18next";
import { MAIN_MENU } from "router";
import { IMainMenu, TMainPage } from "router/interface";
import { usePatchNotification } from "services/notification-header";
import { INotificationHeader } from "services/notification-header/interface";
import styled from "styled-components";

const SubCardContainer = styled.div<{ background: boolean; height?: string }>`
  height: ${({ height }) => (height ? height : "50px")};
  background-color: ${({ background }) =>
    background ? "rgba(255, 0, 0, 0.04)" : "white"};
  cursor: pointer;
  &:hover {
    background-color: #f9ecec;
  }
`;

export const SubCard = ({ item }: { item: INotificationHeader }) => {
  const qClient = useQueryClient();
  const { onAddTab } = useNavigator();
  const { setSelectMenu, setOpenNoti } = useNotificationHeader();
  const patch_noti = usePatchNotification();
  const onClick = (menu?: IMainMenu) => {
    if (item.read) {
      return setMenu(menu);
    }
    return patch_noti.mutate(
      { id: item.id },
      {
        onSuccess() {
          qClient.invalidateQueries(["get-notifications"]);
          qClient.invalidateQueries(["get-notifications-scroll"]);
          setMenu(menu);
        },
        onError({ message }: any) {
          fireNotification({ type: "error", description: message });
        },
      }
    );
  };

  const setMenu = (menu?: IMainMenu) => {
    setSelectMenu?.(undefined);
    setOpenNoti?.(false);
    if (menu) onAddTab(menu);
  };

  const findMenu = (key: TMainPage) => {
    return MAIN_MENU.find((e) => e.key === key);
  };

  switch (item.type) {
    case "FAILED_THE_3RD_OF_VEHICLE_CONDITION":
      return (
        <SubCardVehicle
          item={item}
          menu={findMenu("vehicle-management")}
          onClick={onClick}
        />
      );
    case "VIP_REJECT_CAR":
      return (
        <SubCardVehicle
          item={item}
          menu={findMenu("vehicle-management")}
          onClick={onClick}
        />
      );
    case "LICENSE_PLATE_DOES_NOT_PASS":
      return (
        <SubCardVehicle
          item={item}
          menu={findMenu("vehicle-management")}
          onClick={onClick}
        />
      );
    default:
      return (
        <SubCardFace
          item={item}
          menu={findMenu("access-control")}
          onClick={onClick}
        />
      );
  }
};

export const SubCardFace = ({
  item,
  menu,
  onClick,
}: {
  item: INotificationHeader;
  menu?: IMainMenu;
  onClick: (v?: IMainMenu) => void;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const { doorLog, createdAt, type } = item;
  const { firstName, lastName, imageUrl, temperature, door } = doorLog || {};
  const name = firstName && lastName ? firstName + " " + lastName : "-";
  const src = imageUrl || "";
  const height = type === "TEMPERATURE_DOES_NOT_PASS" ? "85px" : "65px";
  const date = formatDate({
    date: createdAt,
    format: "nomal-full-time",
    keySeparator: " ",
  });
  return (
    <SubCardContainer
      background={!item.read}
      className="px-2"
      height={height}
      onClick={() => {
        onClick(menu);
      }}
    >
      <Row align="middle" className="h-full ">
        <Col span={4}>
          <CAvatar src={src} />
        </Col>
        <Col span={20}>
          <Typography.Title style={{ fontSize: 13, color: "#f82602" }}>
            {name}
          </Typography.Title>
          <Typography.Text style={{ fontSize: 12, color: "#4e4e4e" }}>
            {t("door")} : {door?.doorName || "-"}
          </Typography.Text>
          {type === "TEMPERATURE_DOES_NOT_PASS" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              {t("temperature")} : {temperature}
            </div>
          )}
          <div style={{ fontSize: 12, color: "#4e4e4e" }}>
            {t("date")} : {date}
          </div>
        </Col>
      </Row>
    </SubCardContainer>
  );
};

export const SubCardVehicle = ({
  item,
  menu,
  onClick,
}: {
  item: INotificationHeader;
  menu?: IMainMenu;
  onClick: (v?: IMainMenu) => void;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const { vehicleCondition, createdAt, type, licensePlateImage } = item;
  const { description, vehicle } = vehicleCondition || {};
  const { vehicleDetects } = vehicle || {};
  const url =
    vehicleDetects?.sort((a, b) => b.id - a.id)?.[0]?.detectImage?.url || "";
  const urlNotPass = licensePlateImage?.url || "";
  const date = formatDate({
    date: createdAt,
    format: "nomal-full-time",
    keySeparator: " ",
  });

  return (
    <SubCardContainer
      background={!item.read}
      className="px-2"
      height="65px"
      onClick={() => {
        onClick(menu);
      }}
    >
      <Row align="middle" className="h-full ">
        <Col span={6}>
          <CImage
            src={type === "LICENSE_PLATE_DOES_NOT_PASS" ? urlNotPass : url}
            preview={false}
            width={80}
            height={49}
            style={{ objectFit: "unset" }}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={17}>
          <Typography.Title style={{ fontSize: 13, color: "#f82602" }}>
            {type === "LICENSE_PLATE_DOES_NOT_PASS"
              ? item?.licensePlate || ""
              : vehicle?.licensePlate || "-"}
          </Typography.Title>
          {type === "FAILED_THE_3RD_OF_VEHICLE_CONDITION" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              {t("failed-vehicle-inspection-more-than-3-time")}
            </div>
          )}
          {type === "LICENSE_PLATE_DOES_NOT_PASS" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              {t("license-plate-does-not-pass")}
            </div>
          )}
          <div style={{ fontSize: 12, color: "#4e4e4e" }}>
            {t("date")} : {date}
          </div>
          {type === "VIP_REJECT_CAR" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              <Typography.Text
                ellipsis={{
                  tooltip: `${t("callback-note")} : ${description || "-"}`,
                }}
                style={{ fontSize: 12, color: "#4e4e4e" }}
              >
                {t("callback-note")} : {description || "-"}
              </Typography.Text>
            </div>
          )}
        </Col>
      </Row>
    </SubCardContainer>
  );
};
