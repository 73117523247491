import React from "react";
import { APP_ROUTES } from "router";
import { TSubPage } from "./../../router/interface";
import {
  IPageRoutePage,
  IPageRouteState,
  PageRoutePayloadType,
} from "./interface";

const getElement = (key: TSubPage): React.ReactNode => {
  const find = APP_ROUTES.find((e) => e.key === key);
  if (!find) {
    throw new Error("ไม่พบหน้านี้ในระบบ กรุณาตรวจสอบ key และ element");
  }
  return React.createElement(find.element);
};

const updateCurrentPageParams = (
  state: IPageRouteState,
  params?: object
): IPageRouteState => {
  if (!params) return state;
  const { pages } = state;
  const next = pages.map((item, index) => {
    if (index === 0) {
      return { ...item, params: { ...item.params, ...params } };
    }
    return item;
  });
  return { ...state, pages: next };
};

export const pageRouteReducer = (
  state: IPageRouteState,
  payload: PageRoutePayloadType
) => {
  const { action, pageKey, params } = payload;
  let { pages } = state;

  if (action === "back") {
    const _pages = [...pages];
    _pages.pop();
    return { ...state, pages: _pages };
  }

  if (action === "updateCurrentPageParams") {
    return updateCurrentPageParams(state, params);
  }

  if (!pageKey) return state;

  const element = getElement(pageKey);
  const page: IPageRoutePage = { element, key: pageKey, params };

  switch (action) {
    case "push":
      return { ...state, pages: [...pages, page] };
    case "replace":
      const _pages = [...pages];
      _pages.pop();
      return { ...state, pages: [..._pages, page] };
    case "replaceAll":
      return { ...state, pages: [page] };
    case "updateParams":
      return state;
  }
};
