import { Col, Typography, Form } from "antd";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import React from "react";
import { useTranslation } from "react-i18next";

const SupplierInformation = ({ pending }: { pending: boolean }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={24} className="mb-2">
        <Typography.Text className="!text-primary-dark">
          {t("shipping-information")}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Form.Item
          name="companyName"
          rules={[{ required: true }]}
          label={t("transport-name")}
        >
          <CSelect.CompanyName
            placeholder={t("transport-name")}
            disabled={pending}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="address"
          rules={[{ required: true }]}
          label={t("shipping-address")}
        >
          <CInput.TextArea
            rows={4}
            placeholder={t("shipping-address")}
            disabled={pending}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="licensePlateNumber"
          rules={[{ required: true }]}
          label={t("trailer-head-license-plate-number")}
        >
          <CInput
            placeholder={t("trailer-head-license-plate-number")}
            disabled={pending}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="trailerLicensePlateNumber"
          label={t("trailer-tail-license-plate-number")}
        >
          <CInput
            placeholder={t("trailer-tail-license-plate-number")}
            disabled={pending}
          />
        </Form.Item>
      </Col>
      {/* <Col span={24}>
        <Form.Item label={t("product-group")} name="productGroups">
          <CInput.TextArea
            rows={4}
            placeholder={t("product-group")}
            disabled={pending}
          />
        </Form.Item>
      </Col> */}
      <Col span={24}>
        <Form.Item label={t("vehicle-type")} name="vehicleType">
          <CSelect.VehicleType disabled={pending} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default SupplierInformation;
