import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import { useAppRoleMenu } from "provider/app-role-menu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteParking,
  useExportExcelParking,
  useGetParkings,
} from "services/parking";
import { IParking, IParkingParams } from "services/parking/interface";
import AddLevelDrawer from "./add-level-drawer";
import AddParkingDrawer from "./add-parking-drawer";

const VehicleParkings = () => {
  const { t } = useTranslation();
  const [vLevel, setVLevel] = useState<boolean>(false);
  const [vParking, setVParking] = useState<boolean>(false);
  const [parking, setParking] = useState<IParking>();
  const [params, setParams] = useState<IParkingParams>({ page: 1, limit: 10 });

  const get_parkings = useGetParkings(params);
  const del_parking = useDeleteParking();
  const export_excel = useExportExcelParking();

  const { getMenuByKey } = useAppRoleMenu();

  const toggleLevel = () => {
    setVLevel(!vLevel);
  };
  const toggleParking = () => {
    setVParking(!vParking);
  };

  const columns: ColumnsType<IParking[]> = [
    {
      title: t("parking-name"),
      width: 200,
      ellipsis: { showTitle: false },
      dataIndex: "parkingName",
    },
    { title: t("capacity"), dataIndex: "capacity" },
    { title: t("parking-available"), dataIndex: "unoccupied" },
    {
      title: t("visitor"),
      ellipsis: true,
      dataIndex: "parkingVisit",
    },
    {
      title: t("registered"),
      ellipsis: true,
      dataIndex: "parkingRegister",
    },
    {
      title: t("motorcycle"),
      ellipsis: true,
      dataIndex: "parkingMotor",
    },
    {
      title: t("staff"),
      ellipsis: true,
      dataIndex: "parkingEmployee",
    },
  ];

  const hasMenuLevel = () => {
    const { menus } = getMenuByKey("vehicle-management");
    const find = menus.find(
      (e) => e.key === "vehicle-management.parking-management"
    );
    if (!find || !find.children) return false;

    return find.children.some((e) => e.key === "vehicle-management.floors");
  };

  const hideAddMenuLevel = hasMenuLevel();

  return (
    <PageContent breadcrumb className="p-5">
      <AddLevelDrawer onClose={toggleLevel} visible={vLevel} />
      <AddParkingDrawer
        parking={parking}
        onClose={toggleParking}
        visible={vParking}
        resetParking={() => setParking(undefined)}
      />
      <CTable
        columns={columns}
        title={t("parking-list")}
        onAdd={{ onClick: toggleParking, label: `+ ${t("add-parking")}` }}
        extraHeaderButton={[
          {
            onClick: toggleLevel,
            children: `+ ${t("add-level")}`,
            theme: "white",
            hidden: hideAddMenuLevel,
          },
        ]}
        action={{
          excels: {
            export: {
              mutation: export_excel,
              fileName: "parking-report",
            },
          },
        }}
        selectAble={false}
        query={get_parkings}
        onDelete={{
          invalidateQueries: ["get-parkings", "get-parking", "parking-count"],
          mutation: del_parking,
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        onRowClick={(value) => {
          setParking(value);
          toggleParking();
        }}
      />
    </PageContent>
  );
};

export default VehicleParkings;
