import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IFaceInformation,
  IFaceInformationNotIn,
  IFaceInformationNotInParams,
  IFaceInformationParams,
} from "./interface";

export const useGetFaceInformations = (
  params?: IFaceInformationParams
): UseQueryResult<IResponse<IFaceInformation[]>> => {
  return useQuery(
    ["get-face-informations", params],
    async () => {
      const res = await axios.get(`/face-information/${params?.group}`, {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: !!params?.group }
  );
};

export const usePostFaceInformation = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.post("/face-information", data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetFaceInformationsNotIn = (
  params?: IFaceInformationNotInParams
): UseQueryResult<IResponse<IFaceInformationNotIn[]>> => {
  return useQuery(
    ["get-face-informations-not-in", params],
    async () => {
      const res = await axios.get(`/face-information/not-in-group`, { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useDeleteFaceInformation = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/face-information/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
