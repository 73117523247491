import { Select, SelectProps } from "antd";
import { FC, ReactNode } from "react";
import CSelectPagination, { CSelectPaginationProps } from "./pagination";
import styled from "styled-components";
import CSelectJobPosition, { CSelectJobPositionProps } from "./job-position";
import CSelectGender from "./gender";
import CSelectRole, { CSelectRoleProps } from "./role";
import CSelectIsActive from "./is-active-status";
import { useAppLanguage } from "provider/app-language";
import CSelectVehicleBrand from "./vehicle-brand";
import CSelectVehicleType from "./vehicle-type";
import CSelectProvince from "./province";
import CSelectCDF from "./company-departure-floor";
import CSelectContactByDeparture from "./contact-by-departure";
import CSelectContactStaffName from "./contact-staff-name";
import CSelectContactType from "./contact-type";
import CSelectOrganization from "./organization";
import CSelectUser, { CSelectUserProps } from "./user";
import CSelectScheduleGroup from "./schedule-group";
import CSelectEntranceAndDepartureCamera, {
  CSelectEntranceAndDepartureCameraProps,
} from "./entrance-departure-camera";
import CSelectUserType from "./person-type";
import CSelectParking, { CSelectParkingProps } from "./parking";
import CSelectCompanyName from "./company-name";
import CSelectJobSupplier from "./job-supplier";
import CSelectJobType from "./work-type";
import CSelectPin from "./pin";
import CSelectRegisterType from "./register-type";
import CSelectEmployee, { CSelectEmployeeProps } from "./employee";
import CSelectAcceptStatus from "./accept-status";
import CSelectStatusQueueCar from "./status-queue-car";
import CSelectStatusQuickLab from "./status-quicklab";
import CSelectTemplateEmail, {
  CSelectTemplateEmailProps,
} from "./template-email";
import CSelectGateName from "./gate-name";
import CSelectNotificationType from "./notification-type";
import { useTranslation } from "react-i18next";
import CSelectCompanyNameTags from "./company-name-tags";
import CSelectGroupPerson from "./group-person";
import CSelectDepartment, { CSelectDepartmentProps } from "./department";
import CSelectErpType from "./erp-type";
import CSelectErpProduct from "./erp-product";
import CSelectErpTransactionType from "./erp-transaction-type";
import CSelectErpPickupPoint, { CSelectErpPickupPointProps } from "./erp-pickup-point";
import CSelectErpBusinessPartner from "./erp-business-partner";
import CSelectWorkArea from "./work-area";

export const StyledSelect = styled(Select) <{ $translationI18n?: boolean }>`
  height: ${({ $translationI18n }) => ($translationI18n ? "100%" : "45px")};
  width: 100%;
  input {
    font-family: FCIconic !important;
  }
  .ant-select-selector {
    height: 100% !important;
    padding-top: ${({ $translationI18n }) => ($translationI18n ? "10px" : "")};
    padding-right: ${({ $translationI18n }) =>
    $translationI18n ? "10px" : ""};
    // padding-left: ${({ $translationI18n }) =>
    $translationI18n ? "5px" : ""};
    padding-bottom: ${({ $translationI18n }) =>
    $translationI18n ? "5px" : ""};
  }
  .ant-select-selection-placeholder {
    margin: auto;
  }
  .ant-select-selection-item {
    margin: auto;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-overflow-item {
    padding-right: ${({ $translationI18n }) => ($translationI18n ? "5px" : "")};
    padding-bottom: ${({ $translationI18n }) =>
    $translationI18n ? "5px" : ""};
  }
`;

interface CSelectOption {
  values: any[];
  valueKey: string;
  titleKey: string;
  translation?: boolean;
  translationI18n?: boolean;
}

type NotationTypes = {
  Pagination: FC<CSelectPaginationProps>;
  JobPosition: FC<CSelectJobPositionProps>;
  Gender: FC<CSelectProps>;
  Role: FC<CSelectRoleProps>;
  IsActive: FC<CSelectProps>;
  VehicleType: FC<CSelectProps>;
  VehicleBrand: FC<CSelectProps>;
  PlateProvince: FC<CSelectProps>;
  CompanyDepartureFloor: FC<CSelectProps>;
  ContactByDeparture: FC<CSelectProps>;
  ContactStaffName: FC<CSelectProps>;
  ContactType: FC<CSelectProps>;
  Organization: FC<CSelectProps>;
  User: FC<CSelectUserProps>;
  ScheduleGroup: FC<CSelectProps>;
  EntranceDepartureCamera: FC<CSelectEntranceAndDepartureCameraProps>;
  UserType: FC<CSelectProps>;
  Parking: FC<CSelectParkingProps>;
  CompanyName: FC<CSelectProps>;
  JobSupplier: FC<CSelectProps>;
  JobType: FC<CSelectProps>;
  Pin: FC<CSelectProps>;
  RegisterType: FC<CSelectProps>;
  Employee: FC<CSelectEmployeeProps>;
  AcceptStatus: FC<CSelectProps>;
  StatusQueueCar: FC<CSelectProps>;
  StatusQuicklab: FC<CSelectProps>;
  EmailTemplate: FC<CSelectTemplateEmailProps>;
  GateName: FC<CSelectProps>;
  NotificationType: FC<CSelectProps>;
  CompanyTage: FC<CSelectProps>;
  GroupPerson: FC<CSelectProps>;
  Department: FC<CSelectDepartmentProps>;
  ErpType: FC<CSelectProps>;
  ErpProduct: FC<CSelectProps>;
  ErpTransactionType: FC<CSelectProps>;
  ErpPickupPoint: FC<CSelectErpPickupPointProps>;
  ErpBusinessPartner: FC<CSelectProps>;
  WorkArea: FC<CSelectProps>;
};

export interface CSelectProps extends SelectProps<any, any> {
  valueOptions?: CSelectOption;
  render?: (v: any[]) => ReactNode;
  multipleMode?: boolean;
}

const CSelect: FC<CSelectProps> & NotationTypes = ({
  valueOptions,
  render,
  multipleMode,
  ...props
}) => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();

  const getOptionTitle = (object: any) => {
    if (!valueOptions) return "";
    const { translation, titleKey, translationI18n } = valueOptions;
    if (translation) {
      const value = translationObject({ object: object, keys: [titleKey] });
      return value?.[titleKey];
    }
    if (translationI18n) {
      return t(object?.[titleKey]);
    }
    return object?.[titleKey];
  };

  const getOptions = (opt?: CSelectOption): ReactNode => {
    if (render) {
      return render(opt?.values || []);
    }
    if (!opt) return [];
    const { values, valueKey } = opt;
    return values.map((item) => {
      const title = getOptionTitle(item);
      return (
        <StyledSelect.Option key={item[valueKey]} value={item[valueKey]}>
          {title}
        </StyledSelect.Option>
      );
    });
  };

  return (
    <StyledSelect
      getPopupContainer={(e) => e}
      {...props}
      $translationI18n={multipleMode}
    >
      {getOptions(valueOptions)}
    </StyledSelect>
  );
};

CSelect.Pagination = CSelectPagination;
CSelect.JobPosition = CSelectJobPosition;
CSelect.Gender = CSelectGender;
CSelect.Role = CSelectRole;
CSelect.IsActive = CSelectIsActive;
CSelect.VehicleBrand = CSelectVehicleBrand;
CSelect.VehicleType = CSelectVehicleType;
CSelect.PlateProvince = CSelectProvince;
CSelect.CompanyDepartureFloor = CSelectCDF;
CSelect.ContactByDeparture = CSelectContactByDeparture;
CSelect.ContactStaffName = CSelectContactStaffName;
CSelect.ContactType = CSelectContactType;
CSelect.Organization = CSelectOrganization;
CSelect.User = CSelectUser;
CSelect.ScheduleGroup = CSelectScheduleGroup;
CSelect.EntranceDepartureCamera = CSelectEntranceAndDepartureCamera;
CSelect.UserType = CSelectUserType;
CSelect.Parking = CSelectParking;
CSelect.CompanyName = CSelectCompanyName;
CSelect.JobSupplier = CSelectJobSupplier;
CSelect.JobType = CSelectJobType;
CSelect.Pin = CSelectPin;
CSelect.RegisterType = CSelectRegisterType;
CSelect.Employee = CSelectEmployee;
CSelect.AcceptStatus = CSelectAcceptStatus;
CSelect.StatusQueueCar = CSelectStatusQueueCar;
CSelect.StatusQuicklab = CSelectStatusQuickLab;
CSelect.EmailTemplate = CSelectTemplateEmail;
CSelect.GateName = CSelectGateName;
CSelect.NotificationType = CSelectNotificationType;
CSelect.CompanyTage = CSelectCompanyNameTags;
CSelect.GroupPerson = CSelectGroupPerson;
CSelect.Department = CSelectDepartment;
CSelect.ErpType = CSelectErpType;
CSelect.ErpProduct = CSelectErpProduct;
CSelect.ErpTransactionType = CSelectErpTransactionType;
CSelect.ErpPickupPoint = CSelectErpPickupPoint;
CSelect.ErpBusinessPartner = CSelectErpBusinessPartner;
CSelect.WorkArea = CSelectWorkArea

export default CSelect;
