import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePinScroll } from "services/pin";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";


const CSelectPin: FC<CSelectProps> = (props) => {
    const { t } = useTranslation();
    return (
      <CSelectPagination
        {...props}
        useQuery={usePinScroll}
        valueKey="id"
        titleKey="pinName"
        // searchKey="title"
        placeholder={t("parking-lot-type")}
      />
    );
}

export default CSelectPin