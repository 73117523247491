import { Col, Typography, Form } from "antd";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import React from "react";
import { useTranslation } from "react-i18next";
import { TMenu } from "../create-user-pdpa";

const VehicleField = ({ pending, menu }: { pending: boolean; menu: TMenu }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={24} className="mb-2">
        <Typography.Text className="!text-primary-dark">
          {t("vehicle-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item label={t("vehicle-type")} name="vehicleType">
          <CSelect.VehicleType disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("vehicle-brand")} name="vehicleBrand">
          <CSelect.VehicleBrand disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={t("vehicle-registration")}
          name="licensePlateNumber"
          rules={[{ required: menu !== "visitor" }]}
        >
          <CInput placeholder={t("vehicle-registration")} disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("plate-province")} name="provincePlate">
          <CSelect.PlateProvince disabled={pending} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default VehicleField;
