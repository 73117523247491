import { Divider, Form, TableColumnsType } from "antd";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAccessibilityDeterminedByGroup } from "provider/accessibility-determined-by-group";
import { useState, FC } from "react";
import { useTranslation } from "react-i18next";

const AccessibilityDeterminedByGroupContent = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const { group } = useAccessibilityDeterminedByGroup();

  if (!group) return null;

  const toggle = () => {
    setVisible(!visible);
  };

  const elements: ISearchBoxElement[] = [
    {
      name: "a",
      label: t("accessibility-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: TableColumnsType = [
    {
      title: t("accessibility-name"),
    },
    {
      title: t("accessibility"),
    },
  ];

  const onSearch = (values: any) => {
    //
  };

  return (
    <Container padding="p-0" height="auto">
      <CreateAndEditDrawer visible={visible} onClose={toggle} />
      <PageSearchBox shadow={false} colSpan={8} elements={elements} onFinish={onSearch} />
      <Divider className="!m-0" />
      <CTable
        shadow={false}
        title={t("access-control.accessibility-determined-by-group-of-people", {
          ns: "menu",
        })}
        wrapperClassName="!mt-0"
        onAdd={{ onClick: toggle }}
        columns={columns}
      />
    </Container>
  );
};

const CreateAndEditDrawer: FC<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const columns: TableColumnsType = [
    {
      title: t("accessibility-name"),
    },
    {
      title: t("accessibility"),
    },
  ];

  return (
    <CDrawer
      title={t("add")}
      form={form}
      visible={visible}
      onClose={onClose}
      width="40vw"
    >
      <Form form={form} layout="vertical">
        <Form.Item label={t("search")} name="search">
          <CInput.Debounce />
        </Form.Item>
        <Form.Item name="select">
          <CTable.Select columns={columns} />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default AccessibilityDeterminedByGroupContent;
