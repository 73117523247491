import { CameraFilled, VideoCameraFilled } from "@ant-design/icons";
import { Typography, Divider, Row, Col, Form } from "antd";
import { FormInstance } from "antd/es/form/Form";
import CButton from "components/button/c-button";
import CImage from "components/display/c-image";
import CModal from "components/display/c-modal";
import Container from "components/display/container";
import CUpload from "components/input/c-upload";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FileInformation = ({
  form,
  pending,
}: {
  form: FormInstance;
  pending: boolean;
}) => {
  const { t } = useTranslation();

  const [src, setSrc] = useState<string>();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const setFile = () => {
    if (!src) return;
    form.setFieldsValue({ identityCardPicture: src });
  };

  const toggle = () => {
    return setVisible(!visible);
  };

  return (
    <Container height="100%" padding="p-0" className="mt-4">
      <CaptureModal visible={visible} toggle={toggle} setSrc={setSrc} />
      <div className="p-5">
        <Typography.Title level={5} className="!text-primary-dark">
          {t("data-file")}
        </Typography.Title>
      </div>
      <Divider className="!m-0" />
      <div className="p-5">
        <Row gutter={24}>
          <Col span={24}></Col>
          <Col span={8} className="!flex items-center">
            <Typography.Text>{t("id-card-picture")}</Typography.Text>
          </Col>
          <Col span={2}></Col>
          <Col span={4}>
            <CButton
              icon={<CameraFilled />}
              className="w-full"
              onClick={toggle}
            >
              {t("take-card-picture")}
            </CButton>
          </Col>
          <Col span={24} className="mt-5">
            <Form.Item name="identityCardPicture">
              <CUpload.Area readonly={pending} className="py-4" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const StyledModal = styled(CModal)`
  .ant-modal-body {
    padding: 0px;
    border-radius: 0px;
  }
`;

const CaptureModal = ({
  visible,
  toggle,
  setSrc,
}: {
  visible: boolean;
  toggle: () => void;
  setSrc: (v?: string) => void;
}) => {
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [stream, setStream] = useState<MediaStream>();
  const [isScanned, setIsScanned] = useState<boolean>(false);
  const [image, setImage] = useState<string | undefined>();

  const displaySize = {
    width: 1000,
    height: 1000,
  };

  const openVideo = async () => {
    try {
      setIsScanned(false);
      const request = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user", ...displaySize },
        audio: false,
      });

      if (request.active && videoRef.current) {
        videoRef.current.srcObject = request;
        if (videoRef.current) videoRef.current.play();
        setStream(request);
      }
    } catch (err) {
      //
    }
  };

  const closeVideo = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
          setStream(undefined);
        }
      });
    }
  };

  const capture = () => {
    var canvas = document.querySelector("canvas");
    const video = document.querySelector("video");
    if (!canvas || !video) return;
    canvas.width = video?.videoWidth;
    canvas.height = video?.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    setImage(canvas.toDataURL());
    setIsScanned(true);
  };

  const onCancel = () => {
    toggle();
    if (visible === true) {
      closeVideo();
    }
  };

  return (
    <StyledModal
      className="rounded-[0px]"
      visible={visible}
      onCancel={onCancel}
      width={1000}
      centered
    >
      <AcceptCamera openVideo={openVideo} hidden={!!stream || isScanned} />
      <video
        ref={videoRef}
        {...displaySize}
        playsInline
        hidden={isScanned || !stream}
      />
      <canvas ref={canvasRef} {...displaySize} hidden />
      <CImage src={image} hidden={!isScanned} />
      <CButton
        theme="yellow"
        className="w-full !rounded-[0px]"
        onClick={() => {
          onCancel();
          setSrc(image);
          setIsScanned(false);
        }}
        hidden={!isScanned}
      >
        {t("save")}
      </CButton>
      <CButton
        icon={<CameraFilled />}
        className="w-full !rounded-[0px]"
        onClick={capture}
        hidden={!stream || isScanned}
      >
        {t("take-card-picture")}
      </CButton>
    </StyledModal>
  );
};

const AcceptCamera: FC<{ openVideo: () => void; hidden: boolean }> = ({
  openVideo,
  hidden,
}) => {
  const { t } = useTranslation();

  if (hidden) return null;

  return (
    <div className="center flex-col h-[1000px] w-[1000px]">
      <VideoCameraFilled
        style={{ fontSize: 40 }}
        className="!text-primary-dark"
      />
      <Typography.Text className="mt-4">
        {t("please-accept-access-to-the-camera", { ns: "message" })}
      </Typography.Text>
      <Typography.Text style={{ fontSize: 12 }} className="mt-2">
        {t("or", { ns: "message" })}
      </Typography.Text>
      <CButton
        onClick={openVideo}
        className="mt-3"
        style={{ width: 120, height: 35 }}
      >
        {t("click-here", { ns: "message" })}
      </CButton>
    </div>
  );
};

export default FileInformation;
