import { Typography, Divider, Row, Col, Badge } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSpace from "../c-space";
import Container from "../container";

interface IMarkCount {
  pinId: number;
  pinName: string;
  colorType: string;
}
export interface ParkingTypeCardProps {
  markType?: IMarkCount[];
}

// const mocking = [
//   {
//     name: "VIP",
//     value: 3,
//     color: "orange",
//   },
//   {
//     name: "GENERAL",
//     value: 1,
//     color: "blue",
//   },
//   {
//     name: "BANNED",
//     value: 1,
//     color: "red",
//   },
// ];

const ParkingTypeCard: FC<ParkingTypeCardProps> = ({ markType = [] }) => {
  const { t } = useTranslation();

  const groupById = () => {
    return markType.reduce((r, c) => {
      const find = r.find((e) => e.pinId === c.pinId);
      if (find) {
        const next = r.map((item) => {
          if (item.pinId === c.pinId) {
            return {...item, count: item.count + 1}
          }
          return item;
        });
        return next
      }

      return [...r, { ...c, count: 1 }];
    }, [] as (IMarkCount & { count: number })[]);
  };

  const data = groupById()

  return (
    <Container className="!shadow" padding="p-0" height="auto">
      <div className="p-4">
        <Typography.Title level={5}>{t("parking-lot-type")}</Typography.Title>
      </div>
      <Divider className="!m-0" />
      <Row
        style={{ height: 82 }}
        justify="space-between"
        align="middle"
        className="p-4"
      >
        {data.map((item) => {
          return (
            <Col span={12} key={item.pinId}>
              <CSpace>
                <Badge color={item.colorType} />
                <Typography.Text>{item.pinName}</Typography.Text>
                <Typography.Text>
                  {item.count}
                </Typography.Text>
              </CSpace>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ParkingTypeCard;
