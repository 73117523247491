import { Checkbox, Form, Typography } from "antd";
import CInput from "components/input/c-input";
import CRangePicker from "components/input/c-range-picker";
import CSelect from "components/input/c-select";
import { useVehicleReport } from "provider/vehicle-report";
import { vehicleReportChoice } from "provider/vehicle-report/interface";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div`
  ${tw`bg-white`};
  height: calc(100vh - 178px);
  overflow-y: auto;
`;

const VehicleReportSider = () => {
  const { t } = useTranslation();

  const { selectKeys, setSelectKeys, setParams, params } = useVehicleReport();

  return (
    <Wrapper className="p-4">
      <Form layout="vertical">
        <Form.Item label={t("created-at")}>
          <CRangePicker
            onChange={(e) => {
              setParams({
                ...params,
                startDate: e?.[0],
                endDate: e?.[1],
              });
            }}
            value={[params.startDate, params.endDate]}
          />
        </Form.Item>
        <Form.Item label={t("first-name")}>
          <CInput.Debounce
            value={params?.firstName}
            onChange={(e) => {
              setParams({ ...params, firstName: e });
            }}
            placeholder={t("first-name")}
          />
        </Form.Item>
        <Form.Item label={t("last-name")}>
          <CInput.Debounce
            value={params?.lastName}
            onChange={(e) => {
              setParams({ ...params, lastName: e });
            }}
            placeholder={t("last-name")}
          />
        </Form.Item>
        <Form.Item label={t("license-plate-number")}>
          <CSelect
            onChange={(e: string[]) => {
              setParams({ ...params, plateNum: e });
            }}
            mode="tags"
            placeholder={t("license-plate-number")}
          />
        </Form.Item>
        <Form.Item label={t("color")}>
          <CSelect
            onChange={(e: string[]) => {
              setParams({ ...params, color: e });
            }}
            mode="tags"
            placeholder={t("color")}
          />
        </Form.Item>
        <Form.Item label={t("phone-number")}>
          <CInput.Debounce
            value={params?.phoneNumber}
            onChange={(e) => {
              setParams({ ...params, phoneNumber: e });
            }}
            placeholder={t("phone-number")}
          />
        </Form.Item>
        <Form.Item>
          <Typography.Title level={5}>
            {t("required-information")}
          </Typography.Title>
        </Form.Item>
        {Object.keys(vehicleReportChoice).map((itemKey) => {
          const { key, title } = vehicleReportChoice[itemKey];
          return (
            <Form.Item key={key}>
              <Checkbox
                onChange={(e) => {
                  const { checked } = e.target;
                  if (checked) {
                    setSelectKeys([...selectKeys, key]);
                  } else {
                    const next = selectKeys.filter((e) => e !== key);
                    setSelectKeys(next);
                  }
                }}
                checked={selectKeys.some((e) => e === key)}
              >
                {t(title)}
              </Checkbox>
            </Form.Item>
          );
        })}
      </Form>
    </Wrapper>
  );
};

export default VehicleReportSider;
