import { Form } from "antd";
import CDrawer, { IBaseVisible } from "components/display/c-drawer";
import CSelect from "components/input/c-select";
import { fireNotification } from "components/popup/notification";
import { useDisplayScreen } from "provider/display-screen";
import { useVehicleSocket } from "provider/vehicle-socket";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IMark } from "services/mark/interface";

const DisplayDeviceInHeader: FC<IBaseVisible> = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { setVisibleEntrance, visibleEntrance } = useDisplayScreen();
  const [itn, setItn] = useState<{
    vehicle?: IMark;
    truckFace?: IMark;
    generalFace?: IMark;
  }>();
  const { setSelected, selected } = useVehicleSocket();

  useEffect(() => {
    if (selected && selected.vehicle.barrierType === "IN") {
      form.setFieldsValue({
        vehicle: selected?.vehicle?.id,
        generalFace: selected.generalFace?.id,
        truckFace: selected.truckFace?.id,
      });
      setItn(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, visible]);

  const onSave = () => {
    const { vehicle, truckFace, generalFace } = itn || {};

    if (!vehicle) {
      return fireNotification({
        type: "warning",
        menu: "vehicle-management",
        message: "please select vehicle camera!",
      });
    }

    if (!vehicle.encodeDevice) {
      return fireNotification({
        type: "warning",
        menu: "vehicle-management",
        message: "encode-device of vehicle camera is missing!",
      });
    }

    // if (face && !face.encodeDevice) {
    //   return fireNotification({
    //     type: "warning",
    //     menu: "vehicle-management",
    //     message: "encode-device of face camera is missing!",
    //   });
    // }

    setSelected({ vehicle, truckFace, generalFace });
    onClose();
    setVisibleEntrance?.(!visibleEntrance);
  };

  return (
    <CDrawer
      form={form}
      placement={"left"}
      title={t("select-device-in")}
      onClose={onClose}
      visible={visible}
      onSave={onSave}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          rules={[{ required: true }]}
          name="vehicle"
          label={t("vehicle-camera")}
        >
          <CSelect.EntranceDepartureCamera
            barrierType="IN"
            placeholder={t("vehicle-camera")}
            onSelectCallback={(vehicle) => {
              setItn({ ...itn, vehicle });
            }}
          />
        </Form.Item>
        <Form.Item
          name="truckFace"
          label={`${t("face-camera")} (${t("truck")})`}
        >
          <CSelect.EntranceDepartureCamera
            barrierType="IN"
            placeholder={`${t("face-camera")} (${t("truck")})`}
            onSelectCallback={(truckFace) => {
              setItn({ ...itn, truckFace });
            }}
          />
        </Form.Item>
        <Form.Item
          name="generalFace"
          label={`${t("face-camera")} (${t("general")})`}
        >
          <CSelect.EntranceDepartureCamera
            barrierType="IN"
            placeholder={`${t("face-camera")} (${t("general")})`}
            onSelectCallback={(generalFace) => {
              setItn({ ...itn, generalFace });
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default DisplayDeviceInHeader;
