import { throwResponse } from "config/axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import { IMenu } from "./interface";

export const useGetMenus = (): UseQueryResult<IMenu[]> => {
  return useQuery(["get-menus"], async () => {
    const res = await axios.get("/menu", { params: { pagination: false } });
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};
