import CButton from "components/button/c-button";
import CSpace from "components/display/c-space";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserMenuTypes } from "services/user/interface";

export interface IUserTypeSelectData {
  userType: UserMenuTypes;
  title: string;
}

export const USER_TYPE_DATA: IUserTypeSelectData[] = [
  {
    userType: "VISITOR",
    title: "user-management.guest",
  },
  {
    userType: "CONTRACTOR",
    title: "user-management.contractor",
  },
  {
    userType: "SUPPLIER",
    title: "user-management.supplier",
  },
  {
    userType: "RECEIVER",
    title: "user-management.consignee",
  },
  {
    userType: "EMPLOYEE",
    title: "vehicle-inspection.vehicle-of-company",
  },
];

interface CUserTypeSelectProps {
  value?: IUserTypeSelectData;
  onChange?: (v: IUserTypeSelectData) => void;
  accepts?: UserMenuTypes[];
  className?: string;
  direction?: "vertical" | "horizontal";
}

const CUserTypeSelect: FC<CUserTypeSelectProps> = ({
  value,
  onChange,
  accepts = ["VISITOR", "CONTRACTOR", "SUPPLIER", "RECEIVER", "EMPLOYEE"],
  direction,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <CSpace className={className} direction={direction}>
      {USER_TYPE_DATA.filter((item) =>
        accepts.some((e) => e === item.userType)
      ).map((item) => {
        if (item.userType === value?.userType) {
          return (
            <CButton key={item.userType}>
              {t(item.title, { ns: "menu" })}
            </CButton>
          );
        }
        return (
          <CButton
            onClick={() => onChange?.(item)}
            theme="white"
            key={item.userType}
          >
            {t(item.title, { ns: "menu" })}
          </CButton>
        );
      })}
    </CSpace>
  );
};

export default CUserTypeSelect;
