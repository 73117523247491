import { CheckCircleFilled } from "@ant-design/icons";
import { Col, Divider, Form, Row, Typography } from "antd";
import CInput from "components/input/c-input";
import CRadio from "components/input/c-radio";
import { useRealTimeFaceMonitoring } from "provider/realtime-face-monitoring";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = styled.div`
  ${tw`bg-white`};
  height: calc(100vh - 104px);
`;

const RealtimeFaceMonitoringSidebar = () => {
  const { t } = useTranslation();

  const { device, setDevice } = useRealTimeFaceMonitoring();

  return (
    <Wrapper>
      <div className="p-5">
        <Typography.Title level={4} className="!text-primary-dark">
          {t("application-video")}
        </Typography.Title>
      </div>
      <Divider className="!m-0" />
      <Form className="!p-5" layout="vertical">
        <Form.Item name="search" label={t("search")}>
          <CInput.Debounce search placeholder={t("search")} />
        </Form.Item>
        <div
          style={{ height: "calc(100vh - 315px)", overflow: "auto" }}
          className="pr-1"
        >
          <Row gutter={[0, 12]}>
            {[1, 2, 3, 4, 5].map((item) => {
              return (
                <Col span={24} key={item}>
                  <SelectDeviceCard
                    onClick={() => setDevice(item)}
                    isActive={device === item}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </Form>
    </Wrapper>
  );
};

const SelectDeviceCardWrapper = styled(Row)<{ $isActive: boolean }>`
  ${tw`py-4 px-2 rounded-app border cursor-pointer transition-all`};
  ${({ $isActive }) =>
    $isActive ? tw`border-primary-light bg-light` : tw`border-gray-200`};

  &:hover {
    ${tw`border-primary-light bg-light`};
  }
`;

const SelectDeviceCard: FC<{ isActive: boolean; onClick: () => void }> = ({
  isActive,
  onClick,
}) => {
  return (
    <Row align="middle" onClick={onClick}>
      <Col>
        <CRadio checked={isActive} />
      </Col>
      <Col className="grow">
        <SelectDeviceCardWrapper $isActive={isActive}>
          <Col>
            <Typography.Text>Camera 01</Typography.Text>
          </Col>
          <Col className="ml-auto center">
            <CheckCircleFilled style={{ color: "#2BB53B", fontSize: 12 }} />
            {/* <CloseCircleFilled style={{ color: "#DD211E", fontSize: 12 }} /> */}
          </Col>
        </SelectDeviceCardWrapper>
      </Col>
    </Row>
  );
};

export default RealtimeFaceMonitoringSidebar;
