import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IMenuLevel, IMenuLevelParams } from "./interface";

export const useGetMenuLevel = (id?: string): UseQueryResult<IMenuLevel> => {
  return useQuery(
    ["get-menu-level", id],
    async () => {
      const res = await axios.get(`/menu-level/${id}`);
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetMenuLevels = (
  params?: IMenuLevelParams
): UseQueryResult<IResponse<IMenuLevel[]>> => {
  return useQuery(
    ["get-menu-levels", params],
    async () => {
      const res = await axios.get("/menu-level");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
      enabled: params?.enabled,
    }
  );
};

export const usePostMenuLevel = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post("/menu-level", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchMenuLevel = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/menu-level/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteMenuLevel = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/menu-level/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
