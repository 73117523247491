import { Typography } from "antd";
import AppLoadingLoader from "components/layout/app-loading-loader";
import { createContext, FC, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { IAppLoadingContext } from "./interface";

const Context = createContext<IAppLoadingContext>({} as IAppLoadingContext);

const MaskScreen = styled.div<{ $appLoading: boolean }>`
  ${tw`absolute `};
  ${({ $appLoading }) =>
    $appLoading ? tw`visible opacity-100` : tw`invisible opacity-0`};
  width: 100vw;
  height: 100vh;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  transition: visibility 0s, opacity 0.1s linear;
`;

const AppLoadingProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const [appLoading, setAppLoading] = useState<boolean>(false);
  // const isLoading = useIsMutating()

  const toggleAppLoading = () => {
    setAppLoading(!appLoading);
  };

  return (
    <Context.Provider value={{ appLoading, setAppLoading, toggleAppLoading }}>
      <MaskScreen $appLoading={appLoading} className="center flex-col">
        <AppLoadingLoader />
        <Typography.Title level={5} className="!text-white">
          {t("in-progress", { ns: "message" })}
        </Typography.Title>
      </MaskScreen>
      {children}
    </Context.Provider>
  );
};

export const useAppLoading = () => useContext(Context);
export default AppLoadingProvider;
