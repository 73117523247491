import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteUserBlackList,
  useDeleteUsersBlackListBulk,
  useGetUsersBlackLists,
} from "services/user-blacklist";
import {
  IUserBlacklist,
  IUserBlacklistParams,
} from "services/user-blacklist/interface";

const UserManagementBlacklistPersonPage = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState<IUserBlacklistParams>({
    page: 1,
    limit: 10,
  });

  const get_user_blacklists = useGetUsersBlackLists(params);
  const del_user_blacklist = useDeleteUserBlackList();
  const del_user_blacklist_bulk = useDeleteUsersBlackListBulk();

  const elements: ISearchBoxElement[] = [
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "address",
      label: t("address"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: ColumnsType<IUserBlacklist> = [
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc?.backlistPresonImage?.url} />;
      },
    },
    {
      title: t("full-name"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis={{ tooltip: rc.phoneNumber || "-" }}>
            {rc.phoneNumber || "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: t("address"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis={{ tooltip: rc.address || "-" }}>
            {rc.address || "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: t("status"),
      align: "center",
      render: (_, rc) => {
        return (
          <>
            <div
              style={{
                backgroundColor: "#FFE6E6",
                border: "1px solid #DB1212",
              }}
              className="!p-1 shadow-sm rounded-app"
            >
              <Typography.Text ellipsis style={{ color: "#DB1212" }}>
                {t("blacklist-person")}
              </Typography.Text>
            </div>
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        query={get_user_blacklists}
        title={t("blacklist-person-list")}
        columns={columns}
        action={{
          bulkDelete: {
            mutation: del_user_blacklist_bulk,
            invalidateQueries: ["get-user-blacklists", "get-user-blacklist"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: del_user_blacklist,
          invalidateQueries: ["get-user-blacklists", "get-user-blacklist"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        onAdd="user-management.blacklist-person-add"
        onRowClick="user-management.blacklist-person-edit"
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default UserManagementBlacklistPersonPage;
