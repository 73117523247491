import { FC, ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import CIndicator from "./c-indicator";
import EmptyPage from "components/pages/empty-page";
import ErrorPage from "components/pages/error-page";
import { IResponse, IInfiniteScroll } from "config/axios/interface";

export interface CInfiniteScrollProps {
  height?: number | string;
  mutation?: UseInfiniteQueryResult<IResponse<any[]>, Error>;
  render: (v: any[]) => ReactNode;
  className?: string;
  textColor?: string;
  style?: React.CSSProperties;
  renderEmpty?: JSX.Element;
  hidden?: boolean;
  backgroundColor?: string;
}

const CInfiniteScroll: FC<CInfiniteScrollProps> = ({
  height,
  mutation,
  render,
  className,
  textColor,
  style,
  renderEmpty,
  hidden,
  backgroundColor = "",
}) => {
  const entireData = (): IInfiniteScroll<any> => {
    const { pages } = mutation?.data || {};
    if (!pages?.[0])
      return { data: [], hasMore: false, page: 1, undefinded: true };

    const idx = (pages?.length || 0) - 1;
    const page = pages?.[idx]?.page || 1;
    const pageCount = pages?.[idx]?.pageCount || 0;
    const data =
      pages
        ?.map((item) => {
          return item?.data;
        })
        .flat() || [];
    return { data, hasMore: pageCount > page, page: page };
  };

  const { page, hasMore, data, undefinded } = entireData();

  const fetchNext = () => {
    mutation?.fetchNextPage({ pageParam: { page: page + 1 } });
  };

  if (hidden) return null;

  if (mutation?.isLoading) {
    return (
      <CIndicator
        height={height}
        style={{ backgroundColor: backgroundColor }}
      />
    );
  }

  if (mutation?.isError) {
    return (
      <div style={{ height: height, backgroundColor: backgroundColor }}>
        <ErrorPage error={mutation?.error} />
      </div>
    );
  }

  if (data.length <= 0 && !undefinded) {
    if (renderEmpty) {
      return renderEmpty;
    }
    return (
      <div style={{ height: height, backgroundColor: backgroundColor }}>
        <EmptyPage />
      </div>
    );
  }

  return (
    <InfiniteScroll
      className={className}
      height={height}
      style={{ ...style, backgroundColor: backgroundColor }}
      next={fetchNext}
      hasMore={hasMore}
      dataLength={data.length}
      loader={<CIndicator height={50} />}
    >
      {render(data)}
    </InfiniteScroll>
  );
};

export default CInfiniteScroll;
