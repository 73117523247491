import { Radio, RadioGroupProps, Space } from "antd";
import { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";

export const StyledRadioGroup = styled(Radio.Group)<{ height?: string }>`
  ${tw`rounded-app`};
  .ant-radio-button-wrapper {
    height: ${({ height }) => height};
  }
  .ant-radio-button-wrapper {
    display: inline-flex;
    align-items: center;
  }
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    svg * {
      fill: black;
    }
  }
  .ant-radio-button-wrapper {
    span: not(.ant-radio-button) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  height: ${({ height }) => height};
  ${tw`flex items-center`};
  .ant-radio-wrapper {
    .ant-radio {
      margin-right: 5px;
    }
  }
  //style size
  .ant-radio-inner {
    width: 18px;
    height: 18px;
  }
  .ant-radio-inner::after {
    margin-top: -13px;
    margin-left: -13px;
    width: 26px;
    height: 26px;
  }
`;

export interface CRadioGroupProps extends RadioGroupProps {
  height?: string;
  vertical?: boolean;
  spaceSize?: number;
}

const CRadioGroup: FC<CRadioGroupProps> = ({
  optionType = "button",
  buttonStyle = "solid",
  height = "45px",
  vertical = false,
  spaceSize = 0,
  ...props
}) => {
  if (vertical) {
    const { options, ...rest } = props;
    return (
      <StyledRadioGroup
        {...rest}
        optionType={optionType}
        buttonStyle={buttonStyle}
        height={height}
      >
        <Space direction="vertical" size={spaceSize}>
          {options?.map((item, index) => {
            if (typeof item === "number" || typeof item === "string")
              return null;
            return (
              <Radio disabled={item.disabled} key={index} value={item.value}>
                {item.label}
              </Radio>
            );
          })}
        </Space>
      </StyledRadioGroup>
    );
  }

  return (
    <StyledRadioGroup
      {...props}
      height={height}
      optionType={optionType}
      buttonStyle={buttonStyle}
    />
  );
};

export default CRadioGroup;
