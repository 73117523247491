import { EditFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { TableColumnsType } from "antd";
import { BinIcon } from "assets/icons/general";
import CButtonIcon from "components/button/c-button-icon";
import CSpace from "components/display/c-space";
import CTable from "components/display/c-table";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeletePin, useGetPins } from "services/pin";
import { IPin, IPinParams } from "services/pin/interface";

interface FloorEditTablePinProps {
  onEdit: (v?: IPin) => void;
}

const FloorEditTablePin: FC<FloorEditTablePinProps> = ({ onEdit }) => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IPinParams>({ page: 1, limit: 5 });

  const { setAppLoading } = useAppLoading();

  const get_pins = useGetPins(params);
  const del_pin = useDeletePin();
  const qClient = useQueryClient();

  const columns: TableColumnsType<IPin> = [
    {
      title: t("parking-lot-type"),
      dataIndex: "pinName",
    },
    {
      title: t("color"),
      width: 20,
      render: (_, rc) => {
        return (
          <div
            style={{ backgroundColor: rc.colorType, width: 15, height: 15 }}
          />
        );
      },
    },
    {
      title: t("table-action"),
      width: 40,
      render: (_, rc) => {
        return (
          <CSpace className="mt-1">
            <CButtonIcon
              theme="white"
              style={{ width: 20, height: 20 }}
              icon={<EditFilled style={{ fontSize: 12 }} />}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(rc);
              }}
            />
            <CButtonIcon
              theme="white"
              style={{ width: 20, height: 20 }}
              icon={<BinIcon style={{ fontSize: 12, width: 12, height: 12 }} />}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(rc);
              }}
            />
          </CSpace>
        );
      },
    },
  ];

  const onDelete = async ({ id }: IPin) => {
    const confirm = await fireConfirmDelete();
    if (!confirm) return;
    setAppLoading(true);
    del_pin.mutate(
      { id },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "vehicle-management" });
          qClient.invalidateQueries(["get-pins"]);
          qClient.invalidateQueries(["get-pin"]);
          if ((get_pins.data?.data.length || 0) <= 1) {
            setParams({ ...params, page: 1 });
          }
        },
        onError: ({ message }) => {
          fireNotification({
            type: "error",
            message,
            menu: "vehicle-management",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  return (
    <CTable
      selectAble={false}
      columns={columns}
      wrapper={false}
      query={get_pins}
      pagination={{
        onChange: (page, limit) => {
          setParams({ ...params, page, limit });
        },
        showSizeChanger: false,
        size: 'small'
      }}
    />
  );
};

export default FloorEditTablePin;
