import { FC } from "react";
import ActiveInActiveCard, {
  ActiveInActiveCardProps,
} from "./active-in-active-card";
import BasicCard, { BasicCardProps } from "./basic-card";
import EmptySelectVideoCard, {
  EmptySelectVideoCardProps,
} from "./empty-select-video";
import OnlineOfflineCard, {
  OnlineOfflineCardProps,
} from "./online-offline-card";
import ParkingTypeCard, { ParkingTypeCardProps } from "./parking-type";
import PercentCard, { PercentCardProps } from "./percent-card";
import SlashHeaderCard, { SlashHeaderCardProps } from "./slash-header";
import LiquidSprayCard, { LiquidSprayCardProps } from "./spray-card";
import CCardWithBorder, { CCardWithBorderProps } from "./with-border";

interface CCardProps {}
type NotationTypes = {
  SlashHeader: FC<SlashHeaderCardProps>;
  OnLineOffLineCard: FC<OnlineOfflineCardProps>;
  EmptySelectVideo: FC<EmptySelectVideoCardProps>;
  LiquidSprayCard: FC<LiquidSprayCardProps>;
  BasicCard: FC<BasicCardProps>;
  Percent: FC<PercentCardProps>;
  ParkingType: FC<ParkingTypeCardProps>;
  WithBorder: FC<CCardWithBorderProps>;
  ActiveInActive: FC<ActiveInActiveCardProps>;
};

const CCard: FC<CCardProps> & NotationTypes = () => {
  return <div></div>;
};

CCard.SlashHeader = SlashHeaderCard;
CCard.OnLineOffLineCard = OnlineOfflineCard;
CCard.EmptySelectVideo = EmptySelectVideoCard;
CCard.LiquidSprayCard = LiquidSprayCard;
CCard.BasicCard = BasicCard;
CCard.Percent = PercentCard;
CCard.ParkingType = ParkingTypeCard;
CCard.WithBorder = CCardWithBorder;
CCard.ActiveInActive = ActiveInActiveCard;

export default CCard;
