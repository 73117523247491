import { TableColumnsType, Typography } from "antd";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteVehicleBlackList,
  useDeleteVehicleBlackLists,
  useExportExcelVehicleBlackList,
  useGetVehicleBlackLists,
} from "services/vehicle-blacklist";
import {
  IVehicleBlackList,
  IVehicleBlackListParams,
} from "services/vehicle-blacklist/interface";

const VehicleBannedVehicle = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IVehicleBlackListParams>({
    page: 1,
    limit: 10,
  });

  const { formatDate } = useAppLanguage();

  const query = useGetVehicleBlackLists(params);
  const del = useDeleteVehicleBlackList();
  const bulk_del = useDeleteVehicleBlackLists();
  const export_excel = useExportExcelVehicleBlackList();

  const elements: ISearchBoxElement[] = [
    {
      name: "licensePlate",
      label: t("license-plate-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "vehicleBrand",
      label: t("vehicle-brand"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "date",
      label: t("created-at"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
  ];

  const columns: TableColumnsType<IVehicleBlackList> = [
    {
      title: t("license-plate-number"),
      dataIndex: "licensePlate",
    },
    {
      title: t("last-entry-date"),
      render: (_, rc) => {
        return "-";
      },
    },
    {
      title: t("banned-type"),
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis={{ tooltip: rc.detail }}>
            {rc.detail || "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: t("created-at"),
      render: (_, rc) => {
        const date = formatDate({ date: rc.createdAt });
        return <Typography.Text>{date}</Typography.Text>;
      },
    },
  ];

  const onSearch = (values: any) => {
    values = { ...values, ...values.date };
    delete values.date;
    setParams({
      ...params,
      startDate: undefined,
      endDate: undefined,
      ...values,
      page: 1,
    });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        query={query}
        columns={columns}
        onAdd="vehicle-management.banned-vehicle-add"
        onRowClick="vehicle-management.banned-vehicle-edit"
        title={t("banned-vehicle-list")}
        action={{
          excels: { export: { mutation: export_excel } },
          bulkDelete: {
            mutation: bulk_del,
            invalidateQueries: ["vehicle-black-list"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: del,
          invalidateQueries: ["vehicle-black-list"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default VehicleBannedVehicle;
