import { Col, Row, Typography } from "antd";
import { MenuSchedule } from "assets/icons/general";
import { useAppLanguage } from "provider/app-language";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

const CardHomeData = ({
  title,
  children,
  startDate,
  endDate,
}: {
  title: string;
  children?: ReactNode;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  return (
    <Row className="p-5">
      <Col span={24}>
        <Typography.Text
          ellipsis
          className="font-bold"
          style={{ fontSize: "20px" }}
        >
          {t(title) || ""}
        </Typography.Text>
      </Col>
      <Col span={2} className="center">
        <MenuSchedule className="w-[22px] h-[22px]" />
      </Col>
      <Col span={22} className="px-2">
        <span style={{ color: "#9A9A9A", fontSize: "18px" }}>
          {`${formatDate({ date: startDate, format: "normal" })} - ${formatDate(
            { date: endDate, format: "normal" }
          )}`}
        </span>
      </Col>
      <Col span={24} className="!p-0">
        {children}
      </Col>
    </Row>
  );
};

export default CardHomeData;
