import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { postMediaObject } from "services/media-object";
import { IUserBlacklist, IUserBlacklistParams } from "./interface";

export const useGetUsersBlackLists = (
  params?: IUserBlacklistParams
): UseQueryResult<IResponse<IUserBlacklist[]>> => {
  return useQuery(
    ["get-user-blacklists", params],
    async () => {
      const res = await axios.get("/blacklist-preson", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetUsersBlackList = (
  id?: number
): UseQueryResult<IUserBlacklist> => {
  return useQuery(
    ["get-user-blacklist", id],
    async () => {
      const res = await axios.get(`/blacklist-preson/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostUserBlackList = (): UseMutationResult => {
  return useMutation(async (data) => {
    data = await appendBlackListImages(data);
    const res = await axios.post("/blacklist-preson", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchUserBlackList = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    data = await appendBlackListImages(data);
    const res = await axios.patch(`/blacklist-preson/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteUserBlackList = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/blacklist-preson/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteUsersBlackListBulk = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.delete("/blacklist-preson/bulk", { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

const appendBlackListImages = async (values: any): Promise<any> => {
  let { backlistPresonImage, ...rest } = values || {};

  if (backlistPresonImage && typeof backlistPresonImage === "object") {
    backlistPresonImage = await postMediaObject(backlistPresonImage);
    rest = { ...rest, backlistPresonImage };
  }

  return rest;
};
