import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "provider/auth";
import { createContext, useContext, useEffect, useState } from "react";
import {
  useGetNotification,
  useGetNotificationScroll,
} from "services/notification-header";

import {
  IDataQueryScroll,
  IMenuDataNoti,
  INotiContext,
  INotRead,
  MenuNot,
  TMenu,
} from "./interface";

const NotificationContext = createContext<INotiContext>({} as INotiContext);

const params = { page: 1, limit: 10 };

export const menuDataNoti: IMenuDataNoti[] = [
  // { id: 1, keyNoti: "FAILED_FACE_SCAN", lable: "failed-face-scan" },
  { id: 2, keyNoti: "SCAN_THE_FACE_THROUGH", lable: "pass-face-scan" },
  {
    id: 3,
    keyNoti: "TEMPERATURE_DOES_NOT_PASS",
    lable: "temperature-does-not-pass",
  },
  { id: 4, keyNoti: "NO_MASK", lable: "not-wearing-a-mask" },
  {
    id: 5,
    keyNoti: "LICENSE_PLATE_DOES_NOT_PASS",
    lable: "license-plate-does-not-pass",
  },
  {
    id: 6,
    keyNoti: "FAILED_THE_3RD_OF_VEHICLE_CONDITION",
    lable: "vehicle-inspection-not-pass-3rd-noti-vip",
  },
  { id: 7, keyNoti: "VIP_REJECT_CAR", lable: "vip-reject-car-case" },
];

const NotificationProvider = ({ children }: { children: JSX.Element }) => {
  const qClient = useQueryClient();
  const { userMe } = useAuth();
  const [selectMenu, setSelectMenu] = useState<TMenu>();
  const [openNoti, setOpenNoti] = useState<boolean>(false);
  const [notRead, setNotRead] = useState<INotRead[]>();
  const { role } = userMe || {};

  // getscroll
  const queryFailedFaceScan = useGetNotificationScroll({
    ...params,
    type: "FAILED_FACE_SCAN",
    wait: selectMenu === "FAILED_FACE_SCAN",
  });
  const queryPassFaceScan = useGetNotificationScroll({
    ...params,
    type: "SCAN_THE_FACE_THROUGH",
    wait: selectMenu === "SCAN_THE_FACE_THROUGH",
  });
  const queryTemperatureNotPass = useGetNotificationScroll({
    ...params,
    type: "TEMPERATURE_DOES_NOT_PASS",
    wait: selectMenu === "TEMPERATURE_DOES_NOT_PASS",
  });
  const queryNoMask = useGetNotificationScroll({
    ...params,
    type: "NO_MASK",
    wait: selectMenu === "NO_MASK",
  });
  const queryLicensePlateNotPass = useGetNotificationScroll({
    ...params,
    type: "LICENSE_PLATE_DOES_NOT_PASS",
    wait: selectMenu === "LICENSE_PLATE_DOES_NOT_PASS",
  });
  const queryFailed3rdVehicle = useGetNotificationScroll({
    ...params,
    type: "FAILED_THE_3RD_OF_VEHICLE_CONDITION",
    wait: selectMenu === "FAILED_THE_3RD_OF_VEHICLE_CONDITION",
  });
  const queryVIPRejectCar = useGetNotificationScroll({
    ...params,
    type: "VIP_REJECT_CAR",
    wait: selectMenu === "VIP_REJECT_CAR",
  });

  //get read
  const dataFailedFaceScan = useGetNotification({
    ...params,
    type: "FAILED_FACE_SCAN",
  });
  const dataPassFaceScan = useGetNotification({
    ...params,
    type: "SCAN_THE_FACE_THROUGH",
  });
  const dataTemperatureNotPass = useGetNotification({
    ...params,
    type: "TEMPERATURE_DOES_NOT_PASS",
  });
  const dataNoMask = useGetNotification({
    ...params,
    type: "NO_MASK",
  });
  const dataLicensePlateNotPass = useGetNotification({
    ...params,
    type: "LICENSE_PLATE_DOES_NOT_PASS",
  });
  const dataFailed3rdVehicle = useGetNotification({
    ...params,
    type: "FAILED_THE_3RD_OF_VEHICLE_CONDITION",
  });
  const dataVIPRejectCar = useGetNotification({
    ...params,
    type: "VIP_REJECT_CAR",
  });

  const loading =
    dataFailedFaceScan.isFetching ||
    dataPassFaceScan.isFetching ||
    dataTemperatureNotPass.isFetching ||
    dataNoMask.isFetching ||
    dataLicensePlateNotPass.isFetching ||
    dataFailed3rdVehicle.isFetching ||
    dataVIPRejectCar.isFetching;

  const dataNotRead: INotRead[] = [
    // {
    //   key: "FAILED_FACE_SCAN",
    //   notRead: Number(
    //     dataFailedFaceScan.data?.unread?.totalFailedFaceScan || 0
    //   ),
    // },
    {
      key: "SCAN_THE_FACE_THROUGH",
      notRead: Number(
        dataPassFaceScan.data?.unread?.totalScanTheFaceThrough || 0
      ),
    },
    {
      key: "TEMPERATURE_DOES_NOT_PASS",
      notRead: Number(
        dataTemperatureNotPass.data?.unread?.totalTemperatureDoesNotPass || 0
      ),
    },
    {
      key: "NO_MASK",
      notRead: Number(dataNoMask.data?.unread?.totalNoMask || 0),
    },
    {
      key: "LICENSE_PLATE_DOES_NOT_PASS",
      notRead: Number(
        dataLicensePlateNotPass.data?.unread?.totalFailedLicensePlate || 0
      ),
    },
    {
      key: "FAILED_THE_3RD_OF_VEHICLE_CONDITION",
      notRead: Number(
        dataFailed3rdVehicle.data?.unread?.totalFailed3rdTime || 0
      ),
    },
    {
      key: "VIP_REJECT_CAR",
      notRead: Number(dataVIPRejectCar.data?.unread?.totalVipRejectCar || 0),
    },
  ];

  useEffect(() => {
    fetchDataScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectMenu]);

  useEffect(() => {
    setNotRead(dataNotRead);
    if (openNoti) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNoti]);

  useEffect(() => {
    setSelectMenu(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!openNoti]);

  useEffect(() => {
    notiNotRead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    qClient.invalidateQueries(["get-notifications"]);
  };

  const fetchDataScroll = () => {
    qClient.invalidateQueries(["get-notifications-scroll"]);
  };

  const queryData: IDataQueryScroll = {
    queryFailedFaceScan,
    queryPassFaceScan,
    queryTemperatureNotPass,
    queryNoMask,
    queryLicensePlateNotPass,
    queryFailed3rdVehicle,
    queryVIPRejectCar,
  };

  const mapData = (): MenuNot[] => {
    return menuDataNoti.map((item) => {
      const data = notRead?.find((e) => e.key === item.keyNoti);
      const isActive = (role?.notification || []).some(
        (e) => e === item.keyNoti
      );
      return {
        ...item,
        notRead: data?.notRead || 0,
        isActive: isActive || false,
      };
    });
  };

  const notiNotRead = () => {
    const findData = mapData();
    const dataFind = dataNotRead.filter((item) => {
      return findData.find((e) => e.keyNoti === item.key && e.isActive);
    });
    return dataFind?.reduce((prev, current) => prev + current.notRead, 0) || 0;
  };

  return (
    <NotificationContext.Provider
      value={{
        selectMenu,
        setSelectMenu,
        queryData,
        setOpenNoti,
        openNoti,
        notRead,
        mapData,
        getLoadingNotRead: loading,
        notiNotRead,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
export const useNotificationHeader = () => useContext(NotificationContext);
export default NotificationProvider;
