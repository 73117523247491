import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";
import { useGetBusinessPartner } from "services/master";

const CSelectErpBusinessPartner: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useGetBusinessPartner}
      valueKey="id"
      titleKey="bpCode"
      searchKey="bpCode"
      placeholder={t("bp-code")}
      getOriginOption
      queryParams={{
        limit: 100,
      }}
    />
  );
};
export default CSelectErpBusinessPartner;
