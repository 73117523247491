import { Col, Divider, Form, Row, Typography } from "antd";
import CDatePicker from "components/input/c-date-picker";
import CRangePicker from "components/input/c-range-picker";
import { fireNotification } from "components/popup/notification";
import dayjs from "dayjs";
import { VideoNoData } from "assets/icons/general";
import { useCameraGroup } from "provider/all-video";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequestVideoHistoryUrl } from "services/stream-video";
import styled from "styled-components";
import { RangeValue } from "rc-picker/lib/interface";
import Icon from "@ant-design/icons";
import CInput from "components/input/c-input";
import CIndicator from "components/display/c-indicator";

interface IPeriod {
  start: string;
  end: string;
  size: number;
  key: string;
}

const PeriodCard = styled.div<{ $active: boolean }>`
  color: ${({ $active }) => ($active ? "#438860" : "gray")};
  border: solid 1px ${({ $active }) => ($active ? "#438860" : "#E4E4E4")};
  border-radius: 5px;
  padding: 6px;
  display: flex;
  justify-content: center;
`;

const Playback = () => {
  const { t } = useTranslation();
  const [periods, setPeriods] = useState<IPeriod[]>();
  const { selected, setSelected } = useCameraGroup();

  const requestVideoHistory = useRequestVideoHistoryUrl();

  useEffect(() => {
    const period = selected?.periodList
      ?.map((e, i) => {
        const start = dayjs(e.beginTime).format("HH:mm");
        const end = dayjs(e.endTime).format("HH:mm");
        return { ...e, start, end };
      })
      .sort((a, b) => {
        const min = Number(a.end.split(":").join("."));
        const max = Number(b.end.split(":").join("."));
        return min - max;
      });
    setPeriods(period);
  }, [selected]);

  const onDateChange = (value?: dayjs.Dayjs | null) => {
    setSelected({ ...selected, date: value });

    if (!value) return;

    const start = value.clone().startOf("d");
    const end = value.clone().endOf("d");

    requestVideoHistory.mutate(
      {
        cameraIndexCode: selected?.encodeDeviceId,
        startDate: start,
        endDate: end,
      },
      {
        onSuccess: (data) => {
          setSelected({
            ...selected,
            date: value,
            playerUrl: data.url,
            periodList: data.list.map((item, index) => {
              return { ...item, key: `k_${index}` };
            }),
          });
        },
        onError: ({ message }) => {
          setSelected({
            ...selected,
            date: value,
            playerUrl: undefined,
            periodList: [],
          });
          fireNotification({
            type: "error",
            message: message,
            menu: "resource-management",
          });
        },
      }
    );
  };

  const onChangeRangeTime = (values: RangeValue<dayjs.Dayjs>) => {
    const format = dayjs(selected?.date).format("YYYY-MM-DD");
    const beginTime = format + "T" + dayjs(values?.[0]).format("HH:mm:ssZ");
    const endTime = format + "T" + dayjs(values?.[1]).format("HH:mm:ssZ");
    if (!beginTime || !endTime) return;
    setSelected({ ...selected, selectPeriod: { beginTime, endTime } });
  };

  const isLoading = requestVideoHistory.isLoading;

  const hiddenNodata = () => {
    if (isLoading) return true;
    if (!selected?.date) {
      if (!!selected?.periodList?.length) {
        return false;
      }
      return true;
    }
    if (selected?.date) {
      if (!selected?.periodList?.length) {
        return false;
      }
      return true;
    }
    return true;
  };

  return (
    <div className="h-full bg-white">
      <div className="p-5">
        <Typography.Title
          ellipsis={{ tooltip: t("all-video-app") }}
          level={5}
          className="!text-primary"
        >
          {t("all-video-app")}
        </Typography.Title>
      </div>
      <Divider className="!m-0" />
      <Form className="!p-5">
        <Form.Item>
          {/* <CSelectPagination
            disabled={isLoading}
            placeholder={t("select-device")}
            useQuery={useGetEncodeDevicesScroll}
            titleKey="devName"
            valueKey="id"
            searchKey="devName"
            value={selected?.encodeDeviceId}
            onChange={(e) => {
              setSelected({ encodeDeviceId: e });
            }}
          /> */}
          <CInput.Number
            placeholder={t("camera-index")}
            value={selected?.encodeDeviceId}
            onChange={(e) => {
              if (!e) return setSelected(undefined);
              return setSelected({ encodeDeviceId: Number(e) });
            }}
          />
        </Form.Item>
        <Form.Item>
          <CDatePicker
            disabled={isLoading || !selected?.encodeDeviceId}
            value={selected?.date}
            onChange={onDateChange}
          />
        </Form.Item>
        <Form.Item
          hidden={
            isLoading ||
            (!!selected?.periodList?.length && !selected.date) ||
            !selected?.periodList?.length
          }
        >
          <CRangePicker
            picker="time"
            format="HH:mm"
            placeholder={[t("start-time"), t("end-time")]}
            onChange={onChangeRangeTime}
          />
        </Form.Item>
        <Row
          style={{ height: "calc(100vh - 427px)" }}
          hidden={isLoading || (!!selected?.date && !!selected?.encodeDeviceId)}
          className="w-full flex !flex-col justify-center items-center"
        >
          <Icon component={VideoNoData} style={{ fontSize: 60 }} />
          <Typography.Text
            className="text-gray-500"
            ellipsis={{ tooltip: t("please-select-camera-and-date") }}
          >
            {t("please-select-camera-and-date")}
          </Typography.Text>
        </Row>
        <Row
          hidden={isLoading || hiddenNodata()}
          style={{ height: "calc(100vh - 427px)" }}
          className="w-full flex !flex-col justify-center items-center"
        >
          <Icon component={VideoNoData} style={{ fontSize: 60 }} />
          <Typography.Text
            className="text-gray-500"
            ellipsis={{ tooltip: t("no-data") }}
          >
            {t("no-data")}
          </Typography.Text>
        </Row>
        <Row
          hidden={!isLoading}
          style={{ height: "calc(100vh - 427px)" }}
          className="w-full flex justify-center items-center"
        >
          <CIndicator />
        </Row>
        <Row
          gutter={[6, 6]}
          hidden={isLoading || !selected?.date || !periods?.length || !periods}
        >
          {periods?.map((period) => (
            <Col
              span={12}
              key={period.key}
              className="cursor-pointer"
              onClick={() => {
                const find = selected?.periodList?.find(
                  (e) => e.key === period.key
                );
                if (!find) return;
                setSelected({ ...selected, selectPeriod: find });
              }}
            >
              <PeriodCard $active={selected?.selectPeriod?.key === period.key}>
                <span>{period.start}</span>
                <span> - </span>
                <span>{period.end}</span>
              </PeriodCard>
            </Col>
          ))}
        </Row>
      </Form>
    </div>
  );
};

export default Playback;
