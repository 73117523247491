import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  IVehicleOvernight,
  IVehicleOvernightParams,
} from "services/vehicle-overnight/interface";
import {
  useExportVehicleOvernight,
  useGetVehicleOvernight,
} from "services/vehicle-overnight";
import { useAppLanguage } from "provider/app-language";
import { Typography } from "antd";
import { typeOfUser } from "config/axios/interface";

const VehicleOvernightReport = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const [params, setParams] = useState<IVehicleOvernightParams>({
    page: 1,
    limit: 10,
  });
  const { data, isFetching } = useGetVehicleOvernight(params);
  const exportExcel = useExportVehicleOvernight();

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("date"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
    {
      name: "fullname",
      label: t("first-name") + "-" + t("last-name"),
      input: {
        type: "Input",
        options: {
          search: true,
          placeholder: t("first-name") + "-" + t("last-name"),
        },
      },
    },
    {
      name: "licensePlate",
      label: t("license-plate"),
      input: {
        type: "Input",
        options: {
          search: true,
          placeholder: t("license-plate"),
        },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: {
          search: true,
          placeholder: t("phone-number"),
        },
      },
    },
  ];

  const columns: ColumnsType<IVehicleOvernight> = [
    {
      title: t("date"),
      dataIndex: "createdAt",
      render: (_, rc) => {
        if (!rc?.createdAt) return <span>-</span>;
        const time = formatDate({
          format: "nomal-full-time",
          date: rc.createdAt,
        });
        return (
          <Typography.Text ellipsis={{ tooltip: time }}>{time}</Typography.Text>
        );
      },
    },
    {
      title: t("first-name") + "-" + t("last-name"),
      dataIndex: "name",
      render(___, rc) {
        if (rc.customerEmployee && !rc.employee) {
          const { firstName, lastName } = rc?.customerEmployee || {};
          if (!firstName && !lastName) return <span>-</span>;
          const name = `${firstName} ${lastName}`;
          return (
            <Typography.Text ellipsis={{ tooltip: name }}>
              {name}
            </Typography.Text>
          );
        }

        if (rc.employee && !rc.customerEmployee) {
          const { firstName, lastName } = rc?.employee || {};
          if (!firstName && !lastName) return <span>-</span>;
          const name = `${firstName} ${lastName}`;
          return (
            <Typography.Text ellipsis={{ tooltip: name }}>
              {name}
            </Typography.Text>
          );
        }
        return "-";
      },
    },
    {
      title: t("license-plate"),
      dataIndex: "licensePlate",
      render(___, rc) {
        return <span>{rc?.vehicle?.licensePlate || "-"}</span>;
      },
    },
    {
      title: t("phone-number"),
      dataIndex: "phoneNumber",
      render(___, rc) {
        if (rc.customerEmployee && !rc.employee) {
          const { phoneNumber } = rc?.customerEmployee;
          if (!phoneNumber) return <span>-</span>;
          return (
            <Typography.Text ellipsis={{ tooltip: phoneNumber }}>
              {phoneNumber}
            </Typography.Text>
          );
        }

        if (rc.employee && !rc.customerEmployee) {
          const { phoneNumber } = rc?.employee;
          if (!phoneNumber) return <span>-</span>;
          return (
            <Typography.Text ellipsis={{ tooltip: phoneNumber }}>
              {phoneNumber}
            </Typography.Text>
          );
        }
        return "-";
      },
    },
    {
      title: t("parking-lot"),
      dataIndex: "parking",
      render(___, rc) {
        if (!rc?.parking || !rc?.parking?.parkingName) return "-";
        const parking = rc?.parking?.parkingName;
        return (
          <Typography.Text ellipsis={{ tooltip: parking }}>
            {parking}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      dataIndex: "userType",
      render: (_, rc) => {
        if (!rc.userType) return <span>-</span>;
        const text = t(typeOfUser[rc.userType]);
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    delete values.date;

    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        scroll={{ x: 1200 }}
        selectAble={false}
        title={t("vehicle-management.overnight-report", { ns: "menu" })}
        columns={columns}
        dataSource={data?.data || []}
        loading={isFetching}
        action={{
          excels: {
            export: { fileName: "overnight-report", mutation: exportExcel },
          },
        }}
        pagination={{
          total: data?.count,
          current: params?.page,
          pageSize: params.limit,
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default VehicleOvernightReport;
