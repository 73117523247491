import { TableColumnsType } from "antd";
import CTable from "components/display/c-table";
import { useVehicleFloorEdit } from "provider/vehicle-floor-edit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteDeviceFromFloor, useGetDeviceInFloor } from "services/floor";
import { IFloorDeviceParams } from "services/floor/interface";
import FloorEditDeviceDrawer from "./device-drawer";

const invalidateQueries = [
  "get-floors",
  "get-floor",
  "get-encode-devices",
  "get-encode-device",
];

const FloorEditStep1 = () => {
  const { t } = useTranslation();
  const { floor } = useVehicleFloorEdit();
  const [visible, setVisible] = useState<boolean>(false);
  const [params, setParams] = useState<IFloorDeviceParams>({
    page: 1,
    limit: 10,
    id: floor.id,
  });

  const query = useGetDeviceInFloor(params);
  const bulk_del = useDeleteDeviceFromFloor();

  const columns: TableColumnsType = [
    {
      title: t("equipment-name"),
      dataIndex: "devName",
    },
    {
      title: t("internet-protocol-address"),
      dataIndex: "devIp",
    },
    {
      title: t("model"),
      dataIndex: "treatyType",
    },
    {
      title: t("port"),
      dataIndex: "devPort",
    },
  ];

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <div style={{ height: 500, overflowY: "auto" }}>
      <FloorEditDeviceDrawer visible={visible} onClose={toggle} />
      <CTable
        query={query}
        shadow={false}
        wrapperClassName="!mt-0"
        title={t("related-equipment")}
        onAdd={{ onClick: toggle }}
        action={{
          bulkDelete: {
            key: "deviceIds",
            mutation: bulk_del,
            additionParams: { id: floor.id },
            invalidateQueries: invalidateQueries,
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        // onDelete={onDelete}
        columns={columns}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </div>
  );
};

export default FloorEditStep1;
