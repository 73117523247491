import { IUser } from "./../user/interface";
import { throwResponse } from "./../../config/axios/index";
import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { ILogin } from "./interface";

export const useGetMe = (isPublic: boolean): UseQueryResult<IUser> => {
  return useQuery(
    ["get-me", isPublic],
    async () => {
      const res = await axios.get("/user/me");
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !isPublic }
  );
};

export const useMutationGetMe = (): UseMutationResult<IUser, Error> => {
  return useMutation(async () => {
    const res = await axios.get("/user/me");
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const useLogin = (): UseMutationResult<ILogin> => {
  return useMutation(async (data) => {
    const res = await axios.post("/auth/login", data);
    // const dgf = await digitalInstance.post('/auth/login', data)
    if (res.status === 200) {
      // return {...res.data, digital: dgf.data};
      return {...res.data};
    }
    throwResponse(res);
  });
};

export const Logout = () => {
  removeToken();
  window.location.reload();
};

export const removeToken = () => {
  localStorage.removeItem("tkc-access-token");
};

export const setToken = (token: string) => {
  localStorage.setItem("tkc-access-token", token);
};

export const getToken = (): string | null => {
  return localStorage.getItem("tkc-access-token");
};
