import { SearchOutlined } from "@ant-design/icons";
import { Input, InputNumberProps, InputProps } from "antd";
import { PasswordProps, TextAreaProps } from "antd/lib/input";
import { FC } from "react";
import styled from "styled-components";
import CInputColor, { CInputColorProps } from "./color";
import CInputDebounce, { CInputDebounceProps } from "./debounce";
import CNumber from "./number";
import CPassword from "./password";
import CTextArea from "./text-area";

export const StyledInput = styled(Input)`
  height: 45px;
  .ant-input-group-wrapper,
  .ant-input-wrapper,
  .ant-input-group {
    height: 45px;
    input {
      height: 45px;
    }
  }
`;

export interface CInputProps extends InputProps {
  search?: boolean;
}

type NotationTypes = {
  TextArea: FC<TextAreaProps>;
  Password: FC<PasswordProps>;
  Number: FC<InputNumberProps>;
  Debounce: FC<CInputDebounceProps>;
  Color: FC<CInputColorProps>;
};

const CInput: FC<CInputProps> & NotationTypes = ({ search, ...props }) => {
  return (
    <StyledInput
      suffix={
        search ? (
          <SearchOutlined style={{ fontSize: 20, color: "#F0F0F0" }} />
        ) : undefined
      }
      {...props}
    />
  );
};

CInput.TextArea = CTextArea;
CInput.Password = CPassword;
CInput.Number = CNumber;
CInput.Debounce = CInputDebounce;
CInput.Color = CInputColor;

export default CInput;
