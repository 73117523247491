import { Segmented } from "antd";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { SegmentedRawOption } from "rc-segmented";
import { FC } from "react";
import styled from "styled-components";

const StyledSegmented = styled(Segmented)`
  .ant-segmented-item:not(.ant-segmented-item-selected) {
    .ant-segmented-item-label {
      color: white !important;
    }
  }
`;

interface CSegmentedProps {
  options: (SegmentedRawOption | SegmentedLabeledOption)[];
  onChange?: (v: any) => void;
  value?: any;
  block?: boolean;
}

const CSegmented: FC<CSegmentedProps> = (props) => {
  return <StyledSegmented {...props} />;
};

export default CSegmented;
