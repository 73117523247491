import { useQueryClient } from "@tanstack/react-query";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import DeviceStatusCard from "components/display/card/device-status-card";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteEncodeDevice,
  useDeleteEncodeDeviceBulk,
  useExportEncodeDevice,
  useGetEncodeDevices,
  useGetEncodeDevicesFromHcp,
} from "services/encode-device";
import {
  IEncodeDevice,
  IEncodeDeviceParams,
} from "services/encode-device/interface";

const ResourceEncodeDevicePage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IEncodeDeviceParams>({
    page: 1,
    limit: 10,
  });

  const qClient = useQueryClient();

  const data_encode = useGetEncodeDevices(params);
  const delete_encode_device = useDeleteEncodeDevice();
  const bulk_delete_encode_device = useDeleteEncodeDeviceBulk();
  const export_encode_device = useExportEncodeDevice();
  const get_encode_device_from_hcp = useGetEncodeDevicesFromHcp();

  const elements: ISearchBoxElement[] = [
    {
      name: "devName",
      label: t("equipment-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "devIp",
      label: t("internet-protocol-address"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "devPort",
      label: t("port"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "devCode",
      label: t("serial-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "macAddress",
      label: t("mac-address"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "status",
      label: t("network-status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              {
                key: 0,
                label: t("unknow"),
              },
              {
                key: 1,
                label: t("online-status"),
              },
              {
                key: 2,
                label: t("offline-status"),
              },
            ],
            valueKey: "key",
            titleKey: "label",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<IEncodeDevice> = [
    {
      title: t("equipment-name"),
      dataIndex: "devName",
      render: (_, rc) => {
        const devName = rc?.devName || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: devName }}>
            {devName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("internet-protocol-address"),
      dataIndex: "devIp",
      render: (_, rc) => {
        return rc?.devIp || "-";
      },
    },
    {
      title: t("port"),
      dataIndex: "devPort",
      render: (_, rc) => {
        return rc?.devPort || "-";
      },
    },
    {
      title: t("serial-number"),
      dataIndex: "devCode",
      render: (_, rc) => {
        const devCode = rc?.devCode || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: devCode }}>
            {devCode}
          </Typography.Text>
        );
      },
    },
    {
      title: t("mac-address"),
      dataIndex: "macAddress",
      render: (_, rc) => {
        return rc?.macAddress || "-";
      },
    },
    {
      title: t("network-status"),
      dataIndex: "status",
      align: "center",
      render: (text) => {
        return (
          <div className="!flex !justify-center">
            <DeviceStatusCard status={text?.toLocaleUpperCase() || "OFFLINE"} />
          </div>
        );
      },
    },
  ];

  const onSearch = (FormItem: any) => {
    setParams({ ...params, ...FormItem });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        rowKey="id"
        onAdd="resource-management.encode-device-add"
        onRowClick="resource-management.encode-device-edit"
        columns={columns}
        query={data_encode}
        loading={data_encode?.isFetching}
        action={{
          bulkDelete: {
            mutation: bulk_delete_encode_device,
            invalidateQueries: ["get-encode-devices"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
          excels: {
            export: {
              mutation: export_encode_device,
            },
          },
        }}
        onDelete={{
          mutation: delete_encode_device,
          invalidateQueries: ["get-encode-devices"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        title={t("encode-device-list")}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        extraHeaderButton={[
          {
            children: t("fetch-new-data"),
            theme: "gray",
            loading: get_encode_device_from_hcp.isFetching,
            onClick: () => {
              get_encode_device_from_hcp.refetch();
              qClient.invalidateQueries(["get-encode-devices"]);
            },
          },
        ]}
      />
    </PageContent>
  );
};

export default ResourceEncodeDevicePage;
