import { Col, Row, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import styled from "styled-components";
import CardHomeData from "components/home/card-data-home";
import PieChartHome from "components/home/pie-chart-home";
import LineChartHome, { LabelStyle } from "components/home/line-chart-home";
import CardHomeList, { IconHomeStlye } from "components/home/card-list-home";
import {
  CheckSquareFilled,
  ClockCircleFilled,
  UndoOutlined,
} from "@ant-design/icons";
import { MenuSchedule } from "assets/icons/general";
import { IDashboardParams, IUserDashboard } from "services/dashboard/interface";
import { useGetUserDashboardsV2 } from "services/dashboard";
import dayjs from "dayjs";
import CRangePicker from "components/input/c-range-picker";
import { useAppLoading } from "provider/app-loading";
import CButton from "components/button/c-button";
import { useAppLanguage } from "provider/app-language";
import tw from "twin.macro";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayoutStyle = styled.div`
  ${tw`rounded-app`}
  background-color: #fff;
  box-shadow: 0px 1px 6px #0000000d;
`;

function getFromLS(key: string) {
  let ls = [];
  if (localStorage) {
    try {
      const lc = localStorage.getItem("user-manage-layout");
      ls = JSON.parse(lc || "[]") || {};
    } catch (e) { }
  }
  return ls[key];
}

function saveToLS(key: string, value: any) {
  if (localStorage) {
    localStorage.setItem(
      "user-manage-layout",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

const originalLayouts = getFromLS("layouts") || {};

// const sDate = dayjs().startOf("month").toISOString();
// const eDate = dayjs().endOf("month").toISOString();

const sDate = dayjs().startOf("day").toISOString();
const eDate = dayjs().endOf("day").toISOString();

const UserManagementHomePage = () => {
  const { t } = useTranslation();

  const [state, setState] = useState<any>({
    layouts: JSON.parse(JSON.stringify(originalLayouts)),
  });

  const [params, setParams] = useState<IDashboardParams>({
    startDate: sDate,
    endDate: eDate,
  });
  // const { data } = useGetUserDashboards(params);
  const { data } = useGetUserDashboardsV2(params);
  // const { data } = useGetUserDashboards();
  const { setAppLoading } = useAppLoading();

  const [startDate, setStartDate] = useState<string>(sDate);
  const [endDate, setEndDate] = useState<string>(eDate);

  const onLayoutChange = (layout: any, layouts: any) => {
    saveToLS("layouts", layouts);
    setState({ layouts });
  };

  const onSearch = (values: any) => {
    const startDate = values
      ? dayjs(values[0])?.startOf("day").toISOString()
      : sDate;
    const endDate = values
      ? dayjs(values[1])?.endOf("day").toISOString()
      : eDate;
    values = { ...values, startDate, endDate };
    setStartDate(startDate);
    setEndDate(endDate);
    setParams({ ...params, ...values });
  };

  const refreshPage = () => {
    setAppLoading(true);
    window.location.reload();
  };

  const onReset = () => {
    setState({ layouts: [] });
    setAppLoading(true);
    window.location.reload();
  };

  const innerHeight = window.innerHeight;
  const layout =
    innerHeight > 929
      ? Number(((innerHeight - 64 - 90 - 70) / 4).toFixed(0))
      : Number(((929 - 64 - 90 - 70) / 4).toFixed(0));

  return (
    <div className="w-full h-full">
      <Row className="h-[7%] mb-2">
        <Col span={13} className="pl-3 !flex !items-center">
          <Typography.Title level={4} style={{ color: "#425F4E" }}>
            {t("user-management", { ns: "menu" })}
          </Typography.Title>
        </Col>
        <Col span={6} className="!flex !items-center">
          <CRangePicker onChange={onSearch} />
        </Col>
        <Col span={3} className="px-2 !flex !items-center">
          <CButton
            theme="yellow"
            children={t("save-to-change")}
            onClick={refreshPage}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={2} className="!flex !items-center">
          <CButton
            children={t("reset")}
            icon={<UndoOutlined />}
            onClick={onReset}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row className="h-[93%]">
        <Col span={24}>
          <ResponsiveReactGridLayout
            layouts={state.layouts}
            onLayoutChange={onLayoutChange}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            style={{ height: "100%" }}
            rowHeight={layout}
            className="layout"
          >
            {/* ------------------------New Dashboard-------------------------------- */}
            <GridLayoutStyle
              key="contractor-all"
              data-grid={{ x: 0, y: 0, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <CountAllContractor
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>

            <GridLayoutStyle
              key="number-general-persons"
              data-grid={{ x: 3, y: 4, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <NumberGeneralPersons
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="number-contractors-work-group"
              data-grid={{ x: 6, y: 4, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <NumberContractorsWorkGroup
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="number-contractors-work-area"
              data-grid={{ x: 9, y: 4, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <NumberContractorsWorkArea
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="number-persons-and-visitors"
              data-grid={{ x: 0, y: 5, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <NumberPersonsAndVisitors
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="contractor-user"
              data-grid={{ x: 3, y: 5, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <ContractorUserAll
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>

            {/* ------------------------New Dashboard-------------------------------- */}



            {/* ------------------------Old Dashboard-------------------------------- */}
            {/* <GridLayoutStyle
              key="count-all-person"
              data-grid={{ x: 0, y: 0, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <CountAllPerson
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="guest"
              data-grid={{ x: 3, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <Guest data={data} startDate={startDate} endDate={endDate} />
            </GridLayoutStyle>

            <GridLayoutStyle
              key="contrator"
              data-grid={{ x: 6, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <Contractor data={data} startDate={startDate} endDate={endDate} />
            </GridLayoutStyle>


            <GridLayoutStyle
              key="supplierr"
              data-grid={{ x: 9, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <Supplier data={data} startDate={startDate} endDate={endDate} />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="receiver"
              data-grid={{ x: 3, y: 2, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <Receiver data={data} startDate={startDate} endDate={endDate} />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="count-pending-person"
              data-grid={{ x: 6, y: 2, w: 6, h: 1, maxH: 1, minW: 6 }}
            >
              <CountOfPersonPending
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="count-all-company"
              data-grid={{ x: 0, y: 3, w: 3, h: 2, minH: 2, maxH: 2, minW: 3 }}
            >
              <CountAllCompany
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="check-in-time"
              data-grid={{ x: 3, y: 3, w: 6, h: 2, minH: 2, maxH: 2, minW: 6 }}
            >
              <StatisticsCheckIN
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="count-all-emloyee"
              data-grid={{ x: 9, y: 3, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CountAllEmployee
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="count-user-blacklist"
              data-grid={{ x: 9, y: 4, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CountOfBlacklist
                data={data}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle> */}

            {/* ------------------------Old Dashboard-------------------------------- */}
          </ResponsiveReactGridLayout>
        </Col>
      </Row>
    </div>
  );
};

const CountAllPerson = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const visitor = Number(
    data?.totalAllCustomerEmployee?.[0]?.totalVisitor || 0
  );
  const contractor = Number(
    data?.totalAllCustomerEmployee?.[0]?.totalContractor || 0
  );
  const supplier = Number(
    data?.totalAllCustomerEmployee?.[0]?.totalSupplier || 0
  );
  const receiver = Number(
    data?.totalAllCustomerEmployee?.[0]?.totalReceiver || 0
  );
  const sum = Number(
    visitor + contractor + supplier + receiver
  ).toLocaleString();
  return (
    <CardHomeData
      title="count-all-person"
      startDate={startDate}
      endDate={endDate}
    >
      <Row gutter={[16, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[]}
            series={[visitor, contractor, supplier, receiver]}
            colors={["#425F4E", "#BF9C3D", "#438860", "#E0B33C"]}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>
            {`${sum} ${t("person-value")}`}
          </Typography.Title>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#425F4E" text={t("guest")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(visitor).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#BF9C3D" text={t("contractor")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(contractor).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#438860" text={t("supplier-type")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(supplier).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#E0B33C" text={t("receiver")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(receiver).toLocaleString()}
          </Typography.Text>
        </Col>
      </Row>
    </CardHomeData>
  );
};

const CountAllContractor = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const totalContractor5 = Number(
    data?.totalContractorType?.[0]?.totalContractor5 || 0
  );
  const totalContractor6 = Number(
    data?.totalContractorType?.[0]?.totalContractor6 || 0
  );
  const totalVisitorWorking = Number(
    data?.totalVisitorWorking || 0
  );
  const sum = Number(
    totalContractor5 + totalContractor6 + totalVisitorWorking
  ).toLocaleString();
  return (
    <CardHomeData
      title="contractor-all"
      startDate={startDate}
      endDate={endDate}
    >
      <Row gutter={[16, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[]}
            series={[totalContractor5, totalContractor6, totalVisitorWorking]}
            colors={["#425F4E", "#BF9C3D", "#438860"]}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>
            {`${sum} ${t("person-value")}`}
          </Typography.Title>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#425F4E" text={t("regular-contractor")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalContractor5).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#BF9C3D" text={t("job-contractor")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalContractor6).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#438860" text={t("special-contractor")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalVisitorWorking).toLocaleString()}
          </Typography.Text>
        </Col>

      </Row>
    </CardHomeData>
  );
};

const Guest = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="guests"
      theme="deepGreen"
      value={data?.totalAllVisitor}
      valueType="person-value"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const Contractor = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="contractor"
      theme="green"
      value={data?.totalAllContractor}
      valueType="person-value"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const Supplier = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="supplier-deliver-goods"
      theme="yellow"
      value={data?.totalAllSupplier}
      valueType="person-value"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const Receiver = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="consignee"
      theme="yellow"
      value={data?.totalAllReceiver}
      valueType="person-value"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const CountOfPersonPending = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const approve = Number(
    data?.totalAllVehiclePandingStatus?.[0]?.totalStatusApprove || 0
  );
  const pending = Number(
    data?.totalAllVehiclePandingStatus?.[0]?.totalStatusPending || 0
  );
  const sum = Number(approve + pending).toLocaleString();
  return (
    <Row className="p-5 h-full">
      <Col span={24}>
        <Typography.Text
          ellipsis
          className="font-bold"
          style={{ fontSize: "20px" }}
        >
          {t("count-pending-person")}
        </Typography.Text>
      </Col>
      <Col span={6}>
        <PieChartHome
          height={100}
          labels={[t("approval-pending"), t("approve")]}
          series={[pending, approve]}
          colors={["#BF9C3D", "#438860"]}
        />
      </Col>
      <Col span={8} className="center">
        <Typography.Title level={2}>
          {`${sum} ${t("person-value")}`}
        </Typography.Title>
      </Col>
      <Col span={10}>
        <Row gutter={[0, 12]}>
          <Col span={4}>
            <IconHomeStlye justify="center" align="middle" $color="yellow">
              <ClockCircleFilled
                style={{ fontSize: "16px", color: "#BF9C3D" }}
              />
            </IconHomeStlye>
          </Col>
          <Col span={16} className="!flex !items-center">
            <Typography.Text style={{ color: "#9A9A9A" }}>
              {t("approval-pending")}
            </Typography.Text>
          </Col>
          <Col span={4} className="text-end">
            <Typography.Title level={4} style={{ color: "#BF9C3D" }}>
              {Number(pending).toLocaleString()}
            </Typography.Title>
          </Col>
          <Col span={4}>
            <IconHomeStlye justify="center" align="middle" $color="green">
              <CheckSquareFilled
                style={{ fontSize: "16px", color: "#438860" }}
              />
            </IconHomeStlye>
          </Col>
          <Col span={16} className="!flex !items-center">
            <Typography.Text style={{ color: "#9A9A9A" }}>
              {t("approve")}
            </Typography.Text>
          </Col>
          <Col span={4} className="text-end">
            <Typography.Title level={4} style={{ color: "#438860" }}>
              {Number(approve).toLocaleString()}
            </Typography.Title>
          </Col>
        </Row>
      </Col>
      <Col span={1} className="center">
        <MenuSchedule />
      </Col>
      <Col className="!flex !items-center">
        <Typography.Text style={{ color: "#8F949D", fontSize: "18px" }}>
          {`${formatDate({ date: startDate, format: "normal" })} - ${formatDate(
            { date: endDate, format: "normal" }
          )}`}
        </Typography.Text>
      </Col>
    </Row>
  );
};

const CountAllCompany = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const contrator = Number(data?.totalOrganization?.[0]?.totalContractor || 0);
  const supplire = Number(data?.totalOrganization?.[0]?.totalSupplier || 0);
  const reciver = Number(data?.totalOrganization?.[0]?.totalReceiver || 0);
  const sum = Number(contrator + supplire + reciver).toLocaleString();
  return (
    <CardHomeData
      title="count-all-company"
      startDate={startDate}
      endDate={endDate}
    >
      <Row gutter={[12, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[]}
            series={[contrator, supplire, reciver]}
            colors={["#425F4E", "#438860", "#BF9C3D"]}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>
            {`${sum || 0} ${t("person-value")}`}
          </Typography.Title>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#425F4E" text={t("contractor")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(contrator).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col span={10} />
        <Col className="!flex !items-center">
          <LabelStyle color="#BF9C3D" text={t("supplier-type")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(supplire).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#438860" text={t("receiver")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(reciver).toLocaleString()}
          </Typography.Text>
        </Col>
      </Row>
    </CardHomeData>
  );
};

const StatisticsCheckIN = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();

  const getCategory = () => {
    return Array(24)
      .fill(undefined)
      .map((_, i) => i++)
      .filter((e) => !(e % 2));
  };

  const categories = getCategory().map((e) =>
    e >= 10 ? `${e}:00` : `0${e}:00`
  );

  const getHour = (hour: string) => {
    return dayjs(hour, "HH:mm:ssZZ").hour();
  };

  const getCheckIn = (): number[] => {
    const values = getCategory().map((item) => {
      const count = data?.graphCustomerCheckin?.find(
        (e) => getHour(e?.timeInterval) === item
      )?.totalCustomerCheckin?.count;
      const count2 = data?.graphCustomerCheckin?.find(
        (e) => getHour(e?.timeInterval) === item - 1
      )?.totalCustomerCheckin?.count;
      if (count2) return Number(count || 0) + Number(count2 || 0);
      if (count) return Number(count || 0);
      return 0;
    });

    return values;
  };

  return (
    <CardHomeData title="check-in-time" startDate={startDate} endDate={endDate}>
      <Row className="h-full">
        <Col span={24}>
          <LineChartHome
            height={275}
            categories={categories}
            series={[
              {
                name: t("check-in"),
                data: getCheckIn(),
              },
            ]}
          />
        </Col>
      </Row>
    </CardHomeData>
  );
};

const CountAllEmployee = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="count-all-emloyee"
      theme="green"
      value={data?.totalEmployee}
      valueType="person-value"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const CountOfBlacklist = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  return (
    <CardHomeList
      title="count-user-blacklist"
      theme="yellow"
      value={data?.totalBacklistPreson}
      valueType="person-value"
      startDate={startDate}
      endDate={endDate}
    />
  );
};

const NumberGeneralPersons = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const totalPickup = Number(
    data?.totalVisitorSubject?.[0]?.totalPickup || 0
  );
  const totalDeliver = Number(
    data?.totalVisitorSubject?.[0]?.totalDeliver || 0
  );
  const totalContact = Number(
    data?.totalVisitorSubject?.[0]?.totalContact || 0
  );
  const totalApplyJob = Number(
    data?.totalVisitorSubject?.[0]?.totalApplyJob || 0
  );
  const totalMeeting = Number(
    data?.totalVisitorSubject?.[0]?.totalMeeting || 0
  );
  const totalOther = Number(
    data?.totalVisitorSubject?.[0]?.totalOther || 0
  );
  const sum = Number(
    totalPickup + totalDeliver + totalContact + totalApplyJob + totalMeeting + totalOther
  ).toLocaleString();
  return (
    <CardHomeData
      title="number-general-persons"
      startDate={startDate}
      endDate={endDate}
    >

      <Row gutter={[16, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[]}
            series={[totalPickup, totalDeliver, totalContact, totalApplyJob, totalMeeting, totalOther]}
            colors={["#2980B9", "#F5B041", "#CACFD2", "#F4D03F", "#E74C3C", "#27AE60"]}
          />
        </Col>

        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>
            {`${sum} ${t("person-value")}`}
          </Typography.Title>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#2980B9" text={t("visitor-subject-pickup")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalPickup).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#F5B041" text={t("visitor-subject-deliver")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalDeliver).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#CACFD2" text={t("visitor-subject-contact")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalContact).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#F4D03F" text={t("visitor-subject-apply-job")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalApplyJob).toLocaleString()}
          </Typography.Text>
        </Col>

        <Col className="!flex !items-center">
          <LabelStyle color="#E74C3C" text={t("visitor-subject-meeting")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalMeeting).toLocaleString()}
          </Typography.Text>
        </Col>

        <Col className="!flex !items-center">
          <LabelStyle color="#27AE60" text={t("visitor-subject-other")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalOther).toLocaleString()}
          </Typography.Text>
        </Col>
      </Row>
    </CardHomeData>
  );
};
const NumberContractorsWorkGroup = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const dataList: any = [];
  const colorList: any = [];
  var sum = 0;
  var color = ["#C0392B", "#9B59B6", "#2980B9", "#16A085", "#F1C40F", "#D35400", "#839192"];

  for (var i = 0; i < Number(data?.totalContractorJobs.length); i++) {
    dataList.push(Number(data?.totalContractorJobs[i].totalContractor))
    sum = sum + Number(data?.totalContractorJobs[i].totalContractor);
    colorList.push(color[i % 7])
  }

  return (
    <CardHomeData
      title="number-contractors-work-group"
      startDate={startDate}
      endDate={endDate}
    >
      <Row gutter={[16, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[]}
            series={dataList}
            colors={colorList}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>
            {`${sum} ${t("person-value")}`}
          </Typography.Title>
        </Col>


        <div style={{ height: '80px', width: "100%", overflowY: 'scroll' }}>

          {data?.totalContractorJobs.map((item, i) =>
            <div>
              <Row gutter={12} className="py-1 p-4 mx-4">
                <Col className="!flex !items-left">
                  <LabelStyle color={colorList[i]} text={t(item.jobTypeNameTh)} />
                </Col>
                <Col className="text-end">
                  <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
                    {Number(item.totalContractor).toLocaleString()} {"  "}
                  </Typography.Text>
                </Col>

              </Row>
            </div>
          )}

        </div>

      </Row>
    </CardHomeData>
  );
};

const NumberContractorsWorkArea = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const dataList: any = [];
  const colorList: any = [];
  var sum = 0;
  var color = ["#C0392B", "#9B59B6", "#2980B9", "#16A085", "#F1C40F", "#D35400", "#839192"];

  for (var i = 0; i < Number(data?.totalContractorWorks.length); i++) {
    dataList.push(Number(data?.totalContractorWorks[i].totalContractor))
    sum = sum + Number(data?.totalContractorWorks[i].totalContractor);
    colorList.push(color[i % 7])
  }

  return (
    <CardHomeData
      title="number-contractors-work-area"
      startDate={startDate}
      endDate={endDate}
    >
      <Row gutter={[16, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[]}
            series={dataList}
            colors={colorList}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>
            {`${sum} ${t("person-value")}`}
          </Typography.Title>
        </Col>


        <div style={{ height: '80px', width: "100%", overflowY: 'scroll' }}>

          {data?.totalContractorWorks.map((item, i) =>
            <div>
              <Row gutter={12} className="py-1 p-4 mx-4">
                <Col className="!flex !items-left">
                  <LabelStyle color={colorList[i]} text={t(item.workAreaNameTh)} />
                </Col>
                <Col className="text-end">
                  <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
                    {Number(item.totalContractor).toLocaleString()} {"  "}
                  </Typography.Text>
                </Col>

              </Row>
            </div>
          )}

        </div>

      </Row>
    </CardHomeData>
  );
};

const NumberPersonsAndVisitors = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();

  const totalAllVisitor = Number(
    data?.totalAllCustomerEmployee?.[0]?.totalVisitor || 0
  );
  const totalAllContractor = Number(
    data?.totalAllCustomerEmployee?.[0]?.totalContractor || 0
  );
  const totalAllSupplier = Number(
    data?.totalAllCustomerEmployee?.[0]?.totalSupplier || 0
  );
  const totalAllReceiver = Number(
    data?.totalAllCustomerEmployee?.[0]?.totalReceiver || 0
  );
  const totalEmployee = Number(
    data?.totalEmployee || 0
  );

  const sum = Number(
    totalAllVisitor + totalAllContractor + totalAllSupplier + totalAllReceiver + totalEmployee
  ).toLocaleString();
  return (
    <CardHomeData
      title="number-persons-and-visitors"
      startDate={startDate}
      endDate={endDate}
    >

      <Row gutter={[16, 6]} className="h-full">
        <Col span={24}>
          <PieChartHome
            height={160}
            labels={[]}
            series={[totalAllVisitor, totalAllContractor, totalAllSupplier, totalAllReceiver, totalEmployee]}
            colors={["#2980B9", "#F5B041", "#CACFD2", "#F4D03F", "#E74C3C"]}
          />
        </Col>
        <Col span={24} className="py-2 center">
          <Typography.Title level={4}>
            {`${sum} ${t("person-value")}`}
          </Typography.Title>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#2980B9" text={t("general-public")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalAllVisitor).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#F5B041" text={t("contractor-user")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalAllContractor).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#CACFD2" text={t("shipper")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalAllSupplier).toLocaleString()}
          </Typography.Text>
        </Col>
        <Col className="!flex !items-center">
          <LabelStyle color="#F4D03F" text={t("receiver-goods")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalAllReceiver).toLocaleString()}
          </Typography.Text>
        </Col>

        <Col className="!flex !items-center">
          <LabelStyle color="#E74C3C" text={t("employee-user")} />
        </Col>
        <Col className="text-end">
          <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
            {Number(totalEmployee).toLocaleString()}
          </Typography.Text>
        </Col>


      </Row>
    </CardHomeData>
  );
};

const ContractorUserAll = ({
  data,
  startDate,
  endDate,
}: {
  data?: IUserDashboard;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  var sum = 0;
  for (var i = 0; i < Number(data?.totalContractorOrg.length); i++) {
    sum = sum + Number(data?.totalContractorOrg[i].totalContractor);
  }


  return (
    <CardHomeData
      title="number-contractors-org"
      startDate={startDate}
      endDate={endDate}
    >


      <div style={{ height: '270px', width: "100%", overflowY: 'scroll' }}>

        {data?.totalContractorOrg.map((item, i) =>

          <div>
            <Row gutter={12} className="py-1 p-4 mx-4">
              <Col className="!flex !items-left">
                <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
                  {i + 1} {"  "} {(item.organizationName)} {"  "}  {Number(item.totalContractor).toLocaleString()} {"  "}   {` ${t("people")}`}
                </Typography.Text>
              </Col>
            </Row>
          </div>
        )}

        <div>
          <Row gutter={12} style={{ float: "right", }} className="py-1 p-4 mx-4">
            <Col className="!flex !items-right">
              <Typography.Text style={{ fontSize: "18px", color: "#9A9A9A" }}>
                {` ${t("together")}`} {"  "}  {Number(sum).toLocaleString()} {"  "}   {` ${t("people")}`}
              </Typography.Text>
            </Col>
          </Row>
        </div>

      </div>


    </CardHomeData>
  );
};

export default UserManagementHomePage;
