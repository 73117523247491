import {
  Col,
  Divider,
  Form,
  InputNumber,
  InputNumberProps,
  Row,
  Typography,
} from "antd";
import CSpace from "components/display/c-space";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import CTransfer from "components/input/c-transfer";
import PageContent from "components/layout/page-content";
import { usePageRoute } from "provider/page-route";
import { useTranslation } from "react-i18next";
import Icon from "@ant-design/icons";
import { FaceScanningIcon } from "assets/icons/general";
import CProgress from "components/display/c-progress";
import {
  useGetFaceTaskID,
  usePatchFaceTask,
  usePostFaceTask,
} from "services/face-task";
import { useEffect, useState } from "react";
import { useGetFaceCompareGroups } from "services/face-compare-group";
import { useQueryClient } from "@tanstack/react-query";
import { fireNotification } from "components/popup/notification";
import CSelect from "components/input/c-select";
import { useGetEncodeDevices } from "services/encode-device";

const FaceDeterminedByAccessLevelCreateAndEditPage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const qClient = useQueryClient();
  const { id } = getCurrentParams();

  const { data, isLoading } = useGetFaceTaskID(id);
  const post_face_task = usePostFaceTask();
  const patch_face_task = usePatchFaceTask();
  const data_face_compare_group = useGetFaceCompareGroups({ pagination: false });
  const data_encode = useGetEncodeDevices({ pagination: false });

  const pageKey = getCurrentKey();

  const [getData, setData] = useState<IFaceTranfer[]>([]);

  useEffect(() => {
    initailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_face_compare_group.data, data]);

  const initailData = () => {
    const get = data_face_compare_group.data?.data.map((item) => {
      return {
        key: `${item.id}`,
        id: item.id,
        name: item.name,
      };
    });
    setData(get || []);
  };

  useEffect(() => {
    const groupName = data?.faceTaskWithGroups.map(
      (item) => `${item.compareGroup?.id}`
    );

    form.setFieldsValue({
      ...data,
      group: groupName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSave = (values: any) => {
    const groupID = values.group?.map((item: any) => Number(item));

    const data = {
      title: values.title,
      description: values.description,
      group: values.group ? groupID : undefined,
      similarity: values.similarity,
    };

    if (id) {
      patch_face_task.mutate(
        { id: id, ...data },
        {
          onSuccess: () => {
            fireNotification({ type: "success" });
            qClient.invalidateQueries(["get-face-tasks"]);
            qClient.invalidateQueries(["get-face-task-id"]);
          },
          onError: ({ err }: any) => {
            fireNotification({ type: "error", description: err });
          },
        }
      );
    } else {
      post_face_task.mutate(data, {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-face-tasks"]);
          qClient.invalidateQueries(["get-face-task-id"]);
        },
        onError: ({ err }: any) => {
          fireNotification({ type: "error", description: err });
        },
      });
    }
  };

  const onSaveAndContinue = (values: any) => {
    const groupID = values.group?.map((item: any) => Number(item));

    const data = {
      title: values.title,
      description: values.description,
      group: values.group ? groupID : undefined,
      similarity: values.similarity,
    };

    post_face_task.mutate(data, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        qClient.invalidateQueries(["get-face-tasks"]);
        qClient.invalidateQueries(["get-face-task-id"]);
      },
      onError: ({ err }: any) => {
        fireNotification({ type: "error", description: err });
      },
    });
  };

  return (
    <PageContent
      loading={isLoading && !!id}
      breadcrumb={{
        form,
        mutate: {
          onSave: onSave,
          onSaveAndContinue: !id ? onSaveAndContinue : false,
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title level={5} className="!text-primary-dark">
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form
          layout="vertical"
          className="!p-5"
          form={form}
          initialValues={{ userType: "GUEST", productList: false }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t("accessibility-name")}
                name="title"
                rules={[{ required: true }]}
              >
                <CInput placeholder={t("accessibility-name")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t("description")} name="description">
                <CInput.TextArea rows={5} placeholder={t("description")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("select-device")} name="deviceAnalysis">
                <CSelect
                  valueOptions={{
                    values: data_encode?.data?.data || [],
                    valueKey: "id",
                    titleKey: "devName",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mb-4">
              <Typography.Title level={5} className="!text-primary">
                {t("group")}
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Form.Item name="group" className="center-form-item">
                <TransferFaceGrop getData={getData} />
              </Form.Item>
            </Col>
            <Col span={24} className="mb-4">
              <Typography.Title level={5} className="!text-primary">
                {t("similarity")}
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Form.Item name="similarity">
                <ProcessStlye />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageContent>
  );
};

interface IFaceTranfer {
  key: string;
  id: number;
  name: string;
}

const TransferFaceGrop = ({
  onChange,
  value,
  getData,
}: {
  onChange?: any;
  value?: any;
  getData: IFaceTranfer[];
}) => {
  const { t } = useTranslation();
  const [targetKeys, setTargetKeys] = useState<string[]>(value);

  useEffect(() => {
    setTargetKeys(value);
  }, [value]);

  const filterOption = (inputValue: string, option: IFaceTranfer) =>
    option.name.indexOf(inputValue) > -1;

  const handleChange = (newTargetKeys: string[]) => {
    setTargetKeys(newTargetKeys);
    onChange(newTargetKeys);
  };

  return (
    <CTransfer
      dataSource={getData}
      showSearch
      filterOption={filterOption}
      targetKeys={targetKeys}
      onChange={handleChange}
      render={(item) => {
        return (
          <CSpace align="center">
            <div key={item.id}>
              <Icon
                style={{ fontSize: 18 }}
                component={FaceScanningIcon}
                className="mx-3"
              />
              <Typography.Text>{item.name}</Typography.Text>
            </div>
          </CSpace>
        );
      }}
      selectAllLabels={[
        ({ selectedCount, totalCount }) => (
          <Typography.Text>
            {selectedCount} / {totalCount} {t("list")}
          </Typography.Text>
        ),
        ({ selectedCount, totalCount }) => (
          <Typography.Text>
            {selectedCount} / {totalCount} {t("list")}
          </Typography.Text>
        ),
      ]}
      listStyle={{ width: 500, height: 400 }}
    />
  );
};

const ProcessStlye = (props: InputNumberProps) => {
  return (
    <Row>
      <Col span={22} className="pt-1">
        <CProgress percent={Number(props.value)} />
      </Col>
      <Col span={2} className="px-3">
        <InputNumber min={0} max={100} defaultValue={0} {...props} />
      </Col>
    </Row>
  );
};

export default FaceDeterminedByAccessLevelCreateAndEditPage;
