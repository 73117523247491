import Icon, { CarOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Col,  Row, Typography } from "antd";
import { AddMapIcon } from "assets/icons/general";
import CIndicator from "components/display/c-indicator";
import CModal from "components/display/c-modal";
import Container from "components/display/container";
import VideoPlayer from "components/display/video-player";
import ErrorPage from "components/pages/error-page";
import PleaseSelectDataPage from "components/pages/select-data-page";
import { fireNotification } from "components/popup/notification";
import { useParkingCamera } from "provider/vehicle-parking-camera";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageMarker from "react-image-marker";
import { useGetFloor } from "services/floor";
import { IFloor } from "services/floor/interface";
import { IMark } from "services/mark/interface";
import styled from "styled-components";
import MapPinMarkerComponent from "../../floors/edit/step-3/map-pin-marker/marker-component";

const VehicleParkingCameraContent = () => {
  return (
    <Container className="mt-4" height="calc(100% - 155px)">
      <Content />
    </Container>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  .image-marker {
    height: 100%;
  }
`;

const Content = () => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);
  const [selectedMark, setSelectMark] = useState<IMark>();

  const { selectedFloor } = useParkingCamera();
  const { data, isLoading, isError, error } = useGetFloor(selectedFloor);

  if (!selectedFloor) {
    return <PleaseSelectDataPage />;
  }

  if (isLoading) {
    return <CIndicator />;
  }

  if (!data || isError) {
    return <ErrorPage error={error} />;
  }

  if (!data.mediaObject) {
    return (
      <div className="center flex-col h-full">
        <Icon component={AddMapIcon} style={{ fontSize: 80 }} />
        <Typography.Text>
          {t("the-map-for-the-layer-not-config", { ns: "message" })}
        </Typography.Text>
      </div>
    );
  }

  const toggle = () => {
    setVisible(!visible);
  };

  const getMarkers = () => {
    return (data.marks || []).map((item) => {
      return { ...item, top: Number(item.yAxis), left: Number(item.xAxis) };
    });
  };

  const markers = getMarkers();

  return (
    <ContentWrapper className="center">
      <DescriptionModal
        selectedMark={selectedMark}
        visible={visible}
        onClose={toggle}
        floor={data}
      />
      {/* <Image     src={data.mediaObject.url} /> */}
      <ImageMarker
        extraClass="!h-full"
        src={data.mediaObject.url}
        markers={markers}
        markerComponent={(props: any) => {
          const mark = props as IMark;
          return (
            <MapPinMarkerComponent
              cursorPointer={mark.markType === "CAMERA"}
              mark={mark}
              animationKey={markers.length}
              onClick={() => {
                if (mark.markType === "CAMERA") {
                  if (!mark.encodeDevice) {
                    return fireNotification({
                      type: "warning",
                      message: "missing encode-device",
                      menu: 'vehicle-management'
                    });
                  }
                  toggle();
                  setSelectMark(mark);
                }
              }}
            />
          );
        }}
      />
    </ContentWrapper>
  );
};

interface DescriptionModalProps {
  visible: boolean;
  onClose: () => void;
  selectedMark?: IMark;
  floor: IFloor;
}

const DescriptionModal: FC<DescriptionModalProps> = ({
  onClose,
  visible,
  selectedMark,
  floor,
}) => {
  const { t } = useTranslation();

  const title = (
    <Row align="middle" gutter={6}>
      <Col>
        <Typography.Title level={5}>{t("camera")}</Typography.Title>
      </Col>
      <Col className="ml-auto center">
        <CarOutlined style={{ fontSize: 12, color: "red" }} />
      </Col>
      <Col>
        <Typography.Text style={{ fontSize: 12 }}>
          {floor.parking?.parkingName} - {floor.floorName}
        </Typography.Text>
      </Col>
      <Col className="center">
        <VideoCameraOutlined style={{ fontSize: 12, color: "red" }} />
      </Col>
      <Col>
        <Typography.Text style={{ fontSize: 12 }}>
          {selectedMark?.markName}
        </Typography.Text>
      </Col>
    </Row>
  );

  return (
    <CModal width={800} title={title} visible={visible} onCancel={onClose}>
      <VideoPlayer
        height={400}
        encodeDeviceId={selectedMark?.encodeDevice?.id}
      />
    </CModal>
  );
};

export default VehicleParkingCameraContent;
