import UserWithPDPACreateAndEditPage from "components/menu/user-management/create-user-pdpa";
import { usePageRoute } from "provider/page-route";
import {
  useGetContractor,
  usePatchContractor,
  usePostContractor,
} from "services/contractor";

const UserContractorCreateAndEditPage = () => {
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const post_Contractor = usePostContractor();
  const patch_Contractor = usePatchContractor();
  const data = useGetContractor(id);

  return (
    <UserWithPDPACreateAndEditPage
      menu="contractor"
      action={{
        get: data,
        post: post_Contractor,
        patch: patch_Contractor,
        invalidateQueries: ["get-contractors", "get-contractor"],
      }}
    />
  );
};

export default UserContractorCreateAndEditPage;
