import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectContactByDeparture: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return <CSelect placeholder={t("contact-by-department")} {...props} />;
};

export default CSelectContactByDeparture;
