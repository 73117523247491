import { Form } from "antd";
import CDrawer from "components/display/c-drawer";
import CInput from "components/input/c-input";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePostMenuLevel } from "services/menu-level";

interface AddLevelDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const ParkingAddLevelDrawer: FC<AddLevelDrawerProps> = ({
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const add_level = usePostMenuLevel();

  return (
    <CDrawer
      width="40vw"
      title={t("add-level")}
      visible={visible}
      onClose={onClose}
      form={form}
      mutation={{
        mutate: add_level,
        invalidateQueries: ["get-menu-levels", "get-menu-level"],
        onBeforeSubmit: (values) => {
          return { ...values, levelNumber: 1 };
        },
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("level-name")}
          name="levelName"
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("level-name")} />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default ParkingAddLevelDrawer;
