import {
  KeyGateName,
  KeyNotificationName,
} from "services/notification-setting/interface";

export interface IDataNoti {
  gateName: KeyGateName;
  notificationName?: KeyNotificationName;
  title: string;
  children?: IDataNoti[];
}

export const dataNoti: IDataNoti[] = [
  {
    gateName: "GATE_1_IN",
    title: "gete-1-in",
    children: [
      // {
      //   gateName: "GATE_1_IN",
      //   notificationName: "FAILED_FACE_SCAN",
      //   title: "failed-face-scan",
      // },
      {
        gateName: "GATE_1_IN",
        notificationName: "TEMPERATURE_DOES_NOT_PASS",
        title: "temperature-does-not-pass",
      },
      {
        gateName: "GATE_1_IN",
        notificationName: "NO_MASK",
        title: "not-wearing-a-mask",
      },
      {
        gateName: "GATE_1_IN",
        notificationName: "SCAN_QRCODE_PDPA_NOT_ACCEPTED",
        title: "scan-qr-code-notify-the-staff-appointment",
      },
      {
        gateName: "GATE_1_IN",
        notificationName: "SCAN_THE_FACE_THROUGH",
        title: "pass-face-scan-notify-the-staff-appointment",
      },
    ],
  },
  {
    gateName: "GATE_1_OUT",
    title: "gete-1-out",
    children: [
      // {
      //   gateName: "GATE_1_OUT",
      //   notificationName: "FAILED_FACE_SCAN",
      //   title: "failed-face-scan",
      // },
      {
        gateName: "GATE_1_OUT",
        notificationName: "NO_MASK",
        title: "not-wearing-a-mask",
      },
    ],
  },
  {
    gateName: "GATE_2_IN",
    title: "gete-2-in",
    children: [
      // {
      //   gateName: "GATE_2_IN",
      //   notificationName: "FAILED_FACE_SCAN",
      //   title: "failed-face-scan",
      // },
      {
        gateName: "GATE_2_IN",
        notificationName: "TEMPERATURE_DOES_NOT_PASS",
        title: "temperature-does-not-pass",
      },
      {
        gateName: "GATE_2_IN",
        notificationName: "NO_MASK",
        title: "not-wearing-a-mask",
      },
      {
        gateName: "GATE_2_IN",
        notificationName: "SCAN_QRCODE_PDPA_NOT_ACCEPTED",
        title: "scan-qr-code-notify-the-staff-appointment",
      },
      {
        gateName: "GATE_2_IN",
        notificationName: "SCAN_THE_FACE_THROUGH",
        title: "pass-face-scan-notify-the-staff-appointment",
      },
    ],
  },
  {
    gateName: "GATE_2_OUT",
    title: "gete-2-out",
    children: [
      // {
      //   gateName: "GATE_2_OUT",
      //   notificationName: "FAILED_FACE_SCAN",
      //   title: "failed-face-scan",
      // },
      {
        gateName: "GATE_2_OUT",
        notificationName: "NO_MASK",
        title: "not-wearing-a-mask",
      },
    ],
  },
  {
    gateName: "GATE_3_IN",
    title: "gete-3-in",
    children: [
      {
        gateName: "GATE_3_IN",
        notificationName: "LICENSE_PLATE_DOES_NOT_PASS",
        title: "license-plate-does-not-pass",
      },
      {
        gateName: "GATE_3_IN",
        notificationName: "TEMPERATURE_DOES_NOT_PASS",
        title: "temperature-does-not-pass",
      },
    ],
  },
  {
    gateName: "GATE_3_OUT",
    title: "gete-3-out",
    children: [
      {
        gateName: "GATE_3_OUT",
        notificationName: "LICENSE_PLATE_DOES_NOT_PASS",
        title: "license-plate-does-not-pass",
      },
    ],
  },
  {
    gateName: "GATE_3_IN_TRUCK_CASE",
    title: "gete-3-in-truck",
    children: [
      {
        gateName: "GATE_3_IN_TRUCK_CASE",
        notificationName: "LICENSE_PLATE_DOES_NOT_PASS",
        title: "license-plate-does-not-pass",
      },
      {
        gateName: "GATE_3_IN_TRUCK_CASE",
        notificationName: "TEMPERATURE_DOES_NOT_PASS",
        title: "temperature-does-not-pass",
      },
      {
        gateName: "GATE_3_IN_TRUCK_CASE",
        notificationName: "SECOND_ROUND_TEMPERATURE_DOES_NOT_PASS",
        title: "failing-second-tem-check-alert-vip",
      },
      {
        gateName: "GATE_3_IN_TRUCK_CASE",
        notificationName: "VIP_REJECT_CAR",
        title: "vip-reject-car-case",
      },
    ],
  },
  {
    gateName: "CAR_GATE_1",
    title: "car-gate-1",
    children: [
      {
        gateName: "CAR_GATE_1",
        notificationName: "LICENSE_PLATE_DOES_NOT_PASS",
        title: "license-plate-does-not-pass",
      },
      {
        gateName: "CAR_GATE_1",
        notificationName: "TEMPERATURE_DOES_NOT_PASS",
        title: "temperature-does-not-pass",
      },
      {
        gateName: "CAR_GATE_1",
        notificationName: "SECOND_ROUND_TEMPERATURE_DOES_NOT_PASS",
        title: "failing-second-tem-check-alert-vip",
      },
      {
        gateName: "CAR_GATE_1",
        notificationName: "SECOND_ROUND_TEMPERATURE_PASS",
        title: "second-tem-check-vip-passed",
      },
    ],
  },
  {
    gateName: "CAR_GATE_1_OPEN_BARRIER",
    title: "car-gate-1-open",
    children: [
      {
        gateName: "CAR_GATE_1_OPEN_BARRIER",
        notificationName: "FAILED_THE_3RD_OF_VEHICLE_CONDITION",
        title: "vehicle-inspection-not-pass-3rd-noti-vip",
      },
      {
        gateName: "CAR_GATE_1_OPEN_BARRIER",
        notificationName: "VIP_REJECT_CAR",
        title: "vip-reject-car-case",
      },
    ],
  },
  {
    gateName: "TRUCK_PARKING_LOT",
    title: "truck-parking-lot",
    children: [
      {
        gateName: "TRUCK_PARKING_LOT",
        notificationName: "LICENSE_PLATE_DOES_NOT_PASS",
        title: "license-plate-does-not-pass",
      },
      {
        gateName: "TRUCK_PARKING_LOT",
        notificationName: "TEMPERATURE_DOES_NOT_PASS",
        title: "temperature-does-not-pass",
      },
    ],
  },
];
