import { ISelectUserType } from "pages/access-control/accessibility/determined-by-access-level/select-user-type/select-user-type";
import { ComponentType, createContext, useContext, useState } from "react";
import { AccessibilityDeterminedByAccessLevelContext } from "./interface";

const Context = createContext<AccessibilityDeterminedByAccessLevelContext>(
  {} as AccessibilityDeterminedByAccessLevelContext
);

const withAccessibilityDeterminedAccessLevel = (Component: ComponentType) => {
  return () => {
    const [accessibility, setAccessibility] = useState();
    const [userType, setUserType] = useState<ISelectUserType>({
      userType: "ALL",
      title: "all",
    });
    const [userGroup , setUserGroup] = useState();

    return (
      <Context.Provider
        value={{
          userType,
          setUserType,
          accessibility,
          setAccessibility,
          userGroup,
          setUserGroup
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useAccessibilityDeterminedAccessLevel = () => useContext(Context);
export default withAccessibilityDeterminedAccessLevel;
