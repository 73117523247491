import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ISettingScaleParams, ISttingScale } from "./interface";

export const useGetSettingScale = (
  params: ISettingScaleParams
): UseQueryResult<IResponse<ISttingScale[]>> => {
  return useQuery(
    ["get-Scales", params],
    async () => {
      const res = await axios.get("/scale", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostScale = (): UseMutationResult => {
    return useMutation(async (values: any) => {
      const res = await axios.post("/scale", values);
      if (res.status === 200) {
        return;
      }
      throwResponse(res);
    });
  };

  export const usePatchScaleId = (): UseMutationResult => {
    return useMutation(async ({ id, ...values }: any) => {
      const res = await axios.patch(`/scale/${id}`, values);
      if (res.status === 200) {
        return;
      }
      throwResponse(res);
    });
  };
  
  export const useDeleteScale = (): UseMutationResult => {
    return useMutation(async ({ id }: any) => {
      const res = await axios.delete(`/scale/${id}`);
      if (res.status === 200) {
        return;
      }
      throwResponse(res);
    });
  };
  export const useDeleteScaleBulk = (): UseMutationResult => {
    return useMutation(async ({ ids }: any) => {
      const res = await axios.delete(`/scale/bulk`, { data: { ids } });
      if (res.status === 200) {
        return;
      }
      throwResponse(res);
    });
  };

