import { Space, SpaceProps } from "antd";
import { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const StyledSpace = styled(Space)`
  .ant-space-item {
    ${tw`flex items-center`};
  }
`;

const CSpace:FC<SpaceProps> = (props) => {
  return <StyledSpace {...props} />;
};

export default CSpace;
