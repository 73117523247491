import { Checkbox, Col, Form, Row, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import CInput from "components/input/c-input";
import CRangePicker from "components/input/c-range-picker";
import CSelect from "components/input/c-select";
import CSelectCompanyNameTags from "components/input/c-select/company-name-tags";
import CSelectUserType from "components/input/c-select/person-type";
import dayjs from "dayjs";
import { useUserReport } from "provider/user-report";
import React from "react";
import { useTranslation } from "react-i18next";

const SideBarCustomer = () => {
  const { t } = useTranslation();
  const { columnsKey, setColumnsKey, search, setSearch } = useUserReport();

  const option = [
    {
      key: "date",
      title: t("entry-date"),
    },
    {
      key: "identityCode",
      title: t("id"),
    },
    {
      key: "name",
      title: t("full-name"),
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
    },
    {
      key: "companyName",
      title: t("company-or-shipping"),
    },
    {
      key: "startDate",
      title: t("start-date"),
    },
    {
      key: "endDate",
      title: t("end-date"),
    },
    {
      key: "registerType",
      title: t("formation"),
    },
    {
      key: "userType",
      title: t("person-type"),
    },
  ];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setColumnsKey(checkedValues as string[]);
  };

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item label={t("entry-date")} name="date">
              <CRangePicker
                onChange={(e) => {
                  const sDate = e
                    ? dayjs(e?.[0]).startOf("day").toISOString()
                    : undefined;
                  const eDate = e
                    ? dayjs(e?.[1])?.endOf("day").toISOString()
                    : undefined;
                  setSearch({ ...search, startDate: sDate, endDate: eDate });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("full-name")} name="name">
              <CInput
                placeholder={t("full-name")}
                onChange={(e) => {
                  const name = e.target.value ? e.target.value : undefined;
                  setSearch({ ...search, name: name });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("phone-number")} name="phoneNumber">
              <CSelect
                open={false}
                allowClear
                placeholder={t("phone-number")}
                mode="tags"
                maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, phoneNumber: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t("company-or-shipping")} name="companyName">
              <CSelectCompanyNameTags
                onChange={(e) => {
                  setSearch({ ...search, companyName: e });
                }}
                multipleMode
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="registerType" label={t("formation")}>
              <CSelect.RegisterType
                onChange={(e) => {
                  setSearch({ ...search, registerType: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="userType" label={t("person-type")}
              initialValue="VISITOR"
            >
              <CSelectUserType
                // allowClear
                // mode="tags"
                // maxTagCount="responsive"
                onChange={(e) => {
                  setSearch({ ...search, userType: [e] })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="mb-4">
            <Typography.Title level={5}>
              {t("required-information")}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onChange}
              defaultValue={columnsKey}
            >
              <Row gutter={[0, 16]}>
                {option?.map((item, index) => {
                  return (
                    <Col span={24} key={index}>
                      <Checkbox value={item?.key}>{t(item?.title)}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default SideBarCustomer;
