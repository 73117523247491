import { Card, Typography } from "antd";
import { FC } from "react";

type ColorTypes = "success" | "warning" | "danger";

const colorList: { [K in ColorTypes]: { bg: string; border: string } } = {
  success: {
    bg: "#EAF7EC",
    border: "#47B174",
  },
  warning: {
    bg: "#FFF6E0",
    border: "#E0B33C",
  },
  danger: {
    bg: "#FFE6E6",
    border: "#DB1212",
  },
};

export interface CCardWithBorderProps {
  type: ColorTypes;
  label: string;
  className?: string;
}

const CCardWithBorder: FC<CCardWithBorderProps> = ({
  label,
  type,
  className,
}) => {
  return (
    <Card
      style={{
        borderColor: colorList[type].border,
        backgroundColor: colorList[type].bg,
      }}
      bodyStyle={{ padding: 0, margin: "auto" }}
      className={`!p-1 shadow shadow-[${colorList[type].border}] ${className}`}
    >
      <Typography.Text ellipsis={{ tooltip: label }}>
        {label || "-"}
      </Typography.Text>
    </Card>
  );
};

export default CCardWithBorder;
