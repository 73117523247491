import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectErpTransactionType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("transaction-type")}
      {...props}
      valueOptions={{
        values: [
          { label: "กาก - Pack", value: 1 },
          { label: "กาก - Bulk", value: 2 },
          { label: "น้ำมัน - Pack", value: 3 },
          { label: "น้ำมัน - Bulk", value: 4 }
        ],
        valueKey: "value",
        titleKey: "label",
      }}
    />
  );
};

export default CSelectErpTransactionType;
