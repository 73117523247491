import { Col, Divider, Row, Steps, Typography, Tabs } from "antd";
import CButton from "components/button/c-button";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

interface TabsProps {
  title: string;
  element: ReactNode;
}

interface StepsTabProps {
  title: string;
  onEditClick?: () => void;
  tabs: TabsProps[];
}

const PaneBody = styled.div`
  // height: 500px;
  min-height: 200px;
  background-color: white;
`;

const StyledSteps = styled(Steps)`
  .ant-steps-item-description {
    font-size: 20px;
  }
  .ant-steps-item-icon {
    font-size: 22px;
    margin-left: 30px;
    // margin-bottom: 20px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-steps-item-tail {
    margin-left: 52px;
    margin-top: 7px;
  }
  .ant-steps-item: not(.ant-steps-item-active) {
    &:hover {
      .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
        ${tw`!border-primary`};
        .ant-steps-icon {
          ${tw`!text-primary`};
        }
      }
    }
  }
`;

const StepsTab = ({ tabs, title, onEditClick }: StepsTabProps) => {
  const { t } = useTranslation();
  const [getCurrent, setCurrent] = useState<number>(0);

  const goBack = getCurrent > 0;
  const goNext = getCurrent < tabs.length - 1;

  const onBack = () => {
    if (goBack) {
      setCurrent(getCurrent - 1);
    }
  };

  const onNext = () => {
    if (goNext) {
      setCurrent(getCurrent + 1);
    }
  };

  return (
    <React.Fragment>
      <div className="bg-white">
        <Row justify="space-between" align="middle" className="p-4">
          <Col>
            <Typography.Title level={4}>{title}</Typography.Title>
          </Col>
          <Col span={3}>
            <CButton className="w-full" theme="white" onClick={onEditClick}>
              {t("edit")}
            </CButton>
          </Col>
        </Row>
        <Divider className="!m-0" />
        <Row className="p-4">
          <Col span={4}></Col>
          <Col span={16}>
            <StyledSteps
              current={getCurrent}
              onChange={setCurrent}
              labelPlacement="vertical"
            >
              {tabs.map((item, index) => {
                return (
                  <StyledSteps.Step key={index} description={item.title} />
                );
              })}
            </StyledSteps>
          </Col>
          <Col span={4}></Col>
        </Row>
      </div>
      <div className="mt-4">
        <Tabs tabBarStyle={{ display: "none" }} activeKey={`${getCurrent}`}>
          {tabs.map((item, index) => {
            return (
              <Tabs.TabPane key={index}>
                <PaneBody>{item.element}</PaneBody>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
      <div className="mt-4 bg-white p-3">
        <Row gutter={12}>
          <Col span={3} hidden={!goBack}>
            <CButton theme="white" onClick={onBack} className="w-full">
              {t("back")}
            </CButton>
          </Col>
          <Col span={3} hidden={!goNext}>
            <CButton theme="white" onClick={onNext} className="w-full">
              {t("next")}
            </CButton>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default StepsTab;
