import { Form } from "antd";
import { FormInstance } from "rc-field-form";
import CInput from "components/input/c-input";
import CRadio from "components/input/c-radio";
import React from "react";
import { useTranslation } from "react-i18next";
import { TypeInput } from "provider/vehicle-inspection/interface";

interface InputProps {
  keyName: TypeInput;
  form: FormInstance;
  disabled: boolean;
}

const FormInputRadio: React.FC<InputProps> = ({ form, disabled, keyName }) => {
  const { t } = useTranslation();
  const status = Form.useWatch("status", form);

  const first = Form.useWatch(`firstTimeCheckQuestion${keyName}`, form);
  if (first) {
    form.setFieldsValue({
      [`firstTimeCheckQuestion${keyName}Detail`]: undefined,
    });
  }

  const second = Form.useWatch(`secondTimeCheckQuestion${keyName}`, form);
  if (second) {
    form.setFieldsValue({
      [`secondTimeCheckQuestion${keyName}Detail`]: undefined,
    });
  }

  const third = Form.useWatch(`thirdTimeCheckQuestion${keyName}`, form);
  if (third) {
    form.setFieldsValue({
      [`thirdTimeCheckQuestion${keyName}Detail`]: undefined,
    });
  }

  const fourth = Form.useWatch(`fourthTimeCheckQuestion${keyName}`, form);
  if (fourth) {
    form.setFieldsValue({
      [`fourthTimeCheckQuestion${keyName}Detail`]: undefined,
    });
  }

  const disabled1 = disabled || status !== "WAITING_FOR_INSPECT";
  const show2 = first === false && status !== "WAITING_FOR_INSPECT";
  const show3 = second === false && status !== "FAILED_THE_1ST_INSPECTION";
  const show4 = third === false && status !== "FAILED_THE_2ND_INSPECTION";

  return (
    <div className="pr-2" key={keyName}>
      <Form.Item
        name={`firstTimeCheckQuestion${keyName}`}
        rules={[{ required: true }]}
      >
        <CRadio.Group
          optionType="default"
          options={[
            { label: t("pass"), value: true },
            { label: t("not-pass"), value: false },
          ]}
          disabled={disabled1}
        />
      </Form.Item>
      <Form.Item
        name={`firstTimeCheckQuestion${keyName}Detail`}
        rules={[{ required: !first }]}
        noStyle={first === true || first === undefined}
      >
        <CInput.TextArea
          hidden={first === true || first === undefined}
          placeholder={t("note")}
          rows={4}
          disabled={disabled1}
        />
      </Form.Item>
      {show2 && [
        <Form.Item
          name={`secondTimeCheckQuestion${keyName}`}
          rules={[{ required: status === "FAILED_THE_1ST_INSPECTION" }]}
        >
          <CRadio.Group
            optionType="default"
            options={[
              { label: t("pass"), value: true },
              { label: t("not-pass"), value: false },
            ]}
            disabled={disabled || status !== "FAILED_THE_1ST_INSPECTION"}
          />
        </Form.Item>,
        <Form.Item
          name={`secondTimeCheckQuestion${keyName}Detail`}
          noStyle={second === true || second === undefined}
          rules={[
            { required: !second && status === "FAILED_THE_1ST_INSPECTION" },
          ]}
        >
          <CInput.TextArea
            hidden={second === true || second === undefined}
            placeholder={t("note")}
            rows={4}
            disabled={disabled || status !== "FAILED_THE_1ST_INSPECTION"}
          />
        </Form.Item>,
      ]}
      {show3 && [
        <Form.Item
          name={`thirdTimeCheckQuestion${keyName}`}
          rules={[{ required: status === "FAILED_THE_2ND_INSPECTION" }]}
        >
          <CRadio.Group
            optionType="default"
            options={[
              { label: t("pass"), value: true },
              { label: t("not-pass"), value: false },
            ]}
            disabled={disabled || status !== "FAILED_THE_2ND_INSPECTION"}
          />
        </Form.Item>,
        <Form.Item
          name={`thirdTimeCheckQuestion${keyName}Detail`}
          noStyle={third === true || third === undefined}
          rules={[
            { required: !third && status === "FAILED_THE_2ND_INSPECTION" },
          ]}
        >
          <CInput.TextArea
            hidden={third === true || third === undefined}
            placeholder={t("note")}
            rows={4}
            disabled={disabled || status !== "FAILED_THE_2ND_INSPECTION"}
          />
        </Form.Item>,
      ]}
      {show4 && [
        <Form.Item
          name={`fourthTimeCheckQuestion${keyName}`}
          rules={[{ required: status === "FAILED_THE_3RD_INSPECTION" }]}
        >
          <CRadio.Group
            optionType="default"
            options={[
              { label: t("pass"), value: true },
              { label: t("not-pass"), value: false },
            ]}
            disabled={disabled || status !== "FAILED_THE_3RD_INSPECTION"}
          />
        </Form.Item>,
        <Form.Item
          name={`fourthTimeCheckQuestion${keyName}Detail`}
          noStyle={fourth === true || fourth === undefined}
          rules={[
            { required: !fourth && status === "FAILED_THE_3RD_INSPECTION" },
          ]}
        >
          <CInput.TextArea
            hidden={fourth === true || fourth === undefined}
            placeholder={t("note")}
            rows={4}
            disabled={disabled || status !== "FAILED_THE_3RD_INSPECTION"}
          />
        </Form.Item>,
      ]}
    </div>
  );
};

export default FormInputRadio;
