import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import { USER_TYPE_DATA } from "components/input/c-user-type";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import { useUserPendingList } from "provider/user-pending-list";
import { useTranslation } from "react-i18next";
import {
  useGetReqContarctor,
  useGetReqVisitor,
  useGetReqReceiver,
  useGetReqSupplier
} from "services/req-approve-customer-employee";

const PendingListSidebar = () => {
  const { t } = useTranslation();
  const { selected, onSelect } = useUserPendingList();

  const count_visitor = useGetReqVisitor({ acceptStatus: "PENDING" });
  const count_contractor = useGetReqContarctor({ acceptStatus: "PENDING" });
  const count_receiver = useGetReqReceiver({ acceptStatus: "PENDING" });
  const count_supplier = useGetReqSupplier({ acceptStatus: "PENDING" });

  const content = USER_TYPE_DATA.filter((e) => {
    return (
      // e.userType !== "SUPPLIER" &&
      // e.userType !== "RECEIVER" &&
      e.userType !== "EMPLOYEE"
    );
  }).map((item) => {
    const countUser =
      item?.userType === "VISITOR"
        ? count_visitor?.data?.count
        : item?.userType === "CONTRACTOR"
          ? count_contractor?.data?.count
          : item?.userType === "RECEIVER"
            ? count_receiver?.data?.count
            : item?.userType === "SUPPLIER"
              ? count_supplier?.data?.count
              : "";

    return (
      <CCard.SlashHeader
        active={item.userType === selected?.userType}
        className="mb-4"
        title={t(item.title, { ns: "menu" })}
        key={item.userType}
        onClick={() => onSelect(item)}
      >
        <Row
          align="middle"
          className="px-3 py-2"
          style={{ height: 86 }}
          gutter={12}
        >
          <Col>
            <Typography.Text type="secondary" ellipsis={{ tooltip: t("list") }}>
              {t("pending-approval")} :
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{`${countUser || 0} ${t(
              "list"
            )}`}</Typography.Text>
          </Col>
        </Row>
      </CCard.SlashHeader>
    );
  });

  return <InfiniteSidebar title={t("pending-approval")} content={content} />;
};

export default PendingListSidebar;
