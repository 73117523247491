import { throwResponse } from "config/axios";
import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";
import { IVehicleOvernight, IVehicleOvernightParams } from "./interface";

export const useGetVehicleOvernight = (
  params?: IVehicleOvernightParams
): UseQueryResult<IResponse<IVehicleOvernight[]>, Error> => {
  return useQuery(["get-vehicle-overnights", params], async () => {
    const res = await axios.get("/vehicle-parking", { params });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportVehicleOvernight = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/export-excel/vehicle/vehicle-parking-report-excel",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
