import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectErpProduct: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("product-group")}
      {...props}
      valueOptions={{
        values: [
          { label: "น้ำมันพืช และ ไขมัน", value: 1 },
          { label: "วัตถุดิบอาหารสัตว์", value: 2 },
          { label: "เลซิทินจากถั่วเหลือง", value: 3 },
          { label: "ผลิตภัณฑ์อื่นๆ", value: 4 },
          { label: "ถั่วเหลือง", value: 5 },
          { label: "เมล็ดทานตะวัน", value: 6 },
          { label: "คาโนลา", value: 7 },
          { label: "สารเคมี", value: 8 },
          { label: "อื่นๆ", value: 9 },
          { label: "ไม่ระบุ", value: 10 },
        ],
        valueKey: "value",
        titleKey: "label",
      }}
    />
  );
};

export default CSelectErpProduct;
