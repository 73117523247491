import { InputNumber, InputNumberProps } from "antd";
import { FC } from "react";
import styled from "styled-components";

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  height: 45px;
  input,
  .ant-input-number-input-wrap {
    height: 100%;
    font-size: 20px;
  }
`;

const CNumber: FC<InputNumberProps> = (props) => {
  return <StyledInputNumber precision={0} {...props} />;
};

export default CNumber;
