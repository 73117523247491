import { Empty } from "antd";
import { CSSProperties, FC } from "react";
import { useTranslation } from "react-i18next";
// import empty from "../../assets/images/empty.svg";

interface CEmptyProps {
  text?: string;
  className?: string;
  style?: CSSProperties;
}

const CEmpty: FC<CEmptyProps> = ({ text, className, style }) => {
  const { t } = useTranslation("message");
  return (
    <Empty
      // image={empty}
      style={style}
      imageStyle={{ height: 60 }}
      description={text || t("no-content", { ns: "message" })}
      className={`p-5 ${className}`}
    />
  );
};

export default CEmpty;
