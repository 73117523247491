import { ComponentType, createContext, useContext, useState } from "react";
import { IParking } from "services/parking/interface";
import { IParkingCameraContext } from "./interface";

const Context = createContext<IParkingCameraContext>(
  {} as IParkingCameraContext
);

const withParkingCameraProvider = (Component: ComponentType) => {
  return () => {
    const [selectedParking, setSelectedParking] = useState<IParking>();
    const [selectedFloor, setSelectedFloor] = useState<number>();

    return (
      <Context.Provider
        value={{
          selectedParking,
          selectedFloor,
          setSelectedFloor,
          setSelectedParking,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useParkingCamera = () => useContext(Context);
export default withParkingCameraProvider;
