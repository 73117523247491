import { createContext, ReactNode, useContext, useState } from "react";
import { IReportDoorLogContext, IReportDoorLogSearch } from "./interface";

const Context = createContext<IReportDoorLogContext>(
  {} as IReportDoorLogContext
);

const key = [
  "date",
  "fullName",
  "phoneNumber",
  "actionType",
  "maskStatus",
  "companyName",
  "temperatureStatus",
  "doorName",
  "userType"
];

const AccessReportDoorLogProvider = ({ children }: { children: ReactNode }) => {
  const [columnsKey, setColumnsKey] = useState<string[]>(key);
  const [search, setSearch] = useState<IReportDoorLogSearch>({});

  return (
    <Context.Provider
      value={{
        columnsKey,
        setColumnsKey,
        search,
        setSearch,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useReportDoorLog = () => useContext(Context);
export default AccessReportDoorLogProvider;
