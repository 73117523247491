import styled from "styled-components";
import { SidebarLogoImage } from "assets/images";
import { IPageSidebarItem } from "./interface";
import PageSidebarItem from "./page-sidebar-item";

const PageSidebarWrapper = styled.div`
  height: 100%;
  min-height: 1px;
  width: 300px;
  user-select: none;
  display: inline-block;
  // overflow-y: auto;
  background-image: url(${SidebarLogoImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -100px bottom;
  @media only screen and (max-width: 1200px) {
    width: auto;
  }
`;

const PageSidebar = ({ menus }: { menus: IPageSidebarItem[] }) => {
  return (
    <PageSidebarWrapper className="pb-5 relative bg-primary-light">
      <div style={{ overflowY: "auto" }} className="h-full">
        {menus.map((item, index) => {
          return <PageSidebarItem menu={item} key={index} />;
        })}
      </div>
    </PageSidebarWrapper>
  );
};

export default PageSidebar;
