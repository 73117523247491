import { TableColumnsType } from "antd";
import CImage from "components/display/c-image";
import CTable from "components/display/c-table";
import CCard from "components/display/card";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportExcelVehicleDetect,
  useGetVehicleDetects,
} from "services/vehicle-detect";
import {
  IVehicleDetect,
  IVehicleDetectParams,
} from "services/vehicle-detect/interface";

const accessType = {
  IN: { label: "vehicle-in", type: "success" },
  OUT: { label: "vehicle-out", type: "danger" },
};

const VehicleListOfAllVehicle = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IVehicleDetectParams>({
    page: 1,
    limit: 10,
  });

  const query = useGetVehicleDetects(params);
  const export_excel = useExportExcelVehicleDetect();

  const { formatDate } = useAppLanguage();

  const elements: ISearchBoxElement[] = [
    {
      name: "licensePlate",
      label: t("license-plate-number"),
      input: {
        type: "Input",
      },
    },
    {
      name: "fullname",
      label: t("full-name"),
      input: {
        type: "Input",
      },
    },
    {
      name: "date",
      label: t("time-period"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
    {
      name: "accessStatus",
      label: t("entry-type"),
      input: {
        type: "Select",
        options: {
          allowClear: true,
          valueOptions: {
            values: [
              { title: t("vehicle-in"), value: "IN" },
              { title: t("vehicle-out"), value: "OUT" },
            ],
            valueKey: "value",
            titleKey: "title",
          },
        },
      },
    },
    {
      name: "userType",
      label: t("user-type"),
      input: {
        type: "SelectUserType",
      },
    },
  ];

  const columns: TableColumnsType<IVehicleDetect> = [
    {
      title: t("license-plate-image"),
      render: (_, rc) => {
        return <CImage width={100} height={62} src={rc.detectImage?.url} />;
      },
    },
    {
      title: t("license-plate-number"),
      dataIndex: "licensePlate",
    },
    {
      title: t("time-period"),
      render: (_, rc) => {
        return formatDate({
          date: rc.createdAt,
          format: "full-month-full-time",
        });
      },
    },
    {
      title: t("entry-type"),
      width: 150,
      align: "center",
      render: (_, rc) => {
        if (!rc.accessStatus) return "-";
        return (
          <CCard.WithBorder
            type={accessType[rc.accessStatus].type as any}
            label={t(accessType[rc.accessStatus].label)}
          />
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const { firstName, lastName } = rc.customerEmployee || {};
        if (!firstName && !lastName) return "-";

        return `${firstName} ${lastName}`;
      },
    },
    {
      title: t("parking-lot"),
      render: (_, rc) => {
        const { parkingName } = rc.parking || {};
        if (!parkingName) return "-";

        return parkingName;
      },
    },
    {
      title: t("parking-floor"),
      render: (_, rc) => {
        const {floorName} = rc.floor || {}
        if (!floorName) return "-";
        return floorName
      },
    },
    {
      title: t("user-type"),
      render: (_, rc) => {
        const { userType } = rc.customerEmployee || {};

        if (!userType) return "-";
        return t(userType.toLowerCase());
      },
    },
  ];

  const onSearch = (values: any) => {
    values = { ...values, ...values.date };
    setParams({
      ...params,
      startDate: undefined,
      endDate: undefined,
      ...values,
      page: 1,
    });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        scroll={{ x: 1200 }}
        query={query}
        title={t("list-of-all-vehicles")}
        onRowClick="vehicle-management.list-of-all-vehicles-edit"
        columns={columns}
        action={{
          excels: {
            export: {
              mutation: export_excel,
              fileName: "list-of-all-vehicle",
            },
          },
        }}
        selectAble={false}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default VehicleListOfAllVehicle;
