import { PieChartFilled, UndoOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Typography } from "antd";
import { MenuSchedule } from "assets/icons/general";
import CImage from "components/display/c-image";
import CardHomeData from "components/home/card-data-home";
import CardHomeList, { IconHomeStlye } from "components/home/card-list-home";
import LineChartHome, { LabelStyle } from "components/home/line-chart-home";
import PieChartHome from "components/home/pie-chart-home";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useGetVehicleDashboards } from "services/dashboard";
import {
  IDashboardParams,
  IGraphInOutVehicle,
  ITotalAllvehicleOften,
} from "services/dashboard/interface";
import dayjs from "dayjs";
import CRangePicker from "components/input/c-range-picker";
import CButton from "components/button/c-button";
import { useAppLoading } from "provider/app-loading";
import { useAppLanguage } from "provider/app-language";
import CEmpty from "components/display/c-empty";
import tw from "twin.macro";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayoutStyle = styled.div`
  ${tw`rounded-app`}
  background-color: #fff;
  box-shadow: 0px 1px 6px #0000000d;
`;

function getFromLS(key: string) {
  let ls = [];
  if (localStorage) {
    try {
      const lc = localStorage.getItem("vehicle-manage-layout");
      ls = JSON.parse(lc || "[]") || {};
    } catch (e) {}
  }
  return ls[key];
}

function saveToLS(key: string, value: any) {
  if (localStorage) {
    localStorage.setItem(
      "vehicle-manage-layout",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

const originalLayouts = getFromLS("layouts") || {};

const sDate = dayjs().startOf("month").toISOString();
const eDate = dayjs().endOf("month").toISOString();

const VehicleManagementHomePage = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<any>({
    layouts: JSON.parse(JSON.stringify(originalLayouts)),
  });

  const [params, setParams] = useState<IDashboardParams>({
    startDate: sDate,
    endDate: eDate,
  });
  const { data } = useGetVehicleDashboards(params);
  const { setAppLoading } = useAppLoading();

  const [startDate, setStartDate] = useState<string>(sDate);
  const [endDate, setEndDate] = useState<string>(eDate);

  const onLayoutChange = (layout: any, layouts: any) => {
    saveToLS("layouts", layouts);
    setState({ layouts });
  };

  const onSearch = (values: any) => {
    const startDate = values
      ? dayjs(values[0])?.startOf("day").toISOString()
      : sDate;
    const endDate = values
      ? dayjs(values[1])?.endOf("day").toISOString()
      : eDate;
    values = { ...values, startDate, endDate };
    setStartDate(startDate);
    setEndDate(endDate);
    setParams({ ...params, ...values });
  };

  const refreshPage = () => {
    setAppLoading(true);
    window.location.reload();
  };

  const onReset = () => {
    setState({ layouts: [] });
    setAppLoading(true);
    window.location.reload();
  };

  const innerHeight = window.innerHeight;
  const layout =
    innerHeight > 929
      ? Number(((innerHeight - 64 - 90 - 70) / 4).toFixed(0))
      : Number(((929 - 64 - 90 - 70) / 4).toFixed(0));

  return (
    <div className="w-full h-full">
      <Row className="h-[7%] mb-2">
        <Col span={12} className="pl-4 !flex !items-center">
          <Typography.Title level={4} style={{ color: "#425F4E" }}>
            {t("vehicle-and-parking-management")}
          </Typography.Title>
        </Col>
        <Col span={6} className="!flex !items-center">
          <CRangePicker onChange={onSearch} />
        </Col>
        <Col span={4} className="px-2 !flex !items-center">
          <CButton
            theme="yellow"
            children={t("save-to-change")}
            onClick={refreshPage}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={2} className="!flex !items-center">
          <CButton
            children={t("reset")}
            icon={<UndoOutlined />}
            onClick={onReset}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row className="h-[93%]">
        <Col span={24}>
          <ResponsiveReactGridLayout
            layouts={state.layouts}
            onLayoutChange={onLayoutChange}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            style={{ height: "100%" }}
            rowHeight={layout}
            className="layout"
          >
            <GridLayoutStyle
              key="total-vehicle"
              data-grid={{ x: 0, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                key="total-vehicle"
                title="total-vehicle-many"
                theme="deepGreen"
                value={data?.totalAllvehicle}
                valueType="cars"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="total-entrance-vehicle"
              data-grid={{ x: 3, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                title="entrance-total"
                theme="green"
                value={data?.totalInVehicle}
                valueType="cars"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="total-departure-vehicle"
              data-grid={{ x: 6, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                title="departure-total"
                theme="yellow"
                value={data?.totalOutVehicle}
                valueType="cars"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="total-avg-vehicle"
              data-grid={{ x: 9, y: 0, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <AverageOfVehicle data={data} start={startDate} end={endDate} />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="count-vehicle-in-out"
              data-grid={{ x: 0, y: 2, w: 6, h: 2, maxH: 2, minH: 2, minW: 6 }}
            >
              <CountOfVehicleInAndOut
                data={data?.graphInOutVehicle}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="count-blackList-vehicle"
              data-grid={{ x: 6, y: 2, w: 3, h: 2, maxH: 2, minH: 2, minW: 3 }}
            >
              <CountOfVehicleBlacklist
                data={data?.totalVehicleBalcklistType || []}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="top-7-vehicle"
              data-grid={{ x: 12, y: 2, w: 3, h: 3, maxH: 3, minH: 3, minW: 3 }}
            >
              <MostEnteredVehicles
                data={data?.totalAllvehicleOften || []}
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="vehicle-of-company"
              data-grid={{ x: 0, y: 3, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                title="vehicle-of-company"
                theme="deepGreen"
                value={data?.totalCompanyVehicle}
                valueType="cars"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="vehicle-continue"
              data-grid={{ x: 3, y: 3, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                title="vehicle-continue"
                theme="yellow"
                value={data?.totalVehicleParking}
                valueType="cars"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
            <GridLayoutStyle
              key="vehicle-blacklist"
              data-grid={{ x: 6, y: 3, w: 3, h: 1, maxH: 1, minW: 3 }}
            >
              <CardHomeList
                title="vehicle-blacklist"
                theme="deepGreen"
                value={data?.totalVehicleBalcklist}
                valueType="cars"
                startDate={startDate}
                endDate={endDate}
              />
            </GridLayoutStyle>
          </ResponsiveReactGridLayout>
        </Col>
      </Row>
    </div>
  );
};

const AverageOfVehicle = ({
  data,
  start,
  end,
}: {
  data?: any;
  start?: string;
  end?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const allVehicle = data?.totalAllvehicle;
  const avgIn = Number(allVehicle / data?.totalInVehicle) || 0;
  const avgOut = Number(allVehicle / data?.totalOutVehicle) || 0;
  return (
    <Row className="p-5 h-full">
      <Col span={3} className="center">
        <IconHomeStlye justify="center" align="middle" $color="yellow">
          <PieChartFilled style={{ fontSize: "18px", color: "#E0B33C" }} />
        </IconHomeStlye>
      </Col>
      <Col span={21} className="!flex !items-center">
        <Typography.Text
          ellipsis
          className="font-bold px-2"
          style={{ fontSize: "20px" }}
        >
          {t("average-all-vehicle")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <PieChartHome
          height={80}
          series={[avgIn, avgOut]}
          colors={["#438860", "#BF9C3D"]}
          labels={[t("vehicle-in"), t("vehicle-out")]}
        />
      </Col>
      <Col span={16} className="pt-3">
        <LabelStyle color="#438860" text={t("vehicle-in")} />
        <LabelStyle color="#BF9C3D" text={t("vehicle-out")} />
      </Col>
      <Col className="center">
        <MenuSchedule className="w-[22px] h-[22px]" />
      </Col>
      <Col className="px-2 center">
        <span style={{ color: "#8F949D", fontSize: "18px" }}>
          {`${formatDate({ date: start, format: "normal" })} - ${formatDate({
            date: end,
            format: "normal",
          })}`}
        </span>
      </Col>
    </Row>
  );
};

const CountOfVehicleInAndOut = ({
  data,
  startDate,
  endDate,
}: {
  data?: IGraphInOutVehicle[];
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const getCategory = () => {
    return Array(24)
      .fill(undefined)
      .map((_, i) => i++)
      .filter((e) => !(e % 2));
  };

  const categories = getCategory().map((e) =>
    e >= 10 ? `${e}:00` : `0${e}:00`
  );

  const getHour = (hour: string) => {
    return dayjs(hour, "HH:mm:ssZZ").hour();
  };

  const getIn = () => {
    const values = getCategory().map((item) => {
      const count = data?.find((e) => getHour(e?.timeInterval) === item)
        ?.totalInVehicle?.count;
      const count2 = data?.find((e) => getHour(e?.timeInterval) === item - 1)
        ?.totalInVehicle?.count;
      if (count2) return Number(count || 0) + Number(count2 || 0);
      if (count) return Number(count || 0);

      return 0;
    });

    return values;
  };

  const getOut = () => {
    const values = getCategory().map((item) => {
      const count = data?.find((e) => getHour(e?.timeInterval) === item)
        ?.totalOutVehicle?.count;
      const count2 = data?.find((e) => getHour(e?.timeInterval) === item - 1)
        ?.totalOutVehicle?.count;
      if (count2) return Number(count || 0) + Number(count2 || 0);
      if (count) return Number(count || 0);

      return 0;
    });

    return values;
  };

  return (
    <Row className="p-5 h-full">
      <Col span={24}>
        <Typography.Text
          ellipsis
          className="font-bold"
          style={{ fontSize: "20px" }}
        >
          {t("count-vehicle-in-and-out-time")}
        </Typography.Text>
      </Col>
      <Col span={1} className="center">
        <MenuSchedule className="w-[22px] h-[22px]" />
      </Col>
      <Col span={13} className="!flex items-center">
        <span style={{ color: "#9A9A9A", fontSize: "18px" }}>
          {`${formatDate({ date: startDate, format: "normal" })} - ${formatDate(
            { date: endDate, format: "normal" }
          )}`}
        </span>
      </Col>
      <Col span={5}>
        <LabelStyle color="#438860" text={t("vehicle-in")} />
      </Col>
      <Col span={5}>
        <LabelStyle color="#BF9C3D" text={t("vehicle-out")} />
      </Col>
      <Col span={24} className="!p-0">
        <LineChartHome
          height={270}
          categories={categories}
          series={[
            {
              name: t("vehicle-in"),
              data: getIn(),
            },
            {
              name: t("vehicle-out"),
              data: getOut(),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

const CountOfVehicleBlacklist = ({
  data,
  startDate,
  endDate,
}: {
  data?: any;
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  const totalVehicle = Number(data?.[0]?.totalVehicle || 0);
  const totalTruck = Number(data?.[0]?.totalTruck || 0);
  const totalLargebus = Number(data?.[0]?.totalLargebus || 0);
  const totalMotopcycle = Number(data?.[0]?.totalMotopcycle || 0);
  const sum = Number(
    totalVehicle + totalTruck + totalLargebus + totalMotopcycle
  ).toLocaleString();
  return (
    <CardHomeData
      title="count-vehicle-blacklist"
      startDate={startDate}
      endDate={endDate}
    >
      <Row className="h-full">
        <Col span={24}>
          <PieChartHome
            height={150}
            series={[totalVehicle, totalTruck, totalLargebus, totalMotopcycle]}
            colors={["#425F4E", "#438860", "#BF9C3D", "#E0B33C"]}
            labels={[t("VEHICLE"), t("TRUCK"), t("LARGERBUS"), t("MOTORCYCLE")]}
          />
        </Col>
        <Col span={24} className="text-center">
          <Typography.Title level={4} className="p-0 m-0">
            {`${sum || 0} ${t("list")}`}
          </Typography.Title>
        </Col>
        <Col span={12}>
          <LabelStyle color="#425F4E" text={t("VEHICLE")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Text style={{ color: "#9A9A9A", fontSize: "18px" }}>
            {`${totalVehicle} ${t("cars")}`}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <LabelStyle color="#438860" text={t("TRUCK")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Text style={{ color: "#9A9A9A", fontSize: "18px" }}>
            {`${totalTruck} ${t("cars")}`}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <LabelStyle color="#BF9C3D" text={t("LARGERBUS")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Text style={{ color: "#9A9A9A", fontSize: "18px" }}>
            {`${totalLargebus} ${t("cars")}`}
          </Typography.Text>
        </Col>
        <Col span={12}>
          <LabelStyle color="#E0B33C" text={t("MOTORCYCLE")} />
        </Col>
        <Col span={12} className="text-end">
          <Typography.Text style={{ color: "#9A9A9A", fontSize: "18px" }}>
            {`${totalMotopcycle} ${t("cars")}`}
          </Typography.Text>
        </Col>
      </Row>
    </CardHomeData>
  );
};

const MostEnteredVehicles = ({
  data,
  startDate,
  endDate,
}: {
  data?: ITotalAllvehicleOften[];
  startDate?: string;
  endDate?: string;
}) => {
  const { t } = useTranslation();
  return (
    <CardHomeData
      title="most-access-vehicle"
      startDate={startDate}
      endDate={endDate}
    >
      <div style={{ height: 430, overflowY: "auto", overflowX: "hidden" }}>
        {data?.length === 0 ? (
          <div style={{ height: 430 }} className="center">
            <CEmpty />
          </div>
        ) : (
          <>
            {data?.map((item, index) => {
              const firstName = item?.customerEmployee_first_name || "";
              const lastName = item?.customerEmployee_last_name || "";
              return (
                <Row key={index} gutter={[12, 6]} className="my-3">
                  <Col span={6}>
                    <CImage src="" />
                  </Col>
                  <Col span={10}>
                    {!firstName && !lastName ? (
                      <span>-</span>
                    ) : (
                      <Typography.Text
                        ellipsis={{
                          tooltip: `${t(firstName)}  ${t(lastName)}`,
                        }}
                        style={{ fontSize: "20px" }}
                      >
                        {`${t(firstName)}  ${t(lastName)}`}
                      </Typography.Text>
                    )}
                    <br />
                    <Typography.Text
                      style={{ fontSize: "20px", color: "#9A9A9A" }}
                    >
                      {t(item?.vehicle_license_plate || "-")}
                    </Typography.Text>
                  </Col>
                  <Col span={4} className="text-end">
                    <Typography.Title level={5} style={{ color: "#438860" }}>
                      {Number(item?.vehicle || 0)}
                    </Typography.Title>
                  </Col>
                  <Col span={4}>
                    <Typography.Text
                      style={{ fontSize: "20px", color: "#9A9A9A" }}
                    >
                      {t("count-time")}
                    </Typography.Text>
                  </Col>
                  <Divider className="!m-0" />
                </Row>
              );
            })}
          </>
        )}
      </div>
    </CardHomeData>
  );
};

export default VehicleManagementHomePage;
