import { TableColumnsType } from "antd";
import CTable from "components/display/c-table";
import { useAppLanguage } from "provider/app-language";
import { useVehicleReport } from "provider/vehicle-report";
import { vehicleReportChoice } from "provider/vehicle-report/interface";
import { useTranslation } from "react-i18next";
import {
  useExportVehicleReportExcel,
  useGetVehicleReport,
} from "services/vehicle-report";
import { IVehicleReport } from "services/vehicle-report/interface";

const VehicleReportContent = () => {
  const { t } = useTranslation();

  const { formatDate } = useAppLanguage();
  const { selectKeys, params } = useVehicleReport();

  const query = useGetVehicleReport(params);
  const excel = useExportVehicleReportExcel();

  const {
    firstName,
    lastName,
    create,
    license,
    color,
    brand,
    phone,
    userType,
    company,
  } = vehicleReportChoice;

  const columns: TableColumnsType<IVehicleReport> = [
    {
      title: t(create.title),
      key: create.key,
      width: 120,
      render: (_, rc) => {
        return formatDate({ date: rc.createdAt });
      },
    },
    {
      title: t(license.title),
      key: license.key,
      render: (_, rc) => {
        return rc.licensePlate;
      },
    },
    {
      title: t(color.title),
      key: color.key,
      render: (_, rc) => {
        if (!rc.color) return "-";
        return rc.color;
      },
    },
    {
      title: t(brand.title),
      key: brand.key,
      render: (_, rc) => {
        if (!rc.brand) return "-";
        return rc.brand;
      },
    },
    {
      title: t(firstName.title),
      key: firstName.key,
      render: (_, rc) => {
        if (!rc.customerEmployee?.firstName) return "-";
        return rc.customerEmployee.firstName;
      },
    },
    {
      title: t(lastName.title),
      key: lastName.key,
      render: (_, rc) => {
        if (!rc.customerEmployee?.lastName) return "-";
        return rc.customerEmployee.lastName;
      },
    },
    {
      title: t(phone.title),
      key: phone.key,
      render: (_, rc) => {
        if (!rc.customerEmployee?.phoneNumber) return "-";
        return rc.customerEmployee.phoneNumber;
      },
    },
    {
      title: t(userType.title),
      key: userType.key,
      render: (_, rc) => {
        if (!rc.customerEmployee?.userType) return "-";
        return t(rc.customerEmployee.userType.toLocaleLowerCase());
      },
    },
    {
      title: t(company.title),
      key: company.key,
      render: (_, rc) => {
        if (!rc.customerEmployee?.companyName) return "-";
        return rc.customerEmployee.companyName;
      },
    },
  ];

  return (
    <CTable
      title={t("list")}
      action={{
        excels: {
          export: { mutation: excel, extraValue: { topic: selectKeys } },
        },
      }}
      query={query}
      selectAble={false}
      wrapperClassName="!mt-0"
      columns={columns.filter((e) => selectKeys.some((x) => e.key === x))}
      scroll={{ x: 1000 }}
    />
  );
};

export default VehicleReportContent;

// const arr = ['vehicle.createdAt', 'customerEmployee.id', 'customerEmployee.companyName']
