import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ICheckQuickLab, ICheckQuickLabParams } from "./interface";

export const useGetCheckQuicklabs = (
  params?: ICheckQuickLabParams
): UseQueryResult<IResponse<ICheckQuickLab[]>> => {
  return useQuery(
    ["get-check-quicklabs", params],
    async () => {
      const res = await axios.get("/check-quicklab", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetCheckQuicklab = (
  id?: string | number
): UseQueryResult<ICheckQuickLab> => {
  return useQuery(
    ["get-check-quicklab", id],
    async () => {
      const res = await axios.get(`/check-quicklab/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePatchCheckQuicklab = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/check-quicklab/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportCheckQuicklab = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/vehicle/check-quicklab-export-excel`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
