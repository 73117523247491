import { TableColumnsType, Typography } from "antd";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteVehicleCompany,
  useDeleteVehicleCompanys,
  useExportExcelVehicleCompany,
  useGetVehicleCompanys,
} from "services/vehicle-company";
import {
  IVehicleCompany,
  IVehicleCompanyParams,
} from "services/vehicle-company/inteface";

const VehicleRegisteredVehicle = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const [params, setParams] = useState<IVehicleCompanyParams>({
    page: 1,
    limit: 10,
  });

  const query = useGetVehicleCompanys(params);
  const del = useDeleteVehicleCompany();
  const bulk_del = useDeleteVehicleCompanys();
  const export_excel = useExportExcelVehicleCompany();

  const elements: ISearchBoxElement[] = [
    {
      name: "licensePlate",
      label: t("license-plate-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    // {
    //   name: "vehicleType",
    //   label: t("vehicle-type"),
    //   input: {
    //     type: "Input",
    //     options: {
    //       search: true,
    //     },
    //   },
    // },
    {
      name: "vehicleBrand",
      label: t("vehicle-brand"),
      input: {
        type: "SelectVehicleBrand",
      },
    },
    {
      name: "color",
      label: t("color"),
      input: {
        type: "Input",
      },
    },
    // {
    //   name: 'entryDate',
    //   label: t('entry-date'),
    //   input: {
    //     type: ''
    //   }
    // },
    // {
    //   name: "provincePlate",
    //   label: t("plate-province"),
    //   input: {
    //     type: "Input",
    //   },
    // },
  ];

  const columns: TableColumnsType<IVehicleCompany> = [
    {
      title: t("license-plate-number"),
      dataIndex: "licensePlate",
    },
    {
      title: t("created-at"),
      render: (_, rc) => {
        const date = formatDate({ date: rc.createdAt, format: "normal-time" });
        return <Typography.Text>{date}</Typography.Text>;
      },
    },
    // {
    //   title: t("entry-date"),
    //   render: (_, rc) => {
    //     return "-";
    //     // if (!rc.provincePlate) return "-";
    //     // return <Typography.Text>{rc.provincePlate}</Typography.Text>;
    //   },
    // },
    // {
    //   title: t("exit-date"),
    //   render: (_, rc) => {
    //     return "-";
    //     // if (!rc.vehicleBrand) return "-";
    //     // return <Typography.Text>{rc.vehicleBrand}</Typography.Text>;
    //   },
    // },
    // {
    //   title: t("vehicle-type"),
    //   render: (_, rc) => {
    //     if (!rc.vehicleType) return "-";
    //     return <Typography.Text>{rc.vehicleType}</Typography.Text>;
    //   },
    // },
    {
      title: t("vehicle-brand"),
      render: (_, rc) => {
        if (!rc.vehicleBrand) return "-";
        return <Typography.Text>{rc.vehicleBrand}</Typography.Text>;
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const { firstName, lastName } = rc.employee || {};
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text>
            {firstName} {lastName}
          </Typography.Text>
        );
      },
    },
    // {
    //   title: t("car-weight"),
    //   render: (_, rc) => {
    //     if (!rc.weight) return "-";
    //     return <Typography.Text>{rc.weight}</Typography.Text>;
    //   },
    // },
    // {
    //   title: t("trailer-weight"),
    //   render: (_, rc) => {
    //     if (!rc.trailerWeight) return "-";
    //     return <Typography.Text>{rc.trailerWeight}</Typography.Text>;
    //   },
    // },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values, page: 1 });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        query={query}
        columns={columns}
        title={t("registered-vehicle-list")}
        onAdd="vehicle-management.registered-vehicle-add"
        onRowClick="vehicle-management.registered-vehicle-edit"
        action={{
          excels: { export: { mutation: export_excel } },
          bulkDelete: {
            mutation: bulk_del,
            invalidateQueries: ["vehicle-company"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: del,
          invalidateQueries: ["vehicle-company"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default VehicleRegisteredVehicle;
