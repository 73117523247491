import { FormInstance, Form, Col, Typography } from "antd";
import CInput from "components/input/c-input";
import CTextArea from "components/input/c-input/text-area";
import CRadio from "components/input/c-radio";
import CSelect from "components/input/c-select";
import CUpload from "components/input/c-upload";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TMenu } from "../create-user-pdpa";
import { IPickupPoint } from "services/master/interface";

const ContactInformation = ({
  menu,
  pending,
  form,
}: {
  menu: TMenu;
  pending: boolean;
  form: FormInstance;
}) => {
  const { t } = useTranslation();
  const wProductList = Form.useWatch("haveItem");
  const wContactType = Form.useWatch("subject");
  const department = Form.useWatch("department", form);
  const pickupPoint = Form.useWatch("pickupPoint")
  const [stateOpt, setStateOpt] = useState<IPickupPoint[]>()

  useEffect(() => {
    if (!stateOpt?.length) return
    const scale = stateOpt.find(e => e.id === pickupPoint)?.scales
    form.setFieldValue("scale", scale?.scalesDescription)
  }, [pickupPoint])

  const workPermitLabel = (
    <React.Fragment>
      <span>{t("upload-image-files")}</span>
      <span className="text-red-500 ml-2">
        {t("maximum-image-file-size-20-mb-image", { ns: "message" })}
      </span>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Col span={24} className="mb-4">
        <Typography.Text className="!text-primary-dark">
          {t("contact-information")}
        </Typography.Text>
      </Col>
      <Col>
        <Form.Item label={t("product-list")} name="haveItem">
          <CRadio.Group
            optionType="default"
            options={[
              { label: t("do-not-have"), value: false },
              { label: t("have"), value: true },
            ]}
            disabled={pending}
          />
        </Form.Item>
      </Col>
      {wProductList && [
        <Col span={24}>
          <Form.Item
            label={t("details")}
            name="itemDescription"
            rules={[{ required: wProductList }]}
          >
            <CInput.TextArea
              rows={4}
              placeholder={t("details")}
              disabled={pending}
            />
          </Form.Item>
        </Col>,
        <Col span={24}>
          <Form.Item
            name="itemPictures"
            label={workPermitLabel}
            rules={[{ required: wProductList }]}
          >
            <CUpload.List
              disabled={pending}
              accepts={[".jpeg", ".jpg", ".png"]}
            />
          </Form.Item>
        </Col>,
      ]}
      <Col span={24}></Col>
      <Col span={8}>
        <Form.Item
          label={t("department")}
          rules={[{ required: true }]}
          name="department"
        >
          <CSelect.Department
            queryParams={{ departmentType: "EMPLOYEE" }}
            onChange={(e) => {
              form.setFieldValue("employee", undefined);
            }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="employee" label={t("contact-staff-name")}>
          <CSelect.Employee
            placeholder={t("contact-staff-name")}
            disabled={pending || !department}
            queryParams={{ department, enabled: !!department }}
          />
        </Form.Item>
      </Col>
      {menu === "visitor" && (
        <Col span={8}>
          <Form.Item
            name="contactDepartment"
            label={t("contact-by-department")}
            rules={[{ required: true }]}
          >
            <CSelect
              placeholder={t("contact-by-department")}
              valueOptions={{
                values: [
                  { id: "meeting-seminar", title: t("meeting-seminar") },
                  {
                    id: "offer-products-services",
                    title: t("offer-products-services"),
                  },
                  { id: "audit", title: t("audit") },
                  {
                    id: "apply-for-job-interview",
                    title: t("apply-for-job-interview"),
                  },
                  { id: "factory-visit", title: t("factory-visit") },
                  { id: "special-contractor", title: t("special-contractor") },
                ],
                valueKey: "id",
                titleKey: "title",
              }}
            />
          </Form.Item>
        </Col>
      )}
      {menu !== "supplier" && menu !== "visitor" && (
        <Col span={8}>
          <Form.Item
            name="contactDepartment"
            label={t("contact-by-department")}
          >
            <CInput
              placeholder={t("contact-by-department")}
              disabled={pending}
            />
          </Form.Item>
        </Col>
      )}

      {menu === "supplier" ? (
        <>
          <Col span={8}>
            <Form.Item
              rules={[{ required: menu === "supplier" }]}
              name="supplierJobType"
              label={t("job-type-supplier")}
            >
              <CSelect.JobSupplier
                placeholder={t("job-type-supplier")}
                disabled={pending}
                mode="multiple"
              />
            </Form.Item>
          </Col>


          <NumberPO />
          {/* <TypeOfProduct /> */}
          <StatusWorking />

        </>
      ) : null}
      <Col span={16}>
        <Form.Item label={t("contact-type")} name="subject">
          <CRadio.Group
            disabled={pending}
            optionType="default"
            options={[
              {
                label: t("pick-up"),
                value: "PICKUP",
              },
              {
                label: t("deliver"),
                value: "DELIVER",
              },
              {
                label: t("coordination"),
                value: "CONTACT",
              },
              {
                label: t("apply-for-work"),
                value: "APPLY_JOB",
              },
              {
                label: t("meeting"),
                value: "MEETING",
              },
              {
                label: t("working"),
                value: "WORKING",
              },
              {
                label: t("other"),
                value: "OTHER",
              },
            ]}
          />
        </Form.Item>
      </Col>
      <Col span={8}></Col>
      <Col span={24} hidden={wContactType !== "OTHER"}>
        <Form.Item
          name="subjectDescription"
          rules={[{ required: wContactType === "OTHER" }]}
          label={t("other")}
        >
          <CInput.TextArea
            rows={4}
            disabled={wContactType !== "OTHER" || pending}
            placeholder={t("other")}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="description" label={t("note")}>
          <CTextArea rows={5} placeholder={t("note")} />
        </Form.Item>
      </Col>
      {menu === "supplier" && (
        <>
          <Col span={24} className="mb-4">
            <Typography.Text className="!text-primary-dark">
              {t("erp-information")}
            </Typography.Text>
          </Col>
          <Col span={8}>
            <Form.Item label={t("type-erp")} name="rgnoType">
              <CSelect.ErpType disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("product-group")} name="productType">
              <CSelect.ErpProduct disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("transaction-type")} name="transactionType">
              <CSelect.ErpTransactionType disabled={pending} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={t("pickup-point")}
              name="pickupPoint"
            >
              <CSelect.ErpPickupPoint
                placeholder={t("pickup-point")}
                disabled={pending}
                // onChange={(e, opt: IPickupPoint) => {
                //   form.setFieldValue("scale", opt.scales.scalesDescription);
                // }}
                setStateOptions={setStateOpt}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="scale" label={t("scale")}>
              <CInput
                placeholder={t("scale")}
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("bp-code")}
              rules={[{ required: false }]}
              name="businessPartner"
            >
              <CSelect.ErpBusinessPartner />
            </Form.Item>
          </Col>
        </>
      )}

    </React.Fragment>
  );
};

const NumberPO = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={8}>
        <Form.Item label={t("po-number")} name="poNo">
          <CInput placeholder={t("po-number")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const TypeOfProduct = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={8}>
        <Form.Item label={t("product-type")} name="productType">
          <CInput placeholder={t("product-type")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const StatusWorking = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col span={8}>
        <Form.Item label={t("status")} name="status">
          <CSelect
            placeholder={t("status")}
            valueOptions={{
              values: [
                { label: t("is-working"), value: true },
                { label: t("not-working"), value: false },
              ],
              valueKey: "value",
              titleKey: "label",
            }}
          />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};
export default ContactInformation;
