import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { postMediaObject } from "services/media-object";
import { IReceiver, IReceiverParams } from "./interface";

export const useGetReceivers = (
  params?: IReceiverParams
): UseQueryResult<IResponse<IReceiver[]>> => {
  return useQuery(
    ["get-receivers", params],
    async () => {
      const res = await axios.get("/receiver", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetReceiver = (
  id?: string | number
): UseQueryResult<IReceiver> => {
  return useQuery(
    ["get-receiver", id],
    async () => {
      const res = await axios.get(`/receiver/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostReceiver = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    data = await appendImages(data);
    const res = await axios.post("/receiver", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchReceiver = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    data = await appendImages(data);
    const res = await axios.patch(`/receiver/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteReceiver = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/receiver/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteReceivers = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.delete(`/receiver/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

const appendImages = async (values: any): Promise<any> => {
  let { profile, identityCardPicture, profiles, ...rest } = values || {};
  if (profile && typeof profile === "object") {
    profile = await postMediaObject(profile);
    rest = { ...rest, profile };
  }

  if (identityCardPicture && typeof identityCardPicture === "object") {
    identityCardPicture = await postMediaObject(identityCardPicture);
    rest = { ...rest, identityCardPicture };
  }

  if (profiles?.length < 1) {
    rest = { ...rest, profiles: [] };
  }

  if (profiles && profiles?.length > 0) {
    const itemPicturesList: number[] = await Promise.all(
      profiles.map(async (item: any): Promise<number> => {
        let id: number;
        if (!item.id) {
          id = await postMediaObject(item);
        } else {
          id = item.id;
        }
        return id;
      })
    );
    rest = { ...rest, profiles: itemPicturesList };
  }

  return rest;
};

export const useExportTemplateReceivers = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/template/receiver`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportExcelReceivers = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/receiver/import/receiver`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useImportProfileReceivers = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    data = await appendImages(data);
    const res = await axios.post("/receiver/upload-profile", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
