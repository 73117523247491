import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { postMediaObject } from "services/media-object";
import { IOrganization, IOrganizationParams } from "./interface";

export const useGetOrganizations = (
  params?: IOrganizationParams
): UseQueryResult<IResponse<IOrganization[]>> => {
  return useQuery(
    ["get-organizations", params],
    async () => {
      const res = await axios.get("/organization", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetOrganization = (
  id?: number | string
): UseQueryResult<IOrganization> => {
  return useQuery(
    ["get-organization", id],
    async () => {
      const res = await axios.get(`/organization/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostOrganization = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    data = await appendImages(data);
    const res = await axios.post("/organization", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchOrganization = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    data = await appendImages(data);
    const res = await axios.patch(`/organization/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteOrganization = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/organization/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteOrganizations = (): UseMutationResult => {
  return useMutation(async (data) => {
    const res = await axios.delete(`/organization/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

const appendImages = async (values: any): Promise<any> => {
  let { profile, ...rest } = values || {};
  if (profile && typeof profile === "object") {
    profile = await postMediaObject(profile);
    rest = { ...rest, profile };
  }
  return rest;
};

export const useGetScrollCompany = (
  params?: IOrganizationParams
): UseInfiniteQueryResult<IResponse<IOrganization[]>, Error> => {
  return useInfiniteQuery(
    ["get-users", params],
    async ({ pageParam }) => {
      const res = await axios.get("/organization", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};
