import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import {
  ICustomerCheckin,
  ICustomerCheckinParams,
  IIdCardPDK,
  IIdPassportMRZ,
} from "./interface";

export const usePostIdCard = (): UseMutationResult<IIdCardPDK> => {
  return useMutation(async () => {
    const res = await axios.post("http://localhost:5000/api/PDK");
    if (res.status === 200) {
      return res.data[0];
    }
    throwResponse(res);
  });
};

export const usePostIdPassport = (): UseMutationResult<IIdPassportMRZ> => {
  return useMutation(async (comPort) => {
    const res = await axios.post("http://localhost:5000/api/MRZ",comPort);
    if (res.status === 200) {
      return res.data[0];
    }
    throwResponse(res);
  });
};

export const usePostSerialPort = (
): UseQueryResult<ICustomerCheckin[]> => {
  return useQuery(
    ["get-serial-port",],
    async () => {
      const res = await axios.get("http://localhost:5000/api/serial-port", {
      });
      if (res.status === 200) {
        return res.data[0].serialPort;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetCustomerCheckins = (
  identityCode?: ICustomerCheckinParams
): UseQueryResult<ICustomerCheckin[]> => {
  return useQuery(
    ["get-customer-checkins", identityCode],
    async () => {
      const res = await axios.get("/customer-checkin/find-by-identity-code", {
        params: {...identityCode},
      });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const usePostCustomerCheckins = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/customer-checkin", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
