import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectAcceptStatus: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("status")}
      {...props}
      valueOptions={{
        values: [
          { title: t("approve"), value: "APPROVE" },
          { title: t("disapproval"), value: "REJECT" },
          { title: t("approval-pending"), value: "PENDING" },
        ],
        valueKey: "value",
        titleKey: "title",
      }}
    />
  );
};

export default CSelectAcceptStatus;
