import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IEncodeDevice } from "services/encode-device/interface";
import { postMediaObject } from "services/media-object";
import {
  IFloor,
  IFloorDeviceParams,
  IFloorParams,
  IFloorRoot,
  IParkingCount,
  IParkingCountParams,
} from "./interface";

export const useFloorScroll = (
  params?: IFloorParams
): UseInfiniteQueryResult<IResponse<IFloor[]>, Error> => {
  return useInfiniteQuery(
    ["get-floors", params],
    async ({ pageParam }) => {
      const res = await axios.get("/floor", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};

export const useFloorRoot = (
  params?: IFloorParams
): UseQueryResult<IFloorRoot[]> => {
  return useQuery(
    ["get-floors", "root", params],
    async () => {
      const res = await axios.get("/floor/root", { params });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};

export const useGetFloors = (
  params?: IFloorParams
): UseQueryResult<IResponse<IFloor[]>, Error> => {
  return useQuery(
    ["get-floors", params],
    async () => {
      const res = await axios.get("/floor", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled }
  );
};

export const useGetFloor = (
  id?: string | number
): UseQueryResult<IFloor, Error> => {
  return useQuery(
    ["get-floor", id],
    async () => {
      const res = await axios.get(`/floor/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostFloor = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post("/floor", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchFloor = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, mediaObject, ...data }: any) => {
    if (mediaObject && typeof mediaObject === "object") {
      mediaObject = await postMediaObject(mediaObject);
      data = { ...data, mediaObject };
    }
    if (mediaObject === null) {
      data = { ...data, mediaObject };
    }
    const res = await axios.patch(`/floor/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteFloor = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/floor/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetAvailableDevice = (params?: {
  devName?: string;
}): UseQueryResult<IResponse<IEncodeDevice[]>> => {
  return useQuery(["get-encode-devices", params], async () => {
    const res = await axios.get("/floor/device-available", { params });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetDeviceInFloor = (
  params?: IFloorDeviceParams
): UseQueryResult<IResponse<IEncodeDevice[]>> => {
  return useQuery(
    ["get-encode-devices", params],
    async () => {
      const res = await axios.get(`/floor/${params?.id}/device-in-floor`, {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: !!params?.id }
  );
};

export const useDeleteDeviceFromFloor = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/floor/${id}/remove-device`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useAddDeviceToFloor = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/floor/${id}/add-device`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportExcelParentFloor = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      "/export-excel/floor/summary-report-excel",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportExcelChildrenFloor = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      "/export-excel/floor/floor-children-report-excel",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useParkingFloorCount = (
  params?: IParkingCountParams
): UseQueryResult<IParkingCount, Error> => {
  return useQuery(
    ["parking-count", params],
    async () => {
      if (params?.parentFloorId) {
        params = { ...params, floorId: undefined };
      }
      const res = await axios.post(`/floor/count-in-parking`, null, { params });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      enabled: params?.enabled,
      keepPreviousData: true,
    }
  );
};
