import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectGender: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("gender")}
      {...props}
      valueOptions={{
        values: [
          { id: "MALE", title: t("male") },
          { id: "FEMALE", title: t("female") },
        ],
        valueKey: "id",
        titleKey: "title",
      }}
    />
  );
};

export default CSelectGender;
