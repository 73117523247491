import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetEmployees } from "services/employee";
import CSelect, { CSelectProps } from ".";
import { IEmployeeParams } from "services/employee/interface";

export interface CSelectEmployeeProps extends CSelectProps {
  queryParams?: IEmployeeParams;
}

const CSelectEmployee: FC<CSelectEmployeeProps> = ({
  queryParams,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelect.Pagination
      {...props}
      useQuery={useScrollGetEmployees}
      valueKey="id"
      titleKey="fullName"
      searchKey="name"
      placeholder={t("employee")}
      queryParams={{ ...queryParams, pagination: false }}
    />
  );
};

export default CSelectEmployee;
