import { Spin } from "antd";
import CIndicator from "components/display/c-indicator";
import CSpyTab, { CSpyTabProps } from "components/display/c-scroll-spy";
import { FC } from "react";
import { CSSProperties } from "react";
import { ReactNode } from "react";
import styled from "styled-components";
import Breadcrumb, { BreadcrumbProps } from "./bread-crumb";

interface PageContentProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
  breadcrumb?: BreadcrumbProps | boolean;
  scrollSpy?: CSpyTabProps | boolean;
}

const ContainerWrapper = styled.div`
  background-color: #f5f5f5;
  height: 100%;
  position: relative;
`;

const BodyWrapper = styled.div`
  // height: calc(100vh - 178px);
  // overflow-y: auto;
`;

const PageContent: FC<PageContentProps> = ({
  className,
  style,
  loading = false,
  children,
  breadcrumb = false,
  scrollSpy = false
}) => {
  const options = typeof breadcrumb === "boolean" ? {} : breadcrumb;
  const spyOptions = typeof scrollSpy === 'boolean' ? {} : scrollSpy;

  return (
    <ContainerWrapper>
      {breadcrumb ? <Breadcrumb {...options} /> : null}
      {scrollSpy ? <CSpyTab {...spyOptions} /> : null}
      <Spin spinning={loading} indicator={<CIndicator />}>
        <BodyWrapper className={className} style={style}>
          {children}
        </BodyWrapper>
      </Spin>
    </ContainerWrapper>
  );
};

export default PageContent;
