import { useQueryClient } from "@tanstack/react-query";
import { Divider, Form, TableColumnsType, Typography } from "antd";
import CAvatar from "components/display/c-avatar";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import { ISearchBoxElement } from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import { useAccessibilityDeterminedGroupOfPersons } from "provider/accessibility-determined-by-group-persons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetGroupInGroup,
  useGetUserGroupNotInGroup,
  usePatchRemoveUser,
  usePostUserToGroup,
} from "services/user-group";
import {
  IUserGroupInGroup,
  IUserGroupInGroupParams,
  IUserGroupNotInGroup,
  IUserGroupNotInGroupParams,
} from "services/user-group/interface";

const GroupContent = () => {
  const { t } = useTranslation();
  const { userGroup } = useAccessibilityDeterminedGroupOfPersons();
  const [params, setParams] = useState<IUserGroupInGroupParams>({});

  var get_user_group = useGetGroupInGroup({
    ...params,
    groupId: userGroup?.id,
    slug: userGroup?.slug
  });


  const patch_remove_user = usePatchRemoveUser();
  const qClient = useQueryClient();

  const [visible, setVisible] = useState<boolean>(false);

  const toggle = () => {
    setVisible(!visible);
  };

  if (!userGroup) {
    return (
      <div style={{ height: "calc(100vh - 218px)" }} className="center">
        {t("please-select")}
      </div>
    );
  }

  const elements: ISearchBoxElement[] = [
    {
      name: "userType",
      label: t("person-type"),
      input: {
        type: "SelectUserType",
      },
    },
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
      },
    },
  ];

  const columns: TableColumnsType<IUserGroupInGroup> = [
    {
      title: t("profile-picture"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url} />;
      },
    },
    {
      title: t("identification-card-number"),
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis>{rc?.identityCode || "-"}</Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        if (!rc?.phoneNumber) return "-";
        return (
          <Typography.Text ellipsis>{rc?.phoneNumber || "-"}</Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      render: (_, rc) => {

        if (rc.userType == null) {
          return <Typography.Text>{ t("employee")}</Typography.Text>;
        } else {
          const type =
            rc?.userType === "VISITOR"
              ? t("guests")
              : rc?.userType === "CONTRACTOR"
                ? t("contractor-full-time")
                : rc?.userType === "SUPPLIER"
                  ? t("supplier-deliver-goods")
                  : t("consignee");

          return <Typography.Text>{type || "-"}</Typography.Text>;
        }

      },
    },
  ];

  const onRemove = (value: any) => {
    patch_remove_user.mutate(
      { id: userGroup?.id, users: value },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "user-management" });
          qClient.invalidateQueries(["get-user-group-in-group"]);
          qClient.invalidateQueries(["get-user-group-not-in"]);
          if (
            (get_user_group?.data?.data?.length || 0) <= 1 ||
            value?.length >= (get_user_group?.data?.data?.length || 0)
          ) {
            setParams({ ...params, page: 1 });
          }
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "user-management",
          });
        },
      }
    );
  };

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  return (
    <Container height="auto" padding="p-0">
      <CreateAndEditDrawer visible={visible} toggle={toggle} />
      <Divider className="!m-0" />
      <CTable
        shadow={false}
        title={t("list-all")}
        columns={columns}
        onAdd={{ onClick: toggle }}
        query={get_user_group}
        action={{
          searchBox: { elements: elements, onFinish: onSearch },
        }}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        extraHeaderButton={[
          {
            children: t("remove-access"),
            theme: "gray",
            onClick: (_, selectRowsKey) => {
              onRemove(selectRowsKey);
            },
          },
        ]}
      />
    </Container>
  );
};

const CreateAndEditDrawer = ({
  visible,
  toggle,
}: {
  visible: boolean;
  toggle: () => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { userGroup } = useAccessibilityDeterminedGroupOfPersons();

  const [params, setParams] = useState<IUserGroupNotInGroupParams>({});

  const { data, isFetching } = useGetUserGroupNotInGroup({
    ...params,
    wait: !visible,
  });

  const qClient = useQueryClient();
  const post_user_to_group = usePostUserToGroup();

  const columns: TableColumnsType<IUserGroupNotInGroup> = [
    {
      title: t("profile-picture"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url} />;
      },
    },
    {
      title: t("identification-card-number"),
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis>{rc?.identityCode || "-"}</Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        if (!rc.phoneNumber) return "-";
        return (
          <Typography.Text ellipsis>{rc?.phoneNumber || "-"}</Typography.Text>
        );
      },
    },
  ];

  const onFinish = (values: any) => {
    post_user_to_group.mutate(
      { id: userGroup?.id, users: values.select },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "user-management" });
          qClient.invalidateQueries(["get-user-group-in-group"]);
          qClient.invalidateQueries(["get-user-group-not-in"]);
          qClient.invalidateQueries(["get-user-groups"]);
          form.resetFields();
          toggle();
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "user-management",
          });
        },
      }
    );
  };

  const onSearch = (e: any) => {
    setParams({ ...params, search: e.target.value });
  };

  const Close = () => {
    toggle();
  };

  return (
    <CDrawer
      title={t("add")}
      form={form}
      visible={visible}
      onClose={Close}
      onCancel={Close}
      onSave={onFinish}
      width="40vw"
      loading={post_user_to_group?.isLoading}
    >
      <Form form={form} layout="vertical">
        <Form.Item label={t("search")} name="search">
          <CInput onChange={onSearch} />
        </Form.Item>
        <Form.Item name="select">
          <CTable.Select
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isFetching}
            pagination={{
              total: data?.count,
              current: params?.page,
              pageSize: params.limit,
              onChange: (page, limit) => {
                setParams({ ...params, page, limit });
              },
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default GroupContent;
