import { IDoorwayEmergency, IDoorwayEmergencyParams } from "./interface";
import axios, { throwResponse } from "config/axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
} from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";
import { UseQueryResult } from "@tanstack/react-query";

export const useGetDoorwayEmergency = (
  params: IDoorwayEmergencyParams
): UseQueryResult<IResponse<IDoorwayEmergency[]>> => {
  return useQuery(
    ["get-doorway-emergencys", params],
    async () => {
      const res = await axios.get("/doorway-emergency", { params });
      if (res.status === 200) {
        const data = res.data.data.map((item: any) => {
          if (item.detectImage)
            item.detectImage = {
              ...item.detectImage,
              licensePlateImage: { url: item.detectImage?.url },
            };
          return item;
        });
        return { data: data };
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostDoorwayWithBarrier = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ doorway, barrier }: any) => {
    const dwe = await axios.post("/doorway-emergency", doorway);
    if (dwe.status !== 200) {
      throwResponse(dwe);
    }
    const bgc = await axios.post("/barrier-gate-control/action", barrier);
    if (bgc.status !== 200) {
      throwResponse(bgc);
    }
    return;
  });
};

export const useGetDoorwayEmergencyCount = (
  params?: IDoorwayEmergencyParams
): UseQueryResult<IResponse<IDoorwayEmergency[]>> => {
  return useQuery(["get-vehicle-detects", params], async () => {
    const res = await axios.get("/doorway-emergency", { params });
    if (res.status === 200) {
      return res.data;
    }
  });
};

export const useExportDoorwayEmergency = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/export-excel/doorwer-emergancy-report-excel",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
