import { usePageRoute } from "provider/page-route";
import UserWithPDPACreateAndEditPage from "components/menu/user-management/create-user-pdpa";
import {
  useGetSupplier,
  usePatchSupplier,
  usePostSupplier,
} from "services/supplier";

const UserSupplierCreateAndEditPage = () => {
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const post_supplier = usePostSupplier();
  const patch_supplier = usePatchSupplier();
  const data = useGetSupplier(id);

  return (
    <UserWithPDPACreateAndEditPage
      menu="supplier"
      action={{
        get: data,
        post: post_supplier,
        patch: patch_supplier,
        invalidateQueries: ["get-suppliers", "get-supplier"],
      }}
    />
  );
};

export default UserSupplierCreateAndEditPage;
