import { Form } from "antd";
import CDrawer from "components/display/c-drawer";
import CInput from "components/input/c-input";
import CSwitch from "components/input/c-switch";
import { useVehicleFloor } from "provider/vehicle-floor";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePatchFloor, usePostFloor } from "services/floor";
import { IFloor } from "services/floor/interface";

interface CreateAndEditFloorDrawerProps {
  visible: boolean;
  onClose: () => void;
  initialValue?: IFloor;
}

const CreateAndEditFloorDrawer: FC<CreateAndEditFloorDrawerProps> = ({
  visible,
  onClose,
  initialValue,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const post_floor = usePostFloor();
  const patch_floor = usePatchFloor();

  const { levelId, selectedParking, selectedFloor } = useVehicleFloor();

  useEffect(() => {
    if (!initialValue) return;
    form.setFieldsValue({
      carReq: true,
      motorReq: true,
      ...initialValue,
      // floorName: initialValue.floorName,
      // carCapacity: initialValue.carCapacity,
      // carUnoccupied: initialValue.carUnoccupied,
      // motorCapacity: initialValue.motorCapacity,
      // motorUnoccupied: initialValue.motorUnoccupied,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onBeforeSubmit = (values: any) => {
    if (initialValue) {
      return { ...values, mediaObject: initialValue.mediaObject?.url };
    }
    return {
      ...values,
      parking: selectedParking?.id,
      menuLevel: levelId,
      parentFloor: selectedFloor?.id,
    };
  };

  return (
    <CDrawer
      form={form}
      title={t("add-floor")}
      visible={visible}
      onClose={onClose}
      mutation={{
        mutate: initialValue ? patch_floor : post_floor,
        invalidateQueries: ["get-floors", "get-floor", "parking-count"],
        onBeforeSubmit: onBeforeSubmit,
      }}
      resetFormAfterClose={initialValue ? false : true}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          carReq: true,
          carCapacity: 0,
          carUnoccupied: 0,
          motorReq: true,
          motorCapacity: 0,
          motorUnoccupied: 0,
        }}
      >
        <Form.Item name="id" hidden>
          <CInput />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="floorName"
          label={t("floor-name")}
        >
          <CInput placeholder={t("floor-name")} />
        </Form.Item>
        <InputWithSwitchField type="motor" />
        <InputWithSwitchField type="car" />
      </Form>
    </CDrawer>
  );
};

const InputWithSwitchField: FC<{ type: "motor" | "car" }> = ({ type }) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();

  const switchKey = `${type}Req`;
  const capacityKey = `${type}Capacity`;
  const availableKey = `${type}Unoccupied`;

  const wSwitch = Form.useWatch(switchKey);

  return (
    <React.Fragment>
      <Form.Item
        label={t(type === "motor" ? "motorcycle" : "car")}
        name={switchKey}
        labelAlign="right"
        valuePropName="checked"
        getValueFromEvent={(e) => {
          if (!e) {
            form.setFieldsValue({ [capacityKey]: 0, [availableKey]: 0 });
          }
          return e;
        }}
      >
        <CSwitch />
      </Form.Item>
      <Form.Item
        rules={[{ required: !!wSwitch }]}
        label={t("capacity")}
        name={capacityKey}
      >
        <CInput.Number
          disabled={!wSwitch}
          min={0}
          placeholder={t("capacity")}
        />
      </Form.Item>
      <Form.Item
        rules={[{ required: !!wSwitch }]}
        label={t("available-parking-area")}
        name={availableKey}
      >
        <CInput.Number
          disabled={!wSwitch}
          min={0}
          placeholder={t("available-parking-area")}
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default CreateAndEditFloorDrawer;
