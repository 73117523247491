import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IResponseStatus,
  IVehicleBroken,
  IVehicleBrokenParams,
  IVehicleQueue,
  IVehicleQueueLog,
  IVehicleQueueParams,
} from "./interface";

export const useGetVehiclesQueues = (
  params?: IVehicleQueueParams
): UseQueryResult<IResponseStatus<IVehicleQueue[]>> => {
  return useQuery(
    ["get-vehicles-queues", params],
    async () => {
      const res = await axios.get("/vehicle/vehicle-queue", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled, keepPreviousData: true }
  );
};

export const useGetVehiclesQueue = (
  id?: string | number
): UseQueryResult<IVehicleQueue> => {
  return useQuery(
    ["get-vehicles-queue", id],
    async () => {
      const res = await axios.get(`/vehicle/${id}/vehicle-queue`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetVehiclesQueueLog = (
  id?: string | number
): UseQueryResult<IVehicleQueueLog> => {
  return useQuery(
    ["get-vehicles-queue-log", id],
    async () => {
      const res = await axios.get(`/vehicle/${id}/vehicle-queue-log`);
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostVehiclesBroken = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/vehicle-disrepair", data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

//vehicle-disrepair
export const useGetVehiclesBrokens = (
  params?: IVehicleBrokenParams
): UseQueryResult<IResponse<IVehicleBroken[]>> => {
  return useQuery(
    ["get-vehicles-brokens", params],
    async () => {
      const res = await axios.get("/vehicle-disrepair", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled, keepPreviousData: true }
  );
};

export const useGetVehiclesBroken = (
  id?: string | number
): UseQueryResult<IVehicleBroken> => {
  return useQuery(
    ["get-vehicles-broken", id],
    async () => {
      const res = await axios.get(`/vehicle-disrepair/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useExportVehiclesQueue = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/vehicle/queue-report-export-excel`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useExportVehiclesBroken = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/vehicle/disrepair-report-export-excel`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
