import { Progress, ProgressProps } from "antd";
import { FC } from "react";

const colors = {
  full: { "0%": "#f87171", "100%": "#dc2626" },
  normal: { "0%": "#2F9A5D", "100%": "#57BC82" },
};

const CProgress: FC<ProgressProps> = (props) => {
  return (
    <Progress
      strokeColor={props.percent === 100 ? colors.full : colors.normal}
      status={props.percent === 100 ? "exception" : "normal"}
      format={(percent) => {
        return `${percent}%`;
      }}
      {...props}
    />
  );
};

export default CProgress;
