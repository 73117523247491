import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import withAccessibilityDeterminedByGroup, {
  useAccessibilityDeterminedByGroup,
} from "provider/accessibility-determined-by-group";
import { useTranslation } from "react-i18next";
import AccessibilityDeterminedByGroupContent from "./content";

const AccessibilityDeterminedByGroupOfPeoplePage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <Sidebar />
        </Col>
        <Col span={18} className="p-5">
          <AccessibilityDeterminedByGroupContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { group, setGroup } = useAccessibilityDeterminedByGroup();

  const content = [1, 2, 3].map((item) => {
    return (
      <CCard.SlashHeader
        active={group === item}
        className="mb-4"
        title="Car gate 01"
        key={item}
        onClick={() => {
          setGroup(item);
        }}
      >
        <Row
          align="middle"
          className="px-3 py-2"
          style={{ height: 86 }}
          gutter={12}
        >
          <Col>
            <Typography.Text type="secondary" ellipsis={{ tooltip: t("list") }}>
              {t("list")} :
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{`${5} ${t("list")}`}</Typography.Text>
          </Col>
        </Row>
      </CCard.SlashHeader>
    );
  });

  return (
    <InfiniteSidebar
      title={t("determined-by-group-of-people-list")}
      content={content}
    />
  );
};

export default withAccessibilityDeterminedByGroup(
  AccessibilityDeterminedByGroupOfPeoplePage
);
