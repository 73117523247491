import { MenuProps, Menu, Dropdown } from "antd";
import CButtonIcon from "components/button/c-button-icon";
import { FC } from "react";

const TableActionSettingButton: FC<{ menuProps?: MenuProps; record: any }> = ({
  menuProps,
  record,
}) => {
  if (!menuProps) return null;

  const appendRecord = (props?: MenuProps) => {
    if (!props) return {};
    const { items, ...rest } = props;
    const next = items?.map((item) => {
      if (item !== null && "onClick" in item) {
        return {
          ...item,
          onClick: () => {
            item?.onClick?.(record);
          },
        };
      }
      return item;
    });
    return { ...rest, items: next };
  };

  const props = appendRecord(menuProps);

  const menu = <Menu {...props} />;

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      overlayStyle={{ minWidth: 140 }}
    >
      <CButtonIcon.Setting />
    </Dropdown>
  );
};

export default TableActionSettingButton;
