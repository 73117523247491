import { TableColumnsType, Tag, Typography } from "antd";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteFaceTaskID, useGetFaceTasks } from "services/face-task";
import { IFaceTask, IFaceTaskParams } from "services/face-task/interface";

const FaceDeterminedByAccessLevelPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IFaceTaskParams>({ page: 1, limit: 10 });
  const get_face_task = useGetFaceTasks(params);
  const delete_face_task = useDeleteFaceTaskID();

  const elements: ISearchBoxElement[] = [
    {
      name: "title",
      label: t("name-request-to-area"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: TableColumnsType<IFaceTask> = [
    {
      title: t("name-request-to-area"),
      render: (_, rc) => {
        const title = rc?.title || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: title }}>
            {title}
          </Typography.Text>
        );
      },
    },
    {
      title: t("accessibility"),
      render: (_, rc) => {
        const accessName = rc?.faceTaskWithGroups.map((item) => {
          const color = item?.compareGroup?.color;
          return (
            <Tag key={item.id} color={color}>
              {item?.compareGroup?.name || "-"}
            </Tag>
          );
        });
        if (!accessName?.length) return <span>-</span>;
        return <React.Fragment>{accessName}</React.Fragment>;
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        selectAble={false}
        columns={columns}
        title={t("determined-by-access-level")}
        onAdd="access-control.face-accessibility-determined-by-access-level-add"
        onRowClick="access-control.face-accessibility-determined-by-access-level-edit"
        query={get_face_task}
        loading={get_face_task?.isFetching}
        onDelete={{
          mutation: delete_face_task,
          invalidateQueries: ["get-face-tasks", "get-face-task-id"],
        }}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default FaceDeterminedByAccessLevelPage;
