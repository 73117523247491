import { Col, Row } from "antd";
import CIndicator from "components/display/c-indicator";
import CCard from "components/display/card";
import PageContent from "components/layout/page-content";
import ErrorPage from "components/pages/error-page";
import PleaseSelectDataPage from "components/pages/select-data-page";
// import { usePageRoute } from "provider/page-route";
import withVehicleFloorProvider, {
  useVehicleFloor,
} from "provider/vehicle-floor";
import withVehicleSocketProvider, {
  useVehicleSocket,
} from "provider/vehicle-socket";
import { useTranslation } from "react-i18next";
import { useParkingFloorCount } from "services/floor";
import VehicleFloorContent from "./content";
import VehicleFloorSidebar from "./sidebar";

const VehicleFloorsPage = () => {
  // const { getCurrentParams } = usePageRoute();

  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6} style={{ zIndex: 1 }}>
          <VehicleFloorSidebar />
        </Col>
        <Col span={18}>
          <WrapWithSocket />
          <VehicleFloorContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const Description = () => {
  const { t } = useTranslation();
  const { levelNumber, selectedFloor, selectedParking } = useVehicleFloor();

  const { receives } = useVehicleSocket();
  const { data, isLoading, isError, error } = useParkingFloorCount({
    floorId: selectedFloor?.id,
    parkingId: selectedParking?.id,
    trigger: receives.length,
    // menuLevelId: levelId,
    parentFloorId: levelNumber >= 3 ? selectedFloor?.id : undefined,
  });

  if (!selectedParking) {
    return (
      <div className="bg-white" style={{ height: 179 }}>
        <PleaseSelectDataPage icon={false} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white" style={{ height: 179 }}>
        <CIndicator />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="bg-white" style={{ height: 179 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  const { motor, markType, car } = data;

  return (
    <div className="p-5 bg-white shadow-sm">
      <Row gutter={12}>
        <Col span={8}>
          <CCard.Percent
            value={motor.capacity - motor.unoccupied}
            max={motor.capacity}
            title={t("motorcycle")}
          />
        </Col>
        <Col span={8}>
          <CCard.Percent
            value={car.capacity - car.unoccupied}
            max={car.capacity}
            title={t("car")}
          />
        </Col>
        <Col span={8}>
          <CCard.ParkingType markType={markType} />
        </Col>
      </Row>
    </div>
  );
};

const WrapWithSocket = withVehicleSocketProvider(Description, true);

export default withVehicleFloorProvider(VehicleFloorsPage);
