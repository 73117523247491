import { Col, Divider, Form, Row, Typography } from "antd";
import Container from "components/display/container";
import PageContent from "components/layout/page-content";
import { usePageRoute } from "provider/page-route";
import { useTranslation } from "react-i18next";
import EmailEditor from "react-email-editor";
import CInput from "components/input/c-input";
import { useEffect, useRef } from "react";
import {
  useGetEmailTemplate,
  usePatchEmailTemplate,
  usePostEmailTemplate,
} from "services/email-template";

const EmailTemplateCreateAndEditPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const emailEditorRef: any = useRef(null);

  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const post_email_template = usePostEmailTemplate();
  const patch_email_template = usePatchEmailTemplate();
  const { data, isLoading } = useGetEmailTemplate(id);

  const pageKey = getCurrentKey();

  useEffect(() => {
    if (data) initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initialData = () => {
    if (data?.template) {
      const template = JSON.parse(data.template);
      emailEditorRef.current.editor?.loadDesign(template);
    }
    form.setFieldsValue(data);
  };

  const saveDesign = () => {
    return new Promise((resolve) => {
      emailEditorRef.current.editor.saveDesign((data: any) =>
        resolve(JSON.stringify(data))
      );
    });
  };

  const exportHtml = () => {
    return new Promise((resolve) => {
      return emailEditorRef.current.editor.exportHtml((data: any) =>
        resolve(JSON.stringify(data.html))
      );
    });
  };

  return (
    <PageContent
      loading={isLoading && !!id}
      breadcrumb={{
        form,
        mutate: {
          mutation: id ? patch_email_template : post_email_template,
          invalidateQueries: ["get-email_templates", "get-email_template"],
          onSaveAndContinue: !id,
          onBeforeUpdate: (value) => {
            const template = saveDesign();
            const html = exportHtml();
            return { ...value, template, html };
          },
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title level={5} className="!text-primary-dark">
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form layout="vertical" className="!p-5" form={form}>
          <Row gutter={24}>
            <Form.Item name="id" hidden>
              <CInput />
            </Form.Item>
            <Form.Item name="html" hidden>
              <CInput />
            </Form.Item>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("name")}
                rules={[{ required: true }]}
              >
                <CInput placeholder={t("name")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t("template")}>
                <EmailEditor ref={emailEditorRef} />
              </Form.Item>
            </Col>
            <Reason />
          </Row>
        </Form>
      </Container>
    </PageContent>
  );
};

const Reason = () => {
  const { t } = useTranslation();

  const titles = [
    [t("license-plate-does-not-pass")],
    [t("vehicle-inspection-not-pass-3rd-noti-vip"), t("vip-reject-car")],
    [
      t("not-wearing-a-mask"),
      t("temperature-does-not-pass"),
      t("pass-face-scan"),
    ],
  ];

  const keys = {
    name: <div>@name</div>,
    date: <div>@date</div>,
    mask: <div>@mask</div>,
    temperature: <div>@temperature</div>,
    vehicleType: <div>@vehicleType</div>,
    vehicleColor: <div>@vehicleColor</div>,
    vehicleBrand: <div>@vehicleBrand</div>,
    licensesPlate: <div>@licensesPlate</div>,
    encodeDeviceName: <div>@encodeDeviceName</div>,
  };

  return (
    <Col span={24}>
      <div className="pb-2 text-[red] text-[20px]">* {t("note")}</div>
      <Row className="text-[red] text-[20px]" gutter={[12, 12]}>
        {Array.from(Array(3).keys()).map((i) => {
          const span = !i ? 6 : 9;
          return (
            <Col span={span} className="line-clamp">
              ({titles[i].join(", ")})
              <div className="pl-2" hidden={!!i}>
                {keys.encodeDeviceName}
                {keys.date}
                {keys.licensesPlate}
                {keys.vehicleType}
                {keys.vehicleColor}
                {keys.vehicleBrand}
              </div>
              <div className="pl-2" hidden={i !== 1}>
                {keys.name}
                {keys.date}
                {keys.licensesPlate}
                {keys.vehicleType}
              </div>
              <div className="pl-2" hidden={i !== 2}>
                {keys.name}
                {keys.date}
                {keys.encodeDeviceName}
                {keys.temperature}
                {keys.mask}
              </div>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};

export default EmailTemplateCreateAndEditPage;
