import {
  Col,
  Divider,
  Form,
  Row,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import CAvatar from "components/display/c-avatar";
import CInput from "components/input/c-input";
import CTextArea from "components/input/c-input/text-area";
import PageContent from "components/layout/page-content";
import { usePageRoute } from "provider/page-route";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetAccessibility,
  useGetAccessibilityInGroup,
} from "services/accessibility";
import { IAccessibilityParams } from "services/accessibility/interface";
import styled from "styled-components";
import tw from "twin.macro";

const AccessibilityManagementDetail = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();
  const { data } = useGetAccessibility(id);

  const [params, setParams] = useState<IAccessibilityParams>({
    accessibilityId: id,
  });
  const data_in_group = useGetAccessibilityInGroup(params);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns: TableColumnsType<any> = [
    {
      title: t("profile-picture"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url || ""} />;
      },
    },
    {
      title: t("identification-card-number"),
      render: (_, rc) => {
        return <Typography.Text>{rc?.identityCode || "-"}</Typography.Text>;
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text
            ellipsis
          >{`${firstName} ${lastName}`}</Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        return <Typography.Text>{rc?.phoneNumber || "-"}</Typography.Text>;
      },
    },
  ];

  return (
    <PageContent
      breadcrumb={{ form, mutate: { onSave: false, onSaveAndContinue: false } }}
      className="p-5"
    >
      <Form form={form} layout="vertical" className="bg-white">
        <Row className="p-5">
          <Typography.Title
            level={5}
            style={{ color: "#438860" }}
            className="px-4"
          >
            {t("access-control.accessibility-management-detail", {
              ns: "menu",
            })}
          </Typography.Title>
        </Row>
        <Divider className="!m-0" />
        <Row className="p-5">
          <Col span={24} className="px-4">
            <Form.Item label={t("accessibility-name")} name="accessibilityName">
              <CInput readOnly />
            </Form.Item>
          </Col>
          <Col span={24} className="px-4">
            <Form.Item label={t("description")} name="description">
              <CTextArea rows={8} readOnly />
            </Form.Item>
          </Col>
          <Col span={24} className="pt-4 px-4">
            <TableStyled
              columns={columns}
              dataSource={data_in_group?.data?.data || []}
              pagination={{
                onChange: (page, limit) => {
                  setParams({ ...params, page, limit });
                },
              }}
            />
          </Col>
        </Row>
      </Form>
    </PageContent>
  );
};

const TableStyled = styled(Table)`
  th {
    background-color: #ebebeb !important;
    color: black !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #f0faf3;
  }

  .ant-pagination-item-link {
    ${tw`flex items-center justify-center`};
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #f0faf3;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
`;

export default AccessibilityManagementDetail;
