import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParkingsScroll } from "services/parking";
import { IParking } from "services/parking/interface";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

export interface CSelectParkingProps extends CSelectProps {
  onSelectCallback?: (e: IParking) => void;
}

const CSelectParking: FC<CSelectParkingProps> = ({
  onSelectCallback,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useParkingsScroll}
      onSelectCallback={onSelectCallback}
      valueKey="id"
      titleKey="parkingName"
      searchKey="search"
      placeholder={t("vehicle-management.parkings", { ns: "menu" })}
    />
  );
};

export default CSelectParking;
