import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "../../config/axios";
import { IResponse } from "../../config/axios/interface";
import { IListSupplier, IListSupplierParams } from "./interface";

export const useGetListSupplier = (
  params: IListSupplierParams
): UseQueryResult<IResponse<IListSupplier[]>> => {
  return useQuery(
    ["get-list-suppliers", params],
    async () => {
      if (!params?.supplierId) return;
      const res = await axios.get(`/list-supplier/list/${params?.supplierId}`, {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostListSupplier = (): UseMutationResult => {
  return useMutation(async ({ values, id }: any) => {
    const res = await axios.post(`/list-supplier/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchListSupplierId = (): UseMutationResult => {
  return useMutation(async ({ id, ...values }: any) => {
    const res = await axios.patch(`/list-supplier/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteListSupplier = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/list-supplier/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteListSuppliers = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/list-supplier/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useScrollGetListSuppliers = (): UseInfiniteQueryResult<
  IResponse<IListSupplier[]>
> => {
  return useInfiniteQuery(
    ["list-supplier"],
    async () => {
      const res = await axios.get("/list-supplier");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};
