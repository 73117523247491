import { Typography } from "antd";
import FilePicker, { FilePickerRef } from "components/input/file-picker";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IMediaObject } from "services/media-object/interface";
import styled from "styled-components";
import tw from "twin.macro";
import CImage from "./c-image";

interface VehicleImageProps {
  value?: IMediaObject | File;
  onChange?: (v: File) => void;
}

const VehicleImageWrapper = styled.div`
  height: 200px;
  border: 1px solid;
  ${tw`p-3 rounded-app !border-primary-dark cursor-pointer`};
`;

const VehicleImage: React.FC<VehicleImageProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const ref = useRef<FilePickerRef>(null);

  const onClick = () => {
    ref.current?.click();
  };

  const onPick = (file: File) => {
    onChange?.(file);
  };

  if (!value) {
    return (
      <React.Fragment>
        <FilePicker onChange={onPick} ref={ref} />
        <VehicleImageWrapper onClick={onClick} className="center">
          <Typography.Text>
            {t("vehicle-image-not-found", { ns: "message" })}
          </Typography.Text>
        </VehicleImageWrapper>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <FilePicker onChange={onPick} ref={ref} />
      <VehicleImageWrapper
        onClick={onClick}
        className="p-3 rounded-app border-primary"
      >
        <CImage src={"url" in value ? value?.url : value} />
      </VehicleImageWrapper>
    </React.Fragment>
  );
};

export default VehicleImage;
