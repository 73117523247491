import { Grid, Layout } from "antd";
import AppRoleMenuProvider from "provider/app-role-menu";
import NotificationSocket from "provider/notification-socket";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import NavigatorProvider from "../provider/navigator";
import Header from "./header";
import Navigator from "./navigator";

const MainLayout = () => {
  return (
    <React.Fragment>
      <ToSmallPage />
      <AppRoleMenuProvider>
        <NavigatorProvider>
          <NotificationSocket>
            <Layout>
              <Header />
              <Navigator />
            </Layout>
          </NotificationSocket>
        </NavigatorProvider>
      </AppRoleMenuProvider>
    </React.Fragment>
  );
};

const ToSmallPageWrapper = styled.div`
  ${tw`h-screen flex items-center justify-center text-[20px]`};
  z-index: 99999;
`;

const ToSmallPage = () => {
  const { lg } = Grid.useBreakpoint();

  return <ToSmallPageWrapper hidden={lg}>too small</ToSmallPageWrapper>;
};

export default MainLayout;
