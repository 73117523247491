import { useQueryClient } from "@tanstack/react-query";
import { Col, Divider, Form, Input, Row, Typography } from "antd";
import CInput from "components/input/c-input";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import {
  useGetTermAndCondition,
  usePatchTermAndCondition,
} from "services/terms-and-condition";
import styled from "styled-components";

const ReactQuillStyle = styled(ReactQuill)`
  .ql-toolbar.ql-snow {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }
  .ql-container.ql-snow {
    font-size: 20px;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .ql-snow .ql-picker {
    font-size: 20px;
  }
  .ql-snow .ql-picker-label {
    display: flex;
  }
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    margin-top: 0px;
    top: 0;
    width: 24px;
  }
  .ant-form-item {
    font-size: 20px;
  }
`;

const TermsAndConditionPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [description, setDescription] = useState("");

  const { data } = useGetTermAndCondition();
  const patch_terms_condition = usePatchTermAndCondition();
  const qClient = useQueryClient();
  const { setAppLoading } = useAppLoading();

  useEffect(() => {
    if (!data?.data) return;

    form.setFieldsValue({
      ...data?.data,
      ...data?.data?.[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onFinish = (values: any) => {
    patch_terms_condition.mutate(
      { ...values, id: data?.data?.[0]?.id },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "setting" });
          qClient.invalidateQueries(["get-terms-condition"]);
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "setting",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const titleTh = (
    <div>
      <span className="px-2">{t("title-terms-and-condition")}</span>
      <span style={{ color: "red" }}>{t("(TH)")}</span>
    </div>
  );
  const titleEn = (
    <div>
      <span className="px-2">{t("title-terms-and-condition")}</span>
      <span style={{ color: "red" }}>{t("(EN)")}</span>
    </div>
  );

  return (
    <PageContent
      breadcrumb={{
        form,
        mutate: { onSave: onFinish, onSaveAndContinue: false, onCancel: false },
      }}
      className="p-5"
    >
      <Form form={form} layout="vertical">
        <div className="rounded-app bg-white">
          <div className="p-5 py-3">
            <Typography.Title level={4} className="!text-green-600">
              {t("terms-and-condition")}
            </Typography.Title>
          </div>
          <Divider className="!m-0" />
          <Row className="px-5 py-3" gutter={[0, 0]}>
            <Col span={23}>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name="headerTh"
                label={titleTh}
                rules={[{ required: true }]}
              >
                <CInput placeholder={t("title-terms-and-condition-th")} />
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item name="descriptionTh" label={t("descripttion-th")}>
                <ReactQuillStyle
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  className="h-[300px] pb-8"
                  placeholder={t("details")}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="rounded-app bg-white">
          <Row className="px-5 py-3 mt-5" gutter={[0, 0]}>
            <Col span={23}>
              <Form.Item
                name="headerEn"
                label={titleEn}
                rules={[{ required: true }]}
              >
                <CInput placeholder={t("title-terms-and-condition-en")} />
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item name="descriptionEn" label={t("descripttion-en")}>
                <ReactQuillStyle
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  className="h-[300px] pb-8"
                  placeholder={t("details")}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </PageContent>
  );
};

export default TermsAndConditionPage;
