import { PlusSquareFilled, EditFilled } from "@ant-design/icons";
import { BinIcon } from "assets/icons/general";
import CButtonIcon from "components/button/c-button-icon";
import CSpace from "components/display/c-space";
import { useVehicleFloorEdit } from "provider/vehicle-floor-edit";
import { FloorEditCursorTypes } from "provider/vehicle-floor-edit/interface";
import { ReactNode } from "react";

type ObjectType = {
  key: FloorEditCursorTypes;
  icon: ReactNode;
};

const obj: ObjectType[] = [
  {
    key: "add",
    icon: <PlusSquareFilled />,
  },
  {
    key: "edit",
    icon: <EditFilled />,
  },
  {
    key: "delete",
    icon: <BinIcon />,
  },
];

const FloorEditAction = () => {
  const { setCursorType, cursorType } = useVehicleFloorEdit();

  const onActionClick = (type: FloorEditCursorTypes) => {
    if (type === cursorType) {
      return setCursorType(undefined);
    }
    return setCursorType(type);
  };

  return (
    <CSpace className="px-4 py-3">
      {obj.map((item) => {
        return (
          <CButtonIcon
            icon={item.icon}
            key={item.key}
            theme={cursorType === item.key ? 'primary' : 'gray'}
            onClick={() => {
              onActionClick(item.key);
            }}
          />
        );
      })}
    </CSpace>
  );
};

export default FloorEditAction;
