import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectIsActive: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("status")}
      {...props}
      valueOptions={{
        valueKey: "value",
        titleKey: "title",
        values: [
          { title: t("active"), value: true },
          { title: t("in-active"), value: false },
        ],
      }}
    />
  );
};

export default CSelectIsActive;
