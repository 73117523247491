import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "../../config/axios";
import { IResponse } from "../../config/axios/interface";
import { IDepartment, IDepartmentParams } from "./interface";

export const useGetDepartment = (
  params: IDepartmentParams
): UseQueryResult<IResponse<IDepartment[]>> => {
  return useQuery(
    ["get-departments", params],
    async () => {
      const res = await axios.get("/department", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostDepartment = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/department", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchDepartmentId = (): UseMutationResult => {
  return useMutation(async ({ id, ...values }: any) => {
    const res = await axios.patch(`/department/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteDepartment = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/department/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteDepartments = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/department/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useScrollGetDepartments = (
  params?: IDepartmentParams
): UseInfiniteQueryResult<IResponse<IDepartment[]>> => {
  return useInfiniteQuery(
    ["get-departments", params],
    async ({ pageParam }) => {
      const res = await axios.get("/department", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};
