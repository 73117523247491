import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserProductListTypes } from "services/user/interface";
import CRadioGroup, { CRadioGroupProps } from "./c-radio-group";

export interface CRadioProductChoiceProps extends CRadioGroupProps {
  hasProduct?: boolean;
}

const CRadioProductChoice: FC<CRadioProductChoiceProps> = ({
  hasProduct,
  ...props
}) => {
  const { t } = useTranslation();

  const options: {
    label: string;
    disabled?: boolean;
    value: UserProductListTypes;
  }[] = [
    {
      label: t("add-product"),
      value: "ADD_PRODUCT",
      disabled: !hasProduct,
    },
    {
      label: t("product-group"),
      value: "ADD_PRODUCT_GROUP",
      disabled: !hasProduct,
    },
    { label: t("capture-product-image"), value: "CAPTURE_PRODUCT_IMAGE" },
  ];

  return <CRadioGroup options={options} {...props} />;
};

export default CRadioProductChoice;
