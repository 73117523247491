import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectGateName: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  const data_gate_name = [
    {
      gateName: "GATE_1_IN",
      title: t("gete-1-in"),
    },
    {
      gateName: "GATE_1_OUT",
      title: t("gete-1-out"),
    },
    {
      gateName: "GATE_2_IN",
      title: t("gete-2-in"),
    },
    {
      gateName: "GATE_2_OUT",
      title: t("gete-2-out"),
    },
    {
      gateName: "GATE_3_IN",
      title: t("gete-3-in"),
    },
    {
      gateName: "GATE_3_OUT",
      title: t("gete-3-out"),
    },
    {
      gateName: "GATE_3_IN_TRUCK_CASE",
      title: t("gete-3-in-truck"),
    },
    {
      gateName: "CAR_GATE_1",
      title: t("car-gate-1"),
    },
    {
      gateName: "CAR_GATE_1_OPEN_BARRIER",
      title: t("car-gate-1-open"),
    },
    {
      gateName: "TRUCK_PARKING_LOT",
      title: t("truck-parking-lot"),
    },
  ];
  return (
    <CSelect
      {...props}
      placeholder={t("position-device")}
      valueOptions={{
        values: data_gate_name || [],
        titleKey: "title",
        valueKey: "gateName",
      }}
    />
  );
};

export default CSelectGateName;
