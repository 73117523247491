import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetOrganizations } from "services/user-company";
import CSelect, { CSelectProps } from ".";

const CSelectCompanyNameTags: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  const { data, isFetching } = useGetOrganizations({ pagination: false });
  return (
    <CSelect
      {...props}
      allowClear
      placeholder={t("company-or-shipping")}
      mode="tags"
      loading={isFetching}
      valueOptions={{
        values: data?.data || [],
        valueKey: "name",
        titleKey: "name",
      }}
    />
  );
};

export default CSelectCompanyNameTags;
