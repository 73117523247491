import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import CCard from "components/display/card";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteEmployee,
  useDeleteEmployees,
  useExportTemplateEmployee,
  useGetEmployees,
  useImportExcelEmployee,
  useImportProfileEmployee,
} from "services/employee";
import { IEmployee, IEmployeeParams } from "services/employee/interface";

const UserEmployeePage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IEmployeeParams>({ limit: 10, page: 1 });
  // const { translationObject } = useAppLanguage();

  const get_emps = useGetEmployees(params);
  const del_emp = useDeleteEmployee();
  const del_emps = useDeleteEmployees();

  const export_template = useExportTemplateEmployee();
  const import_employee = useImportExcelEmployee();
  const import_profile = useImportProfileEmployee();

  const elements: ISearchBoxElement[] = [
    {
      name: "identityCode",
      label: t("employee-id"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "position",
      label: t("job-position"),
      input: {
        type: "SelectJobPosition",
      },
    },
    {
      name: "department",
      label: t("department"),
      input: {
        type: "SelectDepartment",
      },
    },
    {
      name: "licensePlateNumber",
      label: t("license-plate-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: ColumnsType<IEmployee> = [
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc.profile?.url} />;
      },
    },
    {
      title: t("employee-id"),
      // align: "center",
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc.identityCode) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: rc.identityCode }}>
            {rc.identityCode}
          </Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const text = `${rc.firstName} ${rc.lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      width: 120,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc.phoneNumber) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: rc.phoneNumber }}>
            {rc.phoneNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("license-plate-number"),
      width: 200,
      render: (_, rc) => {
        if (
          !rc.vehicleInfos ||
          (rc?.vehicleInfos && rc?.vehicleInfos.length <= 0)
        )
          return "-";

        let licensePlateNumber = (rc?.vehicleInfos || [])?.map(
          (e, index) =>
            `${e.licensePlateNumber}${
              index + 1 < (rc.vehicleInfos?.length || 0) ? " , " : " "
            }`
        );

        if (licensePlateNumber.some((e) => e.includes("null"))) {
          licensePlateNumber = licensePlateNumber.map((e) => {
            if (e.includes("null")) return e.split("null").join("-");
            return e;
          });
        }

        return (
          <Typography.Text ellipsis={{ tooltip: licensePlateNumber }}>
            {licensePlateNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("job-position"),
      align: "center",
      width: 150,
      // render: (_, rc) => {
      //   const { positionName } = translationObject({
      //     object: rc,
      //     keys: ["position.positionName"],
      //   });
      //   return <CCard.WithBorder label={positionName} type="warning" />;
      // },
      render: (_, rc) => {
        if (!rc?.position?.positionCode) return "-";
        return (
          <CCard.WithBorder label={rc?.position?.positionCode} type="warning" />
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        scroll={{ x: 1200 }}
        query={get_emps}
        title={t("employee-list")}
        columns={columns}
        onDelete={{
          mutation: del_emp,
          invalidateQueries: ["get-employees", "get-employee"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        action={{
          bulkDelete: {
            mutation: del_emps,
            invalidateQueries: ["get-employees", "get-employee"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
          excels: {
            template: {
              mutation: export_template,
              fileName: "employee template",
            },
            import: {
              mutation: import_employee,
              list: {
                key: "firstName",
                child: [
                  "licensePlateNumber",
                  "vehicleBrand",
                  "vehicleModel",
                  "provincePlate",
                  "vehicleType",
                ],
                listName: "vehicleLists",
              },
              invalidateQueries: ["get-employees", "get-employee"],
            },
            importProfile: {
              mutation: import_profile,
              invalidateQueries: ["get-employees", "get-employee"],
              typeUser: "employee",
            },
          },
        }}
        onAdd="user-management.employee-add"
        onRowClick="user-management.employee-edit"
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default UserEmployeePage;
