import { useQueryClient } from "@tanstack/react-query";
import { Col, Form, TableColumnsType, Typography } from "antd";
import { FormInstance } from "antd/es/form/Form";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import { ISearchBoxElement } from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import useDebounce from "hooks/useDebounce";
import { useAppLoading } from "provider/app-loading";
import { useNotificationSetting } from "provider/notification-setting";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEmployee } from "services/employee/interface";
import {
  useDeleteNotificationSetting,
  useDeleteNotificationSettings,
  useGetEmployeeNotification,
  useGetNotificationSettings,
  useGetTemplateNotifications,
  usePostNotificationSetting,
  usePostTemplateNotifications,
} from "services/notification-setting";
import {
  IEmployeeNotificationParams,
  INotificationSetting,
  INotificationSettingParams,
} from "services/notification-setting/interface";

const ContentPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [params, setParams] = useState<INotificationSettingParams>({
    page: 1,
    limit: 10,
  });

  const { keyStatus } = useNotificationSetting();

  const query = useGetNotificationSettings({
    gateName: keyStatus?.gateName,
    notificationName: keyStatus?.notificationName,
    ...params,
  });
  const deleteEmail = useDeleteNotificationSetting();
  const deleteEmails = useDeleteNotificationSettings();

  useEffect(() => {
    setParams({ ...params, page: 1, email: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyStatus]);

  const elements: ISearchBoxElement[] = [
    {
      label: t("email"),
      name: "email",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const column: TableColumnsType<INotificationSetting> = [
    {
      title: t("email"),
      width: 120,
      render: (_, rc) => {
        return <Typography.Text>{rc?.email || "-"}</Typography.Text>;
      },
    },
  ];

  const onClose = () => {
    setVisible(!visible);
  };

  const onSearch = (values: any) => {
    setParams({
      ...params,
      ...values,
      page: 1,
    });
  };

  return (
    <div>
      <AddEmail visible={visible} onClose={onClose} form={form} />
      <CTable
        style={{ height: "calc(100vh - 325px)" }}
        scroll={{ y: 300 }}
        className="mt-0"
        title={keyStatus?.title ? t(keyStatus?.title) : t("notification-list")}
        rowKey="id"
        query={query}
        columns={column}
        extraElement={<ExtraElement />}
        onAdd={{
          onClick: () => {
            setVisible(!visible);
          },
          label: `+ ${t("add")}${t("email")}`,
        }}
        action={{
          searchBox: { elements: elements, onFinish: onSearch },
          bulkDelete: {
            mutation: deleteEmails,
            invalidateQueries: ["get-notification-settings"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: deleteEmail,
          invalidateQueries: ["get-notification-settings"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </div>
  );
};

const ExtraElement = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const qClient = useQueryClient();
  const { keyStatus } = useNotificationSetting();

  const watchSearch = Form.useWatch("templateEmail", form);
  const templateEmail = useDebounce(watchSearch, 500);

  const { data } = useGetTemplateNotifications({
    gateName: keyStatus?.gateName,
    notificationName: keyStatus?.notificationName,
    page: 1,
  });
  const postTemplate = usePostTemplateNotifications();

  useEffect(() => {
    if (data?.data && data?.data.length > 0) {
      const dataTemplate = data?.data?.[0];
      if (
        keyStatus?.gateName === dataTemplate.gateName &&
        keyStatus?.notificationName === dataTemplate.notificationName
      ) {
        return form.setFieldsValue({
          templateEmail: dataTemplate?.templateEmailId,
        });
      }
    }
    return form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, keyStatus]);

  useEffect(() => {
    const value = {
      gateName: keyStatus?.gateName,
      notificationName: keyStatus?.notificationName,
      templateEmail,
    };
    if (
      data?.data &&
      data?.data.length > 0 &&
      data?.data?.[0]?.templateEmailId === templateEmail
    ) {
      return;
    } else {
      if (templateEmail) {
        return postTemplate.mutate(value, {
          onSuccess: () => {
            qClient.invalidateQueries(["get-template-notification"]);
            fireNotification({ type: "success" });
          },
          onError: (message: any) => {
            fireNotification({ type: "error", description: message });
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateEmail]);

  return (
    <Col style={{ width: "15vw" }}>
      <Form form={form}>
        <Form.Item name="templateEmail" noStyle>
          <CSelect.EmailTemplate
            className="w-full"
            placeholder={t("setting.email-template", { ns: "menu" })}
          />
        </Form.Item>
      </Form>
    </Col>
  );
};

interface IAddEmail {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
}

const AddEmail: FC<IAddEmail> = ({ visible, onClose, form }) => {
  const { t } = useTranslation();
  const { setAppLoading } = useAppLoading();
  const qClient = useQueryClient();

  const watchSearch = Form.useWatch("search", form);
  const email = useDebounce(watchSearch, 500);

  const [params, setParams] = useState<IEmployeeNotificationParams>({
    limit: 10,
    page: 1,
  });

  const { keyStatus } = useNotificationSetting();
  const postEmailNoti = usePostNotificationSetting();

  const { data, isFetching } = useGetEmployeeNotification({
    gateName: keyStatus?.gateName,
    notificationName: keyStatus?.notificationName,
    wait: !visible,
    email,
    ...params,
  });

  const columns: TableColumnsType<IEmployee> = [
    {
      title: t("email"),
      render: (_, rc) => {
        return <Typography.Text>{rc?.email || "-"}</Typography.Text>;
      },
    },
  ];

  const onSave = () => {
    const { emails } = form.getFieldsValue();
    const findEmail = data?.data
      .filter((e) => emails.find((id: any) => id === e.id))
      .map((item) => item.email);

    const values = {
      emails: findEmail,
      gateName: keyStatus?.gateName,
      notificationName: keyStatus?.notificationName,
    };
    setAppLoading(true);
    return postEmailNoti.mutate(values, {
      onSuccess: () => {
        qClient.invalidateQueries(["get-notification-settings"]);
        qClient.invalidateQueries(["get-notification-setting"]);
        fireNotification({ type: "success" });
        form.resetFields();
        onClose();
      },
      onError: (message: any) => {
        fireNotification({ type: "error", description: message });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  return (
    <CDrawer
      visible={visible}
      onCancel={onClose}
      onClose={onClose}
      form={form}
      forceRender={false}
      title={t("select-email")}
      onSave={onSave}
    >
      <Form form={form} layout="vertical">
        <Form.Item label={t("search")} name="search">
          <CInput search placeholder={t("search")} />
        </Form.Item>
        <Form.Item name="emails">
          <CTable.Select
            rowKey="id"
            columns={columns}
            dataSource={data?.data || []}
            loading={isFetching}
            pagination={{
              size: "small",
              total: data?.count,
              current: params?.page,
              pageSize: params.limit,
              onChange: (page, limit) => {
                setParams({ ...params, page, limit });
              },
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default ContentPage;
