import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, ModalFuncProps } from "antd";
import { t } from "i18next";

const { confirm } = Modal;

export const fireConfirmDelete = (props?: ModalFuncProps): Promise<boolean> => {
  return new Promise((resolve) => {
    confirm({
      title: `${t("do-you-want-to-delete-these-items", { ns: "message" })}`,
      icon: <ExclamationCircleOutlined />,
      content: `${t("this-action-is-irreverible", { ns: "message" })}`,
      onOk: () => {
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
      },
      transitionName: "",
      centered: true,
      maskClosable: true,
      zIndex: 9999,
      ...props,
    });
  });
};
