import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "../../config/axios";
import { IScheduleGroup, IScheduleGroupParams } from "./interface";
import { IResponse } from "config/axios/interface";

export const useScrollGetScheduleGroups = (
  params?: IScheduleGroupParams
): UseInfiniteQueryResult<IResponse<IScheduleGroup[]>, Error> => {
  return useInfiniteQuery(
    ["get-schedule-groups", params],
    async ({ pageParam }) => {
      const res = await axios.get("/group-table", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetScheduleGroups = (): UseQueryResult<IScheduleGroup[]> => {
  return useQuery(
    ["get-schedule-groups"],
    async () => {
      const res = await axios.get("/group-table");
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostScheduleGroup = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/group-table", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchScheduleGroup = (): UseMutationResult => {
  return useMutation(async ({ id, ...values }: any) => {
    values = { ...values, id };
    const res = await axios.patch(`/group-table/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useGetScheduleGroup = (
  id?: number | string
): UseQueryResult<IScheduleGroup> => {
  return useQuery(
    ["get-schedule-group", id],
    async () => {
      const res = await axios.get(`/group-table/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useDeleteScheduleGroup = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/group-table/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteScheduleGroups = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/position/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
