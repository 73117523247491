import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import {
  IParkingPeriodReport,
  ITrafficReport,
  IVehicleReport,
  IVehicleReportParams,
} from "./interface";

export const useExportVehicleReportExcel = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (values) => {
    const res = await axios.post(
      "export-excel/report-vehicle/vehicle",
      values,
      { responseType: "blob" }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetVehicleReport = (
  params?: IVehicleReportParams
): UseQueryResult<IResponse<IVehicleReport[]>, Error> => {
  return useQuery(["get-vehicle-report", params], async () => {
    const res = await axios.get("/report/vehicle/vehicle-report", { params });
    if (res.status === 200) {
      const { data } = res.data;
      const next = data.map((item: any) => {
        return {
          ...item,
          customerEmployee:
            item?.vehicleCustomerEmployee?.[0]?.customerEmployee,
        };
      });
      return { ...res.data, data: next };
    }
    throwResponse(res);
  });
};

export const useGETParkingPeriodReport = (
  params?: IVehicleReportParams
): UseQueryResult<IResponse<IParkingPeriodReport[]>> => {
  return useQuery(
    ["get-parking-period-report", params],
    async () => {
      const res = await axios.get("/report/vehicle/summary-report", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGETTrafficReport = (
  params?: IVehicleReportParams
): UseQueryResult<IResponse<ITrafficReport>> => {
  return useQuery(
    ["get-vehicle-traffic-reports", params],
    async () => {
      const res = await axios.get("/report/vehicle/traffic-report", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useExportParkingPeriodReport = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/vehicle/summary-report-export-excel`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const EXPORT_VEHICLE_TRAFFIC_REPORTS = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      `/export-excel/vehicle/traffic-report-export-excel`,
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
