import { RefObject, useEffect, useState } from "react";

const useMouse = ({
  ref,
  prevent = false,
}: {
  ref?: RefObject<HTMLDivElement>;
  prevent?: boolean;
}) => {
  const [mousePosition, setMousePosition] = useState<{
    x: number;
    y: number;
  }>();
  useEffect(() => {
    const mouseMoveHandler = (event: MouseEvent) => {
      if (ref?.current && ref.current.contains(event.target as Node)) {
        if (prevent) return;
        const { clientX, clientY } = event;
        setMousePosition({ x: clientX - 12, y: clientY - 12 });
      } else {
        setMousePosition(undefined);
      }
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, [ref, prevent]);
  return mousePosition;
};
export default useMouse;
