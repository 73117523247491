import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { Col, Divider, Form, Row, Typography } from "antd";
import Container from "components/display/container";
import CDatePicker from "components/input/c-date-picker";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { IResponse } from "config/axios/interface";
import dayjs from "dayjs";
import { usePageRoute } from "provider/page-route";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePostIdCard } from "services/check-in";
import { IIdCardPDK } from "services/check-in/interface";
import { IContractor } from "services/contractor/interface";
import { IReceiver } from "services/receiver/interface";
import { ISupplier } from "services/supplier/interface";
import { useGetUserGroups } from "services/user-group";
import { useGetVisitors } from "services/visitor";
import { IVisitor } from "services/visitor/interface";
import styled from "styled-components";
import tw from "twin.macro";
import AccessConTrol from "./form-create-user/access-control";
import ContactInformation from "./form-create-user/contact-information";
import ContractorPage from "./form-create-user/contractor-page";
import DefaultInformation from "./form-create-user/default-infomation";
import DriverInformation from "./form-create-user/device-information";
import EmployeeField from "./form-create-user/employee-field";
import FileInformation from "./form-create-user/file-information";
import ImageField from "./form-create-user/image-field";
import PdpaInformation from "./form-create-user/pdpa-information";
import SupplierInformation from "./form-create-user/supplier-information";
import TemperatureInformation from "./form-create-user/temperature-information";
import VehicleField from "./form-create-user/vehicle-field";
import CTextArea from "components/input/c-input/text-area";
import { useAppLanguage } from "provider/app-language";
import { useGetPickupPoint } from "services/master";
import { IPickupPoint } from "services/master/interface";
import { FormInstance } from "antd/es/form/Form";

export type TMenu = "visitor" | "contractor" | "supplier" | "receiver";

export interface IUSerCreateAndEdit {
  menu: TMenu;
  action?: IMutation;
  pendingListPage?: boolean;
  notDisablePending?: boolean;
}

export interface IMutation {
  get: UseQueryResult<any>;
  post?: UseMutationResult;
  patch?: UseMutationResult;
  invalidateQueries?: string[];
  getIdentityCode?: (params?: any) => UseQueryResult<IResponse<any[]>, Error>;
}

export const userType: { [k in TMenu]: string } = {
  visitor: "VISITOR",
  contractor: "CONTRACTOR",
  receiver: "RECEIVER",
  supplier: "SUPPLIER",
};

export const WrapperTemperatureInformation = styled.div`
  width: 200px;
  height: 200px;
  border: 1px solid rgb(220, 220, 220, 1);
  overflow: hidden;
  background-color: rgb(245, 245, 245, 1);
  ${tw`flex items-center justify-center cursor-pointer `};
  ${tw`rounded-app`};
`;

const UserWithPDPACreateAndEditPage = ({
  menu,
  pendingListPage = false,
  notDisablePending = false,
  action,
}: IUSerCreateAndEdit) => {
  const { t } = useTranslation();
  const { translationObject } = useAppLanguage();

  const [form] = Form.useForm();

  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();
  const pageKey = getCurrentKey();

  const postIdCard = usePostIdCard();

  const dataById = action?.get.data;
  const [params, setParams] = useState<any>({ identityCode: " " });
  const { data, isFetching } = useGetVisitors({ ...params, limit: 1 });
  const userGroups = useGetUserGroups({
    pagination: false,
    slug: !id ? "DEFAULT" : undefined,
  });
  // const _pickupPoint = useGetPickupPoint(dataById?.lnInboundDetail?.lnInbound?.pickupPointId)


  useEffect(() => {
    const idUserGroup = userGroups.data?.data?.find(
      (e) => e.slug === "DEFAULT"
    );
    form.setFieldsValue({ groupId: idUserGroup?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!id]);


  // gen qrcode on accept pdpa
  const acceptPdpa = Form.useWatch("acceptPdpa", form);

  useEffect(() => {
    if (menu === "supplier" || menu === "receiver") {
      const day = dayjs().format("DDMMYYYY");
      const match = Math.floor(Math.random() * 100000000);
      const qrcodeNumber = day + match;
      form.setFieldsValue({ qrcodeNumber: `${qrcodeNumber}` });
    } else {
      if (acceptPdpa === false && (!id || !dataById?.qrcodeNumber)) {
        const day = dayjs().format("DDMMYYYY");
        const match = Math.floor(Math.random() * 100000000);
        const qrcodeNumber = day + match;
        form.setFieldsValue({ qrcodeNumber: `${qrcodeNumber}` });
      }

      if (acceptPdpa !== false) {
        form.setFieldsValue({ qrcodeNumber: undefined });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptPdpa]);

  // initial value visitor
  useEffect(() => {
    if (menu === "visitor") {
      initialValueVisitor(dataById as IVisitor);
    }
    if (menu === "receiver") {
      initialValuesReceiver(dataById as IReceiver);
    }
    if (menu === "contractor") {
      initialValuesContractor(dataById as IContractor);
    }
    if (menu === "supplier") {
      initialValuesSupplier(dataById as ISupplier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataById]);

  // useEffect(() => {
  //   if ((data?.data?.length || 0) > 0) {
  //     const phoneNumber = data?.data?.[0]?.phoneNumber;
  //     form.setFieldsValue({ phoneNumber: phoneNumber });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  const initialValueVisitor = (values: IVisitor) => {
    if (!values) return;
    const { visitorsDetail } = values;
    const numberOfPerson = visitorsDetail?.numberOfPerson;
    const itemPictures = visitorsDetail?.itemPictures || [];
    const employee = visitorsDetail?.employee?.id;
    const jobPosition = visitorsDetail?.jobPosition?.id;
    const department = visitorsDetail?.department?.id;
    const vehicleType = visitorsDetail?.vehicleType?.id;
    const startDate = visitorsDetail?.startDate
      ? dayjs(visitorsDetail?.startDate)
      : undefined;
    const endDate = visitorsDetail?.endDate
      ? dayjs(visitorsDetail?.endDate)
      : undefined;
    const groupId = userGroups.data?.data?.find(
      (e) => e.id === values?.visitorsDetail?.groupId
    )?.id;
    const contactDate = visitorsDetail?.contactDate
      ? dayjs(visitorsDetail?.contactDate)
      : undefined;
    const createdAt = values?.createdAt ? dayjs(values?.createdAt) : undefined;
    form.setFieldsValue({
      ...values,
      ...visitorsDetail,
      companyName: values?.companyName ? values?.companyName : undefined,
      profile: values?.profile?.url,
      numberOfPerson: numberOfPerson,
      haveItem: visitorsDetail?.haveItem,
      identityCardPicture: visitorsDetail?.identityCardPicture?.url,
      employee,
      jobPosition,
      vehicleType,
      itemPictures: itemPictures,
      id: values.id,
      groupId,
      startDate,
      endDate,
      contactDate,
      department,
      createdAt,
    });
  };

  const initialValuesReceiver = (values: IReceiver) => {
    if (!values) return;
    const { receiverDetail } = values;
    const { lnInboundDetail } = values;
    const pickupDate = dayjs(receiverDetail?.pickupDate);
    const vehicleType = receiverDetail?.vehicleType?.id;
    const qrcodeNumber = values?.qrcodeNumber;
    const startDate = receiverDetail?.startDate
      ? dayjs(receiverDetail?.startDate)
      : undefined;
    const endDate = receiverDetail?.endDate
      ? dayjs(receiverDetail?.endDate)
      : undefined;
    const createdAt = values?.createdAt ? dayjs(values?.createdAt) : undefined;
    const businessPartner = lnInboundDetail?.lnInbound.businessPartnerId
    const pickupPoint = lnInboundDetail?.lnInbound.pickupPointId
    const transactionType = lnInboundDetail?.lnInbound.transType
    const productType = lnInboundDetail?.lnInbound.prodType
    const rgnoType = lnInboundDetail?.lnInbound.rgnoType
    form.setFieldsValue({
      ...values,
      ...receiverDetail,
      startDate,
      endDate,
      companyName: values?.companyName ? values?.companyName : undefined,
      profile: values?.profile?.url,
      identityCardPicture: receiverDetail?.identityCardPicture?.url,
      qrcodeNumber,
      pickupDate,
      vehicleType,
      id: values.id,
      createdAt,
      businessPartner,
      pickupPoint,
      transactionType,
      productType,
      rgnoType,
    });
  };

  const initialValuesContractor = (values: IContractor) => {
    if (!values) return;
    const { contractorDetail } = values;
    const startDate = contractorDetail?.startDate
      ? dayjs(contractorDetail?.startDate)
      : undefined;
    const endDate = contractorDetail?.endDate
      ? dayjs(contractorDetail?.endDate)
      : undefined;
    const jobType = contractorDetail?.jobType?.id;
    const contractorImages = contractorDetail?.contractorImages || [];
    const vehicleInfos = values?.vehicleInfos?.map((e) => {
      const {
        vehicleBrand,
        vehicleImage,
        vehicleModel,
        licensePlateNumber,
        provincePlate,
      } = e;
      return {
        vehicleBrand,
        vehicleImage,
        vehicleModel,
        licensePlateNumber,
        provincePlate,
      };
    });

    const groupId = userGroups.data?.data?.find(
      (e) => e.id === values?.contractorDetail?.groupId
    )?.id;
    const createdAt = values?.createdAt ? dayjs(values?.createdAt) : undefined;

    const { title } = translationObject({
      object: contractorDetail?.department,
      keys: ["title"],
    });

    const department = contractorDetail?.department ? title : undefined;

    form.setFieldsValue({
      ...values,
      ...contractorDetail,
      companyName: values?.companyName ? values?.companyName : undefined,
      organizationId: values?.organizationCustomer?.organization?.id ? values?.organizationCustomer?.organization?.id : undefined,
      profile: values?.profile?.url,
      identityCardPicture: contractorDetail?.identityCardPicture?.url,
      startDate,
      endDate,
      jobType,
      contractorImages: contractorImages,
      id: values.id,
      vehicles: vehicleInfos,
      groupId,
      createdAt,
      department,
      workAreaId: values?.workAreaId,
    });
  };

  const initialValuesSupplier = (values: ISupplier) => {
    if (!values) return;
    const { supplierDetail } = values;
    const { lnInboundDetail } = values;
    const numberOfPerson = supplierDetail?.numberOfPerson;
    const itemPictures = supplierDetail?.itemPictures || [];
    const employee = supplierDetail?.employee?.id;
    const jobPosition = supplierDetail?.jobPosition?.id;
    const vehicleType = supplierDetail?.vehicleType?.id;
    const department = supplierDetail?.department?.id;
    const supplierJobType = supplierDetail?.supplierJobTypes.map(
      (item) => item?.supplierType?.id
    );
    const startDate = supplierDetail?.startDate
      ? dayjs(supplierDetail?.startDate)
      : undefined;
    const endDate = supplierDetail?.endDate
      ? dayjs(supplierDetail?.endDate)
      : undefined;
    const createdAt = values?.createdAt ? dayjs(values?.createdAt) : undefined;
    const businessPartner = lnInboundDetail?.lnInbound.businessPartnerId
    const pickupPoint = lnInboundDetail?.lnInbound.pickupPointId
    const transactionType = lnInboundDetail?.lnInbound.transType
    const productType = lnInboundDetail?.lnInbound.prodType
    const rgnoType = lnInboundDetail?.lnInbound.rgnoType
    // const scale = _pickupPoint?.data?.data?.scales?.scalesDescription


    form.setFieldsValue({
      ...values,
      ...supplierDetail,
      companyName: values?.companyName ? values?.companyName : undefined,
      profile: values?.profile?.url,
      numberOfPerson: numberOfPerson,
      haveItem: supplierDetail?.haveItem,
      identityCardPicture: supplierDetail?.identityCardPicture?.url,
      employee,
      jobPosition,
      vehicleType,
      supplierJobType,
      itemPictures: itemPictures,
      id: values.id,
      startDate,
      endDate,
      createdAt,
      department,
      businessPartner,
      pickupPoint,
      transactionType,
      productType,
      rgnoType,
      // scale
    });
  };

  const getIdCard = () => {
    postIdCard.mutate(
      {},
      {
        onSuccess: (data: IIdCardPDK) => {
          if (data.id) {
            const gender = data.txtGender === "หญิง" ? "FEMALE" : "MALE";
            setParams({ ...params, identityCode: data.id });
            form.setFieldsValue({
              identityCode: data.id,
              firstName: data.txtname_T,
              lastName: data.txtLastName_T,
              gender: gender,
            });
          } else {
            fireNotification({
              type: "error",
              description: t("insert-your-id-card"),
            });
          }
        },
        onError: ({ message }: any) => {
          fireNotification({ type: "error", description: message });
        },
      }
    );
  };

  

  const loading = action?.get.isFetching;

  const disabledDate = (current: dayjs.Dayjs) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };

  const groupId = Form.useWatch("groupId", form);
  const acceptStatus = Form.useWatch("acceptStatus", form);

  const pending = notDisablePending ? false : pendingListPage;

  return (
    <PageContent
      loading={loading && !!id}
      breadcrumb={{
        form,
        shadow: false,
        mutate: {
          mutation: id ? action?.patch : action?.post,
          invalidateQueries: action?.invalidateQueries,
          onSaveAndContinue: !id,
          onSave: !pending,
          onBeforeUpdate: (v) => {
            if (!Number(v.department))
              v.department = dataById?.contractorDetail?.department?.id;
            if (menu !== "contractor") {
              if (!v.startDate) {
                v.startDate = dayjs();
              }
              if (!v.endDate) {
                v.endDate = dayjs(v.startDate).add(24, "hour");
              }
              return v;
            }
            return v;
          },
        },
      }}
      className="p-5"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          userType: userType[menu],
          productList: false,
          registerType: "ONSITE",
        }}
      >
        <PdpaInformation pending={pending} form={form} menu={menu} />
        <Container height="100%" padding="p-0">
          <DefaultInformation
            pageKey={pageKey}
            registerType={dataById?.registerType || "ONSITE"}
          />
          <div className="p-5">
            <Form.Item name="id" hidden>
              <CInput />
            </Form.Item>
            <Form.Item name="userType" hidden>
              <CInput />
            </Form.Item>
            <Form.Item name="registerType" hidden>
              <CInput />
            </Form.Item>

            {menu !== "contractor" ? (
              [
                <TemperatureInformation
                  pending={pending}
                  acceptPdpa={acceptPdpa}
                  key="1"
                />,
                <Row gutter={24} key="2">
                  {menu !== "receiver" ? (
                    <Col span={16}>
                      <Form.Item
                        rules={[{ required: true }]}
                        name="companyName"
                        label={t("company-name")}
                      >
                        <CInput
                          placeholder={t("company-name")}
                          disabled={pending}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={8}>
                      <Form.Item
                        name="pickupDate"
                        rules={[{ required: true }]}
                        label={t("pick-up-date")}
                      >
                        <CDatePicker
                          disabled={pending}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {pending && (
                    <Col span={8}>
                      <Form.Item name="acceptStatus" label={t("status")}>
                        <CSelect.AcceptStatus disabled={pending} />
                      </Form.Item>
                    </Col>
                  )}
                  {menu === "receiver"
                    ? [
                      <SupplierInformation pending={pending} key="3" />,
                      <StartDateEndDate pending={pending} key="7" />,
                      <DateRecordingData />,
                      <EmployeeField pending={pending} key="4" />,
                      <ReceiverDetailInput pending={pending} form={form} />,
                    ]
                    : [
                      <DriverInformation
                        pending={pending}
                        getIdCard={getIdCard}
                        isLoading={postIdCard.isLoading || isFetching}
                        menu={menu}
                        key="5"
                      />,
                      <StartDateEndDate pending={pending} key="6" />,
                      <>
                        {menu === "visitor" ? (
                          <DateToContact pending={pending} />
                        ) : null}
                      </>,
                      <>
                        {menu === "supplier" ? <DateRecordingData /> : null}
                      </>,
                      <VehicleField pending={pending} menu={menu} key="7" />,
                      <ContactInformation
                        menu={menu}
                        pending={pending}
                        form={form}
                        key="8"
                      />,
                    ]}
                </Row>,
              ]
            ) : (
              <div>
                <ContractorPage pending={pending} />
              </div>
              
            )}
          </div>
        </Container>
        {menu !== "visitor" ? (
          <ImageField pending={pending} />
        ) : (
          <FileInformation form={form} pending={pending} />
        )}
        {(menu === "contractor" || menu === "visitor") && id && (
          <AccessConTrol
            pending={pending}
            groupId={groupId}
            acceptStatus={acceptStatus}
          />

        
        )}
      </Form>
    </PageContent>
  );
};

const StartDateEndDate = ({ pending }: { pending: boolean }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col span={8}>
        <Form.Item
          name="startDate"
          label={t("start-date")}
          rules={[{ required: true }]}
        >
          <CDatePicker showTime disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="endDate"
          label={t("end-date")}
          rules={[{ required: true }]}
        >
          <CDatePicker showTime disabled={pending} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const DateToContact = ({ pending }: { pending: boolean }) => {
  const { t } = useTranslation();
  const currentTime = dayjs();
  return (
    <React.Fragment>
      <Col span={8}>
        <Form.Item
          label={t("registration-date")}
          name="createdAt"
          initialValue={currentTime}
        >
          <CDatePicker disabled showTime />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const DateRecordingData = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={8}>
        <Form.Item
          label={t("registration-date")}
          name="createdAt"
          initialValue={dayjs()}
        >
          <CDatePicker disabled />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const ReceiverDetailInput = ({ pending, form }: { pending: boolean, form: FormInstance; }) => {
  const { t } = useTranslation();
  const pickupPoint = Form.useWatch("pickupPoint")
  const [stateOpt, setStateOpt] = useState<IPickupPoint[]>()
  useEffect(() => {
    if (!stateOpt?.length) return
    const scale = stateOpt.find(e => e.id === pickupPoint)?.scales
    form.setFieldValue("scale", scale?.scalesDescription)
  }, [pickupPoint])

  return (
    <React.Fragment>
      {/* <Divider /> */}
      <Col span={24}>
        <Form.Item label={t("place-send-product")} name="shipTo">
          <CTextArea rows={5} placeholder={t("place-send-product")} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label={t("details")} name="description">
          <CTextArea rows={5} placeholder={t("details")} />
        </Form.Item>
      </Col>
      <Col span={24} className="mb-4">
        <Typography.Text className="!text-primary-dark">
          {t("erp-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item label={t("type-erp")} name="rgnoType">
          <CSelect.ErpType disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("product-group")} name="productType">
          <CSelect.ErpProduct disabled={pending} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("transaction-type")} name="transactionType">
          <CSelect.ErpTransactionType disabled={pending} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label={t("pickup-point")}
          name="pickupPoint"
        >
          <CSelect.ErpPickupPoint
            placeholder={t("pickup-point")}
            disabled={pending}
            // onChange={(e, opt: IPickupPoint) => {
            //   form.setFieldValue("scale", opt.scales.scalesDescription);
            // }}
            setStateOptions={setStateOpt}
          />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item name="scale" label={t("scale")}>
          <CInput
            placeholder={t("scale")}
            disabled={true}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("bp-code")} name="businessPartner">
          {/* <CInput placeholder={t("bp-code")} /> */}
          <CSelect.ErpBusinessPartner />
        </Form.Item>
      </Col>

    </React.Fragment>
  );
};

export default UserWithPDPACreateAndEditPage;
