import { useMainMenu } from "provider/main-menu";
import { Col, Modal, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { IMainMenu } from "router/interface";
import styled from "styled-components";
import Icon from "@ant-design/icons";
import { useNavigator } from "provider/navigator";
import { useAppRoleMenu } from "provider/app-role-menu";
import React from "react";
import tw from "twin.macro";

const StyledModal = styled(Modal)`
  padding: 0;
  max-width: 100vw;
  width: 100% !important;
  .ant-modal-content {
    height: 100%;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

const MainMenu = () => {
  const { visible, toggleMenu } = useMainMenu();
  const { roleMenus } = useAppRoleMenu();

  return (
    <StyledModal
      visible={visible}
      onCancel={toggleMenu}
      footer={null}
      title={null}
      closable={false}
      transitionName=""
      maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
      // maskTransitionName=""
      centered
    >
      <div onClick={toggleMenu} className="center">
        <Row justify="center" gutter={[12, 12]} style={{ width: 1008 }}>
          {roleMenus.map((item, index) => {
            if (index === 0) {
              return (
                <React.Fragment key={item.key}>
                  <Col span={3}></Col>
                  <Col span={6}>
                    <Menu item={item} />
                  </Col>
                </React.Fragment>
              );
            }
            if (index === 2) {
              return (
                <React.Fragment key={item.key}>
                  <Col span={6}>
                    <Menu item={item} />
                  </Col>
                  <Col span={3}></Col>
                </React.Fragment>
              );
            }
            return (
              <Col span={6} key={item.key}>
                <Menu item={item} />
              </Col>
            );
          })}
        </Row>
      </div>

      {/* <Row onClick={toggleMenu}>
        <Col xs={1} lg={2} xxl={4}></Col>
        <Col xs={22} lg={20} xxl={16}>
          <Row gutter={[12, 12]} justify="center">
            {roleMenus.map((item) => {
              return (
                <Col key={item.key}>
                  <Menu item={item} />
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col xs={1} lg={2} xxl={4} onClick={toggleMenu}></Col>
      </Row> */}
    </StyledModal>
  );
};

const MenuWrapper = styled.div`
  // height: 200px;
  // width: 200px;
  background-color: white;
  ${tw`rounded-app`};
  transition: all 0.2s ease-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    border: 3px solid #1a5527;
  }
`;

const Menu = ({ item }: { item: IMainMenu }) => {
  const { t } = useTranslation("menu");
  const { onAddTab } = useNavigator();
  // const { xl } = Grid.useBreakpoint();

  const onAdd = () => {
    onAddTab(item);
  };

  const getSize = () => {
    // if (xl) {
    return { width: 240, height: 240, fontSize: 150 };
    // }
    // return { width: 200, height: 200, fontSize: 120 };
  };

  const { width, height, fontSize } = getSize();

  return (
    <MenuWrapper style={{ width, height }} className="p-3" onClick={onAdd}>
      <div className="center">
        <Icon component={item.icon} style={{ fontSize: fontSize }} />
      </div>
      <Typography.Title className="text-center" level={5}>
        {t(item.key)}
      </Typography.Title>
    </MenuWrapper>
  );
};

export default MainMenu;
