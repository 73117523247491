import { Typography, Divider, Row, Col } from "antd";
import numeral from "numeral";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CProgress from "../c-progress";
import Container from "../container";
import { percentage } from "tools/utils";

export interface PercentCardProps {
  title: string;
  value?: number;
  max?: number;
}

const PercentCard: FC<PercentCardProps> = ({ title, value = 0, max = 0 }) => {
  const { t } = useTranslation();
  const percent = percentage();
  
  return (
    <Container className="!shadow" padding="p-0" height="auto">
      <div className="p-4">
        <Typography.Title level={5}>{title}</Typography.Title>
      </div>
      <Divider className="!m-0" />
      <Row
        style={{ height: 82 }}
        justify="space-between"
        align="middle"
        className="p-4"
      >
        <Col>
          <Typography.Text type="secondary">{t("quantity")}</Typography.Text>
          <Typography.Title level={5}>
            {numeral(value).format("0,0")} / {numeral(max).format("0,0")}
          </Typography.Title>
        </Col>
        <Col>
          <CProgress type="circle" percent={percent} width={50} />
        </Col>
      </Row>
    </Container>
  );
};

export default PercentCard;
