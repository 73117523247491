import { Image, ImageProps } from "antd";
import { FallbackImage } from "assets/images";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { fileToDataUrl } from "tools/file";
import tw from "twin.macro";
import fallback from "../../assets/images/holder/fallback.jpeg";
import CIndicator from "./c-indicator";

const StyledImage = styled(Image)<{ $rounded: boolean }>`
  ${({ $rounded }) => ($rounded ? tw`rounded-app` : "")};
`;

interface CImageProps extends Omit<ImageProps, "src"> {
  rounded?: boolean;
  src?: string | File;
}

const CImage = ({
  preview = false,
  rounded = true,
  src,
  ...props
}: CImageProps) => {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const initial = async () => {
    if (!src) return setTimeout(() => setUrl(fallback), 3000);
    if (typeof src === "string") {
      return setUrl(src);
    }
    const _src = await fileToDataUrl(src);
    if (typeof _src === "string") {
      return setUrl(_src);
    }
  };

  return (
    <StyledImage
      $rounded={rounded}
      style={{ objectFit: "cover" }}
      height="100%"
      width="100%"
      placeholder={
        <CIndicator
          height={
            props?.height || props.style?.height || props.wrapperStyle?.height
          }
        />
      }
      src={url}
      {...props}
      preview={preview}
      fallback={fallback}
      onError={({ currentTarget }) => {
        console.log(123456, currentTarget);
        currentTarget.onerror = null;
        currentTarget.src = FallbackImage;
      }}
    />
  );
};

export default CImage;
