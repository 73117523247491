import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectStatusQuickLab: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("status")}
      {...props}
      valueOptions={{
        values: [
          // { title: t("inspect"), value: "WAITING_FOR_INSPECT" },
          { title: t("pass"), value: "PASSED_THE_INSPECTION" },
          { title: t("not-pass"), value: "FAILED_THE_INSPECTION" },
          { title: t("conditionally-passed"), value: "VIP" },
        ],
        valueKey: "value",
        titleKey: "title",
      }}
    />
  );
};

export default CSelectStatusQuickLab;
