import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IEmployee } from "services/employee/interface";
import {
  IEmployeeNotificationParams,
  INotificationSetting,
  INotificationSettingParams,
  ITemplateNotification,
  ITemplateNotificationParams,
} from "./interface";

export const useGetNotificationSettings = (
  params: INotificationSettingParams
): UseQueryResult<IResponse<INotificationSetting[]>, Error> => {
  return useQuery(
    ["get-notification-settings", params],
    async () => {
      const { wait } = params;
      if (wait) return;
      const res = await axios.get("/notification-setting", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetNotificationSetting = (
  id?: string
): UseQueryResult<INotificationSetting, Error> => {
  return useQuery(
    ["get-notification-setting", id],
    async () => {
      const res = await axios.get(`/notification-setting/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostNotificationSetting = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axios.post(`/notification-setting`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteNotificationSetting = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/notification-setting/${id}`);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteNotificationSettings = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/notification-setting/bulk`, { data });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetEmployeeNotification = (
  params: IEmployeeNotificationParams
): UseQueryResult<IResponse<IEmployee[]>, Error> => {
  return useQuery(
    ["get-employee-notification", params],
    async () => {
      const { wait } = params;
      if (wait) return;
      const res = await axios.get("/notification-setting/not-in-notification", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

// template notification
export const useGetTemplateNotifications = (
  params: ITemplateNotificationParams
): UseQueryResult<IResponse<ITemplateNotification[]>, Error> => {
  return useQuery(
    ["get-template-notification", params],
    async () => {
      const { wait } = params;
      if (wait) return;
      const res = await axios.get("/template-notification", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const usePostTemplateNotifications = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ ...data }: any) => {
    const res = await axios.post(`/template-notification`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
