import { useQueryClient } from "@tanstack/react-query";
import { Col, Divider, Form, Row, TableColumnsType, Typography } from "antd";
import GroupPersonList from "components/access-control/group-list";
import { fireConfirmRemovePerson } from "components/access-control/modal-remove-person";
import CAvatar from "components/display/c-avatar";
import CDrawer from "components/display/c-drawer";
import CInfiniteScroll from "components/display/c-infinite-scroll";
import CTable from "components/display/c-table";
import CInput from "components/input/c-input";
import { ISearchBoxElement } from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import { useAccessibilityDeterminedAccessLevel } from "provider/accessibility-determined-by-access-level";
import { useAppLoading } from "provider/app-loading";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetAccessibilityInGroup,
  useGetAccessibilityNotInGroup,
  usePatchRemovePersonInAccess,
  usePostAddPersonAccess,
} from "services/accessibility";
import {
  IAccessibilityInGroup,
  IAccessibilityNotInGroupParams,
  IAccessibilityParams,
} from "services/accessibility/interface";
import { useGetUserGroupsrScroll } from "services/user-group";
import { IUserGroup, IUserGroupParams } from "services/user-group/interface";

const DeterminedByAccessAll = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const { accessibility, setUserGroup } =
    useAccessibilityDeterminedAccessLevel();

  const [params, setParams] = useState<IAccessibilityParams>({
    page: 1,
    limit: 10,
  });

  const data_in_group = useGetAccessibilityInGroup({
    ...params,
    accessibilityId: accessibility?.id,
  });

  const patch_remove = usePatchRemovePersonInAccess();
  const qClient = useQueryClient();

  const { setAppLoading } = useAppLoading();

  const elements: ISearchBoxElement[] = [
    {
      name: "fullName",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "identityCode",
      label: t("identification-card-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "userType",
      label: t("person-type"),
      input: {
        type: "SelectUserType",
      },
    },
    {
      name: "companyName",
      label: t("company-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: TableColumnsType<IAccessibilityInGroup> = [
    {
      title: t("profile-picture"),
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url || ""} />;
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        const phoneNumber = rc?.phoneNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: phoneNumber }}>
            {phoneNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("identification-card-number"),
      render: (_, rc) => {
        const identityCode = rc?.identityCode || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: identityCode }}>
            {identityCode}
          </Typography.Text>
        );
      },
    },
    {
      title: t("company-name"),
      render: (_, rc) => {
        const companyName = rc?.companyName || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: companyName }}>
            {companyName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      render: (_, rc) => {
        const type =
          rc?.userType === "VISITOR"
            ? t("guests")
            : rc?.userType === "CONTRACTOR"
            ? t("contractor-full-time")
            : rc?.userType === "SUPPLIER"
            ? t("supplier-deliver-goods")
            : t("consignee");

        return (
          <Typography.Text ellipsis={{ tooltip: type }}>
            {type || "-"}
          </Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  const toggle = () => {
    setVisible(!visible);
    setUserGroup(undefined);
  };

  const onRemove = async (value: any) => {
    const remove = await fireConfirmRemovePerson();
    if (remove) {
      patch_remove.mutate(
        { accessibilityId: accessibility?.id, personId: value },
        {
          onSuccess: () => {
            fireNotification({ type: "success", menu: "access-control" });
            qClient.invalidateQueries(["get-accessibilitys"]);
            qClient.invalidateQueries(["get-in-group-accessibility"]);
            qClient.invalidateQueries(["get-not-in-group-accessibility"]);
          },
          onError: ({ message }: any) => {
            fireNotification({
              type: "error",
              description: message,
              menu: "access-control",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    }

    if (
      (data_in_group?.data?.data?.length || 0) <= 1 ||
      (value.length || 0) >= (data_in_group?.data?.data?.length || 0)
    ) {
      setParams({ ...params, page: 1 });
    }
  };

  return (
    <React.Fragment>
      <CreateAndEditDrawer visible={visible} onClose={toggle} />
      <CTable
        scroll={{ x: 1100 }}
        action={{
          searchBox: { elements: elements, onFinish: onSearch, colSpan: 12 },
        }}
        shadow={false}
        wrapperClassName="!mt-0"
        title={t("list-all")}
        columns={columns}
        onAdd={{ onClick: toggle }}
        query={data_in_group}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        extraHeaderButton={[
          {
            children: t("remove-access"),
            theme: "white",
            onClick: (_, selectRowsKey) => {
              onRemove(selectRowsKey);
            },
          },
        ]}
      />
    </React.Fragment>
  );
};

const CreateAndEditDrawer: FC<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { accessibility, userGroup, setUserGroup } =
    useAccessibilityDeterminedAccessLevel();

  const [paramsUserGroup, setParamsUserGroup] = useState<IUserGroupParams>();
  const data_user_group = useGetUserGroupsrScroll(paramsUserGroup);

  const [paramsNotIn, setParamsNotIn] =
    useState<IAccessibilityNotInGroupParams>({});

  const { data } = useGetAccessibilityNotInGroup({
    ...paramsNotIn,
    accessibilityId: accessibility?.id,
    userGroupId: userGroup?.id,
  });

  const post_person = usePostAddPersonAccess();
  const qClient = useQueryClient();

  const { setAppLoading } = useAppLoading();

  const onSearchUesrGroup = (value: any) => {
    setParamsUserGroup({ ...paramsUserGroup, search: value.target.value });
  };

  const columns: TableColumnsType<IAccessibilityInGroup> = [
    {
      title: t("profile-picture"),
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url || ""} />;
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstName || "";
        const lastName = rc?.lastName || "";
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text
            ellipsis
          >{`${firstName} ${lastName}`}</Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        return <Typography.Text>{rc?.phoneNumber || "-"}</Typography.Text>;
      },
    },
    {
      title: t("person-type"),
      render: (_, rc) => {
        const type =
          rc?.userType === "VISITOR"
            ? t("guests")
            : rc?.userType === "CONTRACTOR"
            ? t("contractor-full-time")
            : rc?.userType === "SUPPLIER"
            ? t("supplier-deliver-goods")
            : t("consignee");

        return <Typography.Text ellipsis>{type || "-"}</Typography.Text>;
      },
    },
  ];

  const onSearch = (value: any) => {
    setParamsNotIn({ ...paramsNotIn, search: value.target.value });
  };

  const onFinish = (values: any) => {
    post_person.mutate(
      { accessibilityId: accessibility?.id, personId: values.select },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "access-control" });
          qClient.invalidateQueries(["get-not-in-group-accessibility"]);
          qClient.invalidateQueries(["get-in-group-accessibility"]);
          qClient.invalidateQueries(["get-accessibilitys"]);
          onClose();
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "access-control",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  return (
    <CDrawer
      title={t("add")}
      form={form}
      visible={visible}
      onClose={onClose}
      onCancel={onClose}
      width="50vw"
      className="!p-0"
      onSave={onFinish}
      loading={post_person?.isLoading}
    >
      <Form form={form} layout="vertical">
        <Row>
          <Col span={8} className="p-5">
            <Form.Item label={t("search")}>
              <CInput onChange={onSearchUesrGroup} />
            </Form.Item>
            <Typography.Text>{t("group-of-person")}</Typography.Text>
            <div className="pt-3">
              <CInfiniteScroll
                mutation={data_user_group}
                className="pr-2"
                textColor="white"
                height="calc(100vh - 200px)"
                render={(data: IUserGroup[]) => {
                  return data?.map((item) => {
                    return (
                      <GroupPersonList
                        id={item.id}
                        groupName={item.title}
                        key={item?.id}
                        activeKey={userGroup}
                        onClick={() => {
                          setUserGroup(item);
                        }}
                      />
                    );
                  });
                }}
              />
            </div>
          </Col>
          <Col span={1} className="center ">
            <Divider
              type="vertical"
              style={{ height: `calc(133vh - 100px)` }}
            />
          </Col>
          <Col span={15} className="pt-5 pr-5">
            {!userGroup?.id ? (
              <div
                style={{ height: "calc(100vh - 200px)" }}
                className="center flex items-center"
              >
                <Typography.Text>{t("please-select")}</Typography.Text>
              </div>
            ) : (
              <div>
                <Form.Item label={t("search")}>
                  <CInput onChange={onSearch} />
                </Form.Item>
                <Form.Item name="select">
                  <CTable.Select
                    rowKey="id"
                    columns={columns}
                    dataSource={data?.data || []}
                    pagination={{
                      onChange: (page, limit) => {
                        setParamsNotIn({ ...paramsNotIn, page, limit });
                      },
                    }}
                  />
                </Form.Item>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </CDrawer>
  );
};

export default DeterminedByAccessAll;
