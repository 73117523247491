import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import CTable from "components/display/c-table";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  IParkingPeriodReport,
  IVehicleReportParams,
} from "services/vehicle-report/interface";
import {
  useExportParkingPeriodReport,
  useGETParkingPeriodReport,
} from "services/vehicle-report";
import { splitRangePicker } from "tools/utils";
import { useAppLanguage } from "provider/app-language";
import { Typography } from "antd";
import { useGetParkings } from "services/parking";

const VehicleParkingPeriodReport = () => {
  const { formatDate } = useAppLanguage();
  const { t } = useTranslation();
  const [params, setParams] = useState<IVehicleReportParams>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGETParkingPeriodReport(params);
  const export_excel = useExportParkingPeriodReport();

  const parkings = useGetParkings({ pagination: false });

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("date"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "fullname",
      label: `${t("first-name")} - ${t("last-name")}`,
      input: {
        type: "Input",
        options: {
          search: true,
          placeholder: `${t("first-name")} - ${t("last-name")}`,
        },
      },
    },
    {
      name: "plateNum",
      label: t("plate-number"),
      input: {
        type: "Input",
        options: { search: true, placeholder: `${t("plate-number")}` },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: { search: true, placeholder: `${t("phone-number")}` },
      },
    },
    {
      name: "parkingLots",
      label: t("parking-lot"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: parkings.data?.data || [],
            valueKey: "parkingName",
            titleKey: "parkingName",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<IParkingPeriodReport> = [
    {
      title: t("date"),
      width: 150,
      ellipsis: true,
      render: (_, rc) => {
        if (!rc.createAt) return <span>-</span>;
        const date = formatDate({ date: rc.createAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: `${t("first-name")} - ${t("last-name")}`,
      dataIndex: "firstName,lastName",
      align: "center",
      width: 200,
      ellipsis: true,
      render: (_, rc) => {
        const { firstName, lastName } = rc;
        const { employeeFirstName, employeeLastName } = rc;

        if (firstName && lastName)
          return <span>{`${firstName} ${lastName}`}</span>;

        if (employeeFirstName && employeeLastName)
          return <span>{`${employeeFirstName} ${employeeLastName}`}</span>;

        return <span>-</span>;
      },
    },
    {
      title: t("plate-number"),
      width: 100,
      ellipsis: true,
      render: (_, rc) => {
        return <span>{rc.licensePlate || "-"}</span>;
      },
    },
    {
      title: t("phone-number"),
      width: 150,
      ellipsis: true,
      render: (_, rc) => {
        const { phoneNumber, firstName, lastName } = rc;
        const { employeePhoneNumber, employeeFirstName, employeeLastName } = rc;
        if (firstName || lastName) return <span>{phoneNumber || "-"}</span>;
        if (employeeFirstName || employeeLastName)
          return <span>{employeePhoneNumber || "-"}</span>;
        return <span>-</span>;
      },
    },
    {
      title: `${t("parking-area")} (${t("parking-lot")})`,
      align: "center",
      width: 150,
      ellipsis: true,
      render: (_, rc) => {
        return <span>{rc.parkingLot || "-"}</span>;
      },
    },
    {
      title: `${t("entry-time")} - ${t("exit-time")}`,
      width: 150,
      ellipsis: true,
      render: (_, rc) => {
        if (!rc.entryDate) return <span>-</span>;
        const date = formatDate({ date: rc.entryDate, format: "normal-time" });
        const date2 = formatDate({ date: rc.exitDate, format: "normal-time" });
        return (
          <>
            <p>{date !== "Invalid Date" ? date : "-"}</p>
            <p>{date2 !== "Invalid Date" ? date2 : "-"}</p>
          </>
        );
      },
    },
    {
      title: t("total-duration"),
      width: 150,
      ellipsis: true,
      align: "center",
      render: (_, rc) => `${rc.diffDate?.hours || 0} ${t("hrs")}`,
    },
  ];

  const onSearch = (values: any) => {
    const date = splitRangePicker({
      arrays: values.date,
      startKey: "startDate",
      endKey: "endDate",
    });
    delete values.date;
    setParams({ ...params, ...values, ...date, page: 1 });
  };

  return (
    <div>
      <PageContent className="p-5" breadcrumb>
        <PageSearchBox elements={elements} onFinish={onSearch} />
        <CTable
          scroll={{ x: 1200 }}
          rowKey="id"
          selectAble={false}
          dataSource={data?.data || []}
          title={t("parking-period-report")}
          columns={columns}
          loading={isLoading}
          action={{
            excels: {
              export: { fileName: "string", mutation: export_excel },
            },
          }}
          pagination={{
            total: data?.count,
            current: params?.page,
            pageSize: params.limit,
            onChange: (page, limit) => {
              setParams({ ...params, page, limit });
            },
          }}
        />
      </PageContent>
    </div>
  );
};

export default VehicleParkingPeriodReport;
