import { Col, Divider, Row, Typography } from "antd";
import Container from "components/display/container";
import { UserTypeKey } from "config/axios/interface";
import { useAppLanguage } from "provider/app-language";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  InspectionTypes,
  IVehicleCondition,
} from "services/vehicle-condition/interface";
import styled from "styled-components";
import tw from "twin.macro";

const UserTypeWrapper = styled.div<{ type?: UserTypeKey }>`
  ${tw`p-2 text-center rounded-app`};
  background-color: ${({ type }) =>
    type === "RECEIVER" ? "#eaf7ec" : "#FFF6E0"};
  span {
    ${({ type }) =>
      type === "RECEIVER"
        ? tw`!text-primary-dark`
        : "color: #E0B33C !important;"}
  }
`;

interface InspectionDescriptionCardProps {
  menuKey: InspectionTypes;
  item?: IVehicleCondition;
  onClick: () => void;
}

const InspectionDescriptionCard: React.FC<InspectionDescriptionCardProps> = ({
  item,
  onClick,
}) => {
  const { t } = useTranslation();
  const { formatDate, translationObject } = useAppLanguage();

  const { createdAt, customerEmployee, vehicle } = item || {};
  const { receiverDetail, supplierDetail } = customerEmployee || {};

  const name =
    customerEmployee?.firstName && customerEmployee.lastName
      ? `${customerEmployee?.firstName} ${customerEmployee?.lastName}`
      : "-";
  const date = formatDate({
    date: createdAt,
    format: "normal-time",
  });

  const licensePlate = vehicle?.licensePlate || "-";
  const transportName = customerEmployee?.companyName || "-";
  const supplierType = supplierDetail?.supplierJobTypes?.map((item) => {
    const { title } = translationObject({
      object: item?.supplierType,
      keys: ["title"],
    });
    return title;
  });

  const productGroup =
    receiverDetail?.productGroups ||
    (supplierDetail ? supplierType?.toString() : undefined) ||
    "-";

  return (
    <React.Fragment>
      {/* <InspectionDescriptionDrawer visible={visible} onClose={toggle} /> */}
      <Container
        onClick={onClick}
        style={{ cursor: "pointer" }}
        height="auto"
        className="mb-3"
      >
        <Row>
          <Col>
            <Typography.Title level={5}>{name}</Typography.Title>
          </Col>
          <Col className="ml-auto"></Col>
          <Col>
            <Typography.Text style={{ fontSize: 18 }}>{date}</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>
              {customerEmployee?.phoneNumber || "-"}
            </Typography.Text>
          </Col>
        </Row>
        <Divider className="!my-3" />
        <Row align="middle">
          <Col span={14}>
            <VehicleField
              licensePlate={licensePlate}
              transportName={transportName}
              productGroup={productGroup}
            />
          </Col>
          <Col className="ml-auto">
            <UserTypeWrapper type={customerEmployee?.userType}>
              <Typography.Text>
                {t(
                  customerEmployee?.userType === "RECEIVER"
                    ? "user-management.consignee"
                    : "user-management.supplier",
                  { ns: "menu" }
                )}
              </Typography.Text>
            </UserTypeWrapper>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

interface IDetail {
  licensePlate: string;
  transportName: string;
  productGroup: string;
}

const VehicleField: React.FC<IDetail> = ({
  licensePlate,
  transportName,
  productGroup,
}) => {
  const { t } = useTranslation();
  return (
    <Row gutter={6}>
      <Col>
        <Typography.Text>{t("license-plate-number")}</Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text type="secondary">{licensePlate}</Typography.Text>
      </Col>
      <Col span={24}></Col>

      <Col>
        <Typography.Text>{t("transport-name")}</Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text type="secondary">{transportName}</Typography.Text>
      </Col>
      <Col span={24}></Col>

      <Col>
        <Typography.Text>{t("product-group")}</Typography.Text>
      </Col>
      <Col>:</Col>
      <Col>
        <Typography.Text type="secondary">{productGroup}</Typography.Text>
      </Col>
      <Col span={24}></Col>
    </Row>
  );
};

export default InspectionDescriptionCard;
