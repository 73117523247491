import { Col, Row, Typography } from "antd";
import { Dayjs } from "dayjs";
import { useAppLanguage } from "provider/app-language";
import { useSettingSchedule } from "provider/setting-schedule";
import SettingScheduleCalendar from "./calendar";

const SettingScheduleYearType = () => {
  const { currentTime } = useSettingSchedule();
  const { formatDate } = useAppLanguage();

  const getMonthInYear = () => {
    const { year } = currentTime;
    let months: Dayjs[] = [];
    for (let i = 0; i < 12; i++) {
      var month = year.clone().month(i);
      months = [...months, month];
    }
    return months;
  };

  const months = getMonthInYear();

  return (
    <div className="mt-5" style={{ height: "calc(100vh - 313px)", overflowY: "auto", overflowX: 'hidden' }}>
      <Row gutter={[24, 24]}>
        {months.map((item, index) => {
          const startDate = item.clone().startOf("M").format();
          const endDate = item.clone().endOf("M").format();
          const title = formatDate({ date: startDate, format: "normal" });
          return (
            <Col span={24} key={index}>
              <Typography.Title level={5}>{title}</Typography.Title>
              <SettingScheduleCalendar
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SettingScheduleYearType;
