import { Popover } from "antd";
import React from "react";
import { BlockPicker } from "react-color";
import CInput from ".";

export interface CInputColorProps {
  value?: string;
  onChange?: (v: string) => void;
}

const CInputColor: React.FC<CInputColorProps> = ({ onChange, value }) => {
  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      content={
        <BlockPicker
          onChange={(e) => onChange?.(e.hex)}
          color={value}
          triangle="hide"
        />
      }
    >
      <CInput
        prefix={
          <span
            style={{ width: 20, height: 20, backgroundColor: value }}
          ></span>
        }
      />
    </Popover>
  );
};

export default CInputColor;
