import { Row, Col, Typography, Form } from "antd";
import { LogoImage } from "assets/images";
import CImage from "components/display/c-image";
import CCard from "components/display/card";
import Container from "components/display/container";
import { typeOfUser } from "config/axios/interface";
import { useAppLanguage } from "provider/app-language";
import { useVehicleSocket } from "provider/vehicle-socket";
import { IReceiveVehicleSocket } from "provider/vehicle-socket/interface";
import QRCode from "qrcode.react";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ICustomerEmployee } from "services/vehicle-inspection/interface";
import styled from "styled-components";

const Wrapper = styled.div`
  label {
    font-size: 12px !important;
  }
  span:not(#fixed-size-typo) {
    font-size: 12px !important;
  }
`;

const TransitionDescription = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { receives, selected } = useVehicleSocket();

  useEffect(() => {
    const lastest = receives?.[0];
    if (lastest) {
      const { perSonType, trailerLicensePlateNumber, weight, trailerWeight } =
        findDataValue({ value: lastest?.customerEmployee });
      const { name, phoneNumber } = findDataBasic({ value: lastest });
      form.setFieldsValue({
        vehicleType: lastest.vehicleType,
        headPlate: lastest.licensePlate,
        userType: perSonType ? t(perSonType) : undefined,
        phoneNumber: phoneNumber,
        name: name,
        tailPlate: trailerLicensePlateNumber,
        headWeight: weight,
        tailWeight: trailerWeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receives.length]);

  if (!selected) {
    return (
      <Container height={280}>
        <Typography.Text>{t("basic-information")}</Typography.Text>
        <CCard.EmptySelectVideo style={{ height: 200 }} type="empty" />
      </Container>
    );
  }

  if (receives.length <= 0) {
    return (
      <Container height={280}>
        <Typography.Text>{t("basic-information")}</Typography.Text>
        <CCard.EmptySelectVideo style={{ height: 200 }} type="wait" />
      </Container>
    );
  }

  return (
    <Wrapper>
      <Container height={280} className="relative">
        <Form form={form} layout="horizontal">
          <Row gutter={[12, 0]}>
            <Col span={24}>
              <Typography.Text id="fixed-size-typo">
                {t("basic-information")}
              </Typography.Text>
            </Col>
            <Col span={9}>
              <Form.Item
                name="name"
                className="remove-space-form-item"
                label={t("full-name")}
              >
                <TextPreview />
              </Form.Item>
              <Form.Item
                name="userType"
                className="remove-space-form-item"
                label={t("person-type")}
              >
                <TextPreview />
              </Form.Item>
              <Form.Item
                name="headPlate"
                className="remove-space-form-item"
                label={t("trailer-head-license-plate-number")}
              >
                <TextPreview />
              </Form.Item>
              <Form.Item
                name="headWeight"
                className="remove-space-form-item"
                label={t("trailer-head-weight")}
              >
                <TextPreview />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="phoneNumber"
                className="remove-space-form-item"
                label={t("phone-number")}
              >
                <TextPreview />
              </Form.Item>
              <Form.Item
                name="vehicleType"
                className="remove-space-form-item"
                label={t("vehicle-type")}
              >
                <TextPreview />
              </Form.Item>
              <Form.Item
                name="tailPlate"
                className="remove-space-form-item"
                label={t("trailer-tail-license-plate-number")}
              >
                <TextPreview />
              </Form.Item>
              <Form.Item
                name="tailWeight"
                className="remove-space-form-item"
                label={t("trailer-tail-weight")}
              >
                <TextPreview />
              </Form.Item>
            </Col>
            <Col span={6} className="center flex-col">
              <QrPreview />
            </Col>
          </Row>
        </Form>

        <Row
          gutter={12}
          className="absolute w-full"
          style={{ height: 50, bottom: 16 }}
        >
          <LicensePlatePreview />
        </Row>
      </Container>
    </Wrapper>
  );
};

const LicensePlatePreview: React.FC = () => {
  const { selected, receives } = useVehicleSocket();

  if (!selected || !receives.length) return null;

  return (
    <React.Fragment>
      <Col style={{ height: 50 }} span={6}>
        <CImage
          height={50}
          className="rounded"
          src={receives?.[0]?.licensePlateImage?.url}
        />
      </Col>
      <Col span={6}>
        <div
          className="center rounded px-4"
          style={{ height: 50, border: "1px solid black" }}
        >
          <Typography.Title level={4} ellipsis>
            {receives?.[0]?.licensePlate}
          </Typography.Title>
        </div>
      </Col>
    </React.Fragment>
  );
};

const QrPreview = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const { receives } = useVehicleSocket();

  if (!receives.length) return null;
  if (!receives?.[0]?.customerEmployee?.uuid) {
    return (
      <div className="center flex-col">
        <CImage src={LogoImage} width="50%" height="auto" />
        <Typography.Text className="!mt-2">
          {t("no-qr-code-found", { ns: "message" })}
        </Typography.Text>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Typography.Text>
        {formatDate({ date: receives?.[0]?.createdAt, format: "normal-time" })}
      </Typography.Text>
      <QRCode
        height={180}
        className="my-3"
        value={receives?.[0]?.customerEmployee?.uuid}
        renderAs="canvas"
      />
      <Typography.Text>{t("qr-code")}</Typography.Text>
    </React.Fragment>
  );
};

const TextPreview: React.FC<{ value?: string }> = ({ value }) => {
  if (!value) {
    return <Typography.Text className="!text-primary-dark">-</Typography.Text>;
  }
  return (
    <Typography.Text className="!text-primary-dark">{value}</Typography.Text>
  );
};

export interface IFindData {
  perSonType?: string;
  trailerLicensePlateNumber?: string;
  weight?: string;
  trailerWeight?: string;
}

export const findDataValue = ({
  value,
}: {
  value?: ICustomerEmployee;
}): IFindData => {
  if (value) {
    const { userType } = value;
    let perSonType = typeOfUser[userType];

    if (value?.userType === "SUPPLIER") {
      const { trailerLicensePlateNumber, weight, trailerWeight } =
        value?.supplierDetail || {};
      return {
        perSonType,
        trailerLicensePlateNumber,
        weight: weight?.toString(),
        trailerWeight: trailerWeight?.toString(),
      };
    }
    if (value?.userType === "VISITOR") {
      const { trailerLicensePlateNumber, weight, trailerWeight } =
        value?.visitorsDetail || {};
      return {
        perSonType,
        trailerLicensePlateNumber,
        weight: weight?.toString(),
        trailerWeight: trailerWeight?.toString(),
      };
    }
    if (value?.userType === "RECEIVER") {
      const { trailerLicensePlateNumber } = value?.receiverDetail || {};
      return {
        perSonType,
        trailerLicensePlateNumber,
      };
    }
    return { perSonType };
  }

  return {};
};

export interface IFindDataBasic {
  name?: string;
  phoneNumber?: string;
  perSonType?: string;
  trailerLicensePlateNumber?: string;
  weight?: string;
  trailerWeight?: string;
}

export const findDataBasic = ({
  value,
}: {
  value?: IReceiveVehicleSocket;
}): IFindDataBasic => {
  if (value) {
    if (
      value.isVehicleCompany &&
      value?.vehicleCompany &&
      value?.vehicleCompany?.employee
    ) {
      const { firstName, lastName, phoneNumber } =
        value.vehicleCompany?.employee || {};
      const name =
        firstName && lastName ? `${firstName} ${lastName}` : undefined;
      return { name, phoneNumber };
    }

    if (
      value.isVehicleCompany &&
      value?.vehicleCompany &&
      !value?.vehicleCompany?.employee
    ) {
      const { firstName, lastName, phoneNumber } = value?.vehicleCompany || {};
      const name =
        firstName && lastName ? `${firstName} ${lastName}` : undefined;
      return { name, phoneNumber };
    }

    const { firstName, lastName, phoneNumber } = value.customerEmployee || {};
    const name = firstName && lastName ? `${firstName} ${lastName}` : undefined;
    return { name, phoneNumber };
  }

  return {};
};

export default TransitionDescription;
