import CModal from "components/display/c-modal";
import React, { FC, useEffect, useRef, useState } from "react";
import * as faceapi from "face-api.js";
import { useTranslation } from "react-i18next";
import AppLoadingLoader from "components/layout/app-loading-loader";
import { VideoCameraFilled } from "@ant-design/icons";
import { Typography } from "antd";
import CButton from "components/button/c-button";
import CModalFooter from "components/display/c-modal/modal-footer";
import styled from "styled-components";
import { useCaptureModalProvider } from "provider/capture-modal";
import { dataUrlToFile } from "tools/file";

interface CaptureModalProps {
  visible: boolean;
  onClose: () => void;
  onFinish: (file: File, base64: string) => void;
}

const displaySize = {
  width: 300,
  height: 300,
};

const Mask = styled.div`
  z-index: 2;
  position: absolute;
  width: 200px;
  height: 200px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 0 50px rgba(0, 0, 0, 0.5);
`;

const CaptureModal: React.FC<CaptureModalProps> = ({
  visible,
  onClose,
  onFinish,
}) => {
  const { t } = useTranslation();
  const { isModelLoaded, stream, canvasRef } = useCaptureModalProvider();

  const onSubmit = async () => {
    if (!canvasRef.current) return;
    const image = canvasRef.current.toDataURL("image/png", 1);
    const file = await dataUrlToFile(image);
    onFinish(file, image);
    onClose();
  };

  return (
    <CModal
      title={t("face-capture")}
      footer={
        <CModalFooter
          submit={{ onClick: onSubmit }}
          cancel={{ onClick: onClose }}
        />
      }
      visible={visible}
      onCancel={onClose}
    >
      <div className="mx-auto relative" style={{ ...displaySize }}>
        <Mask hidden={!isModelLoaded || !stream} />
        <Content />
      </div>
    </CModal>
  );
};

const Content = () => {
  const { t } = useTranslation();
  const interval = useRef<ReturnType<typeof setInterval>>();
  const [isScanned, setIsScanned] = useState<boolean>(false);
  const {
    setIsModelLoaded,
    videoRef,
    canvasRef,
    setStream,
    isModelLoaded,
    stream,
  } = useCaptureModalProvider();

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(() => {
        setIsModelLoaded(true);
        openVideo();
      });
    };
    loadModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openVideo = async () => {
    try {
      setIsScanned(false);
      const request = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user", ...displaySize },
        audio: false,
      });

      if (request.active && videoRef.current) {
        videoRef.current.srcObject = request;
        videoRef.current.play();
        setStream(request);
      }
    } catch (err) {
      //
    }
  };

  const closeVideo = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
    }
  };

  const onPlay = () => {
    interval.current = setInterval(async () => {
      if (!videoRef.current || !canvasRef.current || isScanned) return;
      faceapi.matchDimensions(canvasRef.current, displaySize);
      const detections = await faceapi
        .detectSingleFace(
          videoRef.current,
          new faceapi.TinyFaceDetectorOptions()
        )
        .withFaceLandmarks()
        .withFaceExpressions();
      if (!detections) {
        return console.log("detections is undefined");
      }
      const resizedDetections = faceapi.resizeResults(detections, displaySize);

      if (!resizedDetections) return;

      const frame_x = resizedDetections.detection.box.x;
      const frame_y = resizedDetections.detection.box.y;
      const score = resizedDetections.detection.score;
      console.log("frame_x", frame_x);
      console.log("frame_y", frame_y);
      console.log("_________________________");
      console.log("score", score);

      // if (frame_x < 65 || frame_x > 95 || frame_y < 95 || frame_y > 135) {
      //   // textRef.current.innerText = "ใกล้หรือใกลเกินไป";
      //   return console.log("not pass");
      // }

      if (score < 0.75) {
        return console.log("not pass");
      }

      // faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
      //   canvasRef.current.getContext('2d')?.drawImage()
      canvasRef.current
        .getContext("2d")
        ?.clearRect(0, 0, displaySize.width, displaySize.height);
      canvasRef.current
        ?.getContext("2d")
        ?.drawImage(
          videoRef.current,
          1,
          1,
          displaySize.width,
          displaySize.height
        );

      clearInterval(interval.current);
      setTimeout(() => {
        closeVideo();
        videoRef.current?.pause();
        setIsScanned(true);
      }, 1000);
    }, 1500);
  };

  return (
    <React.Fragment>
      <LoadingMedel hidden={isModelLoaded} />
      <AcceptCamera openVideo={openVideo} hidden={!!stream || !isModelLoaded} />
      <CButton
        hidden={!isScanned}
        style={{
          zIndex: 3,
          height: 30,
          left: 0,
          right: 0,
          bottom: 10,
          position: "absolute",
          width: 100,
          margin: "auto",
        }}
        onClick={openVideo}
      >
        {t("capture-again", { ns: "message" })}
      </CButton>
      <video
        ref={videoRef}
        {...displaySize}
        playsInline
        onPlay={onPlay}
        // style={{ transform: "scaleX(-1)", ...displaySize }}
        hidden={isScanned}
      />
      <canvas
        ref={canvasRef}
        {...displaySize}
        // style={{ transform: "scaleX(-1)", ...displaySize }}
        hidden={!isScanned}
      />
    </React.Fragment>
  );
};

const LoadingMedel: FC<{ hidden: boolean }> = ({ hidden }) => {
  const { t } = useTranslation();

  if (hidden) return null;

  return (
    <div className="center flex-col h-full">
      <AppLoadingLoader />
      {t("loading-the-model", { ns: "message" })}
    </div>
  );
};

const AcceptCamera: FC<{ openVideo: () => void; hidden: boolean }> = ({
  openVideo,
  hidden,
}) => {
  const { t } = useTranslation();

  if (hidden) return null;

  return (
    <div className="center flex-col h-full">
      <VideoCameraFilled
        style={{ fontSize: 40 }}
        className="!text-primary-dark"
      />
      <Typography.Text className="mt-4">
        {t("please-accept-access-to-the-camera", { ns: "message" })}
      </Typography.Text>
      <Typography.Text style={{ fontSize: 12 }} className="mt-2">
        {t("or", { ns: "message" })}
      </Typography.Text>
      <CButton
        onClick={openVideo}
        className="mt-3"
        style={{ width: 120, height: 35 }}
      >
        {t("click-here", { ns: "message" })}
      </CButton>
    </div>
  );
};

export default CaptureModal;
