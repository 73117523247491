import { Col, Form, FormInstance, Row } from "antd";
import CIndicator from "components/display/c-indicator";
import CModal from "components/display/c-modal";
import CModalFooter from "components/display/c-modal/modal-footer";
import CInput from "components/input/c-input";
import CRadio from "components/input/c-radio";
import CSelect from "components/input/c-select";
import { useVehicleFloorEdit } from "provider/vehicle-floor-edit";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetDeviceInFloor } from "services/floor";
import { useGetMark } from "services/mark";
import { IMark, MarkTypes } from "services/mark/interface";

interface MarkerPinCreateAndEditModalProps {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
  mark?: IMark;
  resetMark: () => void;
}

const MarkerPinCreateAndEditModal: FC<MarkerPinCreateAndEditModalProps> = ({
  visible,
  onClose,
  form,
  mark,
  resetMark,
}) => {
  const { t } = useTranslation();
  const { onCreateMark, onUpdateMark } = useVehicleFloorEdit();
  const { data, isFetching } = useGetMark(mark?.id);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        pin: data.pin?.id,
        encodeDevice: data.encodeDevice?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onFinish = (values: any) => {
    if (mark) {
      return onUpdateMark(values, onClose);
    }
    return onCreateMark(values, onClose);
  };

  return (
    <CModal
      footer={
        <CModalFooter
          submit={{ onClick: () => form.submit() }}
          cancel={{ onClick: onClose }}
        />
      }
      afterClose={() => {
        form.resetFields();
        resetMark();
      }}
      title={mark ? t("edit-marker") : t("add-marker")}
      visible={visible}
      onCancel={onClose}
      width={800}
      bodyStyle={{ minHeight: 345 }}
    >
      <CIndicator height={297} hidden={!isFetching} />
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ markType: "PARKING_AREA" }}
        hidden={isFetching}
      >
        <Form.Item name="id" hidden>
          <CInput />
        </Form.Item>
        <Form.Item name="xAxis" hidden>
          <CInput />
        </Form.Item>
        <Form.Item name="yAxis" hidden>
          <CInput />
        </Form.Item>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label={t("marker-type")} name="markType">
              <CRadio.MarkerType />
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.markType !== next.markType}
          >
            {(form) => {
              const markType: MarkTypes = form.getFieldValue("markType");
              switch (markType) {
                case "PARKING_AREA":
                  return <ParkingLotField />;
                case "CAMERA":
                  return <CameraField />;
                case "BARRIER":
                  return <BarrierField />;
                default:
                  return null;
              }
            }}
          </Form.Item>
        </Row>
      </Form>
    </CModal>
  );
};

const ParkingLotField = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={12}>
        <Form.Item name="markName" label={t("parking-number")}>
          <CInput placeholder={t("parking-number")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="encodeDevice" label={t("equipment-name")}>
          <SelectDevice />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="pin" label={t("parking-lot-type")}>
          <CSelect.Pin />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const CameraField = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={12}>
        <Form.Item
          rules={[{ required: true }]}
          name="markName"
          label={t("camera-name")}
        >
          <CInput placeholder={t("camera-name")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          rules={[{ required: true }]}
          name="encodeDevice"
          label={t("equipment-name")}
        >
          <SelectDevice />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          rules={[{ required: true }]}
          name="barrierType"
          label={t("gate-type")}
        >
          <CSelect
            placeholder={t("gate-type")}
            valueOptions={{
              values: [
                { value: "IN", label: t("entrance") },
                { value: "OUT", label: t("departure") },
              ],
              valueKey: "value",
              titleKey: "label",
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="detectVehicleType"
          label={t("detect-type")}
          rules={[{ required: true }]}
        >
          <CSelect
            placeholder={t("detect-type")}
            valueOptions={{
              values: [
                {
                  value: "VEHICLE",
                  title: t("vehicle"),
                },
                {
                  value: "MOTOR",
                  title: t("motorcycle"),
                },
              ],
              valueKey: "value",
              titleKey: "title",
            }}
          />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const BarrierField = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={12}>
        <Form.Item name="markName" label={t("barrier-name")}>
          <CInput placeholder={t("barrier-name")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="encodeDevice" label={t("equipment-name")}>
          <SelectDevice />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="alarmOutputIndexCode" label={t("barrier-number")}>
          <CInput.Number placeholder={t("barrier-number")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const SelectDevice: FC<{ onChange?: any; value?: any }> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const { floor } = useVehicleFloorEdit();
  const { data, isLoading } = useGetDeviceInFloor({
    pagination: false,
    id: floor.id,
  });
  return (
    <CSelect
      onChange={onChange}
      value={value}
      loading={isLoading}
      valueOptions={{
        values: data?.data || [],
        valueKey: "id",
        titleKey: "devName",
      }}
      placeholder={t("equipment-name")}
    />
  );
};

export default MarkerPinCreateAndEditModal;
