import { ComponentType, createContext, useContext, useState } from "react";
import { IVehicleConditionParams } from "services/vehicle-condition/interface";
import { IVehicleInspectionContext } from "./interface";

const Context = createContext<IVehicleInspectionContext>(
  {} as IVehicleInspectionContext
);

const withVehicleInspectionProvider = (Component: ComponentType) => {
  return () => {
    const [selectedId, setSelectedId] = useState<number | string>();
    const [params, setParams] = useState<IVehicleConditionParams>();

    return (
      <Context.Provider
        value={{
          selectedId,
          setSelectedId,
          params,
          setParams,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useVehicleInspection = () => useContext(Context);
export default withVehicleInspectionProvider;
