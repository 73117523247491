import {
  CheckCircleFilled,
  CloseCircleFilled,
  DownOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import { fireConfirmDelete } from "components/popup/confirm";
import { fireNotification } from "components/popup/notification";
import { useCameraGroup } from "provider/all-video";
import { useAppLoading } from "provider/app-loading";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteCameraGroup,
  useGetCameraDevicesScroll,
} from "services/camera-group";
import { ICameraGroupParams } from "services/camera-group/interface";
import CameraDrawer from "../drawer";

const AllVideos = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const qClient = useQueryClient();

  const [params, setParams] = useState<ICameraGroupParams>();
  const [useCheckBox, setUseCheckBox] = useState<boolean>(false);
  const { getCameraGroups, setCameraGroups, toggle } = useCameraGroup();
  const { setAppLoading } = useAppLoading();

  const camera_group = useGetCameraDevicesScroll(params);
  const delete_group = useDeleteCameraGroup();

  useEffect(() => {
    initialValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera_group.data]);

  const initialValue = () => {
    if (!camera_group.data) return;
    const pages = camera_group.data?.pages.length;
    const groups = Array.from(Array(pages).keys()).flatMap((e) => {
      return camera_group.data.pages[e].data;
    });
    const next = groups.map((group) => {
      const cameraGroupDevices = group.cameraGroupDevices.map((camera) => {
        const id = `${group.id}.${camera.id}`;
        return { ...camera, id, selected: false };
      });
      return { ...group, cameraGroupDevices };
    });
    setCameraGroups(next);
  };

  const deleteGroup = async () => {
    const id = getCameraGroups.find((e) => e.expand)?.id;
    const confirm = await fireConfirmDelete();
    if (confirm) {
      delete_group.mutate(
        { id },
        {
          onSuccess: () => {
            qClient.invalidateQueries(["get-camera-groups"]);
            qClient.invalidateQueries(["get-camera-groups-scroll"]);
            fireNotification({ type: "success", menu: "resource-management" });
          },
          onError: ({ message }: any) => {
            fireNotification({
              type: "error",
              description: message,
              menu: "resource-management",
            });
          },
          onSettled: () => {
            setAppLoading(false);
          },
        }
      );
    }
  };

  const render = () => {
    const onClickGroup = (id: number) => {
      const next = getCameraGroups?.map((group) => {
        let expand = group.id === id ? true : false;
        if (expand && group.expand) {
          expand = false;
        }
        return { ...group, expand };
      });
      setCameraGroups(next);
    };

    const onCheckGroup = (id: number) => {
      const next = getCameraGroups.map((group) => {
        if (group.id === id) {
          let selected = !group.selected;
          let indeterminate = group.indeterminate;
          if (indeterminate) {
            selected = true;
            indeterminate = false;
          }
          const cameraGroupDevices = group.cameraGroupDevices?.map((camera) => {
            return { ...camera, selected };
          });
          return { ...group, selected, cameraGroupDevices, indeterminate };
        }
        return group;
      });
      setCameraGroups(next);
    };

    const onCheckCamera = (id: string) => {
      const next = getCameraGroups.map((group) => {
        const groupId = Number(`${id}`.split(".")[0]);
        if (groupId === group.id) {
          const cameraGroupDevices = group.cameraGroupDevices?.map((camera) => {
            if (camera.id === id) {
              return { ...camera, selected: !camera.selected };
            }
            return camera;
          });
          const indeterminate = !cameraGroupDevices.every(
            (e) => e.selected === cameraGroupDevices[0]?.selected
          );
          const selected = indeterminate
            ? false
            : cameraGroupDevices.every((e) => e.selected);
          return { ...group, cameraGroupDevices, indeterminate, selected };
        }
        return group;
      });
      setCameraGroups(next);
    };

    return getCameraGroups.map((group) => {
      const selectedLength = group.cameraGroupDevices.filter(
        (e) => e.selected
      ).length;
      const groupLength = group.cameraGroupDevices.length;
      return (
        <React.Fragment key={group.id}>
          <CCard.BasicCard
            active={group.expand}
            onClick={() => (useCheckBox ? {} : onClickGroup(group.id))}
            className="my-3"
          >
            <Row justify="space-between" className="w-full p-4 text-[20px]">
              <Col
                className="flex items-end"
                onMouseOver={() => setUseCheckBox(true)}
                onMouseOut={() => setUseCheckBox(false)}
              >
                <Checkbox
                  checked={group.selected}
                  indeterminate={group.indeterminate}
                  onClick={() => onCheckGroup(group.id)}
                />
              </Col>
              <Col span={15} className=" flex items-center pl-2">
                <Typography.Text ellipsis={{ tooltip: group.title }}>
                  {group.title}
                </Typography.Text>
              </Col>
              <Col className="flex items-center">
                ({selectedLength}/{groupLength})
                <DownOutlined
                  rotate={group.expand ? 180 : 0}
                  className="transition duration-1000"
                />
              </Col>
            </Row>
          </CCard.BasicCard>
          {group.cameraGroupDevices?.map((camera) => {
            return (
              <Row
                key={camera.id}
                justify="space-between"
                className="w-full p-4"
                hidden={!group.expand}
              >
                <Col>
                  <Checkbox
                    checked={camera.selected}
                    onClick={() => {
                      onCheckCamera(camera.id);
                    }}
                  />
                </Col>
                <Col span={20}>
                  <Typography.Text ellipsis={{ tooltip: camera?.devName }}>
                    {camera?.devName}
                  </Typography.Text>
                </Col>
                <Col>
                  <CheckCircleFilled
                    className="!text-[#07B53B]"
                    hidden={!camera.selected}
                  />
                  <CloseCircleFilled
                    className="!text-[#FF9C00]"
                    hidden={camera.selected}
                  />
                </Col>
              </Row>
            );
          })}
        </React.Fragment>
      );
    });
  };

  const onToggle = (cameraAction?: boolean) => {
    const group = getCameraGroups.find((e) => e.expand);
    const title = group?.title;
    const device = group?.cameraGroupDevices.map((e) => e?.id);
    const editCamera = cameraAction ? true : false;
    form.setFieldsValue({ id: group?.id, title, device, editCamera });
    toggle();
  };

  return (
    <React.Fragment>
      <CameraDrawer form={form} />
      <InfiniteSidebar
        title={t("all-video-app")}
        actionButtonGroup={{
          onAdd: toggle,
          onEdit: () => onToggle(false),
          onDelete: deleteGroup,
          onAddCamera: () => onToggle(true),
          hide: {
            edit: !getCameraGroups.some((e) => e.expand),
            del: !getCameraGroups.some((e) => e.expand),
            addCamera: !getCameraGroups.some((e) => e.expand),
          },
        }}
        infiniteScroll={{
          render: render,
          className: "!h-100 px-5",
          search: {
            onChange: (e) => {
              setParams({ ...params, title: e });
            },
            placeholder: t("group-name"),
          },
        }}
      />
    </React.Fragment>
  );
};

export default AllVideos;
