import UserWithPDPACreateAndEditPage from "components/menu/user-management/create-user-pdpa";
import { usePageRoute } from "provider/page-route";
import { useGetContractor } from "services/contractor";

const PendingContructorTypeEditPage = () => {
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const data_contractor = useGetContractor(id);

  return (
    <UserWithPDPACreateAndEditPage
      menu="contractor"
      action={{ get: data_contractor }}
      pendingListPage
    />
  );
};

export default PendingContructorTypeEditPage;
