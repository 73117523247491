import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetJobTypes } from "services/setting-job-type";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

const CSelectJobType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetJobTypes}
      valueKey="id"
      titleKey="jobType"
      searchKey="jobType"
      placeholder={t("work-type")}
      translation
      queryParams={{
        // pagination: false
        // limit: 100
      }}
    />
  );
};
export default CSelectJobType;
