import { throwResponse } from "config/axios";
import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";
import { IPin, IPinParams } from "./interface";

export const usePinScroll = (
  params?: IPinParams
): UseInfiniteQueryResult<IResponse<IPin[]>, Error> => {
  return useInfiniteQuery(
    ["get-pins", params],
    async ({ pageParam }) => {
      const res = await axios.get("/pin", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetPins = (
  params?: IPinParams
): UseQueryResult<IResponse<IPin[]>, Error> => {
  return useQuery(
    ["get-pins", params],
    async () => {
      const res = await axios.get("/pin", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetPin = (id?: string): UseQueryResult<IPin[], Error> => {
  return useQuery(
    ["get-pin"],
    async () => {
      const res = await axios.get(`/pin/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostPin = (): UseMutationResult<unknown, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/pin`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchPin = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/pin/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeletePin = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/pin/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
