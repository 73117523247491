import { TableColumnsType, Typography } from "antd";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { typeOfUser } from "config/axios/interface";
import { useAppLanguage } from "provider/app-language";
import withVehicleInspectionProvider, {
  useVehicleInspection,
} from "provider/vehicle-inspection";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportVehicleConditionsFailed,
  useGetVehicleConditionsFailed,
} from "services/vehicle-condition";
import {
  IVehicleCondition,
  IVehicleConditionParams,
} from "services/vehicle-condition/interface";
import InspectionDescriptionDrawer from "../vehicle-inspection/content/description-drawer";

const VehicleInspectionFailedInspectionPage = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const { setSelectedId } = useVehicleInspection();

  const [visible, setVisible] = useState<boolean>(false);
  const [params, setParams] = useState<IVehicleConditionParams>({ page: 1 });

  const { data, isFetching } = useGetVehicleConditionsFailed({ ...params });
  const export_condition_failed = useExportVehicleConditionsFailed();

  const toggle = () => {
    setVisible(!visible);
  };

  const elements: ISearchBoxElement[] = [
    {
      label: t("transaction-date"),
      name: "date",
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
    {
      label: t("license-plate-number"),
      name: "licensePlate",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("full-name"),
      name: "name",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: `${t("company-name")} / ${t("transport-name")}`,
      name: "companyName",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("person-type"),
      name: "userType",
      input: {
        type: "SelectUserType",
      },
    },
  ];

  const columns: TableColumnsType<IVehicleCondition> = [
    {
      title: t("transaction-date"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const date = formatDate({
          date: rc?.createdAt,
          format: "normal-time",
        });
        return <Typography.Text>{date}</Typography.Text>;
      },
    },
    {
      title: t("license-plate-number"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.vehicle?.licensePlate) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.vehicle?.licensePlate }}>
            {rc?.vehicle?.licensePlate}
          </Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const customer = rc?.customerEmployee;
        const firstName = customer?.firstName;
        const lastName = customer?.lastName;
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: `${t("company-name")} / ${t("transport-name")}`,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const companyName = rc?.customerEmployee?.companyName;

        if (!companyName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: companyName }}>
            {companyName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        const userTypeKey = rc?.customerEmployee?.userType;

        if (!userTypeKey) return <span>-</span>;
        const text = t(typeOfUser[userTypeKey]);
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      title: t("note"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.description) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.description }}>
            {rc?.description}
          </Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    delete values.date;
    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  const onRowClick = (values: any) => {
    setSelectedId?.(values.id);
    toggle();
  };

  return (
    <PageContent breadcrumb className="p-5">
      <InspectionDescriptionDrawer visible={visible} onClose={toggle} />
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        selectAble={false}
        title={t("failed-inspection-list")}
        columns={columns}
        action={{
          excels: {
            export: {
              fileName: `${t("failed-inspection-list")}`,
              mutation: export_condition_failed,
            },
          },
        }}
        onRowClick={onRowClick}
        dataSource={data?.data || []}
        loading={isFetching}
        pagination={{
          total: data?.count,
          current: params?.page,
          pageSize: params.limit,
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default withVehicleInspectionProvider(
  VehicleInspectionFailedInspectionPage
);
