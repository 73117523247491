import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  useExportDoorwayEmergency,
  useGetDoorwayEmergency,
} from "services/doorway-emergency";
import {
  IDoorwayEmergency,
  IDoorwayEmergencyParams,
} from "services/doorway-emergency/interface";
import { useAppLanguage } from "provider/app-language";
import { Typography } from "antd";
import VehicleDetectImage from "components/display/vehicle-detect-image";

const EmergencyBarrier = () => {
  const { formatDate, translationObject } = useAppLanguage();
  const { t } = useTranslation();
  const [params, setParams] = useState<IDoorwayEmergencyParams>({
    page: 1,
    limit: 10,
  });

  const { data, isFetching } = useGetDoorwayEmergency(params);
  const exportExcel = useExportDoorwayEmergency();

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("date"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
    {
      name: "name",
      label: t("first-name") + "-" + t("last-name"),
      input: {
        type: "Input",
        options: {
          search: true,
          placeholder: t("first-name") + "-" + t("last-name"),
        },
      },
    },
    {
      name: "licensePlate",
      label: t("license-plate"),
      input: {
        type: "Input",
        options: {
          search: true,
          placeholder: t("license-plate"),
        },
      },
    },
    {
      name: "tel",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: {
          search: true,
          placeholder: t("phone-number"),
        },
      },
    },
  ];

  const columns: ColumnsType<IDoorwayEmergency> = [
    {
      title: t("date"),
      width: 200,
      ellipsis: true,
      render: (_, rc) => {
        if (!rc?.dateTime) return <span>-</span>;
        const date = formatDate({ date: rc?.dateTime, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("picture"),
      width: 150,
      ellipsis: true,
      render: (_, rc) => {
        return (
          <VehicleDetectImage
            value={rc?.detectImage}
            bordered={!!!rc?.detectImage}
          />
        );
      },
    },
    {
      title: t("license-plate"),
      dataIndex: "licenseplate",
      render: (_, rc) => {
        const plateNumber = rc?.licensePlate;
        return <span>{plateNumber || "-"} </span>;
      },
    },
    {
      title: t("first-name") + "-" + t("last-name"),
      dataIndex: "name",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const { firstName, lastName } = rc || {};
        const name = !firstName && !lastName ? "-" : `${firstName} ${lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      dataIndex: "phonenumber",
      render: (_, rc) => {
        return <span>{rc?.tel || "-"} </span>;
      },
    },
    {
      title: t("note"),
      dataIndex: "note",
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.annotation || "-" }}>
            {rc?.annotation || "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: t("grantor"),
      dataIndex: "grantor",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const { firstName, lastName } = translationObject({
          object: rc.approver,
          keys: ["firstName", "lastName"],
        });
        const name = !firstName && !lastName ? "-" : `${firstName} ${lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    delete values.date;

    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        scroll={{ x: 1200 }}
        selectAble={false}
        title={t("vehicle-management.emergency-barrier-report", { ns: "menu" })}
        columns={columns}
        action={{
          excels: {
            export: { fileName: "doorway-emergency", mutation: exportExcel },
          },
        }}
        dataSource={data?.data || []}
        loading={isFetching}
        pagination={{
          total: data?.count,
          current: params?.page,
          pageSize: params.limit,
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};
export default EmergencyBarrier;
