import Icon, { SettingOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd";
import { BinIcon } from "assets/icons/general";
import { FC } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { CButtonProps, StyledButton } from "./c-button";
import { ButtonThemeTypes, BUTTON_THEME } from "./theme";

const StyledButtonIcon = styled(StyledButton)<{ thm: ButtonThemeTypes }>`
  height: 45px;
  width: 45px;
  ${tw`inline-flex`};
  span {
    font-size: 18px;
  }
  svg * {
    fill: ${({ thm }) => BUTTON_THEME[thm].icon};
  }
`;

const CButtonIcon: FC<CButtonProps> & {
  Delete: FC<ButtonProps>;
  Setting: FC<ButtonProps>;
} = ({ theme = "gray", ...props }) => {
  return <StyledButtonIcon thm={theme} {...props} />;
};

const StyledButtonDelete = styled(StyledButton)<{ thm: ButtonThemeTypes }>`
  height: 35px;
  width: 35px;
  ${tw`inline-flex bg-white`};
  span {
    font-size: 18px;
  }
  svg * {
    fill: ${({ thm }) => BUTTON_THEME[thm].icon};
  }

  &:hover,
  &:active,
  &:focus {
    ${tw`!bg-red-500 !border-red-500`};
    svg * {
      fill: white;
    }
  }
`;

const Delete: FC<ButtonProps> = (props) => {
  return (
    <StyledButtonDelete
      icon={<Icon component={BinIcon} />}
      thm="gray"
      {...props}
    />
  );
};

const StyledButtonSetting = styled(StyledButton)<{ thm: ButtonThemeTypes }>`
  height: 35px;
  width: 35px;
  ${tw`inline-flex bg-white`};
  span {
    font-size: 18px;
  }
  svg * {
    fill: ${({ thm }) => BUTTON_THEME[thm].icon};
  }

  &:hover,
  &:active,
  &:focus {
    ${tw`!bg-primary-dark !border-primary-dark`};
    svg * {
      fill: white;
    }
  }
`;

const Setting: FC<ButtonProps> = (props) => {
  return (
    <StyledButtonSetting icon={<SettingOutlined />} thm="gray" {...props} />
  );
};

CButtonIcon.Delete = Delete;
CButtonIcon.Setting = Setting;

export default CButtonIcon;
