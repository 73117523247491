import { Col, Row } from "antd";
import TransitionCaptureList from "components/menu/vehicle-management/transition-capture-list";
import TransitionDescription from "components/menu/vehicle-management/transition-description";
import TransitionDualVideo from "components/menu/vehicle-management/transition-dual-video";
// import TransitionFaceVideo from "components/menu/vehicle-management/transition-face-video";
import TransitionHeader from "components/menu/vehicle-management/transition-header";
import TransitionImageCapture from "components/menu/vehicle-management/transition-image-capture";
// import TransitionVehicleVideo from "components/menu/vehicle-management/transition-vehicle-video";
import withVehicleSocketProvider from "provider/vehicle-socket";

const VehicleEntranceScreen = () => {
  return (
    <div className="p-5">
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <TransitionHeader type="vehicle-management.entrance-screen" />
        </Col>
        {/* <Col span={12}>
          <TransitionVehicleVideo />
        </Col>
        <Col span={12}>
          <TransitionFaceVideo  />
        </Col> */}
        <Col span={24}>
          <TransitionDualVideo />
        </Col>
        <Col span={12}>
          <TransitionImageCapture />
        </Col>
        <Col span={12}>
          <TransitionDescription />
        </Col>
        <Col span={24}>
          <TransitionCaptureList type="entrance" />
        </Col>
      </Row>
    </div>
  );
};

export default withVehicleSocketProvider(VehicleEntranceScreen);
