import Chart from "react-apexcharts";

interface PieChartProps {
  series: number[];
  colors?: string[];
  height?: number;
  labels: string[];
}

const PieChartHome = ({
  labels,
  series,
  colors,
  height = 250,
}: PieChartProps) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      fontFamily: "kanit-regular",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    labels: labels,
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: colors,
  };

  return <Chart height={height} type="donut" options={options} series={series} />;
};

export default PieChartHome;
