import { useQueryClient } from "@tanstack/react-query";
import { Form, Input, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ColumnsType } from "antd/lib/table";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import CCard from "components/display/card";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteScale,
  useDeleteScaleBulk,
  useGetSettingScale,
  usePatchScaleId,
  usePostScale,
} from "services/setting-scale";
import {
  ISettingScaleParams,
  ISttingScale,
} from "services/setting-scale/interface";
const Scale = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { formatDate, translationObject } = useAppLanguage();
  const [params, setParams] = useState<ISettingScaleParams>({
    page: 1,
    limit: 10,
  });
  const data = useGetSettingScale(params);
  const post_data = usePostScale();
  const qClient = useQueryClient();
  const del_data = useDeleteScale();
  const del_data_bulk = useDeleteScaleBulk();
  const patch_data = usePatchScaleId();
  const { t } = useTranslation();
  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };
  const onFinish = (values: any) => {
    const { id } = values;
    if (!values.status) values.status = "true";
    if (id) {
      return patch_data.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-Scales"]);
          onClose();
        },
        onError: ({ meesage }: any) => {
          fireNotification({ type: "error", description: meesage });
        },
      });
    }

    return post_data.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        qClient.invalidateQueries(["get-Scales"]);
        onClose();
      },
      onError: ({ meesage }: any) => {
        fireNotification({ type: "error", description: meesage });
      },
    });
  };

  const elements: ISearchBoxElement[] = [
    {
      name: "scaleCode",
      label: t("scale-code"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "scale",
      label: t("scale"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "createdAt",
      label: t("created-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "updatedAt",
      label: t("updated-at"),
      input: {
        type: "RangePicker",
      },
    },

    {
      name: "status",
      label: t("status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              { key: "true", value: t("online") },
              { key: "false", value: t("offline") },
            ],
            valueKey: "key",
            titleKey: "value",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<ISttingScale> = [
    {
      title: t("scale-code"),
      dataIndex: "scaleCode",
    },
    {
      title: t("scale"),
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const { scale } = translationObject({ object: rc, keys: ["scale"] });
        return (
          <Typography.Text ellipsis={{ tooltip: scale }}>
            {scale}
          </Typography.Text>
        );
      },
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      width: "20%",
      render: (_, rc) => {
        if (!rc.createdAt) return <span>-</span>;
        const date = formatDate({ date: rc.createdAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
      // align: "center",
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      render: (_, rc) => {
        if (!rc.updatedAt) return <span>-</span>;
        const date = formatDate({ date: rc.updatedAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (status: "true" | "false") => {
        return (
          <CCard.OnLineOffLineCard
            status={status === "true" ? "online" : "offline"}
          />
        );
      },
    },
  ];

  const onSearch = (FormItem: any) => {
    setParams({
      ...params,
      ...FormItem,
      startUpdatedAt: FormItem["updatedAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endUpdatedAt: FormItem["updatedAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
      startCreatedAt: FormItem["createdAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endCreatedAt: FormItem["createdAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
    });
  };
  const scaleTH = (
    <span>
      {t("scale")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const scaleEN = (
    <span>
      {t("scale")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );
  const descriptionTH = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const descriptionEN = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );
  const titleForm = form.getFieldValue("id") ? t("edit-scale") : t("add-scale");
  return (
    <PageContent breadcrumb className="p-5">
      <CDrawer
        form={form}
        title={titleForm}
        placement="right"
        onClose={onClose}
        size="large"
        onSave={onFinish}
        visible={visible}
        width="40vw"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="scaleCode"
            label={t("scale-code")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <CInput placeholder={t("scale-code")} />
          </Form.Item>
          <Form.Item
            name="scaleTh"
            label={scaleTH}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <CInput placeholder={t("scale")} />
          </Form.Item>
          <Form.Item
            name="scaleEn"
            label={scaleEN}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <CInput placeholder={t("scale")} />
          </Form.Item>
          <Form.Item label={t("status")} name="status">
            <CSelect
              placeholder={t("status")}
              defaultValue="true"
              valueOptions={{
                values: [
                  { id: "true", title: t("online") },
                  { id: "false", title: t("offline") },
                ],
                valueKey: "id",
                titleKey: "title",
              }}
            />
          </Form.Item>
          <Form.Item name="descriptionTh" label={descriptionTH}>
            <TextArea
              placeholder={t("description")}
              autoSize={{
                minRows: 6,
                maxRows: 6,
              }}
            />
          </Form.Item>
          <Form.Item name="descriptionEn" label={descriptionEN}>
            <TextArea
              placeholder={t("description")}
              autoSize={{
                minRows: 6,
                maxRows: 6,
              }}
            />
          </Form.Item>
        </Form>
      </CDrawer>
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        onAdd={{
          onClick: () => {
            setVisible(true);
          },
        }}
        onRowClick={(dataSource: any) => {
          form.setFieldsValue(dataSource);
          setVisible(true);
        }}
        action={{
          bulkDelete: {
            mutation: del_data_bulk,
            invalidateQueries: ["get-Scales"],
          },
        }}
        onDelete={{
          mutation: del_data,
          invalidateQueries: ["get-Scales"],
        }}
        query={data}
        columns={columns}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        title={t("scale-list")}
      />
    </PageContent>
  );
};
export default Scale;
