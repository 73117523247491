import { Col, Row } from "antd";
import PageContent from "components/layout/page-content";
import withVehicleReport from "provider/vehicle-report";
import VehicleReportContent from "./content";
import VehicleReportSider from "./sider";



const VehicleReportPage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <VehicleReportSider />
        </Col>
        <Col span={18} className="p-5">
          <VehicleReportContent />
        </Col>
      </Row>
    </PageContent>
  );
};

export default withVehicleReport(VehicleReportPage);
