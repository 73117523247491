import { TableColumnsType, Typography } from "antd";
import CTable from "components/display/c-table";
import { typeOfUser } from "config/axios/interface";
import { useAppLanguage } from "provider/app-language";
import { useReportVehicleInspection } from "provider/report-vehicle-inspection";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportReportVehicleCheck,
  useExportReportVehicleSpray,
  useExportReportVehicleCondition,
  useGetReportVehicleInspections,
} from "services/vehicle-condition";
import { IReportVehicleInspection } from "services/vehicle-condition/interface";

const ReportContent = () => {
  const { t } = useTranslation();
  const { columnsKey, params, setParams } = useReportVehicleInspection();
  const { formatDate } = useAppLanguage();

  useEffect(() => {
    setParams?.({ ...params, vehicleQueueStatus: "VEHICLE_SPRAY" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportReportCheck = useExportReportVehicleCheck();
  const exportReportSpray = useExportReportVehicleSpray();
  const exportReportCondition = useExportReportVehicleCondition();
  const dataVehicleCondition = useGetReportVehicleInspections({ ...params });

  const columns: TableColumnsType<IReportVehicleInspection> = [
    {
      title: t("date"),
      key: "date",
      render: (_, rc) => {
        const date = formatDate({
          date: rc?.createdAt,
          format: "normal-time",
        });
        return <Typography.Text>{date}</Typography.Text>;
      },
    },
    {
      title: t("license-plate-number"),
      key: "licensePlate",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.vehicle?.licensePlate) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.vehicle?.licensePlate }}>
            {rc?.vehicle?.licensePlate}
          </Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      key: "name",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const customer = rc?.customerEmployee;
        const firstName = customer?.firstName;
        const lastName = customer?.lastName;
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      key: "phoneNumber",
      render: (_, rc) => {
        return (
          <Typography.Text>
            {rc?.customerEmployee?.phoneNumber || "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: `${t("company-name")} / ${t("transport-name")}`,
      key: "companyName",
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const companyName = rc?.customerEmployee?.companyName;

        if (!companyName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: companyName }}>
            {companyName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("person-type"),
      key: "userType",
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        const userTypeKey = rc?.customerEmployee?.userType;
        if (!userTypeKey) return <span>-</span>;
        const text = t(typeOfUser[userTypeKey]);
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
  ];

  const _columns = columns.filter((item) => {
    return columnsKey.some((e) => e === item.key);
  });

  const topic = _columns
    .map((i) => {
      if (i?.key === "date") return "createdAt";
      if (i?.key === "name")
        return ["customerEmployee.firstName", "customerEmployee.lastName"];
      if (i?.key === "licensePlate") return "vehicle.licensePlate";
      if (i?.key === "companyName") return "customerEmployee.companyName";
      if (i?.key === "phoneNumber") return "customerEmployee.phoneNumber";
      if (i?.key === "userType") return "customerEmployee.userType";
      return i?.key;
    })
    .flat();

  const mutation =
    params?.vehicleQueueStatus === "VEHICLE_CONDITION"
      ? exportReportCondition
      : params?.vehicleQueueStatus === "VEHICLE_QUICKLAB"
      ? exportReportCheck
      : exportReportSpray;

  return (
    <CTable
      scroll={{ x: 1000 }}
      selectAble={false}
      shadow={false}
      title={t("list-all")}
      columns={_columns}
      query={dataVehicleCondition}
      loading={dataVehicleCondition.isFetching}
      action={{
        excels: {
          export: {
            mutation: mutation,
            extraValue: { topic: topic },
            fileName: "Vehicle condition",
          },
        },
      }}
      pagination={{
        onChange: (page, limit) => {
          setParams?.({ ...params, page, limit });
        },
      }}
    />
  );
};

export default ReportContent;
