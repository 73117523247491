import { useEffect, useState, VideoHTMLAttributes } from "react";
import { useRequestVideoUrl } from "services/stream-video";
import { fireNotification } from "components/popup/notification";
import { usePageRoute } from "provider/page-route";
import CCard from "../card";
import { v4 } from "uuid";
import styled from "styled-components";

const iWind = 0;

declare global {
  interface Window {
    JSPlugin: any; // 👈️ turn off type checking
  }
}

interface VideoContainerProps {
  height: string | number;
}

const VideoContainer = styled.div<VideoContainerProps>`
  width: 100%;
  .parent-wnd > div {
    width: 100% !important;
    canvas {
      width: 100%;
      object-fit: fill;
      height: ${({ height }) => height};
    }
  }
`;

interface VideoPlayerProps extends VideoHTMLAttributes<HTMLVideoElement> {
  encodeDeviceId?: number;
  backgroundColor?: string;
}

const VideoPlayer = ({
  autoPlay = true,
  controls = true,
  width = "100%",
  height = "auto",
  backgroundColor = "black",
  style,
  encodeDeviceId,
  ...props
}: VideoPlayerProps) => {
  const [videoUrl, setVideoUrl] = useState<string>();
  const [uuid, setUUid] = useState<string>();
  const [jsDecoder, setJsDecoder] = useState<any>();

  const { pageKey } = usePageRoute();
  const request = useRequestVideoUrl();

  useEffect(() => {
    if (!encodeDeviceId) return;
    // const playerId = `${v4()}_${encodeDeviceId}`;
    // setUUid(playerId);
    requestVideo(encodeDeviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encodeDeviceId]);

  useEffect(() => {
    if (!jsDecoder) return;
    jsDecoder.JS_Play(videoUrl, { playURL: videoUrl }, iWind);
    return () => {
      if (jsDecoder) {
        jsDecoder.JS_Stop(iWind);
        setJsDecoder(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsDecoder]);

  const requestVideo = (id: number) => {
    const unique = v4();
    setUUid(unique);
    request.mutate(
      { encodeDeviceId: id, streamType: "SUB" },
      {
        onSuccess: (data) => {
          const instance = new window.JSPlugin({
            szId: unique,
            iType: 2,
            iWidth: width,
            iHeight: height,
            iMaxSplit: 1,
            iCurrentSplit: 1,
            szBasePath: process.env.PUBLIC_URL + "/dist",
            oStyle: {
              border: "#343434",
              borderSelect: "red",
              background: "#4C4B4B",
            },
          });

          setVideoUrl(data.url);
          setJsDecoder(instance);
        },
        onError: ({ message }) => {
          fireNotification({ message, menu: pageKey, type: "error" });
        },
      }
    );
  };

  // if (request.isLoading) {
  //   return (
  //     <div style={{ height, width }}>
  //       <CIndicator height={height} />
  //     </div>
  //   );
  // }

  // if (!src) {
  //   return <CCard.EmptySelectVideo style={{ height, width }} type="empty" />;
  // }

  return (
    <VideoContainer
      height={typeof height === "string" ? height : `${height}px`}
    >
      <CCard.EmptySelectVideo
        style={{ height, width }}
        type="empty"
        hidden={!!encodeDeviceId}
      />
      <div
        id={uuid}
        style={{ height: height, width: width }}
        hidden={!encodeDeviceId}
      ></div>
    </VideoContainer>
  );
};

export default VideoPlayer;
