import { IMediaObject } from "./interface";
import { throwResponse } from "config/axios";
import axios from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";

export const GET_MEDIA_OBJECTS = (): UseQueryResult<
  IResponse<IMediaObject[]>
> => {
  return useQuery(["get-media-objects"], async () => {
    const res = await axios.get("/media-object");
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const GET_MEDIA_OBJECT = (id?: string): UseQueryResult<IMediaObject> => {
  return useQuery(["get-media-object", id], async () => {
    if (!id) return;
    const res = await axios.get(`/media-object/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const postMediaObject = async (
  imageFile: File
): Promise<number> => {
  let formData = new FormData();
  formData.append("file", imageFile);
  const res = await axios.post<IResponse<IMediaObject>>(
    "/media-object",
    formData
  );
  if (res.status === 200) {
    const { data } = res.data;
    return data.id;
  }
  return throwResponse(res);
};
