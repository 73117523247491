import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { Col, Popover, Row } from "antd";
import { IconFaceID, IconQrCode } from "assets/icons/general";
import { eventType } from "provider/door-realtime/interface";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledButton = styled.div<{ active: number }>`
  width: 35px;
  height: 35px;
  border: 1px solid ${(props) => (props.active ? "red" : "#E4E4E4")};
  background-color: white;
  .anticon {
    path *,
    g * {
      fill: ${(props) => (props.active ? "red" : "#E4E4E4")};
    }
  }
`;

interface IAccessibility {
  title: string;
  key: eventType;
  icon: React.ComponentType<
    CustomIconComponentProps | React.SVGProps<SVGSVGElement>
  >;
}

const Accessibility = ({ type }: { type?: string[] }) => {
  const { t } = useTranslation();
  const accessibility: IAccessibility[] = [
    // {
    //   title: t("card"),
    //   key: "IDENTITY_CARD",
    //   icon: IconCard,
    // },
    {
      title: t("face-recognition"),
      key: "FACE_RECOGNITION",
      icon: IconFaceID,
    },
    // {
    //   title: t("finger-print"),
    //   key: "FINGERPRINT",
    //   icon: IconFingerScan,
    // },
    {
      title: t("qr-code"),
      key: "IDENTITY_CARD",
      icon: IconQrCode,
    },
  ];
  return (
    <Row gutter={[6, 6]}>
      {accessibility.map((item, index) => {
        const active = type?.find((e) => e === item.key);
        return (
          <Col key={index}>
            <Popover content={t(item.title)}>
              <StyledButton
                active={active ? 1 : 0}
                className="center rounded-app "
              >
                <Icon component={item.icon} style={{ fontSize: 30 }} />
              </StyledButton>
            </Popover>
          </Col>
        );
      })}
    </Row>
  );
};

export default Accessibility;
