import { VideoCameraFilled } from "@ant-design/icons";
import { Popover } from "antd";
import { FC } from "react";
import { IMark } from "services/mark/interface";
import styled, { keyframes } from "styled-components";

interface MapPinMarkerComponentProps {
  animationKey: number | string;
  cursorPointer?: boolean;
  mark: IMark;
  onClick?: (v: any) => void;
}

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(2.5);
  }
`;

const StyledMarker = styled.span<{ $pointer?: boolean; bgColor: string }>`
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
  position: relative;
  z-index: 1;
  cursor: ${({ $pointer }) => ($pointer ? "pointer" : "")};
  background-color: ${({ bgColor }) => bgColor};
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 50%;
    z-index: -1;
    animation: ${ripple} 1.5s ease-out infinite;
  }
`;

const MapPinMarkerComponent: FC<MapPinMarkerComponentProps> = ({
  cursorPointer,
  animationKey,
  onClick,
  mark,
}) => {
  if (mark.markType === "CAMERA") {
    return (
      <Popover content={<span>{mark.markName}</span>}>
        <StyledMarker
          onClick={onClick}
          $pointer={cursorPointer}
          bgColor={mark.pin?.colorType || "red"}
          key={animationKey}
          className="center"
        >
          <VideoCameraFilled style={{ fontSize: 20, color: "red" }} />
        </StyledMarker>
      </Popover>
    );
  }

  return (
    <Popover content={<span className="text-[20px]">{mark.markName}</span>}>
      <StyledMarker
        onClick={onClick}
        $pointer={cursorPointer}
        bgColor={mark.pin?.colorType || "red"}
        key={animationKey}
      ></StyledMarker>
    </Popover>
  );
};

export default MapPinMarkerComponent;
