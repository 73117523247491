import { ReactNode, useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IMainMenu, TMainPage } from "router/interface";
import { MAIN_MENU } from "../../router";
import { INavigatorContext, INavigatorTab } from "./interface";

const NavigatorContext = createContext<INavigatorContext>(
  {} as INavigatorContext
);

const NavigatorProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [navigators, setNavigators] = useState<INavigatorTab[]>([]);
  const [activeKey, setActiveKey] = useState<TMainPage>();

  useEffect(() => {
    initialTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialTab = () => {
    if (pathname === "/" || pathname === "/home") return;
    const find = MAIN_MENU.find((e) => `/${e.key}` === pathname);
    if (!find || navigators.length > 0) return;
    onAddTab(find);
  };

  const onAddTab = (item: IMainMenu) => {
    //if same tab has clicked
    const navigator = navigators.find((e) => e.key === item.key);
    if (navigator) {
      return onGoToTab(navigator.key);
    }

    if (item.key === "home") {
      return navigate("/");
    }

    setNavigators([...navigators, item]);
    onGoToTab(item.key);
  };

  const onRemoveTab = (key: string) => {
    const closedTab = getClosedTab(key);
    const next = navigators.filter((e) => e.key !== key);

    setNavigators(next);
    if (closedTab) {
      onGoToTab(closedTab.key);
    }
  };

  const onGoToTab = (key: string) => {
    setActiveKey(key as TMainPage);
    navigate(`/${key}`, { replace: true });
  };

  const getClosedTab = (key: string) => {
    const cIndex = navigators.findIndex((e) => e.key === key);
    if (cIndex < 0) return;
    if (cIndex !== navigators.length - 1) {
      return navigators[cIndex + 1];
    }
    return navigators[cIndex - 1];
  };

  return (
    <NavigatorContext.Provider
      value={{ navigators, activeKey, onAddTab, onRemoveTab, onGoToTab }}
    >
      {children}
    </NavigatorContext.Provider>
  );
};

export const useNavigator = () => useContext(NavigatorContext);
export default NavigatorProvider;
