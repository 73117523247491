import io from "socket.io-client";
import {
  createContext,
  ReactNode,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  INoti,
  INotificationContext,
  INotiState,
  TNotiAction,
} from "./interface";
import { registerApiUrl, socketUrl } from "config/axios";

const NotificationContext = createContext<INotificationContext>({
  sidebarNoti: {
    "user-management.pending-list": [],
    "vehicle-inspection.spray-check": [],
    "vehicle-inspection.vehicle-inspection": [],
    "vehicle-inspection.failed-inspection-vehicle": [],
    "vehicle-inspection.check-quick-lab": [],
    "vehicle-inspection.broken-car": [],
  },
  tableNoti: {
    "user-management.pending-list": [],
    "vehicle-inspection.spray-check": [],
    "vehicle-inspection.vehicle-inspection": [],
    "vehicle-inspection.failed-inspection-vehicle": [],
    "vehicle-inspection.check-quick-lab": [],
    "vehicle-inspection.broken-car": [],
  },
});

const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<Reducer<INotiState, TNotiAction>>(
    notiReducer,
    {
      tableNoti: {
        "user-management.pending-list": [],
        "vehicle-inspection.spray-check": [],
        "vehicle-inspection.vehicle-inspection": [],
        "vehicle-inspection.failed-inspection-vehicle": [],
        "vehicle-inspection.check-quick-lab": [],
        "vehicle-inspection.broken-car": [],
      },
      sidebarNoti: {
        "user-management.pending-list": [],
        "vehicle-inspection.spray-check": [],
        "vehicle-inspection.vehicle-inspection": [],
        "vehicle-inspection.failed-inspection-vehicle": [],
        "vehicle-inspection.check-quick-lab": [],
        "vehicle-inspection.broken-car": [],
      },
      headerNoti: {
        "user-management.pending-list": [],
        "vehicle-inspection.spray-check": [],
        "vehicle-inspection.vehicle-inspection": [],
        "vehicle-inspection.failed-inspection-vehicle": [],
        "vehicle-inspection.check-quick-lab": [],
        "vehicle-inspection.broken-car": [],
      },
    }
  );
  
  useEffect(() => {
    const socket = io(socketUrl.replace("http", "ws"), {
      transports: ["websocket"],
    });

    socket.on("on-created", setFromSocketNoti);

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const socket = io(registerApiUrl.replace("http", "ws"), {
      transports: ["websocket"],
    });

    socket.on("on-created", setFromSocketNotiUser);

    return () => {
      socket.disconnect();
    };
  }, []);

  const setFromSocketNoti = (value: INoti) => {
    console.log("setFromSocketNoti", value);

    const { id, type } = value;

    // if (type === "USER_PENDING_LIST") {
    //   dispatch({
    //     type: "all",
    //     action: "add",
    //     id: id,
    //     key: ["user-management.pending-list"],
    //   });
    // }

    if (type === "VEHICLE_INSPECTION_SPRAY") {
      dispatch({
        type: "all",
        action: "add",
        id: id,
        key: ["vehicle-inspection.spray-check"],
      });
    }

    if (type === "VEHICLE_INSPECTION") {
      dispatch({
        type: "all",
        action: "add",
        id: id,
        key: ["vehicle-inspection.vehicle-inspection"],
      });
    }

    if (type === "VEHICLE_INSPECTION_BROKEN_CAR") {
      dispatch({
        type: "all",
        action: "add",
        id: id,
        key: ["vehicle-inspection.broken-car"],
      });
    }

    if (type === "VEHICLE_INSPECTION_CHECK_QUICK_LAB") {
      dispatch({
        type: "all",
        action: "add",
        id: id,
        key: ["vehicle-inspection.check-quick-lab"],
      });
    }

    if (type === "VEHICLE_INSPECTION_FAILED") {
      dispatch({
        type: "all",
        action: "add",
        id: id,
        key: ["vehicle-inspection.failed-inspection-vehicle"],
      });
    }
  };

  const setFromSocketNotiUser = (value: INoti) => {
    console.log("setFromSocketNotiUser", value);

    const { id, type } = value;

    if (type === "USER_PENDING_LIST") {
      dispatch({
        type: "all",
        action: "add",
        id: id,
        key: ["user-management.pending-list"],
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        dispatchNoti: dispatch,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
export default NotificationProvider;

const notiReducer = (state: INotiState, payload: TNotiAction): INotiState => {
  const { action, type, id, key } = payload;

  for (const keyItem of key) {
    const { tableNoti, sidebarNoti } = state;
    if (action === "clear") {
      switch (type) {
        case "all":
          state = {
            ...state,
            tableNoti: { ...tableNoti, [keyItem]: [] },
            sidebarNoti: { ...sidebarNoti, [keyItem]: [] },
          };
          break;
        case "sidebar":
          state = {
            ...state,
            sidebarNoti: { ...sidebarNoti, [keyItem]: [] },
          };
          break;
        case "table":
          state = {
            ...state,
            tableNoti: { ...tableNoti, [keyItem]: [] },
          };
          break;
      }
      continue;
    }
    if (id) {
      switch (type) {
        case "all":
          state = {
            ...state,
            sidebarNoti: {
              ...sidebarNoti,
              [keyItem]: [...sidebarNoti[keyItem]!, id].flat(),
            },
            tableNoti: {
              ...tableNoti,
              [keyItem]: [...tableNoti[keyItem]!, id].flat(),
            },
          };
          break;
        case "sidebar":
          state = {
            ...state,
            sidebarNoti: {
              ...sidebarNoti,
              [keyItem]: [...sidebarNoti[keyItem]!, id].flat(),
            },
          };
          break;
        case "table":
          state = {
            ...state,
            tableNoti: {
              ...tableNoti,
              [keyItem]: [...tableNoti[keyItem]!, id].flat(),
            },
          };
          break;
      }
    }
  }

  return state;
};
