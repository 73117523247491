import { Form } from "antd";
import CInput from "components/input/c-input";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { useAuth } from "provider/auth";
import { usePageRoute } from "provider/page-route";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePostDoorwayWithBarrier } from "services/doorway-emergency";
import CModal, { CModalProps } from ".";
import CModalFooter from "./modal-footer";

export interface CModalBarrierActionProps extends CModalProps {
  afterFinish?: () => void;
  encodeDeviceId?: number;
  vehicleId?: number;
  detectImageId?: number;
  licensePlateNumber?: string;
}

const CModalBarrierAction: FC<CModalBarrierActionProps> = ({
  afterFinish,
  encodeDeviceId,
  detectImageId,
  licensePlateNumber,
  vehicleId,
  ...props
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { mutate } = usePostDoorwayWithBarrier();

  const { setAppLoading } = useAppLoading();
  const { pageKey } = usePageRoute();
  const { userMe } = useAuth();

  const onFinish = async () => {
    const { description } = await form.validateFields();
    setAppLoading(true);

    const doorway = {
      firstName: userMe?.firstNameTh,
      lastName: userMe?.lastNameTh,
      licensePlate: licensePlateNumber,
      tel: userMe?.phoneNumber,
      annotation: description,
      approver: "9a58edc4-d244-466c-b848-8b3c2fba602e",
      detectImage: detectImageId,
      vehicle: vehicleId,
    };

    const barrier = {
      deviceId: encodeDeviceId,
      action: "open",
    };

    mutate(
      { barrier, doorway },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: pageKey });
          afterFinish?.();
        },
        onError: ({ message }) => {
          fireNotification({ type: "error", menu: pageKey, message });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  return (
    <CModal
      {...props}
      afterClose={() => form.resetFields()}
      title={t("note-for-open-barrier", { ns: "message" })}
      footer={
        <CModalFooter
          cancel={{ onClick: props.onCancel }}
          submit={{ onClick: onFinish }}
        />
      }
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={t("note")}
          rules={[{ required: true }]}
          name="description"
        >
          <CInput.TextArea rows={6} />
        </Form.Item>
      </Form>
    </CModal>
  );
};

export default CModalBarrierAction;
