import { useQueryClient } from "@tanstack/react-query";
import { Typography } from "antd";
import Container from "components/display/container";
import { useAccessibilityDeterminedAccessLevel } from "provider/accessibility-determined-by-access-level";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAccessibilityParams } from "services/accessibility/interface";
import DeterminedByAccessAll from "./content-user-type/content-all";

// const contentPage = {
//   ALL: <DeterminedByAccessAll/>,
//   VISITOR: <DeterminedByAccessVisitor />,
//   CONTRACTOR: <DeterminedByAccessContractor />,
//   SUPPLIER: <DeterminedByAccessSupplier />,
//   RECEIVER: <DeterminedByAccessReceiver />,
// };

const AccessibilityDeterminedByAccessLevelContent = () => {
  const { t } = useTranslation();
  const { userType,accessibility } = useAccessibilityDeterminedAccessLevel();

  const [params, setParams] = useState<IAccessibilityParams>({
    accessibilityId: accessibility?.id,
    userType: userType?.userType,
  });

  const qClient = useQueryClient();

  useEffect(() => {
    qClient.invalidateQueries(["get-accessibilitys"]);
    setParams({
      ...params,
      accessibilityId: accessibility?.id,
      userType: userType?.userType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibility, userType]);

  if (!accessibility) {
    return (
      <div style={{ height: "calc(100vh - 218px)" }} className="center">
        <Typography.Text>{t("please-select")}</Typography.Text>
      </div>
    );
  }

  return (
    <Container height="auto" padding="p-0">
      <DeterminedByAccessAll/>
    </Container>
  );
};

export default AccessibilityDeterminedByAccessLevelContent;
