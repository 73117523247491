import { Form } from "antd";
import CDrawer from "components/display/c-drawer";
import CInput from "components/input/c-input";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePatchPin, usePostPin } from "services/pin";
import { IPin } from "services/pin/interface";

interface FloorEditAddPinDrawerProps {
  visible: boolean;
  onClose: () => void;
  pin?: IPin;
  resetPin: () => void;
}

const FloorEditAddPinDrawer: FC<FloorEditAddPinDrawerProps> = ({
  visible,
  onClose,
  pin,
  resetPin,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const post_pin = usePostPin();
  const patch_pin = usePatchPin();

  useEffect(() => {
    if (pin) {
      form.setFieldsValue(pin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  return (
    <CDrawer
      title={pin ? t("edit-parking-lot-type") : t("add-parking-lot-type")}
      visible={visible}
      onClose={onClose}
      form={form}
      mutation={{
        mutate: pin ? patch_pin : post_pin,
        invalidateQueries: [
          "get-pins",
          "get-pin",
          "get-floors",
          "get-floor",
          "get-mark",
          "get-marks",
        ],
      }}
      afterVisibleChange={(e) => {
        if (!e) {
          resetPin();
        }
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item hidden name="id">
          <CInput />
        </Form.Item>
        <Form.Item
          name="pinName"
          label={t("parking-lot-type-name")}
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("parking-lot-type-name")} />
        </Form.Item>
        <Form.Item
          name="colorName"
          label={t("color-name")}
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("color-name")} />
        </Form.Item>
        <Form.Item
          label={t("color")}
          name="colorType"
          rules={[{ required: true }]}
        >
          <CInput.Color />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default FloorEditAddPinDrawer;
