import PageContent from "components/layout/page-content";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CTable from "components/display/c-table";
import { ColumnsType } from "antd/lib/table";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import {
  useDeleteEmailTemplate,
  useDeleteEmailTemplates,
  useGetEmailTemplates,
} from "services/email-template";
import {
  IEmailTemplate,
  IEmailTemplateParams,
} from "services/email-template/interface";
import { Typography } from "antd";
import { useAppLanguage } from "provider/app-language";

const EmailTemplatePage = () => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const [params, setParams] = useState<IEmailTemplateParams>();

  const get_email_templates = useGetEmailTemplates(params);
  const del_email_template = useDeleteEmailTemplate();
  const del_email_templates = useDeleteEmailTemplates();

  const elements: ISearchBoxElement[] = [
    {
      name: "name",
      label: t("name"),
      input: {
        type: "Input",
      },
    },
  ];

  const columns: ColumnsType<IEmailTemplate> = [
    {
      title: t("name"),
      width: 200,
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis={{ tooltip: rc.name }}>
            {rc.name}
          </Typography.Text>
        );
      },
    },
    {
      title: t("created-at"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        if (!rc.createdAt) return <span>-</span>;
        const date = formatDate({ date: rc.createdAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({
      ...params,
      ...values,
      page: 1,
    });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        rowKey="id"
        scroll={{ x: 1200 }}
        query={get_email_templates}
        title={t("email-template-list")}
        columns={columns}
        onDelete={{
          mutation: del_email_template,
          invalidateQueries: ["get-email-templates", "get-email-template"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        action={{
          bulkDelete: {
            mutation: del_email_templates,
            invalidateQueries: ["get-email-templates", "get-email-template"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onAdd="setting.email-template-add"
        onRowClick="setting.email-template-edit"
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default EmailTemplatePage;
