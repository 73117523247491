import { Avatar, Popover } from "antd";
import React from "react";
import { TwitterPicker } from "react-color";

const CInputColor = ({ value, onChange }: { onChange?: any; value?: any }) => {
  return (
    <React.Fragment>
      <Popover
        trigger="click"
        placement="bottomLeft"
        className="pointer"
        content={
          <TwitterPicker onChange={(e) => onChange?.(e?.hex)} color={value} />
        }
      >
        <Avatar
          shape="square"
          size="small"
          style={{ backgroundColor: value }}
        />
        <span className="p-3">{value}</span>
      </Popover>
    </React.Fragment>
  );
};

export default CInputColor;
