import { IVehicleReportParams } from "services/vehicle-report/interface";


export interface IVehicleReportContext {
    selectKeys: string[];
    setSelectKeys: (v: string[]) => void;
    params: IVehicleReportParams;
    setParams: (v: IVehicleReportParams) => void;
}

export const vehicleReportChoice: {
    [key: string]: { key: string; title: string };
  } = {
    create: { title: "created-at", key: "vehicle.createdAt" },
    license: { title: "license-plate-number", key: "vehicle.licensePlate" },
    color: { title: "color", key: "vehicle.color" },
    brand: { title: "brand", key: "vehicle.brand" },
    firstName: { title: "first-name", key: "customerEmployee.firstName" },
    lastName: { title: "last-name", key: "customerEmployee.lastName" },
    phone: { title: "phone-number", key: "customerEmployee.phoneNumber" },
    userType: { title: "user-type", key: "customerEmployee.userType" },
    company: { title: "company-name", key: "customerEmployee.companyName" },
  };