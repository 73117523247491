

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetRoles } from "services/role";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

const CSelectOrganization: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetRoles}
      valueKey="id"
      titleKey="title"
      searchKey="title"
      placeholder={t("organization")}
      translation
    />
  );
};

export default CSelectOrganization;
