import { throwResponse } from "./../../config/axios/index";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import axios from "axios";
import { IMatchDevice } from "./interface";

export const usePostMatchDevice = (): UseMutationResult<
  IMatchDevice | undefined,
  Error
> => {
  return useMutation(async (values) => {
    const res = await axios.post("/match-encode", values);
    if (res.status === 200) {
      console.log(res.data.data)
      return res.data.data?.[0];
    }
    throwResponse(res);
  });
};

export const usePatchMatchDevice = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/match-encode/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
