import { LoginBackgroundImage } from "assets/images";
import CIndicator from "components/display/c-indicator";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  height: 100vh;
  background: rgb(47, 154, 93);
  background: linear-gradient(
    180deg,
    rgba(47, 154, 93, 1) 0%,
    rgba(3, 8, 18, 1) 100%
  );
`;

const LoadingBackground = styled.div`
  background-image: url(${LoginBackgroundImage});
  background-size: contain;
  opacity: 0.5;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const PreAppLoadingPage = () => {
  return (
    <LoadingWrapper>
      <LoadingBackground />
      <CIndicator height="100vh" size={50} />
    </LoadingWrapper>
  );
};

export default PreAppLoadingPage;
