import UserWithPDPACreateAndEditPage from "components/menu/user-management/create-user-pdpa";
import { usePageRoute } from "provider/page-route";
import { useGetReceiver, usePatchReceiver } from "services/receiver";

const PendingReceiverTypeEditPage = () => {
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const data_receiver = useGetReceiver(id);
  const patch = usePatchReceiver();

  return (
    <UserWithPDPACreateAndEditPage
      menu="receiver"
      action={{ get: data_receiver, patch }}
      pendingListPage
      notDisablePending={data_receiver.data?.acceptStatus === "PENDING"}
    />
  );
};

export default PendingReceiverTypeEditPage;
