import { useQueryClient } from "@tanstack/react-query";
import { Col, Divider, Form, Row, Typography } from "antd";
import CImage from "components/display/c-image";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import CTextArea from "components/input/c-input/text-area";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IMediaObject } from "services/media-object/interface";
import {
  useGetVehicleDetect,
  usePatchVehicleDetect,
} from "services/vehicle-detect";
import { IVehicleDetect } from "services/vehicle-detect/interface";
import styled from "styled-components";
import tw from "twin.macro";

const VehicleListOfAllVehicleCreateAndEditPage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();

  const { getCurrentParams, dispatch } = usePageRoute();
  const { id } = getCurrentParams();

  const { data, isFetching } = useGetVehicleDetect(id);
  const patch = usePatchVehicleDetect();

  const { setAppLoading } = useAppLoading();
  const qClient = useQueryClient();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const init = () => {
    if (!data) return;
    const { vehicleType, brand, userType } = data.vehicle || {};
    const { firstName, lastName, phoneNumber } = data.vehicle || {};
    form.setFieldsValue({
      createdAt: formatDate({ date: data.createdAt, format: "normal-time" }),
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      vehicleType: vehicleType,
      userType: userType,
      licensePlateNumber: data.licensePlate,
      color: data.color,
      brand: brand,
      parking: data.parking?.parkingName,
      floor: data.floor?.floorName,
      description: data?.description,
    });
    if (data.isVehicleCustomerCompany) {
      const { firstName, lastName } = data.customerEmployee || {};
      const { phoneNumber, userType } = data.customerEmployee || {};
      form.setFieldsValue({ firstName, lastName, phoneNumber, userType });
    }
  };

  const className = "!text-primary-dark font-semibold";

  const onSave = (value: any) => {
    patch.mutate(
      { id, ...value },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "vehicle-management" });
          qClient.invalidateQueries(["vehicle-detect"]);
          dispatch({ action: "back" });
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "vehicle-management",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  return (
    <PageContent
      loading={isFetching && !!id}
      breadcrumb={{
        form,
        shadow: false,
        mutate: {
          onSaveAndContinue: false,
          onSave: onSave,
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title level={5} className="!text-primary-dark">
            {t("vehicle-information")}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form layout="vertical" className="!p-5" form={form}>
          <div className="pb-2">
            <Typography.Text className={className}>
              {t("license-plate-information")}
            </Typography.Text>
          </div>
          <ImageInformation data={data} />
          <div className="pb-2">
            <Typography.Text className={className}>
              {t("basic-information")}
            </Typography.Text>
          </div>
          <BasicInformation />
          <div className="pb-2">
            <Typography.Text className={className}>
              {t("vehicle-information")}
            </Typography.Text>
          </div>
          <VehicleInformation />
          <div>
            <Col span={24}>
              <Form.Item name="description" label={t("note")}>
                <CTextArea rows={5} placeholder={t("note")} />
              </Form.Item>
            </Col>
          </div>
        </Form>
      </Container>
    </PageContent>
  );
};

const ImageInformation: FC<{ data?: IVehicleDetect }> = ({ data }) => {
  const { t } = useTranslation();
  const { detectImage, licensePlate } = data || {};
  return (
    <Row gutter={[14, 14]} className="w-full">
      <Col xs={24} md={12} lg={6} hidden={!licensePlate}>
        <BlockImage value={licensePlate} lable={t("vehicle-registration")} />
      </Col>
      <Col xs={24} md={12} lg={6} hidden={!detectImage}>
        <BlockImage value={detectImage} lable={t("image-capture")} />
      </Col>
      <Col xs={24} md={12} lg={6}></Col>
      <Col xs={24} md={12} lg={6}></Col>
      <Col span={8} className="pt-5">
        <Form.Item name="createdAt" label={t("time-period")}>
          <CInput placeholder={t("time-period")} readOnly />
        </Form.Item>
      </Col>
      <Col span={8}></Col>
      <Col span={8}></Col>
    </Row>
  );
};

const BlockImageStyle = styled.div`
  ${tw`border-solid border border-[#438860] rounded-app`}
  height: calc(100vh - 80vh);
`;

const BlockLicenseStyle = styled.div`
  ${tw`border-solid border border-[#000000] rounded-app h-full `}
`;

const BlockImage: React.FC<{
  value?: string | IMediaObject;
  lable?: string;
}> = ({ value, lable }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  if (typeof value === "object") {
    return (
      <div>
        <BlockImageStyle className="center">
          {!value && <div>{t("image-not-found", { ns: "message" })}</div>}
          {value && (
            <CImage
              src={value?.url}
              onClick={(e) => e.stopPropagation()}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              wrapperStyle={{ height: "100%" }}
              className="pointer"
              preview={{
                visible: visible,
                onVisibleChange: setVisible,
                maskClassName: "d-none",
              }}
            />
          )}
        </BlockImageStyle>
        <div className="center pt-2">{lable}</div>
      </div>
    );
  }

  return (
    <div>
      <BlockImageStyle className="px-3 py-4">
        <BlockLicenseStyle className="center">
          {value ? (
            <Typography.Title level={1}>{value}</Typography.Title>
          ) : (
            <div>{t("license-plate-not-found", { ns: "message" })}</div>
          )}
        </BlockLicenseStyle>
      </BlockImageStyle>
      <div className="center pt-2">{lable}</div>
    </div>
  );
};

const BasicInformation = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 4]}>
      <Col span={8}>
        <Form.Item name="firstName" label={t("first-name")}>
          <CInput readOnly placeholder={t("first-name")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="lastName" label={t("last-name")}>
          <CInput readOnly placeholder={t("last-name")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="phoneNumber" label={t("phone-number")}>
          <CInput readOnly placeholder={t("phone-number")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="userType" label={t("person-type")}>
          <CInput readOnly placeholder={t("person-type")} />
        </Form.Item>
      </Col>
      {/* <Col span={8}>
        <Form.Item label={t("vehicle-type")} name="vehicleType">
          <CInput readOnly placeholder={t("vehicle-type")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={t("trailer-head-license-plate-number")}
          name="licensePlateNumber"
        >
          <CInput
            readOnly
            placeholder={t("trailer-head-license-plate-number")}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={t("trailer-tail-license-plate-number")}
          name="trailerLicensePlateNumber"
        >
          <CInput
            disabled
            placeholder={t("trailer-tail-license-plate-number")}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("car-weight")} name="weight">
          <CInput disabled placeholder={t("car-weight")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("trailer-weight")} name="trailerWeight">
          <CInput disabled placeholder={t("trailer-weight")} />
        </Form.Item>
      </Col> */}
    </Row>
  );
};

const VehicleInformation = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 4]}>
      <Col span={8}>
        <Form.Item name="licensePlateNumber" label={t("license-plate-number")}>
          <CInput readOnly placeholder={t("license-plate-number")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("vehicle-type")} name="vehicleType">
          <CInput readOnly placeholder={t("vehicle-type")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("color")} name="color">
          <CInput readOnly placeholder={t("color")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("vehicle-brand")} name="brand">
          <CInput readOnly placeholder={t("vehicle-brand")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("parking-lot")} name="parking">
          <CInput readOnly placeholder={t("parking-lot")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("parking-floor")} name="floor">
          <CInput readOnly placeholder={t("parking-floor")} />
        </Form.Item>
      </Col>
      {/* <Col span={8}>
        <Form.Item label={t("parking-zone")} name="weight">
          <CInput disabled placeholder={t("parking-zone")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("parking-lot-type")} name="trailerWeight">
          <CInput disabled placeholder={t("parking-lot-type")} />
        </Form.Item>
      </Col> */}
    </Row>
  );
};

export default VehicleListOfAllVehicleCreateAndEditPage;
