import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

interface SprayCheckCountProps {
  sprayed: boolean;
  count ?: number;
}

const SprayCheckCountWrapper = styled.div<{ $sprayed: boolean }>`
  ${tw`p-4 rounded-app`};
  background-color: ${({ $sprayed }) => ($sprayed ? "#EAF7EC" : "#FFF6E0")};
  h3 {
    color: ${({ $sprayed }) => ($sprayed ? "#2F9A5D" : "#BF9C3D")} !important;
  }
`;

const SprayCheckCount: React.FC<SprayCheckCountProps> = ({ sprayed , count }) => {
  const { t } = useTranslation();

  return (
    <SprayCheckCountWrapper $sprayed={sprayed}>
      <Row align="middle" gutter={[6, 6]}>
        <Col span={24}>
          <Typography.Text ellipsis>
            {t(
              sprayed
                ? "spray-sterilizing-solution"
                : "not-spray-sterilizing-solution"
            )}
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Title level={3} ellipsis>{count || 0}</Typography.Title>
        </Col>
        <Col>
          <Typography.Text type="secondary">{t("cars")}</Typography.Text>
        </Col>
      </Row>
    </SprayCheckCountWrapper>
  );
};

export default SprayCheckCount;
