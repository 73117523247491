import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "../../config/axios";
import { IResponse } from "../../config/axios/interface";
import { IStock, IStockParams } from "./interface";


export const useGetStock = (
  params: IStockParams
): UseQueryResult<IResponse<IStock[]>> => {
  return useQuery(
    ["get-stocks", params],
    async () => {
      const res = await axios.get("/stock", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostStock = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/stock", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchStockId = (): UseMutationResult => {
  return useMutation(async ({ id, ...values }: any) => {
    const res = await axios.patch(`/stock/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteStock = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/stock/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteStocks = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/stock/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useScrollGetStocks = (): UseInfiniteQueryResult<
  IResponse<IStock[]>
> => {
  return useInfiniteQuery(
    ["stock"],
    async () => {
      const res = await axios.get("/stock");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};