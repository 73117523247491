import UserWithPDPACreateAndEditPage from "components/menu/user-management/create-user-pdpa";
import { usePageRoute } from "provider/page-route";
import { useGetVisitor } from "services/visitor";

const PendingGuestTypeEditPage = () => {
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const data_visitor = useGetVisitor(id);

  return (
    <UserWithPDPACreateAndEditPage
      menu="visitor"
      action={{ get: data_visitor }}
      pendingListPage
    />
  );
};

export default PendingGuestTypeEditPage;
