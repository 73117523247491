import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectContactType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t('contact-type')}
      valueOptions={{
        values: [
          {
            label: t("pick-up"),
            value: "PICKUP",
          },
          {
            label: t("deliver"),
            value: "DELIVER",
          },
          {
            label: t("coordination"),
            value: "COORDINATION",
          },
          {
            label: t("apply-for-work"),
            value: "APPLY_FOR_WORK",
          },
          {
            label: t("meeting"),
            value: "MEETING",
          },
          {
            label: t("working"),
            value: "WORKING",
          },
          {
            label: t("other"),
            value: "OTHER",
          },
        ],
        valueKey: "value",
        titleKey: "label",
      }}
      {...props}
    />
  );
};

export default CSelectContactType;
