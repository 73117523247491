import { Col, Divider, Form, Input, Row, Typography } from "antd";
import CInput from "components/input/c-input";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill"; // ES6
import { useGetPDPA, usePatchPDPA } from "services/setting-pdpa";
import styled from "styled-components";

const ReactQuillStyle = styled(ReactQuill)`
  .ql-toolbar.ql-snow {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }
  .ql-container.ql-snow {
    font-size: 20px;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .ql-snow .ql-picker {
    font-size: 20px;
  }
  .ql-snow .ql-picker-label {
    display: flex;
  }
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    margin-top: 0px;
    top: 0;
    width: 24px;
  }
  .ant-form-item {
    font-size: 20px;
  }
`;

const PDPA = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const data = useGetPDPA();
  const patchdata = usePatchPDPA();

  const onFinish = (values: any) => {
    patchdata.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
      },
      onError: ({ message }: any) => {
        fireNotification({ type: "error", description: message });
      },
    });
  };
  useEffect(() => {
    initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data?.data]);
  const initialData = () => {
    if (!data.data?.data) {
      return;
    }
    const values = {
      ...data?.data?.data,
      id: data?.data?.data[0]?.id,
      headerTh: data?.data?.data[0]?.headerTh,
      headerEn: data?.data?.data[0]?.headerEn,
      descriptionTh: data?.data?.data[0]?.descriptionTh,
      descriptionEn: data?.data?.data[0]?.descriptionEn,
    };
    form.setFieldsValue(values);
  };
  const titlepdpaTh = (
    <span>
      {t("pdpa-title")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const titlepdpaEn = (
    <span>
      {t("pdpa-title")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );

  return (
    <PageContent
      breadcrumb={{
        form,
        mutate: {
          onCancel: false,
          onSaveAndContinue: false,
          onSave: onFinish,
        },
      }}
      className="p-5"
    >
      <Form form={form} layout="vertical">
        <div className="rounded-app bg-white">
          <div className="px-5 py-3 ">
            <Typography.Title className="!text-green-600" level={4}>
              {t("setting.pdpa")}
            </Typography.Title>
          </div>
          <Divider className="!m-0" />
          <Row className="px-5 py-3 " gutter={[0, 0]}>
            <Col span={23}>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name="headerTh"
                label={titlepdpaTh}
                rules={[{ required: true }]}
              >
                <CInput placeholder={t("pdpa-title")} />
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item
                name="descriptionTh"
                label={t("details")}
                rules={[{ required: true }]}
              >
                <ReactQuillStyle
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  className="h-[300px] pb-8"
                  placeholder={t("details")}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="rounded-app bg-white">
          <Row className="px-5 py-3  mt-5" gutter={[0, 0]}>
            <Col span={23}>
              <Form.Item
                name="headerEn"
                label={titlepdpaEn}
                rules={[{ required: true }]}
              >
                <CInput placeholder={t("pdpa-title")} />
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item
                name="descriptionEn"
                label={t("details")}
                rules={[{ required: true }]}
              >
                <ReactQuillStyle
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  className="h-[300px] pb-8"
                  placeholder={t("details")}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </PageContent>
  );
};
export default PDPA;
