import LandingPage from "components/pages/landing-page";
import { useContext } from "react";
import { useReducer } from "react";
import { ComponentType, createContext } from "react";
import { TMainPage } from "router/interface";
import { IPageRouteContext, PageRouteReducerType } from "./interface";
import { pageRouteReducer } from "./reducer";

const PageRouteContext = createContext<IPageRouteContext>(
  {} as IPageRouteContext
);

const withPageRoute = (Component: ComponentType, pageKey: TMainPage) => {
  return () => {
    const [state, dispatch] = useReducer<PageRouteReducerType>(
      pageRouteReducer,
      { pages: [] }
    );

    const getCurrentElement = () => {
      const { pages } = state;
      if (pages.length <= 0) {
        return <LandingPage />;
      }
      return pages[pages.length - 1].element;
    };

    const getCurrentKey = (position?: number) => {
      const { pages } = state;
      if (pages.length <= 0) {
        return;
      }
      if (position || position === 0) {
        return pages[position].key;
      }
      return pages[pages.length - 1].key;
    };

    const getCurrentParams = () => {
      const { pages } = state;
      if (pages.length <= 0) {
        return {};
      }
      return pages[pages.length - 1]?.params || {};
    };

    return (
      <PageRouteContext.Provider
        value={{
          dispatch,
          getCurrentElement,
          getCurrentParams,
          getCurrentKey,
          pageKey,
          ...state,
        }}
      >
        <Component />
      </PageRouteContext.Provider>
    );
  };
};

export const usePageRoute = () => useContext(PageRouteContext);
export default withPageRoute;
