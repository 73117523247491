import { Col, Row, Typography } from "antd";
import styled from "styled-components";
import Chart from "react-apexcharts";

const Label = styled.div<{ color: string }>`
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: ${({ color }) => color};
`;

interface LineChartProps {
  options?: ApexCharts.ApexOptions;
  categories: string[] | any[];
  series: { name: string; data: number[] }[];
  height?: number;
}

export const LabelStyle = ({
  color,
  text,
}: {
  color: string;
  text?: string;
}) => {
  return (
    <Row gutter={[8, 6]}>
      <Col className="center">
        <Label color={color} />
      </Col>
      <Col flex="auto" className="center">
        <Typography.Text
          ellipsis={{ tooltip: text || "" }}
          style={{ color: "#9A9A9A", fontSize: "18px" }}
        >
          {text || ""}
        </Typography.Text>
      </Col>
    </Row>
  );
};

const LineChartHome = ({
  categories,
  series,
  height = 380,
}: LineChartProps) => {
  const options: ApexCharts.ApexOptions = {
    xaxis: {
      categories: categories,
      labels: {
        style: {
          //   colors: textColors[color],
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          //   colors: textColors[color],
          fontSize: "14px",
        },
        offsetY: 3,
      },
    },
    grid: {
      strokeDashArray: 0.5,
    },
    chart: {
      fontFamily: "kanit-regular",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value.toString();
        },
      },
    },
    colors: ["#57BC82", "#E0B33C"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      lineCap: "butt",
      width: 3,
      colors: ["#57BC82", "#E0B33C"],
    },
  };

  return (
    <Chart type="area" height={height} options={options} series={series} />
  );
};

export default LineChartHome;
