import {
  Layout,
  Row,
  Col,
  Image,
  Dropdown,
  Menu,
  Badge,
  Divider,
  Typography,
} from "antd";
import {
  AppstoreFilled,
  BellFilled,
  DownOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MenuInfo } from "rc-menu/lib/interface";
import React from "react";
import CAvatar from "components/display/c-avatar";
import withMainMenu, { useMainMenu } from "provider/main-menu";
import MainMenu from "components/layout/main-menu";
import { LogoImage } from "assets/images";
import { Logout } from "services/auth";
import { useAppLanguage } from "provider/app-language";
import CSegmented from "components/input/c-segmented";
import { useAuth } from "provider/auth";
import NotificationProvider, {
  useNotificationHeader,
} from "provider/notification-header";
import NotificationPanel from "components/layout/notification-header/notification";

const NotificationBellWrapper = styled.div`
  display: inline-flex;
  color: white;
  height: 64px;
`;

const Header = () => {
  return (
    <Layout.Header style={{ backgroundColor: "#2F9A5D" }} className="!p-0">
      <Row gutter={12} align="middle" className="w-full h-full">
        <Col>
          <MenuBox />
        </Col>
        <Col>
          <LogoBox />
        </Col>
        <Col className="ml-auto">
          <LanguageBar />
        </Col>
        <Col></Col>
        <Col>
          <NotificationProvider>
            <NotificationBell />
          </NotificationProvider>
        </Col>
        <Col>
          <Divider
            type="vertical"
            style={{
              height: 30,
              borderLeft: "1px solid rgb(255, 255, 255, 0.3)",
            }}
          />
        </Col>
        <Col>
          <UserBox />
        </Col>
      </Row>
    </Layout.Header>
  );
};

const LanguageBar = () => {
  const { lang, changeLanguage } = useAppLanguage();
  return (
    <CSegmented
      onChange={changeLanguage}
      options={[
        { value: "en", label: "EN" },
        { value: "th", label: "TH" },
      ]}
      value={lang}
    />
  );
};

const UserBox = () => {
  const { t } = useTranslation();

  const onMenuClick = (info: MenuInfo) => {
    if (!info) return;
    const { key } = info;
    switch (key) {
      case "logout":
        return Logout();
      default:
        return;
    }
  };

  const overlay = (
    <Menu
      onClick={onMenuClick}
      items={[
        {
          label: (
            <Row align="middle" className="text-[20px]">
              <Col span={4} className="!flex align-center">
                <ExportOutlined />
              </Col>
              <Col span={20}>{t("logout")}</Col>
            </Row>
          ),
          key: "logout",
        },
      ]}
    />
  );

  return (
    <Dropdown
      getPopupContainer={(e) => e}
      overlayStyle={{ width: 200, top: 50 }}
      trigger={["click"]}
      placement="bottomRight"
      arrow
      overlay={overlay}
    >
      <ProfileBox />
    </Dropdown>
  );
};

const ProfileBox = ({
  className,
  onClick,
  ...rest
}: {
  className?: string;
  onClick?: any;
}) => {
  const { translationObject } = useAppLanguage();
  const { userMe } = useAuth();

  const { title, firstName, lastName } = translationObject({
    object: userMe,
    keys: ["role.title", "firstName", "lastName"],
  });

  return (
    <Row
      className={`text-white cursor-pointer ${className}`}
      style={{ width: 240 }}
      onClick={onClick}
      align="middle"
      {...rest}
    >
      <Col span={6}>
        <CAvatar src={userMe?.profile?.url} size="large" />
      </Col>
      <Col span={16}>
        <Typography.Title ellipsis className="!text-white !text-[22px]">
          {firstName} {lastName}
        </Typography.Title>
        <Typography.Title
          style={{ fontSize: 18 }}
          ellipsis
          className="!text-gray-300"
        >
          {title}
        </Typography.Title>
      </Col>
      <Col span={2}>
        <DownOutlined className="!text-white" style={{ fontSize: 18 }} />
      </Col>
    </Row>
  );
};

const MenuBoxWrapper = styled.div`
  height: 64px;
  width: 64px;
  display: inline-flex;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

const LogoBox = () => {
  return (
    <div className="center">
      <Image
        preview={false}
        src={LogoImage}
        style={{ height: 50, width: 50 }}
      />
      <div className="ml-2">
        <Typography.Title style={{ fontSize: 18 }} className="!text-white">
          บริษัท ธนากร ผลิตภัณฑ์น้ำมันพืช จำกัด
        </Typography.Title>
        <Typography.Title style={{ fontSize: 16 }} className="!text-white">
          Thanakorn Vegetable Oil Products Co., Ltd.
        </Typography.Title>
      </div>
    </div>
  );
};

const MenuBox = () => {
  const { toggleMenu } = useMainMenu();

  return (
    <React.Fragment>
      <MainMenu />
      <MenuBoxWrapper className="center" onClick={toggleMenu}>
        <AppstoreFilled style={{ fontSize: 30 }} />
      </MenuBoxWrapper>
    </React.Fragment>
  );
};

const NotificationBell = () => {
  const { setOpenNoti, notiNotRead, openNoti } = useNotificationHeader();
  const notReadSome = notiNotRead?.();
  // const findNoti = mapData();
  // const findNotification = findNoti.filter((e) => e.isActive);

  // if (!findNotification || findNotification.length <= 0) {
  //   return null;
  // }

  return (
    <Dropdown
      overlay={<NotificationPanel />}
      overlayStyle={{ top: 55 }}
      trigger={["click"]}
      placement="bottom"
      arrow={false}
      getPopupContainer={(e) => e}
      onVisibleChange={(open) => {
        setOpenNoti?.(open);
      }}
      visible={openNoti}
    >
      <NotificationBellWrapper className="center cursor-pointer">
        <Badge dot={notReadSome > 0}>
          <BellFilled style={{ fontSize: 24, color: "white" }} />
        </Badge>
      </NotificationBellWrapper>
    </Dropdown>
  );
};

export default withMainMenu(Header);
