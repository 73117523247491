export const MenuAccessControlOverview = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.182"
      height="27.182"
      viewBox="0 0 27.182 27.182"
      {...props}
    >
      <path
        fill="#fff"
        d="M9.627 7.384V2.243C9.627.646 8.9 0 7.1 0H2.526C.725 0 0 .646 0 2.243v5.13c0 1.608.725 2.243 2.526 2.243H7.1c1.8.011 2.527-.635 2.527-2.232z"
        transform="translate(15.29 2.265)"
      ></path>
      <path
        fill="#fff"
        d="M9.627 7.1V2.526C9.627.725 8.9 0 7.1 0H2.526C.725 0 0 .725 0 2.526V7.1c0 1.8.725 2.527 2.526 2.527H7.1c1.8 0 2.527-.727 2.527-2.527z"
        data-name="Vector"
        transform="translate(15.29 15.29)"
      ></path>
      <path
        fill="#fff"
        d="M9.627 7.384V2.243C9.627.646 8.9 0 7.1 0H2.526C.725 0 0 .646 0 2.243v5.13c0 1.608.725 2.243 2.526 2.243H7.1c1.8.011 2.527-.635 2.527-2.232z"
        data-name="Vector"
        transform="translate(2.265 2.265)"
      ></path>
      <path
        fill="#fff"
        d="M9.627 7.1V2.526C9.627.725 8.9 0 7.1 0H2.526C.725 0 0 .725 0 2.526V7.1c0 1.8.725 2.527 2.526 2.527H7.1c1.8 0 2.527-.727 2.527-2.527z"
        data-name="Vector"
        transform="translate(2.265 15.29)"
      ></path>
    </svg>
  );
};

export const MenuAccessControlAccessibility = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.828"
      height="21.763"
      data-name="Group 43989"
      viewBox="0 0 17.828 21.763"
      {...props}
    >
      <defs>
        <clipPath id="MenuAccessControlAccessibility">
          <path
            fill="#fff"
            d="M0 0H17.828V21.763H0z"
            data-name="Rectangle 24057"
          ></path>
        </clipPath>
      </defs>
      <g
        fill="#fff"
        clipPath="url(#MenuAccessControlAccessibility)"
        data-name="Group 29418"
      >
        <path
          d="M8.99 160.277h7.4c1.161 0 1.439.27 1.44 1.406v10.792c0 1.094-.306 1.413-1.366 1.414H1.371C.328 173.889 0 173.57 0 172.55v-10.943c0-1.036.3-1.327 1.365-1.329q3.811-.006 7.622 0M7.4 169.642h3.032c-.235-.779-.425-1.5-.675-2.207a.886.886 0 01.243-1.159 1.372 1.372 0 00-.028-2.019 1.47 1.47 0 00-2.1-.024 1.448 1.448 0 00-.09 2.036 6.46 6.46 0 00.484.46l-.866 2.913"
          data-name="Path 35492"
          transform="translate(0 -152.128)"
        ></path>
        <path
          d="M53.906 7.517c-.5-3.5-2.269-5.519-4.829-5.553-2.628-.036-4.4 1.928-4.979 5.563h-1.85a7.57 7.57 0 013.141-6.292A5.838 5.838 0 0152 .842c2.553 1.428 3.62 3.776 3.783 6.676z"
          data-name="Path 35493"
          transform="translate(-40.1)"
        ></path>
      </g>
    </svg>
  );
};

export const MenuAccessControlFaceAccessibility = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.442"
      height="23.442"
      viewBox="0 0 23.442 23.442"
      {...props}
    >
      <defs>
        <clipPath id="MenuAccessControlFaceAccessibility">
          <path
            fill="none"
            d="M0 0H23.442V23.442H0z"
            data-name="Rectangle 26266"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 43990">
        <g
          fill="#fff"
          clipPath="url(#MenuAccessControlFaceAccessibility)"
          data-name="Group 33495"
        >
          <path
            d="M24.964 36.476a4.948 4.948 0 002.356-1.015 28.46 28.46 0 001.746-1.251l-.043-.086a3.7 3.7 0 00-1.62-1.636l-2.657-1.207a.774.774 0 01-.274-1.038l.882-1.548a6.879 6.879 0 00.462-6.16 3.923 3.923 0 00-7.266.026l-.006.016a6.879 6.879 0 00.666 6.4l.839 1.251a.684.684 0 01-.286 1l-2.839 1.292a3.7 3.7 0 00-1.741 1.634l-.046.086a10.479 10.479 0 004.229 2.248.294.294 0 01.226.15h5.08a.334.334 0 01.291-.162"
            data-name="Path 37772"
            transform="translate(-10.381 -13.79)"
          ></path>
          <path
            d="M51.658 21.128a.774.774 0 01.1-.011.34.34 0 01.136.025v-2.807h-2.761c.008.078.019.156.03.234h2.5z"
            data-name="Path 37773"
            transform="translate(-33.697 -12.575)"
          ></path>
          <path
            d="M51.453 44.378h-.01v2.662h-2.618a1.334 1.334 0 01-.005.234h2.857v-2.906c-.075 0-.15 0-.224.01"
            data-name="Path 37774"
            transform="translate(-33.482 -30.429)"
          ></path>
          <path
            d="M19.24 18.335h-2.732v2.784h.081a.289.289 0 01.055 0 .585.585 0 01.071.007h.027v-2.557h2.5v-.022-.211"
            data-name="Path 37775"
            transform="translate(-11.322 -12.575)"
          ></path>
          <path
            d="M16.733 44.378h.009v2.662h2.618a1.366 1.366 0 00.005.234h-2.857v-2.906c.075 0 .15 0 .225.01"
            data-name="Path 37776"
            transform="translate(-11.322 -30.429)"
          ></path>
          <path
            d="M20.009 3.433A11.721 11.721 0 103.433 20.009 11.721 11.721 0 0020.009 3.433M11.721.811a10.909 10.909 0 11-10.91 10.91A10.922 10.922 0 0111.721.811"
            data-name="Path 37777"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuAccessControlReport = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.373"
      height="25.373"
      viewBox="0 0 25.373 25.373"
      {...props}
    >
      <g data-name="vuesax/bold/document-text" transform="translate(-172 -188)">
        <g fill="#fff" transform="translate(172 188)">
          <path
            d="M1.12.195A.654.654 0 000 .635v3.49a2.726 2.726 0 002.75 2.67c.95.01 2.27.01 3.4.01a.631.631 0 00.47-1.07c-1.44-1.45-4.02-4.06-5.5-5.54z"
            transform="translate(16.053 2.015)"
          ></path>
          <path
            d="M19.3 8.752h-3.1a4.61 4.61 0 01-4.611-4.6V1.069A1.074 1.074 0 0010.519 0H5.973A5.649 5.649 0 000 5.952v9.468a5.649 5.649 0 005.973 5.952H14.4a5.649 5.649 0 005.973-5.952v-5.6A1.074 1.074 0 0019.3 8.752zm-9.65 8.079H5.361a.8.8 0 110-1.6H9.65a.8.8 0 110 1.6zm2.145-4.275H5.361a.8.8 0 110-1.6h6.434a.8.8 0 110 1.6z"
            data-name="Vector"
            transform="translate(2.5 2)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuAccessControlRealtimeMonitoring = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="#fff" transform="translate(-300 -252)">
        <path
          d="M16.75 9.9H14.6l-2.51 1.68a2.144 2.144 0 01-1.19.35 2.065 2.065 0 01-1.02-.26 2.276 2.276 0 01-1.13-1.98 4.52 4.52 0 01-1.92-1.12A4.764 4.764 0 015.5 5.15V0h-1C1.8 0 0 1.35 0 4.5v5.4c0 3.15 1.8 4.5 4.5 4.5h3.75v2.85H5.4a.75.75 0 000 1.5h7.2a.75.75 0 000-1.5H9.75V14.4h3.75c2.7 0 4.5-1.35 4.5-4.5z"
          data-name="Vector"
          transform="translate(302 256)"
        ></path>
        <path
          d="M9.75 0h-6.5A3.048 3.048 0 00.2 2 3.854 3.854 0 000 3.25v3.9a3.263 3.263 0 00.89 2.36 3.263 3.263 0 002.36.89v1.39a.655.655 0 001.01.54l2.89-1.93h2.6a3.854 3.854 0 001.25-.2 3.048 3.048 0 002-3.05v-3.9A3.071 3.071 0 009.75 0zM3.62 6a.75.75 0 11.75-.75.749.749 0 01-.75.75zm2.82 0a.75.75 0 11.75-.75.749.749 0 01-.75.75zm2.81 0a.75.75 0 11.75-.75.749.749 0 01-.75.75z"
          data-name="Vector"
          transform="translate(309 254)"
        ></path>
        <path
          d="M13 3.25v3.9a3.048 3.048 0 01-2 3.05 3.854 3.854 0 01-1.25.2h-2.6l-2.89 1.93a.655.655 0 01-1.01-.54V10.4a3.263 3.263 0 01-2.36-.89A3.263 3.263 0 010 7.15v-3.9A3.854 3.854 0 01.2 2a3.048 3.048 0 013.05-2h6.5A3.071 3.071 0 0113 3.25z"
          data-name="Vector"
          transform="translate(309 254)"
        ></path>
      </g>
    </svg>
  );
};
