import { FC } from "react";
import { useTranslation } from "react-i18next";
import { MarkTypes } from "services/mark/interface";
import { CRadioGroupProps, StyledRadioGroup } from "./c-radio-group";

const CRadioMarkerType: FC<CRadioGroupProps> = (props) => {
  const { t } = useTranslation();
  const options: { label: string; value: MarkTypes }[] = [
    {
      label: t("parking-area"),
      value: "PARKING_AREA",
    },
    {
      label: t("camera"),
      value: "CAMERA",
    },
    {
      label: t("barrier"),
      value: "BARRIER",
    },
  ];

  return (
    <StyledRadioGroup
      {...props}
      height="45px"
      optionType="default"
      options={options}
    />
  );
};

export default CRadioMarkerType;
