import { Col, Form, Row, Typography } from "antd";
import CAvatar from "components/display/c-avatar";
import PageContent from "components/layout/page-content";
import LogStatusCar from "components/menu/vehicle-inspection/log-status-car";
import { typeOfUser } from "config/axios/interface";
import { useAppLanguage } from "provider/app-language";
import { usePageRoute } from "provider/page-route";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useGetVehiclesQueue,
  useGetVehiclesQueueLog,
} from "services/status-queue-car";
import {
  IVehicleQueue,
  IVehicleQueueLog,
} from "services/status-queue-car/interface";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #e6e6e6;
  height: calc(100vh - 180px);
`;

const StatusQueueCarDetail = () => {
  const [form] = Form.useForm();
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const dataVehicleQueue = useGetVehiclesQueue(id);
  const dataVehicleQueueLog = useGetVehiclesQueueLog(id);

  return (
    <PageContent
      loading={dataVehicleQueue.isFetching || dataVehicleQueueLog.isFetching}
      breadcrumb={{
        form: form,
        shadow: false,
        mutate: { onSave: false, onSaveAndContinue: false },
      }}
    >
      <Row>
        <Col span={6}>
          <SideBarDetail data={dataVehicleQueue.data} />
        </Col>
        <Col span={18} className="px-5 pt-5">
          <CardHistory data={dataVehicleQueueLog.data} />
        </Col>
      </Row>
    </PageContent>
  );
};

interface IProps {
  data?: IVehicleQueue;
}

//Detail Sidebar
const SideBarDetail: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const dataValue = InitialValue({ data });
  const {
    productGroup,
    name,
    userTypeText,
    vehicleType,
    vehicleBrand,
    licensePlateNumber,
    trailerLicensePlateNumber,
    weight,
    trailerWeight,
    provincePlate,
    image,
  } = dataValue;

  return (
    <React.Fragment>
      <Wrapper className="py-5 px-8">
        <Row>
          <Typography.Title level={5}>{t("details-data")}</Typography.Title>
        </Row>
        <Row className="py-8 d-flex justify-center text-center">
          <Col span={24}>
            <CAvatar size={90} src={image} />
          </Col>
          <Col span={24} className="py-4">
            <Typography.Title level={5} style={{ color: "#2F9A5D" }}>
              {name}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("material-type")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {productGroup}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("person-type")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {userTypeText}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("car-type")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {vehicleType}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("vehicle-brand")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {vehicleBrand}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("trailer-head-license-plate-number")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {licensePlateNumber}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("trailer-tail-license-plate-number")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {trailerLicensePlateNumber}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("trailer-head-weight")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {weight}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("trailer-tail-weight")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {trailerWeight}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <div>
              <Typography.Text style={{ color: "#716C6C" }}>
                {t("province")}
              </Typography.Text>
            </div>
            <Typography.Text className="bold" style={{ color: "#2C2D31" }}>
              {provincePlate}
            </Typography.Text>
          </Col>
        </Row>
      </Wrapper>
    </React.Fragment>
  );
};

interface IDataInitial {
  productGroup: string;
  name: string;
  userTypeText: string;
  vehicleType: string;
  vehicleBrand: string;
  licensePlateNumber: string;
  trailerLicensePlateNumber: string;
  weight: string;
  trailerWeight: string;
  provincePlate: string;
  image?: string;
}

const InitialValue = ({ data }: { data?: IVehicleQueue }): IDataInitial => {
  const { translationObject } = useAppLanguage();
  const { t } = useTranslation();

  const { customerEmployeeDetail } = data || {};
  const { receivers, supplierAndVisitors } = customerEmployeeDetail || {};

  let productGroup = "-";
  let name = "-";
  let userTypeText = "-";

  let vehicleType = "-";
  let vehicleBrand = "-";
  let licensePlateNumber = "-";
  let trailerLicensePlateNumber = "-";
  let weight = "-";
  let trailerWeight = "-";
  let provincePlate = "-";

  const image = customerEmployeeDetail?.profile?.url;

  const userTypeKey = customerEmployeeDetail?.userType;
  userTypeText = userTypeKey ? t(typeOfUser[userTypeKey]) : "-";

  const firstName = customerEmployeeDetail?.firstName || "";
  const lastName = customerEmployeeDetail?.lastName || "";
  name = firstName && lastName ? `${firstName} ${lastName}` : "-";

  switch (customerEmployeeDetail?.userType) {
    case "RECEIVER": //RECEIVER
      productGroup = receivers?.[0]?.productGroups || "-";
      const vehicleRecei = translationObject({
        object: receivers?.[0]?.vehicleType,
        keys: ["vehicle"],
      });
      vehicleType = receivers?.[0]?.vehicleType ? vehicleRecei.vehicle : "-";
      licensePlateNumber = receivers?.[0]?.licensePlateNumber || "-";
      trailerLicensePlateNumber =
        receivers?.[0]?.trailerLicensePlateNumber || "-";
      break;

    case "SUPPLIER": //SUPPLIER
      const supplierJobTypes = supplierAndVisitors?.[0]?.supplierJobTypes?.map(
        (e) => {
          const { title } = translationObject({
            object: e.supplierType,
            keys: ["title"],
          });
          return title;
        }
      );

      productGroup =
        (supplierAndVisitors?.[0]?.supplierJobTypes
          ? supplierJobTypes?.toString()
          : "-") || "-";

      const vehicleSupplier = translationObject({
        object: supplierAndVisitors?.[0]?.vehicleType,
        keys: ["vehicle"],
      });
      vehicleType = supplierAndVisitors?.[0]?.vehicleType
        ? vehicleSupplier.vehicle
        : "-";
      vehicleBrand = supplierAndVisitors?.[0]?.vehicleBrand || "-";
      licensePlateNumber = supplierAndVisitors?.[0]?.licensePlateNumber || "-";
      trailerLicensePlateNumber =
        supplierAndVisitors?.[0]?.trailerLicensePlateNumber || "-";
      weight = supplierAndVisitors?.[0]?.weight?.toString() || "-";
      trailerWeight =
        supplierAndVisitors?.[0]?.trailerWeight?.toString() || "-";
      provincePlate = supplierAndVisitors?.[0]?.provincePlate || "-";
      break;

    case "VISITOR": //VISITOR
      productGroup = "-";
      const vehicleVisitor = translationObject({
        object: supplierAndVisitors?.[0]?.vehicleType,
        keys: ["vehicle"],
      });
      vehicleType = supplierAndVisitors?.[0]?.vehicleType
        ? vehicleVisitor.vehicle
        : "-";
      vehicleBrand = supplierAndVisitors?.[0]?.vehicleBrand || "-";
      licensePlateNumber = supplierAndVisitors?.[0]?.licensePlateNumber || "-";
      trailerLicensePlateNumber =
        supplierAndVisitors?.[0]?.trailerLicensePlateNumber || "-";
      weight = supplierAndVisitors?.[0]?.weight?.toString() || "-";
      trailerWeight =
        supplierAndVisitors?.[0]?.trailerWeight?.toString() || "-";
      provincePlate = supplierAndVisitors?.[0]?.provincePlate || "-";
      break;

    case "CONTRACTOR": //CONTRACTOR
      break;
  }

  return {
    productGroup,
    name,
    userTypeText,
    vehicleType,
    vehicleBrand,
    licensePlateNumber,
    trailerLicensePlateNumber,
    weight,
    trailerWeight,
    provincePlate,
    image,
  };
};

//Log status

const CardHistory = ({ data }: { data?: IVehicleQueueLog }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Row className="p-5">
        <Typography.Title level={5}>{t("history-work")}</Typography.Title>
      </Row>
      <LogStatusCar data={data} />
    </React.Fragment>
  );
};

export default StatusQueueCarDetail;
