import Icon from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteAccessibility,
  useDeleteAccessibilityBulk,
  useGetAccessibilitys,
  usePostAccessFromHCP,
} from "services/accessibility";
import {
  IAccessibility,
  IAccessibilityParams,
} from "services/accessibility/interface";
import styled from "styled-components";
import tw from "twin.macro";
import { BarrierCloseIcon } from "assets/icons/general";
import { hikCentral } from "config/axios";
import { fireNotification } from "components/popup/notification";
import { useQueryClient } from "@tanstack/react-query";
import { useAppLoading } from "provider/app-loading";

const StyledIcon = styled(Icon)`
  svg * {
    ${tw`fill-colors-primary-dark`};
  }
  font-size: 28px;
`;

const AccessibilityManagementPage = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState<IAccessibilityParams>({
    page: 1,
    limit: 10,
  });
  const data_accessibility = useGetAccessibilitys(params);
  const delete_accessibility = useDeleteAccessibility();
  const delete_accessibility_bulk = useDeleteAccessibilityBulk();
  const post_hcp = usePostAccessFromHCP();
  const qClient = useQueryClient();

  const { setAppLoading } = useAppLoading();

  const elements: ISearchBoxElement[] = [
    {
      name: "search",
      label: t("accessibility-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: ColumnsType<IAccessibility> = [
    {
      title: t("accessibility-name"),
      render: (_, rc) => {
        return (
          <Typography.Text>{rc?.accessibilityName || "-"}</Typography.Text>
        );
      },
    },
    {
      title: t("number-of-accessibility"),
      render: (_, rc) => {
        return (
          <Row gutter={12} className="mx-2">
            <Col>
              <StyledIcon component={BarrierCloseIcon} />
            </Col>
            <Col>
              <Typography.Text>{`${rc?.lengthInGroup|| 0} ${t("list")}`}</Typography.Text>
            </Col>
          </Row>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  const onRefetch = () => {
    post_hcp.mutate(
      {},
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "access-control" });
          qClient.invalidateQueries(["get-accessibilitys"]);
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: "access-control",
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        title={t("accessibility-list")}
        columns={columns}
        query={data_accessibility}
        loading={data_accessibility?.isFetching}
        action={{
          bulkDelete: {
            mutation: delete_accessibility_bulk,
            invalidateQueries: ["get-accessibilitys", "get-accessibility"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: delete_accessibility,
          invalidateQueries: ["get-accessibilitys", "get-accessibility"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        extraHeaderButton={[
          {
            children: t("refetch"),
            theme: "white",
            onClick: () => {
              onRefetch();
            },
          },
          {
            children: t("add"),
            theme: "yellow",
            onClick: () => {
              window.open(hikCentral, "_blank");
            },
          },
        ]}
        onRowClick="access-control.accessibility-management-detail"
      />
    </PageContent>
  );
};

export default AccessibilityManagementPage;
