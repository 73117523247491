import { baseUrl } from "config/axios";
import {
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IMatchDevice } from "services/match-device/interface";
import io from "socket.io-client";
import {
  IReceiveVehicleSocket,
  ISelectedVehicleSocket,
  IVehicleSocketContext,
  IVehicleSocketFace,
  IVehicleSocketPerson,
} from "./interface";

const Context = createContext<IVehicleSocketContext>(
  {} as IVehicleSocketContext
);

const withVehicleSocketProvider = (
  Component: ComponentType,
  ignoreDevice: boolean = false
) => {
  return () => {
    const [socket, setSocket] = useState<SocketIOClient.Socket>();
    const [selected, setSelected] = useState<ISelectedVehicleSocket>();
    const [receives, setReceives] = useState<IReceiveVehicleSocket[]>([]);
    const [person, setPerson] = useState<IVehicleSocketPerson>();
    const [detect, setDetect] = useState<IReceiveVehicleSocket>();
    const [face, setFace] = useState<IVehicleSocketFace>();

    const [requireFace, setRequireFace] = useState<boolean>(false);
    const [matchDevice, setMatchDevice] = useState<IMatchDevice | undefined>();

    useEffect(() => {
      const sk = io(baseUrl.replace("http", "ws"), {
        transports: ["websocket"],
      });
      setSocket(sk);

      return () => {
        sk.disconnect();
        setSocket(undefined);
      };
    }, []);

    useEffect(() => {
      if (!socket || !selected) return;
      initializeVehicle();
      initializePerson();
      initializeFace();

      return () => {
        setReceives([]);
        setPerson(undefined);
        setDetect(undefined);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, socket, person]);

    const initializeVehicle = () => {
      socket?.on(`on-detect-vehicle`, (data: IReceiveVehicleSocket) => {
        console.log(`on-detect-vehicle`, data);

        const { id: inCommingId } = data.encodeDevice || {};
        const { id: selectId } = selected?.vehicle.encodeDevice || {};
        if (inCommingId !== selectId && !ignoreDevice) return;
        setReceives((prev) => [data, ...prev]);
        setDetect(data);
      });
    };

    const initializePerson = () => {
      socket?.on(`on-detect-person`, (value: IVehicleSocketPerson) => {
        const { id: inCommingId } = value.encodeDevice || {};
        const { id: general } = selected?.generalFace?.encodeDevice || {};
        const { id: truck } = selected?.truckFace?.encodeDevice || {};

        if (inCommingId === general || inCommingId === truck) {
          setPerson(value);
        }
      });
    };

    const initializeFace = () => {
      socket?.on(`on-face-detect`, (value: IVehicleSocketFace) => {
        console.log(value);
        setFace(value);
      });
    };

    return (
      <Context.Provider
        value={{
          receives,
          selected,
          person,
          setSelected,
          face,
          setFace,
          detect,
          setDetect,
          requireFace,
          setRequireFace,
          matchDevice,
          setMatchDevice,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useVehicleSocket = () => useContext(Context);
export default withVehicleSocketProvider;
