import { useQueryClient } from "@tanstack/react-query";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import { ISearchBoxElement } from "components/layout/page-search-box";
import { fireNotification } from "components/popup/notification";
import dayjs from "dayjs";
import { useAppLanguage } from "provider/app-language";
import { useAppLoading } from "provider/app-loading";
import { useUserPendingList } from "provider/user-pending-list";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetReqContarctor,
  useGetReqVisitor,
  useGetReqReceiver,
  useGetReqSupplier,
  usePatchAcceptUser,
  usePatchRejectUser,
} from "services/req-approve-customer-employee";
import { IReqApproveParams } from "services/req-approve-customer-employee/interface";

const PendingApprovalTabel = () => {
  const { t } = useTranslation();
  const { selected } = useUserPendingList();

  const [params, setParams] = useState<IReqApproveParams>({
    acceptStatus: "PENDING",
  });
  const [paramsContractor, setParamsContractor] = useState<IReqApproveParams>({
    acceptStatus: "PENDING",
  });
  const [paramsReceiver, setParamsReceiver] = useState<IReqApproveParams>({
    acceptStatus: "PENDING",
  });
  const [paramsSupplier, setParamsSupplier] = useState<IReqApproveParams>({
    acceptStatus: "PENDING",
  });

  const get_visiters = useGetReqVisitor(params);
  const get_contractors = useGetReqContarctor(paramsContractor);
  const get_suppliers = useGetReqSupplier(paramsSupplier);
  const get_receivers = useGetReqReceiver(paramsReceiver);


  const { formatDate } = useAppLanguage();
  const qClient = useQueryClient();
  const { setAppLoading } = useAppLoading();

  const patch_reject = usePatchRejectUser();
  const patch_accept = usePatchAcceptUser();

  const get_user =
    selected?.userType === "VISITOR"
      ? get_visiters
      : selected?.userType === "CONTRACTOR"
        ? get_contractors
        : selected?.userType === "RECEIVER"
          ? get_receivers
          : selected?.userType === "SUPPLIER"
            ? get_suppliers
            : undefined;

  const invalidateQueries =
    selected?.userType === "VISITOR"
      ? ["get-req-visitor"]
      : selected?.userType === "CONTRACTOR"
        ? ["get-req-contractor"]
        : selected?.userType === "RECEIVER"
          ? ["get-req-receiver"]
          : selected?.userType === "SUPPLIER"
            ? ["get-req-supplier"]
            : undefined;

  if (!selected) return null;

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("registration-date"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  if (selected?.userType === "CONTRACTOR") {
    elements.push(
      {
        name: "phoneNumber",
        label: t("phone-number"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      },
      {
        name: "identityCode",
        label: t("contractor-id"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      },
      {
        name: "companyName",
        label: t("affiliation"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      }
    );
  } else {
    elements.push(
      {
        name: "identityCode",
        label: t("identification-card-number-passport-other"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      },
      {
        name: "employeeName",
        label: t("contact-recipient"),
        input: {
          type: "Input",
          options: {
            search: true,
          },
        },
      }
    );
  }

  const columns: ColumnsType<any> = [
    {
      title: t("registration-date"),
      width: 200,
      render: (_, rc) => {
        const date = formatDate({
          date: rc.createdAt,
          format: "nomal-full-time",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc.profile?.url} />;
      },
    },
  ];

  if (selected?.userType === "CONTRACTOR") {
    columns.push(
      {
        title: t("identification-card-number-passport-other"),
        align: "center",
        render: (_, rc) => {
          return (
            <Typography.Text ellipsis>
              {rc?.employeeName || "-"}
            </Typography.Text>
          );
        },
      },
      {
        title: t("full-name"),
        ellipsis: true,
        width: 200,
        align: "center",
        render: (_, rc) => {
          const firstName = rc?.firstName || "";
          const lastName = rc?.lastName || "";

          if (!firstName && lastName) return "-";
          const fullName = `${firstName} ${lastName}`;

          return (
            <Typography.Text ellipsis={{ tooltip: fullName }}>
              {fullName}
            </Typography.Text>
          );
        },
      },
      {
        title: t("phone-number"),
        align: "center",
        render: (_, rc) => {
          return (
            <Typography.Text ellipsis={{ tooltip: rc.phoneNumber || "-" }}>
              {rc.phoneNumber || "-"}
            </Typography.Text>
          );
        },
      },
      {
        title: t("staff"),
        render: (_, rc) => {
          const firstName =
            rc?.reqApproveDetail?.userApprove?.firstNameTh || "";
          const lastName = rc?.reqApproveDetail?.userApprove?.lastNameTh || "";

          if (!firstName && !lastName) return "-";

          return (
            <Typography.Text
              ellipsis
            >{`${firstName} ${lastName}`}</Typography.Text>
          );
        },
      }
    );
  } else {
    columns.push(
      {
        title: t("contact-recipient"),
        align: "center",
        render: (_, rc) => {
          const { visitorsDetail } = rc || {};
          const employee = visitorsDetail?.employee;

          const firstName = employee?.firstName;
          const lastName = employee?.lastName;

          if (!firstName && !lastName) return "-";
          const fullName = `${firstName} ${lastName}`;

          return <Typography.Text ellipsis>{fullName}</Typography.Text>;
        },
      },
      {
        title: t("full-name"),
        ellipsis: true,
        width: 200,
        align: "center",
        render: (_, rc) => {
          const firstName = rc?.firstName || "";
          const lastName = rc?.lastName || "";

          if (!firstName && lastName) return "-";
          const fullName = `${firstName} ${lastName}`;

          return (
            <Typography.Text ellipsis={{ tooltip: fullName }}>
              {fullName}
            </Typography.Text>
          );
        },
      },
      {
        title: t("phone-number"),
        align: "center",
        render: (_, rc) => {
          return (
            <Typography.Text ellipsis={{ tooltip: rc.phoneNumber || "-" }}>
              {rc.phoneNumber || "-"}
            </Typography.Text>
          );
        },
      },
      {
        title: t("staff"),
        render: (_, rc) => {
          const firstName =
            rc?.reqApproveDetail?.userApprove?.firstNameTh || "";
          const lastName = rc?.reqApproveDetail?.userApprove?.lastNameTh || "";

          if (!firstName && !lastName) return "-";

          return (
            <Typography.Text
              ellipsis
            >{`${firstName} ${lastName}`}</Typography.Text>
          );
        },
      }
    );
  }

  const onSearch = (values: any) => {
    const startDate = values.date
      ? dayjs(values.date[0]).startOf("day")
      : undefined;
    const endDate = values.date
      ? dayjs(values.date[1]).endOf("day")
      : undefined;
    values = { ...values, startDate, endDate };

    delete values.date;

    switch (selected?.userType) {
      case "VISITOR":
        return setParams({ ...params, ...values });
      case "CONTRACTOR":
        return setParamsContractor({ ...paramsContractor, ...values });
      case "RECEIVER":
        return setParamsReceiver({ ...paramsReceiver, ...values });
      case "SUPPLIER":
        return setParamsSupplier({ ...paramsSupplier, ...values });

    }
  };

  const onReject = (values: any) => {
    patch_reject.mutate(
      { ids: values },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "user-management" });
          invalidateQueries?.forEach((e) => qClient.invalidateQueries([e]));
          if ((values?.length || 0) <= 1) {
            switch (selected?.userType) {
              case "VISITOR":
                return setParams({ ...params, page: 1 });
              case "CONTRACTOR":
                return setParamsContractor({ ...paramsContractor, page: 1 });
              case "RECEIVER":
                return setParamsReceiver({ ...paramsReceiver, page: 1 });
              case "SUPPLIER":
                return setParamsSupplier({ ...paramsSupplier, page: 1 });
            }
          }
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            menu: "user-management",
            description: message,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const onAccept = (values: any) => {
    patch_accept.mutate(
      { ids: values },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: "user-management" });
          invalidateQueries?.forEach((e) => qClient.invalidateQueries([e]));
          if ((values?.length || 0) <= 1) {
            switch (selected?.userType) {
              case "VISITOR":
                return setParams({ ...params, page: 1 });
              case "CONTRACTOR":
                return setParamsContractor({ ...paramsContractor, page: 1 });
              case "RECEIVER":
                return setParamsReceiver({ ...paramsReceiver, page: 1 });
              case "SUPPLIER":
                return setParamsSupplier({ ...paramsSupplier, page: 1 });
            }
          }
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            menu: "user-management",
            description: message,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const rowClickPage =
    selected?.userType === "VISITOR"
      ? "usre-management.pending-guest-edit"
      : selected?.userType === "CONTRACTOR"
        ? "usre-management.pending-contractor-edit"
        : selected?.userType === "RECEIVER"
          ? "usre-management.pending-receiver-edit"
          : selected?.userType === "SUPPLIER"
            ? "usre-management.pending-supplier-edit"
            : undefined;

  return (
    <CTable
      scroll={{ x: 1000 }}
      title={t("pending-approval")}
      query={get_user}
      columns={columns}
      onRowClick={rowClickPage}
      extraHeaderButton={[
        {
          theme: "gray",
          children: t("disapproval"),
          onClick: (_, selectRowsKey) => {
            onReject(selectRowsKey);
          },
        },
        {
          theme: "yellow",
          children: t("approve"),
          onClick: (_, selectRowsKey) => {
            onAccept(selectRowsKey);
          },
        },
      ]}
      action={{
        searchBox: { elements: elements, onFinish: onSearch },
      }}
      pagination={{
        onChange: (page, limit) => {
          switch (selected?.userType) {
            case "VISITOR":
              return setParams({ ...params, page, limit });
            case "CONTRACTOR":
              return setParamsContractor({ ...paramsContractor, page, limit });
            case "RECEIVER":
              return setParamsReceiver({ ...paramsReceiver, page, limit });
            case "SUPPLIER":
              return setParamsSupplier({ ...paramsSupplier, page, limit });
          }
        },
      }}
    />
  );
};

export default PendingApprovalTabel;
