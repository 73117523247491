import { useUserReport } from "provider/user-report";
import UserManagementReportEmployee from "./content/content-employee";
import UserManagementCustomerReport from "./content/content-customer";
import UserManagementUserReport from "./content/contend-user";
import UserManagementBlacklistReport from "./content/content-blacklist";

const contentPage = {
  CUSTOMER: <UserManagementCustomerReport />,
  EMPLOYEE: <UserManagementReportEmployee />,
  USER: <UserManagementUserReport />,
  BLACKLIST: <UserManagementBlacklistReport/>,
};

const UserManagementReportContent = () => {
  const { personType } = useUserReport();

  return (
    <div className="p-5">
      {personType === "CUSTOMER"
        ? contentPage["CUSTOMER"]
        : personType === "EMPLOYEE"
        ? contentPage["EMPLOYEE"]
        : personType === "USER"
        ? contentPage["USER"]
        : contentPage["BLACKLIST"]
      }
    </div>
  );
};

export default UserManagementReportContent;
