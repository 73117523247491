import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Typography } from "antd";
import Form from "antd/lib/form";
import { ParkerTime } from "assets/icons/general";
import CInput from "components/input/c-input";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetParkingTime,
  usePatchParkingTime,
} from "services/setting-set-parking-time";

const SetAParkingTime = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const data = useGetParkingTime();
  const patchdata = usePatchParkingTime();

  useEffect(() => {
    initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data?.data]);

  const initialData = () => {
    if (!data.data?.data) {
      return;
    }
    const values = {
      ...data?.data?.data,
      id: data?.data?.data?.[0]?.id,
      minutes: data?.data?.data?.[0]?.minutes,
    };
    form.setFieldsValue(values);
  };

  const onFinish = (values: any) => {
    if (values.time < 0) {
      fireNotification({
        type: "error",
        description: t("data-greater-than-0"),
      });
    } else {
      patchdata.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success" });
        },
        onError: ({ message }: any) => {
          fireNotification({ type: "error", description: message });
        },
      });
    }
  };
  return (
    <PageContent
      breadcrumb={{
        form,
        mutate: {
          onCancel: false,
          onSaveAndContinue: false,
          onSave: onFinish,
        },
      }}
      className="p-5"
    >
      <div className="rounded-app bg-white">
        <div className="px-5 py-3 ">
          <Typography.Title className="!text-green-600" level={4}>
            {t("set-a-parking-time")}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form form={form} layout="vertical" className="!px-[25%] !py-3 ">
          <Row className="justify-center ">
            <ParkerTime className=" p-8" />
          </Row>
          <Row>
            <Col span={22}>
              <Form.Item name="id" hidden>
                <CInput />
              </Form.Item>
              <Form.Item
                name="minutes"
                label={t("set-a-parking-time")}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]*$/),
                    message: t("please-enter-an-integer", { ns: "message" }),
                  },
                ]}
              >
                <CInput.Number placeholder={t("time")} min={0} />
              </Form.Item>
            </Col>
            <Col
              span={2}
              className="!text-center !self-center !mt-3 text-[20px]"
            >
              {t("minute")}
            </Col>
          </Row>
          <Row className="pb-10">
            <Col span={1}>
              <span className="!text-red-500 text-[20px]">
                <InfoCircleOutlined />
              </span>
            </Col>
            <Col span={21}>
              <div className="text-[20px]">{t("des-1")}</div>
            </Col>
          </Row>
        </Form>
      </div>
    </PageContent>
  );
};
export default SetAParkingTime;
