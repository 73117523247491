import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { INotificationHeader, INotificationParams } from "./interface";

export const useGetNotification = (
  params: INotificationParams
): UseQueryResult<IResponse<INotificationHeader[]>, Error> => {
  return useQuery(
    ["get-notifications", params],
    async () => {
      const res = await axios.get("/notification", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetNotificationScroll = (
  params?: INotificationParams
): UseInfiniteQueryResult<IResponse<INotificationHeader[]>, Error> => {
  return useInfiniteQuery(
    ["get-notifications-scroll", params],
    async ({ pageParam }) => {
      const { wait } = params || {};
      if (!wait) return;
      const res = await axios.get("/notification", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const usePatchNotification = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/notification/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
