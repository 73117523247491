import { Dropdown, Menu } from "antd";
import { ExcelIcon } from "assets/icons/general";
import CButtonIcon from "components/button/c-button-icon";
import { ButtonThemeTypes } from "components/button/theme";
import FilePicker from "components/input/file-picker";
import { fireNotification } from "components/popup/notification";
import { usePageRoute } from "provider/page-route";
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CModalExportExcel from "../c-modal/export-excel";
import { TableExcelActionTypes } from "./action";
import { useAppLoading } from "provider/app-loading";
import { downloadBlobFile } from "tools/file";
import * as XLSX from "xlsx";
import { useQueryClient } from "@tanstack/react-query";
import CModalUploadProfile from "../c-modal/modal-profile";
import dayjs, { getClientTimeZone } from "config/dayjs";

interface TableActionExcelButtonProps {
  actions?: TableExcelActionTypes;
  resetSelectRowKey: () => void;
  buttonTheme?: ButtonThemeTypes;
}
interface IObj {
  title: string;
  onClick: (v: any[]) => void;
  hidden: boolean;
}

const TableActionExcelButton: FC<TableActionExcelButtonProps> = ({
  actions,
  buttonTheme,
}) => {
  const { t } = useTranslation();
  const [gvExport, svExport] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);
  const { pageKey } = usePageRoute();
  const { setAppLoading } = useAppLoading();
  const [value, setValue] = useState<any[]>();
  const qClient = useQueryClient();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (value) {
      ImportExcel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (!actions) return null;
  const { export: exp, import: imt, template, importProfile } = actions;
  const onExportExcel = async () => {
    if (!exp?.mutation) return;

    const timeZone = getClientTimeZone();

    setAppLoading(true);
    exp?.mutation?.mutate(
      {
        timeZone,
        ...exp?.extraValue,
      },
      {
        onSuccess: (file) => {
          fireNotification({ type: "success", menu: pageKey });
          downloadBlobFile(
            file,
            "xlsx",
            `${exp?.fileName || "export"}-${dayjs().format("DD-MMMM-YYYY")}`
          );
        },
        onError: ({ message }) => {
          fireNotification({ type: "error", menu: pageKey, message });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const toggleExport = () => {
    if (exp?.notShowModal) return onExportExcel();
    return svExport(!gvExport);
  };

  const onExport = () => {
    toggleExport();
  };

  const onOpenDialog = () => {
    ref.current?.click();
  };

  const onImportWithList = (values: any[]) => {
    if (!imt?.list) return;
    const { child, listName, key } = imt?.list || {};
    const rowData = values.reduce((prev, cur) => {
      const main: any = Object.keys(cur).reduce((p, c) => {
        if (child?.includes(c)) return p;
        return { ...p, [c]: cur[c], [listName]: cur?.[listName] || [] };
      }, {});

      const children = Object.keys(cur).reduce((p, c) => {
        if (!child.includes(c)) return p;
        return { ...p, [c]: cur[c] };
      }, {});

      if (cur[key]) {
        main[listName]?.push(children);
      } else {
        prev[prev?.length - 1]?.[listName]?.push(children);
        return prev;
      }

      Object.keys(cur).forEach((k) => {
        if (child?.includes(k)) delete cur[k];
      });

      return [...prev, main];
    }, []);

    if (imt?.typeUser === "contractor") { LoopCheckNull(rowData) } else {
      const _rowData = LoopSetDayJs(rowData)
      return setValue(_rowData);
    }
  };

  const onImport = (event: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const bstr = e.target?.result;
      const wb = XLSX.read(bstr, {
        type: "binary",
      });
      const sheetName = wb.SheetNames[0];
      const rowData = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
      if (imt?.list) return onImportWithList(rowData);
      if (imt?.typeUser === "contractor") { LoopCheckNull(rowData) } else {
        const _rowData = LoopSetDayJs(rowData)
        return setValue(_rowData);
      }

    };
    reader.readAsBinaryString(event);
  };

  const LoopSetDayJs = (values: any) => {
    const rowData = values.map((obj: any) => {
      if (obj.startDate && obj.endDate) {
        obj.startDate = dayjs(obj.startDate);
        obj.endDate = dayjs(obj.endDate);
      }
      return obj;
    });
    return rowData
  }

  const LoopCheckNull = (values: any) => {
    const rowData = values.map((item: any) => {
      let newObj = { ...item }; // Create a copy of the object
      for (let key in newObj) {
        if (newObj[key] === "") {
          delete newObj[key]; // Remove the key if the value is null
        }
      }
      if (newObj.startDate && newObj.endDate) {
        newObj.startDate = dayjs(newObj.startDate);
        newObj.endDate = dayjs(newObj.endDate);
      }
      return newObj;
    });
    return setValue(rowData);
  }

  const ImportExcel = () => {
    if (!imt?.mutation) {
      fireNotification({ type: "warning", menu: pageKey });
    }
    imt?.mutation?.mutate(value, {
      onSuccess: () => {
        fireNotification({ type: "success", menu: pageKey });
        imt?.invalidateQueries?.forEach((e) => qClient.invalidateQueries([e]));
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
          menu: pageKey,
        });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  const onTemplate = () => {
    if (!template?.mutation) {
      fireNotification({ type: "warning", menu: pageKey });
    }

    template?.mutation?.mutate(
      {},
      {
        onSuccess: (blob: any) => {
          fireNotification({ type: "success", menu: pageKey });
          downloadBlobFile(blob, "xlsx", template?.fileName);
        },
        onError: ({ massage }: any) => {
          fireNotification({
            type: "error",
            description: massage,
            menu: pageKey,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  const toggleUploadProfile = () => {
    setVisible(!visible);
  };

  const onImportProfile = () => {
    toggleUploadProfile();
  };

  const obj: IObj[] = [
    {
      title: "export-excel",
      onClick: exp?.onClick || onExport,
      hidden: !exp,
    },
    {
      title: "import-excel",
      onClick: imt?.onClick || onOpenDialog,
      hidden: !imt,
    },
    {
      title: "export-template",
      onClick: template?.onClick || onTemplate,
      hidden: !template,
    },
    {
      title: "import-profile",
      onClick: importProfile?.onClick || onImportProfile,
      hidden: !importProfile,
    },
  ];

  const getMenu = () => {
    const overlay = obj
      .filter((item) => !item.hidden)
      .map((item) => {
        return { key: item.title, label: t(item.title), onClick: item.onClick };
      }) as any[];

    return <Menu items={overlay} />;
  };

  const overlay = getMenu();

  return (
    <React.Fragment>
      <FilePicker
        resize={false}
        ref={ref}
        accepts={[".xlsx"]}
        onChange={(e: any) => onImport(e)}
      />
      <CModalUploadProfile
        visible={visible}
        onCancel={toggleUploadProfile}
        mutation={importProfile?.mutation}
        invalidateQueries={importProfile?.invalidateQueries}
        typeUser={importProfile?.typeUser}
      />
      <CModalExportExcel
        visible={gvExport}
        onCancel={toggleExport}
        fileName={exp?.fileName}
        mutation={exp?.mutation}
        extraValue={exp?.extraValue}
      />
      <Dropdown
        overlay={overlay}
        placement="bottomRight"
        overlayStyle={{ minWidth: 140 }}
      >
        <CButtonIcon
          loading={exp?.mutation?.isLoading || imt?.mutation?.isLoading}
          theme={buttonTheme}
          icon={<ExcelIcon />}
        />
      </Dropdown>
    </React.Fragment>
  );
};

export default TableActionExcelButton;
