import {
  MenuAccessIcon,
  MenuHomeIcon,
  MenuVehicleInspectionIcon,
  MenuResourceIcon,
  MenuSettingIcon,
  MenuUserIcon,
  MenuVehicleIcon,
} from "assets/icons/menu";
import { RouteObject } from "react-router-dom";
import MainLayout from "layout";
import LoginPage from "pages/login";
import { IMainMenu, IPageRoute, TSubPage } from "./interface";
import UserManagementPage from "pages/user-management";
import AccessControlPage from "pages/access-control";
import ResourceManagementPage from "pages/resource-management/index.";
import VehicleInspectionPage from "pages/vehicle-inspection";
import VehicleManagementPage from "pages/vehicle-management";
import SettingPage from "pages/setting";
import {
  USER_MANAGEMENT_MENUS,
  USER_MANAGEMENT_ROUTES,
} from "./user-management";
import {
  VEHICLE_MANAGEMENT_MENUS,
  VEHICLE_MANAGEMENT_ROUTES,
} from "./vehicle-management";
import {
  RESOURCE_MANAGEMENT_MENUS,
  RESOURCE_MANAGEMENT_ROUTES,
} from "./resource-management";
import { SETTING_MENUS, SETTING_ROUTES } from "./setting";
import { ACCESS_CONTROL_MENUS, ACCESS_CONTROL_ROUTES } from "./access-control";
import { VEHICLE_INSPECTION_MENUS, VEHICLE_INSPECTION_ROUTES } from "./vehicle-inspection";

export const PUBLIC_PATHS = [
  "/registration/guest",
  "/registration/supplier",
  "/registration/consignee",
  "/registration/contractor",
  "/login",
];

export const APP_ROUTES: IPageRoute<TSubPage>[] = [
  ...USER_MANAGEMENT_ROUTES,
  ...VEHICLE_MANAGEMENT_ROUTES,
  ...RESOURCE_MANAGEMENT_ROUTES,
  ...SETTING_ROUTES,
  ...ACCESS_CONTROL_ROUTES,
  ...VEHICLE_INSPECTION_ROUTES
];

export const APP_ROUTER: RouteObject[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/home",
        element: <></>,
      },
      {
        path: "/user-management",
        element: <></>,
      },
      // {
      //   path: "/visitor",
      //   element: <></>,
      // },
      {
        path: "/access-control",
        element: <></>,
      },
      {
        path: "/vehicle-management",
        element: <></>,
      },
      {
        path: "/resource-management",
        element: <></>,
      },
      {
        path: "/vehicle-inspection",
        element: <></>,
      },
      {
        path: "/setting",
        element: <></>,
      },
    ],
  },
];

export const MAIN_MENU: IMainMenu[] = [
  {
    key: "access-control",
    title: "access-control",
    icon: MenuAccessIcon,
    element: AccessControlPage,
    code: "010000",
    children: ACCESS_CONTROL_MENUS,
  },
  {
    key: "user-management",
    title: "user-management",
    icon: MenuUserIcon,
    element: UserManagementPage,
    code: "020000",
    children: USER_MANAGEMENT_MENUS,
  },
  {
    key: "vehicle-inspection",
    title: "vehicle-inspection",
    icon: MenuVehicleInspectionIcon,
    element: VehicleInspectionPage,
    code: "030000",
    children: VEHICLE_INSPECTION_MENUS
  },
  {
    key: "home",
    title: "home",
    icon: MenuHomeIcon,
    element: () => <></>,
    code: "040000",
  },
  {
    key: "vehicle-management",
    title: "vehicle-management",
    icon: MenuVehicleIcon,
    element: VehicleManagementPage,
    code: "050000",
    children: VEHICLE_MANAGEMENT_MENUS,
  },
  {
    key: "resource-management",
    title: "resource-management",
    icon: MenuResourceIcon,
    element: ResourceManagementPage,
    code: "060000",
    children: RESOURCE_MANAGEMENT_MENUS,
  },
 
  {
    key: "setting",
    title: "setting",
    icon: MenuSettingIcon,
    element: SettingPage,
    code: "070000",
    children: SETTING_MENUS,
  },
];
