import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "../../config/axios";

import { ISchedule, IScheduleParams } from "./interface";

// export const useGetPosition = (
//   params: IGroupTableParams
// ): UseQueryResult<IResponse<IGroupTable[]>> => {
//   return useQuery(
//     ["get-positions", params],
//     async () => {
//       const res = await axios.get("/position", { params });
//       if (res.status === 200) {
//         return res.data;
//       }
//       throwResponse(res);
//     },
//     {
//       keepPreviousData: true,
//     }
//   );
// };

export const useGetSchedule = (
  id?: number | string
): UseQueryResult<ISchedule> => {
  return useQuery(
    ["get-schedule", id],
    async () => {
      const res = await axios.get(`/schedule/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetSchedules = (
  params?: IScheduleParams
): UseQueryResult<ISchedule[]> => {
  return useQuery(
    ["get-schedules", params],
    async () => {
      const res = await axios.get("/schedule", { params });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostSchedule = (): UseMutationResult<void, Error> => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/schedule", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchScheduleId = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, ...values }: any) => {
    const res = await axios.patch(`/schedule/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteSchedule = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/schedule/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteSchedules = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/schedule/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

// export const useScrollGetPositions = (
//   params?: IGroupTableParams
// ): UseInfiniteQueryResult<IResponse<IGroupTable[]>> => {
//   return useInfiniteQuery(
//     ["positions", params],
//     async ({ pageParam }) => {
//       const res = await axios.get("/position", {
//         params: { ...params, ...pageParam },
//       });
//       if (res.status === 200) {
//         return res.data;
//       }
//       throwResponse(res);
//     },
//     { keepPreviousData: true }
//   );
// };
