import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Form, Input, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useQueryClient } from "@tanstack/react-query";
import CSelect from "components/input/c-select";
import { FormInstance } from "antd/es/form/Form";
import CInput from "components/input/c-input";
import { fireNotification } from "components/popup/notification";
import { useAppLanguage } from "provider/app-language";
import CCard from "components/display/card";
import CDrawer from "components/display/c-drawer";
import { IDepartment, IDepartmentParams } from "services/department/interface";
import {
  useDeleteDepartment,
  useDeleteDepartments,
  useGetDepartment,
  usePatchDepartmentId,
  usePostDepartment,
} from "services/department";

const Department = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { formatDate, translationObject } = useAppLanguage();
  const [params, setParams] = useState<IDepartmentParams>({
    page: 1,
    limit: 10,
  });

  const del_department = useDeleteDepartment();
  const del_departments = useDeleteDepartments();
  const get_departments = useGetDepartment(params);

  const toggle = () => {
    setVisible(!visible);
  };

  const elements: ISearchBoxElement[] = [
    {
      name: "titleName",
      label: t("department"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "createdAt",
      label: t("created-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "updatedAt",
      label: t("updated-at"),
      input: {
        type: "RangePicker",
      },
    },
    {
      name: "status",
      label: t("status"),
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              { key: true, value: t("active") },
              { key: false, value: t("in-active") },
            ],
            valueKey: "key",
            titleKey: "value",
          },
        },
      },
    },
  ];

  const columns: ColumnsType<IDepartment> = [
    {
      title: t("department"),
      render: (_, rc) => {
        const { title } = translationObject({
          object: rc,
          keys: ["title"],
        });
        return (
          <Typography.Text ellipsis={{ tooltip: title }}>
            {title}
          </Typography.Text>
        );
      },
    },
    {
      title: t("created-at"),
      dataIndex: "createdAt",
      render: (_, rc) => {
        if (!rc.createdAt) return <span>-</span>;
        const date = formatDate({ date: rc.createdAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("updated-at"),
      dataIndex: "updatedAt",
      render: (_, rc) => {
        if (!rc.updatedAt) return <span>-</span>;
        const date = formatDate({ date: rc.updatedAt, format: "normal-time" });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      width: "20%",
      render: (status) => {
        if (status === null || status === undefined) return <span>-</span>;
        return (
          <CCard.ActiveInActive status={status ? "active" : "in-active"} />
        );
      },
    },
  ];

  const onRowClick = (values: any) => {
    form.setFieldsValue(values);
    toggle();
  };

  const onSearch = (FormItem: any) => {
    setParams({
      ...params,
      ...FormItem,
      startUpdatedAt: FormItem["updatedAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endUpdatedAt: FormItem["updatedAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
      startCreatedAt: FormItem["createdAt"]?.[0]?.format("YYYY-MM-DD 00:00:00"),
      endCreatedAt: FormItem["createdAt"]?.[1]?.format("YYYY-MM-DD 23:59:59"),
    });
  };

  return (
    <PageContent className="p-5" breadcrumb>
      <CreateAndEditDrawer visible={visible} onClose={toggle} form={form} />
      <PageSearchBox onFinish={onSearch} elements={elements} />
      <CTable
        onAdd={{
          onClick: toggle,
        }}
        onRowClick={onRowClick}
        action={{
          bulkDelete: {
            mutation: del_departments,
            invalidateQueries: [
              "get-departments",
              "get-positions",
              "get-employees",
              "get-employee",
              "get-users",
              "get-user",
            ],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
        }}
        onDelete={{
          mutation: del_department,
          invalidateQueries: [
            "get-departments",
            "get-positions",
            "get-employees",
            "get-employee",
            "get-users",
            "get-user",
          ],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        query={get_departments}
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
        columns={columns}
        title={t("department-list")}
      />
    </PageContent>
  );
};

const CreateAndEditDrawer = ({
  visible,
  onClose,
  form,
}: {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
}) => {
  const { t } = useTranslation();
  const post_department = usePostDepartment();
  const patch_department = usePatchDepartmentId();
  const qClient = useQueryClient();

  const onFinish = (values: any) => {
    const { id } = values;
    if (id) {
      return patch_department.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries([
            "get-departments",
            "get-positions",
            "get-employees",
            "get-employee",
            "get-users",
            "get-user",
          ]);
          form.resetFields();
          onClose();
        },
        onError: ({ meesage }: any) => {
          fireNotification({ type: "error", description: meesage });
        },
      });
    }

    return post_department.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        qClient.invalidateQueries(["get-departments"]);
        form.resetFields();
        onClose();
      },
      onError: ({ meesage }: any) => {
        fireNotification({ type: "error", description: meesage });
      },
    });
  };

  const departmentNameTh = (
    <span>
      {t("department")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const departmentNameEn = (
    <span>
      {t("department")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );
  const descriptionTh = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const descriptionEn = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );

  const titleForm = form.getFieldValue("id")
    ? t("edit-department")
    : t("add-department");

  return (
    <CDrawer
      form={form}
      title={titleForm}
      placement="right"
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      size="large"
      visible={visible}
      width="40vw"
      onSave={onFinish}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ status: true }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="titleTh"
          label={departmentNameTh}
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("department")} />
        </Form.Item>
        <Form.Item
          name="titleEn"
          label={departmentNameEn}
          rules={[{ required: true }]}
        >
          <CInput placeholder={`${t("department")} (${t("language-en")})`} />
        </Form.Item>
        <Form.Item
          label={t("type")}
          name="departmentType"
          rules={[{ required: true }]}
          initialValue={"EMPLOYEE"}
        >
          <CSelect
            placeholder={t("type")}
            valueOptions={{
              values: [
                { value: "EMPLOYEE", title: "employee" },
                // { value: "CONTRACTOR", title: "contractor" },
              ],
              valueKey: "value",
              titleKey: "title",
              translationI18n: true,
            }}
            disabled
          />
        </Form.Item>
        <Form.Item
          label={t("status")}
          name="status"
          rules={[{ required: true }]}
        >
          <CSelect.IsActive />
        </Form.Item>
        <Form.Item name="descriptionTh" label={descriptionTh}>
          <CInput.TextArea placeholder={t("description")} rows={6} />
        </Form.Item>
        <Form.Item name="descriptionEn" label={descriptionEn}>
          <TextArea
            placeholder={`${t("description")} (${t("language-en")})`}
            rows={6}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default Department;
