import NotificationProvider from "provider/notification-app";
import { usePageRoute } from "provider/page-route";
import { useEffect } from "react";
import { ISubMenu, TSubPage } from "router/interface";
import styled from "styled-components";
import PageSidebar from "./page-sidebar";

const PageContentWrapper = styled.div`
  height: calc(100vh - 104px);
  display: flex;
`;
const PageSideWrapper = styled.div`
  height: 100%;
`;
const PageBodyWrapper = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100vw - 300px);
  @media only screen and (max-width: 1200px) {
    width: calc(100vw - 68px);
  }
`;

interface RouteContentProps {
  menus: ISubMenu<TSubPage>[];
  children: React.ReactNode;
  initialPage?: TSubPage;
}

const RouteContent: React.FC<RouteContentProps> = ({
  children,
  menus,
  initialPage,
}) => {
  const { dispatch } = usePageRoute();

  useEffect(() => {
    if (initialPage) {
      dispatch({ action: "replace", pageKey: initialPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPage]);

  return (
    <NotificationProvider>
      <PageContentWrapper>
        <PageSideWrapper>
          <PageSidebar menus={menus} />
        </PageSideWrapper>
        <PageBodyWrapper>{children}</PageBodyWrapper>
      </PageContentWrapper>
    </NotificationProvider>
  );
};

export default RouteContent;
