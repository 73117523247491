import { Checkbox, Col, Form, Row, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import CInput from "components/input/c-input";
import CRangePicker from "components/input/c-range-picker";
import CSelect from "components/input/c-select";
import CSelectJobPosition from "components/input/c-select/job-position";
import dayjs from "dayjs";
import { useUserReport } from "provider/user-report";
import { useTranslation } from "react-i18next";

const SideBarUser = () => {
  const { t } = useTranslation();
  const { columnsKey, setColumnsKey, search, setSearch } = useUserReport();

  const option = [
    {
      key: "date",
      title: t("entry-date"),
    },
    {
      key: "name",
      title: t("full-name"),
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
    },
    {
      key: "address",
      title: t("address"),
    },
    {
      key: "email",
      title: t("email"),
    },
    {
      key: "position",
      title: t("job-position"),
    },
  ];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setColumnsKey(checkedValues as string[]);
  };

  return (
    <Form layout="vertical">
      <Row>
        <Col span={24}>
          <Form.Item label={t("entry-date")} name="date">
            <CRangePicker
              onChange={(e) => {
                const sDate = e
                  ? dayjs(e?.[0]).startOf("day").toISOString()
                  : undefined;
                const eDate = e
                  ? dayjs(e?.[1])?.endOf("day").toISOString()
                  : undefined;
                setSearch({ ...search, startDate: sDate, endDate: eDate });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="name" label={t("full-name")}>
            <CInput
              placeholder={t("full-name")}
              onChange={(e) => {
                const name = e.target.value ? e.target.value : undefined;
                setSearch({ ...search, name: name });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="phoneNumber" label={t("phone-number")}>
            <CSelect
              allowClear
              placeholder={t("phone-number")}
              mode="tags"
              maxTagCount="responsive"
              onChange={(e) => {
                setSearch({ ...search, phoneNumber: e });
              }}
              open={false}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="address" label={t("address")}>
            <CInput
              placeholder={t("address")}
              onChange={(e) => {
                const address = e.target.value ? e.target.value : undefined;
                setSearch({ ...search, address: address });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="email" label={t("email")}>
            <CSelect
              allowClear
              placeholder={t("email")}
              mode="tags"
              maxTagCount="responsive"
              onChange={(e) => {
                setSearch({ ...search, email: e });
              }}
              open={false}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="position" label={t("job-position")}>
            <CSelectJobPosition
              mode="tags"
              maxTagCount="responsive"
              onChange={(e) => {
                setSearch({ ...search, position: e });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} className="mb-4">
          <Typography.Title level={5}>
            {t("required-information")}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={onChange}
            defaultValue={columnsKey}
          >
            <Row gutter={[0, 16]}>
              {option?.map((item, index) => {
                return (
                  <Col span={24} key={index}>
                    <Checkbox value={item?.key}>{t(item?.title)}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default SideBarUser;
