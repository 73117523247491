import { useMutation, UseMutationResult } from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IPlayback } from "provider/all-video/interface";

export const useRequestVideoUrl = (): UseMutationResult<
  { url: string },
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/encode-device/get-live-stream-url`, data);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const useRequestVideoHistoryUrl = (): UseMutationResult<
  IPlayback,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/encode-device/search-video-history`, data);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

// export const useOpenStreamVideo = (): UseMutationResult<{
//   url: string;
//   unixTimestamp: string;
// }> => {
//   return useMutation(async (data) => {
//     const res = await digitalInstance.post("/stream-video/get-stream-url", data);
//     if (res.status === 200) {
//       return res.data.data;
//     }
//     throwResponse(res);
//   });
// };

// export const useCloseStreamVideo = (): UseMutationResult => {
//   return useMutation(async (data) => {
//     const res = await digitalInstance.post("/stream-video/close-stream", data);
//     if (res.status === 200) {
//       return res.data;
//     }
//     throwResponse(res);
//   });
// };

// export const POST_STREAM_VIDEO_CALLBACK = (): UseMutationResult => {
//   return useMutation(async (data) => {
//     const res = await axios.post("/stream-video/callback", data);
//     if (res.status === 200) {
//       return res.data;
//     }
//     throwResponse(res);
//   });
// };
