import { ComponentType, createContext, useContext, useState } from "react";
import { IReportVehicleInspectionParams } from "services/vehicle-condition/interface";
import { IReportVehicleInspectionContext } from "./interface";

const ReportVehicleInspectionContext =
  createContext<IReportVehicleInspectionContext>(
    {} as IReportVehicleInspectionContext
  );

const param = { page: 1, limit: 10 };
export const columnsKeyDefult = [
  "date",
  "licensePlate",
  "name",
  "companyName",
  "userType",
  "phoneNumber",
];

const withreportVehicleInspection = (Component: ComponentType) => {
  return () => {
    const [params, setParams] = useState<IReportVehicleInspectionParams>({
      ...param,
    });
    const [columnsKey, setColumnsKey] = useState<string[]>(columnsKeyDefult);
    return (
      <ReportVehicleInspectionContext.Provider
        value={{ params, setParams, columnsKey, setColumnsKey }}
      >
        <Component />
      </ReportVehicleInspectionContext.Provider>
    );
  };
};
export const useReportVehicleInspection = () =>
  useContext(ReportVehicleInspectionContext);
export default withreportVehicleInspection;
