import { Form } from "antd";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import { useAppRoleMenu } from "provider/app-role-menu";
import { menuDataNoti } from "provider/notification-header";
import { useRole } from "provider/role";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IMainMenu, ISubMenu, TSubPage } from "router/interface";
import { useGetMenus } from "services/menu";
import { usePatchRole, usePostRole } from "services/role";

interface RoleCreateAndEditDrawerProps {
  visible: boolean;
  onClose: () => void;
}
interface IDataSource {
  key: string;
  title: string;
  children?: IDataSource[];
}
const RoleCreateAndEditDrawer: FC<RoleCreateAndEditDrawerProps> = ({
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);

  const { selectedRole, setSelectedRole } = useRole();
  const { baseMenus } = useAppRoleMenu();

  const { data } = useGetMenus();
  const post_role = usePostRole();
  const patch_role = usePatchRole();

  useEffect(() => {
    const next = getDataSource(baseMenus);
    if (next) {
      setDataSource(next);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseMenus, i18n.language]);

  useEffect(() => {
    initOnEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  const initOnEdit = () => {
    if (!selectedRole) {
      return form.resetFields();
    }
    const roleMenus = selectedRole.roleMenus
      .filter((e) => e.isActive)
      .map((item) => {
        return item.menu?.code;
      });

    const notification = (selectedRole?.notification || []).filter((item) => {
      return menuDataNoti.find((e) => e.keyNoti === item);
    });

    form.setFieldsValue({
      ...selectedRole,
      roleMenus,
      notification: notification || [],
    });
  };

  const getDataSource = (
    menus?: IMainMenu[] | ISubMenu<TSubPage>[]
  ): IDataSource[] | undefined => {
    if (!menus || !menus.length) return;
    const next = menus.map((item) => {
      const children = getDataSource(item.children);
      return { key: item.code, title: t(item.title, { ns: "menu" }), children };
    });
    return next;
  };

  const onBeforeSubmit = (values: any) => {
    if (!data) return values;
    const { roleMenus, ...rest } = values;
    if (selectedRole) {
      const menus = selectedRole.roleMenus.map((item) => {
        const find = roleMenus.find((e: string) => e === item.menu?.code);

        return { roleMenu: item.id, isActive: !!find || false };
      });
      return { ...rest, roleMenus: menus };
    }
    const menus = data.map((item) => {
      const find = roleMenus.find((e: string) => e === item.code);
      return { menu: item.id, isActive: !!find || false };
    });
    return { ...rest, roleMenus: menus };
  };

  const title = selectedRole ? "setting.role-edit" : "setting.role-add";

  return (
    <CDrawer
      form={form}
      title={t(title, { ns: "menu" })}
      width="40vw"
      visible={visible}
      onClose={onClose}
      mutation={{
        mutate: selectedRole ? patch_role : post_role,
        invalidateQueries: ["get-roles", "get-role", "get-me"],
        onBeforeSubmit: onBeforeSubmit,
        onAfterSubmit: () => {
          setSelectedRole(undefined);
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ roleMenus: [], isActive: true }}
      >
        <Form.Item name="id" hidden>
          <CInput />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="titleTh"
          label={`${t("role-name")} (TH)`}
        >
          <CInput placeholder={`${t("role-name")} (TH)`} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="titleEn"
          label={`${t("role-name")} (EN)`}
        >
          <CInput placeholder={`${t("role-name")} (EN)`} />
        </Form.Item>
        <Form.Item
          name="isActive"
          label={t("status")}
          rules={[{ required: true }]}
        >
          <CSelect.IsActive />
        </Form.Item>
        <Form.Item
          name="notification"
          label={t("setting.notification", { ns: "menu" })}
        >
          <CSelect.NotificationType mode="multiple" showArrow />
        </Form.Item>
        <Form.Item label={t("list")} name="roleMenus">
          <CTable.Select
            checkStrictly={false}
            dataSource={dataSource}
            rowKey="key"
            columns={[{ title: t("menu"), dataIndex: "title" }]}
            pagination={false}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default RoleCreateAndEditDrawer;
