import { Tabs } from "antd";
import styled from "styled-components";
import tw from "twin.macro";

const AppNavigatorBar = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0px;
  }
  .ant-tabs-nav-wrap {
    background-color: #48b074;
    padding-left: 64px;
    padding-right: 64px;
  }
  //children
  &&& {
    .ant-tabs-tab-remove {
      margin-left: 0px;
      margin-right: 5px;
      font-size: 18px;
    }
    .ant-tabs-tab {
      // clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
      // ${tw`rounded-app`}
      font-size: 20px !important;
      padding-left: 25px !important;
      padding-right: 30px !important;
      width: 260px;
      border-top-color: #e9e9e9;
      border-bottom-color: #e9e9e9;
      background-color: #e9e9e9;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      .ant-tabs-tab-btn {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: black;
      }
      button {
        position: absolute;
        right: 20px;
      }
    }
  }

  .ant-tabs-tab-btn {
    user-select: none;
  }

  &&& {
    .ant-tabs-tab-active {
      border-top-color: #ffffff;
      border-bottom-color: #ffffff;
      background-color: #ffffff;
    }
    .ant-tabs-tab:not(:first-child) {
      margin-left: -15px;
    }
  }
`;

export default AppNavigatorBar;
