import { Col, Row, Typography } from "antd";
import Container from "components/display/container";
import PageContent from "components/layout/page-content";
import DisplayDeviceInHeader from "components/menu/vehicle-management/display-device-in";
import DisplayDeviceOutHeader from "components/menu/vehicle-management/display-device-out";
import withDisplayScreenProvider from "provider/display-screen";
import withVehicleSocketProvider from "provider/vehicle-socket";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import DepartureDisplayScreen from "./departure-display/departure-display-screen";
import EntranceDisplayScreen from "./entrance-display/entrance-display-screen";

const Card = styled.div`
  ${tw`w-[180px] h-[180px] bg-[#EAF7EC] text-[#438860] text-[62px] font-bold`}
`;
const Text = styled.div`
  ${tw`!flex !items-center justify-center pt-3 text-[24px]`}
`;

const DisplayScreenPage = () => {
  const { t } = useTranslation();
  const [visibleIn, setVisibleIn] = useState<boolean>(false);
  const [visibleOut, setVisibleOut] = useState<boolean>(false);

  const className = "!flex !items-center justify-center";
  return (
    <PageContent breadcrumb>
      <DisplayDeviceInHeader
        visible={visibleIn}
        onClose={() => {
          setVisibleIn(!visibleIn);
        }}
      />
      <DisplayDeviceOutHeader
        visible={visibleOut}
        onClose={() => {
          setVisibleOut(!visibleOut);
        }}
      />
      <EntranceDisplayScreen />
      <DepartureDisplayScreen />
      <div className="center" style={{ height: "calc(100vh - 178px)" }}>
        <Container className="center" height="auto">
          <div className="py-10">
            <Row gutter={[28, 28]}>
              <Col span={24} className="pb-5">
                <Typography.Title level={2} className={className}>
                  {t("vehicle-display-screen")}
                </Typography.Title>
              </Col>
              <Col span={6}></Col>
              <Col span={6}>
                <div
                  className={`cursor-pointer ${className}`}
                  onClick={() => {
                    // setVisibleEntrance?.(!visibleEntrance);
                    setVisibleIn(!visibleIn);
                  }}
                >
                  <Card className="center rounded-app">{t("IN")}</Card>
                </div>
                <Text>{t("entrance")}</Text>
              </Col>
              <Col span={6}>
                <div
                  className={`cursor-pointer ${className}`}
                  onClick={() => {
                    // setVisibleDeparture?.(!visibleDeparture);
                    setVisibleOut(!visibleOut);
                  }}
                >
                  <Card className="center  rounded-app">{t("OUT")}</Card>
                </div>
                <Text>{t("departure")}</Text>
              </Col>
              <Col span={6}></Col>
            </Row>
          </div>
        </Container>
      </div>
    </PageContent>
  );
};

export default withVehicleSocketProvider(
  withDisplayScreenProvider(DisplayScreenPage)
);
