import { TableColumnsType, Typography } from "antd";
import CTable from "components/display/c-table";
import { ISearchBoxElement } from "components/layout/page-search-box";
import dayjs from "config/dayjs";
import { useAppLanguage } from "provider/app-language";
import { useSprayCheckProvider } from "provider/inspection-spray-check";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportExcelSprayEmployee,
  useGetCheckSprays,
} from "services/check-the-spray";
import {
  ICheckSpray,
  ICheckSprayParams,
} from "services/check-the-spray/interface";

const CheckSprayEmployeeContent = () => {
  const { t } = useTranslation();
  const { userType, selectDate } = useSprayCheckProvider();

  const SDate = selectDate
    ? dayjs(selectDate).startOf("day").toISOString()
    : undefined;
  const EDate = selectDate
    ? dayjs(selectDate).endOf("day").toISOString()
    : undefined;

  const [params, setParams] = useState<ICheckSprayParams>({});

  const data_check_spray = useGetCheckSprays({
    ...params,
    startDate: SDate,
    endDate: EDate,
    userType: userType?.userType,
  });

  const export_employee = useExportExcelSprayEmployee();
  const { formatDate } = useAppLanguage();

  const elements: ISearchBoxElement[] = [
    {
      label: t("license-plate-number"),
      name: "licensePlate",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("full-name"),
      name: "name",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("phone-number"),
      name: "phoneNumber",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("status"),
      name: "liquidSpray",
      input: {
        type: "Select",
        options: {
          valueOptions: {
            values: [
              {
                key: true,
                label: t("active-spray"),
              },
              {
                key: false,
                label: t("no-activ-spray"),
              },
            ],
            valueKey: "key",
            titleKey: "label",
          },
        },
      },
    },
  ];

  const columns: TableColumnsType<ICheckSpray> = [
    {
      title: t("transaction-date"),
      render: (_, rc) => {
        const date = formatDate({
          date: rc?.createdAt,
          format: "normal-time",
        });
        return <Typography.Text>{date}</Typography.Text>;
      },
    },
    {
      title: t("license-plate-number"),
      render: (_, rc) => {
        return (
          <Typography.Text>{rc?.vehicle?.licensePlate || "-"}</Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.employee?.firstName || "";
        const lastName = rc?.employee?.lastName || "";
        if (!firstName && !lastName) return "-";
        return (
          <Typography.Text ellipsis>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        const phoneNumber = rc?.employee?.phoneNumber || "-";
        return <Typography.Text ellipsis>{phoneNumber}</Typography.Text>;
      },
    },
    {
      title: t("status"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        const supplierStatus = rc?.sprayStatus;
        const receiverStatus = rc?.sprayStatus;
        return (
          <>
            {(supplierStatus || receiverStatus) === true ? (
              <div
                className="!p-1 shadow-sm text-center rounded-app"
                style={{
                  backgroundColor: "#EAF7EC",
                  border: "2px solid #47B174",
                }}
              >
                <Typography.Text ellipsis style={{ color: "#47B174" }}>
                  {t("active-spray")}
                </Typography.Text>
              </div>
            ) : (
              <div
                className="!p-1 shadow-sm text-center rounded-app"
                style={{
                  backgroundColor: "#FFF6E0",
                  border: "2px solid #E0B33C",
                }}
              >
                <Typography.Text ellipsis style={{ color: "#E0B33C" }}>
                  {t("no-activ-spray")}
                </Typography.Text>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    delete values.date;

    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  return (
    <CTable
      wrapperClassName="!mt-0"
      title={t("car-of-company")}
      action={{
        searchBox: { elements: elements, onFinish: onSearch },
        excels: {
          export: {
            mutation: export_employee,
          },
        },
      }}
      columns={columns}
      shadow={false}
      query={data_check_spray}
      pagination={{
        onChange: (page, limit) => {
          setParams({ ...params, page, limit });
        },
      }}
      selectAble={false}
    />
  );
};

export default CheckSprayEmployeeContent;
