import { useQueryClient } from "@tanstack/react-query";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ICheckSpraySocket, ICheckSpraySocketContext } from "./interface";
import io from "socket.io-client";
import { checkSprayUrl } from "config/axios";

const CheckSprayContext = createContext<ICheckSpraySocketContext>({});

const CheckSpraySocketProvider = ({ children }: { children: ReactNode }) => {
  const [sprayCheck, setSprayCheck] = useState<ICheckSpraySocket | undefined>();
  const qClient = useQueryClient();

  useEffect(() => {
    const socket = io(checkSprayUrl.replace("http", "ws"), {
      transports: ["websocket"],
    });

    socket.on(`check-spray`, (v: ICheckSpraySocket) => {
      setSprayCheck(v);
      console.log("check-spray", v);
      qClient.invalidateQueries(["get-check-spray"]);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprayCheck]);

  useEffect(() => {
    setSprayCheck(undefined);
  }, [sprayCheck]);

  return (
    <CheckSprayContext.Provider value={{ sprayCheck }}>
      {children}
    </CheckSprayContext.Provider>
  );
};

export const useCheckSpraySocket = () => useContext(CheckSprayContext);
export default CheckSpraySocketProvider;
