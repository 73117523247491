import { Form, FormInstance } from "antd";
import { ColumnsType } from "antd/lib/table";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import DeviceStatusCard from "components/display/card/device-status-card";
import CInput from "components/input/c-input";
import CSwitch from "components/input/c-switch";
import { useCameraGroup } from "provider/all-video";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePatchCameraGroup, usePostCameraGroup } from "services/camera-group";
import { useGetEncodeDevices } from "services/encode-device";
import { IEncodeDeviceParams } from "services/encode-device/interface";

const CameraDrawer = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation();
  const { visible, toggle } = useCameraGroup();
  const [params, setParams] = useState<IEncodeDeviceParams>();

  const post_camera_group = usePostCameraGroup();
  const patch_camera_group = usePatchCameraGroup();
  const get_encode_device = useGetEncodeDevices(params);

  const columns: ColumnsType = [
    {
      title: t("equipment-name"),
      dataIndex: "devName",
      width: "15%",
      ellipsis: true,
    },
    {
      title: t("internet-protocol-address"),
      dataIndex: "devIp",
    },
    {
      title: t("port"),
      dataIndex: "devPort",
    },
    {
      title: t("serial-number"),
      dataIndex: "devCode",
      ellipsis: true,
    },
    {
      title: t("mac-address"),
      dataIndex: "macAddress",
    },
    {
      title: t("network-status"),
      dataIndex: "status",
      align: "center",
      width: "15%",
      render: (text) => {
        return (
          <div className="!flex !justify-center">
            <DeviceStatusCard status={text?.toLocaleUpperCase() || "OFFLINE"} />
          </div>
        );
      },
    },
  ];

  const onBeforeSubmit = (values: any) => {
    delete values.search;
    delete values.editCamera;
    return values;
  };

  const id = form.getFieldValue("id");
  const title = form.getFieldValue("title");
  const editCamera = form.getFieldValue("editCamera");
  const getTitle = () => {
    if (editCamera) return title;
    return t(`${id ? "edit" : "add"}-group-name`);
  };

  return (
    <CDrawer
      title={getTitle()}
      visible={visible}
      placement={"right"}
      form={form}
      onClose={toggle}
      mutation={{
        mutate: id ? patch_camera_group : post_camera_group,
        invalidateQueries: ["get-camera-groups", "get-camera-groups-scroll"],
        onBeforeSubmit: onBeforeSubmit,
      }}
      width={editCamera ? "60vw" : "40vw"}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="id" hidden>
          <CInput />
        </Form.Item>
        <Form.Item name="editCamera" hidden>
          <CSwitch />
        </Form.Item>
        <Form.Item
          name="title"
          hidden={editCamera}
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("group-name")} />
        </Form.Item>
        <Form.Item name="search" hidden={!editCamera} label={t("search")}>
          <CInput.Debounce
            value={params?.devName}
            onChange={(e) => {
              setParams({ ...params, devName: e });
            }}
            placeholder={t("camera-name")}
          />
        </Form.Item>
        <Form.Item name="device" hidden={!editCamera}>
          <CTable.Select
            rowKey="id"
            columns={columns}
            dataSource={get_encode_device.data?.data || []}
            pagination={{
              onChange: (page, limit) => {
                setParams({ ...params, page, limit });
              },
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default CameraDrawer;
