import dayjs from "dayjs";
import { ComponentType, createContext, useContext, useState } from "react";
import { IScheduleGroup } from "services/schedule-group/interface";
import {
  ISettingSchduleContext,
  ISettingSchduleCurrentTime,
  SettingScheduleTypes,
} from "./interface";

const Context = createContext<ISettingSchduleContext>(
  {} as ISettingSchduleContext
);

const withSettingScheduleProvider = (Component: ComponentType) => {
  return () => {
    const [selectedGroupTable, setSelectedGroupTable] = useState<IScheduleGroup>();
    const [type, setType] = useState<SettingScheduleTypes>("month");

    const [currentTime, setCurrentTime] = useState<ISettingSchduleCurrentTime>({
      day: dayjs(),
      month: dayjs(),
      year: dayjs(),
    });

    const [action, setAction] = useState<"add" | "edit">("add");

    return (
      <Context.Provider
        value={{
          type,
          setType,
          selectedGroupTable,
          setSelectedGroupTable,
          currentTime,
          setCurrentTime,
          action,
          setAction,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useSettingSchedule = () => useContext(Context);
export default withSettingScheduleProvider;
