import { Col, Row, Typography } from "antd";
import CImage from "components/display/c-image";
import CSpace from "components/display/c-space";
import Container from "components/display/container";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Icon from "@ant-design/icons";
import tw from "twin.macro";
import { FaceScanningIcon } from "assets/icons/general";

const TimeStampWrapper = styled.div`
  ${tw`absolute py-1 px-2 z-10 bg-gray-400 rounded-app`};
  top: 5px;
  right: 5px;
  font-size: 12px;
  color: white;
`;

const ScannerResultWrapper = styled.div`
  ${tw`py-1 absolute z-10 rounded-app bg-primary`};
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 5%;
  width: 80px;
  text-align: center;
  color: white;
`;

const ResultDescriptionWrapper = styled.div<{ size: "small" | "default" }>`
  ${tw`absolute py-2 px-2 z-10 rounded-app`};
  background: rgb(47, 154, 93);
  background: linear-gradient(
    180deg,
    rgba(47, 154, 93, 0) 0%,
    rgba(3, 8, 18, 1) 100%
  );
  color: white;
  text-align: center;
  font-size: ${({ size }) => (size === "small" ? "10px" : "14px")};
  bottom: ${({ size }) => (size === "small" ? "0px" : "8px")};
  left: ${({ size }) => (size === "small" ? "6px" : "16px")};
  right: ${({ size }) => (size === "small" ? "6px" : "16px")};
`;

const FailedIcon = styled(Icon)`
  font-size: 60px;
  svg * {
    ${tw`fill-colors-primary-dark`};
  }
`;

const RealtimeFaceMonitoringContent = () => {
  return (
    <Row gutter={12}>
      <Col span={16}>
        <FaceDescription />
      </Col>
      <Col span={8} style={{ overflow: "auto", height: "calc(100vh - 144px)" }}>
        {[1, 2, 3, 4, 5].map((item) => {
          return <FaceHistory key={item} />;
        })}
      </Col>
    </Row>
  );
};

const FaceHistory = () => {
  const { t } = useTranslation();
  return (
    <Container height={212} padding="p-3" className="mb-3">
      <Row gutter={12}>
        <Col span={12}>
          <ResultDescriptionWrapper size="small">
            Sample A
          </ResultDescriptionWrapper>
          <CImage
            wrapperStyle={{ height: 180 }}
            src="https://dictionary.cambridge.org/images/thumb/face_noun_002_13169.jpg?version=5.0.252"
          />
        </Col>
        <Col span={12} hidden>
          <ResultDescriptionWrapper size="small">80%</ResultDescriptionWrapper>
          <CImage
            wrapperStyle={{ height: 180 }}
            src="https://dictionary.cambridge.org/images/thumb/face_noun_002_13169.jpg?version=5.0.252"
          />
        </Col>
        <Col span={12}>
          <ResultDescriptionWrapper size="small">
            {t("failed")}
          </ResultDescriptionWrapper>
          <div className="h-full bg-gray-200 center">
            <FailedIcon component={FaceScanningIcon} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const FaceDescription = () => {
  return (
    <Container height="calc(100vh - 144px)">
      <Row className="h-full" gutter={[0, 12]}>
        <Col
          span={24}
          style={{ height: "calc(40% - 12px)" }}
          className="border border-primary rounded-app"
        >
          <TimeStampWrapper>01-01-2565 08:10:00</TimeStampWrapper>
          <CImage
            wrapperStyle={{ height: "100%" }}
            src="https://image.shutterstock.com/image-photo/futuristic-technological-scanning-face-beautiful-260nw-717476011.jpg"
          />
        </Col>
        <Col span={24} style={{ height: "calc(40% - 12px)" }}>
          <ScannerResultWrapper>ถูกต้อง</ScannerResultWrapper>
          <Row gutter={12} className="h-full">
            <Col span={12}>
              <ResultDescriptionWrapper size="default">
                Suwat Yuemkrathok
              </ResultDescriptionWrapper>
              <CImage
                wrapperClassName="border-8"
                wrapperStyle={{ height: "100%" }}
                src="https://www.bioid.com/wp-content/uploads/face-database-bioid.jpg"
              />
            </Col>
            <Col span={12}>
              <ResultDescriptionWrapper size="default">
                90%
              </ResultDescriptionWrapper>
              <CImage
                wrapperClassName="border-8"
                wrapperStyle={{ height: "100%" }}
                src="https://www.bioid.com/wp-content/uploads/face-database-bioid.jpg"
              />
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{ height: "calc(20% - 12px)" }}
          className="center"
        >
          <TextDescriptionField />
        </Col>
      </Row>
    </Container>
  );
};

const TextDescriptionField = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[0, 12]} align="middle">
      <Col span={8}>
        <CSpace>
          <Typography.Text
            ellipsis={{ tooltip: t("full-name") }}
            type="secondary"
          >
            {t("full-name")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "Suwat Yuemkrathok" }}
            className="!text-primary-dark"
          >
            Suwat Yuemkrathok
          </Typography.Text>
        </CSpace>
      </Col>
      <Col span={8}>
        <CSpace>
          <Typography.Text
            ellipsis={{ tooltip: t("phone-number") }}
            type="secondary"
          >
            {t("phone-number")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "0999999999" }}
            className="!text-primary-dark"
          >
            0999999999
          </Typography.Text>
        </CSpace>
      </Col>
      <Col span={8}>
        <CSpace>
          <Typography.Text
            ellipsis={{ tooltip: t("card-number") }}
            type="secondary"
          >
            {t("card-number")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "000000001" }}
            className="!text-primary-dark"
          >
            000000001
          </Typography.Text>
        </CSpace>
      </Col>

      <Col span={8}>
        <CSpace>
          <Typography.Text ellipsis={{ tooltip: t("group") }} type="secondary">
            {t("group")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "Face1" }}
            className="!text-primary-dark"
          >
            Face1
          </Typography.Text>
        </CSpace>
      </Col>
      <Col span={8}>
        <CSpace>
          <Typography.Text ellipsis={{ tooltip: t("gender") }} type="secondary">
            {t("gender")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "MALE" }}
            className="!text-primary-dark"
          >
            MALE
          </Typography.Text>
        </CSpace>
      </Col>
      <Col span={8}>
        <CSpace>
          <Typography.Text
            ellipsis={{ tooltip: t("wear-glasses") }}
            type="secondary"
          >
            {t("wear-glasses")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "000000001" }}
            className="!text-primary-dark"
          >
            NO
          </Typography.Text>
        </CSpace>
      </Col>

      <Col span={8}>
        <CSpace>
          <Typography.Text
            ellipsis={{ tooltip: t("wear-mask") }}
            type="secondary"
          >
            {t("wear-mask")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "NO" }}
            className="!text-primary-dark"
          >
            NO
          </Typography.Text>
        </CSpace>
      </Col>
      <Col span={8}>
        <CSpace>
          <Typography.Text
            ellipsis={{ tooltip: t("expired") }}
            type="secondary"
          >
            {t("expired")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "NO" }}
            className="!text-primary-dark"
          >
            NO
          </Typography.Text>
        </CSpace>
      </Col>
      <Col span={8}>
        <CSpace>
          <Typography.Text
            ellipsis={{ tooltip: t("wear-hat") }}
            type="secondary"
          >
            {t("wear-hat")}:
          </Typography.Text>
          <Typography.Text
            ellipsis={{ tooltip: "NO" }}
            className="!text-primary-dark"
          >
            NO
          </Typography.Text>
        </CSpace>
      </Col>
    </Row>
  );
};

export default RealtimeFaceMonitoringContent;
