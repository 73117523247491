import { FC } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: red;
  z-index: 1;
  opacity: 0.5;
  position: fixed;
  pointer-events: none;
`;

interface FloorEditMapPointerProps {
  hidden?: boolean;
  top?: number;
  left?: number;
}

const FloorEditMapPointer: FC<FloorEditMapPointerProps> = ({
  hidden,
  left,
  top,
}) => {
  return <Wrapper style={{ left, top }} hidden={hidden} />;
};

export default FloorEditMapPointer;
