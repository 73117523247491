import { PieChartFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import {
  IconUseManagementHome,
  IconVehicleInspectionHome,
  IconVehicleManagementHome,
} from "assets/icons/general";
import CButtonIcon from "components/button/c-button-icon";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AccessControlHomePage from "./access-control";
import UserManagementHomePage from "./user-management";
import VehicleInspectionHomePage from "./vehicle-inspection";
import VehicleManagementHomePage from "./vehicle-management";
import tw from "twin.macro";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

const TabBarStlye = styled.div`
  background-color: white;
  height: calc(100vh - 120px);
  width: 90px;
  border-radius: 0px 26px 26px 0px;
  box-shadow: 0px 0px 6px #00000014;
  opacity: 1;
`;

const ButtonIcon = styled(CButtonIcon)`
  ${tw`rounded-app`};
  height: 60px;
  width: 60px;
  &:hover {
    background-color: #438860;
    svg * {
      fill: #fff;
    }
  }
  &:active,
  &:focus {
    background-color: #438860;
    svg * {
      fill: #fff;
    }
  }
`;

type menuType =
  | "vehicle-management"
  | "access-control"
  | "vehicle-inspection"
  | "user-management";

const contentMenu = {
  "vehicle-management": <VehicleManagementHomePage />,
  "access-control": <AccessControlHomePage />,
  "vehicle-inspection": <VehicleInspectionHomePage />,
  "user-management": <UserManagementHomePage />,
};

const HomePage = () => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<menuType>("user-management");
  const [params] = useSearchParams()

  useEffect(() => {
    const type = params.get('type') as menuType
    setActiveMenu(type || 'user-management')
  }, [params]);

  return (
    <React.Fragment>
      <Row style={{ height: "calc(100vh - 64px)" }}>
        <Col span={2}>
          <Row align="middle" style={{ height: "calc(100vh - 64px)" }}>
            <TabBarStlye className="p-5 flex items-center grid justify-items-center">
              <Row justify="center" align="middle" gutter={[16, 16]}>
                <ButtonIcon
                  icon={<IconUseManagementHome />}
                  onClick={() => {
                    navigate({
                      pathname: "/",
                      search: createSearchParams({
                        type: "user-management",
                      }).toString(),
                    });
                    setActiveMenu("user-management");
                  }}
                />
                <ButtonIcon
                  icon={<PieChartFilled style={{ fontSize: "30px" }} />}
                  onClick={() => {
                    navigate({
                      pathname: "/",
                      search: createSearchParams({
                        type: "access-control",
                      }).toString(),
                    });
                    setActiveMenu("access-control");
                  }}
                />
                <ButtonIcon
                  icon={<IconVehicleManagementHome />}
                  onClick={() => {
                    navigate({
                      pathname: "/",
                      search: createSearchParams({
                        type: "vehicle-management",
                      }).toString(),
                    });
                    setActiveMenu("vehicle-management");
                  }}
                />
                <ButtonIcon
                  icon={<IconVehicleInspectionHome />}
                  onClick={() => {
                    navigate({
                      pathname: "/",
                      search: createSearchParams({
                        type: "vehicle-inspection",
                      }).toString(),
                    });
                    setActiveMenu("vehicle-inspection");
                  }}
                />

              </Row>
            </TabBarStlye>
          </Row>
        </Col>
        <Col
          span={22}
          className="p-5"
          style={{
            height: "calc(100vh - 90px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {contentMenu[activeMenu]}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default HomePage;
