import { UseMutationResult } from "@tanstack/react-query";
import { Form, ModalProps } from "antd";
import CRangePicker from "components/input/c-range-picker";
import { fireNotification } from "components/popup/notification";
import dayjs, { getClientTimeZone } from "config/dayjs";

import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { downloadBlobFile } from "tools/file";
import CModal from ".";
import CModalFooter from "./modal-footer";

export interface CModalExportExcelProps extends ModalProps {
  mutation?: UseMutationResult<Blob, Error>;
  fileName?: string;
  extraValue?: Record<string, any>;
}

const CModalExportExcel: FC<CModalExportExcelProps> = ({
  mutation,
  fileName,
  extraValue,
  ...props
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { setAppLoading } = useAppLoading();
  const { pageKey } = usePageRoute();

  const onFinish = async (e: React.MouseEvent<HTMLElement>) => {
    if (!mutation) return;

    const values = await form.validateFields();
    const [startDate, endDate] = values.date as [dayjs.Dayjs, dayjs.Dayjs];
    const timeZone = getClientTimeZone();

    setAppLoading(true);
    mutation?.mutate(
      {
        startDate: startDate.startOf("d"),
        endDate: endDate.endOf("d"),
        timeZone,
        ...extraValue,
      },
      {
        onSuccess: (file) => {
          props?.onCancel?.(e);
          fireNotification({ type: "success", menu: pageKey });
          downloadBlobFile(
            file,
            "xlsx",
            `${fileName || "export"}-${dayjs().format("DD-MMMM-YYYY")}`
          );
        },
        onError: ({ message }) => {
          fireNotification({ type: "error", menu: pageKey, message });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  return (
    <CModal
      forceRender
      closable
      title={t("export-excel")}
      footer={
        <CModalFooter
          submit={{ onClick: onFinish, children: t("export") }}
          cancel={{ onClick: props.onCancel }}
        />
      }
      {...props}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          rules={[{ required: true }]}
          name="date"
          label={t("time-period")}
        >
          <CRangePicker />
        </Form.Item>
      </Form>
    </CModal>
  );
};

export default CModalExportExcel;
