import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import BlockColor from "components/display/card/block-color";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteReceiver,
  useDeleteReceivers,
  useExportTemplateReceivers,
  useGetReceivers,
  useImportExcelReceivers,
  useImportProfileReceivers,
} from "services/receiver";
import { IReceiver, IReceiverParams } from "services/receiver/interface";

const UserConsigneePage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IReceiverParams>();
  const { formatDate } = useAppLanguage();

  const get_Receivers = useGetReceivers(params);
  const del_Receiver = useDeleteReceiver();
  const del_Receivers = useDeleteReceivers();
  const export_template = useExportTemplateReceivers();
  const import_excel = useImportExcelReceivers();
  const import_profile = useImportProfileReceivers();

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("item-creation-date"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
    {
      name: "pickupDate",
      label: t("pick-up-date"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startPickupDate",
          endKey: "endPickupDate",
        },
      },
    },
    {
      name: "name",
      label: t("driver-name"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "licensePlateNumber",
      label: t("license-plate"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "companyName",
      label: t("transport-name"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "productGroup",
      label: t("product-group"),
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      name: "registerType",
      label: t("formation"),
      input: {
        type: "SelectRegisterType",
      },
    },
  ];

  const columns: ColumnsType<IReceiver> = [
    {
      title: t("profile-picture"),
      align: "center",
      width: 120,
      render: (_, rc) => {
        return <CAvatar src={rc?.profile?.url} />;
      },
    },
    {
      title: t("pick-up-date"),
      align: "center",
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        if (!rc?.receiverDetail?.pickupDate) return <span>-</span>;
        const date = formatDate({
          date: rc?.receiverDetail?.pickupDate,
          format: "normal",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("driver-name"),
      align: "center",
      width: 200,
      render: (_, rc) => {
        if (!rc?.firstName && !rc?.lastName) return <div>-</div>;
        const text = `${rc?.firstName} ${rc?.lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        );
      },
    },
    {
      title: t("license-plate"),
      align: "center",
      width: 200,
      render: (_, rc) => {
        const licensePlateNumber =
          rc?.receiverDetail?.licensePlateNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: licensePlateNumber }}>
            {licensePlateNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      align: "center",
      ellipsis: { showTitle: false },
      width: 200,
      render: (_, rc) => {
        if (!rc?.phoneNumber) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.phoneNumber }}>
            {rc?.phoneNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: t("transport-name"),
      ellipsis: { showTitle: false },
      width: 200,
      align: "center",
      render: (_, rc) => {
        if (!rc?.companyName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.companyName }}>
            {rc?.companyName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("product-group"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        if (!rc?.receiverDetail?.productGroups) return <span>-</span>;
        return (
          <Typography.Text
            ellipsis={{ tooltip: rc?.receiverDetail?.productGroups }}
          >
            {rc?.receiverDetail?.productGroups}
          </Typography.Text>
        );
      },
    },
    {
      title: t("approve"),
      align: "center",
      width: 200,
      render: (_, rc) => {
        if (!rc?.acceptStatus) return <span>-</span>;
        return <BlockColor value={rc?.acceptStatus} type="acceptStatus" />;
      },
    },
    {
      title: t("formation"),
      align: "center",
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        if (!rc?.registerType) return <span>-</span>;
        return <BlockColor value={rc?.registerType} type="registerType" />;
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    const pickupDate = values.pickupDate;
    delete values.date;
    delete values.pickupDate;
    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      startPickupDate: undefined,
      endPickupDate: undefined,
      ...date,
      ...pickupDate,
      page: 1,
    });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        rowKey="id"
        scroll={{ x: 1200 }}
        query={get_Receivers}
        title={t("consignee-list")}
        columns={columns}
        onDelete={{
          mutation: del_Receiver,
          invalidateQueries: ["get-receivers", "get-receiver"],
          onLastItemInPage: () => {
            setParams({ ...params, page: 1 });
          },
        }}
        action={{
          bulkDelete: {
            mutation: del_Receivers,
            invalidateQueries: ["get-receivers", "get-receiver"],
            onLastItemInPage: () => {
              setParams({ ...params, page: 1 });
            },
          },
          excels: {
            template: {
              mutation: export_template,
              fileName: "receiver template",
            },
            import: {
              mutation: import_excel,
              invalidateQueries: ["get-receivers", "get-receiver"],
            },
            importProfile: {
              mutation: import_profile,
              invalidateQueries: ["get-receivers", "get-receiver"],
              typeUser: "consignee",
            },
          },
        }}
        onAdd="user-management.consignee-add"
        onRowClick="user-management.consignee-edit"
        pagination={{
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default UserConsigneePage;
