import Icon from "@ant-design/icons";
import { Col, Form, FormItemProps, Popover, Row, Typography } from "antd";
import { FilterIcon } from "assets/icons/general";
import CButton from "components/button/c-button";
import CButtonIcon from "components/button/c-button-icon";
import CInput from "components/input/c-input";
import CRangePicker from "components/input/c-range-picker";
import CRangePickerByObjectKey from "components/input/c-range-picker/split-by-object-key";
import CSelect from "components/input/c-select";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type SearchBoxElementTypes =
  | "Input"
  | "Select"
  | "RangePicker"
  | "SelectRole"
  | "SelectOrganization"
  | "SelectJobPosition"
  | "RangePickerByKey"
  | "SelectUserType"
  | "SelectRegisterType"
  | "SelectJobType"
  | "SelectAcceptStatus"
  | "SelectStatusQueueCar"
  | "SelectVehicleBrand"
  | "SelectDepartment";
export interface ISearchBoxElement {
  name: string;
  label: string;
  input: {
    type: SearchBoxElementTypes;
    options?: any;
  };
  form?: FormItemProps;
}

export interface PageSearchBoxProps {
  elements: ISearchBoxElement[];
  onFinish: (v: any) => void;
  noStyle?: boolean;
  shadow?: boolean;
  colSpan?: number;
}

const PageSearchBox: FC<PageSearchBoxProps> & {
  WithPopover: FC<PageSearchBoxProps>;
} = ({ elements, onFinish, noStyle, colSpan = 6, shadow = true }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const getInput = (type: SearchBoxElementTypes) => {
    switch (type) {
      case "Input":
        return CInput;
      case "Select":
        return CSelect;
      case "RangePicker":
        return CRangePicker;
      case "RangePickerByKey":
        return CRangePickerByObjectKey;
      case "SelectRole":
        return CSelect.Role;
      case "SelectOrganization":
        return CSelect.Organization;
      case "SelectJobPosition":
        return CSelect.JobPosition;
      case "SelectUserType":
        return CSelect.UserType;
      case "SelectRegisterType":
        return CSelect.RegisterType;
      case "SelectJobType":
        return CSelect.JobType;
      case "SelectAcceptStatus":
        return CSelect.AcceptStatus;
      case "SelectStatusQueueCar":
        return CSelect.StatusQueueCar;
      case "SelectVehicleBrand":
        return CSelect.VehicleBrand;
      case "SelectDepartment":
        return CSelect.Department;
    }
  };

  const getPlaceholder = (type: SearchBoxElementTypes, title: string) => {
    if (
      type === "Input" ||
      type === "Select" ||
      type === "SelectUserType" ||
      type === "SelectStatusQueueCar"
    ) {
      return { placeholder: title };
    }
    return {};
  };

  const getClassName = () => {
    if (noStyle) return "";
    if (!shadow) {
      return "!p-5 bg-white rounded-app";
    }
    return "!p-5 bg-white rounded-app shadow";
  };

  const className = getClassName();

  return (
    <Form
      form={form}
      className={className}
      layout="vertical"
      onFinish={onFinish}
    >
      <Row gutter={[12, 6]}>
        {elements.map((item) => {
          const input = getInput(item.input.type) as any;
          const placeholder = getPlaceholder(item.input.type, item.label);
          return (
            <Col span={colSpan} key={item.name}>
              <Form.Item
                {...item.form}
                name={item.name}
                label={item.label}
                className="!mb-0"
              >
                {React.createElement(input, {
                  ...placeholder,
                  ...item.input.options,
                })}
              </Form.Item>
            </Col>
          );
        })}
        <Col span={colSpan}>
          <Row gutter={6} className="h-full flex items-end">
            <Col span={10} className="flex items-end">
              <CButton className="w-full" htmlType="submit">
                {t("search")}
              </CButton>
            </Col>
            <Col span={10}>
              <CButton
                theme="white"
                className="w-full"
                onClick={() => {
                  form.resetFields();
                  form.submit();
                }}
              >
                {t("clear-data")}
              </CButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const WithPopover: FC<PageSearchBoxProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Popover
      overlayInnerStyle={{ width: "40vw" }}
      placement="bottomLeft"
      title={
        <Typography.Title level={4} className="!text-primary-dark py-2">
          {t("search")}
        </Typography.Title>
      }
      content={<PageSearchBox colSpan={12} {...props} noStyle />}
      trigger={["click"]}
    >
      <CButtonIcon theme="white" icon={<Icon component={FilterIcon} />} />
    </Popover>
  );
};

PageSearchBox.WithPopover = WithPopover;

export default PageSearchBox;
