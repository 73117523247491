import { Col, Divider, Form, Row, TableColumnsType, Typography } from "antd";
import CButton from "components/button/c-button";
import CAvatar from "components/display/c-avatar";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import Container from "components/display/container";
import LicensePlateImage from "components/display/license-plate-image";
import VehicleImage from "components/display/vehicle-image";
import CDatePicker from "components/input/c-date-picker";
import CInput from "components/input/c-input";
import CTextArea from "components/input/c-input/text-area";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import { fireNotification } from "components/popup/notification";
import dayjs from "config/dayjs";
import { usePageRoute } from "provider/page-route";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetEmployees } from "services/employee";
import { IEmployee, IEmployeeParams } from "services/employee/interface";
import {
  useGetVehicleCompany,
  usePatchVehicleCompany,
  usePostVehicleCompany,
} from "services/vehicle-company";

const VehicleRegisteredVehicleCreateAndEditPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const patch_company = usePatchVehicleCompany();
  const post_company = usePostVehicleCompany();
  const { data, isLoading } = useGetVehicleCompany(id);

  useEffect(() => {
    if (!data) return;
    const { licensePlateImage, encodeDevice, createdAt } =
      data.vehicle?.lastDetect || {};
    form.setFieldsValue({
      ...data,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      employeeId: data?.employee?.id,
      detectImage: licensePlateImage,
      detectDevice: encodeDevice?.devName,
      detectLicensePlate: data.licensePlate,
      detectDate: createdAt ? dayjs(createdAt) : null,
      description: data?.vehicle?.description,
      vehicleBrand: data.brand,
    });
    if (data.isSelectEmployee) {
      const { firstName, lastName, phoneNumber } = data.employee || {};
      form.setFieldsValue({ firstName, lastName, phoneNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const pageKey = getCurrentKey();

  return (
    <PageContent
      loading={isLoading && !!id}
      breadcrumb={{
        form,
        shadow: false,
        mutate: {
          mutation: id ? patch_company : post_company,
          invalidateQueries: ["vehicle-company"],
          onSaveAndContinue: !id,
          onBeforeUpdate: (values) => {
            return {
              ...values,
              employeeId: values?.employeeId || null,
            };
          },
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title level={5} className="!text-primary-dark">
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form layout="vertical" className="!p-5" form={form}>
          <Form.Item hidden name="id">
            <CInput />
          </Form.Item>
          <Row gutter={[12, 6]}>
            <LicensePlate />
            <Vehicle />
            <VehicleOwner />
            <Col span={24}>
              <Form.Item label={t("note")} name="description">
                <CTextArea rows={5} placeholder={t("note")} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageContent>
  );
};

const Vehicle = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col span={24} className="mt-4">
        <Typography.Text className="!text-primary">
          {t("vehicle-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item name="vehicleImage" label={t("vehicle-picture")}>
          <VehicleImage />
        </Form.Item>
      </Col>
      <Col span={16}></Col>
      <Col span={8}>
        <Form.Item name="vehicleBrand" label={t("vehicle-brand")}>
          <CSelect.VehicleBrand />
        </Form.Item>
      </Col>
      {/* <Col span={8}>
        <Form.Item label={t("vehicle-series")}>
          <CInput placeholder={t("vehicle-series")} />
        </Form.Item>
      </Col> */}
      <Col span={8}>
        <Form.Item name="color" label={t("color")}>
          <CInput placeholder={t("color")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="licensePlate"
          label={t("license-plate-number")}
          rules={[{ required: true }]}
        >
          <CInput placeholder={t("license-plate-number")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        {/* <Form.Item label={t("plate-province")}>
          <CSelect.PlateProvince />
        </Form.Item> */}
      </Col>
    </React.Fragment>
  );
};

const VehicleOwner = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const form = Form.useFormInstance();
  const wUserId = Form.useWatch("employeeId");

  const toggle = () => {
    setVisible(!visible);
  };

  const onReset = () => {
    form.resetFields(["firstName", "lastName", "phoneNumber", "employeeId"]);
  };

  const readOnly = !!wUserId;

  return (
    <React.Fragment>
      <Form.Item name="employeeId" hidden>
        <CInput />
      </Form.Item>
      <UserSelectDrawer
        visible={visible}
        onClose={toggle}
        onFinish={(value) => {
          const { firstName, lastName, phoneNumber, id } = value || {};
          form.setFieldsValue({
            firstName,
            lastName,
            phoneNumber,
            employeeId: id,
          });
        }}
      />
      <Col span={24} className="mt-4">
        <Typography.Text className="!text-primary">
          {t("vehicle-owner-information")}
        </Typography.Text>
        {readOnly ? (
          <Typography.Text
            type="danger"
            className="ml-2"
            style={{ fontSize: 12 }}
          >
            ({t("currently-connected-to-users", { ns: "message" })})
          </Typography.Text>
        ) : null}
      </Col>
      <Col span={8}>
        <Form.Item name="firstName" label={t("first-name")}>
          <CInput readOnly={readOnly} placeholder={t("first-name")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="lastName" label={t("last-name")}>
          <CInput readOnly={readOnly} placeholder={t("last-name")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label=" ">
          <Row gutter={12}>
            <Col span={8}>
              <CButton onClick={toggle} className="w-full">
                {t("search")}
              </CButton>
            </Col>
            <Col span={8}>
              <CButton theme="gray" onClick={onReset} className="w-full">
                {t("clear-data")}
              </CButton>
            </Col>
          </Row>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="phoneNumber" label={t("phone-number")}>
          <CInput readOnly={readOnly} placeholder={t("phone-number")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const LicensePlate = () => {
  const { t } = useTranslation();
  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  if (!id) return null;

  return (
    <React.Fragment>
      <Col span={24}>
        <Typography.Text className="!text-primary">
          {t("license-plate-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item name="detectImage">
          <LicensePlateImage />
        </Form.Item>
      </Col>
      <Col span={16}></Col>
      <Col span={8}>
        <Form.Item
          name="detectDevice"
          label={t("license-plate-detection-camera")}
        >
          <CInput readOnly placeholder={t("license-plate-detection-camera")} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("license-plate")} name="detectLicensePlate">
          <CInput readOnly placeholder={t("license-plate")} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item label={t("entry-date")} name="detectDate">
          <CDatePicker placeholder={t("entry-date")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const UserSelectDrawer: React.FC<{
  visible: boolean;
  onClose: () => void;
  onFinish: (v?: any) => void;
}> = ({ visible, onClose, onFinish }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [params, setParams] = useState<IEmployeeParams>({
    page: 1,
    limit: 10,
  });
  const { data, isFetching } = useGetEmployees(params);

  const columns: TableColumnsType<IEmployee> = [
    {
      title: t("profile-picture"),
      width: 120,
      align: "center",
      render: (_, rc) => {
        return <CAvatar src={rc.profile?.url} />;
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const { firstName, lastName } = rc || {};
        if (!firstName && !lastName) return "-";
        return `${rc?.firstName} ${rc?.lastName}`;
      },
    },
    {
      title: t("phone-number"),
      dataIndex: "phoneNumber",
    },
  ];

  const onSave = (values: any) => {
    const { selected } = values;
    if (!selected || !selected?.length) {
      return fireNotification({
        type: "warning",
        message: t("please-select-data", { ns: "message" }),
      });
    }
    const user = data?.data.find((e) => e.id === selected?.[0]);
    onFinish(user);
    onClose();
  };

  return (
    <CDrawer
      form={form}
      visible={visible}
      onClose={onClose}
      title={t("list")}
      onSave={onSave}
    >
      <Form form={form} layout="vertical">
        <Form.Item label={t("search")}>
          <CInput.Debounce
            value={params.name}
            onChange={(e) => setParams({ ...params, name: e })}
            placeholder={t("search")}
          />
        </Form.Item>
        <Form.Item name="selected">
          <CTable.Select
            type="radio"
            rowKey="id"
            loading={isFetching}
            columns={columns}
            dataSource={data?.data || []}
            pagination={{
              total: data?.count,
              current: data?.page,
              pageSize: data?.limit,
              size: "small",
              onChange: (page, limit) => {
                setParams({ ...params, page, limit });
              },
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default VehicleRegisteredVehicleCreateAndEditPage;
