import { Radio, RadioProps } from "antd";
import { FC } from "react";
import styled from "styled-components";
import CRadioGroup, { CRadioGroupProps } from "./c-radio-group";
import CRadioCardNumber from "./card-number";
import CRadioContactType from "./contact-type";
import CRadioMarkerType from "./marker-type";
import CRadioProductChoice, {
  CRadioProductChoiceProps,
} from "./product-choice";

const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    width: 18px;
    height: 18px;
  }
  .ant-radio-inner::after {
    margin-top: -13px;
    margin-left: -13px;
    width: 26px;
    height: 26px;
  }
`;

type NotationTypes = {
  Group: FC<CRadioGroupProps>;
  CardNumber: FC<CRadioGroupProps>;
  ProductChoice: FC<CRadioProductChoiceProps>;
  ContactType: FC<CRadioGroupProps>;
  MarkerType: FC<CRadioGroupProps>
};

const CRadio: FC<RadioProps> & NotationTypes = (props) => {
  return <StyledRadio {...props} />;
};

CRadio.Group = CRadioGroup;
CRadio.CardNumber = CRadioCardNumber;
CRadio.ProductChoice = CRadioProductChoice;
CRadio.ContactType = CRadioContactType;
CRadio.MarkerType = CRadioMarkerType;

export default CRadio;
