import { UseInfiniteQueryResult } from "@tanstack/react-query";
import CIndicator from "components/display/c-indicator";
import { IInfiniteScroll, IResponse } from "config/axios/interface";
import useDebounce from "hooks/useDebounce";
import React, { FC, useEffect } from "react";
import { useState } from "react";
import CSelect, { CSelectProps } from ".";

export interface CSelectPaginationProps
  extends Omit<CSelectProps, "valueOptions"> {
  useQuery: (params?: any) => UseInfiniteQueryResult<IResponse<any[]>>;
  titleKey: string;
  valueKey: string;
  searchKey?: string;
  onSelectCallback?: (v: any) => void;
  translation?: boolean;
  queryParams?: Record<string, any>;
  getOriginOption?: boolean
  setStateOptions?: (v: any) => void
}

const CSelectPagination: FC<CSelectPaginationProps> = ({
  useQuery,
  titleKey,
  valueKey,
  onChange,
  onSelectCallback,
  searchKey,
  translation,
  queryParams,
  getOriginOption = false,
  setStateOptions,
  ...props
}) => {
  const [search, setSearch] = useState<string>();
  const dSearch = useDebounce(search, 500);
  const mutation = useQuery(
    searchKey ? { [searchKey]: dSearch, ...queryParams } : queryParams
  );

  useEffect(() => {
    if (!mutation.data?.pages.flatMap(e => e.data).length) return
    setStateOptions?.(mutation.data?.pages.flatMap(e => e.data))
  }, [mutation.data])

  const entireData = (): IInfiniteScroll<any> => {
    const { pages } = mutation.data || {};
    if (!pages?.[0])
      return { data: [], hasMore: false, page: 1, undefinded: true };

    const idx = (pages?.length || 0) - 1;
    const page = pages?.[idx]?.page || 1;
    const pageCount = pages?.[idx]?.pageCount || 0;
    const data =
      pages
        ?.map((item) => {
          return item?.data;
        })
        .flat() || [];
    return { data, hasMore: pageCount > page, page: page };
  };

  const { page, hasMore, data } = entireData();

  const fetchNext = () => {
    mutation.fetchNextPage({ pageParam: { page: page + 1 } });
  };
  const fetching = mutation.isFetchingNextPage || mutation.isFetching;

  const _onChange = (e: any, i: any) => {
    const find = data.find((c: any) => c.id === e);
    onChange?.(e, getOriginOption ? find : i);
    onSelectCallback?.(find);
  };

  const onSearch = (e: string) => {
    if (!searchKey) return;
    setSearch(e);
  };

  return (
    <CSelect
      {...props}
      valueOptions={{ values: data, valueKey, titleKey, translation }}
      dropdownRender={(menu) => {
        const { isFetching, isFetchingNextPage } = mutation;
        const isFetchingSearch = isFetching && !isFetchingNextPage;

        return (
          <React.Fragment>
            {/* <CIndicator hidden={!isFetchingSearch} height={260} size={25} /> */}
            <div hidden={isFetchingSearch}>
              {menu}
              <div
                hidden={!isFetchingNextPage}
                style={{ transition: "0.3s" }}
                className="relative bg-white"
              >
                <CIndicator
                  style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                  height={30}
                  size={25}
                />
              </div>
            </div>
          </React.Fragment>
        );
      }}
      loading={mutation.isFetching}
      onPopupScroll={(e) => {
        const { target } = e as any;
        const scrolled = target.scrollTop + target.offsetHeight;
        const reach = scrolled >= target.scrollHeight - 10;
        if (reach && hasMore && !fetching) {
          fetchNext();
        }
      }}
      onChange={_onChange}
      onSearch={searchKey ? onSearch : undefined}
      showSearch={!!searchKey}
      filterOption={() => true}
    />
  );
};

export default CSelectPagination;
