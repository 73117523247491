import Icon from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import { TemperatureIcon, MaskICon } from "assets/icons/web";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import CUpload from "components/input/c-upload";
import { useTranslation } from "react-i18next";
import { WrapperTemperatureInformation } from "../create-user-pdpa";

const TemperatureInformation = ({ pending , acceptPdpa }: { pending: boolean ; acceptPdpa: boolean | undefined;}) => {
  
  const { t } = useTranslation();
  return (
    <Row justify="center" className="w-100" gutter={[24, 24]}>
      <Col>
        <Form.Item name="profile" className="center-form-item" rules={[
          {
            required: acceptPdpa === true,
          }
        ]}>
          <CUpload.Capture disabled={pending} />
        </Form.Item>
      </Col>
      <Col>
        <WrapperTemperatureInformation className="p-2 flex-col !justify-between">
          <div></div>
          <div className="w-full !flex justify-center">
            <Icon component={TemperatureIcon} style={{ fontSize: 64 }} />
          </div>
          <Row gutter={6}>
            <Col span={12}>
              <Form.Item noStyle name="temperature">
                <CInput placeholder={t("temperature")} disabled={pending} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle name="temperatureVerify">
                <CSelect
                  disabled={pending}
                  placeholder={t("temperature")}
                  valueOptions={{
                    values: [
                      { value: true, lable: t("pass") },
                      { value: false, lable: t("not-pass") },
                    ],
                    valueKey: "value",
                    titleKey: "lable",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </WrapperTemperatureInformation>
      </Col>
      <Col>
        <WrapperTemperatureInformation className="p-2 flex-col !justify-between">
          <div></div>
          <div className="w-full !flex justify-center">
            <Icon component={MaskICon} style={{ fontSize: 60 }} />
          </div>
          <div className="w-full">
            <Form.Item noStyle name="mask">
              <CSelect
                disabled={pending}
                placeholder={t("mask")}
                valueOptions={{
                  values: [
                    { value: "WEARMASK", lable: t("wear-a-mask") },
                    { value: "NOMASK", lable: t("not-mask") },
                  ],
                  valueKey: "value",
                  titleKey: "lable",
                }}
              />
            </Form.Item>
          </div>
        </WrapperTemperatureInformation>
      </Col>
    </Row>
  );
};

export default TemperatureInformation;
