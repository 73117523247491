import { Modal } from "antd";
import { FC } from "react";
import styled from "styled-components";
import { CModalProps } from ".";

const StyledFullScreen = styled(Modal)`
  .anticon-close > svg {
    margin: auto;
    display: block;
    width: 15px;
    height: 15px;
  }
  padding: 0;
  max-width: 100vw;
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 0rem !important;
  }
`;

export interface CModalFullScreenProps extends CModalProps {}

const CModalFullScreen: FC<CModalFullScreenProps> = ({
  footer = null,
  closable = false,
  centered = true,
  ...props
}) => {
  return (
    <StyledFullScreen
      centered={centered}
      closable={closable}
      footer={footer}
      {...props}
      transitionName=""
      width="100vw"
      style={{ height: "100vh" }}
      bodyStyle={{ height: "100%" }}
      {...props}
    />
  );
};
export default CModalFullScreen;
