import { Col, Divider, Form, Row, Typography } from "antd";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import UploadAvatar from "components/input/c-upload/avatar";
import PageContent from "components/layout/page-content";
import { usePageRoute } from "provider/page-route";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetUser, usePatchUser, usePostUser } from "services/user";

const UserStaffCreateAndEditPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // const [activeKey, setActiveKey] = useState<string>("#staff-general");

  const { getCurrentKey, getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();

  const post_user = usePostUser();
  const patch_user = usePatchUser();
  const { data, isLoading } = useGetUser(id);
  console.log(data);
  useEffect(() => {
    form.setFieldsValue({
      ...data,
      role: data?.role?.id,
      profile: data?.profile?.url,
      position: data?.position?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // const onSpyClick = (e: string) => {
  //   setActiveKey(e);
  // };

  const pageKey = getCurrentKey();

  return (
    <PageContent
      loading={isLoading && !!id}
      breadcrumb={{
        form,
        shadow: false,
        mutate: {
          mutation: id ? patch_user : post_user,
          invalidateQueries: ["get-users", "get-user"],
          onSaveAndContinue: !id,
        },
      }}
      // scrollSpy={{
      //   activeKey: activeKey,
      //   items: [
      //     { id: "#staff-general", title: t("basic-information") },
      //     { id: "#staff-password", title: t("password-setting") },
      //   ],
      //   onChange: onSpyClick,
      // }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title
            id="staff-general"
            level={5}
            className="!text-primary-dark"
          >
            {t(pageKey || "", { ns: "menu" })}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <Form
          layout="vertical"
          className="!p-5"
          form={form}
          initialValues={{ userType: "STAFF" }}
        >
          <Row gutter={24}>
            <Col span={6} className="!flex justify-center">
              <div>
                <Typography.Text className="!text-primary">
                  {t("basic-information")}
                </Typography.Text>
                <Form.Item className="!mt-3" name="profile">
                  <UploadAvatar />
                </Form.Item>
              </div>
            </Col>
            <Col span={18}>
              <Row gutter={[24, 0]}>
                <Form.Item name="id" hidden>
                  <CInput />
                </Form.Item>
                <Form.Item name="userType" hidden>
                  <CInput />
                </Form.Item>

                <Col span={12}>
                  <Form.Item
                    label={`${t("first-name")} (TH)`}
                    rules={[{ required: true }]}
                    name="firstNameTh"
                  >
                    <CInput placeholder={`${t("first-name")} (TH)`} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={`${t("last-name")} (TH)`}
                    rules={[{ required: true }]}
                    name="lastNameTh"
                  >
                    <CInput placeholder={`${t("last-name")} (TH)`} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={`${t("first-name")} (EN)`}
                    name="firstNameEn"
                  >
                    <CInput placeholder={`${t("first-name")} (EN)`} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={`${t("last-name")} (EN)`} name="lastNameEn">
                    <CInput placeholder={`${t("last-name")} (EN)`} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t("email")}
                    rules={[{ required: true }, { type: "email" }]}
                    name="email"
                  >
                    <CInput placeholder={t("email")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("phone-number")}
                    rules={[{ required: true }]}
                    name="phoneNumber"
                  >
                    <CInput placeholder={t("phone-number")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("job-position")}
                    rules={[{ required: true }]}
                    name="position"
                  >
                    <CSelect.JobPosition />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("gender")}
                    rules={[{ required: true }]}
                    name="gender"
                  >
                    <CSelect.Gender />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("role")}
                    rules={[{ required: true }]}
                    name="role"
                  >
                    <CSelect.Role />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("employee-id")}
                    rules={[{ required: true }]}
                    name="identityCode"
                  >
                    <CInput placeholder={t("employee-id")}/>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t("address")}
                    name="address"
                  >
                    <CInput.TextArea rows={5} placeholder={t("address")} />
                  </Form.Item>
                </Col>
                <Col span={24} className="mt-8">
                  <Typography.Text id="staff-password">
                    {t("password-setting")}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("username")}
                    rules={[{ required: true }]}
                    name="username"
                  >
                    <CInput readOnly={!!id} placeholder={t("username")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {id ? null : (
                    <Form.Item
                      label={t("password")}
                      rules={[{ required: true }]}
                      name="password"
                    >
                      <CInput.Password placeholder={t("password")} />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageContent>
  );
};

export default UserStaffCreateAndEditPage;
