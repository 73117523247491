import Resizer from "react-image-file-resizer";

export const fileToDataUrl = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const dataUrlToFile = async  (
  dataURI: string,
  fileName: string = "file.png"
) => {
  // var byteString = atob(dataURI.split(",")[1]);
  // var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // var ab = new ArrayBuffer(byteString.length);
  // var ia = new Uint8Array(ab);
  // for (var i = 0; i < byteString.length; i++) {
  //   ia[i] = byteString.charCodeAt(i);
  // }
  // return new File([ab], fileName, { type: mimeString });

  let response = await fetch(dataURI);
  let data = await response.blob();
  let metadata = {
    type: 'image/jpeg'
  };
  return new File([data], fileName, metadata);

  
};

export const resizeImageFileSize = async ({
  maxHeight = 200,
  maxWidth = 200,
  file,
}: {
  maxWidth?: number;
  maxHeight?: number;
  file: File;
}) =>
  new Promise<File | string | Blob | ProgressEvent<FileReader>>((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const downloadBlobFile = (
  data: Blob,
  extension: string,
  fileName: string = "report"
) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${extension}`); //or any other extension
  document.body.appendChild(link);
  link.click();
};
