import { CUploadCaptureProps } from "components/input/c-upload/capture";
import {
  ComponentType,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { ICaptureModalContext } from "./interface";

const Context = createContext<ICaptureModalContext>({} as ICaptureModalContext);

const withCaptureModalProvider = (
  Component: ComponentType<CUploadCaptureProps>
) => {
  return (props: CUploadCaptureProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [isModelLoaded, setIsModelLoaded] = useState<boolean>(false);
    const [stream, setStream] = useState<MediaStream>();

    return (
      <Context.Provider
        value={{
          videoRef,
          canvasRef,
          isModelLoaded,
          stream,
          setIsModelLoaded,
          setStream,
        }}
      >
        <Component {...props} />
      </Context.Provider>
    );
  };
};

export const useCaptureModalProvider = () => useContext(Context);
export default withCaptureModalProvider;
