import {
  MenuDisplayScreen,
  MenuVehicleInOutIcon,
  // MenuVehicleOverviewIcon,
  // MenuVehicleMangeVehicleIcon,
  MenuVehicleManageParkingIcon,
  MenuVehicleReportIcon,
} from "assets/icons/menu/vehicle-management";
import DisplayScreenPage from "pages/vehicle-management/display-screen";
import VehicleDepartureScreen from "pages/vehicle-management/entrance-departure-management/departure-screen";
import VehicleEntranceScreen from "pages/vehicle-management/entrance-departure-management/entrance-screen";
import VehicleListOfAllVehicle from "pages/vehicle-management/entrance-departure-management/list-of-all-vehicles";
import VehicleFindVehicle from "pages/vehicle-management/parking-management/find-vehicle";
import VehicleFloors from "pages/vehicle-management/parking-management/floors";
import VehicleFloorEditPage from "pages/vehicle-management/parking-management/floors/edit";
import VehicleParkingCamera from "pages/vehicle-management/parking-management/parking-camera";
import VehicleParkingList from "pages/vehicle-management/parking-management/parking-list";
import VehicleParkings from "pages/vehicle-management/parking-management/parkings";
import VehicleParkingOverview from "pages/vehicle-management/parking-overview";
import VehicleEmergencyBarrierReport from "pages/vehicle-management/report/emergency-barrier-report";
import VehicleOvernightReport from "pages/vehicle-management/report/overnight-report";
import VehicleParkingPeriodReport from "pages/vehicle-management/report/parking-period-report";
import VehicleTrafficReport from "pages/vehicle-management/report/traffic-report";
import VehicleBannedVehicle from "pages/vehicle-management/entrance-departure-management/banned-vehicle";
import VehicleBannedCreateAndEditPage from "pages/vehicle-management/entrance-departure-management/banned-vehicle/create-and-edit";
// import VehicleGeneralVehicle from "pages/vehicle-management/vehicle-management/general-vehicle";
import VehicleRegisteredVehicle from "pages/vehicle-management/entrance-departure-management/registered-vehicle";
import VehicleRegisteredVehicleCreateAndEditPage from "pages/vehicle-management/entrance-departure-management/registered-vehicle/create-and-edit";
import { IPageRoute, ISubMenu } from "./../interface";
import { TSubPageVehicleManagement } from "./interface";
import VehicleListOfAllVehicleCreateAndEditPage from "pages/vehicle-management/entrance-departure-management/list-of-all-vehicles/create-and-edit";
import VehicleReportPage from "pages/vehicle-management/report/report-all";

export const VEHICLE_MANAGEMENT_MENUS: ISubMenu<TSubPageVehicleManagement>[] = [
  {
    key: "vehicle-management.entrance-departure-management",
    title: "vehicle-management.entrance-departure-management",
    icon: MenuVehicleInOutIcon,
    code: "050100",
    children: [
      {
        key: "vehicle-management.entrance-screen",
        title: "vehicle-management.entrance-screen",
        code: "050101",
      },
      {
        key: "vehicle-management.departure-screen",
        title: "vehicle-management.departure-screen",
        code: "050102",
      },
      {
        key: "vehicle-management.registered-vehicle",
        title: "vehicle-management.registered-vehicle",
        code: "050103",
      },
      {
        key: "vehicle-management.list-of-all-vehicles",
        title: "vehicle-management.list-of-all-vehicles",
        code: "050104",
      },
      {
        key: "vehicle-management.banned-vehicle",
        title: "vehicle-management.banned-vehicle",
        code: "050105",
      },
    ],
  },
  {
    key: "vehicle-management.parking-management",
    title: "vehicle-management.parking-management",
    icon: MenuVehicleManageParkingIcon,
    code: "050200",
    children: [
      {
        key: "vehicle-management.parkings",
        title: "vehicle-management.parkings",
        code: "050201",
        loading: true,
      },
      {
        key: "vehicle-management.parking-camera",
        title: "vehicle-management.parking-camera",
        code: "050202",
      },
    ],
  },
  {
    key: "vehicle-management.display-screen",
    title: "vehicle-management.display-screen",
    icon: MenuDisplayScreen,
    code: "050300",
  },
  {
    key: "vehicle-management.report",
    title: "vehicle-management.report",
    icon: MenuVehicleReportIcon,
    code: "050400",
    children: [
      {
        key: "vehicle-management.report-all",
        title: "vehicle-management.report-all",
        code: "050401",
      },
      {
        key: "vehicle-management.traffic-report",
        title: "vehicle-management.traffic-report",
        code: "050402",
      },
      {
        key: "vehicle-management.parking-period-report",
        title: "vehicle-management.parking-period-report",
        code: "050403",
      },
      {
        key: "vehicle-management.emergency-barrier-report",
        title: "vehicle-management.emergency-barrier-report",
        code: "050404",
      },
      {
        key: "vehicle-management.overnight-report",
        title: "vehicle-management.overnight-report",
        code: "050405",
      },
    ],
  },
];

export const VEHICLE_MANAGEMENT_ROUTES: IPageRoute<TSubPageVehicleManagement>[] =
  [
    {
      key: "vehicle-management.parking-overview",
      element: VehicleParkingOverview,
    },
    {
      key: "vehicle-management.entrance-screen",
      element: VehicleEntranceScreen,
    },
    {
      key: "vehicle-management.departure-screen",
      element: VehicleDepartureScreen,
    },
    {
      key: "vehicle-management.list-of-all-vehicles",
      element: VehicleListOfAllVehicle,
    },
    {
      key: "vehicle-management.list-of-all-vehicles-edit",
      element: VehicleListOfAllVehicleCreateAndEditPage
    },
    {
      key: "vehicle-management.registered-vehicle",
      element: VehicleRegisteredVehicle,
    },
    {
      key: "vehicle-management.registered-vehicle-add",
      element: VehicleRegisteredVehicleCreateAndEditPage,
    },
    {
      key: "vehicle-management.registered-vehicle-edit",
      element: VehicleRegisteredVehicleCreateAndEditPage,
    },
    {
      key: "vehicle-management.banned-vehicle",
      element: VehicleBannedVehicle,
    },
    {
      key: "vehicle-management.banned-vehicle-add",
      element: VehicleBannedCreateAndEditPage,
    },
    {
      key: "vehicle-management.banned-vehicle-edit",
      element: VehicleBannedCreateAndEditPage,
    },
    // {
    //   key: "vehicle-management.general-vehicle",
    //   element: VehicleGeneralVehicle,
    // },
    {
      key: "vehicle-management.parkings",
      element: VehicleParkings,
    },
    {
      key: "vehicle-management.floors",
      element: VehicleFloors,
    },
    {
      key: "vehicle-management.floors-edit",
      element: VehicleFloorEditPage,
    },
    {
      key: "vehicle-management.parking-camera",
      element: VehicleParkingCamera,
    },
    {
      key: "vehicle-management.find-vehicle",
      element: VehicleFindVehicle,
    },
    {
      key: "vehicle-management.parking-list",
      element: VehicleParkingList,
    },
    {
      key: "vehicle-management.traffic-report",
      element: VehicleTrafficReport,
    },
    {
      key: 'vehicle-management.report-all',
      element: VehicleReportPage
    },
    {
      key: "vehicle-management.parking-period-report",
      element: VehicleParkingPeriodReport,
    },
    {
      key: "vehicle-management.emergency-barrier-report",
      element: VehicleEmergencyBarrierReport,
    },
    {
      key: "vehicle-management.overnight-report",
      element: VehicleOvernightReport,
    },
    {
      key: "vehicle-management.display-screen",
      element: DisplayScreenPage,
    },
  ];
