import React from "react";
import ApprovalTabel from "./tabel-content/approval-tabel";
import PendingApprovalTabel from "./tabel-content/pending-tabel";
import RejectedTabel from "./tabel-content/reject-tabel";

const TableContent = () => {
  return (
    <React.Fragment>
      <PendingApprovalTabel />
      <ApprovalTabel />
      <RejectedTabel />
    </React.Fragment>
  );
};

export default TableContent;
