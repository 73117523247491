import { fireNotification } from "components/popup/notification";
import { usePageRoute } from "provider/page-route";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { resizeImageFileSize } from "tools/file";
import { AcceptFileTypes } from "./c-upload";

export interface FilePickerRef {
  click: () => void;
}

interface FilePickerProps {
  accepts?: AcceptFileTypes[];
  onChange: (v: File) => void;
  resize?: boolean;
}

const FilePicker = forwardRef<FilePickerRef, FilePickerProps>(
  ({ accepts = [".jpeg", ".jpg", ".png"], resize = true, onChange }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { pageKey } = usePageRoute();

    useImperativeHandle(ref, () => ({
      click: () => {
        openPickFileDialog();
      },
    }));

    const onPicked = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files && files?.length > 0) {
        try {
          const image = files[0];
          if (!resize) {
            return onChange(image);
          }
          const resized = (await resizeImageFileSize({
            file: image,
            maxHeight: 500,
            maxWidth: 500,
          })) as File;
          return onChange(resized);
        } catch (err: any) {
          fireNotification({
            type: "error",
            description: err?.message,
            menu: pageKey,
          });
        }
      }
    };

    const clearPreviousValue = (e: React.MouseEvent<HTMLInputElement>) => {
      e.currentTarget.value = "";
    };

    const openPickFileDialog = () => {
      inputRef.current?.click();
    };

    return (
      <input
        accept={accepts.join(",")}
        multiple={false}
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={onPicked}
        onClick={clearPreviousValue}
      />
    );
  }
);

export default FilePicker;
