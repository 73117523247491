import { Divider, TableColumnsType, Typography } from "antd";
import CTable from "components/display/c-table";
import Container from "components/display/container";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useCheckQuickLab } from "provider/check-quick-lab";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportCheckQuicklab,
  useGetCheckQuicklabs,
} from "services/check-quick-lab";
import {
  ICheckQuickLab,
  ICheckQuickLabParams,
} from "services/check-quick-lab/interface";

const CheckQuickLabContent = () => {
  const { t } = useTranslation();
  const { keyStatus, setNumberOfItems } = useCheckQuickLab();
  const { formatDate } = useAppLanguage();

  const [params, setParams] = useState<ICheckQuickLabParams>({ page: 1 });
  const { data, isFetching } = useGetCheckQuicklabs({
    ...params,
    status: keyStatus?.keyStatus,
  });
  const export_check = useExportCheckQuicklab();

  useEffect(() => {
    setNumberOfItems({
      VIP: data?.countStatusVip || 0,
      WAITING_FOR_INSPECT: data?.countStatusWaiting || 0,
      PASSED_THE_INSPECTION: data?.countStatusPassed || 0,
      FAILED_THE_INSPECTION: data?.countStatusFailed || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("date-get-material"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
    {
      name: "name",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "companyName",
      label: `${t("company-name")} / ${t("transport-name")}`,
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "licensePlate",
      label: t("license-plate"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: TableColumnsType<ICheckQuickLab> = [
    {
      title: t("date-get-material"),
      render: (_, rc) => {
        const date = formatDate({
          date: rc.createdAt,
          format: "nomal-full-time",
          keySeparator: " ",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const { firstName, lastName } = rc?.customerEmployee || {};
        if (!firstName && !lastName) return <span>-</span>;
        return (
          <Typography.Text
            ellipsis={{ tooltip: `${firstName} ${lastName}` }}
          >{`${firstName} ${lastName}`}</Typography.Text>
        );
      },
    },
    {
      title: `${t("company-name")} / ${t("transport-name")}`,
      ellipsis: { showTitle: false },
      render: (_, rc) => {
        const companyName = rc?.customerEmployee?.companyName;

        if (!companyName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: companyName }}>
            {companyName}
          </Typography.Text>
        );
      },
    },
    {
      title: t("license-plate"),
      render: (_, rc) => {
        if (!rc.vehicle?.licensePlate) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: rc.vehicle?.licensePlate }}>
            {rc.vehicle?.licensePlate}
          </Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    delete values.date;
    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  return (
    <Container scrollY height="calc(100vh - 200px)" padding="p-0">
      <PageSearchBox elements={elements} onFinish={onSearch} shadow={false} />
      <Divider className="!m-0" />
      <CTable
        selectAble={false}
        shadow={false}
        rowKey="id"
        title={t("ckeck-quick-lab-list")}
        loading={isFetching}
        columns={columns}
        dataSource={data?.data || []}
        action={{
          excels: {
            export: {
              fileName: `${t("ckeck-quick-lab-list")}`,
              mutation: export_check,
              extraValue: { status: keyStatus?.keyStatus },
            },
          },
        }}
        onRowClick="vehicle-inspection.check-quick-lab-edit"
        pagination={{
          total: data?.count,
          current: params?.page,
          pageSize: params.limit,
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </Container>
  );
};

export default CheckQuickLabContent;
