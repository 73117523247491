import { useSettingSchedule } from "provider/setting-schedule";
import SettingScheduleCalendar from "./calendar";

const SettingScheduleMonthType = () => {
  const { currentTime } = useSettingSchedule();
  const startDate = currentTime.month.startOf("M").format();
  const endDate = currentTime.month.endOf("M").format();

  // const { formatDate } = useAppLanguage();

  return (
    <div style={{ height: "calc(100vh - 293px)", overflow: "auto" }}>
      <SettingScheduleCalendar startDate={startDate} endDate={endDate} />      
    </div>
  );
};

export default SettingScheduleMonthType;
