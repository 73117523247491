import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppLanguageProvider from "provider/app-language";
import AppLoadingProvider from "provider/app-loading";
import AuthProvider from "provider/auth";
import { HashRouter, useRoutes } from "react-router-dom";
import { APP_ROUTER } from "./router";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const App = () => {
  const appRoute = useRoutes(APP_ROUTER);
  return appRoute;
};

const Wrapper = () => {
  return (
    <QueryClientProvider client={client}>
      <HashRouter>
        <AppLoadingProvider>
          <AppLanguageProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </AppLanguageProvider>
        </AppLoadingProvider>
      </HashRouter>
    </QueryClientProvider>
  );
};
export default Wrapper;
