import Icon from "@ant-design/icons";
import { Col, Form, Row, Typography } from "antd";
import { MaskIcon, TemperatureIcon } from "assets/icons/web";
import CDatePicker from "components/input/c-date-picker";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import CUpload from "components/input/c-upload";
import AddVehicleToUserField from "components/user-management/add-vehicle-field";
import React from "react";
import { useTranslation } from "react-i18next";
import { WrapperTemperatureInformation } from "../create-user-pdpa";
import dayjs from "dayjs";

const ContractorPage = ({ pending }: { pending: boolean }) => {
  const { t } = useTranslation();
  const workPermitLabel = (
    <React.Fragment>
      <span>เอกสารใบอนุญาตทำงาน / Work permit document</span>
      <span className="text-red-500 ml-2">
        {t("maximum-file-size-2-mb", { ns: "message" })}
      </span>
    </React.Fragment>
  );
  return (
    <Row gutter={24}>
      <Col span={6} className="!flex justify-center">
        <div>
          <Typography.Text className="!text-primary">
            {t("basic-information")}
          </Typography.Text>
          <Form.Item className="!mt-3 !-mb-2" name="profile">
            <CUpload.Capture disabled={pending} />
          </Form.Item>
          <WrapperTemperatureInformation className="p-2 flex-col !justify-between !mt-4">
            <div></div>
            <div className="w-full !flex justify-center">
              <Icon component={TemperatureIcon} style={{ fontSize: 64 }} />
            </div>
            <Row gutter={6}>
              <Col span={12}>
                <Form.Item noStyle name="temperature">
                  <CInput placeholder={t("temperature")} disabled={pending} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item noStyle name="temperatureVerify">
                  <CSelect
                    placeholder={t("temperature")}
                    valueOptions={{
                      values: [
                        { value: true, lable: t("pass") },
                        { value: false, lable: t("not-pass") },
                      ],
                      valueKey: "value",
                      titleKey: "lable",
                    }}
                    disabled={pending}
                  />
                </Form.Item>
              </Col>
            </Row>
          </WrapperTemperatureInformation>
          <WrapperTemperatureInformation className="p-2 flex-col !justify-between !mt-4">
            <div></div>
            <div className="w-full !flex justify-center">
              <Icon component={MaskIcon} style={{ fontSize: 64 }} />
            </div>
            <div className="w-full">
              <Form.Item noStyle name="mask">
                <CSelect
                  placeholder={t("mask")}
                  valueOptions={{
                    values: [
                      { value: "WEARMASK", lable: t("wear-a-mask") },
                      { value: "NOMASK", lable: t("not-mask") },
                    ],
                    valueKey: "value",
                    titleKey: "lable",
                  }}
                  disabled={pending}
                />
              </Form.Item>
            </div>
          </WrapperTemperatureInformation>
        </div>
      </Col>
      <Col span={18}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, whitespace: true }]}
              name="identificationNumber"
              label={`${t("identification-card-number-passport-other")}`}
            >
              <CInput
                placeholder={`${t(
                  "identification-card-number-passport-other"
                )}`}
                disabled={pending}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ whitespace: true }]}
              name="identityCode"
              label={`${t("contractor-id")}`}
            >
              <CInput
                placeholder={`${t("contractor-id")}`}
                disabled={pending}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="firstName"
              label={`${t("first-name")}`}
            >
              <CInput placeholder={`${t("first-name")}`} disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="lastName"
              label={`${t("last-name")}`}
            >
              <CInput placeholder={`${t("last-name")}`} disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="phoneNumber"
              label={t("phone-number")}
            >
              <CInput placeholder={t("phone-number")} disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("gender")}
              rules={[{ required: true }]}
              name="gender"
            >
              <CSelect.Gender disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{ required: true }]}
              name="workAreaId"
              label={t("work-area")}
            >
              {/* <CInput.TextArea
                rows={4}
                placeholder={t("work-area")}
                disabled={pending}
              /> */}
              <CSelect.WorkArea disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              // name="companyName"
              name="organizationId"
              label={t("affiliation")}
            >
              <CSelect.CompanyName
                placeholder={t("affiliation")}
                disabled={pending}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="jobType"
              label={t("work-type")}
            >
              <CSelect.JobType disabled={pending} />
            </Form.Item>
          </Col>
          {pending && (
            <Col span={12}>
              <Form.Item name="acceptStatus" label={t("status")}>
                <CSelect.AcceptStatus disabled={pending} />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              label={t("department")}
              rules={[{ required: true }]}
              name="department"
            >
              <CSelect.Department
                queryParams={{ departmentType: "EMPLOYEE" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="startDate"
              label={t("start-date")}
              initialValue={dayjs()}
            >
              <CDatePicker disabled={pending} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="endDate"
              label={t("end-date")}
              initialValue={dayjs()}
            >
              <CDatePicker disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              // rules={[{ required: true }]}
              name="groupId"
              label={
                <Row gutter={[6, 0]}>
                  <Col>
                    {t("setting.group-of-persons", { ns: "menu" })}
                  </Col>
                  <Col className="text-[#FF6F6F]">
                    <Typography.Text
                      ellipsis={{
                        tooltip: t("not-select-group-not-sent-hcp", {
                          ns: "message",
                        }),
                      }}
                      className="!text-[#FF6F6F]"
                    >
                      ( {t("not-select-group-not-sent-hcp", { ns: "message" })}{" "}
                      )
                    </Typography.Text>
                  </Col>
                </Row>
              }
            >
              <CSelect.GroupPerson disabled={pending} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("registration-date")}
              name="createdAt"
              initialValue={dayjs()}
            >
              <CDatePicker disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="remark" label={t("note")}>
              <CInput.TextArea
                rows={4}
                placeholder={t("note")}
                disabled={pending}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="contractorImages" label={workPermitLabel}>
              <CUpload.List disabled={pending} />
            </Form.Item>
          </Col>
          {/* <Col xs={24}>
            <Form.Item
              name="identityCardPicture"
              rules={[{ required: true }]}
              label={t("id-card-picture")}
            >
              <CUpload.Area readonly={pending}/>
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item noStyle name="vehicles">
              <AddVehicleToUserField disabled={pending} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContractorPage;
