import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectCDF: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return <CSelect placeholder={t("company-department-floor")} {...props} />;
};

export default CSelectCDF;
