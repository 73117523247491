import { useAuth } from "provider/auth";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { MAIN_MENU } from "router";
import {
  AppMenuType,
  IMainMenu,
  ISubMenu,
  TMainPage,
  TSubPage,
} from "router/interface";
import { IRoleMenu } from "services/role-menu/interface";
import { IUser } from "services/user/interface";
import { IAppRoleMenuContext } from "./interface";

const Context = createContext<IAppRoleMenuContext>({} as IAppRoleMenuContext);

const AppRoleMenuProvider = ({ children }: { children: ReactNode }) => {
  const { userMe } = useAuth();
  const [baseMenus] = useState<IMainMenu[]>(MAIN_MENU);
  const [roleMenus, setRoleMenus] = useState<IMainMenu[]>(MAIN_MENU);

  useEffect(() => {
    if (userMe) {
      initMenus(userMe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMe]);

  const initMenus = (user: IUser) => {
    const { roleMenus } = user.role || {};
    const nextRoleMenus = getRoleMenus(
      MAIN_MENU,
      roleMenus || []
    ) as IMainMenu[];
    setRoleMenus(nextRoleMenus);
  };

  const getRoleMenus = (
    appMenus: AppMenuType[],
    roleMenus: IRoleMenu[]
  ): AppMenuType[] => {
    return appMenus
      .map((item) => {
        if (item.children && item.children.length) {
          const children = getRoleMenus(item.children, roleMenus);
          return { ...item, children } as ISubMenu<TSubPage>;
        }
        return item;
      })
      .filter((item) => {
        const find = roleMenus.find((e) => e.menu?.code === item.code);
        return (item.children?.length || 0) > 0 || find?.isActive || false;
      });
  };

  const getMenuByKey = (
    key: TMainPage
  ): {
    initial?: TSubPage;
    menus: ISubMenu<TSubPage>[];
  } => {
    const find = roleMenus.find((e) => e.key === key);
    if (!find || !find.children) return { menus: [] };

    const initial = getInititalPage(find.children);
    return { menus: find.children, initial: initial };
  };

  const getInititalPage = (
    menus?: ISubMenu<TSubPage>[]
  ): TSubPage | undefined => {
    if (!menus) return;
    if (menus?.[0].children?.length) {
      return menus?.[0].children?.[0].key;
    }
    return menus?.[0].key;
  };

  const updateRoleMenus = (
    pageKey: TMainPage,
    nextSubMenu: ISubMenu<TSubPage>[]
  ) => {
    const next = roleMenus.map((item) => {
      if (item.key === pageKey) {
        return { ...item, children: nextSubMenu };
      }
      return item;
    }) as IMainMenu[];
    setRoleMenus(next);
  };

  return (
    <Context.Provider
      value={{ baseMenus, roleMenus, getMenuByKey, updateRoleMenus }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppRoleMenu = () => useContext(Context);
export default AppRoleMenuProvider;
