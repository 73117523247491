import { Col, Divider, Form, Row, Typography } from "antd";
import Container from "components/display/container";
import CInput from "components/input/c-input";
import CRadio from "components/input/c-radio";
import CSelect from "components/input/c-select";
import PageContent from "components/layout/page-content";
import { useAppLanguage } from "provider/app-language";
import { usePageRoute } from "provider/page-route";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetVehiclesBroken } from "services/status-queue-car";

const BrokenCarDetailPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { getCurrentParams } = usePageRoute();
  const { id } = getCurrentParams();
  const { translationObject } = useAppLanguage();

  const { isFetching, data } = useGetVehiclesBroken(id);

  useEffect(() => {
    initailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initailData = () => {
    if (!data) return;

    const { userApprove, description, vehicle } = data;
    const { receivers, supplierAndVisitors } =
      vehicle?.customerEmployeeDetail || {};

    //staff
    const nameSt = translationObject({
      object: userApprove,
      keys: ["firstName", "lastName"],
    });

    form.setFieldsValue({
      ...vehicle?.customerEmployeeDetail,
      ...receivers?.[0],
      ...supplierAndVisitors?.[0],
      description,
      firstNameSt: nameSt.firstName,
      lastNameSt: nameSt.lastName,
      phoneNumberSt: userApprove?.phoneNumber,
    });
  };

  const wIdCardType = Form.useWatch("identityCodeType", form);
  const label = wIdCardType
    ? wIdCardType === "ID_CARD"
      ? t("identification-card")
      : t("other")
    : " ";

  return (
    <PageContent
      loading={isFetching && !!id}
      breadcrumb={{
        form,
        mutate: {
          onSave: false,
          onSaveAndContinue: false,
        },
      }}
      className="p-5"
    >
      <Container height="100%" padding="p-0">
        <div className="p-5">
          <Typography.Title level={5} className="!text-primary-dark">
            {t("details")}
          </Typography.Title>
        </div>
        <Divider className="!m-0" />
        <div className="p-5">
          <Form layout="vertical" form={form}>
            <Row gutter={24}>
              <DividerInformation label={label} wIdCardType={wIdCardType} />
              <VehicleInformation />
              <StaffInformation />
            </Row>
          </Form>
        </div>
      </Container>
    </PageContent>
  );
};

interface IDriverProps {
  label: string;
  wIdCardType: boolean;
}

const DividerInformation: React.FC<IDriverProps> = ({ label, wIdCardType }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={24} className="mb-4">
        <Typography.Text className="!text-primary-dark">
          {t("driver-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="firstName"
          label={`${t("first-name")}`}
        >
          <CInput placeholder={`${t("first-name")}`} disabled />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="lastName"
          label={`${t("last-name")}`}
        >
          <CInput placeholder={`${t("last-name")}`} disabled />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="phoneNumber"
          label={t("phone-number")}
        >
          <CInput placeholder={t("phone-number")} disabled />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("gender")} name="gender">
          <CSelect.Gender disabled />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="numberOfPerson" label={t("amount")}>
          <CInput.Number
            min={0}
            placeholder={t("amount")}
            addonAfter={t("people")}
            precision={0}
            disabled
          />
        </Form.Item>
      </Col>
      <Col span={8}></Col>
      <Col span={5}>
        <Form.Item
          name="identityCodeType"
          label={t("card-number")}
          rules={[{ required: true }]}
        >
          <CRadio.Group
            optionType="default"
            options={[
              { label: t("identification-card"), value: "ID_CARD" },
              { label: t("other"), value: "OTHER" },
            ]}
            disabled
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: wIdCardType }]}
          name="identityCode"
          label={label}
        >
          <CInput disabled />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const VehicleInformation = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={24} className="mb-2">
        <Typography.Text className="!text-primary-dark">
          {t("vehicle-information")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item label={t("vehicle-type")} name="vehicleType">
          <CSelect.VehicleType disabled />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("vehicle-brand")} name="vehicleBrand">
          <CSelect.VehicleBrand disabled />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label={t("car-weight")} name="weight">
          <CInput.Number placeholder={t("car-weight")} min={0} disabled />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label={t("trailer-weight")} name="trailerWeight">
          <CInput.Number placeholder={t("trailer-weight")} min={0} disabled />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={t("trailer-head-license-plate-number")}
          name="licensePlateNumber"
        >
          <CInput
            placeholder={t("trailer-head-license-plate-number")}
            disabled
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={t("trailer-tail-license-plate-number")}
          name="trailerLicensePlateNumber"
        >
          <CInput
            placeholder={t("trailer-tail-license-plate-number")}
            disabled
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label={t("plate-province")} name="provincePlate">
          <CSelect.PlateProvince disabled />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="description" label={t("note")}>
          <CInput.TextArea disabled rows={5} placeholder={t("note")} />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

const StaffInformation = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={24} className="mb-4">
        <Typography.Text className="!text-primary-dark">
          {t("staff")}
        </Typography.Text>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="firstNameSt"
          label={`${t("first-name")}`}
        >
          <CInput placeholder={`${t("first-name")}`} disabled />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="lastNameSt"
          label={`${t("last-name")}`}
        >
          <CInput placeholder={`${t("last-name")}`} disabled />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          rules={[{ required: true }]}
          name="phoneNumberSt"
          label={t("phone-number")}
        >
          <CInput placeholder={t("phone-number")} disabled />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default BrokenCarDetailPage;
