import { createContext, ReactNode, useContext, useState } from "react";
import { ICameraGroup } from "../../services/camera-group/interface";
import { AllVideoSelectValue, ICameraGroupContext } from "./interface";

const Context = createContext<ICameraGroupContext>({} as ICameraGroupContext);

const CameraGroupProvider = ({ children }: { children: ReactNode }) => {
  const [getCameraGroups, setCameraGroups] = useState<ICameraGroup[]>([]);
  const [getContent, setContent] = useState<"all" | "playback">("all");
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [jsDecoder, setJsDecoder] = useState<any>();
  const [jsDecoderPlayback, setJsDecoderPlayback] = useState<any>();

  const [selected, setSelected] = useState<AllVideoSelectValue>();

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <Context.Provider
      value={{
        visible,
        toggle,
        getContent,
        setContent,
        selected,
        setSelected,
        jsDecoder,
        setJsDecoder,
        fullScreen,
        setFullScreen,
        getCameraGroups,
        setCameraGroups,
        jsDecoderPlayback,
        setJsDecoderPlayback,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export const useCameraGroup = () => useContext(Context);
export default CameraGroupProvider;
