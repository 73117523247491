import { useQueryClient } from "@tanstack/react-query";
import { Popconfirm } from "antd";
import CButtonIcon from "components/button/c-button-icon";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { onDeleteType } from ".";

const TableActionDeleteButton: FC<{
  onDelete?: onDeleteType;
  record: any;
  shouldGoBackAfterDelete: boolean;
}> = ({ onDelete, record, shouldGoBackAfterDelete }) => {
  const { t } = useTranslation();

  const { setAppLoading } = useAppLoading();
  const { pageKey } = usePageRoute();
  const qClient = useQueryClient();

  const onRowDelete = (rc: any) => {
    if (!onDelete) return;
    if (typeof onDelete === "function") {
      return onDelete(rc);
    }
    const {
      key,
      mutation,
      invalidateQueries = [],
      onLastItemInPage,
    } = onDelete;

    const id = rc[key || "id"];
    setAppLoading(true);

    return mutation?.mutate(
      { id },
      {
        onSuccess: () => {
          fireNotification({ type: "success", menu: pageKey });
          invalidateQueries.forEach((e) => qClient.invalidateQueries([e]));
          if (shouldGoBackAfterDelete) {
            onLastItemInPage?.();
          }
        },
        onError: ({ message }: any) => {
          fireNotification({
            type: "error",
            description: message,
            menu: pageKey,
          });
        },
        onSettled: () => {
          setAppLoading(false);
        },
      }
    );
  };

  if (!onDelete) return null;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popconfirm
        placement="topLeft"
        title={t("this-action-is-irreverible", { ns: "message" })}
        // okButtonProps={{ loading }}
        onConfirm={() => onRowDelete(record)}
      >
        <CButtonIcon.Delete />
      </Popconfirm>
    </div>
  );
};

export default TableActionDeleteButton;
