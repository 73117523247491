import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import dayjs from "dayjs";
import { useAppLanguage } from "provider/app-language";
import { FC } from "react";
import { StyledRangePicker } from ".";

// export type CRangePickerByObjectKeyProps = {
//   value?: Record<string, dayjs.Dayjs>;
//   onChange?: (v: Record<string, dayjs.Dayjs>) => void;
//   startKey: string;
//   endKey: string;
// } & Omit<CRangePickerProps, "value" | "onChange">;

const CRangePickerByObjectKey: FC<
  Omit<RangePickerProps<dayjs.Dayjs>, "value" | "onChange"> & {
    startKey: string;
    endKey: string;
    value?: any;
    onChange?: any;
    showTime?: boolean;
  }
> = ({ onChange, value, startKey, endKey, ...props }) => {
  const { lang } = useAppLanguage();

  const format: { en: string; th: string } = {
    en: "DD MMM YYYY",
    th: "DD MMM BBBB",
  };

  const formatTime: { en: string; th: string } = {
    en: "DD MMM YYYY HH:mm:ss",
    th: "DD MMM BBBB HH:mm:ss",
  };

  const getValues = () => {
    if (!value) return;
    return [value[startKey], value[endKey]] as any;
  };

  const _onChange = (value: any) => {
    if (!value) return onChange?.({});
    const _v = value as dayjs.Dayjs[];
    onChange?.({ [startKey]: _v[0].startOf("d"), [endKey]: _v[1].endOf("d") });
  };

  return (
    <StyledRangePicker
      format={props.showTime ? formatTime[lang] : format[lang]}
      onChange={_onChange}
      value={getValues()}
      {...props}
    />
  );
};

export default CRangePickerByObjectKey;
