import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IContractor } from "services/contractor/interface";
import { IVisitor } from "services/visitor/interface";
import { IReqApproveParams } from "./interface";
import { IReceiver } from "services/receiver/interface";
import { ISupplier } from "services/supplier/interface";

export const useGetReqVisitor = (
  params?: IReqApproveParams
): UseQueryResult<IResponse<IVisitor[]>> => {
  return useQuery(
    ["get-req-visitor", params],
    async () => {
      const res = await axios.get("/req-approve-customer-employee/visitor", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetReqContarctor = (
  params?: IReqApproveParams
): UseQueryResult<IResponse<IContractor[]>> => {
  return useQuery(
    ["get-req-contractor", params],
    async () => {
      const res = await axios.get("/req-approve-customer-employee/contractor", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetReqReceiver = (
  params?: IReqApproveParams
): UseQueryResult<IResponse<IReceiver[]>> => {
  return useQuery(
    ["get-req-receiver", params],
    async () => {
      const res = await axios.get("/req-approve-customer-employee/receiver", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const useGetReqSupplier = (
  params?: IReqApproveParams
): UseQueryResult<IResponse<ISupplier[]>> => {
  return useQuery(
    ["get-req-supplier", params],
    async () => {
      const res = await axios.get("/req-approve-customer-employee/supplier", {
        params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled ?? true }
  );
};

export const usePatchRejectUser = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(
      `/req-approve-customer-employee/reject`,
      data
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchAcceptUser = (): UseMutationResult => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(
      `/req-approve-customer-employee/accept`,
      data
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
