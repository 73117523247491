import { Select } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetOrganizations } from "services/user-company";
import styled from "styled-components";
import tw from "twin.macro";
import { CSelectProps } from ".";

const CustomCSelectStyle = styled(Select)`
  height: 45px;
  width: 100%;
  input {
    font-family: FCIconic !important;
  }
  .ant-select-selector {
    height: 100% !important;
    ${tw`!rounded-app`};
  }
  .ant-select-selection-placeholder {
    margin: auto;
  }

  .ant-select-selection-item {
    margin: auto;
    background: none !important;
    border: none !important;
    .ant-select-selection-item-remove {
      display: none !important;
    }
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
`;

const CSelectCompanyName: FC<CSelectProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [dataSelect, setDataSelect] = useState<string[]>(value || []);
  // const { t } = useTranslation();
  const { data, isFetching } = useGetOrganizations({ pagination: false });

  useEffect(() => {
    setDataSelect(value);
  }, [value]);

  return (
    <CustomCSelectStyle
      getPopupContainer={(e) => e}
      loading={isFetching}
      {...props}
      onChange={(values: any, options) => {
        if (options?.length === 0 || options?.length === 1) {
          setDataSelect(values);
          onChange?.(values?.[0], options);
        }
      }}
      // placeholder={t("company-name")}
      mode="tags"
      value={dataSelect}
    >
      {data?.data.map((item) => {
        return (
          <CustomCSelectStyle.Option key={item.id} value={item.id}>
            {item.name}
          </CustomCSelectStyle.Option>
        );
      })}
    </CustomCSelectStyle>
  );
};

export default CSelectCompanyName;
