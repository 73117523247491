import { postMediaObject } from "services/media-object";
import { throwResponse } from "config/axios";
import { IVehicleCompany, IVehicleCompanyParams } from "./inteface";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { IResponse } from "config/axios/interface";
import axios from "axios";

export const useGetVehicleCompanys = (
  params?: IVehicleCompanyParams
): UseQueryResult<IResponse<IVehicleCompany[]>, Error> => {
  return useQuery(
    ["vehicle-company", "vehicle", "get-all", params],
    async () => {
      const res = await axios.get(`/vehicle-company`, { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { enabled: params?.enabled, keepPreviousData: true }
  );
};

export const useGetVehicleCompany = (
  id?: string | number
): UseQueryResult<IVehicleCompany, Error> => {
  return useQuery(
    ["vehicle-company", "vehicle", "get-one", id],
    async () => {
      const res = await axios.get(`/vehicle-company/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostVehicleCompany = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ vehicleImage, ...data }: any) => {
    if (typeof vehicleImage === "object") {
      const id = await postMediaObject(vehicleImage);
      data = { ...data, vehicleImage: id };
    }
    const res = await axios.post(`/vehicle-company`, {
      ...data,
      vehicleType: "VEHICLE",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchVehicleCompany = (): UseMutationResult<unknown, Error> => {
  return useMutation(async ({ id, vehicleImage, ...data }: any) => {
    // if ('size' in vehicleImage) {
    //   const id = await postMediaObject(vehicleImage);
    //   data = { ...data, vehicleImage: id };
    // }
    const res = await axios.patch(`/vehicle-company/${id}`, {
      ...data,
      vehicleType: "VEHICLE",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteVehicleCompany = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/vehicle-company/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteVehicleCompanys = (): UseMutationResult<
  unknown,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.delete(`/vehicle-company/bulk`, { data });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useExportExcelVehicleCompany = (): UseMutationResult<
  Blob,
  Error
> => {
  return useMutation(async (data) => {
    const res = await axios.post(
      "/export-excel/vehicle/company-export-excel",
      data,
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
