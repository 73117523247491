export type ButtonThemeTypes =
  | "primary"
  | "danger"
  | "white"
  | "gray"
  | "yellow"
  | "orange";

type ThemeTypes = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  border?: string;
  icon?: string;
};

export const BUTTON_THEME: {
  [K in ButtonThemeTypes]: ThemeTypes;
} = {
  primary: {
    bg: "#2F9A5D",
    color: "white",
    hoverBg: "#2a8952",
    icon: "white"
  },
  danger: {
    bg: "#fc0002",
    color: "#ffffff",
    hoverBg: "",
  },
  white: {
    bg: "#ffffff",
    color: "#48B074",
    border: "#EBEBEB",
    hoverBg: "#F5F5F5",
    icon: '#2F9A5D'
  },
  gray: {
    bg: "#F5F5F5",
    color: "#468A63",
    hoverBg: "#EBEBEB",
    icon: '#c4c4c4'
  },
  yellow: {
    bg: "#FAEC08",
    color: "#000000",
    hoverBg: "#e8d909",
  },
  orange:{
    bg: "#FE9200",
    color: "#FFFFFF",
    hoverBg: "#F7751F",
  }
};
