import { ComponentType, createContext, useContext, useState } from "react";
import { IMainMenuContext } from "./interface";

const MainMenuContext = createContext<IMainMenuContext>({} as IMainMenuContext);

const withMainMenu = (Component: ComponentType) => {
  return () => {
    const [visible, setVisible] = useState(false);

    const toggle = () => {
      setVisible(!visible);
    };

    return (
      <MainMenuContext.Provider value={{ visible, toggleMenu: toggle }}>
        <Component />
      </MainMenuContext.Provider>
    );
  };
};

export const useMainMenu = () => useContext(MainMenuContext);
export default withMainMenu;
