import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import CTable from "components/display/c-table";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import { useAppLanguage } from "provider/app-language";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useExportVehiclesBroken,
  useGetVehiclesBrokens,
} from "services/status-queue-car";
import {
  IVehicleBroken,
  IVehicleBrokenParams,
} from "services/status-queue-car/interface";

const BrokenCarPage = () => {
  const { t } = useTranslation();
  const { formatDate, translationObject } = useAppLanguage();

  const [params, setParams] = useState<IVehicleBrokenParams>({
    page: 1,
    limit: 10,
  });
  const { data, isFetching } = useGetVehiclesBrokens(params);
  const export_vehicle = useExportVehiclesBroken();

  const elements: ISearchBoxElement[] = [
    {
      name: "date",
      label: t("date-transaction"),
      input: {
        type: "RangePickerByKey",
        options: {
          startKey: "startDate",
          endKey: "endDate",
        },
      },
    },
    {
      name: "fullname",
      label: t("full-name"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "phoneNumber",
      label: t("phone-number"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "licensePlate",
      label: t("license-plate"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "productGroups",
      label: t("material-type"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      name: "userApproveName",
      label: t("staff"),
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
  ];

  const columns: ColumnsType<IVehicleBroken> = [
    {
      title: t("date"),
      render: (_, rc) => {
        const { createdAt } = rc;
        const date = formatDate({
          date: createdAt,
          format: "normal-time",
          keySeparator: "/",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      title: t("full-name"),
      render: (_, rc) => {
        const { firstName, lastName } =
          rc.vehicle?.customerEmployeeDetail || {};
        if (!firstName && !lastName) return <span>-</span>;
        const name = `${firstName} ${lastName}`;
        return (
          <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
        );
      },
    },
    {
      title: t("phone-number"),
      render: (_, rc) => {
        const { phoneNumber } = rc.vehicle?.customerEmployeeDetail || {};
        return (
          <Typography.Text ellipsis={{ tooltip: phoneNumber || "-" }}>
            {phoneNumber || "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: t("license-plate"),
      render: (_, rc) => {
        let licensePlate = rc.vehicle?.licensePlate || "-";

        return (
          <Typography.Text ellipsis={{ tooltip: licensePlate }}>
            {licensePlate}
          </Typography.Text>
        );
      },
    },
    {
      title: t("material-type"),
      render: (_, rc) => {
        let productGroup = "-";
        const { receivers, supplierAndVisitors, userType } =
          rc.vehicle?.customerEmployeeDetail || {};
        switch (userType) {
          case "RECEIVER":
            productGroup = receivers?.[0]?.productGroups || "-";
            break;
          case "SUPPLIER":
            const supplierJobTypes =
              supplierAndVisitors?.[0]?.supplierJobTypes?.map((e) => {
                const { title } = translationObject({
                  object: e.supplierType,
                  keys: ["title"],
                });
                return title;
              });
            productGroup =
              (supplierAndVisitors?.[0]?.supplierJobTypes
                ? supplierJobTypes?.toString()
                : "-") || "-";
            break;
        }
        return (
          <Typography.Text ellipsis={{ tooltip: productGroup }}>
            {productGroup}
          </Typography.Text>
        );
      },
    },
    {
      title: t("staff"),
      render: (_, rc) => {
        const { firstName, lastName } = translationObject({
          object: rc.userApprove,
          keys: ["firstName", "lastName"],
        });
        const name = firstName && lastName ? `${firstName} ${lastName}` : "-";
        return (
          <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
        );
      },
    },
    {
      title: t("note"),
      render: (_, rc) => {
        return (
          <Typography.Text ellipsis={{ tooltip: rc?.description || "-" }}>
            {rc?.description || "-"}
          </Typography.Text>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    const date = values.date;
    delete values.date;
    setParams({
      ...params,
      ...values,
      startDate: undefined,
      endDate: undefined,
      ...date,
      page: 1,
    });
  };

  return (
    <PageContent breadcrumb className="p-5">
      <PageSearchBox elements={elements} onFinish={onSearch} />
      <CTable
        scroll={{ x: 1200 }}
        loading={isFetching}
        selectAble={false}
        title={t("broken-car-lis")}
        onRowClick="vehicle-inspection.broken-car-detail"
        action={{
          excels: {
            export: {
              fileName: `${t("broken-car-lis")}`,
              mutation: export_vehicle,
            },
          },
        }}
        columns={columns}
        dataSource={data?.data || []}
        pagination={{
          total: data?.count,
          current: params?.page,
          pageSize: params.limit,
          onChange: (page, limit) => {
            setParams({ ...params, page, limit });
          },
        }}
      />
    </PageContent>
  );
};

export default BrokenCarPage;
