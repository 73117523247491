import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ITermsCondition } from "services/terms-and-condition/interface";
import { IPrivacyPolicy, IPrivacyPolicyParams } from "./interface";

export const useGetPrivacyPolicy = (
  params?: IPrivacyPolicyParams
): UseQueryResult<IResponse<IPrivacyPolicy[]>, Error> => {
  return useQuery(
    ["get-privacy-policy", params],
    async () => {
      const res = await axios.get("/privacy-policy");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePatchPrivacyPolicy = (): UseMutationResult<
  IResponse<ITermsCondition[]>
> => {
  return useMutation(
    ["patch-privacy-policy"],
    async ({ id, ...values }: any) => {
      const res = await axios.patch(`/privacy-policy/${id}`, values);
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    }
  );
};
