import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ISettingParams, ISettingPDPA } from "./interface";

export const usePatchPDPA = (): UseMutationResult<IResponse<ISettingPDPA[]>> => {
  return useMutation(["patch-setting-pdpa"], async ({id,...values }: any) => {
    const res = await axios.patch(`/pdpa/${id}`,values);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useGetPDPA = (
  params?: ISettingParams
): UseQueryResult<IResponse<ISettingPDPA[]>, Error> => {
  return useQuery(
    ["get-setting-pdpa", params],
    async () => {
      const res = await axios.get("/pdpa");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};
