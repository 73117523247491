import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryResult,
  } from "@tanstack/react-query";
  import axios from "axios";
  import { throwResponse } from "config/axios";
  import { IResponse } from "config/axios/interface";
  import { ISettingParams, ISettingParkingTime } from "./interface";
  
  export const usePatchParkingTime = (): UseMutationResult<IResponse<ISettingParkingTime[]>> => {
    return useMutation(["patch-setting-parking-time"], async ({id,...values }: any) => {
      const res = await axios.patch(`/parking-time/${id}`,values);
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    });
  };
  
  export const useGetParkingTime = (
    params?: ISettingParams
  ): UseQueryResult<IResponse<ISettingParkingTime[]>, Error> => {
    return useQuery(
      ["get-setting-parking-time", params],
      async () => {
        const res = await axios.get("/parking-time");
        if (res.status === 200) {
          return res.data;
        }
        throwResponse(res);
      },
      {
        keepPreviousData: true,
      }
    );
  };
  