import { useTranslation } from "react-i18next";
import Icon, { FileFilled } from "@ant-design/icons";
import { AddMapIcon, BinIcon } from "assets/icons/general";
import { Divider, Typography } from "antd";
import CButton from "components/button/c-button";
import { useVehicleFloorEdit } from "provider/vehicle-floor-edit";
import { useRef } from "react";
import FilePicker, { FilePickerRef } from "components/input/file-picker";
import CImage from "components/display/c-image";
import CSpace from "components/display/c-space";
import CButtonIcon from "components/button/c-button-icon";

const FloorEditStep2 = () => {
  const ref = useRef<FilePickerRef>(null);
  const { t } = useTranslation();
  const { floor, onUploadImage, onDeleteImage } = useVehicleFloorEdit();

  if (floor.mediaObject) {
    return (
      <div style={{ height: 500 }}>
        <FilePicker ref={ref} onChange={onUploadImage} />
        <CSpace className="px-4 py-3">
          <CButtonIcon
            icon={<FileFilled />}
            onClick={() => {
              ref.current?.click();
            }}
          />
          <CButtonIcon icon={<BinIcon />} onClick={onDeleteImage} />
        </CSpace>
        <Divider className="!m-0" />
        <div className="center" style={{ height: "calc(100% - 67px)" }}>
          <CImage
            className="cursor-pointer"
            onClick={() => {
              ref.current?.click();
            }}
            // height="100%"
            width="auto"
            src={floor.mediaObject.url}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ height: 500 }} className="center flex-col">
      <FilePicker ref={ref} onChange={onUploadImage} />
      <Icon component={AddMapIcon} style={{ fontSize: 80 }} />
      <Typography.Text>
        {t("the-map-for-the-layer-not-config", { ns: "message" })}
      </Typography.Text>
      <CButton
        style={{ height: 35 }}
        className="mt-4"
        onClick={() => {
          ref.current?.click();
        }}
      >
        + {t("add-map")}
      </CButton>
    </div>
  );
};

export default FloorEditStep2;
