import { Input } from "antd";
import { PasswordProps } from "antd/lib/input";
import { FC } from "react";
import styled from "styled-components";

const StyledPassword = styled(Input.Password)`
  height: 45px;
`;

const CPassword: FC<PasswordProps> = (props) => {
  return <StyledPassword {...props} />;
};

export default CPassword;
