import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ErrorPageProps {
  error?: Error | null;
  height?: string | number;
}

const ErrorPage: FC<ErrorPageProps> = ({ error, height = "100%" }) => {
  const { t } = useTranslation("message");
  return (
    <div
      className="w-full center"
      style={{ flexDirection: "column", height: height }}
    >
      <span>{t("error-occurred")}</span>
      <span>{error?.message}</span>
    </div>
  );
};

export default ErrorPage;
