import { Col, Form, Row, Typography } from "antd";
import CRangePicker from "components/input/c-range-picker";
import PageContent from "components/layout/page-content";
import PageSearchBox, {
  ISearchBoxElement,
} from "components/layout/page-search-box";
import useDebounce from "hooks/useDebounce";
import { usePageRoute } from "provider/page-route";
import withVehicleInspectionProvider, {
  useVehicleInspection,
} from "provider/vehicle-inspection";
import VehicleInspectionSocketProvider from "provider/vehicle-inspection-socket";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { splitRangePicker } from "tools/utils";
import VehicleInspectionContent from "./content";

const VehicleInspectionInspectionPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { getCurrentKey } = usePageRoute();
  const { params, setParams } = useVehicleInspection();

  const watchSearch = Form.useWatch("date", form);
  const devName = useDebounce(watchSearch, 500);

  useEffect(() => {
    const date = splitRangePicker({
      arrays: devName,
      startKey: "startDate",
      endKey: "endDate",
    });

    setParams?.({ ...params, ...date });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devName]);

  const elements: ISearchBoxElement[] = [
    {
      label: t("full-name"),
      name: "name",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("phone-number"),
      name: "phoneNumber",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("license-plate-number"),
      name: "licensePlate",
      input: {
        type: "Input",
        options: {
          search: true,
        },
      },
    },
    {
      label: t("person-type"),
      name: "userType",
      input: {
        type: "SelectUserType",
      },
    },
    {
      label: t("company-name"),
      name: "companyName",
      input: {
        type: "Input",
        options: { search: true },
      },
    },
    {
      label: t("product-group"),
      name: "productGroups",
      input: {
        type: "Input",
        options: { search: true },
      },
    },
  ];

  const onSearch = (values: any) => {
    setParams?.({ ...params, ...values });
  };

  const pageKey = getCurrentKey();

  return (
    <VehicleInspectionSocketProvider>
      <PageContent>
        <Form
          layout="vertical"
          className="!p-5"
          onFinish={onSearch}
          form={form}
        >
          <Row align="middle" gutter={12}>
            <Col>
              <Typography.Title level={3} className="!text-primary-dark">
                {t(pageKey || "", { ns: "menu" })}
              </Typography.Title>
            </Col>
            <Col span={6} className="ml-auto">
              <Form.Item
                name="date"
                className="!mb-0"
                label={t("registration-date")}
              >
                <CRangePicker />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label=" " className="!mb-0">
                <PageSearchBox.WithPopover
                  onFinish={onSearch}
                  elements={elements}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <VehicleInspectionContent />
      </PageContent>
    </VehicleInspectionSocketProvider>
  );
};

export default withVehicleInspectionProvider(VehicleInspectionInspectionPage);
