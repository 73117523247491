import AppNavigatorBar from "components/layout/app-navigator";
import HomePage from "pages/home";
import { useNavigator } from "provider/navigator";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Content from "./content";

type TEventType =
  | string
  | React.MouseEvent<Element, MouseEvent>
  | React.KeyboardEvent<Element>;

const Navigator = () => {
  const { t } = useTranslation("menu");
  const { pathname } = useLocation();
  const { activeKey, onGoToTab, onRemoveTab, navigators } = useNavigator();

  const onTabClick = (key: string) => {
    onGoToTab(key);
  };

  const onTabEdit = (e: TEventType, action: "add" | "remove") => {
    if (action !== "remove" || typeof e !== "string") return;
    onRemoveTab(e);
  };

  if (pathname === "/" || pathname === "/home") {
    return <HomePage />;
  }

  const closable = navigators.length > 1;

  return (
    <AppNavigatorBar
      hideAdd
      type="editable-card"
      onChange={onTabClick}
      activeKey={activeKey}
      onEdit={onTabEdit}
    >
      {navigators.map((item) => {
        return (
          <AppNavigatorBar.TabPane
            key={item.key}
            tab={t(item.title)}
            closable={closable}
          >
            <Content id={item.key}>{React.createElement(item.element)}</Content>
          </AppNavigatorBar.TabPane>
        );
      })}
    </AppNavigatorBar>
  );
};

export default Navigator;
