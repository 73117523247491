import { IUserType } from "pages/user-management/group-of-persons/user-type/button-user-type";
import { ComponentType, createContext, useContext, useState } from "react";
import { AccessibilityDeterminedByGroupOfPersonsContext } from "./interface";

const Context = createContext<AccessibilityDeterminedByGroupOfPersonsContext>(
  {} as AccessibilityDeterminedByGroupOfPersonsContext
);

const withAccessibilityDeterminedGroupOfPersons = (Component: ComponentType) => {
  return () => {
    const [accessibility, setAccessibility] = useState();
    const [userGroup, setUserGroup] = useState();
    const [userType, setUserType] = useState<IUserType>({
      title: "user-management.guest",
      userType: "VISITOR",
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [action, setAction] = useState<"add" | "edit">("add");

    const toggle = () => {
      setVisible(!visible);
    };

    return (
      <Context.Provider
        value={{
          accessibility,
          userType,
          setUserType,
          setAccessibility,
          userGroup,
          setUserGroup,
          visible,
          toggle,
          action,
          setAction
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useAccessibilityDeterminedGroupOfPersons = () => useContext(Context);
export default withAccessibilityDeterminedGroupOfPersons;
