import { Row, Col, Typography, Steps } from "antd";
import {
  StatusBright,
  StatusBuleLight,
  StatusGreen,
  StatusGreenLight,
  StatusLightBlue,
  StatusOrange,
  StatusPurple,
  StatusYellow,
} from "assets/images";
import CImage from "components/display/c-image";
import { useAppLanguage } from "provider/app-language";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { IVehicleQueueLog } from "services/status-queue-car/interface";
import styled from "styled-components";

const { Step } = Steps;

interface IHistoryDetail {
  title?: string;
  description?: string;
  urlProfile?: string;
  licensePlateImage?: string;
  QRCodeImage?: string;
  time?: string;
  status: boolean;
  keyHistory: KeyHistory;
  icon: JSX.Element;
}

type KeyHistory =
  | "register"
  | "detect"
  | "spray"
  | "quicklab"
  | "queue"
  | "delivery"
  | "weighing"
  | "waiting_to_load"
  | "start_loading"
  | "finish_loading"
  | "weighed_out"
  | "out";

const LogStatusCar = ({ data }: { data?: IVehicleQueueLog }) => {
  const { t } = useTranslation();
  const listData = findHistory({ data, t });
  return (
    <div style={{ overflowY: "auto", height: "calc(100vh - 285px)" }}>
      <Row className="px-5">
        <Steps current={listData?.length} direction="vertical">
          {listData?.map((item) => {
            if (!item.status) return false;
            return (
              <Step
                key={item.title}
                icon={item.icon}
                description={<CardStatusHistory item={item} />}
              />
            );
          })}
        </Steps>
      </Row>
    </div>
  );
};

const DivStyle = styled.div`
  height: 150px;
  width: 150px;
  padding: 10px;
  background-color: #f2f2f2;
`;

const CardStatusHistory = ({ item }: { item: IHistoryDetail }) => {
  const { t } = useTranslation();
  const { formatDate } = useAppLanguage();
  const {
    title,
    description,
    time,
    urlProfile,
    licensePlateImage,
    QRCodeImage,
  } = item;
  const date = time ? formatDate({ date: time, format: "full-time" }) : "";
  return (
    <React.Fragment>
      <Row
        className="p-4 my-2 rounded-app"
        gutter={[6, 6]}
        style={{ backgroundColor: "white" }}
      >
        <Col span={12}>
          <Typography.Text style={{ color: "#716C6C" }}>
            {title ? t(title) : ""}
          </Typography.Text>
        </Col>
        <Col span={12} className="text-end">
          <Typography.Text style={{ color: "#716C6C" }}>{date}</Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text strong>{description}</Typography.Text>
        </Col>
        <Col>
          {urlProfile ? (
            <DivStyle className="rounded-app">
              <CImage src={urlProfile} style={{ objectFit: "contain" }} />
            </DivStyle>
          ) : (
            <></>
          )}
        </Col>
        <Col>
          {licensePlateImage ? (
            <DivStyle className="rounded-app">
              <CImage
                src={licensePlateImage}
                style={{ objectFit: "contain" }}
              />
            </DivStyle>
          ) : (
            <></>
          )}
        </Col>
        <Col>
          {QRCodeImage ? (
            <DivStyle className="rounded-app">
              <CImage src={QRCodeImage} style={{ objectFit: "contain" }} />
            </DivStyle>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const findHistory = ({
  t,
  data,
}: {
  t: TFunction<"translation", undefined>;
  data?: IVehicleQueueLog;
}): IHistoryDetail[] => {
  const { registerPDPADate, sprayStatus, detect, checkTheSpray, quicklabs } =
    data || {};

  const sprayStatusText = sprayStatus
    ? `${t("spray-liquid")}`
    : `${t("do-not-spray-liquid")}`;

  const quicklabsStatus = quicklabs?.status
    ? quicklabs?.status === "PASSED_THE_INSPECTION" ||
      quicklabs?.status === "VIP"
      ? `${t("pass")}`
      : `${t("not-pass")}`
    : "-";

  const dataHistory: IHistoryDetail[] = [
    {
      title: "the-car-has-successfully-left-the-TVOP",
      description: "Car Gate4-Out",
      status: false,
      keyHistory: "out",
      icon: <CImage width="30px" height="30px" src={StatusGreenLight} />,
    },
    {
      title: "weighed-out",
      status: false,
      keyHistory: "weighed_out",
      icon: <CImage width="30px" height="30px" src={StatusBuleLight} />,
    },
    {
      title: "finish-loading-release",
      description: "1000 kg.",
      status: false,
      keyHistory: "finish_loading",
      icon: <CImage width="30px" height="30px" src={StatusYellow} />,
    },
    {
      title: "start-loading-release",
      description: "ผ่าน",
      status: false,
      keyHistory: "start_loading",
      icon: <CImage width="30px" height="30px" src={StatusOrange} />,
    },
    {
      title: "wait-for-load-release",
      description: "ผ่าน",
      status: false,
      keyHistory: "waiting_to_load",
      icon: <CImage width="30px" height="30px" src={StatusBright} />,
    },
    {
      title: "waiting-for-the-weighing-queue-in",
      description: "ผ่าน",
      status: false,
      keyHistory: "weighing",
      icon: <CImage width="30px" height="30px" src={StatusLightBlue} />,
    },
    {
      title: "receive-deliver",
      description: "ผ่าน",
      status: false,
      keyHistory: "delivery",
      icon: <CImage width="30px" height="30px" src={StatusGreen} />,
    },
    {
      title: "queue-to-product",
      description: "ผ่าน",
      status: false,
      keyHistory: "queue",
      icon: <CImage width="30px" height="30px" src={StatusGreen} />,
    },
    {
      title: "check-quicklab",
      description: quicklabsStatus,
      status: !!quicklabs,
      time: quicklabs?.createdAt,
      keyHistory: "quicklab",
      icon: <CImage width="30px" height="30px" src={StatusPurple} />,
    },
    {
      title: "types-of-vehicles-sprayed",
      description: sprayStatusText,
      time: checkTheSpray,
      status: !!checkTheSpray && !!sprayStatus,
      keyHistory: "spray",
      icon: <CImage width="30px" height="30px" src={StatusGreen} />,
    },
    {
      title: "capture-license-plate-person-qrcode",
      description: `${t("able-to-pass-through-the-area")}`,
      licensePlateImage: detect?.licensePlateImage?.url,
      time: detect?.createdAt,
      status: !!detect,
      keyHistory: "detect",
      icon: <CImage width="30px" height="30px" src={StatusGreen} />,
    },
    {
      title: "register",
      description: `${t("pre-register")}`,
      time: registerPDPADate,
      status: !!registerPDPADate,
      keyHistory: "register",
      icon: <CImage width="30px" height="30px" src={StatusGreen} />,
    },
  ];
  return dataHistory;
};

export default LogStatusCar;
