import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import CButton from "components/button/c-button";
import CSpace from "components/display/c-space";
import { useAppLanguage } from "provider/app-language";
import { useSettingSchedule } from "provider/setting-schedule";
import { SettingScheduleTypes } from "provider/setting-schedule/interface";
import { useTranslation } from "react-i18next";
import SettingScheduleMonthType from "./month-type";
import SettingScheduleYearType from "./year-type";

const SettingScheduleContent = () => {
  const { type } = useSettingSchedule();

  const getContentByType = () => {
    switch (type) {
      case "day":
        return <SettingScheduleMonthType />;
      case "month":
        return <SettingScheduleMonthType />;
      case "year":
        return <SettingScheduleYearType />;
    }
  };

  const content = getContentByType();

  return (
    <div className="bg-white p-5 rounded-app">
      <Header />
      {content}
    </div>
  );
};

const Header = () => {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <DateChanger />
      </Col>
      <Col>
        <DateTypeSelect />
      </Col>
    </Row>
  );
};

const DateChanger = () => {
  const { currentTime, type, setCurrentTime } = useSettingSchedule();
  const { formatDate } = useAppLanguage();

  const getTimeByType = () => {
    switch (type) {
      case "day":
        return formatDate({ date: currentTime.day, format: "normal" });
      case "month":
        return formatDate({ date: currentTime.month, format: "month-year" });
      case "year":
        return formatDate({ date: currentTime.year, format: "year" });
    }
  };

  const time = getTimeByType();

  const addTime = () => {
    const add = currentTime[type].add(1, type);
    setCurrentTime({ ...currentTime, [type]: add });
  };

  const reduceTime = () => {
    const reduce = currentTime[type].add(-1, type);
    setCurrentTime({...currentTime , [type]:reduce})
  };

  return (
    <CSpace size={24} align="center">
      <CButton
        style={{ width: 20, height: 20 }}
        icon={<LeftOutlined style={{ fontSize: 9 }} />}
        onClick={reduceTime}
      />
      <Typography.Title level={4}>{time}</Typography.Title>
      <CButton
        style={{ width: 20, height: 20 }}
        icon={<RightOutlined style={{ fontSize: 9 }} />}
        onClick={addTime}
      />
    </CSpace>
  );
};

const dateType: SettingScheduleTypes[] = ["month", "year"];

const DateTypeSelect = () => {
  const { type, setType } = useSettingSchedule();
  const { t } = useTranslation();
  return (
    <CSpace>
      {dateType.map((item) => {
        return (
          <CButton
            key={item}
            onClick={() => setType(item)}
            style={{ width: 70, height: 35 }}
            theme={type === item ? "primary" : "white"}
          >
            {t(item)}
          </CButton>
        );
      })}
    </CSpace>
  );
};

export default SettingScheduleContent;
