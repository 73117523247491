import { EditFilled, PlusSquareFilled } from "@ant-design/icons";
import { Col, Form, Row, TableColumnsType, Typography } from "antd";
import CButtonIcon from "components/button/c-button-icon";
import CImage from "components/display/c-image";
import CModal from "components/display/c-modal";
import CModalFooter from "components/display/c-modal/modal-footer";
import CTable from "components/display/c-table";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import CUpload from "components/input/c-upload";
import { CUploadListProps } from "components/input/c-upload/list";
import { fireNotification } from "components/popup/notification";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface AddVehicleToUserFieldValue {
  licensePlateNumber: string;
  provincePlate: string;
  vehicleBrand?: string;
  vehicleModel?: string;
  vehicleImage?: CUploadListProps["value"];
}

interface AddVehicleToUserFieldProps {
  value?: AddVehicleToUserFieldValue[];
  onChange?: (v: AddVehicleToUserFieldValue[]) => void;
  disabled?: boolean;
}

const AddVehicleToUserField: FC<AddVehicleToUserFieldProps> = ({
  value = [],
  onChange,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<AddVehicleToUserFieldValue>();

  const toggle = () => {
    setVisible(!visible);
  };

  const columns: TableColumnsType<AddVehicleToUserFieldValue> = [
    {
      title: t("license-plate-number"),
      dataIndex: "licensePlateNumber",
      ellipsis: true,
    },
    {
      title: t("plate-province"),
      dataIndex: "provincePlate",
    },
    {
      title: t("vehicle-brand"),
      render: (_, rc) => {
        if (!rc.vehicleBrand) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: rc.vehicleBrand }}>
            {rc.vehicleBrand}
          </Typography.Text>
        );
      },
    },
    {
      title: t("vehicle-series"),
      render: (_, rc) => {
        if (!rc.vehicleModel) return "-";
        return (
          <Typography.Text ellipsis={{ tooltip: rc.vehicleModel }}>
            {rc.vehicleModel}
          </Typography.Text>
        );
      },
    },
    {
      title: t("vehicle-picture"),
      width: 200,
      render: (_, rc) => {
        if (!rc.vehicleImage || !rc.vehicleImage.length) return "-";
        const source = [...rc.vehicleImage].splice(0, 2);
        return (
          <Row gutter={6}>
            {source.map((item, index) => {
              return (
                <Col key={index}>
                  <CImage
                    height={50}
                    width={50}
                    src={"url" in item ? item.url : item}
                  />
                </Col>
              );
            })}
          </Row>
        );
      },
    },
    {
      title: t("table-action"),
      width: 120,
      render: (_, rc) => {
        return (
          <Row gutter={12}>
            <Col>
              <CButtonIcon
                theme="white"
                style={{ width: 35, height: 35 }}
                icon={<EditFilled />}
                onClick={() => onEdit(rc)}
                disabled={disabled}
              />
            </Col>
            <Col>
              <CButtonIcon.Delete
                onClick={() => onDelete(rc.licensePlateNumber)}
                disabled={disabled}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const onEdit = (value: AddVehicleToUserFieldValue) => {
    setSelected(value);
    toggle();
  };

  const onDelete = (licensePlateNumber: string) => {
    const next = value.filter(
      (e) => e.licensePlateNumber !== licensePlateNumber
    );
    onChange?.(next);
  };

  const onFinish = (values: any) => {
    const { licensePlateNumber } = values;
    if (selected) {
      const next = value.map((item) => {
        if (item.licensePlateNumber === licensePlateNumber) {
          return values;
        }
        return item;
      });
      return onChange?.(next);
    }
    if (value.some((e) => e.licensePlateNumber === licensePlateNumber)) {
      return fireNotification({
        type: "warning",
        message: t("duplicate-license-plate-number", { ns: "message" }),
      });
    }
    onChange?.([...value, values]);
  };

  const onReset = () => {
    setSelected(undefined);
  };

  return (
    <React.Fragment>
      <AddModal
        selected={selected}
        onFinish={onFinish}
        visible={visible}
        onClose={toggle}
        onReset={onReset}
      />
      <Row className="mt-3" align="middle">
        <Col>
          <Typography.Text className="!text-primary">
            {t("vehicle-information")}
          </Typography.Text>
        </Col>
        <Col className="ml-auto">
          <CButtonIcon
            onClick={toggle}
            theme="primary"
            icon={<PlusSquareFilled />}
            disabled={disabled}
          />
        </Col>
        <Col span={24}>
          <CTable
            rowKey="licensePlateNumber"
            className="mt-3"
            wrapper={false}
            dataSource={value}
            columns={columns}
            selectAble={false}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const AddModal: FC<{
  visible: boolean;
  onClose: () => void;
  onFinish: (v: any) => void;
  selected?: AddVehicleToUserFieldValue;
  onReset: () => void;
}> = ({ visible, onClose, onFinish, selected, onReset }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (selected) {
      form.setFieldsValue(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const afterClose = () => {
    form.resetFields();
    onReset();
  };

  return (
    <CModal
      afterClose={afterClose}
      footer={
        <CModalFooter
          cancel={{ onClick: onClose }}
          submit={{
            onClick: async () => {
              const values = await form.validateFields();
              onFinish(values);
              onClose();
            },
          }}
        />
      }
      width={700}
      visible={visible}
      onCancel={onClose}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item
              name="licensePlateNumber"
              label={t("license-plate-number")}
              rules={[{ required: true }]}
            >
              <CInput
                placeholder={t("license-plate-number")}
                disabled={!!selected}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="provincePlate"
              label={t("plate-province")}
              rules={[{ required: true }]}
            >
              <CSelect.PlateProvince />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="vehicleType" label={t("vehicle-type")}>
              <CSelect
                placeholder={t("vehicle-type")}
                valueOptions={{
                  values: [
                    {
                      label: t("car"),
                      value: "CAR",
                    },
                    {
                      label: t("motorcycle"),
                      value: "MOTORCYCLE",
                    },
                  ],
                  valueKey: "value",
                  titleKey: "label",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="vehicleBrand" label={t("vehicle-brand")}>
              <CSelect.VehicleBrand />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="vehicleModel" label={t("vehicle-series")}>
              <CInput placeholder={t("vehicle-series")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="vehicleImage" label={t("vehicle-picture")}>
              <CUpload.List accepts={[".jpeg", ".jpg", ".png"]} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CModal>
  );
};

export default AddVehicleToUserField;
