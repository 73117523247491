import { Form } from "antd";
import StepsTab from "components/display/steps-tab";
import PageContent from "components/layout/page-content";
import { usePageRoute } from "provider/page-route";
import withVehicleFloorEdit, {
  useVehicleFloorEdit,
} from "provider/vehicle-floor-edit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CreateAndEditFloorDrawer from "../content/create-and-edit-drawer";
import FloorEditStep1 from "./step-1";
import FloorEditStep2 from "./step-2";
import FloorEditStep3 from "./step-3";

const VehicleFloorEditPage = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false)

  const { dispatch } = usePageRoute();
  const { floor, form } = useVehicleFloorEdit();

  const onEditClick = () => {
    toggle()
  };

  const onCancel = () => {
    dispatch({ action: "back" });
  };

  const toggle = () => {
    setVisible(!visible)
  }

  return (
    <PageContent
      breadcrumb={{
        mutate: { onCancel: onCancel, onSave: false, onSaveAndContinue: false },
        form: form,
      }}
      className="p-5"
    >
      <CreateAndEditFloorDrawer initialValue={floor} onClose={toggle} visible={visible} />
      <Form layout="vertical">
        <StepsTab
          title={floor.floorName}
          onEditClick={onEditClick}
          tabs={[
            {
              title: t("connect-device"),
              element: <FloorEditStep1 />,
            },
            {
              title: t("map-configure"),
              element: <FloorEditStep2 />,
            },
            {
              title: t("map-configure-and-install-equipment"),
              element: <FloorEditStep3 />,
            },
          ]}
        />
      </Form>
    </PageContent>
  );
};

export default withVehicleFloorEdit(VehicleFloorEditPage);
