import { useCameraGroup } from "provider/all-video";
import AllVideos from "./all-videos";
import Playback from "./playback";

const SideBarSelectCameraGroup = () => {
  const { getContent } = useCameraGroup();

  return getContent === "all" ? <AllVideos /> : <Playback />;
};

export default SideBarSelectCameraGroup;
