import { CSSProperties, FC } from "react";
import CEmpty from "../display/c-empty";

interface EmptyPageProps {
  className?: string;
  style?: CSSProperties;
}

const EmptyPage: FC<EmptyPageProps> = ({ className, style }) => {
  return (
    <div className={`w-full h-full center ${className}`} style={style}>
      <CEmpty />
    </div>
  );
};

export default EmptyPage;
