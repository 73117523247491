import axios from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "../../config/axios";
import { IResponse } from "../../config/axios/interface";
import { IVehicleType, IVehicleTypeParams } from "./interface";

export const useGetVehicleTypes = (
  params: IVehicleTypeParams
): UseQueryResult<IResponse<IVehicleType[]>> => {
  return useQuery(
    ["get-vehivle-types", params],
    async () => {
      const res = await axios.get("/vehicle-type", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetVehicleType = (
  id?: string | number
): UseQueryResult<IVehicleType> => {
  return useQuery(
    ["get-vehivle-type", id],
    async () => {
      const res = await axios.get(`/vehicle-type/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostVehicleType = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/vehicle-type", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchVehicleType = (): UseMutationResult => {
  return useMutation(async ({ id, ...values }: any) => {
    const res = await axios.patch(`/vehicle-type/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteVehicleType = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/vehicle-type/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteVehicleTypes = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/vehicle-type/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useScrollGetVehicleTypes = (): UseInfiniteQueryResult<
  IResponse<IVehicleType[]>
> => {
  return useInfiniteQuery(
    ["get-vehivle-types", 'scroll'],
    async () => {
      const res = await axios.get("/vehicle-type");
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};
