import { UserOutlined } from "@ant-design/icons";
import { Avatar, AvatarProps } from "antd";

interface CAvatarProps extends AvatarProps {
  //   placeholder?: "profile" | "building" | "language";
}

const CAvatar = ({ src, ...props }: CAvatarProps) => {
  //   const holder = pholder[placeholder];

  return (
    <Avatar
      className="!inline-flex items-center justify-center"
      icon={<UserOutlined />}
      {...props}
      src={src}
    />
  );
};

export default CAvatar;
