import { fireNotification } from "components/popup/notification";
import { useCameraGroup } from "provider/all-video";
import { useEffect, useRef, useState } from "react";
import { useRequestVideoUrl } from "services/stream-video";
import styled from "styled-components";

interface VideoContainerProps {
  height: string;
  width: string;
}

interface IPlayerNumber {
  key: number;
  url?: string;
  encodeDeviceId?: string;
}

const VideoContainer = styled.div<VideoContainerProps>`
  width: 100%;
  .parent-wnd > div {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    canvas {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
`;

const playerNumbers: IPlayerNumber[] = Array.from(Array(9).keys()).map((e) => {
  return { key: e };
});

const TransitionVideo = () => {
  const ref = useRef<HTMLDivElement>(null);
  // const [jsDecoder, setJsDecoder] = useState<any>();
  const [getPlayerNumbers, setPlayerNumbers] =
    useState<IPlayerNumber[]>(playerNumbers);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const { getContent, getCameraGroups } = useCameraGroup();
  const { jsDecoder, setJsDecoder } = useCameraGroup();

  const videoRequest = useRequestVideoUrl();

  useEffect(() => {
    if (!ref) return;
    initJsDecoderInstance();

    return () => {
      destroyPlayer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    initialPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCameraGroups]);

  const initialPlayer = () => {
    const selecteds = getCameraGroups
      .flatMap((e) => e.cameraGroupDevices)
      .filter((e) => e.selected)
      .map((e) => e.id);
    const players = getPlayerNumbers
      .filter((e) => e.encodeDeviceId)
      .map((e) => e.encodeDeviceId);

    const uniqueAdd = selecteds.filter((e) => !players.includes(e));
    const uniqueRemove = players.filter((e) =>
      e ? !selecteds.includes(e) : false
    );

    const add = selecteds.length > players.length;
    const remove = selecteds.length < players.length;

    if (add) addPlayer(uniqueAdd);
    if (remove) removePlayer(uniqueRemove);
  };

  const destroyPlayer = () => {
    setJsDecoder(null);
  };

  const addPlayer = async (unique: string[]) => {
    let playlist: { id: string; key: number }[] = [];
    const next = getPlayerNumbers.reduce(
      (p: IPlayerNumber[], c: IPlayerNumber) => {
        const players = p.map((e) => e.encodeDeviceId).filter((e) => e);
        const add = unique.find((e) => !players.includes(e));

        if (c.encodeDeviceId) return [...p, c];
        if (add) {
          playlist.push({ id: add, key: c.key });
          return [...p, { ...c, encodeDeviceId: add }];
        }
        return [...p, c];
      },
      []
    );

    const interval = setInterval(() => {
      if (playlist.length) {
        const list = playlist[0];
        requestVideo(list.id, list.key);
        playlist = playlist.filter((_, i) => i);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    setPlayerNumbers(next);
  };

  const removePlayer = (unique: (string | undefined)[]) => {
    const next = getPlayerNumbers.map((e) => {
      const remove = unique.find((id) => id === e.encodeDeviceId);
      if (remove) {
        jsDecoder?.JS_Stop(e.key);
        return { ...e, encodeDeviceId: undefined };
      }
      return e;
    });
    setPlayerNumbers(next);
  };

  const element = document.documentElement;
  element?.addEventListener("fullscreenchange", () => {
    if (document.fullscreenElement?.clientWidth) return setFullScreen(true);
    return setFullScreen(false);
  });

  const initJsDecoderInstance = () => {
    let js = new window.JSPlugin({
      szId: "wind-player",
      iType: 1,
      iWidth: 3000,
      iHeight: 2000,
      iMaxSplit: 3,
      iCurrentSplit: 3,
      szBasePath: process.env.PUBLIC_URL + "/dist",
      oStyle: {
        border: "#343434",
        borderSelect: "#343434",
        background: "#4C4B4B",
      },
    });
    js?.JS_ArrangeWindow(3);
    setJsDecoder(js);
  };

  const requestVideo = (encodeDeviceId: string, key: number) => {
    const id = Number(`${encodeDeviceId}`.split(".")[1]);
    return videoRequest.mutate(
      { encodeDeviceId: id, streamType: "SUB" },
      {
        onSuccess: ({ url }) => {
          jsDecoder?.JS_Play(url, { playURL: url }, key);
        },
        onError: ({ message }) => {
          return fireNotification({
            message,
            menu: "resource-management",
            type: "error",
          });
        },
      }
    );
  };

  const getWidth = () => {
    if (getContent === "playback") {
      return "100% !important";
    }
    return fullScreen ? "33.33% !important" : "50% !important";
  };

  const getHeight = () => {
    return getContent === "playback" ? "100% !important" : "33.33% !important";
  };

  const width = getWidth();
  const height = getHeight();

  return (
    <div ref={ref} className="w-full h-full">
      <VideoContainer
        style={{ height: "100%", width: "100%" }}
        id="wind-player"
        width={width}
        height={height}
      ></VideoContainer>
    </div>
  );
};

export default TransitionVideo;
