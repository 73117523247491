import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {
  ButtonProps,
  Col,
  ColProps,
  FormInstance,
  Row,
  Typography,
} from "antd";
import CButton from "components/button/c-button";
import { fireNotification } from "components/popup/notification";
import { useAppLoading } from "provider/app-loading";
import { usePageRoute } from "provider/page-route";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const BreadcrumbWrapper = styled(Row)<{ $shadow: boolean }>`
  background-color: white;
  position: sticky;
  top: 0px;
  z-index: 100;
  ${({ $shadow }) => ($shadow ? tw`shadow` : "")};
  ${tw`py-3 px-4`};
`;

type FunctionType = (v?: any) => any;

export interface BreadcrumbProps {
  form?: FormInstance;
  mutate?: {
    mutation?: UseMutationResult;
    invalidateQueries?: string[];
    onSave?: FunctionType | boolean;
    onSaveAndContinue?: FunctionType | boolean;
    onCancel?: FunctionType | boolean;
    onBeforeUpdate?: FunctionType;
  };
  extras?: { colProps: ColProps; buttonProps: ButtonProps }[];
  shadow?: boolean;
  //   pageKey?: TMainPage;
}

const Breadcrumb: FC<BreadcrumbProps> = ({
  form,
  mutate,
  extras,
  shadow = true,
}) => {
  const { t } = useTranslation();

  const { setAppLoading } = useAppLoading();
  const { dispatch, pageKey } = usePageRoute();

  const qClient = useQueryClient();

  const {
    mutation,
    invalidateQueries = [],
    onSave,
    onSaveAndContinue,
    onCancel,
    onBeforeUpdate,
  } = mutate || {};

  const _onSave = async () => {
    let values = await form?.validateFields();
    if (typeof onSave === "function") {
      return onSave(values);
    }
    if (onBeforeUpdate) {
      values = onBeforeUpdate({ ...values });
    }
    setAppLoading(true);
    mutation?.mutate(values, {
      onSuccess: () => {
        invalidateQueries.forEach((e) => qClient.invalidateQueries([e]));
        fireNotification({ type: "success", menu: pageKey });
        dispatch({ action: "back" });
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
          menu: pageKey,
        });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  const _onSaveAndContinue = async () => {
    let values = await form?.validateFields();
    if (typeof onSaveAndContinue === "function") {
      return onSaveAndContinue(values);
    }
    if (onBeforeUpdate) {
      values = onBeforeUpdate({ ...values });
    }
    setAppLoading(true);
    mutation?.mutate(values, {
      onSuccess: () => {
        invalidateQueries.forEach((e) => qClient.invalidateQueries([e]));
        fireNotification({ type: "success", menu: pageKey });
        form?.resetFields();
      },
      onError: ({ message }: any) => {
        fireNotification({
          type: "error",
          description: message,
          menu: pageKey,
        });
      },
      onSettled: () => {
        setAppLoading(false);
      },
    });
  };

  const _onCancel = () => {
    if (typeof onCancel === "function") {
      return onCancel();
    }
    dispatch({ action: "back" });
  };

  const loading = mutation?.isLoading;

  return (
    <BreadcrumbWrapper $shadow={shadow} align="middle">
      <Col span={12}>
        <Title />
      </Col>
      <Col span={12}>
        <Row gutter={12} justify="end">
          <Col span={6} hidden={!form || onCancel === false}>
            <CButton
              loading={loading}
              theme="white"
              className="w-full"
              onClick={_onCancel}
            >
              <Typography.Text ellipsis className="color-white">
                {t("cancel")}
              </Typography.Text>
            </CButton>
          </Col>
          <Col hidden={!form || onSaveAndContinue === false}>
            <CButton
              theme="white"
              className="w-full"
              loading={loading}
              onClick={_onSaveAndContinue}
            >
              <Typography.Text
                ellipsis={{ tooltip: t("save-and-continue") }}
                className="color-white"
              >
                {t("save-and-continue")}
              </Typography.Text>
            </CButton>
          </Col>
          <Col span={6} hidden={!form || onSave === false}>
            <CButton
              loading={loading}
              theme="yellow"
              className="w-full"
              onClick={_onSave}
            >
              <Typography.Text ellipsis className="color-white">
                {t("save")}
              </Typography.Text>
            </CButton>
          </Col>
        </Row>
      </Col>
    </BreadcrumbWrapper>
  );
};

const Title = () => {
  const { t } = useTranslation();
  const { pages, getCurrentKey } = usePageRoute();

  const getBreadcrumb = () => {
    return pages.map((item, index) => {
      const lastElement = pages.length - 1 === index;

      if (lastElement) {
        return (
          <Col key={`text_${index}`}>
            <span className="italic" style={{ color: "#48B074" }}>
              {t(item.key, { ns: "menu" })}
            </span>
          </Col>
        );
      }

      return (
        <React.Fragment key={item.key}>
          <Col key={`text_${index}`}>
            <span className="italic">{t(item.key, { ns: "menu" })}</span>
          </Col>
          <Col key={`slash_${index}`}>
            <span>/</span>
          </Col>
        </React.Fragment>
      );
    });
  };

  const title = getCurrentKey();
  const breadcrumb = getBreadcrumb();

  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={4}>
          {t(title || "", { ns: "menu" })}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Row gutter={6} className="text-[20px]">
          {breadcrumb}
        </Row>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
