import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { throwResponse } from "config/axios";
import axios from "axios";
import { IResponse } from "config/axios/interface";
import { IMark, IMarkParams } from "./interface";

export const useGetScrollMarks = (
  params?: IMarkParams
): UseInfiniteQueryResult<IResponse<IMark[]>, Error> => {
  return useInfiniteQuery(
    ["get-marks", "scroll", params],
    async ({ pageParam }) => {
      const res = await axios.get("/mark", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useGetMarks = (
  params?: IMarkParams
): UseQueryResult<IResponse<IMark[]>, Error> => {
  return useQuery(
    ["get-marks"],
    async () => {
      const res = await axios.get("/mark", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true, enabled: params?.enabled }
  );
};

export const useGetMark = (
  id?: string | number
): UseQueryResult<IMark, Error> => {
  return useQuery(
    ["get-mark", id],
    async () => {
      const res = await axios.get(`/mark/${id}`);
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { enabled: !!id }
  );
};

export const usePostMark = (): UseMutationResult<IMark, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/mark`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const usePatchMark = (): UseMutationResult<IMark, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.patch(`/mark/${id}`, data);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const useDeleteMark = (): UseMutationResult<IMark, Error> => {
  return useMutation(async ({ id, ...data }: any) => {
    const res = await axios.delete(`/mark/${id}`);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
