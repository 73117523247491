import { notification } from "antd";
// import { ArgsProps } from "antd/lib/notification";
import { t } from "i18next";
import { ReactNode } from "react";
import { TMainPage } from "router/interface";
type NotificationTypes = "success" | "info" | "warning" | "error";

interface NotificationProps {
  type: NotificationTypes;
  message?: string;
  menu?: TMainPage;
  description?: string;
}

export const fireNotification = ({
  type,
  message,
  menu,
  description,
}: NotificationProps) => {
  const messages: { [K in NotificationTypes]: string } = {
    success: t("action-completed", { ns: "message" }),
    info: t("warning", { ns: "message" }),
    warning: t("warning", { ns: "message" }),
    error: t("error-occurred", { ns: "message" }),
  };
  const msg = message || messages[type];

  notification[type]({
    message: msg,
    duration: 3.5,
    className: "ronded-app",
    description: description,
    // closeIcon: <></>,
    getContainer: () => {
      const element = document.getElementById(menu || "");
      return element || document.body;
    },
  });
};

interface NotificationHeaderProps {
  element?: ReactNode;
  message: string;
}

export const notificationHeader = ({
  element,
  message,
}: NotificationHeaderProps) => {
  notification.open({
    message: message,
    duration: 3.5,
    className: "ronded-app",
    description: element,
    placement: "bottomLeft",
  });
};
