import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetScrollEmailTemplates } from "services/email-template";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

export interface CSelectTemplateEmailProps extends CSelectProps {
  name?: string;
}

const CSelectTemplateEmail: FC<CSelectTemplateEmailProps> = ({
  name,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      placeholder={t("setting.email-template", { ns: "menu" })}
      {...props}
      useQuery={useGetScrollEmailTemplates}
      valueKey="id"
      titleKey="name"
      searchKey="name"
      queryParams={{ name }}
    />
  );
};

export default CSelectTemplateEmail;
