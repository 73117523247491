import { FC } from "react";
import { useTranslation } from "react-i18next";
import CSelect, { CSelectProps } from ".";

const CSelectErpType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelect
      placeholder={t("type-erp")}
      {...props}
      valueOptions={{
        values: [
          { label: "Sales", value: 1 },
          { label: "Purchase Local", value: 2 },
          { label: "Weight Other", value: 3 },
          { label: "Transfers WH", value: 4 },
          { label: "Purchase Import", value: 5 },
        ],
        valueKey: "value",
        titleKey: "label",
      }}
    />
  );
};

export default CSelectErpType;
