import { Layout } from "antd";
import { FC, ReactNode } from "react";

interface ContentProps {
  children: ReactNode;
  id?: string;
}

const Content: FC<ContentProps> = ({ children, id }) => {
  return (
    <Layout.Content
      style={{
        height: "calc(100vh - 104px)",
      }}
      id={id}
    >
      {children}
    </Layout.Content>
  );
};

export default Content;
