import {
  MenuAccessControlAccessibility,
  MenuAccessControlRealtimeMonitoring,
  MenuAccessControlReport,
} from "./../../assets/icons/menu/access-control";
import { TSubPageAccessControl } from "./interface";
import { IPageRoute, ISubMenu } from "router/interface";
import AccessibilityOverviewPage from "pages/access-control/access-overview";
import AccessibilityManagementPage from "pages/access-control/accessibility/accessibility-management";
import AccessibilityDeterminedByAccessLevelPage from "pages/access-control/accessibility/determined-by-access-level";
import AccessibilityDeterminedByGroupOfPeoplePage from "pages/access-control/accessibility/determined-by-group-of-people";
import AccessibilityDeterminedByPersonPage from "pages/access-control/accessibility/determined-by-person";
import FaceComparisonPage from "pages/access-control/face-accessibility/face-comparison";
import FaceDeterminedByAccessLevelPage from "pages/access-control/face-accessibility/determined-by-access-level";
import RealtimeDoorMonitoringPage from "pages/access-control/realtime-monitoring/realtime-door-monitoring";
import RealtimeFaceMonitoringPage from "pages/access-control/realtime-monitoring/realtime-face-monitoring";
import AccessibilityReportPage from "pages/access-control/report/accessibility-report";
import FaceAccessibilityReportPage from "pages/access-control/report/face-accessibility-report";
import FaceDeterminedByAccessLevelCreateAndEditPage from "pages/access-control/face-accessibility/determined-by-access-level/create-and-edit";
import AccessibilityManagementDetail from "pages/access-control/accessibility/accessibility-management/accessbility-detail";
import AccesscontrolAllReport from "pages/access-control/report/report";

export const ACCESS_CONTROL_MENUS: ISubMenu<TSubPageAccessControl>[] = [
  // {
  //   key: "access-control.access-overview",
  //   title: "access-control.access-overview",
  //   code: "01100",
  //   icon: MenuAccessControlOverview,
  // },
  {
    key: "access-control.accessibility",
    title: "access-control.accessibility",
    code: "010100",
    icon: MenuAccessControlAccessibility,
    children: [
      {
        key: "access-control.accessibility-management",
        title: "access-control.accessibility-management",
        code: "010101",
      },
      {
        key: "access-control.accessibility-determined-by-access-level",
        title: "access-control.accessibility-determined-by-access-level",
        code: "010102",
      },
      // {
      //   key: "access-control.accessibility-determined-by-person",
      //   title: "access-control.accessibility-determined-by-person",
      //   code: "01103",
      // },
      // {
      //   key: "access-control.accessibility-determined-by-group-of-people",
      //   title: "access-control.accessibility-determined-by-group-of-people",
      //   code: "01104",
      // },
    ],
  },
  // {
  //   key: "access-control.face-accessibility",
  //   title: "access-control.face-accessibility",
  //   code: "01200",
  //   icon: MenuAccessControlFaceAccessibility,
  //   children: [
  //     {
  //       key: "access-control.face-accessibility-face-comparison",
  //       title: "access-control.face-accessibility-face-comparison",
  //       code: "01201",
  //     },
  //     {
  //       key: "access-control.face-accessibility-determined-by-access-level",
  //       title: "access-control.face-accessibility-determined-by-access-level",
  //       code: "01202",
  //     },
  //   ],
  // },
  {
    key: "access-control.realtime-monitoring",
    title: "access-control.realtime-monitoring",
    code: "010200",
    icon: MenuAccessControlRealtimeMonitoring,
    children: [
      {
        key: "access-control.realtime-door-monitoring",
        title: "access-control.realtime-door-monitoring",
        code: "010201",
      },
      // {
      //   key: "access-control.realtime-face-monitoring",
      //   title: "access-control.realtime-face-monitoring",
      //   code: "01302",
      // },
    ],
  },
  {
    key: "access-control.report",
    title: "access-control.report",
    code: "010300",
    icon: MenuAccessControlReport,
    children: [
      {
        key: "access-control.accessibility-report",
        title: "access-control.accessibility-report",
        code: "010301",
      },
      {
        key: "access-control.report-all",
        title: "access-control.report-all",
        code: "010302"
      }
      // {
      //   key: "access-control.face-accessibility-report",
      //   title: "access-control.face-accessibility-report",
      //   code: "01402",
      // },
    ],
  },
];
export const ACCESS_CONTROL_ROUTES: IPageRoute<TSubPageAccessControl>[] = [
  {
    key: "access-control.access-overview",
    element: AccessibilityOverviewPage,
  },
  {
    key: "access-control.accessibility-management",
    element: AccessibilityManagementPage,
  },
  {
    key: "access-control.accessibility-determined-by-access-level",
    element: AccessibilityDeterminedByAccessLevelPage,
  },
  {
    key: "access-control.accessibility-determined-by-person",
    element: AccessibilityDeterminedByPersonPage,
  },
  {
    key: "access-control.accessibility-determined-by-group-of-people",
    element: AccessibilityDeterminedByGroupOfPeoplePage,
  },
  {
    key: "access-control.face-accessibility-face-comparison",
    element: FaceComparisonPage,
  },
  {
    key: "access-control.face-accessibility-determined-by-access-level",
    element: FaceDeterminedByAccessLevelPage,
  },
  {
    key: "access-control.face-accessibility-determined-by-access-level-add",
    element: FaceDeterminedByAccessLevelCreateAndEditPage,
  },
  {
    key: "access-control.face-accessibility-determined-by-access-level-edit",
    element: FaceDeterminedByAccessLevelCreateAndEditPage,
  },
  {
    key: "access-control.realtime-door-monitoring",
    element: RealtimeDoorMonitoringPage,
  },
  {
    key: "access-control.realtime-face-monitoring",
    element: RealtimeFaceMonitoringPage,
  },
  {
    key: "access-control.accessibility-report",
    element: AccessibilityReportPage,
  },
  {
    key: "access-control.face-accessibility-report",
    element: FaceAccessibilityReportPage,
  },
  {
    key: "access-control.accessibility-management-detail",
    element: AccessibilityManagementDetail,
  },
  {
    key: "access-control.report-all",
    element: AccesscontrolAllReport,
  }
];
