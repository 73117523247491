import { ComponentType, createContext, useContext, useState } from "react";
import { IAccessibilityDeterminedByGroupContext } from "./interface";

const Context = createContext<IAccessibilityDeterminedByGroupContext>(
  {} as IAccessibilityDeterminedByGroupContext
);

const withAccessibilityDeterminedByGroup = (Component: ComponentType) => {
  return () => {
    const [group, setGroup] = useState();
    
    return (
      <Context.Provider value={{ group, setGroup }}>
        <Component />
      </Context.Provider>
    );
  };
};

export const useAccessibilityDeterminedByGroup = () => useContext(Context);
export default withAccessibilityDeterminedByGroup;
