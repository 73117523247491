import { ComponentType, createContext, useContext, useState } from "react";
import { IRealTimeFaceMonitoringContext } from "./interface";

const Context = createContext<IRealTimeFaceMonitoringContext>(
  {} as IRealTimeFaceMonitoringContext
);

const withRealTimeFaceMonitoring = (Component: ComponentType) => {
  return () => {
    const [device, setDevice] = useState();
    
    return (
      <Context.Provider value={{ device, setDevice }}>
        <Component />
      </Context.Provider>
    );
  };
};

export const useRealTimeFaceMonitoring = () => useContext(Context)
export default withRealTimeFaceMonitoring;
