import { Col, Collapse, Row, Space, Typography } from "antd";
import { useAppRoleMenu } from "provider/app-role-menu";
import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { AppMenuType } from "router/interface";
import { IRole } from "services/role/interface";
import styled from "styled-components";
import tw from "twin.macro";

const Panel = styled(Collapse.Panel)<{ $hideArrow: boolean }>`
  min-height: 50px;
  svg * {
    fill: ${({ $hideArrow }) => ($hideArrow ? "white" : "")};
  }
  .ant-collapse-content-box {
    ${tw`bg-[#fafaf9] !py-5`};
  }
  .ant-collapse-expand-icon {
    display: inline-flex;
    align-items: end;
    height: 22px;
    cursor: ${({ $hideArrow }) => ($hideArrow ? "" : "pointer")};
  }
`;

const ActiveDot = styled.span<{ $active: boolean }>`
  width: 18px;
  height: 18px;
  ${tw`rounded-app border block`};
  //bg color
  ${({ $active }) => ($active ? tw`bg-primary-dark` : tw`bg-gray-100`)};
  //border color
  ${({ $active }) => ($active ? tw`border-primary-dark` : tw`border-gray-300`)};
`;

interface RoleMenuListProps {
  role: IRole;
}

const RoleMenuList: FC<RoleMenuListProps> = ({ role }) => {
  const { t } = useTranslation();
  const { baseMenus } = useAppRoleMenu();

  const getIsActive = (code: string): boolean => {
    const { roleMenus } = role;
    const find = roleMenus.find((e) => e.menu?.code === code);
    return find?.isActive || false;
  };

  const getSum = (item: AppMenuType) => {
    if ("element" in item && !item.children) {
      const isActive = getIsActive(item.code);
      return { value: Number(isActive), max: 1 };
    }
    const flat = item.children!.flatMap((item) => {
      if (item.children) {
        return item.children;
      }
      return item;
    });
    const someActive = flat.reduce((r, c) => {
      const isActive = getIsActive(c.code);
      if (isActive) {
        return r + 1;
      }
      return r;
    }, 0);
    return { max: flat.length, value: someActive };
  };

  const renderPanels = (menus?: AppMenuType[]): ReactNode[] | undefined => {
    return menus?.flatMap((item) => {
      if (item.children) {
        return renderPanels(item.children);
      }
      const active = getIsActive(item.code);
      return (
        <Col style={{ overflow: "hidden" }} xs={12} lg={8} key={item.key}>
          <PanelHeader item={item} active={active} />
        </Col>
      );
    });
  };

  return (
    <React.Fragment>
      <div
        style={{ height: 45 }}
        className="bg-neutral-200 px-4 flex items-center rounded-app"
      >
        <Typography.Title level={5}>{t("menu")}</Typography.Title>
      </div>
      <Collapse ghost>
        {baseMenus.map((item) => {
          const hasChildren = !!item.children;
          const { value, max } = getSum(item);
          const active = getIsActive(item.code);
          return (
            <Panel
              $hideArrow={!hasChildren}
              header={
                <PanelHeader
                  active={active}
                  item={item}
                  value={value}
                  max={max}
                />
              }
              key={item.key}
              collapsible={hasChildren ? "header" : "disabled"}
            >
              <Row align="middle" gutter={[0, 24]} className="pl-12">
                {renderPanels(item.children)}
              </Row>
            </Panel>
          );
        })}
      </Collapse>
    </React.Fragment>
  );
};

interface PanelHeaderProps {
  item: AppMenuType;
  active: boolean;
  max?: number;
  value?: number;
}

const PanelHeader: FC<PanelHeaderProps> = ({ item, active, max, value }) => {
  const { t } = useTranslation("menu");

  const hideCount = !value && !max;

  return (
    <Space align="center" style={{ overflow: "hidden" }}>
      <ActiveDot $active={active} />
      <Typography.Text ellipsis={{ tooltip: t(item.key) }}>
        {t(item.key)}
      </Typography.Text>
      <Typography.Text type="danger">
        {hideCount ? "" : `${value}/${max}`}
      </Typography.Text>
    </Space>
  );
};

export default RoleMenuList;
