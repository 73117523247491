import { Typography } from "antd";
import { CSSProperties, FC } from "react";
import Icon from "@ant-design/icons";
import { VideoNoData } from "assets/icons/general";
import { useTranslation } from "react-i18next";
import { TextProps } from "antd/lib/typography/Text";

const placeholder = {
  empty: "please-select-your-channel-to-watch",
  wait: "waiting-for-image-detection",
};

export interface EmptySelectVideoCardProps {
  typography?: TextProps;
  style?: CSSProperties;
  type: "empty" | "wait";
  hidden?: boolean;
  color?: string;
}

const EmptySelectVideoCard: FC<EmptySelectVideoCardProps> = ({
  typography,
  style,
  type,
  hidden,
  color = "",
}) => {
  const { t } = useTranslation("message");

  if (hidden) return null;

  return (
    <div className="center flex-col" style={{ height: "100%", ...style }}>
      <Icon component={VideoNoData} style={{ fontSize: 66 }} />
      <Typography.Text
        className={`mt-4 text-center ${color}`}
        style={{ fontSize: 18 }}
        {...typography}
      >
        {t(placeholder[type])}
      </Typography.Text>
    </div>
  );
};

export default EmptySelectVideoCard;
