import { IPageRoute, ISubMenu } from "router/interface";
import { TSubPageSetting } from "./interface";
import UsePDPAPage from "pages/setting/pdpa";
import UsePositionPage from "pages/setting/position";
import UseDepartmentPage from "pages/setting/department";
import UseRolePage from "pages/setting/role";
import UseJobTypePage from "pages/setting/job-type";
import UseRecivingPointPage from "pages/setting/reciving-point";
import UseStockPage from "pages/setting/stock";
import UseScalePage from "pages/setting/scale";
import UseJobTypeSupplierPage from "pages/setting/job-type-supplier";
import UseParameterOfQuickLapPage from "pages/setting/parameter-of-quick-lap";
import UseSetAParkingTime from "pages/setting/set-parking-time";
import UserGroupOfPersons from "pages/user-management/group-of-persons";
import {
  MenuSettingJobType,
  MenuSettingParameter,
  MenuSettingPDPA,
  MenuSettingPickup,
  MenuSettingPosition,
  MenuSettingRole,
  MenuSettingSchedule,
  MenuSettingTypeSupplier,
  MenuSettingWarehouse,
} from "assets/icons/menu/setting";
import SettingSchedulePage from "pages/setting/schedule";
import SettingProductGroupPage from "pages/setting/product-group";
import TermsAndConditionPage from "pages/setting/terms-and-condition";
import PrivacyPolicyPage from "pages/setting/privacy-policy";
import NotificationPage from "pages/setting/notification";
import EmailTemplatePage from "pages/setting/email-template";
import EmailTemplateCreateAndEditPage from "pages/setting/email-template/create-and-edit";
export const SETTING_MENUS: ISubMenu<TSubPageSetting>[] = [
  {
    key: "setting.role",
    title: "setting.role",
    icon: MenuSettingRole,
    code: "070100",
  },
  {
    key: "setting.position",
    title: "setting.position",
    icon: MenuSettingPosition,
    code: "070200",
  },
  {
    key: "setting.department",
    title: "setting.department",
    icon: MenuSettingPosition,
    code: "071500",
  },
  {
    key: "setting.group-of-persons",
    title: "setting.group-of-persons",
    icon: MenuSettingPosition,
    code: "020500",
  },
  {
    key: "setting.pdpa",
    title: "setting.pdpa",
    icon: MenuSettingPDPA,
    code: "070300",
  },
  {
    key: "setting.terms-conditions",
    title: "setting.terms-conditions",
    icon: MenuSettingPDPA,
    code: "070400",
  },
  {
    key: "setting.privacy-policy",
    title: "setting.privacy-policy",
    icon: MenuSettingPDPA,
    code: "070500",
  },
  {
    key: "setting.schedule",
    title: "setting.schedule",
    icon: MenuSettingSchedule,
    code: "070600",
  },
  {
    key: "setting.jop-type",
    title: "setting.jop-type",
    icon: MenuSettingJobType,
    code: "070700",
  },
  // {
  //   key: "setting.product-group",
  //   title: "setting.product-group",
  //   icon: MenuSettingScale,
  //   code: "08006",
  // },
  {
    key: "setting.vehicle-type",
    title: "setting.vehicle-type",
    icon: MenuSettingPickup,
    code: "070800",
  },
  {
    key: "setting.stock",
    title: "setting.stock",
    icon: MenuSettingWarehouse,
    code: "070900",
  },

  {
    key: "setting.job-type-supplier",
    title: "setting.job-type-supplier",
    icon: MenuSettingTypeSupplier,
    code: "071000",
  },
  {
    key: "setting.parameter-of-quick-lap",
    title: "setting.parameter-of-quick-lap",
    icon: MenuSettingParameter,
    code: "071100",
  },
  {
    key: "setting.set-a-parking-time",
    title: "setting.set-a-parking-time",
    icon: MenuSettingParameter,
    code: "071200",
  },
  // {
  //   key: "setting.scale",
  //   title: "setting.scale",
  //   icon: MenuSettingScale,
  //   code: "08600",
  // },
  {
    key: "setting.notification",
    title: "setting.notification",
    icon: MenuSettingParameter,
    code: "071300",
  },
  {
    key: "setting.email-template",
    title: "setting.email-template",
    icon: MenuSettingParameter,
    code: "071400",
  },
];

export const SETTING_ROUTES: IPageRoute<TSubPageSetting>[] = [
  {
    key: "setting.pdpa",
    element: UsePDPAPage,
  },
  {
    key: "setting.position",
    element: UsePositionPage,
  },
  {
    key: "setting.department",
    element: UseDepartmentPage,
  },
  {
    key: "setting.role",
    element: UseRolePage,
  },
  {
    key: "setting.schedule",
    element: SettingSchedulePage,
  },
  {
    key: "setting.jop-type",
    element: UseJobTypePage,
  },
  {
    key: "setting.product-group",
    element: SettingProductGroupPage,
  },
  {
    key: "setting.scale",
    element: UseScalePage,
  },
  {
    key: "setting.vehicle-type",
    element: UseRecivingPointPage,
  },
  {
    key: "setting.stock",
    element: UseStockPage,
  },
  {
    key: "setting.job-type-supplier",
    element: UseJobTypeSupplierPage,
  },
  {
    key: "setting.parameter-of-quick-lap",
    element: UseParameterOfQuickLapPage,
  },
  {
    key: "setting.set-a-parking-time",
    element: UseSetAParkingTime,
  },
  {
    key: "setting.terms-conditions",
    element: TermsAndConditionPage,
  },
  {
    key: "setting.privacy-policy",
    element: PrivacyPolicyPage,
  },
  {
    key: "setting.notification",
    element: NotificationPage,
  },
  {
    key: "setting.email-template",
    element: EmailTemplatePage,
  },
  {
    key: "setting.email-template-add",
    element: EmailTemplateCreateAndEditPage,
  },
  {
    key: "setting.email-template-edit",
    element: EmailTemplateCreateAndEditPage,
  },
  {
    key: "setting.group-of-persons",
    element: UserGroupOfPersons,
  },
  
];
