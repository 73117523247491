import {
  CheckCircleFilled,
  CloseCircleFilled,
  HourglassOutlined,
} from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

type DeviceStatusType =
  | "ONLINE"
  | "OFFLINE"
  | "COMPLETE"
  | "CANCEL"
  | "OUTSTANDING";
export interface DeviceStatusCardProps {
  status: DeviceStatusType;
}
const colors: { [K in DeviceStatusType]: string } = {
  ONLINE: "#07B53B",
  OFFLINE: "#FC0002",
  COMPLETE: "#07B53B",
  CANCEL: "#FC0002",
  OUTSTANDING: "#FCE300",
};
const icons: { [K in DeviceStatusType]: React.ReactElement } = {
  ONLINE: <CheckCircleFilled className="mx-1" />,
  OFFLINE: <CloseCircleFilled className="mx-1" />,
  COMPLETE: <CheckCircleFilled className="mx-1" />,
  CANCEL: <CloseCircleFilled className="mx-1" />,
  OUTSTANDING: <HourglassOutlined className="mx-1" />,
};
const Wrapper = styled.div<{
  active: "ONLINE" | "OFFLINE" | "COMPLETE" | "CANCEL" | "OUTSTANDING";
}>`
  background: ${({ active }) =>
    active === "ONLINE"
      ? "#EAF7EC"
      : active === "OFFLINE"
      ? "#FFE6E6"
      : "transparent"};
  border: 1px solid ${({ active }) => colors[active]};
  border-radius: 2px;
  span {
    color: ${({ active }) => colors[active]};
  }
  ${tw`w-[100px] !p-1 shadow-sm text-center`};
  width: 90%;
`;

const DeviceStatusCard = ({ status }: DeviceStatusCardProps) => {
  const { t } = useTranslation();
  if (status === "ONLINE") {
    return (
      <Wrapper active={status} className="">
        {icons[status]}
        <span className="mx-1">{t(status.toLocaleLowerCase() + "1")}</span>
      </Wrapper>
    );
  } else if (status === "OFFLINE") {
    return (
      <Wrapper active={status} className="c-container">
        {icons[status]}
        <span className="mx-1">{t(status.toLocaleLowerCase() + "1")}</span>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper active={status} className="c-container">
        {icons[status]}
        <span className="mx-1">{t(status.toLocaleLowerCase())}</span>
      </Wrapper>
    );
  }
};

export default DeviceStatusCard;
