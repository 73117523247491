import { TableColumnsType, Typography } from "antd";
import CAvatar from "components/display/c-avatar";
import CTable from "components/display/c-table";
import { useAppLanguage } from "provider/app-language";
import { useUserReport } from "provider/user-report";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useExportUserReport, useGetUserReport } from "services/users-report";
import {
  IUserReport,
  IUsersReportParams,
} from "services/users-report/interface";

const UserManagementUserReport = () => {
  const { t } = useTranslation();
  const { columnsKey, search } = useUserReport();
  const { formatDate } = useAppLanguage();

  const [params, setParams] = useState<IUsersReportParams>({});

  const data_user = useGetUserReport({
    ...params,
    position: search?.position,
    name: search?.name,
    phoneNumber: search?.phoneNumber,
    address: search?.address,
    email: search?.email,
    startDate: search?.startDate,
    endDate: search?.endDate,
  });

  const export_user = useExportUserReport();

  const columns: TableColumnsType<IUserReport> = [
    {
      key: "date",
      title: t("entry-date"),
      render: (_, rc) => {
        const date = formatDate({
          date: rc.createdAt,
          format: "nomal-full-time",
        });
        return (
          <Typography.Text ellipsis={{ tooltip: date }}>{date}</Typography.Text>
        );
      },
    },
    {
      key: "name",
      title: t("full-name"),
      render: (_, rc) => {
        const firstName = rc?.firstNameTh || "";
        const lastName = rc?.lastNameTh || "";
        if (!firstName && !lastName) return <span>-</span>;
        return (
          <Typography.Text ellipsis={{ tooltip: `${firstName} ${lastName}` }}>
            {`${firstName} ${lastName}`}
          </Typography.Text>
        );
      },
    },
    {
      key: "phoneNumber",
      title: t("phone-number"),
      render: (_, rc) => {
        const phone = rc.phoneNumber || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: phone }}>
            {phone}
          </Typography.Text>
        );
      },
    },
    {
      key: "address",
      title: t("address"),
      render: (_, rc) => {
        const address = rc?.address || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: address }}>
            {address}
          </Typography.Text>
        );
      },
    },
    {
      key: "email",
      title: t("email"),
      render: (_, rc) => {
        const email = rc?.email || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: email }}>
            {email}
          </Typography.Text>
        );
      },
    },
    {
      key: "position",
      title: t("job-position"),
      render: (_, rc) => {
        const position = rc?.position?.positionNameTh || "-";
        return (
          <Typography.Text ellipsis={{ tooltip: position }}>
            {position}
          </Typography.Text>
        );
      },
    },
  ];

  const _columns = columns.filter((item) => {
    return columnsKey.some((e) => e === item.key);
  });

  const topic = _columns
    .map((i) => {
      if (i?.key === "profile") return "profile.url";
      if (i?.key === "date") return "createdAt";
      if (i?.key === "name") return ["firstNameTh", "lastNameTh"];
      if (i?.key === "position") return "position.positionNameTh";
      return i?.key;
    })
    .flat();

  return (
    <CTable
      scroll={{ x: 1100 }}
      selectAble={false}
      title={t("list-all")}
      columns={_columns}
      query={data_user}
      loading={data_user?.isFetching}
      pagination={{
        onChange: (page, limit) => {
          setParams({ ...params, page, limit });
        },
      }}
      action={{
        excels: {
          export: {
            mutation: export_user,
            extraValue: { topic: topic, userType: search?.userType },
            notShowModal: true,
          },
        },
      }}
    />
  );
};

export default UserManagementUserReport;
