import { Form, Input } from "antd";
import CDrawer from "components/display/c-drawer";
import CInput from "components/input/c-input";
import { useSettingSchedule } from "provider/setting-schedule";
import { useEffect } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetScheduleGroup,
  usePatchScheduleGroup,
  usePostScheduleGroup,
} from "services/schedule-group";

interface CreateAndEditDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const CreateAndEditDrawer: FC<CreateAndEditDrawerProps> = ({
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { selectedGroupTable, action } = useSettingSchedule();

  const post_grouptable = usePostScheduleGroup();
  const patch_grouptable = usePatchScheduleGroup();
  const { data } = useGetScheduleGroup(selectedGroupTable?.id);

  useEffect(() => {
    initail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, visible]);

  const initail = () => {
    if (action === "add") {
      form.resetFields();
    }
    if (data && action === "edit") {
      form.setFieldsValue({
        ...data,
      });
    }
  };

  const groupTableTh = (
    <span>
      {t("manage-table-group-name")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const groupTableEn = (
    <span>
      {t("manage-table-group-name")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );
  const descriptionTh = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-th")})</span>
    </span>
  );
  const descriptionEn = (
    <span>
      {t("description")}
      <span className="text-red-500"> ({t("language-en")})</span>
    </span>
  );

  const titleForm =
    action === "edit" ? t("edit-table-group") : t("add-table-group");
  const loading = patch_grouptable?.isLoading || post_grouptable?.isLoading;

  return (
    <CDrawer
      form={form}
      title={titleForm}
      forceRender
      width="40vw"
      mutation={{
        mutate: selectedGroupTable?.id ? patch_grouptable : post_grouptable,
        invalidateQueries: ["get-schedule-groups"],
      }}
      onClose={onClose}
      visible={visible}
      loading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="groupTableTh"
          label={groupTableTh}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput placeholder={t("manage-table-group-name")} />
        </Form.Item>
        <Form.Item
          name="groupTableEn"
          label={groupTableEn}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CInput
            placeholder={`${t("manage-table-group-name")} (${t(
              "language-en"
            )})`}
          />
        </Form.Item>
        <Form.Item name="descriptionTh" label={descriptionTh}>
          <CInput.TextArea placeholder={t("description")} rows={6} />
        </Form.Item>
        <Form.Item name="descriptionEn" label={descriptionEn}>
          <CInput.TextArea
            placeholder={`${t("description")} (${t("language-en")})`}
            rows={6}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default CreateAndEditDrawer;
