import { LockFilled, UnlockFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Badge, Col, Form, Pagination, Row, Space, Typography } from "antd";
import { Door, OpenDoor } from "assets/images";
import CButton from "components/button/c-button";
import CImage from "components/display/c-image";
import CInput from "components/input/c-input";
import AppLoadingLoader from "components/layout/app-loading-loader";
import PageContent from "components/layout/page-content";
import EmptyPage from "components/pages/empty-page";
import ErrorPage from "components/pages/error-page";
import { fireNotification } from "components/popup/notification";
import { useDoorRealtime } from "provider/door-realtime";
import { usePageRoute } from "provider/page-route";
import { FormInstance } from "rc-field-form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetDoors, usePostGetDoor, usePostUpdateDoor } from "services/door";
import { doorStatus, IDoor } from "services/door/interface";
import styled from "styled-components";
import tw from "twin.macro";

const DoorListPage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { setParams, params } = useDoorRealtime();

  const className = "!p-5 bg-white rounded-app shadow w-full";

  const _onSubmit = (value: any) => {
    setParams?.({ ...params, ...value });
  };
  return (
    <PageContent>
      <Form
        layout="vertical"
        className={className}
        form={form}
        onFinish={_onSubmit}
      >
        <SearchHeader form={form} />
        <div>
          <Space>
            <Badge status="success" text={t("open")} />
            <Badge status="error" text={t("close")} />
            {/* <Badge status="error" text={t("lock")} /> */}
            <Badge status="default" text={t("offline-status")} />
          </Space>
        </div>
        <DoorList />
      </Form>
    </PageContent>
  );
};

const SearchHeader = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation();
  const qClient = useQueryClient();
  const { pageKey } = usePageRoute();
  const { setDoorId, doorId } = useDoorRealtime();

  const poseGetDoor = usePostGetDoor();
  const postDoorUpdate = usePostUpdateDoor();

  const mutate = {
    onSuccess() {
      setDoorId?.([]);
      qClient.invalidateQueries(["get-doors"]);
      qClient.invalidateQueries(["get-door"]);
      fireNotification({ type: "success", menu: pageKey });
    },
    onError(error: any) {
      fireNotification({
        menu: pageKey,
        type: "error",
        message: error?.message,
      });
    },
  };

  const refetchDoor = () => {
    return poseGetDoor.mutate({}, mutate);
  };

  const _onLock = () => {
    return postDoorUpdate.mutate({ ids: doorId, doorState: "CLOSED" }, mutate);
  };

  const _onUnlock = () => {
    return postDoorUpdate.mutate({ ids: doorId, doorState: "OPEN" }, mutate);
  };
  const style = { color: "#2B374D", fontSize: 18 };

  return (
    <Row gutter={[12, 6]}>
      <Col span={8}>
        <Form.Item name="doorName" label={t("equipment-name")}>
          <CInput placeholder={t("equipment-name")} search />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item className="mb-0" label=" ">
          <Row gutter={12}>
            <Col span={10}>
              <CButton className="w-full" htmlType="submit">
                {t("search")}
              </CButton>
            </Col>
            <Col span={10}>
              <CButton
                theme="white"
                className="w-full"
                onClick={() => {
                  form.resetFields();
                  form.submit();
                }}
              >
                {t("clear-data")}
              </CButton>
            </Col>
          </Row>
        </Form.Item>
      </Col>
      <Col flex="auto">
        <Form.Item label=" ">
          <Row className="w-full" gutter={[12, 6]}>
            <Col span={8}>
              <CButton
                theme="primary"
                onClick={refetchDoor}
                className="w-full"
                loading={poseGetDoor.isLoading}
              >
                {t("fetch-data")}
              </CButton>
            </Col>
            <Col span={8}>
              <CButton
                theme="white"
                icon={<LockFilled style={style} />}
                onClick={_onLock}
                className="w-full"
                loading={postDoorUpdate.isLoading}
              >
                {t("lock")}
              </CButton>
            </Col>
            <Col span={8}>
              <CButton
                theme="white"
                icon={<UnlockFilled style={style} />}
                onClick={_onUnlock}
                className="w-full"
                loading={postDoorUpdate.isLoading}
              >
                {t("unlock")}
              </CButton>
            </Col>
          </Row>
        </Form.Item>
      </Col>
    </Row>
  );
};

const DoorList = () => {
  const { doorId, setDoorId, params, setParams } = useDoorRealtime();
  const { data, isError, isFetching, error } = useGetDoors(params);

  const onChangeClick = (select: number) => {
    const isActive = doorId.some((e) => e === select);
    if (isActive) {
      const next = doorId.filter((e) => e !== select);
      return setDoorId?.(next);
    }
    return setDoorId?.([...doorId, select]);
  };

  if (isError) {
    return (
      <div className="pb-5">
        <Row className="!h-[340px]">
          <ErrorPage error={error as Error} />
        </Row>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="pb-5">
        <div className="!h-[340px] !flex justify-center items-center w-full">
          <AppLoadingLoader />
        </div>
      </div>
    );
  }

  if (data?.data && data?.count === 0) {
    return (
      <div className="pb-5">
        <Row className="!h-[340px]">
          <EmptyPage />
        </Row>
      </div>
    );
  }

  return (
    <div className=" pb-5">
      <Row
        className="!h-[340px] pt-2"
        style={{ overflowY: "auto" }}
        gutter={[12, 12]}
      >
        {data?.data && data.count > 0 && (
          <React.Fragment>
            {data?.data.map((item, index) => {
              return (
                <Col md={12} lg={8} xl={6} xxl={4} key={index}>
                  <CardAction
                    onClick={onChangeClick}
                    activeKey={doorId || []}
                    data={item}
                  />
                </Col>
              );
            })}
          </React.Fragment>
        )}
      </Row>
      <Row justify="end" className="pt-5">
        <PaginationStyle
          showSizeChanger
          total={data?.count}
          size="small"
          pageSize={params.limit}
          current={params.page}
          onChange={(page, limit) => {
            setParams?.({ ...params, page, limit });
          }}
          pageSizeOptions={["12", "24", "60", "120"]}
        />
      </Row>
    </div>
  );
};

const PaginationStyle = styled(Pagination)`
  .ant-pagination-item-link {
    ${tw`flex items-center justify-center`};
  }
`;

const Card = styled.div<{ active: number }>`
  border: 1px solid ${(props) => (props.active ? "#f82602" : "#c1c5cb66")};
  width: 100%;
  height: 160px;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: #fbfbfb;
  cursor: pointer;
  font-size: xx-large;

  &:hover {
    border-color: #f82602;
    .title {
      color: white;
    }
    .oblique {
      background-color: #f82602;
    }
  }
`;

const CardAction = ({
  onClick,
  activeKey,
  data,
}: {
  onClick: (select: number) => void;
  activeKey: number[];
  data: IDoor;
}) => {
  const { door, setDoor } = useDoorRealtime();
  const checkText = activeKey.some((e) => e === data.id);
  const status = findStatus(data.doorState);
  const [open, setOpen] = useState<boolean>(status === "success");
  const [statusBadge, setStatusBadge] = useState<statusBadge>(status);
  useEffect(() => {
    if (data.id === door?.door?.id) {
      setOpen(true);
      setStatusBadge("success");

      setTimeout(() => {
        setOpen(false);
        setStatusBadge("error");
        setDoor?.(undefined);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [door]);

  return (
    <Badge
      status={statusBadge}
      dot
      className="m-0 p-0 w-full h-full"
      style={{ width: 8, height: 8 }}
    >
      <Card
        active={checkText ? 1 : 0}
        onClick={() => onClick(data.id)}
        className="rounded-app"
      >
        <Col className="flex flex-col justify-center w-full">
          <div className="flex justify-center w-full">
            {open ? (
              <CImage
                width={100}
                height="auto"
                src={OpenDoor}
                placeholder={<div></div>}
              />
            ) : (
              <CImage
                width={100}
                height="auto"
                src={Door}
                placeholder={<div></div>}
              />
            )}
          </div>
          <div className="flex flex-col justify-end w-full text-center mt-2">
            <Typography.Text
              ellipsis={{ tooltip: data.doorName }}
              className=" text-[14px]"
            >
              {data.doorName}
            </Typography.Text>
          </div>
        </Col>
      </Card>
    </Badge>
  );
};

type statusBadge = "success" | "processing" | "default" | "error" | "warning";

const findStatus = (status: doorStatus): statusBadge => {
  switch (status) {
    case "CLOSED":
      return "error";
    case "OPEN":
      return "success";
    case "REMAIN_CLOSED":
      return "error";
    case "REMAIN_OPEN":
      return "success";
    default:
      return "default";
  }
};

export default DoorListPage;
