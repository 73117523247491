import { ComponentType, createContext, useContext, useState } from "react";
import { IRole } from "services/role/interface";
import { IRoleContext } from "./interface";

const Context = createContext<IRoleContext>({} as IRoleContext);

const withRoleProvider = (Component: ComponentType) => {
  return () => {
    const [selectedRole, setSelectedRole] = useState<IRole>();
    const [visible, setVisible] = useState<boolean>(false);

    const toggle = () => {
      setVisible(!visible);
    };

    return (
      <Context.Provider
        value={{ selectedRole, setSelectedRole, visible, toggle }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useRole = () => useContext(Context);
export default withRoleProvider;
