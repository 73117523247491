export const MenuVehicleReportIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.373"
      height="25.373"
      viewBox="0 0 25.373 25.373"
      {...props}
    >
      <g data-name="vuesax/bold/document-text" transform="translate(-172 -188)">
        <g fill="#fff" transform="translate(172 188)">
          <path
            d="M1.12.195A.654.654 0 000 .635v3.49a2.726 2.726 0 002.75 2.67c.95.01 2.27.01 3.4.01a.631.631 0 00.47-1.07c-1.44-1.45-4.02-4.06-5.5-5.54z"
            transform="translate(16.053 2.015)"
          ></path>
          <path
            d="M19.3 8.752h-3.1a4.61 4.61 0 01-4.611-4.6V1.069A1.074 1.074 0 0010.519 0H5.973A5.649 5.649 0 000 5.952v9.468a5.649 5.649 0 005.973 5.952H14.4a5.649 5.649 0 005.973-5.952v-5.6A1.074 1.074 0 0019.3 8.752zm-9.65 8.079H5.361a.8.8 0 110-1.6H9.65a.8.8 0 110 1.6zm2.145-4.275H5.361a.8.8 0 110-1.6h6.434a.8.8 0 110 1.6z"
            data-name="Vector"
            transform="translate(2.5 2)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuVehicleOverviewIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.182"
      height="27.182"
      viewBox="0 0 27.182 27.182"
      {...props}
    >
      <g data-name="vuesax/bold/element-3" transform="translate(-620 -252)">
        <g fill="#fff" transform="translate(620 252)">
          <path
            d="M9.627 7.384V2.243C9.627.646 8.9 0 7.1 0H2.526C.725 0 0 .646 0 2.243v5.13c0 1.608.725 2.243 2.526 2.243H7.1c1.8.011 2.527-.635 2.527-2.232z"
            transform="translate(15.29 2.265)"
          ></path>
          <path
            d="M9.627 7.1V2.526C9.627.725 8.9 0 7.1 0H2.526C.725 0 0 .725 0 2.526V7.1c0 1.8.725 2.527 2.526 2.527H7.1c1.8 0 2.527-.727 2.527-2.527z"
            data-name="Vector"
            transform="translate(15.29 15.29)"
          ></path>
          <path
            d="M9.627 7.384V2.243C9.627.646 8.9 0 7.1 0H2.526C.725 0 0 .646 0 2.243v5.13c0 1.608.725 2.243 2.526 2.243H7.1c1.8.011 2.527-.635 2.527-2.232z"
            data-name="Vector"
            transform="translate(2.265 2.265)"
          ></path>
          <path
            d="M9.627 7.1V2.526C9.627.725 8.9 0 7.1 0H2.526C.725 0 0 .725 0 2.526V7.1c0 1.8.725 2.527 2.526 2.527H7.1c1.8 0 2.527-.727 2.527-2.527z"
            data-name="Vector"
            transform="translate(2.265 15.29)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuVehicleInOutIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="vuesax/bold/truck-time" transform="translate(-172 -252)">
        <g fill="#fff">
          <path
            d="M19.5 10.5a.5.5 0 01.5.5v1a3 3 0 01-3 3 3 3 0 00-6 0H9a3 3 0 00-6 0 3 3 0 01-3-3v-2a1 1 0 011-1h9.5A2.5 2.5 0 0013 6.5V1a1 1 0 011-1h.84a2.016 2.016 0 011.74 1.01l.64 1.12a.252.252 0 01-.22.37A2.5 2.5 0 0014.5 5v3a2.5 2.5 0 002.5 2.5z"
            transform="translate(174 257)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(178 270)"
          ></path>
          <path
            d="M4 2a2 2 0 11-2-2 2 2 0 012 2z"
            data-name="Vector"
            transform="translate(186 270)"
          ></path>
          <path
            d="M4 3.53V5H1a1 1 0 01-1-1V1a1 1 0 011-1h1.29l1.45 2.54a2 2 0 01.26.99z"
            data-name="Vector"
            transform="translate(190 261)"
          ></path>
          <path
            d="M11.08 0H3.69A3.687 3.687 0 000 3.69v6.39a1 1 0 001 1h9.15A1.852 1.852 0 0012 9.23V.92a.918.918 0 00-.92-.92zm-3.7 5.91a.751.751 0 01-.36.64l-1.25.75a.724.724 0 01-.39.11.754.754 0 01-.64-.36.746.746 0 01.25-1.03l.89-.53V4.41a.75.75 0 111.5 0z"
            data-name="Vector"
            transform="translate(174 254)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuVehicleMangeVehicleIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.113"
      height="23.113"
      viewBox="0 0 23.113 23.113"
      {...props}
    >
      <g data-name="vuesax/bold/car" transform="translate(-172 -188)">
        <g fill="#fff" transform="translate(172 188)">
          <path
            d="M18.613 5.92a.739.739 0 01-.716.75H.716a.751.751 0 010-1.5h.974l.363-1.81C2.4 1.61 3.112 0 5.956 0h6.7c2.844 0 3.56 1.61 3.9 3.36l.363 1.81h.981a.739.739 0 01.713.75z"
            transform="translate(2.25 1.08)"
          ></path>
          <path
            d="M20.068 3.41C19.925 1.76 19.5 0 16.425 0H4.191C1.114 0 .7 1.76.548 3.41L.011 9.5a2.8 2.8 0 00.671 2.09 2.641 2.641 0 001.994.91h1.8a1.859 1.859 0 002.042-1.54l.192-.6c.221-.69.278-.86 1.141-.86h4.909c.863 0 .892.1 1.141.86l.192.6a1.859 1.859 0 002.042 1.54h1.8a2.656 2.656 0 001.994-.91A2.8 2.8 0 0020.6 9.5zM7.432 5.5H4.556a.751.751 0 010-1.5h2.876a.751.751 0 010 1.5zm8.629 0h-2.877a.751.751 0 010-1.5h2.876a.751.751 0 010 1.5z"
            data-name="Vector"
            transform="translate(1.248 9.363)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MenuVehicleManageParkingIcon = (
  props?: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.723"
      height="23.153"
      viewBox="0 0 16.723 23.153"
      {...props}
    >
      <path
        fill="#fff"
        d="M14.734 3H8.573A2.58 2.58 0 006 5.573V23.58a2.573 2.573 0 105.145 0v-5.145H15A7.726 7.726 0 0022.657 9.7 7.906 7.906 0 0014.734 3zm.527 10.29h-4.116V8.145h4.116a2.573 2.573 0 010 5.145z"
        data-name="Path 30808"
        transform="translate(-6 -3)"
      ></path>
    </svg>
  );
};

export const MenuDisplayScreen = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={682.667}
    height={682.667}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M20.3 26.8C10.3 30.3 3.2 38.3 1 48.4.2 52 0 98.1.2 207l.3 153.5 2.8 5.7c3.2 6.4 8 11.1 14.6 14.2l4.6 2.1h467l4.6-2.1c6.6-3.1 11.4-7.8 14.6-14.2l2.8-5.7v-313l-2.8-5.7c-3.2-6.4-8-11.1-14.6-14.2l-4.6-2.1-232-.2c-220.7-.2-232.3-.2-237.2 1.5zM177 435v22h-42c-40.2 0-42.3.1-46 2-4.5 2.3-8 8-8 13 0 4.8 3.3 10.3 7.8 12.8l4 2.2h163.1c161.6 0 163.2 0 167.1-2 4.5-2.3 8-8 8-13s-3.5-10.7-8-13c-3.7-1.9-5.8-2-46-2h-42v-44H177v22z" />
  </svg>
  );
};
