import { Form } from "antd";
import useMouse from "hooks/useMouse";
import { useVehicleFloorEdit } from "provider/vehicle-floor-edit";
import { FC, useRef, useState } from "react";
import ImageMarker, { Marker } from "react-image-marker";
import { IMark } from "services/mark/interface";
import MarkerPinCreateAndEditModal from "./create-and-edit-modal";
import FloorEditMapPointer from "./map-pointer";
import MapPinMarkerComponent from "./marker-component";

interface FloorEditMapPinMarkerProps {}

const FloorEditMapPinMarker: FC<FloorEditMapPinMarkerProps> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();

  const { floor, cursorType, onDeleteMark } = useVehicleFloorEdit();
  const [visible, setVisible] = useState<boolean>(false);
  const [selectMark, setSelectMark] = useState<IMark>();
  const cursor = useMouse({ ref, prevent: cursorType !== 'add' });

  const onAddMarker = (mark: Marker) => {
    const { top, left } = mark;
    if (cursorType === "add") {
      form.setFieldsValue({ xAxis: `${left}`, yAxis: `${top}` });
      return toggle();
    }
  };

  const toggle = () => {
    setVisible(!visible);
  };

  const getMarkers = () => {
    return (floor.marks || []).map((item) => {
      return { ...item, top: Number(item.yAxis), left: Number(item.xAxis) };
    });
  };

  const markers = getMarkers();

  return (
    <div ref={ref} className="h-full">
      <MarkerPinCreateAndEditModal
        form={form}
        visible={visible}
        onClose={toggle}
        mark={selectMark}
        resetMark={() => setSelectMark(undefined)}
      />
      <FloorEditMapPointer
        left={cursor?.x}
        top={cursor?.y}
        hidden={!cursor || cursorType !== "add"}
      />
      <ImageMarker
        extraClass="!h-full"
        onAddMarker={onAddMarker}
        src={floor.mediaObject?.url || ""}
        markerComponent={(props: any) => {
          const mark = props as IMark;
          return (
            <MapPinMarkerComponent
              cursorPointer={cursorType === "delete" || cursorType === "edit"}
              mark={mark}
              animationKey={markers.length}
              onClick={() => {
                if (cursorType === "delete") {
                  return onDeleteMark(mark.id);
                }
                if (cursorType === "edit") {
                  setSelectMark(mark);
                  return toggle();
                }
              }}
            />
          );
        }}
        markers={markers}
      />
    </div>
  );
};

export default FloorEditMapPinMarker;
