import { Col, Form, Input, Row, Typography } from "antd";
import styled from "styled-components";
import Icon from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { LoginSSBackgroundImage } from "assets/images";
import { LogoIcon, UserIcon, PasswordIcon } from "assets/icons/web";
import CButton from "components/button/c-button";
import { setToken, useLogin } from "services/auth";
import { fireNotification } from "components/popup/notification";
import { useNavigate } from "react-router-dom";

const PageBackground = styled.div`
  // background: rgb(47, 154, 93);
  // background: linear-gradient(
  //   180deg,
  //   rgba(47, 154, 93, 1) 0%,
  //   rgba(3, 8, 18, 1) 100%
  // );
  background-color: #2f9a5d;
  height: 100vh;
`;

const SubBackgroubd = styled.div`
  background-image: url(${LoginSSBackgroundImage});
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 100%;
  position: absolute;
  left: -25%;
  right: 0px;
  top: 0px;
  bottom: 0px;
  // opacity: 0.2;
`;

const StyleInput = styled(Input)`
  height: 60px;
  background-color: white !important;
  border: 1px solid #b1b3b8;
  &:focus {
    border: 1px solid #b1b3b8;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid #b1b3b8;
  }

  .ant-input:hover {
    border-color: #d8dadf;
  }
  .ant-input:focus {
    border-color: #d8dadf;
    box-shadow: unset;
  }
  .ant-input {
    background-color: unset;
    color: #b9bbbf;
  }
  .ant-input-prefix {
    justify-content: center;
    width: 40px;
    border-right: 1px solid #b1b3b869;
    padding-right: inherit;
    margin-right: 16px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
`;
const StyleInputPassword = styled(Input.Password)`
  height: 60px;
  background-color: white;
  border: 1px solid #b1b3b8;
  color: #d8dadf;
  &:focus {
    border: 1px solid #b1b3b8;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid #b1b3b8;
  }

  .ant-input:hover {
    border-color: #d8dadf;
  }
  .ant-input:focus {
    border-color: #d8dadf;
    box-shadow: unset;
  }
  .ant-input {
    background-color: unset;
    color: #b9bbbf;
  }
  .ant-input-prefix {
    justify-content: center;
    width: 40px;
    border-right: 1px solid #b1b3b869;
    padding-right: inherit;
    margin-right: 16px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .ant-input-password-icon {
    color: white;
    cursor: pointer;
    transition: all 0.3s;
  }
  .anticon-eye-invisible {
    svg * {
      fill: #b9bbbf;
    }
  }
`;

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, isLoading } = useLogin();

  const onLogin = (values: any) => {
    mutate(values, {
      onSuccess: (data) => {
        setToken(data.accessToken);
        // localStorage.setItem('DGF:token', data.digital.accessToken)
        navigate("/", { replace: true });
      },
      onError: ({ message }: any) => {
        fireNotification({ type: "error", description: message });
      },
    });
  };

  return (
    <PageBackground>
      <SubBackgroubd />
      <Layout>
        <Form className="w-full" layout="vertical" onFinish={onLogin}>
          <Form.Item className="text-center">
            <Icon component={LogoIcon} style={{ fontSize: 180 }} />
          </Form.Item>
          <Form.Item>
            <Typography.Title
              className="!text-white text-center !mb-1"
              level={2}
            >
              {t("welcome", { ns: "message" })}
            </Typography.Title>
            <Typography.Title className="!text-white text-center" level={5}>
              {t("login-to-your-account-to-continue", { ns: "message" })}
            </Typography.Title>
          </Form.Item>
          <Form.Item name="username" rules={[{ required: true }]}>
            <StyleInput
              readOnly={isLoading}
              placeholder={t("username")}
              prefix={<Icon component={UserIcon} className="text-3xl" />}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]}>
            <StyleInputPassword
              readOnly={isLoading}
              placeholder={t("password")}
              prefix={<Icon component={PasswordIcon} className="text-3xl" />}
            />
          </Form.Item>
          <Form.Item>
            <CButton
              loading={isLoading}
              htmlType="submit"
              style={{ height: 60 }}
              className="w-full"
              theme="yellow"
            >
              <Typography.Title level={5}>{t("login")}</Typography.Title>
            </CButton>
          </Form.Item>
        </Form>
      </Layout>
    </PageBackground>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Row className="h-full">
      <Col xs={1} md={4} lg={8} xxl={9}></Col>
      <Col xs={22} md={16} lg={8} xxl={6} className="center">
        {children}
      </Col>
      <Col xs={1} md={4} lg={8} xxl={9}></Col>
    </Row>
  );
};

export default LoginPage;
