import { Form } from "antd";
import CDrawer from "components/display/c-drawer";
import CInput from "components/input/c-input";
import { usePageRoute } from "provider/page-route";
import { useVehicleFloor } from "provider/vehicle-floor";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePatchMenuLevel, usePostMenuLevel } from "services/menu-level";
import { IMenuLevel } from "services/menu-level/interface";

interface FloorAddLevelDrawerProps {
  visible: boolean;
  onClose: () => void;
  onAfterFinish: () => void;
  menuLevel?: IMenuLevel;
}

const FloorAddLevelDrawer: FC<FloorAddLevelDrawerProps> = ({
  visible,
  onClose,
  onAfterFinish,
  menuLevel,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { topLevel } = useVehicleFloor();
  const { dispatch } = usePageRoute();

  const post_level = usePostMenuLevel();
  const patch_level = usePatchMenuLevel();

  useEffect(() => {
    if (menuLevel) {
      form.setFieldsValue(menuLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuLevel]);

  const onBeforeSubmit = (values: any) => {
    if (menuLevel) {
      return { ...values, id: menuLevel.id };
    }
    return { ...values, levelNumber: topLevel + 1 };
  };

  const onAfterSubmit = (values: any) => {
    dispatch({
      action: "updateCurrentPageParams",
      params: {
        levelName: values.levelName,
        topLevel: menuLevel ? topLevel : topLevel + 1,
      },
    });
    onAfterFinish();
  };

  return (
    <CDrawer
      title={menuLevel ? t("edit-level") : t("add-level")}
      visible={visible}
      onClose={onClose}
      form={form}
      afterVisibleChange={(e) => {
        if (!e) {
          return form.resetFields();
        }
      }}
      mutation={{
        mutate: menuLevel ? patch_level : post_level,
        invalidateQueries: ["get-menu-levels", "get-menu-level"],
        onBeforeSubmit: onBeforeSubmit,
        onAfterSubmit: onAfterSubmit,
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item hidden name="id">
          <CInput />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label={t("level-name")}
          name="levelName"
        >
          <CInput placeholder={t("level-name")} />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default FloorAddLevelDrawer;
