import { InspectionTypes } from "services/vehicle-condition/interface";
import styled from "styled-components";
import tw from "twin.macro";
import InspectionBoardCard from "./board-card";

export interface IVehicleInspectionMenu {
  menuKey: InspectionTypes;
  title: string;
}

const menus: IVehicleInspectionMenu[] = [
  {
    menuKey: "WAITING_FOR_INSPECT",
    title: "waiting-for-inspect",
  },
  {
    menuKey: "PASSED_THE_INSPECTION",
    title: "passed-the-inspection",
  },
  {
    menuKey: "FAILED_THE_1ST_INSPECTION",
    title: "failed-the-1st-inspection",
  },
  {
    menuKey: "FAILED_THE_2ND_INSPECTION",
    title: "failed-the-2nd-inspection",
  },
  {
    menuKey: "FAILED_THE_3RD_INSPECTION",
    title: "failed-the-3rd-inspection",
  },
  {
    menuKey: "VIP",
    title: "vip",
  },
];

const Wrapper = styled.div`
  height: calc(100vh - 219px);
  overflow: auto;
  white-space: nowrap;
  cursor: grab;
  ${tw`p-5`};
`;

const VehicleInspectionContent = () => {
  return (
    <Wrapper>
      {menus.map((item) => {
        return <InspectionBoardCard key={item.menuKey} {...item} />;
      })}
    </Wrapper>
  );
};

export default VehicleInspectionContent;
