import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetScrollMarks } from "services/mark";
import { IMark } from "services/mark/interface";
import CSelect, { CSelectProps } from ".";

export interface CSelectEntranceAndDepartureCameraProps extends CSelectProps {
  barrierType: "IN" | "OUT";
  onSelectCallback?: (v: IMark) => void;
}

const CSelectEntranceAndDepartureCamera: FC<
  CSelectEntranceAndDepartureCameraProps
> = ({ barrierType, onSelectCallback, ...props }) => {
  const { t } = useTranslation();

  return (
    <CSelect.Pagination
      placeholder={t("equipment")}
      useQuery={useGetScrollMarks}
      queryParams={{ barrierType: barrierType }}
      {...props}
      valueKey="id"
      titleKey="markName"
      onSelectCallback={onSelectCallback}
    />
  );
};

export default CSelectEntranceAndDepartureCamera;
