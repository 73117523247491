import { Col, Row, Typography } from "antd";
import CAvatar from "components/display/c-avatar";
import CImage from "components/display/c-image";
import { useTranslation } from "react-i18next";
import { INotificationHeader } from "services/notification-header/interface";
import styled from "styled-components";

const SubCardContainer = styled.div<{ background: boolean; height?: string }>`
  height: ${({ height }) => (height ? height : "50px")};
  background-color: white;
  cursor: pointer;
`;

export const SubCard = ({ item }: { item: INotificationHeader }) => {
  switch (item.type) {
    case "FAILED_THE_3RD_OF_VEHICLE_CONDITION":
      return <SubCardVehicle item={item} />;
    case "VIP_REJECT_CAR":
      return <SubCardVehicle item={item} />;
    case "LICENSE_PLATE_DOES_NOT_PASS":
      return <SubCardVehicleNotPass item={item} />;
    default:
      return <SubCardFace item={item} />;
  }
};

export const SubCardFace = ({ item }: { item: INotificationHeader }) => {
  const { t } = useTranslation();
  const { doorLog, createdAt, type } = item;
  const { firstName, lastName, temperature, door, imageUrl } = doorLog || {};
  // const { firstName, lastName, profile } = customerEmployee || {};
  const name = firstName && lastName ? firstName + " " + lastName : "-";
  // const src = profile?.url || "";
  return (
    <SubCardContainer background={!item.read} className="px-2" height={"100%"}>
      <Row align="middle" className="h-full ">
        <Col span={4}>
          <CAvatar src={imageUrl} />
        </Col>
        <Col span={20}>
          <Typography.Title style={{ fontSize: 13, color: "#f82602" }}>
            {name}
          </Typography.Title>
          <Typography.Text style={{ fontSize: 12, color: "#4e4e4e" }}>
            {t("door")} : {door?.doorName || "-"}
          </Typography.Text>
          {type === "TEMPERATURE_DOES_NOT_PASS" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              {t("temperature")} : {temperature}
            </div>
          )}
          <div style={{ fontSize: 12, color: "#4e4e4e" }}>
            {t("date")} : {createdAt}
          </div>
        </Col>
      </Row>
    </SubCardContainer>
  );
};

export const SubCardVehicle = ({ item }: { item: INotificationHeader }) => {
  const { t } = useTranslation();
  const { vehicleCondition, createdAt, type } = item;
  const { description, vehicle } = vehicleCondition || {};
  const { vehicleDetects } = vehicle || {};
  const url =
    vehicleDetects?.sort((a, b) => b.id - a.id)?.[0]?.detectImage?.url || "";

  return (
    <SubCardContainer background={!item.read} className="px-2" height="100%">
      <Row align="middle" className="h-full ">
        <Col span={6}>
          <CImage
            src={url}
            preview={false}
            width={80}
            height={49}
            style={{ objectFit: "unset" }}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={17}>
          <Typography.Title style={{ fontSize: 13, color: "#f82602" }}>
            {vehicle?.licensePlate || "-"}
          </Typography.Title>
          {/* {type === "FAILED_THE_3RD_OF_VEHICLE_CONDITION" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              {t("failed-vehicle-inspection-more-than-3-time")}
            </div>
          )}
          {type === "LICENSE_PLATE_DOES_NOT_PASS" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              {t("license-plate-does-not-pass")}
            </div>
          )} */}
          <div style={{ fontSize: 12, color: "#4e4e4e" }}>
            {t("date")} : {createdAt}
          </div>
          {type === "VIP_REJECT_CAR" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              {t("callback-note")} : {description || "-"}
            </div>
          )}
        </Col>
      </Row>
    </SubCardContainer>
  );
};

export const SubCardVehicleNotPass = ({
  item,
}: {
  item: INotificationHeader;
}) => {
  const { t } = useTranslation();
  const { licensePlate, licensePlateImage, type, createdAt, read } = item;

  const url = licensePlateImage?.url || "";

  return (
    <SubCardContainer background={!read} className="px-2" height="100%">
      <Row align="middle" className="h-full ">
        <Col span={6}>
          <CImage
            src={url}
            preview={false}
            width={80}
            height={49}
            style={{ objectFit: "unset" }}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={17}>
          <Typography.Title style={{ fontSize: 13, color: "#f82602" }}>
            {licensePlate || "-"}
          </Typography.Title>
          {type === "LICENSE_PLATE_DOES_NOT_PASS" && (
            <div style={{ fontSize: 12, color: "#4e4e4e" }}>
              {t("license-plate-does-not-pass")}
            </div>
          )}
          <div style={{ fontSize: 12, color: "#4e4e4e" }}>
            {t("date")} : {createdAt}
          </div>
        </Col>
      </Row>
    </SubCardContainer>
  );
};
