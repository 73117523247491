import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  Divider,
  Radio,
  RadioChangeEvent,
  Row,
  Typography,
} from "antd";
import Form, { FormInstance } from "antd/lib/form";
import CButton from "components/button/c-button";
import CDrawer from "components/display/c-drawer";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import PleaseSelectDataPage from "components/pages/select-data-page";
import { fireNotification } from "components/popup/notification";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  usePatchSettingQuickLap,
  usePostSettingQuickLap,
} from "services/setting-parameter-of-quick-lap";
interface POQCreateAndEditDrawerProps {
  visible: boolean;
  onClose: () => void;
  form: FormInstance;
}

const POQCreateAndEditDrawer: FC<POQCreateAndEditDrawerProps> = ({
  visible,
  onClose,
  form,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(false);
  const [key, setKey] = useState<number | undefined>();
  const [select, setSelect] = useState(true);
  const qClient = useQueryClient();
  const post_data = usePostSettingQuickLap();
  const patch_data = usePatchSettingQuickLap();
  const [subtitle, setSubtitle] = useState("dont-have-sub-topic");

  useEffect(() => {
    const values = Object.values(form.getFieldsValue()).filter((e) => e);
    if (!values.length) return;
    form.setFieldsValue({});

    const data = form.getFieldsValue();
    setValue(data.hasSecondaryTopic);
    let v = { ...data };
    if (data.hasSecondaryTopic) {
      // eslint-disable-next-line array-callback-return
      data?.topics?.map((item: any, index: number) => {
        if (item.title == null) {
          form.setFieldsValue({ topics: [] });
        }
        v = { ...v, [`secondTopics${index}`]: item.secondTopics };
        setSelect(false);
      });
    } else {
      const secondTopics = data?.topics?.[0]?.secondTopics;
      v = { ...v, secondTopics };
      setSelect(true);
      setSubtitle("dont-have-sub-topic");
    }
    form.setFieldsValue(v);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    const data = form.getFieldsValue();
    // eslint-disable-next-line array-callback-return
    data?.topics?.map((item: any) => {
      if (!item.title) {
        form.setFieldsValue({ topics: [] });
      }
    });
    if (!e.target.value) {
      setSelect(true);
      setKey(undefined);
      setSubtitle("dont-have-sub-topic");
    }
    if (e.target.value) {
      setSelect(false);
    }
  };

  const onFinish = (values: any) => {
    if (!(typeof values.isActive === "boolean")) values.isActive = true;
    if (!(typeof values.hasSecondaryTopic === "boolean"))
      values.hasSecondaryTopic = false;
    const topics = form.getFieldValue("topics");

    if (value) {
      const lists = topics?.map((item: any, index: number) => {
        const data = form.getFieldValue(`secondTopics${index}`);
        const mapData = data?.map((e: any) => {
          if (!(typeof e?.checkSign === "string")) {
            e = { ...e, checkSign: "PASSED" };
          }
          return e;
        });
        return { ...item, secondTopics: mapData };
      });
      values = { ...values, topics: lists };
    } else {
      const secondTopics = values?.secondTopics?.map((e: any) => {
        if (!(typeof e.checkSign === "string")) {
          e = { ...e, checkSign: "PASSED" };
        }
        return e;
      });
      values = { ...values, topics: [{ secondTopics: secondTopics }] };
    }

    const { id } = values;
    if (id) {
      return patch_data.mutate(values, {
        onSuccess: () => {
          fireNotification({ type: "success" });
          qClient.invalidateQueries(["get-qucik-laps"]);
          onClose();
          setValue(false);
          setKey(undefined);
          setSubtitle("dont-have-sub-topic");
          form.resetFields();
        },
        onError: ({ meesage }: any) => {
          fireNotification({ type: "error", description: meesage });
        },
      });
    }
    return post_data.mutate(values, {
      onSuccess: () => {
        fireNotification({ type: "success" });
        qClient.invalidateQueries(["get-qucik-laps"]);
        form.resetFields();
        onClose();
        setSubtitle("dont-have-sub-topic");
        setValue(false);
        setKey(undefined);
      },
      onError: ({ meesage }: any) => {
        fireNotification({ type: "error", description: meesage });
      },
    });
  };

  const changeTitle = (v: any) => {
    setSubtitle(v.target.value);
  };

  const titleForm = form.getFieldValue("id")
    ? t("edit-parameter")
    : t("add-parameter");

  return (
    <CDrawer
      form={form}
      title={titleForm}
      width="70vw"
      visible={visible}
      onClose={() => {
        form.resetFields();
        setValue(false);
        setKey(undefined);
        setSubtitle("dont-have-sub-topic");
        onClose();
      }}
      className="!p-2"
      onSave={onFinish}
      loading={patch_data.isLoading || post_data.isLoading}
    >
      <Row gutter={[16, 0]}>
        <Col span={7} className="bg-white !px-[24px] !py-[16px] h-[100]">
          <Form form={form} layout="vertical">
            <Form.Item name="id" hidden>
              <CInput />
            </Form.Item>
            <Typography.Title level={5} className="!text-green-600 pb-2">
              {t("main-topic")}
            </Typography.Title>
            <Form.Item
              rules={[{ required: true }]}
              name="parameterNameTh"
              label={`${t("parameter-name")} (TH)`}
            >
              <CInput placeholder={`${t("parameter-name")} (EN)`} />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              name="parameterNameEn"
              label={`${t("parameter-name")} (EN)`}
            >
              <CInput placeholder={`${t("parameter-name")} (EN)`} />
            </Form.Item>
            <Form.Item label={t("status")} name="isActive" initialValue={true}>
              <CSelect
                placeholder={t("status")}
                valueOptions={{
                  values: [
                    { id: true, title: t("active") },
                    { id: false, title: t("in-active") },
                  ],
                  valueKey: "id",
                  titleKey: "title",
                }}
              />
            </Form.Item>
            <Form.Item name="hasSecondaryTopic" noStyle initialValue={false}>
              <Radio.Group onChange={onChange} value={value} className="!mb-4">
                <Radio value={false}>{t("dont-have-sub-topic")}</Radio>
                <Radio value={true}>{t("have-sub-topic")}</Radio>
              </Radio.Group>
            </Form.Item>
            <div hidden={!value}>
              <Form.List name="topics">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <div
                        style={{ overflowY: "auto" }}
                        className="mb-[7px] !max-h-[295px]"
                      >
                        {fields.map(({ key, name, ...restField }) => {
                          return (
                            <Row key={key} className="my-3">
                              <Col span={21}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "title"]}
                                  rules={[{ required: !!value }]}
                                  noStyle
                                >
                                  <CInput
                                    onClick={(v: any) => {
                                      setKey(name);
                                      setSubtitle(v.target.value);
                                      setSelect(true);
                                    }}
                                    onChange={changeTitle}
                                    className="w-full"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={3} className="p-3">
                                <CloseCircleFilled
                                  onClick={() => {
                                    remove(name);
                                    setKey(undefined);
                                    form.setFieldsValue({
                                      ["secondTopics" + name]: [],
                                    });
                                    setSelect(false);
                                  }}
                                  style={{ color: "#DBDBDB", fontSize: "20px" }}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                      <div>
                        <CButton
                          block
                          type="dashed"
                          theme="white"
                          children={t("add-sup-topic")}
                          icon={<PlusOutlined />}
                          onClick={() => add()}
                        />
                      </div>
                    </>
                  );
                }}
              </Form.List>
            </div>
          </Form>
        </Col>
        <Col>
          <Divider
            type="vertical"
            style={{ height: "calc(100vh - 160px" }}
            className="!m-0"
          />
        </Col>
        <Col span={16} className="!px-[24px] !py-[16px]">
          {select ? (
            <div>
              <Form
                form={form}
                hidden={value ? !(value && typeof key === "number") : value}
              >
                <Typography.Title level={5} className="!text-green-600">
                  {t(subtitle)}
                </Typography.Title>
                <Form.List
                  name={
                    value && typeof key === "number"
                      ? `secondTopics${key}`
                      : "secondTopics"
                  }
                >
                  {(fields, { add, remove }) => (
                    <>
                      <div
                        style={{ overflowY: "auto" }}
                        className="mb-[7px] !max-h-[450px] w-[100%]"
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            // align="middle"
                            key={key}
                            gutter={[16, 0]}
                            className="my-4 w-[100%] text-[20px]"
                          >
                            <Col span={1} className="!flex items-center">
                              {name + 1}
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                {...restField}
                                name={[name, "min"]}
                                rules={[{ required: true }]}
                                noStyle
                              >
                                <CInput.Number
                                  placeholder={t("default-setting")}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={1} className="!flex items-center">
                              <span>-</span>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                {...restField}
                                name={[name, "max"]}
                                rules={[{ required: true }]}
                                noStyle
                              >
                                <CInput.Number
                                  placeholder={t("maximum-setting")}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...restField}
                                name={[name, "message"]}
                                noStyle
                              >
                                <CInput
                                  placeholder={t("add-message")}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                {...restField}
                                name={[name, "checkSign"]}
                                noStyle
                              >
                                <CSelect
                                  defaultValue="PASSED"
                                  placeholder={t("checkSign")}
                                  valueOptions={{
                                    values: [
                                      { id: "PASSED", title: t("pass") },
                                      { id: "FAILED", title: t("not-pass") },
                                      {
                                        id: "VIP",
                                        title: t("conditionally-passed"),
                                      },
                                    ],
                                    valueKey: "id",
                                    titleKey: "title",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={1} className="!flex items-center">
                              <CloseCircleFilled
                                onClick={() => remove(name)}
                                style={{ color: "#DBDBDB", fontSize: "22px" }}
                              />
                            </Col>
                          </Row>
                        ))}
                      </div>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          className="!h-[45px] mt-2 !text-[20px]"
                          icon={<PlusOutlined />}
                        >
                          {t("add-configure")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          ) : (
            <PleaseSelectDataPage />
          )}
        </Col>
      </Row>
    </CDrawer>
  );
};
export default POQCreateAndEditDrawer;
