import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useXScrollGetSupplierTypes } from "services/supplier-type";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

const CSelectJobSupplier: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useXScrollGetSupplierTypes}
      valueKey="id"
      titleKey="title"
      searchKey="title"
      placeholder={t("job-type-supplier")}
      translation
    />
  );
};
export default CSelectJobSupplier;
