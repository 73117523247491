import { Form, TableColumnsType } from "antd";
import CDrawer from "components/display/c-drawer";
import CTable from "components/display/c-table";
import CInput from "components/input/c-input";
import { useVehicleFloorEdit } from "provider/vehicle-floor-edit";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEncodeDeviceFloorParams } from "services/encode-device/interface";
import {
  useAddDeviceToFloor,
  useGetAvailableDevice,
} from "services/floor";

interface FloorEditDeviceDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const FloorEditDeviceDrawer: FC<FloorEditDeviceDrawerProps> = ({
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { floor } = useVehicleFloorEdit();

  const [params, setParams] = useState<IEncodeDeviceFloorParams>({
    floorId: floor.id,
    page: 1,
    limit: 10,
  });

  const wSearch = Form.useWatch("search", form);

  const { data, isFetching } = useGetAvailableDevice({ devName: wSearch });
  const patch_device = useAddDeviceToFloor();

  const columns: TableColumnsType = [
    {
      title: t("equipment-name"),
      dataIndex: "devName",
    },
    {
      title: t("internet-protocol-address"),
      dataIndex: "devIp",
    },
    {
      title: t("model"),
      dataIndex: "treatyType",
    },
    {
      title: t("port"),
      dataIndex: "devPort",
    },
  ];

  //
  return (
    <CDrawer
      title={t("equipment-list")}
      visible={visible}
      onClose={onClose}
      form={form}
      afterVisibleChange={(e) => {
        if (!e) {
          form.resetFields();
        }
      }}
      mutation={{
        mutate: patch_device,
        invalidateQueries: ["get-encode-devices", "get-encode-device"],
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ deviceIds: [], id: floor.id }}
      >
        <Form.Item name="search">
          <CInput.Debounce placeholder={t("search")} />
        </Form.Item>
        <Form.Item hidden name="id">
          <CInput />
        </Form.Item>
        <Form.Item name="deviceIds">
          <CTable.Select
            rowKey="id"
            columns={columns}
            loading={isFetching}
            dataSource={data?.data || []}
            pagination={{
              total: data?.count,
              current: params?.page,
              pageSize: params.limit,
              onChange: (page, limit) => {
                setParams({ ...params, page, limit });
              },
              size: "small",
            }}
          />
        </Form.Item>
      </Form>
    </CDrawer>
  );
};

export default FloorEditDeviceDrawer;
