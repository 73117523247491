import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { ISettingJobTypeParams, ISttingJobType } from "./interface";

export const useGetSettingJobType = (
  params?: ISettingJobTypeParams
): UseQueryResult<IResponse<ISttingJobType[]>> => {
  return useQuery(
    ["get-job-type", params],
    async () => {
      const res = await axios.get("/job-type", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const usePostJobType = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/job-type", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const usePatchJobTypeId = (): UseMutationResult => {
  return useMutation(async ({ id, ...values }: any) => {
    const res = await axios.patch(`/job-type/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useDeleteJobType = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/job-type/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
export const useDeleteJobTypeBulk = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/job-type/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const useScrollGetJobTypes = (
  params?: ISettingJobTypeParams
): UseInfiniteQueryResult<IResponse<ISttingJobType[]>, Error> => {
  return useInfiniteQuery(
    ["get-job-types", params],
    async ({ pageParam }) => {
      const res = await axios.get("/job-type", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const useScrollGetJobTypesSupplier = (
  params?: ISettingJobTypeParams
): UseInfiniteQueryResult<IResponse<ISttingJobType[]>, Error> => {
  return useInfiniteQuery(
    ["get-job-types", params],
    async ({ pageParam }) => {
      const res = await axios.get("/job-type", {
        params: { ...params, ...pageParam },
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};