import { IUserTypeSelectData } from "components/input/c-user-type";
import { ComponentType, createContext, useContext, useState } from "react";
import { ISprayCheckContext } from "./interface";

const Context = createContext<ISprayCheckContext>({} as ISprayCheckContext);

const withSprayCheckProvider = (Component: ComponentType) => {
  return () => {
    const [userType, setUserType] = useState<IUserTypeSelectData>({
      title: "user-management.supplier",
      userType: "SUPPLIER",
    });
    const [selectDate, setSelectDate] = useState<any>();

    return (
      <Context.Provider
        value={{ userType, setUserType, selectDate, setSelectDate }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useSprayCheckProvider = () => useContext(Context);
export default withSprayCheckProvider;
