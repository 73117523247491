import CImage from "components/display/c-image";
import styled from "styled-components";
import tw from "twin.macro";
import { AvatarHolderImage } from "assets/images";
import React, { useEffect, useRef } from "react";
import CaptureModal from "./capture-modal";
import withCaptureModalProvider from "provider/capture-modal";
import { Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import FilePicker, { FilePickerRef } from "components/input/file-picker";
import { fileToDataUrl } from "tools/file";

export interface CUploadCaptureProps {
  onChange?: (v: File) => void;
  value?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
}

const CUploadCaptureWrapper = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 1px solid rgb(220, 220, 220, 1);
  overflow: hidden;
  background-color: rgb(245, 245, 245, 1);
  ${tw`inline-flex items-center justify-center cursor-pointer`};
  ${tw`rounded-app`};
`;

const CUploadCapture: React.FC<CUploadCaptureProps> = ({
  value,
  onChange,
  width = "200px",
  height = "200px",
  disabled = false,
}) => {
  const { t } = useTranslation();

  const ref = useRef<FilePickerRef>(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [imageUrl, setImageUrl] = React.useState<undefined | string>();

  useEffect(() => {
    if (typeof value === "string") {
      setImageUrl(value);
    }
  }, [value]);

  const toggle = () => {
    if (!disabled) {
      setVisible(!visible);
    }
  };

  const onFilePickerChange = async (file: File) => {
    const image = await fileToDataUrl(file);
    if (typeof image !== "string") return;
    onChange?.(file);
    setImageUrl(image);
  };

  return (
    <React.Fragment>
      <FilePicker ref={ref} onChange={onFilePickerChange} />
      <CaptureModal
        onFinish={(file, base64) => {
          setImageUrl(base64);
          onChange?.(file);
        }}
        visible={visible}
        onClose={toggle}
      />
      <Dropdown
        disabled={disabled}
        getPopupContainer={(e) => e}
        overlay={
          <Menu>
            <Menu.Item
              key={1}
              onClick={() => {
                ref.current?.click();
              }}
            >
              {t("upload-image-files")}
            </Menu.Item>
            <Menu.Item key={2} onClick={toggle}>
              {t("face-scan")}
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <CUploadCaptureWrapper width={width} height={height}>
          <CImage
            className="!h-full !w-full"
            src={imageUrl || AvatarHolderImage}
            preview={false}
            // style={{ transform: "scaleX(-1)" }}
            // style={{ objectFit: "contain" }}
          />
        </CUploadCaptureWrapper>
      </Dropdown>
    </React.Fragment>
  );
};

export default withCaptureModalProvider(CUploadCapture);
