import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserIdCardTypes } from "services/user/interface";
import CRadio from ".";
import { CRadioGroupProps } from "./c-radio-group";

const CRadioCardNumber: FC<CRadioGroupProps> = (props) => {
  const { t } = useTranslation();

  const options: { label: string; value: UserIdCardTypes }[] = [
    { label: t("identification-card"), value: "IDENTIFICATION_CARD" },
    { label: t("other"), value: "OTHER" },
  ];

  return <CRadio.Group options={options} optionType="default" {...props} />;
};

export default CRadioCardNumber;
