import { socketUrl } from "config/axios";
import React, {
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IDoorParams } from "services/door/interface";
import { IDoorRealtimeContext, ITransitionDoorAccess } from "./interface";
import io from "socket.io-client";

const Context = createContext<IDoorRealtimeContext>({} as IDoorRealtimeContext);

const withDoorRealtime = (Component: ComponentType) => {
  return () => {
    const [doorId, setDoorId] = useState<number[]>([]);
    const [params, setParams] = useState<IDoorParams>({ limit: 12, page: 1 });
    const [transitions, setTransitions] = useState<ITransitionDoorAccess[]>([]);
    const [door, setDoor] = useState<ITransitionDoorAccess>();

    useEffect(() => {
      const socket = io(socketUrl.replace("http", "ws"), {
        transports: ["websocket"],
      });

      socket.on(`on-door`, (v: { data: ITransitionDoorAccess }) => {
        console.log(`on-door`,v.data);
        setTransitions((prev) => [v.data, ...prev]);
        setDoor(v.data);
      });

      return () => {
        socket.disconnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setTransitions([]);
      setDoor(undefined);
    }, []);

    return (
      <Context.Provider
        value={{
          doorId,
          setDoorId,
          params,
          setParams,
          transitions,
          door,
          setDoor,
        }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useDoorRealtime = () => useContext(Context);
export default withDoorRealtime;
