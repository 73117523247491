import { ComponentType, createContext, useContext, useState } from "react";
import { IItem, IListStatusQueue, StatusQueueCarContext } from "./interface";

const Context = createContext<StatusQueueCarContext>(
  {} as StatusQueueCarContext
);

const withStatusQueueCar = (Component: ComponentType) => {
  return () => {
    const [keyStatus, setKeyStatus] = useState<IListStatusQueue>();
    const [numberOfItems, setNumberOfItems] = useState<IItem>({
      ALL: 0,
      CHECK_SPRAY: 0,
      CHECK_CAR_CONDITIOPN: 0,
      VEHICLE_QUICKLAB: 0,
      WAITING_QUEUE_IN: 0,
      WAIT_FOR_LOAD: 0,
      START_LOADING: 0,
      FINISH_LOADING: 0,
      WEIGHED_OUT: 0,
      TVOP: 0,
    });

    return (
      <Context.Provider
        value={{ numberOfItems, setNumberOfItems, keyStatus, setKeyStatus }}
      >
        <Component />
      </Context.Provider>
    );
  };
};

export const useStatusQueueCar = () => useContext(Context);
export default withStatusQueueCar;
