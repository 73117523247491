import { DefaultOptionType } from "rc-tree-select/lib/TreeSelect";
import CInfiniteScroll from "components/display/c-infinite-scroll";
import CInput from "components/input/c-input";
import CSelect from "components/input/c-select";
import CTreeSelect from "components/input/c-tree-select";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import { useVehicleFloor } from "provider/vehicle-floor";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFloorRoot, useFloorScroll } from "services/floor";
import { IFloor, IFloorParams, IFloorRoot } from "services/floor/interface";
import { useParkingsScroll } from "services/parking";
import { IParking, IParkingParams } from "services/parking/interface";
import VehicleFloorSidebarCard from "./description";
import { Form } from "antd";

const VehicleFloorSidebar = () => {
  const { levelNumber } = useVehicleFloor();

  switch (levelNumber) {
    case 1:
      return <FirstLevel />;
    case 2:
      return <SecondLevel />;
    default:
      return <ThirdLevel />;
  }
};

const FirstLevel = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IParkingParams>();

  const { setSelectedParking, selectedParking } = useVehicleFloor();

  const scroll_parkings = useParkingsScroll(params);

  return (
    <InfiniteSidebar
      title={t("vehicle-management.floors", { ns: "menu" })}
      infiniteScroll={{
        mutation: scroll_parkings,
        render: (data: IParking[]) => {
          return data.map((item) => {
            return (
              <VehicleFloorSidebarCard
                key={item.id}
                onClick={() => setSelectedParking(item)}
                active={item.id === selectedParking?.id}
                title={item.parkingName}
                value={item.capacity - (item.unoccupied || 0)}
                maxValue={item.capacity}
              />
            );
          });
        },
        className: "mx-5",
        search: {
          placeholder: t('floor-name'),
          onChange: (e) => {
            setParams({ ...params, search: e });
          },
        },
      }}
    />
  );
};

const SecondLevel = () => {
  const { t } = useTranslation();

  const {
    selectedFloor,
    selectedParking,
    setSelectedFloor,
    setSelectedParking,
  } = useVehicleFloor();

  const [params, setParams] = useState<IFloorParams>({
    enabled: !!selectedParking,
    // menuLevelId: levelId,
    parkingId: selectedParking?.id
  });

  const scroll = useFloorScroll(params);

  return (
    <InfiniteSidebar
      title={t("vehicle-management.floors", { ns: "menu" })}
      content={
        <React.Fragment>
          <CSelect.Parking
            value={selectedParking?.id}
            onSelectCallback={(e) => {
              setSelectedParking(e);
              setSelectedFloor(undefined);
              setParams({
                ...params,
                parkingId: e.id,
                enabled: true,
              });
            }}
          />
          <CInput.Debounce
            value={params?.search}
            onChange={(e) => {
              setParams({ ...params, search: e });
            }}
            hidden={!selectedParking}
            className="!mt-5"
            placeholder={t("floor-name")}
          />
          <CInfiniteScroll
            className="mt-5"
            hidden={!selectedParking}
            height="calc(100vh - 415px)"
            mutation={scroll}
            render={(data: IFloor[]) => {
              return data.map((item) => {
                return (
                  <VehicleFloorSidebarCard
                    key={item.id}
                    title={item.floorName}
                    maxValue={0}
                    value={0}
                    onClick={() => {
                      setSelectedFloor(item);
                    }}
                    active={item.id === selectedFloor?.id}
                  />
                );
              });
            }}
          />
        </React.Fragment>
      }
    />
  );
};

const ThirdLevel = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const wTreeFloor = Form.useWatch("treeFloor", form);

  const {
    selectedFloor,
    selectedParking,
    setSelectedFloor,
    setSelectedParking,
    levelId,
  } = useVehicleFloor();

  const [params, setParams] = useState<IFloorParams>();
  const scroll = useFloorScroll({
    ...params,
    enabled: !!wTreeFloor,
    parentFloorId: wTreeFloor,
  });

  useEffect(() => {
    form.resetFields();
    setSelectedFloor(undefined);
    setSelectedParking(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelId]);

  return (
    <InfiniteSidebar
      title={t("vehicle-management.floors", { ns: "menu" })}
      content={
        <Form form={form}>
          <CSelect.Parking
            value={selectedParking?.id}
            onSelectCallback={(v) => {
              setSelectedParking(v)
              setSelectedFloor(undefined)
              form.resetFields();
              setParams({...params, search: undefined})
            }}
          />
          <FloorTreeSelect />
          <CInput.Debounce
            value={params?.search}
            onChange={(e) => {
              setParams({ ...params, search: e });
            }}
            hidden={!selectedParking}
            className="!mt-5"
            placeholder={t("floor-name")}
          />
          <CInfiniteScroll
            className="mt-5"
            hidden={!selectedParking}
            height="calc(100vh - 480px)"
            mutation={scroll}
            render={(data: IFloor[]) => {
              return data.map((item) => {
                return (
                  <VehicleFloorSidebarCard
                    key={item.id}
                    title={item.floorName}
                    maxValue={0}
                    value={0}
                    onClick={() => {
                      setSelectedFloor(item);
                    }}
                    active={item.id === selectedFloor?.id}
                  />
                );
              });
            }}
          />
        </Form>
      }
    />
  );
};

const FloorTreeSelect = () => {
  const { t } = useTranslation();
  const { selectedParking, levelNumber } = useVehicleFloor();

  const { data, isLoading } = useFloorRoot({
    enabled: !!selectedParking,
    parkingId: selectedParking?.id,
  });

  const tranformTree = (
    dataSource: IFloorRoot[],
    round: number
  ): DefaultOptionType[] => {
    return dataSource.map((item) => {
      let children: DefaultOptionType[] = [];
      if (item.children && item.children.length && round <= levelNumber) {
        children = tranformTree(item.children, round + 1);
      }
      return {
        label: item.floorName,
        value: item.id,
        children,
        disabled: round - 1 !== levelNumber,
      };
    });
  };

  const treeData = tranformTree(data || [], 4);

  return (
    <Form.Item noStyle name="treeFloor">
      <CTreeSelect
        loading={isLoading}
        hidden={!selectedParking}
        placeholder={t("floor")}
        className="!mt-5"
        treeData={treeData}
      />
    </Form.Item>
  );
};

export default VehicleFloorSidebar;
