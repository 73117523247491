import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScrollGetVehicleTypes } from "services/setting-vehicle-type";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";

const CSelectVehicleType: FC<CSelectProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useScrollGetVehicleTypes}
      valueKey="id"
      titleKey="vehicle"
      searchKey="vehicle"
      placeholder={t("vehicle-type")}
      translation
    />
  );
};

export default CSelectVehicleType;
