import { Col, Row, Typography } from "antd";
import CCard from "components/display/card";
import InfiniteSidebar from "components/layout/infinite-sidebar";
import PageContent from "components/layout/page-content";
import withAccessibilityDeterminedPeople, {
  useAccessibilityDeterminedPeople,
} from "provider/accessibility-determined-by-people";
import { useTranslation } from "react-i18next";
import AccessibilityDeterminedByPersonContent from "./content";

const AccessibilityDeterminedByPersonPage = () => {
  return (
    <PageContent breadcrumb>
      <Row>
        <Col span={6}>
          <Sidebar />
        </Col>
        <Col span={18} className='p-5'>
          <AccessibilityDeterminedByPersonContent />
        </Col>
      </Row>
    </PageContent>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { accessibility, setAccessibility } =
    useAccessibilityDeterminedPeople();

  const content = [1, 2, 3].map((item) => {
    return (
      <CCard.SlashHeader
        active={accessibility === item}
        className="mb-4"
        title="Group 01"
        key={item}
        onClick={() => {
          setAccessibility(item);
        }}
      >
        <Row
          align="middle"
          className="px-3 py-2"
          style={{ height: 86 }}
          gutter={12}
        >
          <Col>
            <Typography.Text type="secondary" ellipsis={{ tooltip: t("list") }}>
              {t("list")} :
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{`${5} ${t("list")}`}</Typography.Text>
          </Col>
        </Row>
      </CCard.SlashHeader>
    );
  });

  return (
    <InfiniteSidebar
      title={t("group")}
      content={content}
      actionButtonGroup={{
        onAdd: () => {
          //
        },
        onEdit: () => {
          //
        },
        onDelete: () => {
          //
        },
      }}
    />
  );
};

export default withAccessibilityDeterminedPeople(
  AccessibilityDeterminedByPersonPage
);
