import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CSelectProps } from ".";
import CSelectPagination from "./pagination";
import { useGetPickupPoints } from "services/master";
import { IPickupPoint } from "services/master/interface";

export interface CSelectErpPickupPointProps extends CSelectProps {
  setStateOptions?: (v: IPickupPoint[]) => void
}

const CSelectErpPickupPoint: FC<CSelectErpPickupPointProps> = (props) => {
  const { t } = useTranslation();
  return (
    <CSelectPagination
      {...props}
      useQuery={useGetPickupPoints}
      valueKey="id"
      titleKey="pickupPointDescription"
      searchKey="pickupPointName"
      placeholder={t("pickup-point")}
      getOriginOption
      queryParams={{
        limit: 100,
      }}
    />
  );
};
export default CSelectErpPickupPoint;
