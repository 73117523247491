import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { throwResponse } from "config/axios";
import { IResponse } from "config/axios/interface";
import { IReportDoorLog, IReportDoorLogParams } from "./interface";

export const useGetReportDoorLogs = (
  params?: IReportDoorLogParams
): UseQueryResult<IResponse<IReportDoorLog[]>, Error> => {
  return useQuery(
    ["get-report-door-log", params],
    async () => {
      const res = await axios.get("/report/report-door-log", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useExportReportDoorLog = (): UseMutationResult<Blob, Error> => {
  return useMutation(async (data) => {
    const res = await axios.post(`/export-excel/report-door-log`, data, {
      responseType: "blob",
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
