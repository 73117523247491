import RouteContent from "components/layout/route-content";
import { useAppRoleMenu } from "provider/app-role-menu";
import withPageRoute, { usePageRoute } from "provider/page-route";
import { useEffect } from "react";
import { ISubMenu } from "router/interface";
import { TSubPageVehicleManagement } from "router/vehicle-management/interface";
import { useGetMenuLevels } from "services/menu-level";
import { IMenuLevel } from "services/menu-level/interface";

const parentKey = "vehicle-management.parking-management";

const VehicleManagementPage = () => {
  const { getMenuByKey, updateRoleMenus } = useAppRoleMenu();
  const { getCurrentElement } = usePageRoute();

  const { data } = useGetMenuLevels();

  const element = getCurrentElement();
  const { menus, initial } = getMenuByKey("vehicle-management");

  useEffect(() => {
    addMenuLevel();
    // console.log('refetch')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const addMenuLevel = () => {
    if (!data) return;
    const next = menus.map((item) => {
      if (item.key === parentKey && item.children) {
        const parking = [...item.children][0];
        const rest = item.children.filter(
          (e) =>
            e.key !== "vehicle-management.parkings" &&
            e.key !== "vehicle-management.floors"
        );
        const level = generateMenuLevel(data.data);

        return {
          ...item,
          children: [{ ...parking, loading: false }, ...level, ...rest],
        };
      }
      return item;
    });

    updateRoleMenus("vehicle-management", next);
  };

  const generateMenuLevel = (
    menus: IMenuLevel[]
  ): ISubMenu<TSubPageVehicleManagement>[] => {
    const topLevel = Math.max(...menus.map((e) => e.levelNumber));
    return menus.map((item) => {
      return {
        key: "vehicle-management.floors",
        title: item.levelName,
        code: "050201",
        params: {
          key: "id",
          value: { ...item, topLevel },
        },
      };
    }) as ISubMenu<TSubPageVehicleManagement>[];
  };

  return (
    <RouteContent menus={menus} initialPage={initial}>
      {element}
    </RouteContent>
  );
};

export default withPageRoute(VehicleManagementPage, "vehicle-management");
